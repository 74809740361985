import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const RelativeContainer = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  position: relative;
`

export const SelectSimulator = styled.div`
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  border-radius: 5px;
  border: ${({ isActive }) =>
    isActive ? `2px solid ${theme.colors.bluePrimary}` : `1px solid ${theme.colors.bluePrimaryLight}`};

  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }

  svg:last-child {
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.4s ease-in-out;
  }
`

export const OptionsWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  border: 1px solid ${theme.colors.bluePrimaryLight};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  z-index: 100;

  *::before,
  *::after {
    box-sizing: initial;
  }
`

export const OptionsContainer = styled.div`
  max-height: 350px;
  overflow-y: scroll;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`

export const FlexContainerWithSVG = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
