import React from 'react'
import { theme, CheckBox, InputFieldV3 } from '@provi/provi-components'
import { Button } from '~/components/v3'
import { BlackHeaders, ExplanationBox } from '../index'
import { VerticalContainers, FlexContainers, TwoHundredPxDiv } from '../../styles'
import { ChainLinkIcon, PlusIcon } from '~/assets/svgs/v2'
import { BaseTypography } from '~/globalStyles'
import { uniqueId } from 'lodash'

const StepsAndPaymentComponent = ({ isMobile, index }) => {
  const [checked, setChecked] = React.useState(false)

  const flexGaps = isMobile ? '0.5rem' : '1.5rem'
  const flexAligns = !isMobile && 'center'
  const flexDirs = isMobile && 'column'

  return (
    <>
      <FlexContainers justifyContent={'space-between'} flexDirection={flexDirs}>
        <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
          <BaseTypography
            fontSize={isMobile ? '13px' : '16px'}
            lineHeight={isMobile ? '20px' : '24px'}
            color={theme.colors.bluePrimary}
            fontWeight={700}
          >
            {`${index + 1}º Lote`}
          </BaseTypography>
          <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'}>
            <InputFieldV3 label="Início do lote" type="date" name="" placeholder="01/09/2021" />
            <InputFieldV3 label="Horário" type="time" name="" placeholder="00:01" />
          </FlexContainers>
          <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'}>
            <InputFieldV3 label="Fim do lote" type="date" name="" placeholder="30/09/2021" />
            <InputFieldV3 label="Horário" type="time" name="" placeholder="00:01" />
          </FlexContainers>
        </VerticalContainers>
        <ExplanationBox
          isMobile={isMobile}
          explanation={
            'Os valores dos lotes serão mantidos para para quem já selecionou a turma dentro do período de validade do lote, mesmo que a compra só seja efetuada após esse período. '
          }
        />
      </FlexContainers>
      <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
        <BlackHeaders
          isMobile={isMobile}
          title={'Métodos de pagamento disponíveis '}
          explanation={'O aluno poderá escolher como pagar dentro do modelo de precificação determinado por você.'}
        />
        <VerticalContainers gap={'1.5rem'}>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox
                name="creditCard"
                text={'Cartão de crédito'}
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3 type="text" name="" placeholder="R$ 5.000,00" width={'100%'} />
              {!isMobile && <ChainLinkIcon color={!checked && theme.colors.grey500} />}
              <InputFieldV3 type="text" name="" placeholder="Ex: 20%" width={'100%'} />
            </FlexContainers>
          </FlexContainers>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox name="slip" text={'Boleto à vista'} checked={checked} onChange={() => setChecked((prev) => !prev)} />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3 type="text" name="" placeholder="R$ 5.000,00" width={'100%'} />
              {!isMobile && <ChainLinkIcon color={!checked && theme.colors.grey500} />}
              <InputFieldV3 type="text" name="" placeholder="Ex: 20%" width={'100%'} />
            </FlexContainers>
          </FlexContainers>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox
                name="financing"
                text={'Financiamento'}
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3 type="text" name="" placeholder="R$ 5.000,00" width={'100%'} />
              {!isMobile && <ChainLinkIcon color={!checked && theme.colors.grey500} />}
              <InputFieldV3 type="text" name="" placeholder="Ex: 20%" width={'100%'} />
            </FlexContainers>
          </FlexContainers>
        </VerticalContainers>
      </VerticalContainers>
    </>
  )
}

export const StepsAndPaymentMethodSub = ({ isMobile }) => {
  const [stepsArr, setStepsArr] = React.useState([])
  return (
    <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
      {stepsArr.map((eachElement, index) => (
        <StepsAndPaymentComponent key={uniqueId()} isMobile={isMobile} index={index} eachElement={eachElement} />
      ))}
      <Button
        icon={<PlusIcon color={'white'} />}
        text="Adicionar lote"
        mobileWidth={'100%'}
        onClick={() => setStepsArr((prev) => [...prev, 'something to add'])}
      />
    </VerticalContainers>
  )
}
