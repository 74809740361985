import React from 'react'
import { IntegrationsContext } from '~/screens/integrations/context'
import { Button } from './styles'

function AddAccountButton() {
  const { setFlowState } = React.useContext(IntegrationsContext)

  return <Button onClick={() => setFlowState('Adicionar conta')}>Adicionar Conta</Button>
}

export { AddAccountButton }
