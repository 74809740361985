import { useCallback, useContext, useMemo } from 'react'
import { queryClient } from '~/stores/react-query'
import { toast } from 'react-toastify'
import { useUnlinkProductMutation, IntegrationsQueryKeys } from '~/screens/integrations/requests'
import { TemplateContext } from '~/components/Template/context'
import { IntegrationsContext } from '~/screens/integrations/context'

export const useIntegrationsTable = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const { setFlowState, setProductsToEdit } = useContext(IntegrationsContext)

  const extraUnlinkProductConfig = useMemo(
    () => ({
      onMutate: () => {
        setIsLoading(true)
      },
      onError: () => {
        setIsLoading(false)
        toast('Algo de errado aconteceu 😔 Por favor, tente novamente.')
      },
      onSuccess: () => {
        queryClient.invalidateQueries(IntegrationsQueryKeys.invalidateAllKey)
        setIsLoading(false)
        toast('Produto desvinculado com sucesso! 😀')
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }),
    [setIsLoading],
  )

  const { mutate } = useUnlinkProductMutation(extraUnlinkProductConfig)

  const handleEditProduct = useCallback(
    (IdsArr, type, courseId, CourseClassId) => {
      setProductsToEdit({ type, IdsArr, courseId, CourseClassId })
      setFlowState('Conectar produtos')
    },
    [setFlowState],
  )

  const handleUnlinkProduct = useCallback(
    (ProductId) => {
      mutate(ProductId)
    },
    [mutate],
  )

  /**
   *
   * @param {string[]} listOfConnections
   * @returns {Promise<void>}
   */
  async function handleUnlinkAllProducts(listOfConnections) {
    await Promise.all(
      listOfConnections.map((connection) => {
        return handleUnlinkProduct(connection)
      }),
    )
  }

  return { handleEditProduct, handleUnlinkProduct, handleUnlinkAllProducts }
}
