import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const DefaultComparisonChartGrid = styled.div`
  display: grid;
  padding: 2rem 1rem;
  max-width: 1280px;
  background-color: #ffffff;
  gap: 1.5rem;
  border-radius: 20px;
  border: 1px solid #d4daf7;
  box-shadow: 0 4px 14px 0 rgba(180, 194, 215, 0.4);
  transition: 0.3s;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(180, 194, 215, 0.4);
  }

  ${theme.breakpoints.down('md')} {
    padding: 1rem 0.5rem;
    border-radius: initial;
  }
`

export const ChartHeader = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: #000000;
  font-size: 20px;
  font-weight: 600;

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`

export const ChartText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 20px;
  color: #575757;
  font-size: 13px;
  width: 95%;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
  }
`

export const ChartWrapper = styled.div`
  min-width: 300px;
  min-height: 350px;

  * {
    font-family: 'Montserrat', sans-serif;
  }
`
