import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'

import { postCourses, getStates, getCity, getMacroAreas, getMicroAreas, postSimulation } from '~/services/api'
import { capitalize, asyncLocalStorage, CurrencyMask, unMask } from '~/utils'

const initial_period_options = [
  { id: 1, label: 'Full Time', value: true },
  { id: 2, label: 'Part Time', value: false },
]

const initial_modality_options = [
  { id: 1, label: 'Presencial', value: 'presencial' },
  { id: 2, label: 'Semi-presencial', value: 'semi-presencial' },
  { id: 3, label: 'Remoto', value: 'remoto' },
]

export const useCreateCourses = (
  setIsLoading,
  handleModal,
  selectedOptions,
  setSelectedOptions,
  setValue,
  value,
  goToPage,
  activePage,
  setActivePage,
) => {
  const [state_options, setStateOptions] = useState([
    // { id: 1, label: 'São Paulo', value: 'saopaulo' },
    // { id: 2, label: 'Rio de Janeiro', value: 'riodejaneiro' }
  ])
  const [city_options, setCityOptions] = useState([
    // { id: 1, label: 'São Paulo', value: 'saopaulo' },
    // { id: 2, label: 'Olimpia', value: 'olimpia' }
  ])

  const [macro_options, setMacroOptions] = useState([])
  const [micro_options, setMicroOptions] = useState([])

  const [nameError, setNameError] = useState('')
  const [valueError, setValueError] = useState('')
  //
  const [anchorEl, setAnchorEl] = useState()
  const [anchor, setAnchor] = useState('')
  const [visible, setVisible] = useState(null)
  const [optionActive, setOptionActive] = useState({ visibleCoursesOption: null, proccessOption: null })

  const [period_options] = useState(initial_period_options)
  const [modality_options] = useState(initial_modality_options)

  const [values, setValues] = useState({ productValue: null, modality: null })

  const [isButtonDisable, setIsButtonDisable] = useState(true)

  const [periodValue, setPeriodValue] = useState()
  const [modalityValue, setModalityValue] = useState()
  const [macroAreaValue, setMacroAreaValue] = useState()
  const [microAreaValue, setMicroAreaValue] = useState()

  const handleMacroAreas = async () => {
    try {
      const rawMacroAreas = await getMacroAreas()
      const { data: macroData, defaultMacroAreaId } = rawMacroAreas.data
      const macroAreas = macroData.map((macro) => ({ id: macro.id, label: macro.name, value: macro.name }))
      setMacroOptions([...macroAreas])

      if (defaultMacroAreaId) {
        const find = macroAreas.find((ma) => ma.id === defaultMacroAreaId)
        setMacroAreaValue(find)

        const microareasResponse = await getMicroAreas(find.id)
        const { data: microareasData } = microareasResponse

        const microAreas = microareasData.map((micro) => ({ id: micro.id, label: micro.name, value: micro.name }))
        setMicroOptions([...microAreas])
        const newObj = {
          macroareas: find,
        }

        return newObj
      }
    } catch (error) {
      showToast('Ocorreu um erro ao listar categorias, por favor tente novamente ou contate a provi')

      return {
        macroareas: [],
      }
    }
  }

  const handleCheck = async (option, v) => {
    setIsLoading(true)
    if (!option) return
    if (option === 'options') {
      setVisible(v === 'y')
      setOptionActive({ ...optionActive, visibleCoursesOption: v })

      if (v === 'y' && optionActive.visibleCoursesOption !== 'y') {
        try {
          const response = await getStates()
          const { data } = response

          const states = data.map((state) => ({ id: state.id, label: state.name, value: state.name }))
          setStateOptions(states)

          const rawMacroAreas = await getMacroAreas()
          const { data: macroData, defaultMacroAreaId } = rawMacroAreas.data
          const macroAreas = macroData.map((macro) => ({ id: macro.id, label: macro.name, value: macro.name }))
          setMacroOptions([...macroAreas])

          if (defaultMacroAreaId) {
            const newObj = await handleMacroAreas()

            setValues({ ...values, ...newObj })
          }
        } catch (error) {
          //
        }
      }
    } else if (option === 'process') {
      if (optionActive.proccessOption === v) {
        setOptionActive({ ...optionActive, proccessOption: null })
      } else {
        setOptionActive({ ...optionActive, proccessOption: v })
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(false)
    if (activePage !== 'sell') setActivePage('sell')

    const checkIfExistCourse = async () => {
      const course = JSON.parse(await asyncLocalStorage.getItem('duplicate-course'))
      if (course) {
        setIsLoading(true)
        const { name, price, modality, is_full_time, visible: courseVisibility } = course

        const modalityValueRef = initial_modality_options.filter((imo) => imo.value === modality)[0]
        if (modalityValueRef) setModalityValue(modalityValueRef)

        const periodValueRef = initial_period_options.filter((ipo) => ipo.value === is_full_time)[0]
        if (periodValueRef) setPeriodValue(periodValueRef)

        setVisible(courseVisibility)
        setOptionActive({ ...optionActive, visibleCoursesOption: courseVisibility ? 'y' : 'n' })

        let newObj = {}
        if (courseVisibility) {
          newObj = await handleMacroAreas()

          const stateResponse = await getStates()
          const { data } = stateResponse

          const states = data.map((state) => ({ id: state.id, label: state.name, value: state.name }))
          setStateOptions(states)
        }

        setIsLoading(false)
        setValues({
          name,
          productValue: CurrencyMask(price * 100),
          period: periodValueRef,
          modality: modalityValueRef,
          ...newObj,
        })
      }
    }

    checkIfExistCourse()
  }, [])

  const validate = (e, field) => {
    let valueInput
    switch (field) {
      case 'name':
        valueInput = e.target.value
        if (!valueInput) {
          setNameError('Nome é obrigatório')
        } else if (valueInput.length < 8) {
          setNameError('Minimo de 8 digitos para o nome do curso')
        } else {
          setNameError('')
        }
        break
      case 'productValue':
        valueInput = e.target.value
        if (!valueInput) {
          setValueError('Valor é obrigatório')
        } else if (valueInput === 'R$ 0,00') {
          setValueError('Valor não pode ser zero')
        } else {
          setValueError('')
        }
        break

      default:
        break
    }
  }

  const handleSubmitCouses = async () => {
    setIsLoading(true)

    try {
      const { name, productValue, period, modality, city, workload, durationDays, microareas } = values

      const initialData = {
        name,
        price_in_cents: Number(unMask(productValue)),
        is_full_time: period.label === 'Full Time',
        modality: modality.value,
      }

      let submitData = {}

      if (!visible) {
        submitData = {
          ...initialData,
          visible: optionActive.visibleCoursesOption === 'y',
        }
      } else {
        submitData = {
          ...initialData,
          visible: optionActive.visibleCoursesOption === 'y',
          hasSelectionProcess: optionActive.proccessOption ? optionActive.proccessOption === 'y' : null,
          workload: workload && Number(workload) < 0 ? null : Number(workload),
          durationDays: durationDays && Number(durationDays) < 0 ? null : Number(durationDays),
          cityId: (city && city.id) || 0,
          MicroAreaId: microareas.id,
        }
      }

      const { data: dataSimulation } = await postSimulation({
        amountInCents: initialData.price_in_cents,
        upfrontInCents: 0,
        discountInCents: 0,
      })

      if (dataSimulation.options.length === 0) {
        showToast('Valor do produto resulta em parcelas mais baixas do que o mínimo aceito pela Provi.')
      } else {
        const response = await postCourses(submitData)

        const { id, name: productName, price, modality: productModality, attendType } = response.data

        if (response.status === 400) {
          showToast(response.data.error.message)
          return
        }
        const product = {
          id,
          name: productName,
          price_in_cents: price * 100,
          modality: productModality,
          is_full_time: attendType === 'Full-time',
          attendType,
          details: [capitalize(productModality), capitalize(attendType)],
          visible: optionActive.visibleCoursesOption === 'y',
        }

        showToast('Produto cadastrado com sucesso')

        setSelectedOptions([product, ...selectedOptions])
        setValue(Number(value) + product.price_in_cents)
        goToPage('checkout/selecionar-produtos')
      }
    } catch (error) {
      showToast(error.message || 'Houve um erro ao cadastrar o curso')
    } finally {
      setIsLoading(false)
    }
  }

  const handleClick = (event, target) => {
    setAnchorEl(event.currentTarget)
    setAnchor(target)
  }

  const handleClose = () => {
    setAnchor(null)
    setAnchorEl(null)
  }

  const handleValue = async (opt, v) => {
    if (opt === 'modality') {
      setModalityValue(v)
    } else if (opt === 'period') {
      setPeriodValue(v)
    } else if (opt === 'macroareas') {
      setMacroAreaValue(v)
    } else if (opt === 'microareas') {
      setMicroAreaValue(v)
    }

    const newObj = {}
    if (v === 0) {
      newObj[opt] = null
    } else if (opt === 'productValue') {
      newObj[opt] = CurrencyMask(v)
    } else {
      newObj[opt] = v
    }

    if (opt === 'state') {
      setIsLoading(true)
      const response = await getCity(v.id)
      const { data } = response

      const cities = data.map((cy) => ({ id: cy.id, label: cy.name, value: cy.name }))

      setCityOptions([...cities])
      setIsLoading(false)
      newObj.city = null
    }

    if (opt === 'macroareas') {
      setIsLoading(true)
      setMicroAreaValue(null)

      const response = await getMicroAreas(v.id)
      const { data } = response

      const microAreas = data.map((micro) => ({ id: micro.id, label: micro.name, value: micro.name }))
      setMicroOptions([...microAreas])

      setIsLoading(false)
      newObj.microareas = null
    }

    setValues({ ...values, ...newObj })
  }

  useEffect(() => {
    setIsButtonDisable(true)
    if (visible) {
      // curso visivel
      if (values.modality && values.modality.value !== 'remoto') {
        setIsButtonDisable(
          !(
            values.name &&
            values.productValue &&
            values.period &&
            values.modality &&
            values.state &&
            values.city &&
            values.macroareas &&
            values.microareas &&
            !(nameError || valueError)
          ),
        )
      } else {
        setIsButtonDisable(
          !(
            values.name &&
            values.productValue &&
            values.period &&
            values.modality &&
            values.macroareas &&
            values.microareas &&
            !(nameError || valueError)
          ),
        )
      }
    } else {
      // curso invisivelconsole.
      setIsButtonDisable(
        !(values.name && values.productValue && values.period && values.modality && !(nameError || valueError)),
      )
    }
  }, [values, visible, nameError, valueError])

  return [
    validate,
    nameError,
    valueError,
    handleSubmitCouses,
    handleClick,
    anchorEl,
    handleClose,
    anchor,
    handleCheck,
    visible,
    optionActive,
    modality_options,
    period_options,
    //
    state_options,
    city_options,
    //
    handleValue,
    values,

    isButtonDisable,
    //
    periodValue,
    modalityValue,

    macro_options,
    micro_options,
    macroAreaValue,
    microAreaValue,
  ]
}
