import React from 'react'

export const TimelineCircleCheck = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6284 22.8C18.593 22.8 23.4284 17.9646 23.4284 12C23.4284 6.03528 18.593 1.19995 12.6284 1.19995C6.66369 1.19995 1.82837 6.03528 1.82837 12C1.82837 17.9646 6.66369 22.8 12.6284 22.8Z"
      fill="#CFCFCF"
    />
    <path
      d="M17.4284 9.59998L11.4284 15.6L7.82837 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
