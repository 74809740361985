const { REACT_APP_API_ENV } = process.env

export const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const WP_URL = 'https://wp.provi.com.br'

const URLS = {
  production: 'https://ms-checkout.provi.com.br',
  remote_development: 'https://checkout-apidev.provi.com.br',
  remote_staging: 'https://ms-checkout-staging.provi.com.br',
  local_development: 'http://localhost:5005/checkout',
  new_partner_staging: 'https://parceiro.stage.principia.net',
  new_partner_production: 'https://parceiro.principia.net',
}

let _ROOT_URL
let _NEW_PARTNER_URL
switch (REACT_APP_API_ENV) {
  case 'production':
    console.log('[production] -> server')
    _ROOT_URL = URLS.production
    _NEW_PARTNER_URL = URLS.new_partner_production
    break
  case 'staging':
    console.log('[staging] -> server')
    _ROOT_URL = URLS.remote_staging
    _NEW_PARTNER_URL = URLS.new_partner_staging
    break
  case 'development':
    console.log('[development] -> server')
    _ROOT_URL = URLS.remote_development
    break
  case 'local':
    console.log('[development] -> server')
    _ROOT_URL = URLS.local_development
    break
  default:
    console.log('[localhost] -> server')
    _ROOT_URL = URLS.local_development
    break
}

export const ERRORS = {
  default: 'Ops, ocorreu algum erro 😔',
}

export const accessLevels = {
  unrestricted: true,
  admin: 'admin',
  senior: 'senior',
  pleno: 'pleno',
  junior: 'junior',
  superadmin: 'superadmin',
  fiscal: 'fiscal',
}

export const PERMISSIONS = {
  seeCreateSales: {
    junior: true,
    pleno: true,
    senior: true,
    admin: true,
    superadmin: true,
    fiscal: false,
  },
  seeCampaigns: {
    junior: true,
    pleno: true,
    senior: true,
    admin: true,
    superadmin: true,
    fiscal: false,
  },
  seeSalesManagement: {
    junior: true,
    pleno: true,
    senior: true,
    admin: true,
    superadmin: true,
    fiscal: false,
  },
  seeMonitoringDash: {
    junior: false,
    pleno: false,
    senior: true,
    admin: true,
    superadmin: true,
    fiscal: true,
  },
  seeFinantialControl: {
    junior: false,
    pleno: false,
    senior: false,
    admin: true,
    superadmin: true,
    fiscal: false,
  },
  seeFinancialMovement: {
    junior: false,
    pleno: false,
    senior: false,
    admin: REACT_APP_API_ENV === 'staging',
    superadmin: REACT_APP_API_ENV === 'staging',
    fiscal: false,
  },
  seeUserControl: {
    junior: false,
    pleno: false,
    senior: false,
    admin: true,
    superadmin: true,
    fiscal: false,
  },
  seeNFS: {
    junior: false,
    pleno: false,
    senior: false,
    admin: false,
    superadmin: true,
    fiscal: true,
  },
  seeCourseClass: {
    fiscal: false,
    junior: false,
    pleno: false,
    senior: REACT_APP_API_ENV === 'staging', // RETIRAR QUANDO A FUNCIONALIDADE RECEBER APROVAÇÃO DE DISPONIBILIZAÇÃO
    admin: REACT_APP_API_ENV === 'staging', // RETIRAR QUANDO A FUNCIONALIDADE RECEBER APROVAÇÃO DE DISPONIBILIZAÇÃO
    superadmin: REACT_APP_API_ENV === 'staging', // RETIRAR QUANDO A FUNCIONALIDADE RECEBER APROVAÇÃO DE DISPONIBILIZAÇÃO
  },
}

export const invalidMonitoringPartnerIds = [8, 168]

export const ROOT_URL = _ROOT_URL
export const NEW_PARTNER_URL = _NEW_PARTNER_URL
const googleAnalyticsTrackingId = {
  local: 'UA-146320363-9',
  production: 'UA-146320363-13',
  staging: 'UA-146320363-9',
  development: 'UA-146320363-9',
}

export const trackingId = googleAnalyticsTrackingId[REACT_APP_API_ENV]

export const googleAnalyticsDimensions = {
  userId: 'userId',
  accessLevel: 'dimension2',
  partnerId: 'dimension3',
  internalUser: 'dimension6',
}

export const LOCALSTORAGE_TOKENS = {
  userId: 'user-id',
  partnerId: 'partner-id',
  partnerSlug: 'partner-slug',
  token: 'token',
  accessLevel: 'access-level',
  email: 'email',
}

export const brazilianStates = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
]

export const VALIDATIONERRORS = {
  email: 'Por favor informe um e-mail válido',
  phone: 'Por favor informe um número válido',
}

export const PARTNER_COMMERCIAL_TRACKS = {
  go: 'Go',
  full: 'Full',
  flex: 'Flex',
  legacy: 'Legacy',
}
