import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const TimeLineContainer = styled.div`
  width: fit-content;
  min-width: 450px;
  background-color: #ffffff;
  display: grid;
  padding: 2rem;
  border: 2px solid #d4daf7;
  border-radius: 8px;

  ${theme.breakpoints.down('md')} {
    min-width: 100%;
    max-width: 100%;
    padding: 1rem;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const WrappingGrid = styled.div`
  display: grid;
  gap: 1.5rem;
`

export const TextGrid = styled.div`
  display: grid;
  gap: 0.5rem;

  ${({ isFromSteps }) =>
    isFromSteps &&
    css`
      padding: 0 1rem 1rem;
    `};
`

export const TimeLineFlowGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`

export const BlueText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;

  ${({ isTimeLineTitle }) =>
    isTimeLineTitle &&
    css`
      font-weight: 700;
      text-transform: uppercase;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    `};

  ${({ isStepTitle }) =>
    isStepTitle &&
    css`
      font-weight: 600;
    `};
`

export const BlackText = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${({ isFromExplainer }) =>
    isFromExplainer &&
    css`
      color: #575757;
    `};

  ${({ isFromStepsBig }) =>
    isFromStepsBig &&
    css`
      font-size: 16px;
      line-height: 24px;
    `};
`

export const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`

export const Circle = styled.div`
  border: 2px solid ${theme.colors.bluePrimary};
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
`

export const Line = styled.div`
  height: 100%;
  border: ${({ isPast }) => (isPast ? `1px solid ${theme.colors.grey500}` : `1px solid ${theme.colors.bluePrimary}`)};
  background-color: ${({ isPast }) => (isPast ? theme.colors.grey500 : theme.colors.bluePrimary)};
`
