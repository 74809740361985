/* eslint-disable react/prop-types */
import React from 'react'
import { Popover } from '@material-ui/core'
import { WrapperOrigin, PopoverText, Bold } from './styles'
import { useOrigin } from './hooks'
import { Ball } from './ball'

export const Origin = ({ originLink, id }) => {
  const [handlePopover, handleClose, anchorEl, anchor] = useOrigin()

  const Icon = ({ origin }) => {
    switch (origin) {
      case 'CHECKOUT':
      case 'checkout':
        return <Ball text="VE" />
      case 'API_V1':
        return <Ball text="API" />
      case 'API_V2':
        return <Ball text="API" />
      case 'isMaas':
        return <Ball text="MS" />
      case 'MAAS':
        return <Ball text="MS" />
      case 'TYPEFORM':
        return <Ball text="TP" />
      case 'TYPEFORM_ISA':
        return <Ball text="TP" />
      case 'CAMPAIGN':
        return <Ball text="CP" />
      case 'PROVI_PAY':
        return <Ball text="PP" />
      case 'sdk':
      case 'SDK':
        return <Ball />

      default:
        return <>---</>
    }
  }

  const PopoverTextRef = ({ origin }) => {
    switch (origin) {
      case 'CHECKOUT':
      case 'checkout':
        return (
          <>
            A sigla <Bold>VE</Bold> indica usuários que tiveram uma venda gerada exclusivamente para o CPF deles.
          </>
        )

      case 'API_V1':
        return (
          <>
            A sigla <Bold>API</Bold> indica usuários que tiveram uma venda gerada via API.
          </>
        )
      case 'API_V2':
        return (
          <>
            A sigla <Bold>API</Bold> indica usuários que tiveram uma venda gerada via API.
          </>
        )
      case 'isMaas':
        return (
          <>
            A sigla <Bold>MS</Bold> indica um financiamento no formato MaaS.
          </>
        )
      case 'MAAS':
        return (
          <>
            A sigla <Bold>MS</Bold> indica um financiamento no formato MaaS.
          </>
        )
      case 'TYPEFORM':
        return (
          <>
            A sigla <Bold>TP</Bold> indica usuários que tiveram uma venda gerada via typeform.
          </>
        )
      case 'TYPEFORM_ISA':
        return (
          <>
            A sigla <Bold>TP</Bold> indica usuários que tiveram uma venda gerada via typeform.
          </>
        )
      case 'CAMPAIGN':
        return (
          <>
            A sigla <Bold>CP</Bold> indica usuários que acessaram através de um link de campanha.
          </>
        )
      case 'PROVI_PAY':
        return (
          <>
            A sigla <Bold>PP</Bold> indica usuários que acessaram através de um link do ProviPay.
          </>
        )
      case 'SDK':
      case 'sdk':
        return (
          <>
            O <Bold>círculo cinza</Bold> indica usuários que que acessaram sem nenhuma campanha ou desconto específico.
          </>
        )

      default:
        return (
          <>
            O <Bold>---</Bold> indica que temos bug, por favor reporte =]
          </>
        )
    }
  }

  return (
    <>
      <WrapperOrigin onClick={(e) => handlePopover(e, id)}>
        <Icon origin={originLink} />
      </WrapperOrigin>

      <Popover
        id={`${id}`}
        open={anchor === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverText>
          <PopoverTextRef origin={originLink} />
        </PopoverText>
      </Popover>
    </>
  )
}
