import React from 'react'
import { Text } from '~/themes/styles'

import { Option } from './Option'

import { SectionOption, OptionTitle } from '../styles'
import { usePaymentMethods } from './hooks'
import { OptionsSection } from './styles'

export const PaymentMethodSection = ({ paymentMethods, setPaymentMethods }) => {
  const [handlePaymentMethods] = usePaymentMethods(paymentMethods, setPaymentMethods)

  return (
    <SectionOption>
      <OptionTitle>Método de pagamento disponível para os alunos*</OptionTitle>
      <Text size="12px" lineHeight="18px">
        O aluno terá acesso à apenas o(s) método(s) de pagamento selecionado(s) abaixo.
      </Text>
      <OptionsSection>
        {paymentMethods.map(({ name, selected, icon, available }, index) => (
          <Option
            key={index}
            icon={icon}
            text={name}
            selected={selected}
            available={available}
            onClick={() => handlePaymentMethods(name, selected, available)}
          />
        ))}
      </OptionsSection>
    </SectionOption>
  )
}
