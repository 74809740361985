import React, { useState, useEffect } from 'react'
import { api } from '~/services/api'
import { Product as OldProduct } from './old'
import { ProductV2 } from './v2'

export const Product = () => {
  const [useV3, setUseV3] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await api.get('/v4/provipay/version')

        setUseV3(response.data.content.useV3)
      } catch (error) {
        setUseV3(false)
      } finally {
        setIsLoading(false)
      }
    }

    checkVersion()
  }, [])

  if (isLoading) return null

  return <>{useV3 ? <ProductV2 /> : <OldProduct />}</>
}
