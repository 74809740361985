import React from 'react'
import { uniqueId } from 'lodash'

import { CloseIcon } from '~/assets/svgs/v2'
import { FlexWrapContainer, PillContainer, FlexContainer, BlueText } from './styles'

export const SelectPills = ({ pillsArray = [], removeItemFn }) => {
  return (
    <FlexWrapContainer>
      {pillsArray.map((eachObject) => (
        <PillContainer key={uniqueId()}>
          <FlexContainer>
            <BlueText>{eachObject.label}</BlueText>
            <CloseIcon bluePrimarySmall onClick={() => removeItemFn(eachObject)} />
          </FlexContainer>
        </PillContainer>
      ))}
    </FlexWrapContainer>
  )
}
