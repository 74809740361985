import { theme } from '@provi/provi-components'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  withStyles,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& th.MuiTableCell-stickyHeader': {
      backgroundColor: '#FFFFFF !important',
    },
  },
  container: {
    maxHeight: 650,
  },
})

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F6F8FB',
    },
  },
}))(TableRow)

const StyledTableCell = withStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
    fontFamily: 'Montserrat, sans serif',
    fontWeight: 400,
    color: '#414141',
    fontSize: 14,
    '@media(max-width: 800px)': {
      fontSize: '12px',
    },
  },
  head: {
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #01163E',
    fontWeight: 600,
    color: theme.colors.blue900,
    fontSize: 14,
    '@media(max-width: 800px)': {
      fontSize: '12px',
    },
  },
}))(TableCell)

export {
  useStyles,
  Table,
  TableBody,
  StyledTableCell,
  TableContainer,
  TableHead,
  TableRow,
  StyledTableRow,
  Paper,
  TableFooter,
  TableCell,
}
