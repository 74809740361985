import { useState, useContext, useMemo } from 'react'
import { queryClient } from '~/stores/react-query'
import { useToggle } from '~/hooks'
import { TemplateContext } from '~/components/Template/context'
import { IntegrationsContext } from '~/screens/integrations/context'
import { useConnectAccountMutation, IntegrationsQueryKeys } from '~/screens/integrations/requests'
import { showToast } from '@provi/provi-components'

export const useAddAccount = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const { setFlowState } = useContext(IntegrationsContext)
  const [dropdownState, toggleDropdownState] = useToggle()
  const [keyError, setKeyError] = useState('')

  const extraConnectAccountMutationConfig = useMemo(
    () => ({
      onMutate: () => {
        setKeyError('')
        setIsLoading(true)
      },
      onError: (error) => {
        setIsLoading(false)
        if (error.response.data.error.message.includes('secreta')) {
          showToast(error?.response?.data?.error?.message)
          setKeyError(error?.response?.data?.error?.message)
        } else {
          showToast('Algo de errado aconteceu salvando suas informações! 😔 Por favor, tente novamente.')
        }
      },
      onSuccess: () => {
        setKeyError('')
        queryClient.invalidateQueries(IntegrationsQueryKeys.fetchMemberkitIntegrations)
        setIsLoading(false)
        showToast('Conta conectada com sucesso! 😀')
        setTimeout(() => {
          setFlowState('Memberkit')
        }, 1500)
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }),
    [],
  )

  const { mutate } = useConnectAccountMutation(extraConnectAccountMutationConfig)

  return { keyError, dropdownState, toggleDropdownState, mutate }
}
