import React from 'react'
import { Button } from '~/components/v3'
import { CloseIcon } from '~/assets/svgs/v2'
import { StyledPortalModal, ModalContainer, FlexContainer, BlueHeader, BlackParagraph, BtnFlexContainer } from './styles'

export const CoursesModal = ({
  modalRef,
  closeModalFn,
  modalObject,
  handlePrimaryBtn,
  handleSecondaryBtn,
  paragraphVariables,
  headerText,
}) => {
  const { header, paragraphText, primaryBtnText, secondaryBtnText, jsx, customHeader } = modalObject || {}
  return (
    <StyledPortalModal ref={modalRef} backdropColor={'rgba(212, 218, 247, 0.8)'}>
      <ModalContainer>
        <FlexContainer>
          <BlueHeader>{header || customHeader(headerText)}</BlueHeader>
          <CloseIcon onClick={closeModalFn} />
        </FlexContainer>
        <BlackParagraph>{paragraphText || jsx(paragraphVariables)}</BlackParagraph>
        <BtnFlexContainer>
          <Button text={secondaryBtnText} color={'white'} mobileWidth={'100%'} onClick={handleSecondaryBtn} />
          <Button text={primaryBtnText} mobileWidth={'100%'} onClick={handlePrimaryBtn} />
        </BtnFlexContainer>
      </ModalContainer>
    </StyledPortalModal>
  )
}
