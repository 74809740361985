import { useState } from 'react'
import { showToast } from '@provi/provi-components'

import { updateStatus, getGuarantorLinks, postDetailedUpdates } from '~/services/api'
import { logger } from '~/helpers/logger'

export const useStatusBox = (
  id,
  handleModal,
  handleCalcModal,
  setIsLoading,
  setCopyLinksData,
  redirect_url,
  setCalcConfig,
  handleFlexCalcModal,
  commercialTrack,
) => {
  const [option, setOption] = useState('')

  const handleOption = async (e) => {
    setOption(e.target.value)
    try {
      if (e.target.value === 'link_inactive') {
        await updateStatus(id)
      }
    } catch (error) {
      showToast('deu erro em atualizar status')
    }
  }
  const handleClickCalcModal = () => {
    setIsLoading(true)
    const getData = async () => {
      try {
        setCalcConfig({ creditRequestId: id })

        commercialTrack === 'Flex' ? handleFlexCalcModal() : handleCalcModal()

        setIsLoading(false)
      } catch (error) {
        logger({ error })
        setIsLoading(false)
        showToast(error.message || 'Não foi possível calcular o parcelamento, por favor entre em contato com a equipe da Provi')
      }
    }

    getData()
  }

  const handleCopyLinksModal = async () => {
    setIsLoading(true)
    try {
      const response = await getGuarantorLinks(id)
      const { guarantors, proviPay, incomeShare } = response.data

      setCopyLinksData({ guarantor: guarantors, link: redirect_url, proviPay, incomeShare })
      handleModal('copyLink')
    } catch (error) {
      showToast('Ocorreu um erro')
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdatesModal = async (initialStatus) => {
    if (initialStatus === '' || initialStatus === 'not_logged') return

    setIsLoading(true)
    try {
      const response = await postDetailedUpdates(id)
      response.data.id = id
      handleModal('updates', response.data, initialStatus)
    } catch (error) {
      showToast('Ocorreu um erro ao trazer o status detalhado')
    } finally {
      setIsLoading(false)
    }
  }

  return [handleOption, option, handleClickCalcModal, handleCopyLinksModal, handleUpdatesModal]
}
