/* eslint-disable react/prop-types */
import React from 'react'

import { theme } from '@provi/provi-components'
import { Modal } from '~/components/Modal'

import { LoopArrow } from '~/assets/svgs/loop-arrows'
import { Text, Br } from '~/themes/styles'
import { Button, WrapperFooter } from './styles'
import { CpfResult } from '../cpf-result'

export const CheckCpfModal = (props) => {
  const { handleModal, cpf, requests, handleCheckCpfModal, creditRule } = props
  // const [message] = useCheckCPF(requests)

  return (
    <>
      <Modal
        isWithoutHeight
        width="720px"
        radius={20}
        // title={``}
        onDismiss={() => handleModal(false, 'checkCpf')}
        footer={
          <>
            {!creditRule && creditRule !== 0 ? (
              <WrapperFooter>
                <Button
                  disabled={requests.remainingRequestsDay <= 0}
                  onClick={() => handleCheckCpfModal(requests.remainingRequestsDay, requests.remainingRequestsMonth)}
                >
                  <LoopArrow />
                  Testar pré-aprovação
                </Button>
                <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                  Tentativas restantes
                </Text>
                <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                  Hoje ({requests.totalRequestsDays}/{requests.limitRequestsDays}) |Esse mês ({requests.totalRequestsMonth}/
                  {requests.limitRequestsMonth})
                </Text>
              </WrapperFooter>
            ) : (
              <Button onClick={() => handleModal(false, 'checkCpf')}>Entendi</Button>
            )}
          </>
        }
      >
        {!creditRule && creditRule !== 0 ? (
          <>
            <Text bold size="24px" lineHeight="30px" mBottom="15px">
              Confirma o teste de pré-aprovação da solicitação na Provi?
            </Text>
            <Text bold size="20px" lineHeight="30px" mBottom="30px">
              CPF do comprador: {cpf}
            </Text>
            <Text size="16px" lineHeight="24px" color={theme.colors.blue800}>
              A pré-aprovação serve para você já adiantar se será necessário avalista para essa solicitação.
            </Text>
            <Br />
            <Text size="16px" lineHeight="24px" color={theme.colors.blue800}>
              Notas 1 e 2 normalmente não precisam de avalista, notas 3 e 4 provavelmente irão precisar.
            </Text>
          </>
        ) : (
          <>
            <Text bold size="24px" lineHeight="30px" mBottom="15px">
              Resultado da pré-aprovação
            </Text>
            <CpfResult creditRule={creditRule} />
          </>
        )}
      </Modal>
    </>
  )
}
