import React from 'react'

export const ArrowDown = ({ onClick }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 6L8 11L3 6" stroke="#2647D7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
