import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Montserrat', sans-serif;

  .colors__legends {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    list-style: none;

    li {
      padding: 15px;

      h4 {
        display: flex;
        align-items: center;

        span.colors__legends--box {
          border-radius: 2px;
          margin-right: 9px;
          width: 16px;
          height: 16px;
        }
      }

      p {
        width: 350px;
        height: 100px;
      }
    }
  }
`
