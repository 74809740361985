/**
 * @typedef RowValues
 * @type {object}
 * @property {string} CPF
 * @property {number} CreditRequestId
 * @property {string} description
 * @property {string} email
 * @property {string} expireAt
 * @property {number} id
 * @property {string} requestedAt
 * @property {string} status
 * @property {string} total
 */

/**
 * @typedef DefaultColumn
 * @type {object}
 * @property {function} gotoPage
 */

/**
 * @description Returns a function to move to details page or just a void function
 * @param {DefaultColumn} defaultColumn
 * @param {RowValues} rowValues
 * @param {boolean} isGoingToMove
 * @returns {function}
 */
function handleGoToDetailsPage(defaultColumn, rowValues, isGoingToMove) {
  const { gotoPage } = defaultColumn
  const { CreditRequestId } = rowValues

  return isGoingToMove
    ? gotoPage(`detalhes/${CreditRequestId}`)
    : () => {
        return
      }
}

export { handleGoToDetailsPage }
