import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const EachIntegrationWrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #d4daf7;

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: revert;
    `}

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const TitleText = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
