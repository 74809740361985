/* eslint-disable react/prop-types */
import React from 'react'

export const PencilV2 = ({ size = 16, stroke = '#B0AFAF', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M5.95887 17H1.70841C1.52053 17 1.34034 16.9254 1.20749 16.7925C1.07464 16.6597 1 16.4795 1 16.2916V12.3346C1 12.2415 1.01832 12.1494 1.05392 12.0635C1.08953 11.9775 1.14171 11.8994 1.20749 11.8336L11.8336 1.20749C11.9665 1.07464 12.1467 1 12.3346 1C12.5224 1 12.7026 1.07464 12.8355 1.20749L16.7925 5.16452C16.9254 5.29737 17 5.47756 17 5.66544C17 5.85332 16.9254 6.03351 16.7925 6.16636L5.95887 17Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 3.54028L14.4589 8.49915" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
