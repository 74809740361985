import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const EachDetailBox = styled.div`
  display: grid;
`

export const DetailText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  font-size: 16px;
  color: ${theme.colors.blue900};
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `}
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`

export const AutoRowGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #cfcfcf;
  grid-column: 1 / -1;
`
