import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CalendarBtnContainer = styled.div`
  display: flex;
  gap: 1rem;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 6px 16px;
  cursor: pointer;

  :hover {
    border: 1px solid #a9a9a9;
  }

  ${theme.breakpoints.down('md')} {
    gap: 0.5rem;
    padding: 6px 8px;
  }
`

export const MobileCalendarWrapper = styled.div`
  .rdrCalendarWrapper,
  .rdrDateRangeWrapper {
    width: 100%;
    border: 1px solid #cfcfcf;
  }

  .rdrMonth {
    width: 100% !important;
  }
`

export const CalendarText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

export const CalendarFixer = styled.div`
  padding: 1rem;
  display: grid;
  gap: 1rem;
  place-items: center;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;

  .rdrDateRangePickerWrapper {
    border: 1px solid #cfcfcf;
  }

  ${theme.breakpoints.down('md')} {
    padding: 0.5rem;
  }
`
