import React from 'react'

export const IconHelp = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M11 0C4.9 0 0 4.9 0 11C0 17.1 4.9 22 11 22C17.1 22 22 17.1 22 11C22 4.9 17.1 0 11 0ZM11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20Z"
      fill="#DE0C4B"
    />
    <path
      d="M12.1998 5.20009C10.0998 4.50009 7.79975 5.60009 7.09975 7.60009C6.99975 8.20009 7.19975 8.80009 7.79975 8.90009C8.29975 9.10009 8.79975 8.90009 8.99975 8.30009C9.39975 7.30009 10.4998 6.70009 11.5998 7.10009C12.3998 7.40009 12.8998 8.10009 12.8998 9.00009C12.8998 10.0001 11.1998 10.8001 10.5998 11.1001C10.4998 11.1001 10.4998 11.2001 10.3998 11.2001L10.2998 11.3001C10.2998 11.3001 10.1998 11.4001 10.1998 11.5001C10.1998 11.6001 10.0998 11.6001 10.0998 11.7001C10.0998 11.8001 10.0998 11.8001 10.0998 11.9001C10.0998 12.0001 10.0998 12.0001 10.0998 12.1001V13.1001C10.0998 13.7001 10.4998 14.1001 11.0998 14.1001C11.6998 14.1001 12.0998 13.7001 12.0998 13.1001V12.8001C13.1998 12.3001 15.0998 11.1001 15.0998 9.10009C14.8998 7.30009 13.7998 5.80009 12.1998 5.20009Z"
      fill="#DE0C4B"
    />
    <path d="M11 15C10.4 15 10 15.4 10 16C10 16.6 10.5 17 11 17C11.5 17 12 16.6 12 16C12 15.4 11.6 15 11 15Z" fill="#DE0C4B" />
  </svg>
)
