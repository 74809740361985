import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.div`
  margin-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.grey600};

  text-align: left;
  margin-bottom: 30px;
`

export const SubSection = styled.div`
  margin-top: 15px;
  padding-left: 15px;
`

export const IsaFooterSection = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IsaFooterDatesSection = styled.div`
  width: 50%;
`

export const SingleOption = styled.div`
  color: ${theme.colors.blue900};

  font-family: 'CenturyGothicBold';
  font-size: 12px;
  line-height: 24px;
`
export const DateText = styled.div`
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic';
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
`
