import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'

import { matchUrlCheckout, matchUrlPainel } from '../../helpers/matchUrl'

import { FormFormik } from './form'
import { useLogin } from './hooks'

export const Login = () => {
  const { setIsLoading, goToPage } = useContext(TemplateContext)

  useLogin(setIsLoading)

  if (matchUrlCheckout()) {
    setIsLoading(true)
    return <div />
  }

  if (matchUrlPainel()) {
    setIsLoading(true)
    return <div />
  }

  return <FormFormik setIsLoading={setIsLoading} goToPage={goToPage} />
}

// import React, { useContext } from 'react'
// import { NavigationContext } from '~/router/context'

// import { matchUrlCheckout, matchUrlPainel } from '../../helpers/matchUrl'

// import { FormFormik } from './form'
// import { useLogin } from './hooks'

// export const Login = () => {
//   const { setIsLoading, setCurrentUser } = useContext(NavigationContext)

//   useLogin(setIsLoading)

//   if (matchUrlCheckout()) {
//     setIsLoading(true)
//     return <div />
//   }

//   if (matchUrlPainel()) {
//     setIsLoading(true)
//     return <div />
//   }

//   return <FormFormik setCurrentUser={setCurrentUser} setIsLoading={setIsLoading} />
// }
