import React from 'react'
import cep from 'cep-promise'

export const useAddCampusModal = () => {
  const [showFields, setShowFields] = React.useState(false)
  const [isLoadingInput, setIsLoadingInput] = React.useState(false)

  const initialValues = {
    name: '',
    zipcode: '',
    street: '',
    state: '',
    city: '',
    district: '',
    number: '',
    complement: '',
  }

  const handleResetCep = (formikProps) => {
    formikProps.setFieldValue('street', '')
    formikProps.setFieldValue('state', '')
    formikProps.setFieldValue('city', '')
    formikProps.setFieldValue('district', '')
  }

  const handleSetAndTouchFields = (cepDataFromPromise, formikProps) => {
    formikProps.setFieldValue('street', cepDataFromPromise?.street)
    formikProps.setFieldValue('state', cepDataFromPromise?.state)
    formikProps.setFieldValue('city', cepDataFromPromise?.city)
    formikProps.setFieldValue('district', cepDataFromPromise?.neighborhood)

    formikProps.setFieldTouched('street', true)
    formikProps.setFieldTouched('state', true)
    formikProps.setFieldTouched('city', true)
    formikProps.setFieldTouched('district', true)
  }

  const handleCepChange = async (inputEventZipCode, formikProps) => {
    try {
      const { value } = inputEventZipCode.target
      formikProps.setFieldValue('zipcode', value)

      const onlyNumbersZipCode = value?.replace(/\D/g, '')

      if (onlyNumbersZipCode?.length !== 8) {
        handleResetCep(formikProps)
        setShowFields(false)
        return
      }

      setIsLoadingInput(true)
      setShowFields(true)

      const cepData = await cep(onlyNumbersZipCode)
      if (cepData) {
        handleSetAndTouchFields(cepData, formikProps)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoadingInput(false)
    }
  }

  return { initialValues, handleCepChange, showFields, setShowFields, isLoadingInput }
}
