import React from 'react'
import masks from '~/themes/masks'

export const usePaymentMethodSub = ({ isMobile, formValues, setFormValues, discountPercentage, priceWithDiscount }) => {
  const numberFromBE = 500000
  const defaultObj = { value: masks.currency(numberFromBE) || masks.currency(0), percentage: '' }
  const [valuesAndPercentages, setValuesAndPercentages] = React.useState({
    CreditCard: defaultObj,
    Boleto: defaultObj,
    CourseFinancing: defaultObj,
  })

  const flexGaps = isMobile ? '0.5rem' : '1.5rem'
  const flexAligns = !isMobile && 'center'
  const flexDirs = isMobile && 'column'

  const handleCheckBoxChange = React.useCallback(
    (string) => {
      formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === string)
        ? setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: prev.launchPricing.options.filter((eachObj) => eachObj?.paymentMethod !== string),
            },
          }))
        : setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: [
                ...prev.launchPricing.options,
                { paymentMethod: string, priceInCents: parseInt(masks.unmask(valuesAndPercentages[string].value), 10) },
              ],
            },
          }))
    },
    [valuesAndPercentages, formValues, setFormValues],
  )

  const handleMaskedInputChange = React.useCallback(
    (e) => {
      const { name, value } = e.target
      setValuesAndPercentages((prev) => ({ ...prev, [name]: { ...prev[name], value: masks.currency(value) } }))

      const calculatedDiscountPercentage = discountPercentage(numberFromBE, parseInt(masks.unmask(value), 10))

      calculatedDiscountPercentage <= 0 || calculatedDiscountPercentage >= 100
        ? setValuesAndPercentages((prev) => ({ ...prev, [name]: { ...prev[name], percentage: '' } }))
        : setValuesAndPercentages((prev) => ({ ...prev, [name]: { ...prev[name], percentage: calculatedDiscountPercentage } }))

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: prev.launchPricing.options.map((eachMapObj) =>
            eachMapObj?.paymentMethod === name
              ? { ...eachMapObj, priceInCents: parseInt(masks.unmask(value), 10) }
              : eachMapObj,
          ),
        },
      }))
    },
    [formValues, setFormValues, valuesAndPercentages, setValuesAndPercentages, discountPercentage],
  )

  const handlePercentageChange = React.useCallback(
    (e) => {
      const { name, value } = e.target
      setValuesAndPercentages((prev) => ({ ...prev, [name]: { ...prev[name], percentage: value } }))

      const calculatedPriceWithDiscount = priceWithDiscount(numberFromBE, value)

      setValuesAndPercentages((prev) => ({
        ...prev,
        [name]: { ...prev[name], value: masks.currency(calculatedPriceWithDiscount) },
      }))

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: prev.launchPricing.options.map((eachMapObj) =>
            eachMapObj?.paymentMethod === name ? { ...eachMapObj, priceInCents: calculatedPriceWithDiscount } : eachMapObj,
          ),
        },
      }))
    },
    [formValues, setFormValues, valuesAndPercentages, setValuesAndPercentages, priceWithDiscount],
  )

  return {
    valuesAndPercentages,
    flexGaps,
    flexAligns,
    flexDirs,
    handleCheckBoxChange,
    handleMaskedInputChange,
    handlePercentageChange,
  }
}
