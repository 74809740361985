import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.section`
  max-width: 1280px;
`

export const Title = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimary};
`
