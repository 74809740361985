import React from 'react'
import { InputSelector as InputSelectorStyle } from './styles'

/**
 * @name InputSelector
 * @description shared input selector
 * @param {{}} inputProps
 * @returns component rendering
 */
export const InputSelector = ({ defaultTrigger, onChange, placeholder, triggerFormat, row, cell }) => {
  return (
    <InputSelectorStyle
      defaultValue={defaultTrigger(row[cell.item])}
      placeholder={placeholder}
      options={triggerFormat(row[cell.item])}
      // options={[]}
      onChange={(e) => onChange(row, e)}
    />
  )
}
