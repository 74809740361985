import React from 'react'
import { SelectorV3 } from '@provi/provi-components'
import { ColumnGrids, TextContainer } from '../../styles'
import { BaseTypography } from '~/globalStyles'

export const LeftSideSelects = ({
  isMobile,
  useV3,
  isLoadingAllProducts,
  proviProducts,
  chosenProviProducts,
  onChangeHandlers,
  proviCourseClasses,
  chosenProviCourseClasses,
}) => {
  if (useV3) {
    return (
      <ColumnGrids>
        <TextContainer>
          <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'}>
            Produto Provi
          </BaseTypography>
          <BaseTypography fontSize={'13px'} lineHeight={'20px'}>
            Selecione curso e turma cadastrados na Provi para conectar a um produto MemberKit.
          </BaseTypography>
        </TextContainer>
        <SelectorV3
          isClearable
          className="groupSelect"
          classNamePrefix="groupSelect"
          label={'Curso'}
          placeholder={'Selecione um curso'}
          isLoading={isLoadingAllProducts}
          loadingMessage={() => 'Carregando opções'}
          options={proviProducts}
          value={chosenProviProducts}
          onChange={onChangeHandlers.handleProviProducts}
        />

        <SelectorV3
          isClearable
          className="groupSelect"
          classNamePrefix="groupSelect"
          label={'Turma'}
          placeholder={'Selecione uma turma'}
          isLoading={isLoadingAllProducts}
          loadingMessage={() => 'Carregando opções'}
          options={proviCourseClasses}
          value={chosenProviProducts && chosenProviCourseClasses}
          onChange={onChangeHandlers.handleProviCourseClasses}
          isDisabled={!chosenProviProducts}
        />
      </ColumnGrids>
    )
  }

  return (
    <SelectorV3
      isClearable
      className="groupSelect"
      classNamePrefix="groupSelect"
      label={'Produto Provi'}
      explainer={'Selecione um produto cadastrado na Provi para ser conectado a um produto da MemberKit'}
      placeholder={'Selecione um produto'}
      isLoading={isLoadingAllProducts}
      loadingMessage={() => 'Carregando opções'}
      options={proviProducts}
      value={chosenProviProducts}
      onChange={onChangeHandlers.handleProviProducts}
    />
  )
}
