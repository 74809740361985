import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Main = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

const animateFadeIn = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  margin-top: 125px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${theme.breakpoints.down('md')} {
    margin-top: 115px;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  transition: padding 0.5s;
`

export const SectionHeader = styled.div``

export const HoverCard = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  border-radius: 20px;
  opacity: 0.15;
  display: none;
`

export const Option = styled.div`
  border: 1px solid ${theme.colors.blue900};
  border-radius: 20px;
  padding: 18px;
  margin: 10px;
  width: 305px;

  animation-name: ${animateFadeIn};
  animation-duration: 1s;
  cursor: pointer;
  position: relative;

  &:hover ${HoverCard} {
    display: block;
  }
`
export const Grid = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperIcon = styled.div`
  margin-right: 5px;
`

export const RedTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.pink900};
  margin-bottom: 18px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const SelectedOption = styled.div`
  position: relative;
  display: flex;
  font-family: 'CenturyGothicBold';
  border: 1px solid ${theme.colors.blue600};
  background-color: ${theme.colors.blue600};
  color: ${theme.colors.grey400};
  border-radius: 20px;
  padding: 10px 18px;
  /* padding-right: 0; */
  margin: 10px;
  width: 300px;

  font-size: 16px;
  line-height: 24px;
  animation-name: ${animateFadeIn};
  animation-duration: 1s;
`

export const SelectedTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.grey400};
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const SelectedText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: CenturyGothicBold, sans-serif;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const SeeMore = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  margin: 20px;
  text-align: center;
  cursor: pointer;
  color: ${theme.colors.blue900};

  &:hover {
    text-decoration: underline;
  }
`

export const SectionValue = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${theme.colors.grey500};
  padding: 20px 0;
`
export const WrapperValue = styled.div`
  max-width: 500px;
  text-align: center;
`
// export const ValueTitle = styled.div`
//   font-size: 16px;
//   line-height: 24px;
//   color: ${theme.colors.grey600};
//   font-family: 'CenturyGothicBold';
// `
// export const TotalValue = styled.div`
//   font-size: 24px;
//   line-height: 30px;
//   color: ${theme.colors.blue900};
//   font-family: 'CenturyGothicBold';
// `
// export const ValueText = styled.div`
//   font-size: 12px;
//   line-height: 18px;
//   color: ${theme.colors.blue900};
//   font-family: 'CenturyGothic';
//   margin-top: 15px;
// `
// export const TextMore = styled.p`
//   margin: 0;
//   font-size: 16px;
//   line-height: 20px;
//   color: ${theme.colors.blue900};
//   font-family: CenturyGothicBold, sans-serif;
// `
// export const WarningText = styled.div`
//   font-size: 16px;
//   line-height: 24px;
//   font-family: 'CenturyGothicBold';
//   color: ${theme.colors.blue900};
// `

export const Left = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${theme.colors.white100};
  padding-left: 16px;
`

export const Right = styled.div`
  cursor: pointer;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.white100};
  position: absolute;
  right: 0;
  top: 0;
  height: 53px;
  &:hover {
    opacity: 0.7;
  }
`

export const WrapperTabs = styled.div`
  margin-top: 50px;
  justify-content: center;

  font-size: 16px;
  line-height: 24px;

  ul {
    text-align: center;
    display: flex;

    .react-tabs__tab {
      width: 50%;
      font-size: 16px !important;
      line-height: 24px !important;
      font-family: 'CenturyGothic';
      color: ${theme.colors.blue900};
    }

    .react-tabs__tab--selected {
      border: none;
      border-bottom: 5px solid ${theme.colors.pink900};
      font-family: 'CenturyGothicBold';
    }

    .react-tabs__tab:focus {
      border-bottom: 5px solid ${theme.colors.pink900};
      box-shadow: none !important;
      outline: none !important;
    }
    .react-tabs__tab:focus:after {
      position: unset;
    }
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 25px;
  }
`

export const WrapperEyeIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  left: -5px;

  &:hover {
    background-color: ${({ visible }) => (visible ? 'rgba(222, 12, 75, 0.25)' : 'rgba(255, 255, 255, 1)')};
  }
`

export const Message = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: 'CenturyGothic';
  color: ${theme.colors.blue900};
  text-align: center;
  margin-top: 25px;
`

export const Bold = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-family: 'CenturyGothicBold';
`

export const Br = styled.br``

// ---------------- WRAPPERS
export const WrapperText = styled.div`
  text-align: center;
`
export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
`

export const WrapperSearchBar = styled.div`
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    padding: 0 20px;
  }
`
export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: center;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
  }
`

export const SelectedOptions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  animation-name: ${animateFadeIn};
  animation-duration: 1s;
`
export const SectionProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 25px 0;
`
export const DownloadMore = styled.div`
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
`

export const WrapperWarning = styled.div``

export const WrapperMenuItem = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperCalc = styled.div`
  height: 30px;
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const CardBottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const WrapperMenu = styled.div`
  height: 30px;
  width: 30px;
  margin-left: 5px;
  position: relative;
`

export const WrapperCleanOptions = styled.div`
  display: flex;
  justify-content: center;
`

export const ClearOptions = styled(Text).attrs({
  size: '12px',
  color: theme.colors.blue900,
  align: 'center',
  display: 'inline-block',
  bold: true,
})`
  cursor: pointer;
  text-decoration: underline;
`
