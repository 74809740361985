import styled, { css } from 'styled-components'
import { Input } from '@material-ui/core'
import { InputMasks, theme, TextLink, Label } from '@provi/provi-components'
import { fonts } from '~/themes/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.white100}
  overflow-y: auto;
`

export const LabelTitle = styled(Label)`
  alignself: center;
  font-size: 20;
`
export const LinkText = styled(TextLink)`
  color: ${theme.colors.pink900};
  &:hover {
    cursor: pointer;
  }
`

export const DropzoneArea = styled.div`
  width: 432px;
  height: 102px;
  border-radius: 10px;
  border: 2px dashed #b6dfe7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px;
    font-size: 12px;
    font-family: ${fonts.century.bold};
    color: ${theme.colors.blue900};
  }
`

export const Wrapper = styled.div`
  margin-top: ${({ setMarginTop }) => (setMarginTop ? '30px' : 0)};
  display: flex;
  padding-top: 10px;
  max-height: 700px;
  justify-content: center;
  border-radius: 5px;
`

export const ButtonWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FormTitle = styled.h1`
  font-family: ${fonts.century.bold};
  color: ${theme.colors.blue900};
  font-size: 26px;
  margin: 40px;
  width: 428px;
  text-align: center;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
/** @type {import('styled-components').StyledComponent<"div", any, { centered: boolean }>} */
export const InputWrapper = styled.div`
  width: 432px !important;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  margin: 10px;
  p {
    color: ${theme.colors.blue900};
    font-family: ${fonts.century.bold};
    font-size: 17px;
  }
  .css-ofduzd-Placeholder {
    width: 500px;
  }
`
/** @type {import('styled-components').StyledComponent<"div", any, { shouldForceWidth: boolean }>} */
export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
  max-width: 550px;
  width: 100%;
  ${(props) =>
    props.shouldForceWidth &&
    css`
      div {
        width: 550px !important;
        max-width: 550px !important;
      }
    `}
  span {
    margin-top: 5px;
    font-size: 12px;
    color: ${theme.colors.blue900};
    font-family: ${fonts.century.regular};
  }
  p {
    margin: 0px;
    color: ${theme.colors.blue900};
    font-family: ${fonts.century.bold};
    font-size: 16px;
  }
`

TextInputWrapper.defaultProps = {
  shouldForceWidth: true,
}

/** @type {import('styled-components').StyledComponent<"input", any, { disabled: boolean }>} */
export const RegularInput = styled(Input)`
  width: 100%;
  margin-top: 10px;
  max-width: 432px;
  min-width: 432px;
`

/** @type {import('styled-components').StyledComponent<"input", any, { disabled: boolean }>} */
export const MaskedInput = styled(InputMasks).attrs((props) => ({
  inputProps: {
    disabled: props.disabled,
  },
  disabled: props.disabled,
}))`
  width: 100%;
  max-width: 432px;
  min-width: 432px;
`

export const InputError = styled.h1`
  font-family: ${fonts.century.regular};
  color: ${theme.colors.pink800};
  font-size: 14px;
  margin-top: 5px;
`

export const pink = `${theme.colors.pink900}`
