import React from 'react'
import { InputFieldV3, theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { InputContainer, TextFlex } from '../../styles'

export const MakeInactiveInput = ({ isMobile, formik }) => {
  return (
    <InputContainer>
      <TextFlex>
        <BaseTypography fontWeight={600} fontSize={isMobile ? '13px' : '16px'} lineHeight={'20px'}>
          Fechar carrinho
        </BaseTypography>
        <BaseTypography fontSize={isMobile ? '11px' : '13px'} lineHeight={'20px'}>
          (opcional)
        </BaseTypography>
      </TextFlex>
      <BaseTypography fontSize={isMobile ? '11px' : '13px'} color={theme.colors.greyPrimary}>
        Selecione a data e a hora de fechamento do carrinho, isto é, quando as vendas serão encerradas. A partir deste prazo, a
        turma se torna invisível. Alunos que realizaram a compra dentro do período de vendas serão efetivados quando o pagamento
        for confirmado, mesmo que as vendas tenham sido encerradas.
      </BaseTypography>

      <InputFieldV3
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        width="260px"
        type="datetime-local"
        name="expiresAt"
        placeholder="01/09/2021"
        value={formik.values.expiresAt}
        max="9999-12-31T00:00"
        hasError={formik.errors.expiresAt && formik.touched.expiresAt}
        errorMessage={formik.errors.expiresAt}
      />
    </InputContainer>
  )
}
