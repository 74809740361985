const initialMobile = [
  { item: 'id', label: 'ID', show: true, blocked: true },
  { item: 'status', label: 'Status atual', show: true, blocked: true },
  { item: 'hostingStatus', label: 'Acesso à hospedagem', show: true, blocked: true },
  { item: 'cpf', label: 'CPF', show: true, blocked: true },
  { item: 'productType', label: 'Método de pagamento', show: true },
  { item: 'name', label: 'Nome', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', show: true },
  { item: 'products', label: 'Produtos', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true, blocked: true },
  { item: 'installments', label: 'Parcelas contratadas', show: true },
  { item: 'installments_paid', label: 'Parcelas pagas', show: true },
  { item: 'installments_delayed', label: 'Parcelas atrasadas', show: true },
  { item: 'delayed_days', label: 'Dias de atraso', show: true },
  { item: 'released', label: 'Data de liberação do curso', show: true },
  { item: 'nextPayment', label: 'Data do próximo vencimento', show: true, blocked: true },
  { item: 'installment_value', label: 'Valor da parcela (R$)', isHelper: true, show: true, blocked: true },
  { item: 'value', label: 'Valor da venda (R$)', isHelper: true, show: true, blocked: true },
  { item: 'commission_provi', label: 'Comissão Provi', show: true, blocked: true },
  { item: 'commission_partner', label: 'Comissão Provi (%)', show: true, blocked: true },
  { item: 'total_amount_receivable', label: 'Valor a receber (R$)', show: true, blocked: true },
  { item: 'total_amount_received', label: 'Valor recebido (R$)', show: true, blocked: true },
  { item: 'tedDate', label: 'Data da transferência', show: true, blocked: true },
  { item: 'agreementDate', label: 'Data do acordo', show: true, blocked: false },
  { item: 'expectedTransferDate', label: 'Data prevista para transferência', show: true, blocked: false },
]

const initial = [
  { item: 'id', label: 'ID', show: true, blocked: true },
  {
    item: 'status',
    label: 'Status atual',
    show: true,
    blocked: true,
    showSelect: false,
    isFilter: true,
    isHelper: true,
  },
  {
    item: 'hostingStatus',
    label: 'Acesso à hospedagem',
    show: true,
    blocked: true,
  },
  {
    item: 'productType',
    label: 'Método de pagamento',
    show: true,
    isFilter: true,
    showSelect: false,
  },
  { item: 'cpf', label: 'CPF', show: true, blocked: true },
  { item: 'name', label: 'Nome', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', isHelper: true, show: true, isFilter: true, showSelect: false },
  {
    item: 'products',
    label: 'Produtos',
    show: true,
    isFilter: true,
    showSelect: false,
    isHelper: true,
  },
  {
    item: 'madeEffectiveDate',
    label: 'Data de efetivação',
    show: true,
    blocked: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  { item: 'installments', label: 'Parcelas contratadas', isHelper: true, show: true },
  { item: 'installments_paid', label: 'Parcelas pagas', isHelper: true, show: true },
  { item: 'installments_delayed', label: 'Parcelas atrasadas', isHelper: true, show: true, isFilter: true },
  { item: 'delayed_days', label: 'Dias de atraso', isHelper: true, show: true, isFilter: true, blocked: false },
  {
    item: 'released',
    label: 'Data de liberação do curso',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  {
    item: 'nextPayment',
    label: 'Data do próximo vencimento',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  {
    item: 'agreementDate',
    label: 'Data do acordo',
    show: true,
    blocked: false,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  { item: 'installment_value', label: 'Valor da parcela (R$)', show: true, isHelper: true },
  { item: 'value', label: 'Valor da venda (R$)', show: true, blocked: true },
  { item: 'commission_provi', label: 'Comissão Provi (R$)', isHelper: true, show: true, blocked: true },
  { item: 'commission_partner', label: 'Comissão Provi (%)', isHelper: true, show: true },
  { item: 'total_amount_receivable', label: 'Valor a receber (R$)', isHelper: true, show: true, blocked: true },
  { item: 'total_amount_received', label: 'Valor recebido (R$)', isHelper: true, show: true, blocked: true },
  {
    item: 'expectedTransferDate',
    label: 'Data prevista para transferência',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
  {
    item: 'tedDate',
    label: 'Data da transferência',
    show: true,
    isHelper: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
]

const mobileArrWithOrigin = initialMobile.map((eachObj) => ({ ...eachObj, tableOrigin: 'Controlar recebimentos' }))
const initialArrWithOrigin = initial.map((eachObj) => ({ ...eachObj, tableOrigin: 'Controlar recebimentos' }))

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return mobileArrWithOrigin
  }

  return initialArrWithOrigin
}
