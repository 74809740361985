/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { TextLink, theme, showToast } from '@provi/provi-components'

import { getPartnerEmployees, updateUser, getListUpdates, getAccess } from '~/services/api'
import { Text } from '~/themes/styles'

import { Pencil } from '~/assets/svgs/pencil'
import { Trash } from '~/assets/svgs/trash'
import { asyncLocalStorage, capitalize, formatDate } from '~/utils'
import { controlPermissions } from '../helpers/controlPermissions'
import { getHeadersForListUsers } from '../helpers/getHeadersForListUsers'
import { CheckBox, Active, WrapperOrigin, WrapperIcon, ButtonOutline, BoxButton, WrapperTextLink } from './styles'

export const useListUsers = (setIsLoading, isMobile, goToPage, activePage, setActivePage) => {
  const [showCells, setShowCells] = useState([])
  const [rowsActive, setRowsActive] = useState([])
  const [rowsDeactive, setRowsDeactive] = useState([])
  const [warningMessage, setWarningMessage] = useState('')
  const [visibleOpt, setVisibleOpt] = useState({ tabIndex: 0 })
  const [updateTable, setUpdateTable] = useState(false)
  const [showInativateUserModal, setShowInativateUserModal] = useState(false)
  const [partnerEmployeeEmail, setPartnerEmployeeEmail] = useState(``)
  const [showListUpdatesModal, setShowListUpdatesModal] = useState(false)
  const [listUpdates, setListUpdates] = useState([])

  const handleModal = async (option, location) => {
    if (location === 'inativate') {
      setShowInativateUserModal(option)
      setIsLoading(false)
    } else if (location === 'list-updates') {
      if (option === true) {
        const partnerEmployeeId = await asyncLocalStorage.getItem('partnerEmployeeId')
        const response = await getListUpdates({ partnerEmployeeId })
        const { response: listUpdatesEmployee, error } = response.data
        if (error) {
          showToast(`Error ao tentar visualizar o histórico de alteração do usuário`)
        } else {
          console.log(listUpdatesEmployee)
          setListUpdates([...listUpdatesEmployee])
          setIsLoading(false)
        }
        setIsLoading(false)
      }
      setShowListUpdatesModal(option)
    }
  }

  const handleClick = async (option, email, id, active) => {
    setIsLoading(true)
    if (!option) return
    await asyncLocalStorage.setItem(`emailPartnerEmployee`, email)
    await asyncLocalStorage.setItem(`partnerEmployeeId`, id)
    if (option === 'inativate') {
      setIsLoading(false)
      setPartnerEmployeeEmail(email)
      setShowInativateUserModal(true)
    } else if (option === 'edit') {
      setIsLoading(false)
      setActivePage(`user-control`)
      goToPage('usuario/editar-usuario')
    } else if (option === 'reactivate') {
      const response = await updateUser({ partnerEmployeeId: id, active: true })
      const { error } = response.data
      if (error) {
        showToast(`Error tentando reativar o usuário`)
      } else {
        setUpdateTable(true)
        setIsLoading(false)
        showToast(`Usuário reativado com sucesso`)
      }
      setIsLoading(false)
    } else if (option === 'list-updates') {
      setPartnerEmployeeEmail(email)
      handleModal(true, 'list-updates')
    }
  }

  const checkAccess = async () => {
    try {
      const { data: requestData } = await getAccess()
      const { accessLevel } = requestData.data
      let initialState = getHeadersForListUsers({ isMobile: isMobile, accessLevel: accessLevel })
      setShowCells(initialState)
    } catch (error) {}
  }

  const generateTables = async () => {
    setIsLoading(true)
    if (activePage !== 'user-control') setActivePage('user-control')
    const getData = async () => {
      try {
        setWarningMessage('')
        const response = await getPartnerEmployees()
        if (response) {
          if (!response.data.users.length > 0) {
            setWarningMessage('Ainda não existem usuários cadastrados')
          }
          const rowsActiveResponse = response.data.users
            .filter((user) => user.active)
            .map((user) => {
              const { email, accessLevel, id, active, updatedAt } = user
              const salesManagement = controlPermissions[accessLevel].salesManagement
              const createAndEditCampaing = controlPermissions[accessLevel].createAndEditCampaing
              const salesVisualization = controlPermissions[accessLevel].salesVisualization
              const monitoringDashboard = controlPermissions[accessLevel].monitoringDashboard
              const acessManagement = controlPermissions[accessLevel].acessManagement
              const receivesControl = controlPermissions[accessLevel].receivesControl
              const editHistory = updatedAt
              return {
                id,
                email,
                active,
                accessLevel: (
                  <Text bold align="center" color={theme.colors.blue900} size="16px">
                    {capitalize(accessLevel) || '---'}
                  </Text>
                ),
                userEmail: (
                  <Text align="center" color={theme.colors.blue900} size="16px">
                    {email || '---'}
                  </Text>
                ),
                salesManagement: (
                  <WrapperOrigin>
                    <CheckBox>{salesManagement && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                createAndEditCampaing: (
                  <WrapperOrigin>
                    <CheckBox>{createAndEditCampaing && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                salesVisualization: (
                  <WrapperOrigin>
                    <CheckBox>{salesVisualization && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                monitoringDashboard: (
                  <WrapperOrigin>
                    <CheckBox>{monitoringDashboard && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                acessManagement: (
                  <WrapperOrigin>
                    <CheckBox>{acessManagement && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                receivesControl: (
                  <WrapperOrigin>
                    <CheckBox>{receivesControl && <Active />}</CheckBox>
                  </WrapperOrigin>
                ),
                editHistory: (
                  <WrapperTextLink>
                    <TextLink
                      text={formatDate(editHistory, true) || '---'}
                      color={theme.colors.white100}
                      onClick={() => handleClick(`list-updates`, email, id, active)}
                    />
                  </WrapperTextLink>
                ),
                editColumn: (
                  <WrapperIcon>
                    <BoxButton
                      active={activePage === 'user-control'}
                      onClick={() => handleClick(`inativate`, email, id, active)}
                    >
                      <Trash />
                    </BoxButton>
                    <BoxButton active={activePage === 'user-control'} onClick={() => handleClick(`edit`, email, id, active)}>
                      <Pencil />
                    </BoxButton>
                  </WrapperIcon>
                ),
              }
            })
          const rowsDeactiveResponse = response.data.users
            .filter((user) => !user.active)
            .map((user) => {
              const { email, accessLevel, id, active, updatedAt } = user
              const salesManagement = controlPermissions[accessLevel].salesManagement
              const createAndEditCampaing = controlPermissions[accessLevel].createAndEditCampaing
              const salesVisualization = controlPermissions[accessLevel].salesVisualization
              const monitoringDashboard = controlPermissions[accessLevel].monitoringDashboard
              const acessManagement = controlPermissions[accessLevel].acessManagement
              const receivesControl = controlPermissions[accessLevel].receivesControl
              const editHistory = updatedAt
              return {
                id,
                email,
                active,
                accessLevel: (
                  <Text bold align="center" color={theme.colors.grey600} size="16px">
                    {capitalize(accessLevel) || '---'}
                  </Text>
                ),
                userEmail: (
                  <Text align="center" color={theme.colors.grey600} size="16px">
                    {email || '---'}
                  </Text>
                ),
                salesManagement: (
                  <WrapperOrigin>
                    <CheckBox isActivated> {salesManagement && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                createAndEditCampaing: (
                  <WrapperOrigin>
                    <CheckBox isActivated>{createAndEditCampaing && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                salesVisualization: (
                  <WrapperOrigin>
                    <CheckBox isActivated>{salesVisualization && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                monitoringDashboard: (
                  <WrapperOrigin>
                    <CheckBox isActivated>{monitoringDashboard && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                acessManagement: (
                  <WrapperOrigin>
                    <CheckBox isActivated>{acessManagement && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                receivesControl: (
                  <WrapperOrigin>
                    <CheckBox isActivated>{receivesControl && <Active isActivated />}</CheckBox>
                  </WrapperOrigin>
                ),
                editHistory: (
                  <Text align="center" color={theme.colors.grey600} size="16px">
                    {formatDate(editHistory, true) || '---'}
                  </Text>
                ),
                editColumn:
                  <ButtonOutline onClick={() => handleClick('reactivate', email, id, active)}>Reativar</ButtonOutline> || '---',
              }
            })
          console.log('render')
          setRowsActive([...rowsActiveResponse])
          setRowsDeactive([...rowsDeactiveResponse])
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error)
        setWarningMessage('Ops, ocorreu um erro. Atualize essa página e entre em contato com a Provi se o erro permanecer.')
        setIsLoading(false)
      }
    }
    getData()
  }

  useEffect(() => {
    checkAccess()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    generateTables()
    setUpdateTable(false)
  }, [updateTable])

  const submitInativateUser = async () => {
    setIsLoading(true)
    const partnerEmployeeId = await asyncLocalStorage.getItem('partnerEmployeeId')
    const response = await updateUser({ partnerEmployeeId, active: false })
    const { error } = response.data
    if (error) {
      showToast(`Error ao tentar inativar um usuário`)
    }
    setUpdateTable(true)
    setShowInativateUserModal(false)
    setIsLoading(false)
    showToast(`Usuário inativado com sucesso`)
  }

  useEffect(() => {
    handleModal(showListUpdatesModal, 'list-updates')
  }, [showListUpdatesModal])

  return [
    showCells,
    setRowsActive,
    rowsActive,
    setRowsDeactive,
    rowsDeactive,
    warningMessage,
    visibleOpt,
    setVisibleOpt,
    handleModal,
    showInativateUserModal,
    showListUpdatesModal,
    partnerEmployeeEmail,
    listUpdates,
    submitInativateUser,
  ]
}
