import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseGrid } from '~/globalStyles'

export const FinancingConditionsContainer = styled(BaseGrid)`
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  display: grid;
  padding: 2rem;
  border: 2px solid #d4daf7;
  border-radius: 8px;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`

export const TextGrids = styled(BaseGrid)`
  gap: ${({ gap }) => gap || '0.25rem'};
`
