import React from 'react'
import { SelectorV3 as Select } from '@provi/provi-components'
import { FormatClass, InputGroup, InputGroupRadio } from '../../styles'
import { FormikProps } from 'formik'
import { IInitialValues } from '../../hooks'

interface ICampus {
  value: number
  label: string
}

interface IProps {
  formik: FormikProps<IInitialValues>
  handleChangeClassType: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeClassPeriod: (e: React.ChangeEvent<HTMLInputElement>) => void
  isMobile: boolean
  campi: Array<ICampus>
  openAddCampusModal: () => void
}

function ClassTypeInput({
  formik,
  handleChangeClassType,
  handleChangeClassPeriod,
  isMobile,
  campi,
  openAddCampusModal,
}: IProps) {
  return (
    <>
      <InputGroup>
        <label htmlFor="classType">Modalidade de ensino</label>
        <InputGroupRadio>
          <div>
            <input
              type="radio"
              name="classType"
              value="classTypeOnline"
              onChange={handleChangeClassType}
              checked={formik.values.modalityId === 1}
            />
            <label htmlFor="classType">Online</label>
          </div>
          {formik.values.modalityId === 1 && (
            <div className="input__format">
              <label htmlFor="formatClass">Formato da aula</label>
              <InputGroup className={`${isMobile && 'format__class'}`}>
                <FormatClass
                  isSelected={formik.values.attendTypeId === 1}
                  type="button"
                  onClick={() => formik.setFieldValue('attendTypeId', 1)}
                >
                  Ao vivo
                </FormatClass>
                <FormatClass
                  isSelected={formik.values.attendTypeId === 2}
                  type="button"
                  onClick={() => formik.setFieldValue('attendTypeId', 2)}
                >
                  Gravada
                </FormatClass>
                <FormatClass
                  isSelected={formik.values.attendTypeId === 3}
                  type="button"
                  onClick={() => formik.setFieldValue('attendTypeId', 3)}
                >
                  Misto
                </FormatClass>
              </InputGroup>
            </div>
          )}
        </InputGroupRadio>
        <InputGroupRadio>
          <div>
            <input
              type="radio"
              name="classType"
              value="classTypeFaceToFace"
              onChange={handleChangeClassType}
              checked={formik.values.modalityId === 3}
            />
            <label htmlFor="">Presencial</label>
          </div>
          {formik.values.modalityId === 3 && (
            <div className="class__location">
              <Select
                onChange={(option: { label: string; value: number }) => formik.setFieldValue('campusId', option.value)}
                placeholder="Selecione uma unidade"
                value={campi && formik.values.campusId ? campi.find((item) => item.value === formik.values.campusId) : ''}
                options={campi}
              />
              <p className="class__location--add" onClick={openAddCampusModal}>
                Cadastrar nova unidade
              </p>
            </div>
          )}
        </InputGroupRadio>
        <InputGroupRadio>
          <div>
            <input
              type="radio"
              name="classType"
              value="classTypeMixed"
              checked={formik.values.modalityId === 2}
              onChange={handleChangeClassType}
            />
            <label htmlFor="">Híbrido</label>
          </div>
          {formik.values.modalityId === 2 && (
            <>
              <div className="input__format">
                <label htmlFor="formatClass">Formato da aula</label>
                <InputGroup className={`${isMobile && 'format__class'}`}>
                  <FormatClass
                    isSelected={formik.values.attendTypeId === 1}
                    type="button"
                    onClick={() => formik.setFieldValue('attendTypeId', 1)}
                  >
                    Ao vivo
                  </FormatClass>
                  <FormatClass
                    isSelected={formik.values.attendTypeId === 2}
                    type="button"
                    onClick={() => formik.setFieldValue('attendTypeId', 2)}
                  >
                    Gravada
                  </FormatClass>
                  <FormatClass
                    isSelected={formik.values.attendTypeId === 3}
                    type="button"
                    onClick={() => formik.setFieldValue('attendTypeId', 3)}
                  >
                    Misto
                  </FormatClass>
                </InputGroup>
              </div>
              <div className="class__location">
                <Select
                  onChange={(option: { label: string; value: number }) => formik.setFieldValue('campusId', option.value)}
                  placeholder="Selecione uma unidade"
                  value={campi && formik.values.campusId ? campi.find((item) => item.value === formik.values.campusId) : ''}
                  options={campi}
                />
                <p className="class__location--add" onClick={openAddCampusModal}>
                  Cadastrar nova unidade
                </p>
              </div>
            </>
          )}
        </InputGroupRadio>
      </InputGroup>

      {formik.values.modalityId === 2 || formik.values.modalityId === 3 ? (
        <InputGroup>
          <label htmlFor="classroomPeriod">Período</label>
          <InputGroupRadio>
            <div>
              <input
                type="radio"
                name="classroomPeriod"
                value={'halfDay'}
                checked={formik.values.classPeriodId === 1}
                onChange={handleChangeClassPeriod}
              />
              <label htmlFor="classroomPeriod">Meio Período</label>
            </div>
            {formik.values.classPeriodId === 1 && (
              <div className={`classroom__period--div ${isMobile && 'format__class'}`}>
                <FormatClass
                  isSelected={formik.values.classTimeId === 1}
                  type="button"
                  onClick={() => formik.setFieldValue('classTimeId', 1)}
                >
                  Manhã
                </FormatClass>
                <FormatClass
                  isSelected={formik.values.classTimeId === 2}
                  type="button"
                  onClick={() => formik.setFieldValue('classTimeId', 2)}
                >
                  Tarde
                </FormatClass>
                <FormatClass
                  isSelected={formik.values.classTimeId === 3}
                  type="button"
                  onClick={() => formik.setFieldValue('classTimeId', 3)}
                >
                  Noite
                </FormatClass>
              </div>
            )}
          </InputGroupRadio>
          <InputGroupRadio>
            <input
              type="radio"
              name="classroomPeriod"
              value={'fullDay'}
              checked={formik.values.classPeriodId === 2}
              onChange={handleChangeClassPeriod}
            />
            <label htmlFor="classroomPeriod">Tempo Integral</label>
          </InputGroupRadio>
        </InputGroup>
      ) : null}
    </>
  )
}

export { ClassTypeInput }
