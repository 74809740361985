import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperItens = styled.div``

/** @type {import("styled-components").StyledComponent<"div", any, { disabled: boolean }>} */
export const Text = styled.div`
  user-select: none;
`

export const Item = styled.div`
  display: flex;
  height: 30px;
  margin-top: 16px;
  align-items: center;
  font-family: 'CenturyGothicBold';
  cursor: ${({ blocked }) => (blocked ? 'default' : 'pointer')};
  user-select: none;

  ${Text} {
    color: ${({ blocked, disabled }) => {
      if (blocked) return theme.colors.pink900
      if (disabled) return theme.colors.grey600
      return theme.colors.blue900
    }};
  }

  &:hover {
    background-color: ${({ blocked }) => (!blocked ? 'rgba(0, 0, 0, 0.05);' : 'unset')};
  }
`

export const WrapperIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-right: 15px;
`
