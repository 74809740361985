import React from 'react'
import masks from '~/themes/masks'

export const useUniquePriceSub = ({ formValues, setFormValues, discountPercentage, priceWithDiscount }) => {
  const numberFromBE = 500000
  const [maskedValue, setMaskedValue] = React.useState(masks.currency(numberFromBE) || masks.currency(0))
  const [percentage, setPercentage] = React.useState('')

  const handleCheckBoxChange = React.useCallback(
    (string) => {
      formValues?.launchPricing?.options[0].uniquePaymentMethods?.includes(string)
        ? setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: [
                {
                  ...prev.launchPricing.options[0],
                  uniquePaymentMethods: prev.launchPricing.options[0].uniquePaymentMethods.filter(
                    (eachString) => eachString !== string,
                  ),
                },
              ],
            },
          }))
        : setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: [
                {
                  ...prev.launchPricing.options[0],
                  uniquePaymentMethods: [...prev.launchPricing.options[0].uniquePaymentMethods, string],
                },
              ],
            },
          }))
    },
    [formValues, setFormValues],
  )

  const handleMaskedInputChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setMaskedValue(masks.currency(value))

      const calculatedDiscountPercentage = discountPercentage(numberFromBE, parseInt(masks.unmask(value), 10))

      calculatedDiscountPercentage <= 0 || calculatedDiscountPercentage >= 100
        ? setPercentage('')
        : setPercentage(calculatedDiscountPercentage)

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: [
            {
              ...prev.launchPricing.options[0],
              uniquePriceInCents: parseInt(masks.unmask(value), 10),
            },
          ],
        },
      }))
    },
    [formValues, setFormValues, maskedValue, setMaskedValue, percentage, setPercentage, discountPercentage],
  )

  const handlePercentageChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setPercentage(value)

      const calculatedPriceWithDiscount = priceWithDiscount(numberFromBE, value)

      setMaskedValue(masks.currency(calculatedPriceWithDiscount))

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: [
            {
              ...prev.launchPricing.options[0],
              uniquePriceInCents: calculatedPriceWithDiscount,
            },
          ],
        },
      }))
    },
    [formValues, setFormValues, maskedValue, setMaskedValue, percentage, setPercentage, priceWithDiscount],
  )

  return {
    maskedValue,
    setMaskedValue,
    percentage,
    setPercentage,
    handleCheckBoxChange,
    handleMaskedInputChange,
    handlePercentageChange,
  }
}
