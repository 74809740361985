import React, { useContext } from 'react'
import { Container, LeftColumn, MidColumn, RightColumn, Content } from './styles'
import { TemplateContext } from '~/components/Template/context'
import { Text } from '~themes/styles'
import PrincipiaPay from '../../../../components/principiaPay'
export const NewPartnerCard = () => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <>
      <Container>
        <Content>
          <LeftColumn>
            <Text
              color="white"
              size={isMobile ? '15px' : '2rem'}
              lineHeight={isMobile ? '24px' : '38px'}
              align={isMobile ? 'center' : 'start'}
              fontFamily="Monteserrat-SemiBold">
              Estamos
              mudando para
              melhor!
            </Text>
          </LeftColumn>
          <MidColumn>
            <Text
              color="white"
              align="center"
              size={isMobile ? '12px' : '16px'}
              lineHeight={isMobile ? '20px' : '26px'}
              fontFamily="Monteserrat-SemiBold"
            >
              Provi agora é PrincipiaPay! Sua interação com nossas soluções financeiras ainda mais aprimorada. Acesse já o seu novo painel PrincipiaPay.
            </Text>
          </MidColumn>
        </Content>

        <RightColumn>
          <PrincipiaPay />
        </RightColumn>
      </Container>
    </>
  )
}
