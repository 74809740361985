import React from 'react'
import { Circle } from './styles'

export const StatusCircle = ({ status }) => {
  return (
    <>
      <Circle color={status && status.color} />
    </>
  )
}
