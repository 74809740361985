import styled from 'styled-components'

export const SpanContainer = styled.span`
  float: right;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: right;
  color: #575757;
`
