import React, { useState, useRef, useEffect } from 'react'

import { DashboardArrowIcon } from '~/assets/svgs/dashboard-arrow-icon'
import { BlueHeader, FlexContainer, SvgWrapper } from './styles'

export const DashboardCollapse = ({ headerText, subComponent, styleCollapseDiv = {} }) => {
  const [expanded, setExpanded] = useState(false)
  const componentScrollRef = useRef(null)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const scrollToComponent = () => {
    componentScrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  useEffect(() => {
    expanded && scrollToComponent()
  }, [expanded])

  return (
    <div style={styleCollapseDiv}>
      <FlexContainer>
        <BlueHeader>{headerText}</BlueHeader>
        <SvgWrapper onClick={handleExpandClick} state={expanded}>
          <DashboardArrowIcon />
        </SvgWrapper>
      </FlexContainer>

      {expanded && (
        <>
          <>{subComponent}</>
          <div ref={componentScrollRef} />
        </>
      )}
    </div>
  )
}
