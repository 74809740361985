/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react'
import { isArray } from 'lodash'

import { Button, theme, TextLink } from '@provi/provi-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { Title } from '~/components/title'
import { SubTitle } from '~/components/sub-title'
import { Search } from '~/components/search'
import { CalcModalV2 } from '~/components/modals/calc-modal-v2'

import { Text, Br } from '~/themes/styles'

import { ArrowMore } from '~/assets/svgs/arrow-more'
import { EyeIconWithBorder } from '~/assets/svgs/eye'
import { PlusIconWithBorder } from '~/assets/svgs/plus'

import { Modal } from './modal'
import { useProduct } from './hooks'
import { capitalize } from '~/utils'
import { BlueCheck } from '~/assets/svgs/blue-check'
import { DuplicateIcon } from '~/assets/svgs/Copy'
import { Calculator } from '~/assets/svgs/calc'

import { CloseSelectedCourse } from '~/assets/svgs/close-selected-course'
import { access } from './access-control'
import { usePermissions } from '~/hooks'

import {
  Container,
  Content,
  SectionHeader,
  WrapperText,
  WrapperSearchBar,
  SectionProducts,
  Option,
  Grid,
  RedTitle,
  Bold,
  WrapperIcon,
  SelectedOptions,
  SelectedOption,
  SelectedTitle,
  WrapperValue,
  SectionValue,
  HoverCard,
  WrapperButton,
  WrapperTextLink,
  DownloadMore,
  Left,
  Right,
  WrapperTabs,
  WrapperEyeIcon,
  WrapperMenuItem,
  WrapperClose,
  CardBottomSection,
  WrapperCalc,
  WrapperMenu,
  ClearOptions,
  WrapperCleanOptions,
} from './styles'

export const Product = () => {
  const {
    setIsLoading,
    setCurrent,
    handleLogoutModal,
    setSelectedOptions,
    selectedOptions,
    setValue,
    value,
    goToPage,
    activePage,
    setActivePage,
    isMobile,
  } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const { canCreateNewCrids } = usePermissions()

  const {
    handleSearchValue,
    search,
    handleOption,
    revertOption,
    incrementNumberOfCourses,
    showSeeMoreVisible,
    showSeeMoreInvisible,
    handleSubmit,
    handleEye,
    isShowModal,
    handleModal,
    location,
    submitHandleEye,
    submitDisableProduct,
    optionChange,
    formatValue,
    visibleOpt,
    setVisibleOpt,
    userAccess,
    handleClick,
    handleClose,
    anchorEl,
    archorElRef,
    handleDuplicateCourse,
    handleDisableProduct,
    handleCalcClick,
    isShowCalcModal,
    handleCalcModal,
    calcConfig,
    loginUrl,
    handleClearOptions,
    visibleOptions,
    invisibleOptions,
    sliceOptions,
  } = useProduct(
    setIsLoading,
    setCurrent,
    setSelectedOptions,
    selectedOptions,
    setValue,
    value,
    goToPage,
    activePage,
    setActivePage,
  )

  return (
    <>
      {userAccess && (
        <>
          {isShowCalcModal && <CalcModalV2 handleModal={handleCalcModal} show={isShowCalcModal} calcConfig={calcConfig} />}
          {isShowModal && location && (
            <Modal
              handleModal={handleModal}
              location={location}
              optionChange={optionChange}
              submitHandleEye={submitHandleEye}
              submitDisableProduct={submitDisableProduct}
            />
          )}
          <Container>
            <Content isMobile={isMobile} drawerWidth={drawerWidth}>
              <SectionHeader>
                <WrapperText>
                  <Title text="Selecione o(s) produtos(s)" />
                  <SubTitle text="Escolha abaixo os produtos que farão parte desta venda:" />
                </WrapperText>
                <WrapperSearchBar>
                  <Search type="text" placeholder="Pesquise aqui" onChange={handleSearchValue} value={search} />
                </WrapperSearchBar>
                {access.createProduct[userAccess] && (
                  <WrapperTextLink>
                    <TextLink onClick={() => goToPage('checkout/criar-curso')} text="Criar novo produto" />
                  </WrapperTextLink>
                )}
              </SectionHeader>

              {value !== 0 && value && (
                <SectionValue>
                  <WrapperValue>
                    <Text tag="totalvalue" size="16px" lineHeight="24px" color={theme.colors.grey600}>
                      Valor total
                    </Text>

                    <Text tag="value" size="24px" lineHeight="30px" color={theme.colors.blue900} bold>
                      {formatValue(value)}*
                    </Text>

                    <Text tag="textvalue" size="12px" lineHeight="18px" color={theme.colors.blue900} mTop="15px">
                      *Esse valor total é uma soma do valor cheio dos produtos selecionados. Você ainda vai poder aplicar
                      descontos na próxima tela.
                    </Text>
                  </WrapperValue>
                </SectionValue>
              )}

              {selectedOptions && isArray(selectedOptions) && (
                <SelectedOptions>
                  {selectedOptions.map((selectedOption, index) => (
                    <SelectedOption key={index}>
                      <Left>
                        <SelectedTitle>{selectedOption.name}</SelectedTitle>
                        <Text
                          size="16px"
                          lineHeight="20px"
                          bold
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          color={theme.colors.white100}
                        >
                          {capitalize(selectedOption.modality)} |{' '}
                          {selectedOption.formattedValue || formatValue(selectedOption.price_in_cents)}
                        </Text>
                      </Left>
                      <Right onClick={() => revertOption(selectedOption)}>
                        <CloseSelectedCourse />
                      </Right>
                    </SelectedOption>
                  ))}
                </SelectedOptions>
              )}
              <WrapperTabs id="wrapper-tabs">
                {(!!value || (selectedOptions && selectedOptions.length > 0)) && (
                  <WrapperCleanOptions>
                    <ClearOptions onClick={handleClearOptions}>Limpar cursos selecionados</ClearOptions>
                  </WrapperCleanOptions>
                )}
                <Tabs selectedIndex={visibleOpt.tabIndex} onSelect={(tabIndex) => setVisibleOpt({ tabIndex })}>
                  <TabList>
                    <Tab>Produtos visíveis</Tab>
                    <Tab>Produtos invisíveis</Tab>
                  </TabList>

                  <TabPanel>
                    <>
                      <Text size="14px" lineHeight="20px" color={theme.colors.blue900} align="center" mTop="25px">
                        <Bold>Produtos visíveis</Bold> são listados no link geral{' '}
                        {loginUrl ? (
                          <Text display="inline" color={theme.colors.blue600} bold>
                            ({loginUrl})
                          </Text>
                        ) : (
                          'da Provi'
                        )}{' '}
                        e podem ser
                        <Bold> abertamente comercializados pelo preço cadastrado</Bold> no produto.
                        <Br />
                        <Br />
                        <Bold>Produtos invisíveis</Bold> não são listados no site da Provi e são comercializados{' '}
                        <Bold>apenas se o vendedor criar uma venda com esse produto selecionado.</Bold>
                      </Text>
                      <SectionProducts>
                        {visibleOptions &&
                          isArray(visibleOptions) &&
                          sliceOptions(visibleOptions).map((option, idx) => (
                            <div key={idx}>
                              <Option onClick={() => handleOption(option)}>
                                <HoverCard />
                                {option.name && (
                                  <Grid>
                                    <RedTitle>{option.name}</RedTitle>
                                  </Grid>
                                )}
                                {option.price_in_cents && (
                                  <Grid>
                                    <WrapperIcon>
                                      <BlueCheck />
                                    </WrapperIcon>
                                    <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                      {option.formattedValue || formatValue(option.price_in_cents)}
                                    </Text>
                                  </Grid>
                                )}
                                {option &&
                                  option.details &&
                                  isArray(option.details) &&
                                  option.details.map((od, index) => (
                                    <Grid key={index}>
                                      <WrapperIcon>
                                        <BlueCheck />
                                      </WrapperIcon>
                                      <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                        {od}
                                      </Text>
                                    </Grid>
                                  ))}

                                <CardBottomSection>
                                  <WrapperCalc onClick={(e) => handleCalcClick(e, option)}>
                                    <Calculator />
                                  </WrapperCalc>
                                  <WrapperMenu>
                                    {access.changeToInVisible[userAccess] && (
                                      <>
                                        <WrapperEyeIcon visible onClick={(e) => handleClick(e, idx)}>
                                          <PlusIconWithBorder />
                                        </WrapperEyeIcon>

                                        <Menu
                                          id="simple-menu"
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl) && archorElRef === idx}
                                          onClose={handleClose}
                                        >
                                          <MenuItem onClick={(e) => handleDuplicateCourse(e, option, true)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <DuplicateIcon />
                                              </WrapperIcon>
                                              Duplicar e editar
                                            </WrapperMenuItem>
                                          </MenuItem>
                                          <MenuItem onClick={(e) => handleEye(e, 'visible', option)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <EyeIconWithBorder active />
                                              </WrapperIcon>
                                              Tornar Invisivel
                                            </WrapperMenuItem>
                                          </MenuItem>
                                          <MenuItem onClick={(e) => handleDisableProduct(e, option)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <WrapperClose>
                                                  <CloseIcon style={{ fontSize: 19, color: theme.colors.pink900 }} />
                                                </WrapperClose>
                                              </WrapperIcon>
                                              Desativar produto
                                            </WrapperMenuItem>
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}
                                  </WrapperMenu>
                                </CardBottomSection>
                              </Option>
                            </div>
                          ))}

                        {visibleOptions && visibleOptions.length === 0 ? (
                          search ? (
                            <>
                              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                                Não foi encontrada nenhum curso visível para: {search}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                                Não foi encontrada nenhum curso visível
                              </Text>
                            </>
                          )
                        ) : (
                          ''
                        )}
                      </SectionProducts>
                      {showSeeMoreVisible && (
                        <DownloadMore onClick={incrementNumberOfCourses}>
                          <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                            VER MAIS
                          </Text>
                          <ArrowMore />
                        </DownloadMore>
                      )}
                    </>
                  </TabPanel>
                  <TabPanel>
                    <>
                      <Text size="14px" lineHeight="20px" color={theme.colors.blue900} align="center" mTop="25px">
                        <Bold>Produtos visíveis</Bold> são listados no link geral{' '}
                        {loginUrl ? (
                          <Text display="inline" color={theme.colors.blue600} bold>
                            ({loginUrl})
                          </Text>
                        ) : (
                          'da Provi'
                        )}{' '}
                        e podem ser
                        <Bold> abertamente comercializados pelo preço cadastrado</Bold> no produto.
                        <Br />
                        <Br />
                        <Bold>Produtos invisíveis</Bold> não são listados no site da Provi e são comercializados{' '}
                        <Bold>apenas se o vendedor criar uma venda com esse produto selecionado.</Bold>
                      </Text>
                      <SectionProducts>
                        {invisibleOptions &&
                          isArray(invisibleOptions) &&
                          sliceOptions(invisibleOptions).map((option, idx) => (
                            <div key={idx}>
                              <Option onClick={() => handleOption(option, 'i')}>
                                <HoverCard />
                                {option.name && (
                                  <Grid>
                                    <RedTitle>{option.name}</RedTitle>
                                  </Grid>
                                )}
                                {option.price_in_cents && (
                                  <Grid>
                                    <WrapperIcon>
                                      <BlueCheck />
                                    </WrapperIcon>
                                    <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                      {option.formattedValue}
                                    </Text>
                                  </Grid>
                                )}
                                {option.details &&
                                  isArray(option.details) &&
                                  option.details.map((od, index) => (
                                    <Grid key={index}>
                                      <WrapperIcon>
                                        <BlueCheck />
                                      </WrapperIcon>
                                      <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                        {od}
                                      </Text>
                                    </Grid>
                                  ))}

                                <CardBottomSection>
                                  <WrapperCalc onClick={(e) => handleCalcClick(e, option)}>
                                    <Calculator />
                                  </WrapperCalc>
                                  <WrapperMenu>
                                    {access.changeToVisible[userAccess] && (
                                      <>
                                        <WrapperEyeIcon visible onClick={(e) => handleClick(e, idx)}>
                                          <PlusIconWithBorder />
                                        </WrapperEyeIcon>
                                        <Menu
                                          id="simple-menu"
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl) && archorElRef === idx}
                                          onClose={handleClose}
                                        >
                                          <MenuItem onClick={(e) => handleDuplicateCourse(e, option, false)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <DuplicateIcon />
                                              </WrapperIcon>
                                              Duplicar e editar
                                            </WrapperMenuItem>
                                          </MenuItem>
                                          <MenuItem onClick={(e) => handleEye(e, 'invisible', option)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <EyeIconWithBorder />
                                              </WrapperIcon>
                                              Tornar Visivel
                                            </WrapperMenuItem>
                                          </MenuItem>

                                          <MenuItem onClick={(e) => handleDisableProduct(e, option)}>
                                            <WrapperMenuItem>
                                              <WrapperIcon flex>
                                                <WrapperClose>
                                                  <CloseIcon style={{ fontSize: 19, color: theme.colors.pink900 }} />
                                                </WrapperClose>
                                              </WrapperIcon>
                                              Desativar produto
                                            </WrapperMenuItem>
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}
                                  </WrapperMenu>
                                </CardBottomSection>
                              </Option>
                            </div>
                          ))}

                        {invisibleOptions && invisibleOptions.length === 0 ? (
                          search ? (
                            <>
                              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                                Não foi encontrada nenhum curso invisível para: {search}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                                Não foi encontrada nenhum curso invisível
                              </Text>
                            </>
                          )
                        ) : (
                          ''
                        )}
                      </SectionProducts>
                      {showSeeMoreInvisible && (
                        <DownloadMore onClick={incrementNumberOfCourses}>
                          <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                            VER MAIS
                          </Text>
                          <ArrowMore />
                        </DownloadMore>
                      )}
                    </>
                  </TabPanel>
                </Tabs>
              </WrapperTabs>

              {selectedOptions.length > 0 && canCreateNewCrids && (
                <WrapperButton>
                  <Button text="Avançar" onClick={handleSubmit} />
                </WrapperButton>
              )}
              <WrapperTextLink>
                <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
              </WrapperTextLink>
            </Content>
          </Container>
        </>
      )}
    </>
  )
}
