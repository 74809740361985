/* eslint-disable react/prop-types */
import React from 'react'

export const Bag = ({ color = '#B0AFAF' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M5.5 1.83337L2.75 5.50004V18.3334C2.75 18.8196 2.94315 19.2859 3.28697 19.6297C3.63079 19.9736 4.0971 20.1667 4.58333 20.1667H17.4167C17.9029 20.1667 18.3692 19.9736 18.713 19.6297C19.0568 19.2859 19.25 18.8196 19.25 18.3334V5.50004L16.5 1.83337H5.5Z"
      stroke={`${color}`}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.75 5.5H19.25" stroke={`${color}`} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.6667 9.16663C14.6667 10.1391 14.2804 11.0717 13.5927 11.7594C12.9051 12.447 11.9725 12.8333 11 12.8333C10.0275 12.8333 9.09492 12.447 8.40729 11.7594C7.71965 11.0717 7.33334 10.1391 7.33334 9.16663"
      stroke={`${color}`}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
