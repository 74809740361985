import React, { useContext, useEffect, useState } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { Container, Content, Header, ChartSection } from './styles'

import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer } from 'recharts'
import { DashboardCollapse } from '../DashboardCollapse'
import { DescriptionLegendsReceipts } from './components/DescriptionLegendsReceipts'
import { dashboardLegendsObj } from '../../../constants'
import { api } from '~/services/api'
import { formatValueToFloatWith2Digits } from './utils'
import { CustomLabel } from './components/CustomLabel'

const collapseStyle = {
  margin: '0 55px 20px 0px',
}

function ReceiptsChart({ isMobileWitdh, drawerMenuWidth, filters }) {
  const [graphReceiptsData, setGraphReceiptsData] = useState([])
  const { setIsLoading } = useContext(TemplateContext)

  let params = {}

  if (filters && filters?.productsSelected?.length > 0) {
    params = {
      CourseIds: filters?.productsSelected,
    }
  }
  useEffect(() => {
    async function getGraphReceiptsData() {
      setIsLoading(true)
      try {
        const { data } = await api.get('/dashboard/receipts/graph', { params })

        setGraphReceiptsData(data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getGraphReceiptsData()
  }, [setIsLoading, filters])

  const { receiptsChart } = dashboardLegendsObj

  return (
    <Container isMobile={isMobileWitdh} drawerWidth={drawerMenuWidth}>
      <Content>
        <Header>
          <h2>Recebimentos</h2>
          <p>
            Histórico dos valores relativos à venda de cursos, incluindo todos os meios de pagamento disponíveis, já descontadas
            as taxas da Provi.
          </p>
        </Header>

        <ChartSection>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={isMobileWitdh ? graphReceiptsData.slice(Math.max(graphReceiptsData.length - 3, 0)) : graphReceiptsData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barCategoryGap={'10%'}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis height={40} label={{ value: 'Mês', dy: 20 }} dataKey="name" />
              <YAxis label={{ value: 'Valor (Milhares de R$)', angle: -90, dx: -20 }} />
              <Tooltip formatter={formatValueToFloatWith2Digits} />
              <Legend
                className="receipts--legend"
                wrapperStyle={{ top: -30 }}
                layout="horizontal"
                verticalAlign="top"
                align="left"
              />
              {receiptsChart.map((legend) => (
                <Bar
                  isAnimationActive={false}
                  stackId={legend.stackId}
                  key={legend.id}
                  name={legend.label}
                  dataKey={legend.title}
                  fill={legend.color}
                  label={<CustomLabel receiptsData={graphReceiptsData} titleLabel={legend.title} labelColor={legend.color} />}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </ChartSection>
        <DashboardCollapse
          styleCollapseDiv={collapseStyle}
          headerText="Entenda melhor os conceitos deste gráfico"
          subComponent={<DescriptionLegendsReceipts />}
        />
      </Content>
    </Container>
  )
}

export { ReceiptsChart }
