import { useEffect, useState, useCallback } from 'react'
import { showToast } from '@provi/provi-components'

import { getCourseClasses } from '~/services/api'

const INITIAL_VALUE = 6
const INITIAL_PAGE = 1

export const useCourseClass = (setIsLoading, activePage, setActivePage, goToPage, setEditOption) => {
  const [search, setSearch] = useState('')
  const [optionsShow, setOptionsShow] = useState({ active: [], inactive: [] })
  const [showSeeMore, setShowSeeMore] = useState({ active: false, inactive: false })
  const [pageItems, setPageItems] = useState({ active: 0, inactive: 0 })
  const [page, setPage] = useState({ active: 0, inactive: 0 })
  const [countCourseClasses, setCountCourseClasses] = useState({ active: 0, inactive: 0 })
  const [visibleOpt, setVisibleOpt] = useState({ tabIndex: 0 })

  const handleGetCourseClasses = async ({ queryPage, queryActive, querySearch }) => {
    const { data } = await getCourseClasses({ page: queryPage, active: queryActive, search: querySearch })
    const { count, rows } = data

    return { count, rows }
  }

  const getData = async (querySearch = undefined) => {
    try {
      setIsLoading(true)

      const { count: countActives, rows: rowsActives } = await handleGetCourseClasses({
        queryPage: INITIAL_PAGE,
        queryActive: true,
        querySearch,
      })
      const { count: countInactives, rows: rowsInactives } = await handleGetCourseClasses({
        queryPage: INITIAL_PAGE,
        queryActive: false,
        querySearch,
      })

      const active = rowsActives
      const inactive = rowsInactives

      setPage({
        active: INITIAL_PAGE,
        inactive: INITIAL_PAGE,
      })

      setPageItems({
        active: INITIAL_VALUE,
        inactive: INITIAL_VALUE,
      })

      setOptionsShow({
        active: active,
        inactive: inactive,
      })
      setShowSeeMore({
        active: countActives > INITIAL_VALUE,
        inactive: countInactives > INITIAL_VALUE,
      })
      setCountCourseClasses({
        active: countActives,
        inactive: countInactives,
      })
    } catch (error) {
      showToast('Ops, ocorreu algum erro ao listar as turmas 😔')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (activePage !== 'course-class') {
      setActivePage('course-class')
    }
    setEditOption(null)
    getData()
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => getData(search), 1000)
    return () => clearTimeout(timeoutId)
  }, [search])

  const handleSearchValue = (e) => {
    setSearch(e.target.value)
  }

  const incrementNumberOfCourseClasses = useCallback(
    async (key) => {
      if (countCourseClasses[key] <= pageItems[key]) {
        return
      }

      setIsLoading(true)

      const newPage = page
      newPage[key] = page[key] + 1
      setPage(newPage)

      const { rows } = await handleGetCourseClasses({
        queryPage: newPage[key],
        queryActive: key === 'active',
        querySearch: search || undefined,
      })

      const newPageItems = pageItems
      newPageItems[key] = pageItems[key] + INITIAL_VALUE
      setPageItems(newPageItems)

      const newOptionsShow = optionsShow
      newOptionsShow[key] = [...optionsShow[key], ...rows]
      setOptionsShow(newOptionsShow)

      const newShowSeeMore = showSeeMore
      newShowSeeMore[key] = newPageItems[key] <= countCourseClasses[key]
      setShowSeeMore(newShowSeeMore)

      setIsLoading(false)
    },
    [INITIAL_VALUE, handleGetCourseClasses, search],
  )

  const handleEditCourseClass = (option) => {
    setEditOption(option)
    goToPage('turmas/formulario-turma')
  }

  return [
    handleSearchValue,
    search,
    incrementNumberOfCourseClasses,
    showSeeMore,
    optionsShow,
    visibleOpt,
    setVisibleOpt,
    handleEditCourseClass,
  ]
}
