import React, { useContext } from 'react'

import { Button, theme } from '@provi/provi-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Text } from '~/themes/styles'

import { formatValue } from '~/utils'
import { Modal as ModalComponent } from '~/components/Modal'
import { TemplateContext } from '~/components/Template/context'

import { useCalcModal } from './hooks'
import {
  Header,
  HeaderValue,
  Content,
  WrapperOptions,
  Option,
  ContentOption,
  OptionText,
  Title,
  SubTitle,
  WrapperTexts,
  Anchor,
  DiscountValue,
  EntranceValue,
  EntranceOption,
  InstallmentValue,
  Section,
  Wrapper,
} from './styles'
import { BlueWarningPanel } from 'components/BlueWarningPanel'

/**
 * @typedef {Object} CalcConfigDef
 * @property {Number} amountInCents
 * @property {Array} coursesIds
 * @property {Number} discountInCents
 */

/**
 * @typedef {object} IProps
 * @property {(condition:Boolean) => void} handleModal
 * @property {Boolean} show
 * @property {CalcConfigDef} calcConfig
 */

/** @type {React.FC<IProps>}*/
export const CalcModalV2 = (props) => {
  const { setIsLoading } = useContext(TemplateContext)
  const { handleModal, show, calcConfig } = props
  const [handleCopy, calcData] = useCalcModal(calcConfig, setIsLoading, handleModal)

  return (
    <>
      {show && calcData && calcData.values && calcData.options && (
        <ModalComponent
          isWithoutHeight={true}
          width="488px"
          radius={5}
          onDismiss={() => handleModal()}
          footer={
            <CopyToClipboard text={calcData.text} onCopy={handleCopy}>
              <Button text="Copiar texto" />
            </CopyToClipboard>
          }
        >
          <Header>
            {calcData.values.discount !== 0 && (
              <>
                <HeaderValue>
                  <Anchor>Valor total</Anchor>
                  <Anchor>{formatValue(calcData.values.total, true)}</Anchor>
                </HeaderValue>
                <DiscountValue>
                  <Anchor>(-) Desconto</Anchor>
                  <Anchor>{formatValue(calcData.values.discount, true)}</Anchor>
                </DiscountValue>
              </>
            )}
            <Section>
              <HeaderValue>
                <Anchor>Valor da venda</Anchor>
                <Anchor>{formatValue(calcData.values.finalTotal, true)}</Anchor>
              </HeaderValue>
              {calcData.values.hasUpfrontPayment && (
                <>
                  <EntranceValue>
                    <Anchor>Entrada*</Anchor>
                    <Anchor>{formatValue(calcData.values.upfrontAmount, true)}</Anchor>
                  </EntranceValue>
                  <InstallmentValue>
                    <Anchor>Parcelado</Anchor>
                    <Anchor>{formatValue(calcData.values.finalTotal - calcData.values.upfrontAmount, true)}</Anchor>
                  </InstallmentValue>
                </>
              )}
            </Section>
          </Header>
          <Content>
            <WrapperOptions>
              {calcData.options.length === 0 && (
                <Wrapper>
                  <Text size="16px" lineHeight="24px" bold align="center" color={theme.colors.pink900}>
                    Atenção
                  </Text>
                  <Text size="16px" lineHeight="24px" align="center" color={theme.colors.pink900}>
                    O valor do produto está muito baixo, fazendo com que todas as opções de parcelamento disponíveis resultem em
                    parcelas menores de R$30,00, que é o valor mínimo aceito pela Provi.
                  </Text>
                </Wrapper>
              )}
              {calcData.options.length > 0 && (
                <Text size="12px" lineHeight="18px" align="left" color={theme.colors.blue900} mBottom="15px">
                  *O Imposto sobre Operações Financeiras (IOF) é um imposto Federal obrigatório que incide sobre operações de
                  crédito. Este tributo está diluído nas parcelas.
                </Text>
              )}
              {calcData.options.map((option, index) => (
                <Option key={index}>
                  <ContentOption>
                    <WrapperTexts>
                      {calcData.values.hasUpfrontPayment && <EntranceOption>Entrada +</EntranceOption>}
                      <Title>{option.installmentsToApply}x</Title>
                      <SubTitle>{formatValue(option.pricePerInstallment, true)}</SubTitle>

                      <OptionText>
                        {option.monthlyInterest === 0
                          ? 'sem juros'
                          : `${String(option.monthlyInterest).replace('.', ',')}% a.m.`}
                      </OptionText>
                    </WrapperTexts>
                  </ContentOption>
                </Option>
              ))}
            </WrapperOptions>
          </Content>
          <BlueWarningPanel marginTop={32}>
            Essas condições são válidas para o crédito dinâmico, se o aluno for aprovado nele. As opções de parcelamento do
            crédito antecipado são diferentes e foram definidas em contrato.
          </BlueWarningPanel>
        </ModalComponent>
      )}
    </>
  )
}
