import React from 'react'
import { Button } from '~/components/v3'
import { CloseIcon } from '~/assets/svgs/v2'
import { MiddleSection, EachModalRow } from './components'
import {
  StyledPortalModal,
  ModalContainer,
  FlexContainer,
  BlueHeader,
  BtnFlexContainer,
  BlackParagraph,
  Spacer,
} from './styles'
import { BlueWarningPanel } from 'components/BlueWarningPanel'

export const FinancingConditionsModal = ({
  isMobile,
  modalRef,
  closeModalFn,
  handlePrimaryBtn,
  handleSecondaryBtn,
  conditionTitle,
  simulations,
}) => {
  const { options = [], values, extraOptions = [] } = simulations || {}

  return (
    <StyledPortalModal ref={modalRef} backdropColor={'rgba(212, 218, 247, 0.8)'}>
      <ModalContainer>
        <FlexContainer>
          <BlueHeader>{conditionTitle}</BlueHeader>
          <CloseIcon onClick={closeModalFn} />
        </FlexContainer>
        <MiddleSection isMobile={isMobile} values={values} />
        <BlueHeader>Condições de parcelamento</BlueHeader>
        {!!options.length &&
          options.map((eachObj) => (
            <EachModalRow key={eachObj.id} isMobile={isMobile} eachObj={eachObj} upfrontAmount={values.upfrontAmount} />
          ))}
        {extraOptions?.length ? <BlackParagraph>Outras condições para o crédito antecipado:</BlackParagraph> : null}
        {!!extraOptions?.length &&
          extraOptions?.map((eachObj) => (
            <EachModalRow key={eachObj.id} isMobile={isMobile} eachObj={eachObj} upfrontAmount={values.upfrontAmount} />
          ))}
        <BlueWarningPanel marginTop="16">
          O parcelamento no crédito antecipado pode ser feito em até 24x, caso o aluno seja aprovado pelo motor de crédito.
        </BlueWarningPanel>
        <BlackParagraph>
          Cada parcela é composta pela amortização do valor parcelado, pelos juros do período e pelo IOF fixo e diário. O IOF é
          um imposto federal cobrado sobre operações de crédito e, no caso de financiamentos, é calculado de acordo com o prazo
          previsto para o pagamento.
        </BlackParagraph>
        <Spacer />
        <BtnFlexContainer>
          <Button text={'Cancelar'} color={'white'} mobileWidth={'100%'} onClick={handleSecondaryBtn} />
          <Button text={'Copiar condições'} mobileWidth={'100%'} onClick={handlePrimaryBtn} />
        </BtnFlexContainer>
      </ModalContainer>
    </StyledPortalModal>
  )
}
