import React from 'react'

function AttentionIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg" className="sc-hjWSAi">
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        fill="#B30142"
      ></path>
      <path
        d="M10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15Z"
        fill="white"
      ></path>
      <rect x="9" y="5" width="2" height="6" rx="1" fill="white"></rect>
    </svg>
  )
}

export { AttentionIcon }
