import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const TableContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
  background-color: white;
  border-radius: 1rem;
`

export const EachProductContainer = styled.div`
  width: 100%;
  display: grid;
  padding: 1.5rem 0;
  border-bottom: 1px solid #d4daf7;

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom: initial;
    `}
`

export const FlexContainerWithSVG = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    margin-right: auto;
  }

  ${({ isHeader }) =>
    isHeader &&
    css`
      cursor: pointer;
      svg {
        transform: ${({ state }) => (state ? 'rotate(180deg)' : 'rotate(0deg)')};
        transition: transform 0.4s ease-in-out;
      }
    `}
`

export const TableDetailContainer = styled.div`
  padding-top: 1.5rem;
  display: grid;
  gap: 1.5rem;
`

export const BlueTitle = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
  }
`

export const TableText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `}

  ${({ isBolder }) =>
    isBolder &&
    css`
      font-weight: 700;
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`

export const PaginationContainer = styled.div`
  place-self: center;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`
