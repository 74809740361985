import React from 'react'
import { theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { VerticalContainers } from '../../styles'

export const BlackHeaders = ({ isMobile, title, explanation }) => {
  return (
    <VerticalContainers>
      <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'} fontWeight={600}>
        {title}
      </BaseTypography>
      <BaseTypography
        fontSize={isMobile ? '11px' : '13px'}
        lineHeight={isMobile ? '16px' : '20px'}
        color={theme.colors.greyPrimary}
      >
        {explanation}{' '}
      </BaseTypography>
    </VerticalContainers>
  )
}
