import React, { useContext } from 'react'

import { Modal as ModalComponent } from '~/components/Modal'
import { TemplateContext } from '~/components/Template/context'
import { formatValue } from '~/utils'
import { Text } from '~/themes/styles'
import { useInstallmentValue } from './hooks'

export const InstallmentsValueModal = (props) => {
  const { setIsLoading } = useContext(TemplateContext)

  const { handleModal, show, installmentsValueModalData } = props
  const { InstallmentId, CreditRequestId, isDelayed } = installmentsValueModalData
  const { data } = useInstallmentValue({ InstallmentId, CreditRequestId, setIsLoading })

  return (
    <>
      {show && data && (data.length > 0 || data === []) && (
        <ModalComponent title="" isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('installments-value')}>
          {isDelayed && (
            <>
              <Text size="18px">O aluno ainda não efetuou o pagamento desta parcela. </Text>{' '}
              <Text size="18px">
                A divida total do aluno depende de juros, multa, mora e outras eventuais parcelas em atraso.
              </Text>
              <Text mBottom="30px" size="18px">
                Estes acréscimos dependem da data de pagamento do aluno.
              </Text>
            </>
          )}
          {data && data.length > 1 && (
            <Text mBottom="15px" size="18px">
              O aluno possui mais de um pagamento a ser pago neste mês.
            </Text>
          )}

          {data &&
            data.length > 1 &&
            data.map((payment, index) => (
              <Text key={index} mBottom="5px" size="18px">
                Pagamento {index + 1}: {formatValue(payment.paidValue, true)}
              </Text>
            ))}
        </ModalComponent>
      )}
    </>
  )
}
