import styled from 'styled-components'

export const DrawerWrapper = styled.div`
  min-width: 636px;
  padding: 2rem;

  svg {
    display: block;
    margin-left: auto;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`

export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
`

export const Title = styled.h1`
  margin: 0;
  font-family: 'CenturyGothic', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #00198a;
`

export const Content = styled.div`
  width: 100%;
  border: solid #00198a 1px;
  border-radius: 8px;
  padding: 8px 8px 0 8px;
`

export const HeaderAttributes = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: min(64px);

  h3 {
    margin: 0;
    font-family: 'CenturyGothic', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #00198a;
  }

  span {
    font-family: 'CenturyGothic', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${({ color }) => (color ? color : '#000000')};
  }
`
export const SubtitleSimulations = styled.h2`
  font-family: 'CenturyGothic', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #00198a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;

  button {
    border: none;
    background-color: transparent;
    margin: 0;
  }

  svg {
    margin: 0;
    cursor: pointer;
  }
`

export const SubtitleWithBorder = styled(SubtitleSimulations)`
  margin-top: 16px;
  border-top: 1px solid #2647d7;
  width: 100%;
  padding-top: 16px;
`

export const Amounts = styled.span`
  font-family: 'CenturyGothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Installments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Warning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  max-width: fit-content;
  height: max-content;
  background: #d4daf7;
  border-radius: 8px;

  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;
    color: #000000;
  }
`

export const Radio = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 7px;
  position: static;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #2647d7;
  box-sizing: border-box;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
`
