import React from 'react'

import { Modal } from '~/components/Modal'
import { ModalContent, ModalText } from '../../global'

function ModalDashboard({ setModal }) {
  return (
    <Modal isWithoutHeight onDismiss={() => setModal(false)}>
      <ModalContent>
        <ModalText>
          <strong>Data de criação:</strong> data em que o estudante iniciou a solicitação junto à Provi.
        </ModalText>
        <ModalText>
          <strong>Data de efetivação:</strong> data em que o estudante finalizou a sua solicitação junto à Provi.
        </ModalText>
        <ModalText>
          <strong>Data de pagamento:</strong> data em que a Provi realizou o pagamento.
        </ModalText>
      </ModalContent>
    </Modal>
  )
}

export { ModalDashboard }
