import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SpacingGrids = styled.div`
  display: grid;
  gap: ${({ gap }) => gap || '0.5rem'};
  width: ${({ hasCustomWidth }) => hasCustomWidth || 'auto'};

  *::before,
  *::after {
    box-sizing: revert;
  }

  margin-bottom: ${({ hasBottomMargin }) => hasBottomMargin || 0};
`

export const TextFlex = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '0.5rem'};

  & > div {
    width: 225px;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: revert;
    & > div {
      width: 100%;
    }
  }
`

export const FitContentDiv = styled.div`
  width: fit-content;
`
