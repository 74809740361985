import React from 'react'
import { ConnectedProducts, WelcomeMemberkit } from './components'
import { useMemberkitIntegrations } from './hooks'

export const Memberkit = () => {
  const { IntegrationsList, isFetchingIntegrationsList, handleManageAccount } = useMemberkitIntegrations()

  function renderComponent() {
    return IntegrationsList && IntegrationsList.length ? (
      <ConnectedProducts connections={IntegrationsList} handleManageAccountBtn={handleManageAccount} />
    ) : (
      <WelcomeMemberkit />
    )
  }

  return !isFetchingIntegrationsList ? renderComponent() : null
}
