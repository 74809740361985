import React from 'react'
import { EachDetailBox, DetailText } from '../../styles'

export const EachDetail = ({
  title,
  value,
  isBold,
  isRed,
  isBluePrimary,
  isCard,
  isFromSmallCards,
  isFromBigCard,
  isFromHelper,
  isBoldValue,
}) => {
  return (
    <EachDetailBox isCard={isCard}>
      <DetailText isBold={isBold}>{title}</DetailText>
      <DetailText
        isRed={isRed}
        isBluePrimary={isBluePrimary}
        isFromSmallCards={isFromSmallCards}
        isFromBigCard={isFromBigCard}
        isFromHelper={isFromHelper}
        isBoldValue={isBoldValue}
      >
        {value || '---'}
      </DetailText>
    </EachDetailBox>
  )
}
