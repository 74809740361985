/* eslint-disable react/prop-types */
import React from 'react'

import { BackArrow } from '~/assets/svgs/back-arrow'

import { WrapperButtonBack } from './styles'

export const ButtonBack = (props) => {
  const { onClick, isDark } = props

  return (
    <WrapperButtonBack onClick={onClick}>
      <BackArrow isDark={isDark} size={30} />
    </WrapperButtonBack>
  )
}
