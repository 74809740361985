import React from 'react'
import { Tooltip } from '@material-ui/core'

import { useChargebackData } from './hooks'
import { formatChargebackStatus } from '~/screens/requests/helpers'

import { CloseIcon } from '../../../../assets/svgs/close-icon'

import { ChargebackModal } from './Modal/index'
import { checkChargebackExpiry } from '~/screens/details/helpers'

import { InputFileField, TextAreaField, ButtonV2 } from '@provi/provi-components'

import {
  InputFileWrapper,
  TextAreaWrapper,
  TextAreaMaxCharacters,
  ButtonWrapper,
  SentFilesText,
  FileCard,
  FileCardDeleteButton,
  SidesContainer,
  LeftContent,
  RightContent,
  DocumentsUploadTitle,
  Title,
  ListContainer,
  ListTitle,
  ListText,
  ChargebackContainer,
  TitleStatusWrapper,
  TitleTag,
  StatusTag,
} from './styles'

export const Chargeback = ({ data, getDetails }) => {
  const { id, status, description, CreditRequestId } = data[0]

  const { onFileChange, onUploadSubmission, showFileNames, files, text, setText, removeFile } = useChargebackData({
    proviPayChargebackId: id,
    getDetails,
    CreditRequestId,
  })

  return (
    <ChargebackContainer>
      <TitleStatusWrapper>
        <TitleTag>Chargeback</TitleTag>
        <Tooltip title={description}>
          <StatusTag color={formatChargebackStatus(status)}>{status}</StatusTag>
        </Tooltip>
      </TitleStatusWrapper>
      {checkChargebackExpiry(status) ? (
        <>
          <Title>Envie ao lado documentos para contestar chargeback e também escreva uma justificativa.</Title>
          <ListContainer>
            <ListTitle>Documentos recomendados:</ListTitle>
            <ListText>Nota fiscal</ListText>
            <ListText>Comprovante de liberação de acesso</ListText>
            <ListText>Prints de conversas</ListText>
            <ListText>Descrição do produto vendido</ListText>
            <ListText>Comprovante de que o aluno acessou o conteúdo</ListText>
          </ListContainer>
          <SidesContainer>
            <LeftContent>
              <DocumentsUploadTitle>Upload de documentos</DocumentsUploadTitle>
              <InputFileWrapper>
                {showFileNames(files)?.map((file, index) => (
                  <FileCard key={`${file}-${index}`}>
                    <span>{file}</span>
                    <FileCardDeleteButton onClick={() => removeFile(file)}>
                      <CloseIcon />
                    </FileCardDeleteButton>
                  </FileCard>
                ))}
                <InputFileField
                  emptyTitle="Clique para fazer o upload"
                  emptyText=" "
                  accept=".PDF,.PNG,.JPEG,.DOC,.XML"
                  onChangeHandler={onFileChange}
                  variant
                  multiple
                />
              </InputFileWrapper>
              <ChargebackModal ProviPayChargebackId={id}>
                <SentFilesText>Visualizar histórico de envio</SentFilesText>
              </ChargebackModal>
            </LeftContent>

            <RightContent>
              <TextAreaWrapper>
                <TextAreaField
                  label="Justificativa"
                  placeholder="Escreva aqui..."
                  width="100%"
                  maxLength="400"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <TextAreaMaxCharacters>Você ainda tem {400 - text.length} caracteres</TextAreaMaxCharacters>
              </TextAreaWrapper>

              <ButtonWrapper>
                <ButtonV2 text="Enviar" icon=" " disabled={!text.length || !files.length} onClick={onUploadSubmission} />
              </ButtonWrapper>
            </RightContent>
          </SidesContainer>
        </>
      ) : (
        <>
          <h2 style={{ color: '#414141' }}>Esse processo ja foi encerrado.</h2>
          <ChargebackModal ProviPayChargebackId={id}>
            <SentFilesText>Visualizar histórico de envio</SentFilesText>
          </ChargebackModal>
        </>
      )}
    </ChargebackContainer>
  )
}
