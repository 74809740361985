import React from 'react'
import { AddAccountButton } from '..'
import { EachIntegration } from '../EachIntegration'
import { Container, Title } from './styles'
import { ActionsBtn } from '../ActionsBtn'
import { WrenchIcon } from '~/assets/svgs/v2/WrenchIcon'

export const ConnectedProducts = ({ connections, handleManageAccountBtn }) => {
  return (
    <Container>
      <Title>Contas vinculadas</Title>

      {connections &&
        connections.map((connection, index, arr) => (
          <EachIntegration
            key={index}
            name={connection.name}
            secondaryComponent={
              <ActionsBtn
                handleClick={() => handleManageAccountBtn(connection.name, connection._id)}
                svg={<WrenchIcon bluePrimary />}
                actionText={'Gerenciar conta'}
              />
            }
            noBorderBottomCondition={index === arr.length - 1}
          />
        ))}

      <AddAccountButton />
    </Container>
  )
}
