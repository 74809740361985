import { getCampaingDetails } from '~/services/api'
import { showToast } from '@provi/provi-components'

/**
 * @description set payment methods and default payment methods of campaign
 * @name setCampaignPaymentMethods
 * @async
 * @property {Object} data
 * @property {Object} paymentMethods
 * @property {Object} setDefaultPaymentMethods
 * @property {Object} setPaymentMethods
 * @property {Object} parsedProducts
 * @returns {Promise<Void>}
 */

export const setCampaignPaymentMethods = async ({
  paymentMethods,
  setDefaultPaymentMethods,
  setPaymentMethods,
  parsedProducts,
}) => {
  if (!paymentMethods || !paymentMethods.length) {
    showToast('Houve um problema ao carregar os métodos de pagamento, atualize a pagina e tente novamente')
    return
  }

  const checkIfExistCoursePaymentMethod = parsedProducts && parsedProducts.filter((product) => product.paymentMethod).length

  const { data: campaignDetails } = await getCampaingDetails()
  const { partner } = campaignDetails
  if (!partner) return

  setDefaultPaymentMethods(partner.defaultPaymentMethod)

  if (partner.defaultPaymentMethod && checkIfExistCoursePaymentMethod) {
    const coursePaymentMethods = parsedProducts
      .filter((product) => product.paymentMethod && product.paymentMethod.length)
      .map((product) => product.paymentMethod)

    if (!coursePaymentMethods) {
      showToast('Houve um problema ao carregar os métodos de pagamento, atualize a pagina e tente novamente')
    }

    const activePayments = []

    coursePaymentMethods.length &&
      coursePaymentMethods.map((coursePayment) => {
        activePayments.push(...coursePayment)
      })

    const coursedefaultPaymentMethods = paymentMethods.map((method) => {
      const methodIncludes = activePayments.includes(method.value)
      method.selected = partner.hasProviPayAvailable ? methodIncludes : methodIncludes && method.value === 'CourseFinancing'
      method.available = partner.hasProviPayAvailable ? methodIncludes : methodIncludes && method.value === 'CourseFinancing'

      return method
    })

    setPaymentMethods(coursedefaultPaymentMethods)
  } else if (partner && partner.defaultPaymentMethod && partner.defaultPaymentMethod.length) {
    const partnerdefaultPaymentMethods = paymentMethods.map((method) => {
      const methodIncludes = partner.defaultPaymentMethod.includes(method.value)
      method.selected = partner.hasProviPayAvailable ? methodIncludes : methodIncludes && method.value === 'CourseFinancing'
      method.available = partner.hasProviPayAvailable ? methodIncludes : methodIncludes && method.value === 'CourseFinancing'

      return method
    })

    setPaymentMethods(partnerdefaultPaymentMethods)
  }
}
