import { useState, useEffect } from 'react'
import { readChargebackDocuments } from '~/services/api'

export const useChargebackModal = ({ ProviPayChargebackId }) => {
  const [data, setData] = useState([])

  const getDocuments = async () => {
    const chargebackData = await readChargebackDocuments(ProviPayChargebackId)

    setData(chargebackData?.documents)
  }

  useEffect(() => {
    getDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, getDocuments }
}
