import React from 'react'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { CheckBox, TextButton, ButtonV3, theme } from '@provi/provi-components'
import { ItemMenu } from './styles'

export const Option = (props) => {
  return (
    <ItemMenu>
      <components.Option {...props}>
        <CheckBox
          ref={props.innerRef}
          htmlFor={props.value}
          name={props.value}
          value={props.value}
          text={props.label}
          checked={props.isSelected}
        />
      </components.Option>
    </ItemMenu>
  )
}

export const Menu = (props) => {
  const { selectProps } = props
  const { onFilter, value, onClear } = selectProps
  return (
    <>
      <components.Menu {...props}>
        <div>{props.children}</div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: 80 }}>
          <TextButton onClick={onClear} text="Limpar filtro" />
          <ButtonV3
            width={'138px'}
            height={'40px'}
            onClick={() => {
              const valueFiltered = value?.filter((item) => item.value !== '*')
              onFilter(valueFiltered)
            }}
            text="Filtrar"
          />
        </div>
      </components.Menu>
    </>
  )
}

export const MultiValue = (props) => <components.MultiValue {...props}>{props?.data?.label}</components.MultiValue>

export const colourStyles = {
  option: (styles) => ({
    ...styles,
    padding: 5,
    fontSize: 14,
    paddingLeft: 13,
    color: theme.colors.blue800,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
  control: (provided) => ({
    ...provided,
    border: `1px solid ${theme.colors.grey500}`,
    borderRadius: 5,
    fontFamily: 'Montserrat, sans serif',
    cursor: 'pointer',
    minWidth: 450,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#D4DAF7',
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 30,
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#09208E',
    fontSize: 14,
    fontWeight: 'bold',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#09208E',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'red',
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
}

export const animatedComponents = makeAnimated()
