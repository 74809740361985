import React from 'react'
import { theme } from '@provi/provi-components'
import { HeaderBreadcrumbs } from '~/components/v3'
import { ExplanationCollapse, TokenBox } from './components'
import { useLayoutContext } from '~/hooks'
import { useDeveloper } from './hooks'
import { BasePageWrapper, BaseContainer, BaseFlexContainer, BaseBigText } from '~/globalStyles'

export const Developer = () => {
  const { isMobile, drawerWidth } = useLayoutContext()
  const { apiToken, isLoadingToken, postAPItokenData } = useDeveloper()

  return (
    <BasePageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      <BaseContainer>
        <BaseFlexContainer flexDirection={'column'} gap={'2rem'}>
          <HeaderBreadcrumbs levelsArr={['Desenvolvimento']} />
          <BaseBigText color={theme.colors.bluePrimary}>API Token</BaseBigText>
          <ExplanationCollapse />
          <TokenBox tokenProp={apiToken} isLoadingToken={isLoadingToken} postAPItokenData={postAPItokenData} />
        </BaseFlexContainer>
      </BaseContainer>
    </BasePageWrapper>
  )
}
