import React from 'react'
import { theme } from '@provi/provi-components'
import { EditIcon, CopyIcon, DeleteIcon, ShareIcon } from '~/assets/svgs/v2'
import { BaseTypography } from '~/globalStyles'
import { TextContainer, ActionsContainer, LeftSideColumn } from './styles'
import { formatDate } from './helpers'

const temporaryDisable = false

export const TitleAndActions = ({ isMobile, courseTitle, createdAt, actions }) => {
  return (
    <LeftSideColumn>
      <TextContainer>
        <BaseTypography
          fontSize={isMobile ? '19px' : '25px'}
          lineHeight={isMobile ? '28px' : '32px'}
          color={theme.colors.bluePrimary}
        >
          {courseTitle || '---'}
        </BaseTypography>
        <BaseTypography fontSize={'16px'} lineHeight={'24px'} color={theme.colors.bluePrimary}>
          {createdAt ? `Criado em: ${formatDate(createdAt)}` : '---'}
        </BaseTypography>
      </TextContainer>
      <ActionsContainer>
        {temporaryDisable && (
          <>
            <EditIcon {...(actions && { onClick: actions.edit })} />
            <CopyIcon color={theme.colors.bluePrimary} {...(actions && { onClick: actions.copy })} />
          </>
        )}

        <DeleteIcon {...(actions && { onClick: actions.delete })} />
        <ShareIcon color={theme.colors.bluePrimary} {...(actions && { onClick: actions.share })} />
      </ActionsContainer>
    </LeftSideColumn>
  )
}
