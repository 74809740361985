import React, { useRef } from 'react'
import { PortalModal } from '@provi/provi-components'

import { ChargebackTable } from './ChargebackTable'
import { ChargebackModalWrapper, ChargebackModalContent, ChargebackModalTitle, ChargebackCloseModal } from './styles'
import { useChargebackModal } from './hooks'

export const ChargebackModal = ({ children, ProviPayChargebackId }) => {
  const { data, getDocuments } = useChargebackModal({ ProviPayChargebackId })
  const modalRef = useRef()

  const openModal = async () => {
    modalRef.current.openModalFn()
    await getDocuments()
  }

  const closeModal = () => {
    modalRef.current.closeModalFn()
  }

  return (
    <ChargebackModalWrapper>
      <button onClick={openModal}>{children}</button>
      <PortalModal ref={modalRef} borderRadius={'5px'} width={'fit-content'}>
        <ChargebackModalContent>
          <ChargebackModalTitle>Histórico de envio de documentos</ChargebackModalTitle>
          <ChargebackTable data={data} />

          <ChargebackCloseModal onClick={closeModal}>fechar</ChargebackCloseModal>
        </ChargebackModalContent>
      </PortalModal>
    </ChargebackModalWrapper>
  )
}
