import React from 'react'

export const ArrowLeft = ({ onClick }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path d="M19 8.5L1.75 8.5M8 15.5L1 8.5L8 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
