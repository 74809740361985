import styled from 'styled-components';
import { theme } from '@provi/provi-components'

interface IBlueWarningPanelProps {
  marginTop?: number
}

export const BlueWarningPanelContainer = styled.div<IBlueWarningPanelProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${theme.colors.bluePrimaryLight};
  width: 100%;
  height: max-content;
  border-radius: 8px;
  padding: 12px;
  margin-top: ${({marginTop = 0}) => `${marginTop}px`};
`

export const BlueWarningPanelIcon = styled.img`
  margin-right: 10px;
`

export const BlueWarningPanelText = styled.p`
  font-family: 'Montserrat', sans-serif; 
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
  strong{
    font-weight: bold;
  }
`
