import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.section`
  border: 2px solid #d4daf7;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
  max-width: 900px;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  color: ${theme.colors.bluePrimary};
  font-family: Montserrat;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
`

export const DescriptionMemberkit = styled.p`
  max-width: 750px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: #000000;
`

export const ListSteps = styled.ul`
  margin-top: 2rem;
  list-style: none;
  padding-left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    flex-wrap: wrap;
  }
`
export const ListStepsItem = styled.li`
  width: 100%;

  @media screen and (min-width: 921px) {
    &:not(:last-of-type) {
      border-top: 2px solid #d4daf7;
    }
  }

  @media screen and (max-width: 920px) {
    &:not(:last-of-type) {
      border-left: 2px solid #d4daf7;
    }

    div {
      position: relative;
      top: -52px;
      left: 30px;
    }
  }

  p {
    margin-top: 0.5rem;
  }

  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: ${theme.colors.bluePrimary};
    margin-bottom: 0;
  }
`

export const CircleStepItem = styled.span`
  position: relative;
  top: -10px;
  width: 20px;
  height: 20px;
  display: inherit;
  border-radius: 100%;
  background: #ffffff;
  border: 2px solid ${theme.colors.bluePrimary};

  @media screen and (max-width: 920px) {
    &.circle__progress-1,
    &.circle__progress-2 {
      left: -11px;
    }
    &.circle__progress-3 {
      left: -9px;
    }
  }
`
