import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const PageWrapper = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;
  min-height: 100vh;

  ${theme.breakpoints.down('md')} {
    padding: initial;
  }
`

export const Container = styled.div`
  padding: 0px 40px 30px;
  margin: 0 auto;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    padding: 0 0 30px 0;
  }
`

export const HeaderContainer = styled.div`
  padding: 25px 0 35px;
  max-width: 920px;
  display: grid;

  ${theme.breakpoints.down('md')} {
    place-items: center;
    padding: 16px 0;
    width: 100%;
    max-width: 100%;
  }
`

export const Headers = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  color: ${({ isRed }) => (isRed ? theme.colors.pink900 : theme.colors.blue900)};
  ${({ hasUnderline }) =>
    hasUnderline &&
    css`
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;

      ${theme.breakpoints.down('md')} {
        margin-bottom: 20px;
        border-bottom: initial;
        padding-bottom: initial;
      }
    `}

  ${({ hasCursor }) =>
    hasCursor &&
    css`
      cursor: pointer;
    `}
`
export const DetailsGrid = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 920px;
  margin-bottom: 80px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 40px;
  }
`

export const TableWrapper = styled.div`
  max-width: 1300px;

  ${theme.breakpoints.down('md')} {
    padding: 0 16px;
  }
`

export const StyledMobileSpan = styled.span`
  ${theme.breakpoints.down('md')} {
    text-decoration: underline;
    cursor: pointer;
  }
`
