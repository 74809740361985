import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.blue900};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  margin-right: 10px;
`

export const Active = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.pink900};
  background-color: ${theme.colors.pink900};
`
