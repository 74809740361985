import * as Sentry from '@sentry/browser'
import { initGA } from './utils/googleAnalytics'
import { setupFirebase } from './services/firebase'

const { REACT_APP_API_ENV } = process.env

Sentry.init({
  dsn: 'https://c809763062294ff38550fec6888a3dff@o245612.ingest.sentry.io/5186371',
  environment: REACT_APP_API_ENV,
})

setupFirebase()
initGA()
