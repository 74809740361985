import React from 'react'

export const LogoProvi = () => {
  return (
    <svg width="138" height="41" viewBox="0 0 138 41" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M67.2283 22.4891C67.2283 28.6184 62.7721 33.3496 57.3665 33.3496C54.6001 33.3496 52.5775 32.4033 51.2172 30.8393V41.0007H45.0254V12.2074H51.2172V14.1421C52.5775 12.5781 54.6001 11.6318 57.3665 11.6318C62.7721 11.6318 67.2283 16.3597 67.2283 22.4891ZM61.0366 22.4891C61.0366 19.4033 58.9715 17.4718 56.1269 17.4718C53.2789 17.4718 51.2172 19.4065 51.2172 22.4891C51.2172 25.5749 53.2822 27.5064 56.1269 27.5064C58.9748 27.5064 61.0366 25.5749 61.0366 22.4891Z"
        fill="#02163D"
      />
      <path
        d="M83.1156 11.7944V18.7855C80.558 18.3758 76.9271 19.4033 76.9271 23.4743V32.7708H70.7354V12.2074H76.9271V15.8687C77.7492 13.1113 80.5156 11.7944 83.1156 11.7944Z"
        fill="#02163D"
      />
      <path
        d="M84.3516 22.4891C84.3516 16.3597 89.2188 11.6318 95.2866 11.6318C101.354 11.6318 106.222 16.363 106.222 22.4891C106.222 28.6184 101.351 33.3496 95.2866 33.3496C89.2221 33.3496 84.3516 28.6184 84.3516 22.4891ZM100.033 22.4891C100.033 19.5691 97.9682 17.6344 95.2866 17.6344C92.6051 17.6344 90.5401 19.5691 90.5401 22.4891C90.5401 25.4091 92.6051 27.3438 95.2866 27.3438C97.9715 27.3438 100.033 25.4091 100.033 22.4891Z"
        fill="#02163D"
      />
      <path
        d="M128.921 12.2075L121.287 32.7742H114.273L106.64 12.2075H113.49L117.784 25.2466L122.077 12.2075H128.921Z"
        fill="#02163D"
      />
      <path
        d="M130.571 6.69598C130.571 4.67996 132.264 2.99561 134.284 2.99561C136.303 2.99561 137.996 4.68321 137.996 6.69598C137.996 8.712 136.303 10.3996 134.284 10.3996C132.264 10.3996 130.571 8.70875 130.571 6.69598ZM131.191 12.2075H137.383V32.7742H131.191V12.2075Z"
        fill="#02163D"
      />
      <path
        d="M36.1034 7.06609C28.1924 -1.98649 14.3963 -2.34092 6.02213 6.0028C1.97367 10.0413 -0.0293603 15.3415 0.00326226 20.632L0 20.6287V41.0003H20.438L20.4348 40.997C26.1372 41.0328 31.8527 38.7241 35.9925 34.0612C42.7715 26.4231 42.8237 14.7562 36.1034 7.06609ZM26.0817 25.9972C23.0348 29.0342 18.0957 29.0342 15.0488 25.9972C12.0018 22.9601 12.0018 18.0371 15.0488 15.0001C18.0957 11.9631 23.0348 11.9631 26.0817 15.0001C29.1287 18.0371 29.1287 22.9601 26.0817 25.9972Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="41.1105" y1="20.4998" x2="0" y2="20.4998" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2369F1" />
          <stop offset="0.999" stopColor="#06C1EF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
