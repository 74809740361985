import React from 'react'

export const DrawerInternalChevronDown = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.34292 7.75732L4.92871 9.17154L11.9998 16.2426L19.0708 9.17157L17.6566 7.75735L11.9998 13.4142L6.34292 7.75732Z"
      fill="#5CB5FE"
    />
  </svg>
)

export const DrawerInternalChevronUp = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6571 16.2427L19.0713 14.8285L12.0002 7.75737L4.92917 14.8284L6.34338 16.2426L12.0002 10.5858L17.6571 16.2427Z"
      fill="#1672EC"
    />
  </svg>
)
