export const useHeader = ({ setPage, page, setItemsPerPage, totalPages, setSearchOptions, handleActiveBox }) => {
  const handleItensPerPage = (e) => {
    setItemsPerPage(e)
  }

  const handleSearchOptions = (e) => {
    setSearchOptions(e)
    handleActiveBox(e.value)
  }

  const nextPage = () => {
    if (!(page + 1 > totalPages)) {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    if (page !== 1) {
      setPage(page - 1)
    }
  }

  return [handleItensPerPage, handleSearchOptions, nextPage, prevPage]
}
