import React from 'react'

export const CalendarIcon = (props) => {
  return (
    <svg width={20} height={22} viewBox="0 0 20 22" fill="none" {...props}>
      <path
        d="M17 3H3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM14 1v4M6 1v4M1 9h18"
        stroke="#DE0C4B"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
