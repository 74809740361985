/* eslint-disable react/prop-types */
import React from 'react'
import { theme } from '@provi/provi-components'

export const LoopArrow = ({ color = '#fff', isDisable = false }) => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M23 3.00008V9.00008H17M1 19.0001V13.0001M1 13.0001H7M1 13.0001L5.64 17.3601C6.71475 18.4354 8.04437 19.2209 9.50481 19.6433C10.9652 20.0657 12.5089 20.1113 13.9917 19.7758C15.4745 19.4403 16.8482 18.7346 17.9845 17.7247C19.1209 16.7147 19.9828 15.4333 20.49 14.0001M22.4902 7.99764L17.8502 3.63763C16.7755 2.56235 15.4459 1.77684 13.9854 1.35441C12.525 0.931975 10.9813 0.886386 9.49852 1.22189C8.0157 1.5574 6.64203 2.26307 5.5057 3.27305C4.36937 4.28304 3.5074 5.56442 3.00023 6.99764"
      stroke={isDisable ? theme.colors.grey600 : color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
