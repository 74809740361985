import React from 'react'

export const IntegrationsIcon = ({ onClick }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 27.5C17.933 27.5 19.5 25.933 19.5 24C19.5 22.067 17.933 20.5 16 20.5C14.067 20.5 12.5 22.067 12.5 24C12.5 25.933 14.067 27.5 16 27.5Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 12.4997C25.433 12.4997 27 10.9327 27 8.99969C27 7.0667 25.433 5.49969 23.5 5.49969C21.567 5.49969 20 7.0667 20 8.99969C20 10.9327 21.567 12.4997 23.5 12.4997Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 12.4997C10.433 12.4997 12 10.9327 12 8.99969C12 7.0667 10.433 5.49969 8.5 5.49969C6.567 5.49969 5 7.0667 5 8.99969C5 10.9327 6.567 12.4997 8.5 12.4997Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 12.4997V13.4999C8.5 13.8939 8.5776 14.284 8.72837 14.648C8.87913 15.012 9.10012 15.3427 9.3787 15.6213C9.65728 15.8999 9.98801 16.1208 10.352 16.2716C10.716 16.4224 11.1061 16.5 11.5001 16.4999L20.5001 16.4998C21.2957 16.4997 22.0587 16.1837 22.6213 15.6211C23.1839 15.0585 23.5 14.2954 23.5 13.4998V12.4997"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.0003 16.5L16 20.5" stroke="#000B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
