import React from 'react'
import Box from '@material-ui/core/Box'
import { formatCancellationStatus } from '~/screens/requests/helpers'

import { MobileGrid, CenteredBtn } from './styles'

const findAndPrintValue = (row, valueHeader) => {
  return row.allCells.find(({ column: { Header } }) => Header === valueHeader)
}

export const CancellationsMobileRows = ({ row }) => {
  return (
    <Box>
      <MobileGrid>
        <div>
          <strong>Status:</strong>
          <br />
          {formatCancellationStatus(findAndPrintValue(row, 'Status do pedido').value).status}
        </div>
        <div>
          <strong>Solicitação:</strong> <br />
          {findAndPrintValue(row, 'Data da solicitação').render('Cell')}
        </div>
        <div>
          <strong>Prazo garantia:</strong> <br />
          {findAndPrintValue(row, 'Prazo de garantia').render('Cell')}
        </div>
        <div>
          <strong>CPF:</strong> <br />
          {findAndPrintValue(row, 'CPF').render('Cell')}
        </div>
        <div style={{ whiteSpace: 'normal' }}>
          <strong>Motivo:</strong>
          <br />
          {findAndPrintValue(row, 'Motivo').value}
        </div>
        <CenteredBtn>{findAndPrintValue(row, 'Ações').render('Cell')}</CenteredBtn>
      </MobileGrid>
    </Box>
  )
}
