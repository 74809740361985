import React from 'react'
import { InputField, ButtonV2 } from '@provi/provi-components'
import { Container, RowWrapper, Label, Divider, SaveInformation } from './styles'
import { useContactsTab } from './hooks'

export const ContactsTab = () => {
  const {
    isMobile,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,

    contactData,
    isIsaEnabledState,
  } = useContactsTab()

  return (
    <>
      {isIsaEnabledState !== undefined && contactData && (
        <form onSubmit={handleSubmit}>
          <Container>
            <RowWrapper style={{ marginTop: '9px' }}>
              <Label>Email de suporte dos alunos</Label>
              <InputField
                isRequired
                placeholder="Digite o e-mail de suporte aos alunos"
                width={isMobile ? '100%' : '384px'}
                id="contact-support-email"
                value={values.contactSupportEmail}
                hasError={errors.contactSupportEmail && touched.contactSupportEmail}
                errorMessage={errors.contactSupportEmail}
                onChange={handleChange('contactSupportEmail')}
                onBlur={handleBlur('contactSupportEmail')}
              />
            </RowWrapper>
            <Divider />
            <RowWrapper>
              <Label>Whatsapp de suporte aos alunos</Label>
              <InputField
                isRequired
                placeholder="Digite o número de Whatsapp para suporte aos alunos"
                width={isMobile ? '100%' : '384px'}
                id="contact-support-whatsapp"
                mask="(99) 99999-9999"
                value={values.contactSupportWhatsapp}
                hasError={errors.contactSupportWhatsapp && touched.contactSupportWhatsapp}
                errorMessage={errors.contactSupportWhatsapp}
                onChange={handleChange('contactSupportWhatsapp')}
                onBlur={handleBlur('contactSupportWhatsapp')}
              />
            </RowWrapper>
            <Divider />
            <RowWrapper>
              <Label>E-mail ideal para Provi notificar sobre solicitações de cancelamento</Label>
              <InputField
                isRequired
                placeholder="Digite o e-mail para solicitações de cancelamento"
                width={isMobile ? '100%' : '384px'}
                id="contact-cancel-email"
                value={values.contactCancellationEmail}
                hasError={errors.contactCancellationEmail && touched.contactCancellationEmail}
                errorMessage={errors.contactCancellationEmail}
                onChange={handleChange('contactCancellationEmail')}
                onBlur={handleBlur('contactCancellationEmail')}
              />
            </RowWrapper>
            <Divider />
            <RowWrapper>
              <Label>E-mail ideal para Provi notificar sobre alunos sem acesso ao curso</Label>
              <InputField
                isRequired
                placeholder="Digite o e-mail para notificação de remoção de acesso a cursos"
                width={isMobile ? '100%' : '384px'}
                id="contact-access-removal-email"
                value={values.contactStudentAccessEmail}
                hasError={errors.contactStudentAccessEmail && touched.contactStudentAccessEmail}
                errorMessage={errors.contactStudentAccessEmail}
                onChange={handleChange('contactStudentAccessEmail')}
                onBlur={handleBlur('contactStudentAccessEmail')}
              />
            </RowWrapper>
            <Divider />
            <RowWrapper>
              <Label>Contato do financeiro</Label>
              <InputField
                isRequired
                placeholder="Digite o e-mail do contato do financeiro"
                width={isMobile ? '100%' : '384px'}
                id="contact-finance-email"
                value={values.contactFinancialEmail}
                hasError={errors.contactFinancialEmail && touched.contactFinancialEmail}
                errorMessage={errors.contactFinancialEmail}
                onChange={handleChange('contactFinancialEmail')}
                onBlur={handleBlur('contactFinancialEmail')}
              />
            </RowWrapper>
            <Divider />
            <RowWrapper>
              <Label>Contato do responsável pela Parceria com a Provi</Label>
              <InputField
                isRequired
                placeholder="Digite o e-mail do responsável pela Parceria com a Provi"
                width={isMobile ? '100%' : '384px'}
                id="contact-partnership-email"
                value={values.contactPartnershipResponsibleEmail}
                hasError={errors.contactPartnershipResponsibleEmail && touched.contactPartnershipResponsibleEmail}
                errorMessage={errors.contactPartnershipResponsibleEmail}
                onChange={handleChange('contactPartnershipResponsibleEmail')}
                onBlur={handleBlur('contactPartnershipResponsibleEmail')}
              />
            </RowWrapper>
            {isIsaEnabledState && (
              <>
                <Divider />
                <RowWrapper>
                  <Label>Contato do responsável das turmas de ISA</Label>
                  <InputField
                    isRequired
                    placeholder="Digite o e-mail do contato do responsável das turmas de ISA"
                    width={isMobile ? '100%' : '384px'}
                    id="contact-ISA-email"
                    value={values.contactISAStudentEmail}
                    hasError={errors.contactISAStudentEmail && touched.contactISAStudentEmail}
                    errorMessage={errors.contactISAStudentEmail}
                    onChange={handleChange('contactISAStudentEmail')}
                    onBlur={handleBlur('contactISAStudentEmail')}
                  />
                </RowWrapper>
              </>
            )}

            <Divider isVanish={isMobile ? true : false} />
            <SaveInformation>
              <ButtonV2 persistFloatingMode={false} marginVertical={0} marginHorizontal={0} text="Salvar" type="submit" />
            </SaveInformation>
          </Container>
        </form>
      )}
    </>
  )
}
