/* eslint-disable react/prop-types */
import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'

export const InstallmentsModal = (props) => {
  const { handleModal, show, installmentsModalData } = props
  const { initial, initial_intallment_value } = installmentsModalData || {}

  return (
    <>
      {show && installmentsModalData && (
        <ModalComponent title="" isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('installments')}>
          <Text mBottom="30px" size="18px">
            O aluno refinanciou junto a Provi
          </Text>
          <Text bold>Informações do contrato original</Text>
          <Text>Número de parcelas: {initial}</Text>
          <Text>Valor da parcela: {initial_intallment_value}</Text>
        </ModalComponent>
      )}
    </>
  )
}
