import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ChargebackContainer = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 893px;

  background: ${theme.colors.white100};
  border: 1px solid ${theme.colors.pink900};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px 13px 33px 43px;

  ${theme.breakpoints.down('md')} {
    border-radius: 0px;
    border: none;
    border-top: 1px solid ${theme.colors.pink900};
    border-bottom: 1px solid ${theme.colors.pink900};
    padding: 25px 17px;
  }
`

export const TitleStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }
`

export const TitleTag = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${theme.colors.pink900};

  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 26px;

  ${theme.breakpoints.down('md')} {
    margin: 0px;
  }
`

export const StatusTag = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 5px;
  padding: 0px 7px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #414141;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #01163e;
  margin-bottom: 13px;
  max-width: 580px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 22px;
  }
`

export const ListContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #01163e;

  ${theme.breakpoints.down('md')} {
    line-height: 20px;
  }
`

export const ListTitle = styled.ul`
  font-weight: 700;
`

export const ListText = styled.li`
  font-weight: 400;
  margin-left: 10px;

  ::marker {
    font-size: 10px;
  }
`

export const SidesContainer = styled.div`
  display: flex;
  gap: 31px;
  margin-top: 46px;
  margin-right: 23px;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 39px;
    margin-top: 39px;
    margin-right: 0px;
  }
`

export const LeftContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 14px;
  justify-content: flex-start;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const DocumentsUploadTitle = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.blue900};
`

export const FileCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(92, 181, 254, 0.2);
  border: 1px dashed #5cb5fe;
  border-radius: 5px;
  padding: 7px 8px 7px 18px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #414141;
`

export const FileCardDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;
  cursor: pointer;
`

export const RightContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    margin-top: 24px;
    width: 100%;
  }
`

export const InputFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  #input-wrapper {
    height: 40px;
    width: 298px;

    border: 1px dashed #5cb5fe;
    border-radius: 5px;

    div {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #414141;
    }

    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`

export const SentFilesText = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #01163e;
  cursor: pointer;

  ${theme.breakpoints.down('md')} {
    margin: 25px 0 0 0;
  }
`

export const TextAreaWrapper = styled.div`
  margin-bottom: 22px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  div {
    min-height: 188px;
    border-radius: 5px;
    border: 1px solid #b0afaf;
  }

  textarea {
    font-size: 14px;
    line-height: 18px;
    color: #414141;
  }
`

export const TextAreaMaxCharacters = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: #414141;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > div {
    width: fit-content;
  }

  .MuiButton-root {
    max-width: 174px;
  }
`
