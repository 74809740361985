import { string, object } from 'yup'
import { brazilianStates } from '~/constants'

const arrWithStateStrings = brazilianStates.map((eachObj) => eachObj.value)

export const addCampusSchema = object({
  name: string().required('Digite um nome'),
  zipcode: string().required('Digite um CEP'),
  street: string().required('Digite uma rua'),
  state: string().required('Digite a sigla').oneOf(arrWithStateStrings, 'Digite a sigla'),
  city: string().required('Digite uma cidade'),
  district: string().required('Digite um bairro'),
  number: string().notRequired(),
  complement: string().notRequired(),
})
