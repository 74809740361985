/* eslint-disable react/prop-types */
import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'

import { formatDate } from '~/utils'
import { PopBallon } from '~/assets/svgs/popballon'
import { Section, Title, Employee, Comment, WrapperComment } from './styles'
// import { capitalize, formatValue } from '../../../../../utils'

export const LastUpdateModal = (props) => {
  const { handleModal, show, lastUpdateData } = props

  return (
    <>
      {show && (
        <ModalComponent isWithoutHeight width="736px" radius={5} onDismiss={() => handleModal('lastUpdate')}>
          {lastUpdateData.length > 0
            ? lastUpdateData.map((update, idx) => (
                <Section key={idx}>
                  <Title first={idx === 0}>
                    {formatDate(update.createdAt, true)} - {update.description}
                  </Title>
                  <Employee hasComment={!!update.comment}>{update.employee}</Employee>

                  {update.comment && (
                    <WrapperComment>
                      <PopBallon />
                      <Comment>“{update.comment}”</Comment>
                    </WrapperComment>
                  )}
                </Section>
              ))
            : ''}
        </ModalComponent>
      )}
    </>
  )
}
