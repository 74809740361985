import React from 'react'

export const DashboardArrowIcon = (...props) => {
  return (
    <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1Z"
        stroke="#2647D7"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M8.5 11L13 16L17.5 11" stroke="#2647D7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
