import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  background-color: ${theme.colors.bluePrimary};
  margin-bottom: 2rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`
export const Content = styled.div`
width: calc(100% - 18rem);
display: flex;
justify-content: space-between;
align-items: center;
${theme.breakpoints.down('md')} {
  justify-content: center;
 flex-direction: column;
 align-items: start;
}

`
export const LeftColumn = styled.div`
padding: 2rem 0 2rem 2rem;
width: 45%;
display: flex;
justify-content: center;
align-items: center;
${theme.breakpoints.down('md')} {
width: 80%;
padding: 1rem;
}
`

export const MidColumn = styled.div`
padding: 0.5rem 1rem 0.5rem 1rem;
width: 65%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
${theme.breakpoints.down('md')} {
  width: 90%;
  padding:0 0 1.5rem 0.5rem;
  }
`

export const RightColumn = styled.div`
width: 300px;
height: 380px;
position: absolute;
right: -36px;
top: 50%;
transform: translateY(-50%);
background-color: white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
${theme.breakpoints.down('md')} {
  width: 320px;
  height: 400px;
}
`
export const Vanishing = styled.div`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  `



