/* eslint-disable react/prop-types */
import React from 'react'

import { theme } from '@provi/provi-components'
import { Modal } from '~/components/Modal'
import { Text } from '~/themes/styles'
import { Span, WrapperUpdate } from './style'

import { formatDate } from '~/utils'

export const ListUpdatesModal = (props) => {
  const { handleModal, email, listUpdates } = props

  return (
    <>
      <Modal isWithoutHeight width="720px" radius={20} onDismiss={() => handleModal(false, 'list-updates')}>
        <Text bold align="left" color={theme.colors.blue900} size="20px">
          Histórico de edições de <Span>"{email}"</Span>
        </Text>
        {listUpdates &&
          listUpdates.map((update, i) => {
            return (
              <WrapperUpdate key={i + 1}>
                <Text bold align="left" color="#B0AFAF" size="20px" mBottom="4px">
                  {formatDate(update.date, true)} - {update.txt}
                </Text>
                <Text bold align="left" color={theme.colors.pink900} size="20px" mBottom="15px">
                  {update.user}
                </Text>
              </WrapperUpdate>
            )
          })}
      </Modal>
    </>
  )
}
