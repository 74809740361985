import React from 'react'
import * as S from './styles'
import WarningIcon from "assets/svgs/warning-icon.svg"

interface IBlueWarningPanelProps {
  marginTop?: number
}

export const BlueWarningPanel:React.FC<IBlueWarningPanelProps> = ({ children, marginTop }) => {
  return (
    <S.BlueWarningPanelContainer marginTop={marginTop}>
      <S.BlueWarningPanelIcon src={WarningIcon} />
      <S.BlueWarningPanelText>{children}</S.BlueWarningPanelText>
    </S.BlueWarningPanelContainer>
  )
}
