import React from 'react'
import { theme } from '@provi/provi-components'

export const IconFilter = ({ isDark = false }) => {
  const color = isDark ? theme.colors.blue900 : '#DE0C4B'
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="https://www.w3.org/2000/svg">
      <line x1="1" y1="1.73389" x2="9" y2="1.73389" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="4.68652" x2="6" y2="4.68652" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="7.63965" x2="3" y2="7.63965" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export const SelectedIconFilter = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="https://www.w3.org/2000/svg">
    <circle cx="10.5" cy="10.5" r="10.5" fill="#DE0C4B" />
    <line x1="7" y1="8" x2="15" y2="8" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="7" y1="10.9531" x2="12" y2="10.9531" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="7" y1="13.9053" x2="9" y2="13.9053" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
)
