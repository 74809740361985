import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Header = styled.div`
  width: 100%;

  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey600};
`

export const Content = styled.div``

export const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};
  margin: 26px 0;
`

export const WrapperOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
`

export const Option = styled.div`
  padding: 5px;
`

export const ContentOption = styled.div`
  border: 1px solid ${theme.colors.blue900};
  box-sizing: border-box;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};
  width: 100%;
`

export const SubTitle = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
`
export const OptionText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};
`

export const WrapperTexts = styled.div`
  text-align: center;
`

export const Anchor = styled.div``

export const HeaderValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
`

export const DiscountValue = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.pink900};
  font-family: 'CenturyGothicBold';
`

export const EntranceValue = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.blue600};
  font-family: 'CenturyGothicBold';
`

export const EntranceOption = styled.div`
  color: ${theme.colors.blue600};
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
`

export const InstallmentValue = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.grey600};
  font-family: 'CenturyGothicBold';
`

export const Section = styled.div`
  margin-top: 30px;
`

const animateFadeIn = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.pink900};
  padding: 15px 25px;
  /* min-height: 96px; */
  border-radius: 5px;
  margin: 50px 0;

  animation-name: ${animateFadeIn};
  animation-duration: 500ms;
`
