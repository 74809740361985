import React from 'react'
import ReactDOM from 'react-dom'
import { Navigation } from '~/navigation'
// import { Navigation } from './router'
import { TemplateProvider } from './components/Template/provider'
import { Provider } from './stores/provider'
import ErrorBoundary from './components/error-boundary'
import 'moment/locale/pt-br'
import './config'
import './style.css'
// import './newStyles.css'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { ReactQueryWrapper } from '~/stores/react-query'

import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: ['Montserrat:400,500,600,700', 'sans-serif'],
  },
})

// dev mode #coffe
if (process?.env?.NODE_ENV !== 'development') {
  console.log = () => {}
}
console.error = () => {}
console.warn = () => {}

ReactDOM.render(
  <ErrorBoundary>
    <ReactQueryWrapper>
      <TemplateProvider>
        <Provider>
          <Navigation />
        </Provider>
      </TemplateProvider>
    </ReactQueryWrapper>
  </ErrorBoundary>,
  document.getElementById('root'),
)
