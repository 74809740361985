import { theme } from '@provi/provi-components'
import { makeStyles } from '@material-ui/core/styles'

export const getTableStyles = ({ headColor, isLowDesktop, lowTableStyle }) => {
  return makeStyles({
    table: {
      width: '100%',
      minWidth: 1000,
    },
    container: {
      maxHeight: '100%',
      minHeight: '100%',
    },
    headerTitle: {
      whiteSpace: 'nowrap',
      color: theme.colors.blue900,
      fontSize: '12px',
      lineHeight: '24px',
      fontFamily: 'CenturyGothicBold !important',
      backgroundColor: `${headColor || theme.colors.grey600} !important`,
      position: 'sticky',
      padding: isLowDesktop || lowTableStyle ? '16px 7px !important' : '16px !important',
    },
    cell: {
      whiteSpace: 'nowrap',
      color: theme.colors.blue900,
      fontSize: isLowDesktop || lowTableStyle ? '12px' : '16px',
      lineHeight: isLowDesktop || lowTableStyle ? '24px' : '30px',
      fontFamily: 'CenturyGothic !important',
      padding: isLowDesktop || lowTableStyle ? '7px !important' : '16px !important',
    },
    cellFixed: {
      whiteSpace: 'nowrap',
      color: theme.colors.blue900,
      fontSize: isLowDesktop || lowTableStyle ? '12px' : '16px',
      lineHeight: isLowDesktop || lowTableStyle ? '24px' : '30px',
      fontFamily: 'CenturyGothicBold !important',
      padding: isLowDesktop || lowTableStyle ? '7px !important' : '16px !important',
    },
    tableBody: {
      position: 'relative',
    },
  })
}
