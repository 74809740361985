import React from 'react'

export const Lock = ({ color = '#DE0C4B' }) => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M4 7.75V4.75C4 3.75544 4.39509 2.80161 5.09835 2.09835C5.80161 1.39509 6.75544 1 7.75 1C8.74456 1 9.69839 1.39509 10.4017 2.09835C11.1049 2.80161 11.5 3.75544 11.5 4.75V7.75M2.5 7.75H13C13.8284 7.75 14.5 8.42157 14.5 9.25V14.5C14.5 15.3284 13.8284 16 13 16H2.5C1.67157 16 1 15.3284 1 14.5V9.25C1 8.42157 1.67157 7.75 2.5 7.75Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
