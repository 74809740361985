import React from 'react'
import { useNonPaymentsDashboard } from './hooks'
import Selector from 'react-select'
import { Menu, Option, MultiValue, animatedComponents, colourStyles } from '~/components/CheckboxSelector/Options'
import {
  DefaultComparisonChart,
  CollectionsChart,
  DefaultRateChart,
  DefaultStatusChart,
} from '~/screens/dashboard/NonPayments/components'
import { PageWrapper, Container, MobileFallback, ContainerSelector } from './styles'
import { Header, HeaderTitle, About, HeaderContent } from '../global'
import { DesktopTypeForm } from '~/components/v2/TypeForm'

const extraTypeformConfigs = {
  id: 'NLLwci7C',
  onSubmit: () => localStorage.setItem('submittedNonPaymentsTypeform', 'true'),
}

export const NonPaymentsDashboard = () => {
  const {
    isMobile,
    drawerWidth,
    productsFilter,
    selectedProducts,
    setSelectedProducts,
    onFilter,
    filters,
    showTypeform,
  } = useNonPaymentsDashboard()

  return (
    <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      <Container>
        {showTypeform && <DesktopTypeForm extraTypeformConfigs={extraTypeformConfigs} />}
        {isMobile ? (
          <MobileFallback>Não temos versão mobile ainda</MobileFallback>
        ) : (
          <>
            <Header>
              <HeaderContent>
                <HeaderTitle>Carteira</HeaderTitle>
                <ContainerSelector>
                  <Selector
                    placeholder="Filtrar por cursos"
                    name="products"
                    options={productsFilter}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Menu,
                      Option,
                      MultiValue,
                      animatedComponents,
                    }}
                    onChange={setSelectedProducts}
                    allowSelectAll={true}
                    styles={colourStyles}
                    value={selectedProducts}
                    onFilter={onFilter}
                    onClear={() => setSelectedProducts(null)}
                  />
                </ContainerSelector>
              </HeaderContent>
              <About>O conteúdo desta página refere-se à forma de pagamento de Crédito Educacional Dinâmico (MaaS).</About>
            </Header>
            <CollectionsChart filters={filters} />
            <DefaultComparisonChart filters={filters} />
            <DefaultStatusChart filters={filters} />
            <DefaultRateChart filters={filters} />
          </>
        )}
      </Container>
    </PageWrapper>
  )
}
