import { useContext, useMemo, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { IntegrationsContext } from '~/screens/integrations/context'
import { useFetchMemberkitIntegrations } from '~/screens/integrations/requests'

export const useMemberkitIntegrations = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const { setCurrentIntegrationName, setCurrentIntegrationId, setFlowState } = useContext(IntegrationsContext)

  const extraIntegrationsConfig = useMemo(
    () => ({
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas integrações! 😔 Por favor, tente novamente.')
      },
    }),
    [],
  )
  const { data: IntegrationsList, isFetching: isFetchingIntegrationsList } = useFetchMemberkitIntegrations(
    extraIntegrationsConfig,
  )

  useEffect(() => {
    if (isFetchingIntegrationsList) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isFetchingIntegrationsList])

  const handleManageAccount = (integrationName, integrationId) => {
    setCurrentIntegrationName(integrationName)
    setCurrentIntegrationId(integrationId)
    setFlowState('Gerenciar conta')
  }

  return { IntegrationsList, isFetchingIntegrationsList, setCurrentIntegrationId, handleManageAccount }
}
