import { useState, useMemo, useEffect, useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { toast } from 'react-toastify'
import { getAdminFinancingConditions } from '~/services/api'
import { logger } from '~/helpers/logger'
import masks from '~/themes/masks'

export const useFlexCalculator = ({ calcConfig, handleModal }) => {
  const { setIsLoading } = useContext(TemplateContext)
  const [calcData, setCalcData] = useState()
  const [currentSelectedOption, setCurrentSelectedOption] = useState(0)

  const selectedOptionInstallments = useMemo(() => calcData?.otherOptions[currentSelectedOption], [
    currentSelectedOption,
    calcData,
  ])

  const copyText = useMemo(() => {
    if (!selectedOptionInstallments) return ''

    try {
      const formattedUpFront = masks.currency(selectedOptionInstallments?.upfrontAmountInCents)
      const formattedInstallmentAmount = masks.currency(
        calcData.totalWithoutFeeInCents - selectedOptionInstallments?.upfrontAmountInCents,
      )

      let upFrontAndInstallmentsTotal = ''

      if (selectedOptionInstallments?.upfrontAmountInCents !== 0) {
        upFrontAndInstallmentsTotal += `Entrada*: ${formattedUpFront}\nParcelado: ${formattedInstallmentAmount}\n\n`
      }

      const formattedOptions = selectedOptionInstallments?.installments?.map(({ quantity, priceInCents, monthlyInterest }) => ({
        quantity,
        priceInCents: masks.currency(priceInCents),
        monthlyInterest: (monthlyInterest * 100).toFixed(2),
      }))

      let eachInstallments = ''

      formattedOptions.forEach((eachOption, index) => {
        eachInstallments += `Opção ${index + 1}: `

        eachInstallments += `${eachOption.quantity}x de `
        eachInstallments += masks.currency(eachOption.priceInCents)

        if (eachOption.monthlyInterest === 0) {
          eachInstallments += ' (sem juros)'
        } else {
          eachInstallments += ` (juros de ${eachOption.monthlyInterest}% a.m) \n`
        }
      })

      return upFrontAndInstallmentsTotal + eachInstallments
    } catch (err) {
      logger({ err, screen: 'flexCalculator-copyText' })
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    }
  }, [selectedOptionInstallments, calcData])

  const formatInstallmentsOption = (installments) => {
    if (!installments) return

    try {
      const { quantity, priceInCents, monthlyInterest } = installments

      let text = ''

      text += `${quantity}x de `
      text += masks.currency(priceInCents)

      if (monthlyInterest === 0) {
        return (text += ' (sem juros)')
      }

      return (text += ` (juros de ${(monthlyInterest * 100).toFixed(2)}% a.m)`)
    } catch (err) {
      logger({ err, screen: 'flexCalculator-formatInstallmentsOption' })
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    }
  }

  const buildHeaderAttributesCalculator = (flexSelectedOption) => {
    try {
      const { selectedOption } = flexSelectedOption

      return [
        {
          attribute: 'Entrada',
          value: selectedOption ? masks.currency(selectedOption?.upfrontAmountInCents) : '-',
        },
        {
          attribute: 'Desconto',
          value: selectedOption ? `- ${masks.currency(selectedOption?.totalDiscountInCents)}` : '-',
        },
        {
          attribute: 'Parcelas',
          value: selectedOption ? formatInstallmentsOption(selectedOption?.installments) : '-',
        },
        {
          attribute: 'Total a pagar',
          value: selectedOption ? masks.currency(selectedOption?.totalInCents) : '-',
        },
      ]
    } catch (err) {
      logger({ err, screen: 'flexCalculator-buildHeaderAttributesCalculator' })
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    }
  }

  const buildInstallments = (eachInstallmentObj) => {
    try {
      const { quantity, priceInCents, monthlyInterest } = eachInstallmentObj || {}
      const installmentPrice = masks.currency(priceInCents)

      return monthlyInterest === 0
        ? `${quantity}x de ${installmentPrice} (sem juros)`
        : `${quantity}x de ${installmentPrice} (juros de ${(monthlyInterest * 100).toFixed(2)} % a.m)`
    } catch (err) {
      logger({ err, screen: 'flexCalculator-buildInstallments' })
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    }
  }

  const getFlexSimulationData = async (crid) => {
    try {
      setIsLoading(true)
      const response = await getAdminFinancingConditions(crid)

      if (response.status > 199 && response.status < 300) {
        setCalcData(response.data.content)
      }
    } catch (error) {
      if (error.response.status > 399 && error.response.status < 500) {
        toast(error.response.data.error.message || error.response.message)
      } else {
        toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
      }
      handleModal()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const { creditRequestId } = calcConfig || {}
    getFlexSimulationData(creditRequestId)
  }, [])

  return {
    calcData,
    buildHeaderAttributesCalculator,
    currentSelectedOption,
    setCurrentSelectedOption,
    selectedOptionInstallments,
    buildInstallments,
    copyText,
  }
}
