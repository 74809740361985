import React from 'react'
import { Link } from 'react-router-dom'
import { Tags, ActionButton } from '~/components/v2/TableV2/components'
import { getActiveUser, parseISOThenFormatDate } from '~/utils'
import {
  masks,
  formatCancellationStatus,
  handleGoToDetailsPage,
  formatCancellationWarranty,
  fallbackString,
  handleMobileToggle,
  StyledMobileSpan,
} from '~/screens/requests/helpers'

export const cancellationsColumns = [
  {
    Header: 'Ações',
    accessor: 'canBeContested',
    Cell: ({ value, defaultColumn, row }) => (
      <ActionButton
        text={'Reverter'}
        disabled={!value}
        onClick={() => handleGoToDetailsPage(defaultColumn, row.original, true)}
      />
    ),
  },
  {
    Header: 'Prazo para resposta',
    accessor: 'responseDeadline',
    Cell: ({ value, row, defaultColumn }) => (
      <StyledMobileSpan onClick={() => handleMobileToggle(row, defaultColumn)}>
        {parseISOThenFormatDate(value)}
      </StyledMobileSpan>
    ),
  },
  {
    Header: 'Status do pedido',
    accessor: 'status',
    Cell: ({ value, row, defaultColumn }) => {
      const { status, statusColor, textColor } = formatCancellationStatus(value)
      return (
        <span onClick={() => handleMobileToggle(row, defaultColumn)}>
          <Tags value={status} color={statusColor} textColor={textColor} />
        </span>
      )
    },
    disableFilters: false,
  },
  {
    Header: 'Prazo de garantia',
    accessor: 'warrantyPeriod',
    Cell: ({ value }) => formatCancellationWarranty(value),
  },
  {
    Header: 'ID',
    accessor: 'CreditRequestId',
    Cell: ({ value }) => (
      <Link
        to={`/detalhes/${value}?u=${getActiveUser()}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontWeight: 'bold', cursor: 'pointer', color: '#414141' }}
      >
        {value || fallbackString}
      </Link>
    ),
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    Cell: ({ value }) => masks.cpf(value) || fallbackString,
  },
  {
    Header: 'Motivo',
    accessor: 'comment',
    Cell: ({ value }) => value || fallbackString,
  },
  {
    Header: 'Data da solicitação',
    accessor: 'requestDate',
    Cell: ({ value }) => parseISOThenFormatDate(value),
  },
]
