import { useState, useEffect, useContext } from 'react'
import { useToggle } from '~/hooks'

import { TemplateContext } from '~/components/Template/context'
import { getChartDefaultComparison } from '~/services/api'

export const useDefaultComparisonChart = (filters) => {
  const { isMobile } = useContext(TemplateContext)
  const [chartData, setChartData] = useState([])
  const [chartLoadingState, toggleChartLoadingState] = useToggle()

  useEffect(() => {
    const getChartData = async () => {
      try {
        toggleChartLoadingState()
        const { data } = await getChartDefaultComparison(filters)
        setChartData(data)
      } catch (error) {
        console.error(error)
      } finally {
        toggleChartLoadingState()
      }
    }
    getChartData()
  }, [filters])

  return { isMobile, chartData, chartLoadingState }
}
