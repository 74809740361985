import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-family: 'CenturyGothic';
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold';
`

export const Br = styled.br``
