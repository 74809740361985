import { formatValue, isEmptyObj } from '~/utils'

export const formatCoursesText = (coursesObj) => {
  if (isEmptyObj(coursesObj)) {
    return ''
  }

  const { name, courseClassName, courseClassPrice, courseClassId, price } = coursesObj

  return courseClassId
    ? `Curso: ${name}, Turma: ${courseClassName} - ${formatValue(courseClassPrice)}`
    : `Curso: ${name} - ${formatValue(price)}`
}
