import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const IntegrationCheckContainer = styled.div`
  width: fit-content;
  border: 1px solid ${theme.colors.bluePrimary};
  border-radius: 4px;
  padding: 6px;
`

export const IntegrationCheckFlex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const IntegrationCheckText = styled.p`
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 9px;
  }
`
