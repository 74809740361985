import styled from 'styled-components'
import { theme } from '@provi/provi-components'

const handleStatusColor = (status) => {
  switch (status) {
    case 'paid':
      return theme.colors.green100
    case 'delayed':
      return theme.colors.pink900
    case 'yellow':
      return theme.colors.yellow900
    default:
      return 'none'
  }
}

export const Section = styled.div`
  ${({ mTop }) => (mTop ? `margin-top: ${mTop};` : ``)}
  width: 100%;
`
export const Header = styled.div``

export const Option = styled.div`
  width: 100%;
  /* height: 80px; */
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  border: 1px solid ${({ status }) => handleStatusColor(status)};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
`

export const HeaderOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
export const Right = styled.div`
  display: flex;
  align-items: center;
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Circle = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${({ status }) => handleStatusColor(status !== 'paid' && status !== 'delayed' ? 'yellow' : status)};
  margin-left: 5px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  ${({ mTop }) => (mTop ? `margin-top: ${mTop};` : ``)}
`

export const Item = styled.div`
  margin-right: 15px;
  min-width: 185px;
`

export const TextRef = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: ${({ status }) => handleStatusColor(status !== 'paid' && status !== 'delayed' ? 'yellow' : status)};
`
