import React from 'react'
import { theme } from '@provi/provi-components'
import { Text } from '~/themes/styles'

import { Wrapper, Section, IconSection } from './styles'

export const Option = ({ text, selected, onClick, icon: Icon, available }) => {
  return (
    <Wrapper selected={selected} onClick={onClick} available={available}>
      <Section>
        <IconSection>
          <Icon />
        </IconSection>
        <Text size="16px" lineHeight="24px" bold color={theme.colors.blue600}>
          {text}
        </Text>
      </Section>
    </Wrapper>
  )
}
