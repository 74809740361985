import React, { useContext } from 'react'
import { DateRange } from 'react-date-range'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core'
import { ptBR } from 'date-fns/locale'
import { theme } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { InputSelector } from '~/components/InputSelector'
import { SearchIcon } from '~/assets/svgs/v2'
import { ArrowTop } from '~/assets/svgs/arrow-top'
import { ArrowDown } from '~/assets/svgs/arrow-down'
import { IconFilter, SelectedIconFilter } from '~/assets/svgs/icon-filter'
import { QuestionMark } from '~/assets/svgs/QuestionMark'
import { Text } from '~/themes/styles'

import { getTableStyles } from './helpers/getTableStyles'

import { useTable } from './hooks'
import {
  ContainerTableMobile,
  TableMobile,
  Head,
  Row,
  Cell,
  Body,
  ContentRow,
  Value,
  Information,
  Indicator,
  SectionInformation,
  WrapperLabel,
  WrapperHeadButtons,
  HeadSecond,
  DropItens,
  HoverSelect,
  CheckBox,
  Active,
  TextTableCell,
  TextTableCellFilter,
  TextMobileName,
  DropBody,
  DropOption,
  WrapperCheck,
  DropFooter,
  DropFooterSection,
  FilterButton,
  TextLink,
  SquareCheckBox,
  SquareActive,
  WrapperQuestionMark,
  DefaultButton,
  ListsBody,
  VerticalLine,
  useOutlinedInputStyles,
} from './styles'

export const SimpleTable = (props) => {
  const {
    showCells,
    rows,
    headAlign = 'left',
    headColor,
    origin = 'lists',
    setShowCells,
    setFilterStatus,
    setPage,
    lowTableStyle = false,
    handleModal,
    buttonClick = null,
    selectChange = null,
  } = props

  const { isMobile, isLowDesktop } = useContext(TemplateContext)
  const {
    clicked,
    handleOptionTableMobile,
    handleOptionTable,
    optionActive,
    handleSelect,
    handleClick,
    selectOpen,
    closeSelects,
    submitFilter,
    handleClearFilters,
    handleSelectAll,
    startDatePicker,
    endDatePicker,
    setRangeDates,
    handleBlankDate,
    handleQuestionMark,
    defaultTrigger,
    triggerFormat,
    filterSearch,
    setFilterSearch,
    filteredShowCells,
  } = useTable(setShowCells, setFilterStatus, setPage, showCells, handleModal)

  const useStyles = getTableStyles({ headColor, isLowDesktop, lowTableStyle })

  const outlinedInputClasses = useOutlinedInputStyles()

  const classes = useStyles()

  if (isMobile && origin != 'nfse') {
    if (origin === 'lists') {
      return (
        <ContainerTableMobile>
          <TableMobile>
            <Head fixed>
              <Row>
                <ContentRow head>
                  <Cell head width="10%" />
                  <Cell head width="40%">
                    CPF
                  </Cell>
                  <Cell head width="40%">
                    Nome
                  </Cell>
                  <Cell head width="10%" />
                </ContentRow>
              </Row>
            </Head>
            <ListsBody>
              {rows &&
                rows.length > 0 &&
                rows.map((r, i) => (
                  <Row key={i + 1}>
                    <ContentRow disabledInformation={optionActive !== i + 1} onClick={() => handleOptionTable(i + 1)}>
                      <Cell width="10%" align="center">
                        <Indicator status={r.statusCondition} />
                      </Cell>
                      <Cell width="40%">{r.cpf}</Cell>
                      <Cell width="40%">{isMobile ? <TextMobileName>{r.name}</TextMobileName> : r.name}</Cell>
                      <Cell width="10%" align="center">
                        {optionActive !== i + 1 ? <ArrowTop /> : <ArrowDown />}
                      </Cell>
                    </ContentRow>
                    <Information key={i + 1} disabled={optionActive !== i + 1}>
                      <Cell width="100%">
                        {showCells &&
                          showCells.map((cells) => (
                            <>
                              {cells.show && (
                                <SectionInformation>
                                  <Text bold color={theme.colors.grey600}>
                                    {cells.label}
                                  </Text>
                                  <Value>{r[cells.item]}</Value>
                                </SectionInformation>
                              )}
                            </>
                          ))}
                      </Cell>
                    </Information>
                  </Row>
                ))}
            </ListsBody>
          </TableMobile>
        </ContainerTableMobile>
      )
    }
    if (origin === 'users') {
      return (
        <ContainerTableMobile>
          <TableMobile>
            <Head>
              <Row>
                <ContentRow head>
                  <Cell head width="100%">
                    Usuários
                  </Cell>
                </ContentRow>
              </Row>
            </Head>
            <Body>
              {rows &&
                rows.length > 0 &&
                rows.map((r, i) => (
                  <Row key={i + 1}>
                    <ContentRow disabledInformation={optionActive !== i + 1} onClick={() => handleOptionTable(i + 1)}>
                      <Cell width="90%" align="left">
                        {r.email}
                      </Cell>
                      <Cell width="10%" align="center">
                        {optionActive !== i + 1 ? <ArrowTop /> : <ArrowDown />}
                      </Cell>
                    </ContentRow>
                    <Information key={i + 1} disabled={optionActive !== i + 1}>
                      <Cell width="100%" normal>
                        {showCells &&
                          showCells.map((cells) => (
                            <>
                              {cells.show && (
                                <SectionInformation>
                                  <Value flex>
                                    {r[cells.item]} {cells.label && <WrapperLabel>{cells.label}</WrapperLabel>}
                                  </Value>
                                </SectionInformation>
                              )}
                            </>
                          ))}
                      </Cell>
                    </Information>
                  </Row>
                ))}
            </Body>
          </TableMobile>
        </ContainerTableMobile>
      )
    }
    if (origin === 'accessLevel') {
      return (
        <ContainerTableMobile>
          <TableMobile>
            <HeadSecond>
              <Row>
                <WrapperHeadButtons>
                  {rows &&
                    rows.length > 0 &&
                    rows.map((r, i) => (
                      <Cell key={i + 1} head width="40%" onClick={() => handleOptionTableMobile(r.accessLevelString)}>
                        {r.accessLevel}
                      </Cell>
                    ))}
                </WrapperHeadButtons>
              </Row>
            </HeadSecond>
            <Body>
              {rows &&
                rows.length > 0 &&
                rows
                  .filter((r) => {
                    return r.accessLevelString === clicked
                  })
                  .map((r, i) => (
                    <Row key={i + 1}>
                      <Information key={i + 1}>
                        <Cell width="100%" normal>
                          {showCells &&
                            showCells.map((cells) => (
                              <>
                                {cells.show && (
                                  <SectionInformation>
                                    <Value flex>
                                      {r[cells.item]} {cells.label && <WrapperLabel>{cells.label}</WrapperLabel>}
                                    </Value>
                                  </SectionInformation>
                                )}
                              </>
                            ))}
                        </Cell>
                      </Information>
                    </Row>
                  ))}
            </Body>
          </TableMobile>
        </ContainerTableMobile>
      )
    }
    if (origin === 'details') {
      return (
        <ContainerTableMobile>
          <TableMobile>
            <Head>
              <Row>
                <ContentRow head>
                  <Cell head width="10%" />
                  <Cell head width="40%">
                    Parcelas
                  </Cell>
                  <Cell head width="40%">
                    Status
                  </Cell>
                  <Cell head width="10%" />
                </ContentRow>
              </Row>
            </Head>
            <Body>
              {rows &&
                rows.length > 0 &&
                rows.map((r, i) => (
                  <Row key={i + 1}>
                    <ContentRow disabledInformation={optionActive !== i + 1} onClick={() => handleOptionTable(i + 1)}>
                      <Cell width="10%" align="center">
                        <Indicator status={r.statusCondition} />
                      </Cell>
                      <Cell width="40%">{r.installments}</Cell>
                      <Cell width="40%">
                        <TextMobileName>{r.status}</TextMobileName>
                      </Cell>
                      <Cell width="10%" align="center">
                        {optionActive !== i + 1 ? <ArrowTop /> : <ArrowDown />}
                      </Cell>
                    </ContentRow>
                    <Information key={i + 1} disabled={optionActive !== i + 1}>
                      <Cell width="100%">
                        {showCells &&
                          showCells.map((cells) => (
                            <>
                              {cells.show && (
                                <SectionInformation>
                                  <Text bold color={theme.colors.grey600}>
                                    {cells.label}
                                  </Text>
                                  <Value>{r[cells.item]}</Value>
                                </SectionInformation>
                              )}
                            </>
                          ))}
                      </Cell>
                    </Information>
                  </Row>
                ))}
            </Body>
          </TableMobile>
        </ContainerTableMobile>
      )
    }
  } else {
    const Rows =
      rows &&
      rows.length > 0 &&
      rows.map((row) => (
        <TableRow key={row.id}>
          {showCells &&
            showCells.map((cell) => (
              <>
                {cell.show && (
                  <TableCell className={cell.blocked ? classes.cellFixed : classes.cell} align="left">
                    {cell.button ? (
                      <DefaultButton isDefault={row[cell.item]} onClick={() => (row[cell.item] ? null : buttonClick(row))}>
                        {row[cell.item] ? cell.button.active : cell.button.notActive}
                      </DefaultButton>
                    ) : cell.select ? (
                      <InputSelector
                        defaultTrigger={defaultTrigger}
                        placeholder="Selecione a expedição da nota"
                        triggerFormat={triggerFormat}
                        onChange={selectChange}
                        row={row}
                        cell={cell}
                      />
                    ) : (
                      row[cell.item] || '---'
                    )}
                  </TableCell>
                )}
              </>
            ))}
        </TableRow>
      ))

    return (
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {filteredShowCells &&
                filteredShowCells.map(
                  (cell) =>
                    cell.show && (
                      <TableCell className={classes.headerTitle} align={headAlign}>
                        {cell.isFilter ? (
                          <TextTableCellFilter onClick={(e) => handleSelect(e, cell)}>
                            {cell.label}
                            {cell.isChanged ? <SelectedIconFilter /> : <IconFilter isDark />}
                            {cell.isHelper && (
                              <WrapperQuestionMark onClick={(event) => handleQuestionMark(event, cell)}>
                                <QuestionMark />
                              </WrapperQuestionMark>
                            )}
                          </TextTableCellFilter>
                        ) : (
                          <TextTableCell>
                            {cell.label}
                            {cell.isHelper && (
                              <WrapperQuestionMark onClick={(event) => handleQuestionMark(event, cell)}>
                                <QuestionMark />
                              </WrapperQuestionMark>
                            )}
                          </TextTableCell>
                        )}

                        {cell.showSelect && (
                          <DropItens>
                            {!cell.isDate && (
                              <OutlinedInput
                                value={filterSearch}
                                onChange={(e) => setFilterSearch(e.target.value)}
                                fullWidth
                                placeholder="Pesquisar opções"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                }
                                classes={outlinedInputClasses}
                              />
                            )}

                            <DropBody isDate={cell.isDate}>
                              {cell.selectValues &&
                                !cell.isDate &&
                                cell.selectValues.map((item) => (
                                  <DropOption key={item.id} onClick={() => handleClick(item, cell)}>
                                    <WrapperCheck>
                                      <CheckBox>{item.active && <Active />}</CheckBox>
                                      {item.label}
                                    </WrapperCheck>
                                  </DropOption>
                                ))}

                              {cell.isDate && (
                                <DateRange
                                  editableDateInputs={false}
                                  onChange={(e) => setRangeDates(e, cell)}
                                  moveRangeOnFirstSelection={false}
                                  locale={ptBR}
                                  weekdayDisplayFormat="EEEEEE"
                                  ranges={[
                                    {
                                      startDate: cell.isChanged ? cell.startDate || startDatePicker : new Date(),
                                      endDate: cell.isChanged ? cell.endDate || endDatePicker : new Date(),
                                      key: 'selection',
                                    },
                                  ]}
                                />
                              )}
                            </DropBody>
                            <DropFooter>
                              <FilterButton onClick={() => submitFilter(cell)}>Filtrar</FilterButton>

                              <VerticalLine />
                              <DropFooterSection>
                                <TextLink onClick={() => handleClearFilters(cell)}>Limpar filtros</TextLink>
                                {!cell.isDate && <TextLink onClick={() => handleSelectAll(cell)}>Selecionar todos</TextLink>}
                                {cell.blankDatePermitted && (
                                  <WrapperCheck pointer onClick={() => handleBlankDate(cell)}>
                                    <SquareCheckBox>{cell.isBlankDate && <SquareActive />}</SquareCheckBox>
                                    <Text>Data em branco</Text>
                                  </WrapperCheck>
                                )}
                              </DropFooterSection>
                            </DropFooter>
                          </DropItens>
                        )}
                      </TableCell>
                    ),
                )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {selectOpen && <HoverSelect onClick={() => closeSelects()} />}
            {Rows}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}
