/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { controlPermissions } from '../helpers/controlPermissions'
import { CheckBox, Active, WrapperOrigin, BoxButton } from './styles'
import { capitalize, asyncLocalStorage } from '../../../utils/index'
import { generatePassword, updateUser } from '~/services/api'

export const useEditUsers = (setIsLoading, isMobile, activePage, setActivePage, accessData) => {
  const [showCells, setShowCells] = useState([])
  const [rows, setRows] = useState([])
  const [warningMessage, setWarningMessage] = useState('')
  const [link, setLink] = useState(false)
  const [clicked, setClicked] = useState(accessData?.accessLevel)
  const [email, setEmail] = useState(``)
  const [id, setId] = useState('')
  const [active, setActive] = useState(null)
  const [visible, setVisible] = useState(null)
  const [data, setData] = useState({ login: '', password: '' })
  const [password, setPassword] = useState('')
  const [optionActive, setOptionActive] = useState({ visibleAccessLevel: null })

  const { accessLevel: accessLevelFromContext } = accessData

  useEffect(() => {
    let initialState = [
      { item: 'salesManagement', label: 'Criação e gerenciamento de vendas', show: true, blocked: true },
      { item: 'createAndEditCampaing', label: 'Criar e editar curso/campanha', show: true, blocked: true },
      { item: 'salesVisualization', label: 'Visualização completa de vendas', show: true, blocked: true },
      { item: 'monitoringDashboard', label: 'Dashboard de Acompanhamento', show: true, blocked: true },
      { item: 'receivesControl', label: 'Controle de recebimentos', show: true },
      { item: 'acessManagement', label: 'Gestão de acessos da plataforma', show: true, blocked: true },
    ]
    if (!isMobile) {
      initialState = [{ item: 'accessLevel', label: 'Nível de visualização', show: true, blocked: true }, ...initialState]
    }
    setShowCells(initialState)
    setClicked(accessLevelFromContext)
  }, [accessLevelFromContext])

  const handleClicked = async (v) => {
    setClicked(v)
  }

  const handleCheck = async (v) => {
    setIsLoading(true)
    setVisible(v)
    setOptionActive({ ...optionActive, visibleAccessLevel: v })
    setIsLoading(false)
  }

  const generateTable = () => {
    setIsLoading(true)
    if (activePage !== 'user-control') setActivePage('user-control')
    const getData = async () => {
      const accessForSuperAdmin = ['superadmin', 'admin', 'senior', 'pleno', 'junior', 'fiscal']
      const accessForBelowSuperAdmin = ['admin', 'senior', 'pleno', 'junior']
      const tableRenderByAccess = accessLevelFromContext === 'superadmin' ? accessForSuperAdmin : accessForBelowSuperAdmin
      const rowsResponse = tableRenderByAccess.map((accessLevel) => {
        const salesManagement = controlPermissions[accessLevel].salesManagement
        const createAndEditCampaing = controlPermissions[accessLevel].createAndEditCampaing
        const salesVisualization = controlPermissions[accessLevel].salesVisualization
        const monitoringDashboard = controlPermissions[accessLevel].monitoringDashboard
        const acessManagement = controlPermissions[accessLevel].acessManagement
        const receivesControl = controlPermissions[accessLevel].receivesControl
        return {
          accessLevelString: accessLevel,
          accessLevel: (
            <BoxButton
              active={clicked === accessLevel}
              onClick={async () => {
                await handleClicked(accessLevel)
              }}
            >
              {capitalize(accessLevel)}
            </BoxButton>
          ),
          salesManagement: (
            <WrapperOrigin>
              <CheckBox>{salesManagement && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          createAndEditCampaing: (
            <WrapperOrigin>
              <CheckBox>{createAndEditCampaing && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          salesVisualization: (
            <WrapperOrigin>
              <CheckBox>{salesVisualization && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          monitoringDashboard: (
            <WrapperOrigin>
              <CheckBox>{monitoringDashboard && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          acessManagement: (
            <WrapperOrigin>
              <CheckBox>{acessManagement && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
          receivesControl: (
            <WrapperOrigin>
              <CheckBox>{receivesControl && <Active isClicked={accessLevel === clicked} />}</CheckBox>
            </WrapperOrigin>
          ),
        }
      })
      setIsLoading(false)
      setRows([...rowsResponse])
    }
    getData()
  }

  useEffect(() => {
    setIsLoading(true)
    const getData = async () => {
      try {
        const emailPartnerEmployee = await asyncLocalStorage.getItem('emailPartnerEmployee')
        const partnerEmployeeId = await asyncLocalStorage.getItem('partnerEmployeeId')
        setEmail(emailPartnerEmployee)
        setId(partnerEmployeeId)
        await generateTable()
        setIsLoading(false)
      } catch (error) {
        setWarningMessage('Ops, ocorreu um erro. Atualize essa página e entre em contato com a Provi se o erro permanecer.')
      }
    }
    getData()
  }, [])

  useEffect(() => {
    generateTable()
  }, [clicked])

  const handleUpdate = async (option) => {
    setIsLoading(true)
    if (!option) return
    if (option === 'accessLevel') {
      try {
        const response = await updateUser({ partnerEmployeeId: id, accessLevel: clicked })
        const { error } = response.data
        if (error) {
          showToast(`Error atualizando o nível de acesso desse usuário`)
        } else {
          setData({ login: email, password })
          setLink(true)
        }
        setIsLoading(false)
      } catch (error) {
        showToast('Houve um erro na hora de atualizar dados desse usuário')
        setIsLoading(false)
      }
    } else if (option === 'password') {
      try {
        const response = await generatePassword(id)
        const { error } = response.data
        if (error) {
          showToast('Erro ao gerar nova senha para este usuário')
        } else {
          setLink(true)
        }
        setIsLoading(false)
      } catch (error) {
        showToast('Houve um erro na hora de atualizar dados desse usuário')
        setIsLoading(false)
      }
    }
  }

  return [email, visible, handleCheck, handleUpdate, optionActive, link, showCells, setRows, rows, warningMessage, data]
}
