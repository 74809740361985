/* eslint-disable react/prop-types */
import React from 'react'
import { Route } from 'react-router-dom'

import { Template, LoginTemplate } from '~/components/Template'

export default function RouteWrapper(props) {
  const { component: Component, isFirstScreen, isHeader, isTemplate, templateIntro, ...rest } = props

  if (templateIntro) {
    return (
      <Route
        {...rest}
        render={(prop) => (
          <>
            {isTemplate ? (
              <>
                <LoginTemplate isFirstScreen={isFirstScreen} isHeader={isHeader}>
                  <Component {...prop} />
                </LoginTemplate>
              </>
            ) : (
              <Component {...prop} />
            )}
          </>
        )}
      />
    )
  }

  return (
    <>
      <Route
        {...rest}
        render={(prop) => (
          <>
            {isTemplate ? (
              <>
                <Template isFirstScreen={isFirstScreen} isHeader={isHeader} templateIntro={templateIntro}>
                  <Component {...prop} />
                </Template>
              </>
            ) : (
              <Component {...prop} />
            )}
          </>
        )}
      />
    </>
  )
}

RouteWrapper.defaultProps = {
  isFirstScreen: false,
  isTemplate: true,
}
