import { theme } from '@provi/provi-components'

const translattedStatusOptions = [
  {
    value: 'paid',
    label: 'PAGO',
    color: '#A5ECAB',
    fontColor: '#000',
  },
  {
    value: 'host_available',
    label: 'Liberado',
    color: '#428E00',
    fontColor: '#FFF',
  },
  {
    value: 'pending',
    label: 'PENDENTE',
    color: '#F55D0C',
    fontColor: '#FFF',
  },
  {
    value: 'effective',
    label: 'EFETIVADO',
    color: '#2647D7',
    fontColor: '#FFF',
  },
  {
    value: 'default',
    label: 'INADIMPLENTE',
    color: theme.colors.pink900,
    fontColor: '#FFF',
  },
  {
    value: 'host_blocked',
    label: 'Bloqueado',
    color: theme.colors.pink900,
    fontColor: '#000',
  },
  {
    value: 'abandonment_after_settled',
    label: 'CANCELADO',
    color: '#000',
    fontColor: '#FFF',
  },
  {
    value: 'abandonment_after_upfront',
    label: 'DESISTÊNCIA APÓS ENTRADA',
    color: theme.colors.pink900,
    fontColor: '#FFF',
  },
  {
    value: 'abandonment_after_signed',
    label: 'DESISTÊNCIA APÓS ASSINATURA',
    color: theme.colors.pink900,
    fontColor: '#FFF',
  },
  {
    value: 'studying',
    label: 'CURSANDO',
    color: '#CFCFCF',
    fontColor: '#000',
  },
  {
    value: 'late_declaration',
    label: 'DECLARAÇÃO DE RENDA ATRASADA',
    color: '#DE0C4B',
    fontColor: '#FFF',
  },
  {
    value: 'awaiting_declaration',
    label: 'AGUARDANDO DECLARAÇÃO',
    color: '#F55D0C',
    fontColor: '#FFF',
  },
  {
    value: 'declaration_review',
    label: 'DECLARAÇÃO EM REVISÃO',
    color: '#FABC01',
    fontColor: '#000',
  },
  {
    value: 'declaration_analysis',
    label: 'DECLARAÇÃO EM ANÁLISE',
    color: '#FDEF74',
    fontColor: '#000',
  },
  {
    value: 'no_income',
    label: 'SEM RENDA',
    color: '#A5ECAB',
    fontColor: '#000',
  },
  {
    value: 'income_below_min',
    label: 'RENDA ABAIXO DO MÍNIMO',
    color: '#A5ECAB',
    fontColor: '#000',
  },
  {
    value: 'negativated',
    label: 'NEGATIVADO',
    color: '#000',
    fontColor: '#FFF',
  },
  {
    value: 'isa_default',
    label: 'DEFAULT',
    color: '#D4DAF7',
    fontColor: '#000',
  },
  {
    value: 'fully_paid',
    label: 'QUITADO',
    color: '#13A41F',
    fontColor: '#000',
  },
  {
    value: 'protest',
    label: 'PROTESTADO',
    color: '#000',
    fontColor: '#FFF',
  },
  { value: null, label: 'NÃO IDENTIFICADO', color: '#BCACAC' },
  {
    value: 'Cancelado',
    label: 'CANCELADO',
    color: '#000',
    fontColor: '#FFF',
  },
  {
    value: 'Ativo',
    label: 'ATIVO',
    color: '#2647D7',
    fontColor: '#FFF',
  },
]

export const formatStatusBox = (status) => {
  return translattedStatusOptions.find((item) => item.value === status)
}
