import React, { useRef } from 'react'
import { Collapse } from '@material-ui/core'

import { useToggle } from '~/hooks'
import { formatMemberkitTableType } from '~/screens/integrations/helpers'
import { formatDate } from '~/utils'
import { IntegrationsModal } from '../../../../components'
import { ArrowDown, EditIcon, DeleteIcon } from '~/assets/svgs/v2'
import { productModalObj } from '~/screens/integrations/constants'
import { EachProductContainer, FlexContainerWithSVG, TableDetailContainer, BlueTitle, TableText } from '../../styles'

export const EachTableDetail = ({ eachObject, index, mappedArr, handleEditProduct, handleUnlinkAllProducts }) => {
  const [dropdownState, toggleDropdownState] = useToggle()
  const {
    course,
    memberkitProductName,
    type,
    associatedClassrooms,
    memberkitProductIds,
    MemberKitProductId,
    courseId,
    createdAt,
    courseClass,
    CourseClassId,
  } = eachObject || {}
  const detailModalRef = useRef()

  const openModal = () => {
    detailModalRef.current.openModalFn()
  }

  const closeModal = () => {
    detailModalRef.current.closeModalFn()
  }

  return (
    <>
      <EachProductContainer isLast={index === mappedArr.length - 1}>
        <FlexContainerWithSVG isHeader onClick={toggleDropdownState} state={dropdownState}>
          <TableText isBold>
            {courseId} - {course} {courseClass && `- Turma: ${courseClass}`}
          </TableText>
          <ArrowDown />
        </FlexContainerWithSVG>
        <Collapse in={dropdownState}>
          <TableDetailContainer>
            <div>
              <BlueTitle>Produto Memberkit</BlueTitle>
              <TableText>
                {type === 'membership_level' && memberkitProductName}
                {memberkitProductName &&
                  type === 'classroom' &&
                  memberkitProductName.map((eachProduct, indexProduct) => (
                    <TableText key={indexProduct}>{eachProduct}</TableText>
                  ))}
                {associatedClassrooms && ' - '}
                {associatedClassrooms &&
                  associatedClassrooms.map((eachProduct, eachIndex) => (eachIndex ? ', ' : '') + eachProduct.name)}
              </TableText>
            </div>
            <div>
              <BlueTitle>Tipo</BlueTitle>
              <FlexContainerWithSVG>
                <TableText>{formatMemberkitTableType(type)}</TableText>
              </FlexContainerWithSVG>
            </div>
            <div>
              <BlueTitle>Data de criação</BlueTitle>
              <FlexContainerWithSVG>
                <TableText>{formatDate(createdAt)}</TableText>
                <EditIcon onClick={() => handleEditProduct(MemberKitProductId, type, courseId, CourseClassId)} />
                <DeleteIcon onClick={openModal} />
              </FlexContainerWithSVG>
            </div>
          </TableDetailContainer>
        </Collapse>
      </EachProductContainer>
      <IntegrationsModal
        modalRef={detailModalRef}
        closeModalFn={closeModal}
        modalObject={productModalObj}
        handleUnlinkProduct={() => handleUnlinkAllProducts(memberkitProductIds)}
      />
    </>
  )
}
