import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Big from 'big.js'
import { showToast } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { logger } from '~/helpers/logger'
import { useMount, useUpdate } from '~/hooks'
import { getAccess, getFiltersCheckout, getFinanceFluxData } from '~/services/api'
import { isEqual } from 'lodash'
import { options } from '../variables'
import * as moment from 'moment'

const useFinancialHooks = () => {
  const { setIsLoading, goToPage, setActivePage } = useContext(TemplateContext)
  const [startDatePicker, setStartDatePicker] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endDatePicker, setEndDatePicker] = useState(new Date())
  const [dateKind, setDateKind] = useState(options[0])
  const [productsFilter, setProductsFilter] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [checkboxSelected, setCheckboxSelected] = useState(null)
  const [financingFluxData, setFinancingFluxData] = useState(null)
  const [requestStatusKind, setRequestStatusKind] = useState(null)
  const [visible, setVisible] = useState(false)
  const [modal, setModal] = useState(false)
  const [conversionFunnelChecked, setConversionFunnelChecked] = useState(false)
  const [accessLevel, setAccessLevel] = useState(null)

  useEffect(() => {
    const getUserAccessLevel = async () => {
      setIsLoading(true)
      const {
        data: {
          data: { accessLevel: responseLevel },
        },
      } = await getAccess()
      setAccessLevel(responseLevel)
      setIsLoading(false)
    }

    getUserAccessLevel()
  }, [setIsLoading])

  const navigateToPage = useCallback(
    (path, page) => {
      if (page) setActivePage(page)
      goToPage(path)
    },
    [goToPage, setActivePage],
  )

  const calculatePercentages = useCallback((fluxData) => {
    const totalStudents = Big(Number(fluxData?.totalValues?.totalStudents || 0))
    const educationalCreditTotal = Big(Number(fluxData?.educationalCredit?.totalStudents || 0))
    const maasTotal = Big(Number(fluxData?.maas?.totalStudents || 0))

    return {
      maasPercentage:
        fluxData?.maas?.totalStudents !== 0 && fluxData?.totalValues?.totalStudents !== 0
          ? maasTotal.times(100).div(totalStudents).toFixed(1)
          : 0,
      educationalCreditPercentage:
        fluxData?.educationalCredit?.totalStudents !== 0 && fluxData?.totalValues?.totalStudents !== 0
          ? educationalCreditTotal.times(100).div(totalStudents).toFixed(1)
          : 0,
    }
  }, [])

  const calculateProviPayPercentages = useCallback((fluxData) => {
    const totalValue = Big(Number(fluxData?.provipay?.totalValue || 0))
    const creditCardValue = Big(Number(fluxData?.provipay?.totalValueCreditCard || 0))
    const boletoValue = Big(Number(fluxData?.provipay?.totalValueBoleto || 0))
    const creditCardPercentage =
      fluxData?.provipay?.totalCountCreditCard !== 0 && fluxData?.provipay?.totalValueCreditCard !== 0
        ? creditCardValue.times(100).div(totalValue).toFixed(1)
        : 0

    const boletoPercentage =
      fluxData?.provipay?.totalCountBoleto !== 0 && fluxData?.provipay?.totalValueBoleto !== 0
        ? boletoValue.times(100).div(totalValue).toFixed(1)
        : 0

    return { creditCardPercentage, boletoPercentage }
  }, [])

  const setKind = useCallback(
    (kind) => {
      if (isEqual(dateKind, kind)) return
      setDateKind(kind)
      setIsLoading(true)
    },
    [setDateKind, dateKind, setIsLoading],
  )

  const setProducts = useCallback(
    (products) => {
      const newProducts = products || []
      if (isEqual(selectedProducts, newProducts)) return
      setSelectedProducts(newProducts)
      setIsLoading(true)
    },
    [setSelectedProducts, selectedProducts, setIsLoading],
  )

  const makeDatePickerVisible = useCallback(() => {
    setVisible((prev) => !prev)
  }, [setVisible])

  const setRangeDates = useCallback(
    (ranges) => {
      const start = ranges?.selection.startDate
      const end = ranges?.selection.endDate
      setStartDatePicker(start)
      setEndDatePicker(end)
      setStartDate(start)
      setEndDate((prev) => {
        if (start !== end && prev !== end) {
          setTimeout(makeDatePickerVisible, 300)
          setIsLoading(true)
        }
        return end
      })
    },
    [setStartDate, setEndDate, makeDatePickerVisible, setIsLoading],
  )

  const getProducts = useCallback(async () => {
    try {
      const filters = await getFiltersCheckout()
      const courses = filters?.data?.result?.courses || []

      const productsFiltered = courses
        .filter((item) => item.id)
        .map((item) => {
          return {
            label: `${item.name}-${item.id}`,
            value: item.id,
            active: true,
          }
        })

      setProductsFilter(productsFiltered)
    } catch (error) {
      showToast('Um erro inesperado aconteceu')
    }
  }, [setProductsFilter])

  const getData = useCallback(async () => {
    try {
      const filters = {
        startDate: startDate,
        endDate: endDate,
        productsSelected: selectedProducts ? selectedProducts.map((e) => e.value) : [],
        datekind: dateKind.value,
      }

      const { data: financeFluxData } = await getFinanceFluxData(filters)

      setFinancingFluxData({
        ...financeFluxData,
        ...calculatePercentages(financeFluxData),
        ...calculateProviPayPercentages(financeFluxData),
      })
    } catch (error) {
      logger({ error })
      showToast('Um erro inesperado aconteceu')
    } finally {
      setIsLoading(false)
    }
  }, [startDate, endDate, dateKind, selectedProducts, calculatePercentages, calculateProviPayPercentages, setIsLoading])

  const labelDateButton = useMemo(() => {
    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)
    return startDate && endDate && startDateMoment.toString() !== endDateMoment.toString()
      ? `${startDateMoment.format('L')} - ${endDateMoment.format('L')}`
      : 'Filtrar por data'
  }, [startDate, endDate])

  useUpdate(
    () => {
      getData()
    },
    {
      startDate,
      endDate,
      dateKind,
      selectedProducts,
      requestStatusKind,
    },
  )

  useMount(async () => {
    setIsLoading(true)
    getData()
    getProducts()
  })

  return {
    dateKind,
    setDateKind: setKind,
    startDate: startDatePicker,
    endDate: endDatePicker,
    setRangeDates,
    productsFilter,
    selectedProducts,
    setProductsFilter,
    setSelectedProducts: setProducts,
    financingFluxData,
    requestStatusKind,
    setRequestStatusKind,
    makeDatePickerVisible,
    visible,
    navigateToPage,
    setVisible,
    getProducts,
    conversionFunnelChecked,
    setConversionFunnelChecked,
    modal,
    setModal,
    labelDateButton,
    accessLevel,
    checkboxSelected,
    setCheckboxSelected,
  }
}

export { useFinancialHooks }
