import React from 'react'
import { uniqueId } from 'lodash'
import { useIntegrationsTable } from './hooks'
import { EachTableDetail, IntegrationFilter } from './components'
import { ArrowLeft, ArrowRight } from '~/assets/svgs/v2'
import { TableContainer, TableText, PaginationContainer } from './styles'

export const IntegrationsTable = ({ tableProps }) => {
  const {
    memberkitConnections: { connectionsList, pagination },
    connectionsFilters,
    page,
    nextPage,
    previousPage,
    filters,
    setFilters,
    formatBackendResponse,
  } = tableProps || {}
  const { handleEditProduct, handleUnlinkAllProducts } = useIntegrationsTable()

  return (
    <>
      <TableContainer>
        <TableText isBolder>Produto Provi</TableText>
        {connectionsFilters && (
          <IntegrationFilter filterOptions={connectionsFilters} filtersState={filters} setFilterFn={setFilters} />
        )}
        <div>
          {connectionsList.length &&
            formatBackendResponse(connectionsList).map((eachObject, index, mappedArr) => (
              <EachTableDetail
                key={uniqueId()}
                eachObject={eachObject}
                index={index}
                mappedArr={mappedArr}
                handleEditProduct={handleEditProduct}
                handleUnlinkAllProducts={handleUnlinkAllProducts}
              />
            ))}
        </div>
      </TableContainer>
      <PaginationContainer>
        {page !== 1 && <ArrowLeft onClick={previousPage} />}
        <TableText>{`Página ${page} de ${pagination.total}`}</TableText>
        {page !== pagination.total && <ArrowRight onClick={nextPage} />}
      </PaginationContainer>
    </>
  )
}
