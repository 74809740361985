import smartlook from 'smartlook-client'
import { createBrowserHistory } from 'history'
import { trackVirtualPageView } from '../utils/googleAnalytics'
import { getStorage } from '../utils'
import { LOCALSTORAGE_TOKENS } from '../constants'

export const history = createBrowserHistory()

history.listen(() => {
  if (window.smartlook) {
    const userId = getStorage({ key: LOCALSTORAGE_TOKENS.userId })
    const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
    const partnerSlug = getStorage({ key: LOCALSTORAGE_TOKENS.partnerSlug })
    const userEmail = getStorage({ key: LOCALSTORAGE_TOKENS.email })

    userEmail &&
      smartlook.identify(userId, {
        userId: userId,
        email: userEmail,
        partner: partnerId,
        partnerSlug,
      })
  }

  trackVirtualPageView()
})
