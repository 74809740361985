import React from 'react'

export const FunnelIcon = ({ onClick }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85183 1.57385H20.1482C20.3132 1.57385 20.4747 1.62179 20.613 1.71183C20.7513 1.80188 20.8604 1.93015 20.9272 2.08107C20.9939 2.23199 21.0154 2.39905 20.989 2.56195C20.9626 2.72485 20.8895 2.87657 20.7785 2.99868L13.777 10.7003C13.6345 10.8571 13.5555 11.0614 13.5555 11.2733V17.3027C13.5555 17.4429 13.5209 17.581 13.4547 17.7047C13.3885 17.8283 13.2928 17.9337 13.1762 18.0115L9.76885 20.283C9.64057 20.3685 9.49147 20.4176 9.33747 20.4251C9.18347 20.4325 9.03034 20.398 8.8944 20.3253C8.75846 20.2525 8.64482 20.1443 8.56559 20.012C8.48636 19.8797 8.44451 19.7284 8.44451 19.5743V11.2733C8.44451 11.0614 8.36553 10.8571 8.22299 10.7003L1.22153 2.99868C1.11052 2.87657 1.03737 2.72485 1.01097 2.56195C0.984571 2.39905 1.00606 2.23199 1.07282 2.08107C1.13958 1.93015 1.24874 1.80188 1.38704 1.71183C1.52533 1.62179 1.6868 1.57385 1.85183 1.57385V1.57385Z"
      stroke="#2647D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
