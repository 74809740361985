import { useState, useEffect } from 'react'
import moment from 'moment'
import { showToast } from '@provi/provi-components'

import { putReceipts } from '~/services/api'

export const useRelease = (value, id) => {
  const [date, setDate] = useState(value)
  const [error, setError] = useState(false)
  const [checked, setChecked] = useState(false)
  const today = moment().format('DD/MM/YYYY')

  useEffect(() => {
    if (date) {
      setChecked(true)
    }
  }, [])

  const handleInput = (e) => {
    setDate(e.target.value)
  }

  const handleCheck = async (newState, isCheckbox = false) => {
    setChecked(newState)

    if (!newState) {
      setDate('')
      showToast('Data de curso liberado removida')
      // mandar req para o back passando data = null
      await putReceipts(id, null)
    }

    if (!date || !newState) {
      setDate(today)
    }

    if (isCheckbox && newState) {
      // req mandando today para o back
      await putReceipts(id, today)

      showToast(`Alteração de curso liberado para ${today}`)
    }
  }

  const validate = async () => {
    console.log('data valida? ', moment(date, 'DD/MM/YYYY').isValid())
    if (
      date === null ||
      date === undefined ||
      (date.length < 10 && date.length !== 0) ||
      !moment(date, 'DD/MM/YYYY').isValid()
    ) {
      setError(true)
    } else {
      setError(false)
      const response = await putReceipts(id, date)
      console.log('response do back validate', response)

      showToast(`Alteração de curso liberado para ${date}`)

      if (date.length === 0) {
        setChecked(false)
      }
    }
  }

  return [handleInput, validate, date, error, checked, handleCheck]
}
