import React from 'react'
import { InputFieldV3, theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { InputContainer, TextFlex } from '../../styles'

export const ClassroomPriceInput = ({ formik, isMobile, handleMaskInputChange }) => {
  return (
    <InputContainer>
      <TextFlex>
        <BaseTypography fontWeight={600} fontSize={isMobile ? '13px' : '16px'}>
          Preço
        </BaseTypography>
        <BaseTypography fontSize={isMobile ? '11px' : '13px'} lineHeight={'20px'}>
          (opcional)
        </BaseTypography>
      </TextFlex>
      <BaseTypography fontSize={isMobile ? '11px' : '13px'} color={theme.colors.greyPrimary}>
        Caso deseje especificar o valor associado a essa turma, basta informá-lo aqui.
      </BaseTypography>

      <InputFieldV3
        id={'priceInCents'}
        name={'priceInCents'}
        type={'text'}
        width="100%"
        placeholder={'R$'}
        value={formik.values.priceInCentsFormatted}
        onChange={handleMaskInputChange}
        hasError={formik.errors.priceInCents}
        errorMessage={formik.errors.priceInCents}
      />
    </InputContainer>
  )
}
