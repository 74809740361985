import React from 'react'

export const ArrowRight = ({ active }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M1.85715 11L6.85715 6L1.85715 1"
      stroke={active ? '#DE0C4B' : '#B0AFAF'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
