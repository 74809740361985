/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from '@provi/provi-components'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text, Bold, Br } from './styles'

export const Modal = ({ handleModal, location, optionChange, submitHandleEye, submitDisableProduct }) => {
  const texts = {
    visible: {
      title: 'Tem certeza que deseja tornar o produto invisível?',
      text: (
        <Text>
          <Bold>Produtos visíveis</Bold> são listados no site da Provi e podem ser abertamente comercializados pelo preço
          cadastrado no produto.
          <Br />
          <Br />
          <Bold>Produtos invisíveis</Bold> não são listados no site da Provi e são comercializados apenas se o vendedor criar
          uma venda com esse produto selecionado.
        </Text>
      ),
      submit: () => submitHandleEye(location, optionChange),
    },
    invisible: {
      title: 'Tem certeza que deseja tornar o produto visível?',
      text: (
        <Text>
          <Bold>Produtos visíveis</Bold> são listados no site da Provi e podem ser abertamente comercializados pelo preço
          cadastrado no produto.
          <Br />
          <Br />
          <Bold>Produtos invisíveis</Bold> não são listados no site da Provi e são comercializados apenas se o vendedor criar
          uma venda com esse produto selecionado.
        </Text>
      ),
      submit: () => submitHandleEye(location, optionChange),
    },
    disableProduct: {
      title: 'Tem certeza que deseja tornar o produto desativado?',
      text: (
        <Text>
          <Bold>Produtos desativados</Bold> são excluídos da plataforma e não podem mais ser comercializados
        </Text>
      ),
      submit: () => submitDisableProduct(location, optionChange),
    },
  }

  return (
    <ModalComponent
      isWithoutHeight
      title={texts[location].title}
      onDismiss={() => handleModal(false)}
      footer={<Button text="Confirmo" onClick={texts[location].submit} />}
    >
      {texts[location].text}
    </ModalComponent>
  )
}
