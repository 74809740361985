import React from 'react'

export const CreditCardIcon = () => {
  return (
    <svg width="50" height="39" viewBox="0 0 50 39" fill="none" xmlns="https://www.w3.org/2000/svg">
      <rect x="9.08008" y="0.87851" width="39.9468" height="24.9226" fill="url(#paint0_linear)" />
      <rect x="0.242188" y="13.9585" width="39.9468" height="24.9226" fill="url(#paint1_linear)" />
      <rect x="3.4248" y="34.4621" width="17.4988" height="1.94431" rx="0.972156" fill="white" />
      <rect x="22.5127" y="34.4621" width="2.82809" height="1.94431" rx="0.972156" fill="white" />
      <rect x="9.08008" y="5.12067" width="39.9468" height="4.94916" fill="white" />
      <defs>
        <linearGradient id="paint0_linear" x1="29.0535" y1="0.87851" x2="29.0535" y2="25.8011" gradientUnits="userSpaceOnUse">
          <stop stopColor="#000B3C" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="20.2156" y1="5.8277" x2="19.3318" y2="44.5372" gradientUnits="userSpaceOnUse">
          <stop stopColor="#226CF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
