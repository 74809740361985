import React from 'react'
import { useParams } from 'react-router-dom'
import { useAddClassroomHook } from './hooks'
import * as S from './styles'
import { ButtonV3 as Button } from '@provi/provi-components'
import { CoursesBreadCrumbs } from '../../../'
import { ClassroomNameInput } from './components/ClassroomNameInput'
import { ReleaseSubjectInput } from './components/ReleaseSubjectInput'
import { ClassTypeInput } from './components/ClassTypeInput'
import { ClassroomPriceInput } from './components/ClassroomPriceInput'
import { MakeInactiveInput } from './components/MakeInactiveInput'
import { PartnerSkuInput } from './components/PartnerSkuInput'
import { GracePeriodDaysInput } from './components/GracePeriodDaysInput'
import { AddCampusModal } from './components/AddCampusModal'
import { VisibilityInput } from './components/VisibilityInput'
import { FinancingConditionInput } from 'screens/courses/components'
import { FinancingConditionsModal } from 'screens/courses/components/DetailsComponents/components'

function AddClassroom() {
  const { courseId } = useParams<{ courseId: string }>()

  const {
    formik,
    hasErrors,
    handleChangeClassPeriod,
    handleChangeClassType,
    handleChangeRelease,
    deadlineSelectOptions,
    isMobile,
    campi,
    handleMaskInputChange,
    goToPage,
    setCourseClassCopy,
    addCampusModalRef,
    openAddCampusModal,
    closeAddCampusModal,
    postAndGetCampus,
    financingConditions,
    handleSelectFinancingCondition,
    handleOpenModal,
    handleCloseModal,
    handleCopyConditions,
    financingConditionsModalRef,
    simulationsData,
    displayCalculator,
    useV3,
  } = useAddClassroomHook({ courseId })

  return (
    <>
      <CoursesBreadCrumbs breadCrumbsArr={[<span key={'Nova Turma'}>Nova Turma</span>]} />

      <S.Container>
        <S.Form hasErrors={hasErrors(formik.errors)} onSubmit={(el: any) => formik.handleSubmit(el)}>
          <ClassroomNameInput {...formik} />
          <ReleaseSubjectInput
            isMobile={isMobile}
            formik={formik}
            handleChangeRelease={handleChangeRelease}
            deadlineSelectOptions={deadlineSelectOptions}
          />

          <ClassroomPriceInput isMobile={isMobile} formik={formik} handleMaskInputChange={handleMaskInputChange} />

          {financingConditions?.length && useV3 ? (
            <FinancingConditionInput
              explainer="Esse limite é válido apenas para os alunos que estejam no crédito dinâmico."
              label="Limite de parcelamento da turma"
              financingConditions={financingConditions}
              selectedFinancingCondition={formik.values.financingCondition}
              handleSelectFinancingCondition={handleSelectFinancingCondition}
              marginBottom={48}
              calculatorModalAction={handleOpenModal}
              displayCalculator={displayCalculator}
            />
          ) : null}

          <FinancingConditionsModal
            modalRef={financingConditionsModalRef}
            isMobile={isMobile}
            closeModalFn={handleCloseModal}
            handlePrimaryBtn={handleCopyConditions}
            handleSecondaryBtn={handleCloseModal}
            conditionTitle="Financiamento"
            simulations={simulationsData}
          />

          <ClassTypeInput
            formik={formik}
            handleChangeClassType={handleChangeClassType}
            handleChangeClassPeriod={handleChangeClassPeriod}
            isMobile={isMobile}
            campi={campi}
            openAddCampusModal={openAddCampusModal}
          />

          <VisibilityInput isMobile={isMobile} formik={formik} />
          <MakeInactiveInput isMobile={isMobile} formik={formik} />
          <GracePeriodDaysInput isMobile={isMobile} formik={formik} />
          <PartnerSkuInput isMobile={isMobile} formik={formik} />

          <footer className="footer__button--actions">
            <button
              className="footer__button--cancel"
              type="button"
              onClick={() => {
                setCourseClassCopy(null)
                goToPage(`cursos/${courseId}`)
              }}
            >
              Cancelar
            </button>
            <Button
              disabled={!formik.isValid}
              text="Salvar"
              icon
              buttonProps={{
                type: 'submit',
              }}
            />
          </footer>
        </S.Form>
      </S.Container>
      <AddCampusModal
        modalRef={addCampusModalRef}
        handleSecondaryBtn={closeAddCampusModal}
        closeModalFn={closeAddCampusModal}
        handlePrimaryBtn={postAndGetCampus}
      />
    </>
  )
}

export { AddClassroom }
