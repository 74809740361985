/* eslint-disable no-unused-vars */
import React from 'react'
import { Redirect, Switch, Router, useParams } from 'react-router-dom'
import { ToastComponent } from '@provi/provi-components'
import { ToastContainer } from 'react-toastify'

import Route from './Route'
import PrivateRoute from './PrivateRoute'
import { routes } from './routes'
import { history } from './history'
// import { Home } from '../screens/Home'

export const Navigation = () => {
  const commonRoutes = routes.filter((i) => !i.private)
  const privateRoutes = routes.filter((i) => i.private)

  return (
    <Router history={history}>
      <Switch>
        {commonRoutes.map((item) => (
          <Route
            key={item.key}
            exact={item.exact}
            path={item.path}
            // isPrivate={item.private}
            // isFirstScreen={item.isFirstScreen}
            isHeader={item.isHeader}
            component={item.component}
            isTemplate={item.isTemplate}
            templateIntro={item.templateIntro}
          />
        ))}
        {privateRoutes.map((item) => {
          return (
            <PrivateRoute
              key={item.key}
              exact={item.exact}
              path={item.path}
              isHeader={item.isHeader}
              component={item.component}
              isTemplate={item.isTemplate}
              templateIntro={item.templateIntro}
              permissions={item.permissions}
            />
          )
        })}
        <Redirect to="/404" exact />
      </Switch>
      <ToastComponent />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  )
}
