import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #f6f8fb;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;

  ${theme.breakpoints.down('sm')} {
    height: auto;
  }
  @media (max-width: 800px) {
    padding-top: 20px;
  }

  @media (max-width: 500px) {
    padding-top: 60px;
  }
`
export const ContainerContent = styled.div`
  width: 95%;
  max-width: 1000px;
  background-color: #f6f8fb;
`

export const TopSection = styled.section`
  padding: 20px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px inset #a1a1a1;

  ${theme.breakpoints.down('sm')} {
    padding-bottom: 10px;
    width: 100%;
    min-height: 90px;
  }
`

export const BottomSection = styled.section`
  padding-bottom: 30px;
  min-height: 50vh;
  display: flex;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`

export const HeroLabel = styled.div`
  margin-bottom: 30px;
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 30px;
  color: ${theme.colors.blue900};

  span {
    color: ${theme.colors.pink900};
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
    margin-bottom: initial;
  }
`

export const VanishingWrapper = styled.div`
  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

export const BottomColumns = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const LeftLabel = styled.p`
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: 30px;
  font-size: 20px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('sm')} {
    line-height: 30px;
    font-size: 16px;
  }
`

export const RightLabel = styled.p`
  font-family: 'Montserrat-Bold', sans-serif;
  line-height: 30px;
  font-size: 20px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('sm')} {
    line-height: 30px;
    font-size: 16px;
  }
`

export const DeckWrapper = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    min-height: 250px;
  }
`
