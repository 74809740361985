import { asyncLocalStorage, parseJSON } from '~/utils'

/**
 * @description handle local storage token
 * @function
 * @async
 * @name handleStorage
 * @param {Object} data
 * @param {String} data.token
 * @param {String} data.email user email
 * @returns {Promise<Number>}
 */
export const handleToken = async ({ token, email }) => {
  const tokens = await asyncLocalStorage.getItem('tokens')
  const emails = await asyncLocalStorage.getItem('email')

  if (!tokens) {
    const initialTokens = JSON.stringify({ 1: token })
    await asyncLocalStorage.setItem('tokens', initialTokens)
    return 1
  }

  const parsedToken = parseJSON(tokens)
  const parsedEmail = parseJSON(emails)

  const activeUsers = parsedToken ? Object.keys(parsedToken) : []
  const activeKeysEmails = parsedEmail ? Object.keys(parsedEmail) : []

  const activeTokens = parsedToken ? Object.values(parsedToken) : []
  const activeEmails = parsedEmail ? Object.values(parsedEmail) : []

  const checkIfExistsToken = activeTokens.findIndex((activeToken) => activeToken === token)

  if (checkIfExistsToken >= 0) {
    const userRef = Number(activeUsers[checkIfExistsToken])
    if (userRef) return userRef
  }

  const checkIfExistsEmail = activeEmails.findIndex((activeEmail) => activeEmail === email)

  if (checkIfExistsEmail >= 0) {
    const userRef = Number(activeKeysEmails[checkIfExistsEmail])

    parsedToken[userRef] = token
    await asyncLocalStorage.setItem('tokens', JSON.stringify(parsedToken))

    if (userRef) return userRef
  }

  let newUser = 1
  let findIndex = false

  while (!findIndex && newUser < 100) {
    if (activeUsers.includes(String(newUser))) {
      newUser++
    } else {
      parsedToken[newUser] = token
      await asyncLocalStorage.setItem('tokens', JSON.stringify(parsedToken))
      findIndex = true
    }
  }

  return newUser
}
