import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SvgFlex = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    transform: ${({ state }) => (state ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.4s ease-in-out;
  }
`

export const TokenCard = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  border: 2px solid #d4daf7;
  border-radius: 8px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const TwoColGrid = styled.div`
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: minmax(0, 0.7fr) 0.3fr;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: minmax(0, 1fr);
  }
`
