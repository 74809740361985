import React from 'react'
import { theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { EachCondition } from './components'
import { useFinancingConditions } from './hooks'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { copyString } from 'helpers/copyAndPaste'

import { FinancingConditionsContainer, TextGrids } from './styles'
import { FinancingConditionsModal } from '../../../FinancingConditionsModal'

export const FinancingConditions = ({ classroomDetails }) => {
  const {
    isMobile,
    financingModalRef,
    openFinancingModal,
    closeFinancingModal,
    simulations,
    isLoadingSimulations,
    simulationsCopyText,
  } = useFinancingConditions({ classroomDetails })

  return (
    <>
      <FinancingConditionsContainer>
        <LoadingSkeleton
          isLoading={isLoadingSimulations}
          repeat={2}
          containerHeight={isMobile ? '100px' : '120px'}
          hasHeading
          hasParagraph
        >
          <TextGrids gap={'2rem'}>
            <BaseTypography
              fontWeight={700}
              color={theme.colors.bluePrimary}
              fontSize={isMobile ? '13px' : '16px'}
              lineHeight={isMobile ? '20px' : '24px'}
            >
              CONDIÇÕES DE PARCELAMENTO
            </BaseTypography>
            <EachCondition
              isMobile={isMobile}
              conditionTitle={'Financiamento'}
              openFinancingModal={openFinancingModal}
              simulations={simulations}
            />
          </TextGrids>
        </LoadingSkeleton>
      </FinancingConditionsContainer>
      <FinancingConditionsModal
        isMobile={isMobile}
        modalRef={financingModalRef}
        closeModalFn={closeFinancingModal}
        handleSecondaryBtn={closeFinancingModal}
        conditionTitle={'Financiamento'}
        simulations={simulations}
        handlePrimaryBtn={() => copyString(simulationsCopyText, 'linkAndText', closeFinancingModal)}
      />
    </>
  )
}
