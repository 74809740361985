import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const SwitchWrapper = styled.label`
  position: relative;
`
export const StyledSwitch = styled.input`
  position: absolute;
  display: none;

  &:checked + span {
    background-color: ${theme.colors.bluePrimaryDark};

    &:before {
      left: calc(100% - 2px);
      transform: translate(-100%);
    }
  }
`

export const SwitchThumb = styled.span`
  display: flex;
  cursor: pointer;
  width: 48px;
  height: 24px;
  border-radius: 150px;
  background-color: ${theme.colors.bluePrimary};
  position: relative;
  transition: background-color 0.2s;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const Labels = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #000000;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `}
`
