import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'

import { useHelperModal } from './hooks'

export const HelperModal = (props) => {
  const { handleModal, show, helperModalData } = props
  const { data } = useHelperModal(helperModalData)

  return (
    <>
      {show && helperModalData && (
        <ModalComponent isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('helper')}>
          {data}
        </ModalComponent>
      )}
    </>
  )
}
