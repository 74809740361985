import React from 'react'
import { SkeletonContainer, FullBackground, Heading, Paragraph, Content } from './styles'

export const LoadingSkeleton = ({
  repeat = 1,
  isLoading,
  containerHeight,
  hasBackground,
  hasHeading,
  hasParagraph,
  children,
  ...props
}) => {
  const renderSkeleton = () => {
    return (
      <SkeletonContainer containerHeight={containerHeight} {...props}>
        {hasBackground && <FullBackground />}
        {hasHeading || hasParagraph ? (
          <Content>
            {hasHeading && <Heading />}
            {hasParagraph && <Paragraph />}
          </Content>
        ) : null}
      </SkeletonContainer>
    )
  }

  return <>{isLoading ? Array.from({ length: repeat }, () => renderSkeleton()) : <>{children}</>}</>
}
