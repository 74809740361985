import { useEffect, useState } from 'react'
import { readIuguBalance, readTransactionsHistory, withdrawIuguBalance } from '~/services/api'
import { defaultHeaders as headers } from './values'
import masks from '~/themes/masks'
const { REACT_APP_API_ENV } = process.env

export const usePartnersTransactions = ({ setIsLoading }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState('')
  const [withdrawBalance, setWithdrawBalance] = useState(0)
  const [iuguBalance, setIuguBalance] = useState(null)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [customPagination, setCustomPagination] = useState([])
  const [isLoadingBalance, setIsLoadingBalance] = useState(true)
  const [isLoadingHistory, setIsLoadingHistory] = useState(true)

  const handleClickWithdraw = async (company) => {
    setSelectedCompany(company)

    if (REACT_APP_API_ENV === 'staging') {
      setShowModal(true)
      setWithdrawBalance(Math.floor(Math.random() * 600) + 400) // random value between R$4 and R$10
      return
    }

    if (company === 'iugu') {
      await getIuguBalance()
      setShowModal(true)
      setWithdrawBalance(iuguBalance?.availableBalance ? iuguBalance.availableBalance : 0)
    }
  }

  const handleWithdraw = async () => {
    setIsLoading(true)
    let statusCode

    if (selectedCompany === 'iugu') {
      const response = await withdrawIuguBalance({ amount: withdrawBalance })
      statusCode = response?.status
    }

    setIsLoading(false)
    setShowModal(false)

    if (statusCode === 200) {
      getTransactionHistory(5)
      getIuguBalance()
    }
  }

  const createData = ({
    createdAt,
    updatedAt,
    value: valueNumber,
    origin,
    status: statusType,
    bankName,
    agency,
    account,
    requestedBy,
  }) => {
    let value = masks.currency(valueNumber)

    let status, statusColor
    switch (statusType) {
      case 'processing':
        status = 'Em processamento'
        statusColor = '#F9E27F'
        break

      case 'paid':
        status = 'Aprovado'
        statusColor = '#A8E771'

        break

      case 'error':
        status = 'Rejeitado'
        statusColor = '#FF7C7C'
        break
    }

    return { createdAt, updatedAt, value, origin, status, statusColor, bankName, agency, account, requestedBy }
  }

  const formatRows = (rowsBE) => {
    let formattedRows = []
    rowsBE.forEach((eachRowObject) => {
      formattedRows.push(createData(eachRowObject))
    })

    return formattedRows
  }

  const mobileHeaders = [
    { name: 'status', data: 'Status' },
    { name: 'createdAt', data: 'Solicitado em' },
    { name: 'value', data: 'Valor' },
  ]

  const getIuguBalance = async () => {
    try {
      setIsLoadingBalance(true)
      const iuguBalanceData = await readIuguBalance()
      setIuguBalance(iuguBalanceData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingBalance(false)
    }
  }

  const getTransactionHistory = async (size) => {
    try {
      setIsLoadingHistory(true)
      const transactionHistoryData = await readTransactionsHistory({ size, page })
      setCustomPagination([transactionHistoryData.pagination])
      setRows(formatRows(transactionHistoryData.rows))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingHistory(false)
    }
  }

  const handleNextButton = () => {
    setPage(page + 1)
  }

  const handleBackButton = () => {
    setPage(page - 1)
  }

  useEffect(() => {
    if (isLoadingBalance || isLoadingHistory) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingBalance, isLoadingHistory, setIsLoading])

  useEffect(() => {
    getIuguBalance()
  }, [])

  useEffect(() => {
    getTransactionHistory(5)
  }, [page])

  return {
    showModal,
    setShowModal,
    handleClickWithdraw,
    withdrawBalance,
    selectedCompany,
    setSelectedCompany,
    iuguBalance,
    setIuguBalance,
    handleWithdraw,
    rows,
    headers,
    mobileHeaders,
    customPagination,
    handleBackButton,
    handleNextButton,
  }
}
