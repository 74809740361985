import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '@provi/provi-components'

export const usePaginationStyles = makeStyles({
  root: {
    '& button.MuiButtonBase-root': {
      color: theme.colors.pink900,
    },
    '& button.Mui-disabled': {
      color: theme.colors.blue900,
    },
    '& button.MuiIconButton-root': {
      padding: 4,
    },
  },
})

export const Container = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  gap: 10px;
`

export const ToolWrapper = styled.div`
  display: flex;
`
export const PaginationText = styled.p`
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    margin: 0;
  }
`
