import { asyncLocalStorage, parseJSON } from '~/utils'

/**
 * @description handle local storage values
 * @function
 * @async
 * @name handleStorage
 * @param {Object} data
 * @param {Number} data.user user id reference
 * @param {String} data.key key in local storage reference
 * @param {(String|Number)} data.value value with will be stored in key reference
 * @returns {Promise<String>}
 */
export const handleStorage = async ({ user, key, value }) => {
  try {
    const keys = await asyncLocalStorage.getItem(key)

    if (!keys) {
      const initialValue = JSON.stringify({ [user]: value })
      await asyncLocalStorage.setItem(key, initialValue)
      return
    }

    const parsedKeys = parseJSON(keys)

    parsedKeys[user] = value
    await asyncLocalStorage.setItem(key, JSON.stringify(parsedKeys))
  } catch (error) {
    const initialValue = JSON.stringify({ [user]: value })

    await asyncLocalStorage.setItem(key, initialValue)
  }
}
