import React from 'react'
import { isEqual } from 'lodash'
import { InputFieldV3 as Input, CheckBox, SelectorV3 as Select, theme, Radio } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { SpacingGrids, TextFlex, FitContentDiv } from './styles'

export const ReleaseSubjectInput = ({ isMobile, formik, handleChangeRelease, deadlineSelectOptions }) => {
  const smallFonts = isMobile ? '11px' : '13px'
  const bigFonts = isMobile ? '13px' : '16px'

  return (
    <SpacingGrids gap={'1.5rem'} hasBottomMargin={'48px'}>
      <SpacingGrids>
        <BaseTypography fontWeight={600} fontSize={bigFonts}>
          Modelo de liberação do conteúdo
        </BaseTypography>
        <BaseTypography fontSize={smallFonts} color={theme.colors.greyPrimary}>
          Informe como os seus alunos poderão acessar o conteúdo, datas de início e fim ou período de acesso. Isso aparecerá na
          descrição da turma e auxilia o aluno a escolher a melhor opção para o perfil dele. Se você usa a Memberkit como
          plataforma de hospedagem, a liberação do conteúdo será automatizada a partir do momento que você fizer a integração da
          turma no Painel. Caso contrário, você precisará fazer o processo de liberação manualmente.
        </BaseTypography>
      </SpacingGrids>
      <SpacingGrids>
        <FitContentDiv onClick={() => handleChangeRelease('releaseWithDate')}>
          <Radio text={'Início programado'} checked={formik.values.classTypeId === 1} />
        </FitContentDiv>
        <BaseTypography fontSize={smallFonts} color={theme.colors.greyPrimary} maxWidth="500px">
          O conteúdo será liberado de acordo com sua programação.{' '}
          <strong>Essa não é a data de início das vendas, e sim do seu cronograma de aulas.</strong>
        </BaseTypography>
      </SpacingGrids>
      {formik.values.classTypeId === 1 && (
        <SpacingGrids>
          <TextFlex gap={'1.5rem'}>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="date"
              name="beginDate"
              value={formik.values.beginDate}
              label="Início das aulas"
              placeholder="08/07/2021"
              max="9999-12-31"
              isValid={!formik.errors.beginDate}
              hasError={formik.errors.beginDate && formik.touched.beginDate}
            />

            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="date"
              name="endDate"
              value={formik.values.endDate}
              label="Fim das aulas"
              max="9999-12-31"
              placeholder="08/07/2021"
              isValid={!formik.errors.endDate}
              hasError={formik.errors.endDate && formik.touched.endDate}
            />
          </TextFlex>

          <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={'20px'} color="#B30142">
            {(formik.touched.beginDate && formik.errors.beginDate) || (formik.touched.endDate && formik.errors.endDate)}
          </BaseTypography>
        </SpacingGrids>
      )}

      <SpacingGrids>
        <FitContentDiv onClick={() => handleChangeRelease('releaseAfterPurchase')}>
          <Radio text={'Início imediato após a compra'} checked={formik.values.classTypeId === 2} />
        </FitContentDiv>
        <BaseTypography fontSize={smallFonts} color={theme.colors.greyPrimary}>
          O conteúdo gravado e/ou material de estudo será liberado após a realização de cada venda.
        </BaseTypography>
      </SpacingGrids>
      {formik.values.classTypeId === 2 && (
        <>
          <SpacingGrids hasCustomWidth={isMobile ? false : '500px'}>
            <BaseTypography fontWeight={600} fontSize={bigFonts}>
              Prazo limite de acesso
            </BaseTypography>
            <BaseTypography fontSize={smallFonts} color={theme.colors.greyPrimary}>
              Determine por quanto tempo seus alunos poderão acessar conteúdo gravado e/ou material complementar depois de
              completar o curso.
            </BaseTypography>
          </SpacingGrids>

          <SpacingGrids>
            <TextFlex>
              <Input
                disabled={formik.values.lifeTimeSpan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.deadlineSolicitationNumber}
                type="number"
                placeholder="Digite um número"
                name="deadlineSolicitationNumber"
                hasError={formik.errors.deadlineSolicitationNumber && formik.touched.deadlineSolicitationNumber}
                isValid={!formik.errors.deadlineSolicitationNumber && formik.touched.deadlineSolicitationNumber}
              />
              <Select
                className={`${formik.values.lifeTimeSpan && `is__disabled`}`}
                isDisabled={formik.values.lifeTimeSpan}
                value={
                  deadlineSelectOptions && formik.values.deadlineSolicitationSelect
                    ? deadlineSelectOptions.find((option) => isEqual(option.value, formik.values.deadlineSolicitationSelect))
                    : ''
                }
                onChange={(option) => formik.setFieldValue('deadlineSolicitationSelect', option.value)}
                options={deadlineSelectOptions}
              />
            </TextFlex>
            {formik.errors.deadlineSolicitationNumber && formik.touched.deadlineSolicitationNumber && (
              <BaseTypography fontSize={bigFonts} lineHeight={'20px'} color="#B30142">
                {formik.errors.deadlineSolicitationNumber}
              </BaseTypography>
            )}
          </SpacingGrids>

          <CheckBox
            name="lifeTimeSpan"
            value={formik.values.lifeTimeSpan}
            text={'Acesso Vitalício'}
            checked={formik.values.lifeTimeSpan}
            hasError={formik.errors.lifeTimeSpan && formik.touched.lifeTimeSpan}
            isValid={!formik.errors.lifeTimeSpan && formik.touched.lifeTimeSpan}
            errorMessage={formik.errors.lifeTimeSpan}
            onChange={() => formik.setFieldValue('lifeTimeSpan', !formik.values.lifeTimeSpan)}
          />
        </>
      )}
    </SpacingGrids>
  )
}
