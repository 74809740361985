import React from 'react'

export const BoletoIcon = () => {
  return (
    <svg width="39" height="49" viewBox="0 0 39 49" fill="none" xmlns="https://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M35.8054 0H2.59459C1.16164 0 0 1.16164 0 2.59459V46.1838C0 47.6167 1.16164 48.7784 2.59459 48.7784H35.8054C37.2384 48.7784 38.4 47.6167 38.4 46.1838V2.59459C38.4 1.16164 37.2384 0 35.8054 0Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M29.319 9.34055H9.60003C8.88355 9.34055 8.30273 9.92137 8.30273 10.6378C8.30273 11.3543 8.88355 11.9351 9.60003 11.9351H29.319C30.0354 11.9351 30.6162 11.3543 30.6162 10.6378C30.6162 9.92137 30.0354 9.34055 29.319 9.34055Z"
          fill="white"
        />
        <path
          d="M29.319 14.5297H9.60003C8.88355 14.5297 8.30273 15.1105 8.30273 15.827C8.30273 16.5435 8.88355 17.1243 9.60003 17.1243H29.319C30.0354 17.1243 30.6162 16.5435 30.6162 15.827C30.6162 15.1105 30.0354 14.5297 29.319 14.5297Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M29.319 20.2378H9.60003C8.88355 20.2378 8.30273 20.8187 8.30273 21.5351C8.30273 22.2516 8.88355 22.8324 9.60003 22.8324H29.319C30.0354 22.8324 30.6162 22.2516 30.6162 21.5351C30.6162 20.8187 30.0354 20.2378 29.319 20.2378Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M28.0507 35.2865H10.8973C9.46437 35.2865 8.30273 36.4481 8.30273 37.8811V40.7352C8.30273 42.1682 9.46437 43.3298 10.8973 43.3298H28.0507C29.4837 43.3298 30.6453 42.1682 30.6453 40.7352V37.8811C30.6453 36.4481 29.4837 35.2865 28.0507 35.2865Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="19.2" y1="0" x2="19.2" y2="48.7784" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="38.4" height="48.7784" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
