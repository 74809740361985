import styled from 'styled-components'
import { theme, PortalModal } from '@provi/provi-components'

export const StyledPortalModal = styled(PortalModal)`
  border-radius: 16px;

  ${theme.breakpoints.down('md')} {
    max-width: calc(100% - 40px);
    border-radius: 8px;
  }
`

export const ModalContainer = styled.div`
  display: grid;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: auto;
  }
`

export const ReversableFlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
  }

  .MuiButton-label {
    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }
`

export const BlueHeader = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const BlackParagraph = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`
