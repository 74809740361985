import React from 'react'
import { CheckBox, InputFieldV3 } from '@provi/provi-components'
import { BlackHeaders } from '../index'
import { VerticalContainers, FlexContainers } from '../../styles'
import { ChainLinkIcon } from '~/assets/svgs/v2'
import { useUniquePriceSub } from './hooks'

export const UniquePriceSub = ({ isMobile, stateProps }) => {
  const { formValues, setFormValues, discountPercentage, priceWithDiscount } = stateProps || {}

  const { maskedValue, percentage, handleCheckBoxChange, handleMaskedInputChange, handlePercentageChange } = useUniquePriceSub({
    formValues,
    setFormValues,
    discountPercentage,
    priceWithDiscount,
  })

  return (
    <>
      <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
        <BlackHeaders
          isMobile={isMobile}
          title={'Métodos de pagamento disponíveis '}
          explanation={'O aluno poderá escolher como pagar dentro do modelo de precificação determinado por você.'}
        />
        <FlexContainers flexDirection={isMobile && 'column'}>
          <CheckBox
            name="CreditCard"
            text={'Cartão de crédito'}
            checked={formValues?.launchPricing?.options[0]?.uniquePaymentMethods?.includes('CreditCard')}
            onChange={() => handleCheckBoxChange('CreditCard')}
          />
          <CheckBox
            name="Boleto"
            text={'Boleto à vista'}
            checked={formValues?.launchPricing?.options[0]?.uniquePaymentMethods?.includes('Boleto')}
            onChange={() => handleCheckBoxChange('Boleto')}
          />
          <CheckBox
            name="CourseFinancing"
            text={'Financiamento'}
            checked={formValues?.launchPricing?.options[0]?.uniquePaymentMethods?.includes('CourseFinancing')}
            onChange={() => handleCheckBoxChange('CourseFinancing')}
          />
        </FlexContainers>
      </VerticalContainers>
      <VerticalContainers>
        <BlackHeaders
          isMobile={isMobile}
          title={'Valor do lançamento'}
          explanation={'Digite o valor final ou o desconto em %.'}
        />
        <VerticalContainers>
          <FlexContainers gap={isMobile ? '0.5rem' : '1.5rem'} justifyContent={'space-between'} alignItems={'center'}>
            <InputFieldV3
              type="text"
              name="maskedValue"
              placeholder="R$ 5.000,00"
              width={'100%'}
              value={maskedValue}
              onChange={handleMaskedInputChange}
            />
            {!isMobile && <ChainLinkIcon />}
            <InputFieldV3
              type="text"
              name="percentage"
              placeholder="Ex: 20%"
              width={'100%'}
              value={percentage}
              onChange={handlePercentageChange}
              mask={'99'}
            />
          </FlexContainers>
        </VerticalContainers>
      </VerticalContainers>
    </>
  )
}
