import { getReceiptsFiltersV4 } from '~/services/api'
import { getTranslatedStatus, getTranslatedDelayedInstallments, getTranslatedCampaigns } from '~/utils'

import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'
import { isKeyFilterChange } from '~/helpers/tables/isKeyFilterChange'
import { formatInitialFiltersWithUrl } from '~/helpers/tables/formatInitialFiltersWithUrl'

// TODO - VER QUAL FILTRO QUE PODE TIRAR PORQUE NÃO TA USANDO
export const formatCellsWithFilterAndUrl = async ({ setShowCells, showCells, keysFilters, queryFilters }) => {
  const filters = await getReceiptsFiltersV4()

  if (filters && filters.data && filters.data.content && filters.data.content.status) {
    const { status, courses, productType, delayedDays, delayedInstallments, campaigns: campaignName } = filters.data.content
    const cellsStatusFilter = status.map((item) => {
      return {
        label: getTranslatedStatus(item),
        id: item,
        active: true,
      }
    })

    const cellsDelayedDaysFilter = delayedDays.map((item) => {
      return {
        label: item,
        id: item,
        active: true,
      }
    })

    const cellsDelayedInstallmentsFilter =
      delayedInstallments &&
      delayedInstallments.map((item) => {
        return {
          label: getTranslatedDelayedInstallments(item),
          id: item,
          active: true,
        }
      })

    const cellsCoursesFilter = courses.map((item) => {
      return {
        label: `${item.id}-${item.name}`,
        id: item.id,
        active: true,
      }
    })

    const cellsProductTypeFilter = productType.map((item) => {
      return {
        label: getTranslattedProductType(item, true),
        id: item,
        active: true,
      }
    })
    const cellsCampaignNameFilter = campaignName.map((item) => {
      return {
        label: getTranslatedCampaigns(item),
        id: item,
        active: true,
      }
    })

    const newShowCells = showCells.map((cell) => {
      if (isKeyFilterChange(keysFilters, cell.item)) {
        cell.isChanged = true
      }

      if (cell.item === 'campaignName') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsCampaignNameFilter,
          queryFilters: queryFilters.campaignName,
        })
      }
      if (cell.item === 'delayed_days') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsDelayedDaysFilter,
          queryFilters: queryFilters.delayedDays,
        })
      }

      if (cell.item === 'installments_delayed') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsDelayedInstallmentsFilter,
          queryFilters: queryFilters.installments_delayed,
        })
      }

      if (cell.item === 'status') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsStatusFilter,
          queryFilters: queryFilters.status,
        })
      }
      if (cell.item === 'products') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsCoursesFilter,
          queryFilters: queryFilters.products,
        })
      }
      if (cell.item === 'productType') {
        return formatInitialFiltersWithUrl({
          cell,
          origin: cellsProductTypeFilter,
          queryFilters: queryFilters.productType,
        })
      }

      if (cell.item === 'madeEffectiveDate') {
        return formatInitialFiltersWithUrl({
          cell,
          isDate: true,
          isBlankDate: true,
          queryFilters: {
            startDate: queryFilters.startEffectiveDate,
            endDate: queryFilters.endEffectiveDate,
          },
        })
      }

      if (cell.item === 'datePrevToted') {
        return formatInitialFiltersWithUrl({
          cell,
          isDate: true,
          isBlankDate: true,
          queryFilters: {
            startDate: queryFilters.startExpectedPaymentDate,
            endDate: queryFilters.endExpectedPaymentDate,
          },
        })
      }

      if (cell.item === 'tedDate') {
        return formatInitialFiltersWithUrl({
          cell,
          isDate: true,
          isBlankDate: true,
          queryFilters: {
            startDate: queryFilters.startTedDate,
            endDate: queryFilters.endTedDate,
          },
        })
      }

      if (cell.item === 'released') {
        return formatInitialFiltersWithUrl({
          cell,
          isDate: true,
          isBlankDate: true,
          queryFilters: {
            startDate: queryFilters.startReleasedDate,
            endDate: queryFilters.endReleasedDate,
          },
        })
      }
      if (cell.item === 'nextPayment') {
        return formatInitialFiltersWithUrl({
          cell,
          isDate: true,
          isBlankDate: true,
          queryFilters: {
            startDate: queryFilters.startNextPaymentDate,
            endDate: queryFilters.endNextPaymentDate,
          },
        })
      }

      return cell
    })
    setShowCells(newShowCells)
  }
}
