import React from 'react'
import { ButtonV3 } from '@provi/provi-components'
import { Container, StyledHeader, StyledParagraph, StyledDefaultText } from './styles'

export const CommunicationCard = ({ content, accessData }) => {
  let { title, text, cta, cta_link, campaign_level } = content?.data?.partners_cards[0] || {}
  const { userId, partnerId } = accessData || {}
  cta_link = cta_link?.replace(
    'satisfacao#prtnr=xxxxx&prtnremp=xxxxx&f=xxxxx&partner_id=xxxxx',
    `satisfacao#prtnr=${partnerId}&prtnremp=${userId}&f=pe${userId}&partner_id=${partnerId}`,
  )
  return (
    <>
      <Container>
        {campaign_level?.includes(accessData?.accessLevel) ? (
          <>
            <StyledHeader>{title}</StyledHeader>
            <StyledParagraph>{text}</StyledParagraph>
            <ButtonV3
              mobileWidth={'100%'}
              text={cta}
              icon
              buttonProps={{
                href: cta_link,
                target: '_blank',
                rel: 'noopener noreferrer',
              }}
            />
          </>
        ) : (
          <StyledDefaultText>Você não tem comunicados</StyledDefaultText>
        )}
      </Container>
    </>
  )
}
