import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

const animateFadeIn = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  margin-top: 146px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    margin-top: 115px;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const WrapperText = styled.div`
  text-align: center;
`

export const WrapperSearchBar = styled.div`
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    padding: 0 20px;
  }
`

export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: center;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
  }
`

export const SectionHeader = styled.div``

export const WrapperTabs = styled.div`
  margin-top: 50px;
  justify-content: center;

  font-size: 16px;
  line-height: 24px;

  ul {
    text-align: center;
    display: flex;

    .react-tabs__tab {
      width: 50%;
      font-size: 16px !important;
      line-height: 24px !important;
      font-family: 'CenturyGothic';
      color: ${theme.colors.blue900};
    }

    .react-tabs__tab--selected {
      border: none;
      border-bottom: 5px solid ${theme.colors.pink900};
      font-family: 'CenturyGothicBold';
    }

    .react-tabs__tab:focus {
      border-bottom: 5px solid ${theme.colors.pink900};
      box-shadow: none !important;
      outline: none !important;
    }
    .react-tabs__tab:focus:after {
      position: unset;
    }
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 25px;
  }
`

export const Bold = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-family: 'CenturyGothicBold';
`

export const SectionProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 25px 0;
`

export const WrapperEyeIcon = styled.div`
  width: 22px; /* 40px */
  height: 22px; /* 40px */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.colors.blue900};
  cursor: pointer;
`

export const CheckIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? theme.colors.green100 : theme.colors.grey600)};
`

export const Grid = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ first }) => (first ? 'unset' : '15px')};
`

export const Option = styled.div`
  border: 1px solid ${theme.colors.blue900};
  border-radius: 20px;
  padding: 18px;
  margin: 10px;
  width: 305px;

  animation-name: ${animateFadeIn};
  animation-duration: 1s;
  position: relative;
`

export const RedTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${({ inactive }) => (inactive ? theme.colors.grey600 : theme.colors.pink900)};
  margin-bottom: 20px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const WrapperIcon = styled.div`
  margin-right: 5px;
`

export const DownloadMore = styled.div`
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
`

export const Bottom = styled.div`
  position: relative;
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
  justify-content: ${({ single }) => (single ? 'flex-end' : 'space-between')};
`

export const BoxButton = styled.div`
  height: inherit;
  width: 120px;
  height: 32px;
  background-color: ${theme.colors.pink900};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  border-radius: 20px;
  padding: 0 15px;

  /* font */
  font-family: ${({ active }) => (active ? 'CenturyGothicBold' : 'CenturyGothic')};
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey400};
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  ${theme.breakpoints.down('md')} {
    height: 40px;
    width: ${({ noLink }) => (noLink ? '120px' : '40px')};
    margin-right: 10px;
    padding: unset;

    svg {
      margin-right: unset;
    }
  }
`

export const WrapperProduct = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const CardBottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const WrapperCalc = styled.div`
  height: 30px;
  width: 30px;

  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`
