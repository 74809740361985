import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IIsActiveProps {
  isActive: boolean
}

export const CardContainer = styled.div`
  max-width: 286px;

  position: relative;

  ${theme.breakpoints.down('md')} {
    max-width: 214px;
  }
`

export const CardTag = styled.div<IIsActiveProps>`
  padding: 5px;
  position: absolute;
  background-color: ${({ isActive }) => (isActive ? theme.colors.bluePrimary : theme.colors.greyPrimary)};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  top: 5%;
  right: 2px;
  color: white;
  font-size: 12.8px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
    top: 50%;
  }
`

export const TopContainer = styled.div<IIsActiveProps>`
  background-color: #ffffff;
  padding: 10px 1rem 1rem 1rem;
  display: grid;
  gap: 14px;
  border: ${({ isActive }) => `2px solid ${isActive ? theme.colors.bluePrimaryLight : theme.colors.greyLight}`};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 0;
`

export const BottomContainer = styled.div<IIsActiveProps>`
  border: ${({ isActive }) => `2px solid ${isActive ? theme.colors.bluePrimaryLight : theme.colors.greyLight}`};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 0;
  background-color: ${({ isActive }) => (isActive ? theme.colors.bluePrimaryLight : theme.colors.greyLight)};
`

interface ITextProps {
  isBlueTitle?: boolean
  isBlue?: boolean
  isPriceNumber?: boolean
  isActive?: boolean
}

export const Text = styled.p<ITextProps>`
  margin: 0;
  font-size: 12.8px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
  }

  ${({ isBlueTitle, isActive }) =>
    isBlueTitle &&
    css`
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: ${isActive ? theme.colors.bluePrimary : theme.colors.greyPrimary};

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    `}

  ${({ isBlue, isActive }) =>
    isBlue &&
    css`
      font-size: 16px;
      line-height: 24px;
      color: ${isActive ? theme.colors.bluePrimary : theme.colors.greyPrimary};

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    `}

  ${({ isPriceNumber, isActive }) =>
    isPriceNumber &&
    css`
      font-size: 20px;
      line-height: 24px;
      color: ${isActive ? theme.colors.bluePrimary : theme.colors.greyPrimary};

      ${theme.breakpoints.down('md')} {
        font-size: 15px;
      }
    `}
`

export const TopGrid = styled.div`
  display: grid;
  max-width: calc(100% - 1.5rem);

  ${theme.breakpoints.down('md')} {
    max-width: revert;
  }
`

export const BottomGrid = styled.div`
  padding: 0.5rem 1rem;
  display: grid;
  gap: 10px;
`

export const IconsContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`

export const CardFlexContainer = styled.div`
  display: flex;
  gap: 10px;
`
