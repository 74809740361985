/* eslint-disable react/prop-types */
import React from 'react'
import { ButtonBack } from '../ButtonBack'

import { ContainerSubHeader } from './styles'

export const SubHeader = ({ isDark, functionBackButton, isMargin, backgroundColor }) => {
  return (
    <ContainerSubHeader isDark={isDark} isMargin={isMargin} backgroundColor={backgroundColor}>
      <ButtonBack isDark={isDark} onClick={functionBackButton} />
    </ContainerSubHeader>
  )
}
