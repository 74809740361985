import React from 'react'
import { Option, OptionsWrapper } from './styles'

/**
 * @typedef {object} IProps
 * @property {boolean} value
 * @property {string} label
 * @property {(value: boolean) => void} onChange
 */

/** @type {React.FC<IProps>}*/
export const OptionInput = ({ label, value, onChange }) => {
  return (
    <OptionsWrapper>
      <p>{label}</p>
      <section>
        <Option onClick={() => onChange(true)} active={value === true}>
          <span>SIM</span>
        </Option>
        <Option onClick={() => onChange(false)} active={value === false}>
          <span>NÃO</span>
        </Option>
      </section>
    </OptionsWrapper>
  )
}
