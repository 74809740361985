import React from 'react'
import { theme, Radio } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { VerticalContainers } from '../../styles'

export const EachRadioBtn = ({ isMobile, title, explanation, checked, onChange, disabled }) => {
  return (
    <VerticalContainers>
      <Radio text={title} checked={checked} onChange={onChange} disabled={disabled} />
      <BaseTypography
        fontSize={isMobile ? '11px' : '13px'}
        lineHeight={isMobile ? '16px' : '20px'}
        color={theme.colors.greyPrimary}
      >
        {explanation}
      </BaseTypography>
    </VerticalContainers>
  )
}
