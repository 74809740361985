/* eslint-disable react/prop-types */
import React from 'react'
import { theme, Button, showToast } from '@provi/provi-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'
import mask from '~/themes/masks'

import { Section } from './styles'

export const CopyLinks = (props) => {
  const { handleModal, show, copyLinksData } = props

  const handleCopy = (option) => {
    showToast(option)
  }

  return (
    <>
      {show && copyLinksData && (
        <ModalComponent
          isWithoutHeight
          width="630px"
          radius={5}
          onDismiss={() => handleModal('copyLink')}
          footer={
            <>
              <CopyToClipboard
                text={`${copyLinksData.proviPay || copyLinksData.incomeShare || copyLinksData.link}`}
                onCopy={() => handleCopy('Link copiado')}
              >
                <Button text="Copiar link de compra" />
              </CopyToClipboard>
            </>
          }
        >
          {!(copyLinksData.proviPay || copyLinksData.incomeShare) && (
            <Section>
              <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                Link do comprador
              </Text>
              <CopyToClipboard
                text={copyLinksData && copyLinksData.link ? copyLinksData.link : ''}
                onCopy={() => handleCopy('Link de compra copiado')}
              >
                <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900} cursor="pointer">
                  {copyLinksData && copyLinksData.link ? copyLinksData.link : 'Link não encontrado'}
                </Text>
              </CopyToClipboard>
            </Section>
          )}

          {copyLinksData.guarantor &&
            copyLinksData.guarantor.length > 0 &&
            copyLinksData.guarantor.map((guarantor, idx) => (
              <>
                {guarantor.redirectUrl && (
                  <Section>
                    <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                      Link do avalista {copyLinksData.guarantor.length === 1 ? '' : idx + 1}
                    </Text>
                    <CopyToClipboard text={guarantor.redirectUrl} onCopy={() => handleCopy('Link copiado')}>
                      <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900} cursor="pointer">
                        {guarantor.redirectUrl}
                      </Text>
                    </CopyToClipboard>
                    <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                      CPF
                    </Text>
                    <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900}>
                      {mask.cpf(guarantor.CPF)}
                    </Text>
                  </Section>
                )}
              </>
            ))}
          {copyLinksData.proviPay && (
            <>
              <Section>
                <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                  Link de compra no ProviPay
                </Text>
                <CopyToClipboard text={copyLinksData.proviPay} onCopy={() => handleCopy('Link copiado')}>
                  <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900} cursor="pointer">
                    {copyLinksData.proviPay}
                  </Text>
                </CopyToClipboard>
              </Section>
            </>
          )}
          {copyLinksData.incomeShare && (
            <>
              <Section>
                <Text size="16px" lineHeight="24px" bold color={theme.colors.grey600}>
                  Link de compra no ISA
                </Text>
                <CopyToClipboard text={copyLinksData.incomeShare} onCopy={() => handleCopy('Link copiado')}>
                  <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900} cursor="pointer">
                    {copyLinksData.incomeShare}
                  </Text>
                </CopyToClipboard>
              </Section>
            </>
          )}
        </ModalComponent>
      )}
    </>
  )
}
