import styled from 'styled-components'

export const MobileBox = styled.div`
  display: grid;
  gap: 10px;
`
export const DefaultGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-rows: 1fr 1fr;

  & .MuiIconButton-label {
    justify-content: initial;
  }
`
