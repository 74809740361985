import { theme } from '@provi/provi-components'
import styled from 'styled-components'
import { fonts } from '~/themes/fonts'

export const FormHeader = styled.h1`
  text-align: center;
  width: 100%;
  font-family: ${fonts.century.bold};
  color: ${theme.colors.blue900};
`

/** @type {import('styled-components').StyledComponent<"div", any, { isComplete: boolean, isClickable: boolean }>} */
export const CardWrapper = styled.div`
  width: 22%;
  min-height: 140px;
  border-radius: 10px;
  cursor: ${(props) => (props.isClickable === true ? 'pointer' : 'default')};
  background-color: ${theme.colors.white100};
  div {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.isComplete ? theme.colors.green100 : theme.colors.pink900)};
    h1 {
      color: ${theme.colors.white100};
      font-size: 14px;
      font-family: ${fonts.century.bold};
    }
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    p {
      text-align: center;
      font-size: 18px;
      font-family: ${fonts.century.bold};
      color: ${theme.colors.blue900};
    }
    a {
      text-align: center;
      font-size: 14px;
      font-family: ${fonts.century.bold};
      color: ${theme.colors.blue900};
      text-decoration: underline;
    }
  }
`

export const ArrowWrapper = styled.div`
  cursor: pointer;
`

export const BackContainer = styled.section`
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1280px;
`

export const FormsWrapper = styled.div`
  min-height: 100%;
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 81px;
  background-color: ${theme.colors.blue900};
  z-index: 10;
  display: flex;
  align-items: center;
`

export const SubHeader = styled.div`
  padding: 25px;
  display: flex;
  margin-top: 80px;
  width: 100vw;
  background-color: ${theme.colors.white100};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    font-family: ${fonts.century.bold};
    font-size: 30px;
    color: ${theme.colors.blue900};
  }
  p {
    font-family: ${fonts.century.regular};
    text-align: center;
    font-size: 20px;
    color: ${theme.colors.blue900};
    max-width: 677px;
  }
`

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  min-height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`

export const CardsWrapper = styled.div`
  padding: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`

export const Wrapper = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
`

export const ListButton = styled.div`
  background: ${theme.colors.grey400};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  margin-right: 10px;
  font-family: ${fonts.century.bold};
  &:hover {
    opacity: 0.9;
  }
`

export const Separator = styled.div`
  width: 100%;
  border: 0.5px solid #dddddd;
`
