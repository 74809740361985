export const access = {
  changeToVisible: {
    j: false,
    p: true,
    s: true,
    a: true,
  },
  changeToInVisible: {
    j: false,
    p: true,
    s: true,
    a: true,
  },
  createProduct: {
    j: false,
    p: true,
    s: true,
    a: true,
  },
}
