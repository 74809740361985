import React from 'react'
import { fallbackString, formatValue, getTranslattedProductType } from '../../helpers'
import { EachDetail, MaaSDetails, ISADetails, CreditCardDetails, BoletoDetails, CEDetails } from './components'
import { DetailsCardGrid, Divider, Header, TwoColumnGrid } from './styles'

const renderDetailsCrid = (cridDetailsParam, formatValueParam, fallbackStringParam) => {
  const productTypeComponents = {
    MAAS: <MaaSDetails cridDetails={cridDetailsParam} formatValue={formatValueParam} fallbackString={fallbackStringParam} />,
    ISA: <ISADetails cridDetails={cridDetailsParam} formatValue={formatValueParam} fallbackString={fallbackStringParam} />,
    CreditCard: (
      <CreditCardDetails cridDetails={cridDetailsParam} formatValue={formatValueParam} fallbackString={fallbackStringParam} />
    ),
    Boleto: (
      <BoletoDetails cridDetails={cridDetailsParam} formatValue={formatValueParam} fallbackString={fallbackStringParam} />
    ),
    CourseFinancing: (
      <CEDetails cridDetails={cridDetailsParam} formatValue={formatValueParam} fallbackString={fallbackStringParam} />
    ),
  }
  return productTypeComponents[cridDetailsParam.productType]
}

export const DetailsCard = ({ cridDetails }) => {
  return (
    <DetailsCardGrid>
      <Header>Informações básicas</Header>
      <Divider />
      <TwoColumnGrid>
        <EachDetail title={'Nome'} value={cridDetails?.name || fallbackString} />
        <EachDetail title={'Telefone'} value={cridDetails?.phone || fallbackString} />
        <EachDetail title={'Endereço'} value={cridDetails?.address || fallbackString} />
        <EachDetail title={'Email'} value={cridDetails?.email || fallbackString} />
        <EachDetail
          title={'Método de pagamento'}
          value={getTranslattedProductType(cridDetails?.productType) || fallbackString}
        />
        <EachDetail title={'Data de efetivação'} value={cridDetails?.madeEffectiveDate || fallbackString} />
      </TwoColumnGrid>
      <Divider />
      {renderDetailsCrid(cridDetails, formatValue, fallbackString)}
    </DetailsCardGrid>
  )
}
