export const defaultHeaders = [
  {
    name: 'createdAt',
    data: 'Solicitado em',
  },
  {
    name: 'value',
    data: 'Valor',
  },
  {
    name: 'origin',
    data: 'Origem',
  },
  {
    name: 'status',
    data: 'Status',
  },
  {
    name: 'updatedAt',
    data: 'Atualizado em',
  },
  {
    name: 'bankName',
    data: 'Banco',
  },
  {
    name: 'agency',
    data: 'Agência',
  },
  {
    name: 'account',
    data: 'Conta',
  },
  {
    name: 'requestedBy',
    data: 'Solicitado por',
  },
]
