import React from 'react'

import { ptBR } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range'
import { inputRanges, staticRanges } from '../../dateRanges'
import { CalendarWrapper } from '../../global'

function CalendarPicker({ rightPosition, startDate, endDate, setRangeDates }) {
  return (
    <CalendarWrapper
      style={{
        position: 'fixed',
        top: '100px',
        right: rightPosition,
      }}
    >
      <DateRangePicker
        language="pt-br"
        staticRanges={staticRanges}
        inputRanges={inputRanges}
        locale={ptBR}
        ranges={[
          {
            startDate,
            endDate,
            key: 'selection',
          },
        ]}
        onChange={setRangeDates}
      />
    </CalendarWrapper>
  )
}

export { CalendarPicker }
