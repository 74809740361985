import React from 'react'

import { EachDetail } from '../index'

import { DropdownContainer, DropdownFlexChilds } from '../../styles'

export const TableDropdown = () => {
  return (
    <DropdownContainer>
      <DropdownFlexChilds>
        <EachDetail
          isBold
          isFromHelper
          title={'Alunos efetivados'}
          value={'Número de alunos que foram efetivados no período.'}
        />

        <EachDetail
          isBold
          isFromHelper
          title={'Valor das vendas'}
          value={
            'Soma dos valores de cursos vendidos no período, incluindo qualquer meio de pagamento. O valor do repasse é líquido: as taxas da Provi já estão descontadas.'
          }
        />
        <EachDetail
          isBold
          isFromHelper
          title={'Valor a receber '}
          value={'Soma de todas as parcelas com vencimento futuro. Pode sofrer alteração em caso de cancelamento.'}
        />
      </DropdownFlexChilds>
      <DropdownFlexChilds>
        <EachDetail
          isBold
          isFromHelper
          title={'Valor recebido'}
          value={
            'Soma dos repasses realizados no período, incluindo qualquer meio de pagamento: tanto no fluxo até a data de vencimento quanto por meio da recuperação de parcelas em atraso, já contabilizando juros e multas. O valor do repasse é líquido: as taxas da Provi já estão descontadas.'
          }
        />
        <EachDetail
          isBold
          isFromHelper
          title={'Valor pendente'}
          value={
            'Soma de todas as parcelas não pagas pelo aluno. Não inclui juros e multas que irão incidir sobre os valores. '
          }
        />
      </DropdownFlexChilds>
    </DropdownContainer>
  )
}
