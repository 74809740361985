import { useState, useEffect } from 'react'
import { formatThousand, unMask } from '~/utils'
import { getPartnerPreferences, getFinancialMovementV4 } from '~/services/api'

import { getQueryFilters } from '~/helpers/tables/getQueryFilters'
import { setUrlQueryFilters } from '~/helpers/tables/setQueryFilters'

import { getHeaderTableValues, formatResultTable, formatCellsWithFilterAndUrl } from './helpers'

export const useFinantialMovement = (setIsLoading, isMobile, goToPage) => {
  const INITIAL_STATE = getHeaderTableValues({ isMobile })

  //Search
  const [search, setSearch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [activeBox, setActiveBox] = useState('cpf')

  // page
  const [pageIndicator, setPageIndicator] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState({ id: 1, label: '50', value: '50' })
  const [searchOptions, setSearchOptions] = useState({ id: 1, label: 'CPF', value: 'cpf' })
  const [page, setPage] = useState(null)
  const [totalItens, setTotalItens] = useState(null)
  const [totalPages, setTotalPages] = useState(null)

  //Table
  const [rows, setRows] = useState([])
  const [filterStatus, setFilterStatus] = useState({})
  const [warningMessage, setWarningMessage] = useState('')
  const [showCells, setShowCells] = useState(INITIAL_STATE)
  const [preferences, setPreferences] = useState(null)

  //modal
  const [productsModal, setProductsModal] = useState(false)
  const [exportsModal, setExportsModal] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [helperModal, setHelperModal] = useState(false)
  const [simpleModal, setSimpleModal] = useState(false)

  //Modals data
  const [exportsModalData, setExportsModalData] = useState({})
  const [ProductData, setProductData] = useState({})
  const [helperModalData, setHelperModalData] = useState('')
  const [simpleModalData, setSimpleModalData] = useState('')

  const handleModal = (option, data) => {
    switch (option) {
      case 'filters':
        setFilterModal(!filterModal)
        break
      case 'product':
        setProductsModal(!productsModal)
        break
      case 'csv':
        setExportsModal(!exportsModal)
        if (data) setExportsModalData(data)
        break
      case 'helper':
        setHelperModal(!helperModal)
        if (data) setHelperModalData(data)
        break

      case 'simple-modal':
        setSimpleModal(!simpleModal)
        if (data) setSimpleModalData(data)
        break
      default:
        break
    }
  }

  const handleActiveBox = (value) => {
    setSearch('')
    setSearchKey('')
    setActiveBox(value)
  }

  const handleSearch = async (e) => {
    if (e.target.value === '') {
      setSearchKey('')
    }

    if (['cpf', 'crid'].includes(activeBox)) {
      setSearch(unMask(e.target.value))
    } else {
      setSearch(e.target.value)
    }
  }

  const handleSearchKey = (e) => {
    if (e.keyCode === 13) setSearchKey(search)
  }

  const handlePageIndicator = () => {
    let indicator
    if (page === 1) {
      indicator = `1 - ${rows.length < itemsPerPage.value ? formatThousand(rows.length) : formatThousand(itemsPerPage.value)}`
    } else if (rows.length == itemsPerPage.value) {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(itemsPerPage.value * page)}`
    } else {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(
        itemsPerPage.value * page - (itemsPerPage.value - rows.length),
      )}`
    }
    setPageIndicator(indicator)
  }

  const getData = async (filters) => {
    try {
      setWarningMessage('')
      setIsLoading(true)

      const response = await getFinancialMovementV4({ search, page, filters, quantity: itemsPerPage.value, key: activeBox })

      try {
        setTotalItens(response.data.paging.totalItems)
        setTotalPages(response.data.paging.totalPages)
      } catch (error) {
        console.log('[catch]', error)
      }

      if (response) {
        if (!response?.data?.content?.length > 0) {
          setWarningMessage('Nenhuma solicitação efetivada encontrada')
        }

        const rowsResponse = await formatResultTable({
          data: response.data.content,
          handleModal,
          setProductData,
          goToPage,
        })

        setRows([...rowsResponse])
      }
    } catch (error) {
      console.log('estou no catch :(', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryPage = urlParams.get('page')
    const queryFilters = getQueryFilters(urlParams)
    const keysFilters = Object.keys(queryFilters)

    setPage(queryPage ? Number(queryPage) : 1)

    if (queryFilters) {
      setFilterStatus(queryFilters)
    }

    const getFilters = async () => {
      await formatCellsWithFilterAndUrl({ setShowCells, showCells, keysFilters, queryFilters })
    }

    const getPreferences = async () => {
      const response = await getPartnerPreferences('financial-movement')
      const { preferences: preferencesResponse } = response.data
      setPreferences({ ...preferencesResponse })
    }

    getFilters()
    getPreferences()
  }, [])

  useEffect(() => {
    if (rows.length > 0) handlePageIndicator()
  }, [page, itemsPerPage, rows])

  useEffect(() => {
    setUrlQueryFilters(showCells, page, filterStatus)
  }, [searchKey, page, itemsPerPage, filterStatus])

  useEffect(() => {
    if (searchKey) setPage(1)

    if (page) {
      setRows([])
      getData(filterStatus)
    }
  }, [searchKey, page, itemsPerPage, filterStatus])

  const handlers = { handleModal, handleActiveBox, handleSearch, handleSearchKey }
  const states = {
    search,
    searchOptions,
    setSearchOptions,
    activeBox,
    pageIndicator,
    page,
    itemsPerPage,
    totalItens,
    totalPages,
    warningMessage,
    rows,
    showCells,
    productsModal,
    ProductData,
    exportsModal,
    exportsModalData,
    preferences,
    filterModal,
    helperModalData,
    helperModal,
    simpleModal,
    simpleModalData,
  }
  const setStates = { setPageIndicator, setPage, setItemsPerPage, setShowCells, setFilterStatus }

  return { handlers, states, setStates }
}
