import { useState, useCallback, useEffect, useRef, useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import firebase from '~/services/firebase'
import { v4 as uuidv4 } from 'uuid'

import { getStorage } from '~/utils'
import { LOCALSTORAGE_TOKENS } from '~/constants'
import { logger } from '~/helpers/logger'
import { getCroppedImg, readFile } from './helpers/cropperHelpers'
import { showToast } from '@provi/provi-components'

export const useImageCropper = (setCropperPhoto) => {
  const { setIsLoading, isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [imageError, setImageError] = useState(null)

  const inputFileRef = useRef()

  const onCropComplete = useCallback((croppedArea, croppedAreaPxs) => {
    setCroppedAreaPixels(croppedAreaPxs)
  }, [])

  const checkImageDimensions = (imageDimensions) => {
    setImageError(null)

    if (imageDimensions.height > 700) {
      setImageError('Imagem muito grande, por favor selecione outra imagem!')
    }
    if (imageDimensions.width < 90 || imageDimensions.height < 90) {
      setImageError('Imagem muito pequena, por favor selecione outra imagem!')
    }
  }

  const getCroppedImageToState = useCallback(async () => {
    try {
      const croppedImageState = await getCroppedImg(imageSrc, croppedAreaPixels)
      setCroppedImage(croppedImageState)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels])

  const onClose = useCallback(() => {
    setImageSrc(null)
  }, [])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      setImageSrc(imageDataUrl)
    }
  }

  const uploadBlobToFirebase = async (imageBlob) => {
    setImageSrc(null)
    setIsLoading(true)
    const now = new Date().toISOString().slice(0, 10)
    const uuid = uuidv4()
    const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
    const storagePath = `/${partnerId}/profile/images/${uuid}_${partnerId}_profile_${now}`

    const storageRef = firebase.storage().ref(storagePath)
    const uploadTask = storageRef.put(imageBlob)

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      null,
      (err) => {
        logger({ error: err, ref: 'Firebase upload error' })
        setIsLoading(false)
        showToast('Ops, ocorreu um erro ao salvar a sua imagem 😞')
      },
      async () => {
        const url = await storageRef.getDownloadURL()
        setIsLoading(false)
        showToast('Imagem salva com sucesso 😀')
        setCropperPhoto(url)
      },
    )
  }

  useEffect(() => {
    if (croppedImage) {
      uploadBlobToFirebase(croppedImage)
    }
  }, [croppedImage])

  return {
    imageSrc,
    crop,
    zoom,
    setCrop,
    onCropComplete,
    setZoom,
    getCroppedImageToState,
    croppedImage,
    onClose,
    onFileChange,
    inputFileRef,
    isMobile,
    drawerWidth,
    imageError,
    checkImageDimensions,
  }
}
