import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import Select from 'react-select'

export const Container = styled.div`
  max-width: 1200px;
  margin: 146px auto;
  padding: 0 20px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    margin-top: 115px;
  }
`

export const Content = styled.div`
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const CloseButton = styled.div`
  user-select: none;
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 5px;
`

export const WrapperClose = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.pink900};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionHeader = styled.div``

export const WrapperText = styled.div`
  text-align: center;
`

export const WrapperValue = styled.div`
  max-width: 350px;
  text-align: center;
  width: 100%;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: end;
`

export const SectionValue = styled.div`
  padding: 10px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-top: 50px;
  }
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
`

export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 60px 0;
  padding: 0 10px;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
  }

  ${theme.breakpoints.down('md')} {
    margin: 30px 0;
    h5 {
      font-size: 14px;
    }
  }
`

export const Selector = styled(Select).attrs((props) => ({
  inputProps: { 'aria-label': { name: props.name } },
  hideSelected: true,
  styles: {
    multiValue: (provided) => ({
      ...provided,
      maxWidth: 320,
    }),
    option: (provided, { isDisabled }) => ({
      padding: 5,
      height: 35,
      fontSize: 14,
      paddingLeft: 13,
      color: isDisabled ? theme.colors.grey500 : theme.colors.blue800,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isDisabled ? 'transparent' : theme.colors.grey500,
      },
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.blue800}`,
      borderRadius: 5,
      height: 42,
      fontFamily: 'CenturyGothic, sans serif',
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      lineHeight: 34,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      fontFamily: 'CenturyGothic, sans serif',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.pink900,
      marginRight: 8,
    }),
    placeholder: () => ({
      fontFamily: 'CenturyGothic, sans serif',
      color: theme.colors.grey600,
      fontSize: 16,
    }),
    indicatorSeparator: () => ({}),
    menuList: (menuList) => ({
      ...menuList,
      padding: 0,
      zIndex: 100,
    }),
    menu: (provided) => ({
      ...provided,
      borderCollapse: 'separate',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'CenturyGothic, sans serif',
      width: 'max-content',
      minWidth: '100%',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 16px',
      fontSize: 20,
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }),
  },
}))``
