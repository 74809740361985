import React from 'react'
import { Collapse } from '@material-ui/core'
import { theme } from '@provi/provi-components'
import { useToggle } from '~/hooks'
import { BaseTypography } from '~/globalStyles'
import { CollapseContainer, TextGrid } from './styles'
import { EachDetailText } from '~/screens/courses/components/DetailsComponents/components'

export const CourseExpandDetails = ({ isMobile, courseDetails }) => {
  const [dropdownState, toggleDropdownState] = useToggle()
  const priceFormatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(parseFloat(courseDetails?.price || 0))

  const formatIsOnProviSite = courseDetails?.active ? 'Sim' : 'Não'

  return (
    <>
      {isMobile ? (
        <CollapseContainer>
          <BaseTypography
            onClick={toggleDropdownState}
            fontSize={'13px'}
            lineHeight={'20px'}
            fontWeight={700}
            color={theme.colors.bluePrimary}
            cursor={'pointer'}
          >
            Mais detalhes
          </BaseTypography>

          <Collapse in={dropdownState}>
            <TextGrid>
              <EachDetailText property={'Carga horária'} value={courseDetails?.workload} />
              <EachDetailText property={'Preço'} value={priceFormatted || '---'} />
              <EachDetailText property={'Área'} value={courseDetails?.MacroArea?.name} />
              <EachDetailText property={'Especialidade'} value={courseDetails?.MicroArea?.name} />
              <EachDetailText property={'Disponível para compra no site da Provi'} value={formatIsOnProviSite} />
              <EachDetailText property={'Descrição'} value={courseDetails?.description} />
            </TextGrid>
          </Collapse>
        </CollapseContainer>
      ) : (
        <TextGrid>
          <EachDetailText property={'Carga horária'} value={courseDetails?.workload} />
          <EachDetailText property={'Preço'} value={priceFormatted || '---'} />
          <EachDetailText property={'Área'} value={courseDetails?.MacroArea?.name} />
          <EachDetailText property={'Especialidade'} value={courseDetails?.MicroArea?.name} />
          <EachDetailText property={'Disponível para compra no site da Provi'} value={formatIsOnProviSite} />
          <EachDetailText property={'Descrição'} value={courseDetails?.description} />
        </TextGrid>
      )}
    </>
  )
}
