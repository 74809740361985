import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ActionsBtnFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`
export const BlueActionTitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`
