import React from 'react'
import { formatValueToFloatWith2Digits } from '../../utils'

function CustomLabel(props) {
  const { x, y, value, width, labelColor, receiptsData, titleLabel } = props

  const repeatedTitleWithSum = receiptsData.find((valueReceipt) => valueReceipt.sum)

  if (value !== 0 && repeatedTitleWithSum.sum.columnName !== titleLabel) {
    return (
      <text
        x={x}
        y={y}
        // Move slightly above axis
        dy={-5}
        // Center text
        dx={width / 2}
        fill={labelColor}
        fontSize={12}
        fontWeight="bold"
        textAnchor="middle"
      >
        {formatValueToFloatWith2Digits(value)}
      </text>
    )
  } else {
    return <text></text>
  }
}

export { CustomLabel }
