import React from 'react'
import { Link } from 'react-router-dom'
import { isBefore, subDays } from 'date-fns'
import { theme } from '@provi/provi-components'
import { formatValue, formatPercentageInteger, getActiveUser, parseThenFormatDate } from '~/utils'

import { ProductLink } from '~/components/table/product-link'
import { ReleaseCourse } from '~/components/table/release-course'

import { Text } from '~/themes/styles'
import { StatusBox, StatusCircle } from '../components'

import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'
import { WrapperText } from '../styles'

import { formatStatusBox } from './formatStatusBox'
import { formatCoursesText } from '~/components/table/helpers/formatCoursesText'

const checkIfDateIsBefore = ({ status, date }) => {
  if (!date) return null

  return !['paid', 'effective'].includes(status) && isBefore(new Date(date), subDays(new Date(), 3))
}

export const formatResultTable = async ({ data, handleModal, setProductData }) => {
  return data.map((result) => {
    const fallbackString = '---'
    const {
      crid,
      status,
      hostingStatus,
      cpf,
      name,
      email,
      lateDays,
      installmentCount,
      isRenegotiated,
      nextPaymentDate,
      installmentValue,
      saleValue,
      receivedValue,
      courseReleasedAt,
      phone,
      madeEffectiveDate,
      productType,
      missingValue,
      commission,
      transferDate,
      pfCount,
      nextPaymentInstallmentId,
      agreementDate,
      expectedTransferDate,
      comment,
      expectedTedValue,
      originalInstallmentsValue,
      originalNumberOfInstallments,
      campaignName,
      courses,
    } = result

    const { current: currentInstallment, late: delayedInstallment, paid: paidInstallment } = installmentCount || {}
    const { upfront = null, installments = 0 } = currentInstallment || {}
    const installmentsConsideringUpfront = upfront ? `${upfront} + ${installments}` : installments

    const formattedStatus = formatStatusBox(status)
    const hostingColorStatus = formatStatusBox(hostingStatus)
    const isMoreThanOneCurrentInstallments = pfCount && Number(pfCount) > 1

    const nextPaymentIsBefore =
      nextPaymentDate && checkIfDateIsBefore({ status: formattedStatus?.value, date: nextPaymentDate })
    const agreementDateIsBefore = agreementDate && checkIfDateIsBefore({ status: formattedStatus?.value, date: agreementDate })

    let color = theme.colors.blue900
    if (agreementDateIsBefore) {
      color = theme.colors.pink900
    } else if ((agreementDateIsBefore || nextPaymentIsBefore) && isMoreThanOneCurrentInstallments) {
      color = '#FC7900'
    } else if (nextPaymentIsBefore && delayedInstallment > 0) {
      color = theme.colors.pink900
    }

    const agreementDateColor = agreementDateIsBefore && delayedInstallment > 0 ? theme.colors.pink900 : theme.colors.blue900

    const AgreementDate = () =>
      agreementDate ? <Text color={agreementDateColor}> {parseThenFormatDate(agreementDate)} </Text> : fallbackString
    const InstallmentValue = () =>
      installmentValue ? <Text color={color}>{formatValue(installmentValue)}</Text> : fallbackString
    const NextPaymentDate = () =>
      nextPaymentIsBefore ? (
        <Text color={theme.colors.pink900}> {nextPaymentDate && parseThenFormatDate(nextPaymentDate)} </Text>
      ) : (
        nextPaymentDate && parseThenFormatDate(nextPaymentDate)
      )

    const CourseReleasedDate = () => {
      const formattedDate = courseReleasedAt ? parseThenFormatDate(courseReleasedAt) : null

      return <ReleaseCourse value={formattedDate} id={crid} />
    }

    return {
      id: crid ? (
        <Link
          to={`/detalhes/${crid}?u=${getActiveUser()}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: 'pointer', color: theme.colors.blue900 }}
        >
          {crid}
        </Link>
      ) : (
        fallbackString
      ),
      status: status ? <StatusBox status={formattedStatus} /> : fallbackString,
      ...(hostingStatus !== 'host_not_integrated' ? { hostingStatus: <StatusCircle status={hostingColorStatus} /> } : {}),
      cpf: cpf || fallbackString,
      productType: getTranslattedProductType(productType) || fallbackString,
      name: name || fallbackString,
      email: email || fallbackString,
      phone: phone || fallbackString,
      campaignName: campaignName || fallbackString,
      products:
        courses && courses.length > 0 ? (
          <ProductLink target="product" handleModal={handleModal} courses={courses} setProductData={setProductData}>
            {courses.length > 1 ? (
              `${courses.length} produtos`
            ) : (
              <>
                <WrapperText>{formatCoursesText(courses[0])}</WrapperText>
              </>
            )}
          </ProductLink>
        ) : (
          '---'
        ),
      madeEffectiveDate: madeEffectiveDate ? parseThenFormatDate(madeEffectiveDate) : fallbackString,
      installments: isRenegotiated ? (
        <ProductLink
          target="installments"
          handleModal={handleModal}
          installmentData={{
            initial: originalNumberOfInstallments,
            initial_intallment_value: formatValue(originalInstallmentsValue, true),
          }}
        >
          {installmentsConsideringUpfront || fallbackString}
        </ProductLink>
      ) : (
        installmentsConsideringUpfront || fallbackString
      ),
      installments_paid: paidInstallment || paidInstallment === 0 ? paidInstallment : fallbackString,
      installments_delayed: delayedInstallment || delayedInstallment === 0 ? delayedInstallment : fallbackString,
      delayed_days: lateDays || lateDays === 0 ? lateDays : fallbackString,
      released: <CourseReleasedDate />,
      agreementDate: <AgreementDate />,
      nextPayment: <NextPaymentDate />,
      installment_value:
        installmentValue && (isMoreThanOneCurrentInstallments || agreementDateIsBefore) ? (
          <ProductLink
            target="installments-value"
            handleModal={handleModal}
            underlineColor={color}
            installmentValueData={{
              InstallmentId: nextPaymentInstallmentId,
              isDelayed: agreementDateIsBefore,
              CreditRequestId: crid,
            }}
          >
            <InstallmentValue />
          </ProductLink>
        ) : (
          <InstallmentValue />
        ),
      value: saleValue ? formatValue(saleValue) : fallbackString,
      commission_provi: commission?.value ? formatValue(commission?.value) : fallbackString,
      commission_partner: commission?.percentage ? formatPercentageInteger(commission?.percentage) : fallbackString,
      total_amount_receivable: missingValue ? formatValue(missingValue) : fallbackString,
      total_amount_received:
        (receivedValue && expectedTedValue > receivedValue ? (
          <ProductLink
            target="loanOptionComment"
            handleModal={handleModal}
            loanOptionCommentData={{
              expectedTEDValue: formatValue(expectedTedValue) || fallbackString,
              amountOfTEDPerformed: formatValue(receivedValue) || fallbackString,
              loanOptionComments: comment || fallbackString,
            }}
          >
            <div>{receivedValue && formatValue(receivedValue)}</div>
          </ProductLink>
        ) : (
          receivedValue && formatValue(receivedValue)
        )) || fallbackString,
      tedDate: transferDate ? parseThenFormatDate(transferDate) : fallbackString,
      expectedTransferDate: expectedTransferDate ? parseThenFormatDate(expectedTransferDate) : fallbackString,
    }
  })
}
