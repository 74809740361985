import React from 'react'
import { theme, CheckBox, InputFieldV3 } from '@provi/provi-components'
import { BlackHeaders } from '../index'
import { VerticalContainers, FlexContainers, TwoHundredPxDiv } from '../../styles'
import { ChainLinkIcon } from '~/assets/svgs/v2'
import { usePaymentMethodSub } from './hooks'

export const PaymentMethodSub = ({ isMobile, stateProps }) => {
  const { formValues, setFormValues, discountPercentage, priceWithDiscount } = stateProps || {}
  const {
    valuesAndPercentages,
    flexGaps,
    flexAligns,
    flexDirs,
    handleCheckBoxChange,
    handleMaskedInputChange,
    handlePercentageChange,
  } = usePaymentMethodSub({
    isMobile,
    formValues,
    setFormValues,
    discountPercentage,
    priceWithDiscount,
  })

  return (
    <>
      <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
        <BlackHeaders
          isMobile={isMobile}
          title={'Métodos de pagamento disponíveis '}
          explanation={'O aluno poderá escolher como pagar dentro do modelo de precificação determinado por você.'}
        />
        <VerticalContainers gap={'1.5rem'}>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox
                name="CreditCard"
                text={'Cartão de crédito'}
                checked={formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CreditCard')}
                onChange={() => handleCheckBoxChange('CreditCard')}
              />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3
                type="text"
                name="CreditCard"
                placeholder="R$ 5.000,00"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CreditCard')}
                value={valuesAndPercentages?.CreditCard?.value}
                onChange={handleMaskedInputChange}
              />
              {!isMobile && (
                <ChainLinkIcon
                  color={
                    !formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CreditCard') &&
                    theme.colors.grey500
                  }
                />
              )}
              <InputFieldV3
                type="text"
                name="CreditCard"
                placeholder="Ex: 20%"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CreditCard')}
                value={valuesAndPercentages?.CreditCard?.percentage}
                onChange={handlePercentageChange}
                mask={'99'}
              />
            </FlexContainers>
          </FlexContainers>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox
                name="Boleto"
                text={'Boleto à vista'}
                checked={formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'Boleto')}
                onChange={() => handleCheckBoxChange('Boleto')}
              />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3
                type="text"
                name="Boleto"
                placeholder="R$ 5.000,00"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'Boleto')}
                value={valuesAndPercentages?.Boleto?.value}
                onChange={handleMaskedInputChange}
              />
              {!isMobile && (
                <ChainLinkIcon
                  color={
                    !formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'Boleto') &&
                    theme.colors.grey500
                  }
                />
              )}
              <InputFieldV3
                type="text"
                name="Boleto"
                placeholder="Ex: 20%"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'Boleto')}
                value={valuesAndPercentages?.Boleto?.percentage}
                onChange={handlePercentageChange}
                mask={'99'}
              />
            </FlexContainers>
          </FlexContainers>
          <FlexContainers gap={flexGaps} alignItems={flexAligns} flexDirection={flexDirs}>
            <TwoHundredPxDiv>
              <CheckBox
                name="CourseFinancing"
                text={'Financiamento'}
                checked={formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CourseFinancing')}
                onChange={() => handleCheckBoxChange('CourseFinancing')}
              />
            </TwoHundredPxDiv>
            <FlexContainers gap={flexGaps} justifyContent={'space-between'} alignItems={'center'} hasFlexGrow>
              <InputFieldV3
                type="text"
                name="CourseFinancing"
                placeholder="R$ 5.000,00"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CourseFinancing')}
                value={valuesAndPercentages?.CourseFinancing?.value}
                onChange={handleMaskedInputChange}
              />
              {!isMobile && (
                <ChainLinkIcon
                  color={
                    !formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CourseFinancing') &&
                    theme.colors.grey500
                  }
                />
              )}
              <InputFieldV3
                type="text"
                name="CourseFinancing"
                placeholder="Ex: 20%"
                width={'100%'}
                disabled={!formValues?.launchPricing?.options?.some((eachObj) => eachObj?.paymentMethod === 'CourseFinancing')}
                value={valuesAndPercentages?.CourseFinancing?.percentage}
                onChange={handlePercentageChange}
                mask={'99'}
              />
            </FlexContainers>
          </FlexContainers>
        </VerticalContainers>
      </VerticalContainers>
    </>
  )
}
