import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const AddAccountContainer = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 526px;

  ${theme.breakpoints.down('md')} {
    gap: 1.5rem;
  }
`

export const BlueHeader = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }

  ${({ isFromDropdown }) =>
    isFromDropdown &&
    css`
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    `}
`

export const StyledInput = styled.div`
  display: grid;
  gap: 0.5rem;
`

export const BoldLabel = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  ${theme.breakpoints.down('md')} {
    font-size: 13x;
    line-height: 20px;
  }
`

export const BlackParagraph = styled.p`
  margin: 0;
  font-size: 12.8px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    transform: ${({ state }) => (state ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.4s ease-in-out;
  }
`

export const DropdownContainer = styled.div`
  cursor: pointer;
  display: grid;
  gap: 1rem;
`
