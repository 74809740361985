import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { postCsv } from '~/services/api'
import { getStorage } from '~/utils'

export const useCheckCsvHooks = (location, setIsLoading, handleModal, selectedByUserCourses) => {
  const [email, setEmail] = useState()

  useEffect(() => {
    const getData = () => {
      const emailStorage = getStorage({ key: 'email' })
      const emailSplited = emailStorage.split('@')
      setEmail(`${emailSplited[0].split('+')[0]}@${emailSplited[1]}`)
    }
    getData()
  }, [])

  const handleConfirm = async (handleV2 = false, filterStatus = {}) => {
    setIsLoading(true)

    try {
      await postCsv(location, [], handleV2, filterStatus)
      showToast('Envio de email confirmado!')
      handleModal('csv')
    } catch (error) {
      showToast('Houve um erro na criação de csv, tente novamente ou contate a Provi')
    } finally {
      setIsLoading(false)
    }
  }

  const handleExport = async (handlev2 = false) => {
    setIsLoading(true)

    try {
      console.log('SHOULD HANDLE V2 EXPORT', handlev2)
      await postCsv('createHotmartInvitationCsv', selectedByUserCourses, handlev2)
      setIsLoading(false)
      showToast('Envio de email confirmado!')
      handleModal('csv')
    } catch (error) {
      setIsLoading(false)
      showToast('Houve um erro na criação de csv, tente novamente ou contate a Provi')
    }
  }

  return [email, handleConfirm, handleExport]
}
