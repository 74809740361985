import React from 'react'
import clsx from 'clsx'
import { IconButton, List, Drawer, CssBaseline, Tooltip } from '@material-ui/core'
import { DrawerChevron } from '~/assets/svgs/drawer-chevron'
import { DrawerChevronOpen } from '~/assets/svgs/drawer-chevron-open'
import { MobileDrawerIcon } from '~/assets/svgs/mobile-drawer-icon'
import { DrawerTitle, Divider, MainContainer, MainDiv, PartnerPhoto, DrawerWrapper, StyledTitle, SvgWrapper } from './styles'
import { useDrawerStyles } from './hooks'
import { useScreenMeasure } from '~/hooks'
import { CustomListItem } from './components'
import { ProviLogo } from '~/assets/svgs/v2'

const MobileOpenDrawer = ({ handleDrawerState, open }) => {
  if (!open)
    return (
      <IconButton onClick={handleDrawerState} style={{ zIndex: '1000', position: 'fixed' }}>
        <MobileDrawerIcon />
      </IconButton>
    )
  return null
}

export const DrawerMenu = ({
  open,
  currentOpenTabs,
  setCurrentOpenTabs,
  existingTabs,
  currentRoute,
  handleDrawerState,
  accessData,
  goToPage,
}) => {
  const { classes } = useDrawerStyles()
  //eslint-disable-next-line
  const [_, isMobile] = useScreenMeasure()

  const schoolNameToShow =
    accessData?.partnerName?.length > 18 ? `${accessData?.partnerName?.substring(0, 18)}...` : accessData?.partnerName

  return (
    <div>
      {isMobile && (
        <>
          <MobileOpenDrawer handleDrawerState={handleDrawerState} open={open} />
        </>
      )}
      <CssBaseline />
      <Drawer
        variant={'permanent'}
        className={
          isMobile && !open
            ? classes.hide
            : clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })
        }
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <MainDiv className={open ? classes.openedWrapper : classes.closedWrapper}>
          <SvgWrapper>
            <ProviLogo onClick={() => goToPage('home')} hasCursor />
          </SvgWrapper>
          <IconButton onClick={handleDrawerState}>{open ? <DrawerChevron /> : <DrawerChevronOpen />}</IconButton>
        </MainDiv>
        {open ? (
          <>
            <DrawerTitle>
              <p> VOCÊ ESTÁ LOGADO EM:</p>
            </DrawerTitle>
            <Tooltip title={accessData?.partnerName}>
              <DrawerWrapper onClick={() => goToPage('home')}>
                {accessData?.photoURL && <PartnerPhoto src={accessData?.photoURL} alt="" />}
                <StyledTitle isCenteredText={!accessData?.photoURL}>{schoolNameToShow}</StyledTitle>
              </DrawerWrapper>
            </Tooltip>
          </>
        ) : (
          <>
            <Divider customHeight={'48px'} />
            <DrawerWrapper onClick={() => goToPage('home')}>
              {accessData?.photoURL && <PartnerPhoto src={accessData?.photoURL} alt="" isCentered />}
            </DrawerWrapper>
          </>
        )}
        {open ? (
          <DrawerTitle>
            <p>PAINEL DO PARCEIRO</p>
          </DrawerTitle>
        ) : (
          <Divider customHeight={'48px'} />
        )}
        <List>
          {existingTabs.map((tab) => (
            <CustomListItem
              key={tab.title}
              currentOpenTabs={currentOpenTabs}
              setCurrentOpenTabs={setCurrentOpenTabs}
              mainTab={tab.mainTab}
              title={tab.title}
              internalTabs={tab.internalTabs}
              currentRouinheritte={currentRoute}
              open={open}
              Icon={tab.Icon}
              divide={tab.divide}
              titleColor={tab.titleColor}
              currentRoute={currentRoute}
              action={tab.action}
              handleDrawerState={handleDrawerState}
            />
          ))}
        </List>
        <Divider />
      </Drawer>
      <MainContainer className={classes.content}>
        <MainDiv className={classes.toolbar} />
      </MainContainer>
    </div>
  )
}
