/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CloseModal } from '~/assets/svgs/close-modal'

import { Overlay, Header, Body, Container, Title, CloseModalWrapper, Footer, Content } from './style'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

export const Modal = ({ isWithoutHeight, title, onDismiss, children, footer, width, radius, fontv2 }) => {
  const { isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  return (
    <Overlay isWithoutHeight={isWithoutHeight} isMobile={isMobile} drawerWidth={drawerWidth}>
      <Container width={width} isWithoutHeight={isWithoutHeight} radius={radius}>
        <CloseModalWrapper id="close" onClick={onDismiss}>
          <CloseModal />
        </CloseModalWrapper>
        <Content>
          <Header>{title && <Title fontv2={fontv2}>{title}</Title>}</Header>
          <Body fontv2={fontv2}>{children}</Body>
          {footer && <Footer>{footer}</Footer>}
        </Content>
      </Container>
    </Overlay>
  )
}

Modal.defaultProps = {
  isWithoutHeight: false,
  footer: null,
}

Modal.propTypes = {
  isWithoutHeight: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  footer: PropTypes.element,
}
