import styled from 'styled-components'
import { theme, Card } from '@provi/provi-components'

export const PageWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    min-height: calc(100% + 80px);
    padding: initial;
  }
`

export const Container = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 30px 30px 30px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    min-height: calc(100% + 80px);
    padding: initial;
  }
`
export const Header = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #cfcfcf;

  ${theme.breakpoints.down('md')} {
    padding: 25px 0 0;
    border-bottom: initial;
  }
`

export const HeaderText = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    padding-left: 70px;
    font-size: 16px;
  }
`

export const Content = styled.div`
  margin-top: 15px;
`

export const StyledCard = styled(Card)`
  padding: 33px 44px 36px 23px;
  border-radius: 5px;

  ${theme.breakpoints.down('md')} {
    padding: 25px 15px;
  }
`

export const CtaText = styled.div`
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const SectionButtonsWrapper = styled.div`
  display: flex;

  button + button {
    margin-left: 16px;
  }

  ${theme.breakpoints.down('md')} {
    button {
      width: 100%;
      font-size: 10px;
      line-height: 12px;
    }

    button > div {
      width: 10px;
      height: 12px;
      font-size: 10px;
      line-height: 12px;
    }

    button + button {
      margin-left: 8px;
    }
  }
`

export const ComponentSection = styled.div``

export const Components = styled.div`
  min-width: 150px;
  min-height: 300px;
  background-color: white;
`

export const TableHeaderText = styled.div`
  margin-top: 50px;
  margin-bottom: 16px;
  font-size: 16;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    margin-top: 30px;
    font-size: 14px;
    line-height: 24px;
  }
`

export const TableWrapper = styled.div`
  ${theme.breakpoints.down('md')} {
    padding: 0 16px;
  }
`
