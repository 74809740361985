import { useState } from 'react'
import {
  getRegisteredServices,
  sendServiceDefault,
  getPartnerServices,
  setCourseTriggerDefault,
  sendNewNFSEService,
} from '~/services/api'
import { formatCnae } from '~/utils'
import { useMount } from '~/hooks'
import { logger } from '~/helpers/logger'
import { getHeaderTableValues } from './tableValues'

/**
 * @name useServicesNFE
 * @description custom hook to manage school services nfse
 */
export const useServicesNFE = ({ showToast, setIsLoading, onChangeShowModal, resetForm }) => {
  const INITIAL_SERVICES_STATE = getHeaderTableValues({ table: 'services' })
  const INITIAL_COURSES_STATE = getHeaderTableValues({ table: 'courses' })

  const [servicesRegistered, setServicesRegistered] = useState([])
  const [servicesCourses, setServicesCourses] = useState([])
  const [showServicesCells, setShowServicesCells] = useState(INITIAL_SERVICES_STATE)
  const [showCoursesCells, setShowCoursesCells] = useState(INITIAL_COURSES_STATE)

  /**
   * @name changeServiceTrigger
   * @function
   * @description change default trigger from course
   * @return void
   */
  const changeServiceTrigger = async (row, trigger) => {
    const { value } = trigger
    const { NFSeServiceId, id } = row

    setIsLoading(true)
    const putBody = {
      NFSeServiceId,
      CourseId: id,
      NFSeTriggerId: value,
      isDefault: false,
    }

    try {
      await setCourseTriggerDefault(putBody)
      setIsLoading(false)
      showToast(`Alterado com sucesso 😉`)
    } catch (error) {
      setIsLoading(false)
    }
  }

  /**
   * @name getPartnerServicesRegistered
   * @function
   * @description get all courses from partner
   * @return void
   */
  const getPartnerServicesRegistered = async () => {
    setIsLoading(true)
    try {
      const { data } = await getPartnerServices()
      setServicesCourses(data.services)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  /**
   * @name sendNewService
   * @function
   * @description create new school service
   * @param {*} formData
   * @return void
   */
  const sendNewService = async (formData) => {
    setIsLoading(true)
    formData.cnae = formatCnae(formData.cnae)
    try {
      await sendNewNFSEService(formData)
      setIsLoading(false)
      showToast('Serviço criado com sucesso! 🥳')
      await getServicesData()
    } catch (error) {
      setIsLoading(false)
      showToast('Erro ao cadastrar serviço 😞')
    }

    onChangeShowModal(false)
    resetForm()
  }

  /**
   * @name getServicesData
   * @function
   * @description create all services from school
   * @return void
   */
  const getServicesData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getRegisteredServices()
      setServicesRegistered(data.services)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      showToast('Erro ao carregar serviços 😞')
      logger({ error })
    }
  }

  const setServiceDefault = async (service) => {
    setIsLoading(true)

    const postBody = { isDefault: true, NFSeServiceId: service.id }
    const response = await sendServiceDefault(postBody)
    if (response.status === 200) {
      setIsLoading(false)
      showToast('O serviço foi definido como padrão! 🥳')
      await getServicesData()
    } else {
      setIsLoading(false)
      showToast('Erro ao definir serviço como padrão! 😞')
    }
  }

  useMount(() => {
    getServicesData()
    getPartnerServicesRegistered()
  })

  return {
    servicesRegistered,
    getServicesData,
    sendNewService,
    setServiceDefault,
    servicesCourses,
    changeServiceTrigger,
    showServicesCells,
    setShowServicesCells,
    showCoursesCells,
    setShowCoursesCells,
    setServicesRegistered,
    setServicesCourses,
  }
}
