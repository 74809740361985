import React from 'react'

export const ShareIcon = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.78149 12.75C5.30028 12.75 6.53149 11.5188 6.53149 10C6.53149 8.48122 5.30028 7.25 3.78149 7.25C2.26271 7.25 1.03149 8.48122 1.03149 10C1.03149 11.5188 2.26271 12.75 3.78149 12.75Z"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2188 18.9375C17.7375 18.9375 18.9688 17.7063 18.9688 16.1875C18.9688 14.6687 17.7375 13.4375 16.2188 13.4375C14.7 13.4375 13.4688 14.6687 13.4688 16.1875C13.4688 17.7063 14.7 18.9375 16.2188 18.9375Z"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2188 6.5625C17.7375 6.5625 18.9688 5.33128 18.9688 3.8125C18.9688 2.29372 17.7375 1.0625 16.2188 1.0625C14.7 1.0625 13.4688 2.29372 13.4688 3.8125C13.4688 5.33128 14.7 6.5625 16.2188 6.5625Z"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4687 5.125L6.81274 8.51317"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.81274 11.4868L13.125 14.9375"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
