import styled from 'styled-components'

import Select from '@material-ui/core/Select'
import { theme } from '@provi/provi-components'

export const handleColorStatus = (status, initialStatus) => {
  const findStatus = status || initialStatus
  switch (findStatus) {
    case null:
      return '#BCACAC'
    case 'basic_info_review':
    case 'document_info_review':
    case 'guarantor_review':
      return '#FABC01'
    case 'analysis':
      return '#FDEF74'
    case 'approved_by_provi':
      return '#13A41F'
    case 'not_approved_by_provi':
      return '#B30142'
    case 'approved_with_caveats_by_provi':
      return '#A5ECAB'
    case 'approved':
      return '#A5ECAB'
    case 'waiting_signature':
    case 'basic_info_incomplete':
    case 'waiting_payment':
    case 'document_info_incomplete':
    case 'guarantor_needed':
    case 'guarantor_incomplete':
      return '#F55D0C'
    case 'made_effective':
      return '#2647D7'
    case 'link_inactive':
    case 'expired':
    case 'not_logged':
      return '#CFCFCF'
    case 'isa_default':
      return '#D4DAF7'

    case 'abandonment':
    case 'abandonment_before_signed':
    case 'abandonment_after_signed':
    case 'isa_abandonment_after_signed':
      return '#DE0C4B'

    case 'abandonment_after_upfront':
      return '#8D020A'

    case 'abandonment_after_settled':
    case 'canceled':
    case 'negativated':
    case 'denied':
      return '#000'

    default:
      break
  }
}

export const Wrapper = styled.div`
  display: flex;
`

export const DropDown = styled(Select)`
  background-color: ${({ status, initialStatus }) => handleColorStatus(status, initialStatus)};
  cursor: pointer !important;
  color: white !important;
  border-radius: 5px !important;
  width: 300px;
  font-family: 'CenturyGothicBold' !important;
  font-size: 14px !important;
  padding-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px;
  box-sizing: border-box;

  option {
    font-family: 'CenturyGothic' !important;
    color: ${theme.colors.blue900};
  }

  select {
    padding: 0 24px 0 0 !important;
    text-align: center;
    text-transform: uppercase !important;
    text-align-last: center;
  }

  .MuiSelect-select.Mui-disabled {
    cursor: pointer;
  }

  .MuiSvgIcon-root.MuiSelect-icon {
    display: ${({ initialStatus }) => (initialStatus !== 'not_logged' ? 'none' : '')};
  }
  @media screen and (max-width: 1300px) {
    width: 220px;
    font-size: 12px !important;
  }
`

export const IconBox = styled.div`
  border: 1px solid ${theme.colors.pink900};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
`
