import React from 'react'
import { Wrapper, Screen, Wrapper404, WrapperErrorText, BoldText, Text, WrapperIcon } from './style'

import { Text404 } from './svg/404'
import { ErrorText } from './svg/ErrorText'
import { ProviIcon } from './svg/ProviIcon'

export const NotFound = () => {
  return (
    <Wrapper>
      <Screen>
        <Wrapper404>
          <Text404 />
        </Wrapper404>
        <WrapperErrorText>
          <ErrorText />

          <BoldText>Desculpe!</BoldText>
          <Text>Alguma coisa deu errado por nossa culpa.</Text>
          <WrapperIcon onClick={() => {}}>
            <ProviIcon />
          </WrapperIcon>
        </WrapperErrorText>
      </Screen>
    </Wrapper>
  )
}
