import styled from 'styled-components'
import { theme, PortalModal } from '@provi/provi-components'

export const Container = styled.div`
  #modal-root {
    z-index: 999999999;
  }
`

export const PortalModalComponent = styled(PortalModal)`
  padding: 1.3rem;
  border-radius: 5px;
  width: 710px;
  min-height: 265px;
  display: grid;
  gap: 1rem;
  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`
export const StyledHeader = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-size: 18px;
  }
`

export const StyledParagraph = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
