export const getTranslattedOrigin = (origin) => {
  const originOptions = {
    CHECKOUT: 'Venda exclusiva',
    TYPEFORM: 'Venda comum',
    TYPEFORM_ISA: 'Venda comum',
    SDK: 'Venda comum',
    API_V2: 'API',
    API_V1: 'API',
    CAMPAIGN: 'Campanha',
    PROVI_PAY: 'ProviPay',
  }

  return originOptions[origin] || origin
}

export const getOriginalOriginOptions = (origin) => {
  const originOptions = {
    'Venda exclusiva': 'CHECKOUT',
    'Venda comum': 'SDK',
    API: 'API_V2',
    Campanha: 'CAMPAIGN',
    ProviPay: 'PROVI_PAY',
  }

  return originOptions[origin] || origin
}
