import { formatValue } from '~/utils'
import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'

export const fallbackString = '----'

export const formatDetailsStatus = (string) => {
  const detailFormatterObj = {
    paid: {
      status: 'Pago',
      statusColor: '#CFEFB6',
    },
    pending: {
      status: 'Pendente',
      statusColor: '#FFD482',
    },
    default: {
      status: 'Inadimplente',
      statusColor: '#FBBABC',
    },
    negativated: {
      status: 'Negativado',
      statusColor: '#D0D0D0',
    },
    protest: {
      status: 'Protestado',
      statusColor: '#ADDAFF',
    },
    fallback: {
      status: fallbackString,
      statusColor: '',
    },
  }
  return detailFormatterObj[string] || detailFormatterObj.fallback
}

export const formatCancellationStatus = (string) => {
  const cancellationStatusesFormatter = {
    analysis: {
      status: 'Em análise',
      statusColor: '#ADDAFF',
    },
    approved: { status: 'Aprovado', statusColor: '#CFEFB6' },
    canceled: { status: 'Cancelado', statusColor: '#ECEAEA' },
    done: { status: 'Concluído', statusColor: '#9AED53' },
    error: { status: 'Erro no cancelamento', statusColor: '#FF9882' },
    waiting_cancellation: { status: 'Aguardando cancelamento', statusColor: '#FBBABC' },
    reverted: { status: 'Revertido', statusColor: '#FFD482' },
    fallback: { status: fallbackString, statusColor: '' },
  }
  return cancellationStatusesFormatter[string] || cancellationStatusesFormatter.fallback
}

export const installmentCount = (value, rows) => {
  if (!value) {
    return 'Entrada'
  }
  if (rows[0].values.count === null) {
    return `${value}/${rows.length - 1}`
  }
  return `${value}/${rows.length}`
}

export const mobileValuesToPrint = [
  'Valor pago',
  'Comissão',
  'Valor recebido',
  'Vencimento',
  'Pagamento aluno',
  'Atraso em dias',
  'Data do acordo',
]

export const findAndPrintValue = (row, headerValue) => {
  return row.allCells.find(({ column: { Header } }) => Header === headerValue)
}

export const formatBoleto = (string) => {
  const boletoFormatterObj = {
    PAID: 'Pago',
    WAITING: 'Pendente',
    fallback: fallbackString,
  }
  return boletoFormatterObj[string] || boletoFormatterObj.fallback
}

export const handleNegativeDelayedDays = (number) => {
  return number > 0 ? number : fallbackString
}

export const handleMobileToggle = (rowParams, defaultColumnParams) => {
  const { toggleRowExpanded } = rowParams
  const { isMobileProps } = defaultColumnParams

  return isMobileProps
    ? toggleRowExpanded()
    : () => {
        return
      }
}

export const checkChargebackExpiry = (string) => {
  const chargebacksExpiryStrings = ['Encerrado Estornado', 'Encerrado Recuperado', 'Encerrado Coberto', 'Encerrado Expirado']
  return !chargebacksExpiryStrings.includes(string)
}

export { formatValue, getTranslattedProductType }
