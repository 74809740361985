import { useState, useCallback, useContext } from 'react'
import { showToast } from '@provi/provi-components'
import { getFiltersCheckout, getSalesFunnelData } from '~/services/api'
import { useMount, useUpdate } from '~/hooks'
import { TemplateContext } from '~/components/Template/context'
import { isEqual } from 'lodash'
import { logger } from '~/helpers/logger'

/**
 * @typedef {object} IModalState
 * @property {string} dateType
 * @property {string} description
 */

export const options = [
  { value: 'paymentDate', label: 'Data de pagamento' },
  { value: 'createdAt', label: 'Data de criação' },
  { value: 'made_effective', label: 'Data de efetivação' },
]

export const useSalesFunnel = () => {
  const { setIsLoading, goToPage, setActivePage } = useContext(TemplateContext)
  const [startDatePicker, setStartDatePicker] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endDatePicker, setEndDatePicker] = useState(new Date())
  const [dateKind, setDateKind] = useState(options[0])
  const [productsFilter, setProductsFilter] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [checkboxSelected, setCheckboxSelected] = useState(null)
  const [requestStatusData, setRequestStatusData] = useState(null)
  const [requestStatusKind, setRequestStatusKind] = useState(null)
  const [visible, setVisible] = useState(false)
  const [checked, setChecked] = useState(false)
  const [modal, setModal] = useState(false)

  const navigateToPage = useCallback(
    (path, page) => {
      if (page) setActivePage(page)
      goToPage(path)
    },
    [goToPage, setActivePage],
  )

  const setKind = useCallback(
    (kind) => {
      if (isEqual(dateKind, kind)) return
      setDateKind(kind)
      setIsLoading(true)
    },
    [setDateKind, dateKind, setIsLoading],
  )

  const setProducts = useCallback(
    (products) => {
      const newProducts = products || []
      if (isEqual(selectedProducts, newProducts)) return
      setSelectedProducts(newProducts)
      setIsLoading(true)
    },
    [setSelectedProducts, selectedProducts, setIsLoading],
  )

  const makeDatePickerVisible = useCallback(() => {
    setVisible((prev) => !prev)
  }, [setVisible])

  const setRangeDates = useCallback(
    (ranges) => {
      const start = ranges?.selection.startDate
      const end = ranges?.selection.endDate
      setStartDatePicker(start)
      setEndDatePicker(end)
      setStartDate(start)
      setEndDate((prev) => {
        if (start !== end && prev !== end) {
          setTimeout(makeDatePickerVisible, 300)
          setIsLoading(true)
        }
        return end
      })
    },
    [setStartDate, setEndDate, makeDatePickerVisible, setIsLoading],
  )

  const getProducts = useCallback(async () => {
    try {
      const filters = await getFiltersCheckout()
      const courses = filters?.data?.result?.courses || []

      const productsFiltered = courses
        .filter((item) => item.id)
        .map((item) => {
          return {
            label: `${item.name}-${item.id}`,
            value: item.id,
            active: true,
          }
        })

      setProductsFilter(productsFiltered)
    } catch (error) {
      showToast('Um erro inesperado aconteceu')
    }
  }, [setProductsFilter])

  const getData = useCallback(async () => {
    try {
      const filters = {
        startDate: startDate,
        endDate: endDate,
        productsSelected: selectedProducts ? selectedProducts.map((e) => e.value) : [],
        datekind: dateKind.value,
      }

      const { data: requestsData } = await getSalesFunnelData(filters)

      setRequestStatusData(requestsData)
    } catch (error) {
      logger({ error })
      showToast('Um erro inesperado aconteceu')
    } finally {
      setIsLoading(false)
    }
  }, [startDate, endDate, dateKind, selectedProducts, setIsLoading])

  useUpdate(
    () => {
      getData()
    },
    {
      startDate,
      endDate,
      dateKind,
      selectedProducts,
      requestStatusKind,
    },
  )

  useMount(async () => {
    setIsLoading(true)
    getData()
    getProducts()
  })

  return {
    dateKind,
    setDateKind: setKind,
    startDate: startDatePicker,
    endDate: endDatePicker,
    setRangeDates,
    productsFilter,
    selectedProducts,
    setProductsFilter,
    setSelectedProducts: setProducts,
    requestStatusData,
    requestStatusKind,
    setRequestStatusKind,
    makeDatePickerVisible,
    visible,
    navigateToPage,
    setChecked,
    checked,
    setVisible,
    getProducts,
    modal,
    setModal,
    checkboxSelected,
    setCheckboxSelected,
  }
}
