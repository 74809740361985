import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerText = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 28px;
  }
`
