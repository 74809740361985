import React from 'react'
import { useScreenMeasure } from '~/hooks'
import { theme, Breadcrumbs } from '@provi/provi-components'
import { GridDivider } from '~/globalStyles'

export const HeaderBreadcrumbs = ({ levelsArr = [] }) => {
  const [_, isMobile] = useScreenMeasure()

  return (
    <>
      {!isMobile && <Breadcrumbs color={'black'} currentColor={theme.colors.bluePrimary} levels={levelsArr} />}
      <GridDivider isFromBreadcrumbs />
      {isMobile && <Breadcrumbs color={'black'} currentColor={theme.colors.bluePrimary} levels={levelsArr} />}
    </>
  )
}
