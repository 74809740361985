import React from 'react'
import { InputFieldV3, theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { InputContainer, TextFlex, InputFlex } from '../../styles'

export const GracePeriodDaysInput = ({ isMobile, formik }) => {
  return (
    <InputContainer>
      <TextFlex flexColumn={isMobile}>
        <BaseTypography fontWeight={600} fontSize={isMobile ? '13px' : '16px'}>
          Prazo para solicitação de reembolso integral
        </BaseTypography>
        <BaseTypography fontSize={isMobile ? '11px' : '13px'} lineHeight={'20px'}>
          (opcional)
        </BaseTypography>
      </TextFlex>
      <BaseTypography fontSize={isMobile ? '11px' : '13px'} color={theme.colors.greyPrimary}>
        Caso deseje especificar as condições associadas a essa turma, basta informar o prazo aqui.{' '}
      </BaseTypography>

      <InputFlex>
        <InputFieldV3
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          width="260px"
          type="number"
          name="deadlineSolicitation"
          value={formik.values.deadlineSolicitation}
          hasError={formik.errors.deadlineSolicitation && formik.touched.deadlineSolicitation}
        />
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={'20px'}>
          Dias
        </BaseTypography>
      </InputFlex>
      {formik.errors.deadlineSolicitation && formik.touched.deadlineSolicitation && (
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={'20px'} color="#B30142">
          {formik.errors.deadlineSolicitation}
        </BaseTypography>
      )}
    </InputContainer>
  )
}
