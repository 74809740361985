import React from 'react'

export const CoursesContext = React.createContext()

export const CoursesProvider = (props) => {
  const { children } = props || {}

  const [courseClassCopy, setCourseClassCopy] = React.useState(null)
  const [showModalAfterPost, setShowModalAfterPost] = React.useState(false)

  const providerValue = { courseClassCopy, setCourseClassCopy, showModalAfterPost, setShowModalAfterPost }

  return <CoursesContext.Provider value={providerValue}>{children}</CoursesContext.Provider>
}
