import React from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { theme, InputFieldV3 } from '@provi/provi-components'
import { Button } from '~/components/v3'
import { ArrowRight } from '~/assets/svgs/v2'
import {
  MainHeader,
  BlackHeaders,
  ExplanationBox,
  EachRadioBtn,
  UniquePriceSub,
  PaymentMethodSub,
  StepsSub,
  StepsAndPaymentMethodSub,
  ErrorMessage,
} from './components'
import { TimeLine } from '../../components/TimeLine'
import { useAddTimeLine } from './hooks'
import { BaseTypography } from '~/globalStyles'
import { VerticalContainers, FlexContainers } from './styles'
import { CoursesBreadCrumbs } from '~/screens/courses/components'

export const AddTimeLine = () => {
  const { classroomId, courseId } = useParams()

  const {
    isMobile,
    formValues,
    setFormValues,
    handleInputChange,
    formErrors,
    validateForm,
    handleBlur,
    handleRadioChange,
    discountPercentage,
    priceWithDiscount,
    showTimeLine,
    setShowTimeLine,
    postAddTimeLine,
    minimumEndAtDate,
    minimumCanCheckoutUntilDate,
    smallFlexGaps,
    flexDirs,
    btnFontSize,
    btnLineHeight,
  } = useAddTimeLine({ classroomId, courseId })

  if (showTimeLine) {
    return (
      <VerticalContainers gap={isMobile ? '1rem' : '3rem'}>
        <TimeLine formObject={formValues} blueTitle={'Resumo da estratégia de vendas'} />
        <FlexContainers justifyContent={'space-between'} alignItems={'center'} flexDirection={flexDirs}>
          <BaseTypography
            onClick={() => setShowTimeLine()}
            fontSize={btnFontSize}
            lineHeight={btnLineHeight}
            color={theme.colors.bluePrimary}
            fontWeight={600}
            cursor={'pointer'}
          >
            Editar
          </BaseTypography>
          <Button
            text="Salvar"
            mobileWidth={'100%'}
            icon={<ArrowRight color={'white'} />}
            onClick={() => postAddTimeLine(formValues)}
          />
        </FlexContainers>
      </VerticalContainers>
    )
  }

  return (
    <>
      <CoursesBreadCrumbs breadCrumbsArr={[<span key={'Nova Estratégia'}>Nova Estratégia</span>]} />
      <VerticalContainers gap={isMobile ? '1rem' : '2.5rem'}>
        <MainHeader isMobile={isMobile} />
        <BlackHeaders
          isMobile={isMobile}
          title={'Período de vendas'}
          explanation={'Selecione as datas de abertura e fechamento das vendas do seu curso.'}
        />
        <VerticalContainers gap={smallFlexGaps} hasRevertWidth>
          <InputFieldV3
            id={'launchStartsAt'}
            name={'launchStartsAt'}
            label="Início das vendas"
            type="datetime-local"
            min={format(new Date(), "yyyy-MM-dd'T'00:00")}
            value={formValues.launchStartsAt}
            onChange={handleInputChange}
            onBlur={handleBlur}
            hasError={formErrors.launchStartsAt.hasError}
          />
          <ErrorMessage
            isMobile={isMobile}
            errMessage={formErrors.launchStartsAt.errMessage}
            showCondition={formErrors.launchStartsAt.hasError}
          />
        </VerticalContainers>
        <VerticalContainers gap={smallFlexGaps}>
          <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'} hasRevertWidth>
            <InputFieldV3
              id={'launchEndsAt'}
              name={'launchEndsAt'}
              label="Fim das vendas"
              type="datetime-local"
              value={formValues.launchEndsAt}
              onChange={handleInputChange}
              onBlur={handleBlur}
              min={minimumEndAtDate}
              hasError={formErrors.launchEndsAt.hasError}
            />
            <ExplanationBox isMobile={isMobile} explanation={'Após o fim do período, o aluno não poderá solicitar a compra.'} />
          </FlexContainers>
          <ErrorMessage
            isMobile={isMobile}
            errMessage={formErrors.launchEndsAt.errMessage}
            showCondition={formErrors.launchEndsAt.hasError}
          />
        </VerticalContainers>
        <BlackHeaders
          isMobile={isMobile}
          title={'Limite para efetivação'}
          explanation={'Determine a data limite para efetivação do pagamento dos alunos que fecharam o carrinho.'}
        />
        <VerticalContainers gap={smallFlexGaps}>
          <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'} hasRevertWidth>
            <InputFieldV3
              id={'canCheckoutUntil'}
              name={'canCheckoutUntil'}
              label="Prazo"
              type="datetime-local"
              value={formValues.canCheckoutUntil}
              onChange={handleInputChange}
              onBlur={handleBlur}
              min={minimumCanCheckoutUntilDate}
              hasError={formErrors.canCheckoutUntil.hasError}
            />
            <ExplanationBox
              isMobile={isMobile}
              explanation={'Após o prazo, todos os alunos que não efetuaram o pagamento serão automaticamente cancelados. '}
            />
          </FlexContainers>
          <ErrorMessage
            isMobile={isMobile}
            errMessage={formErrors.canCheckoutUntil.errMessage}
            showCondition={formErrors.canCheckoutUntil.hasError}
          />
        </VerticalContainers>
        <BlackHeaders
          isMobile={isMobile}
          title={'Modelo de precificação'}
          explanation={'Defina o valor do curso no decorrer do período de lançamento.'}
        />
        <EachRadioBtn
          isMobile={isMobile}
          title={'Preço único'}
          explanation={'O preço não sofre variação durante todo o período de vendas.'}
          checked={formValues?.launchPricing?.type === 'uniquePrice'}
          onChange={() => handleRadioChange('uniquePrice')}
        />

        {formValues?.launchPricing?.type === 'uniquePrice' && (
          <UniquePriceSub
            isMobile={isMobile}
            stateProps={{ formValues, setFormValues, discountPercentage, priceWithDiscount }}
          />
        )}

        <EachRadioBtn
          isMobile={isMobile}
          title={'Método de pagamento'}
          explanation={'O preço varia de acordo com o método de pagamento.'}
          checked={formValues?.launchPricing?.type === 'paymentMethod'}
          onChange={() => handleRadioChange('paymentMethod')}
        />
        {formValues?.launchPricing?.type === 'paymentMethod' && (
          <PaymentMethodSub
            isMobile={isMobile}
            stateProps={{ formValues, setFormValues, discountPercentage, priceWithDiscount }}
          />
        )}

        <EachRadioBtn
          isMobile={isMobile}
          title={'Data da compra'}
          explanation={'O preço varia de acordo com lotes de vendas com duração específica.'}
          checked={formValues?.launchPricing?.type === 'launchSteps'}
          onChange={() => handleRadioChange('launchSteps')}
        />
        {formValues?.launchPricing?.type === 'launchSteps' && (
          <StepsSub
            isMobile={isMobile}
            stateProps={{
              formValues,
              setFormValues,
              discountPercentage,
              priceWithDiscount,
              minimumEndAtDate,
              minimumCanCheckoutUntilDate,
            }}
          />
        )}

        <EachRadioBtn
          isMobile={isMobile}
          title={'Método de pagamento e data da compra'}
          explanation={'O preço varia de acordo com o método de pagamento e com lotes de vendas com duração específica.'}
          checked={formValues?.launchPricing?.type === 'launchStepsAndPaymentMethod'}
          onChange={() => handleRadioChange('launchStepsAndPaymentMethod')}
          disabled={true}
        />
        {formValues?.launchPricing?.type === 'launchStepsAndPaymentMethod' && (
          <StepsAndPaymentMethodSub
            isMobile={isMobile}
            stateProps={{ formValues, setFormValues, discountPercentage, priceWithDiscount }}
          />
        )}

        <ErrorMessage
          isMobile={isMobile}
          errMessage={formErrors.launchPricing.errMessage}
          showCondition={formErrors.launchPricing.hasError}
        />

        <FlexContainers justifyContent={'space-between'} alignItems={'center'} flexDirection={flexDirs}>
          <BaseTypography
            fontSize={btnFontSize}
            lineHeight={btnLineHeight}
            color={theme.colors.bluePrimary}
            fontWeight={600}
            cursor={'pointer'}
          >
            Cancelar
          </BaseTypography>
          <Button text="Salvar" mobileWidth={'100%'} icon={<ArrowRight color={'white'} />} onClick={() => validateForm()} />
        </FlexContainers>
      </VerticalContainers>
    </>
  )
}
