import React from 'react'
import { theme } from '@provi/provi-components'
import { Button } from '~/components/v2/Button'
import { GridDivider, BaseTypography, BaseMediumText, BaseFlexContainer } from '~/globalStyles'
import { FlexColumn, ActionsGrid, ButtonWrapper, ModalContainer, StyledPortalModal } from '../styles'
import { CloseIcon } from '~/assets/svgs/v2'
import { useScreenMeasure } from '~/hooks'

export const TitleAndText = ({ title, text }) => {
  return (
    <FlexColumn>
      <BaseTypography color={theme.colors.blue900} fontWeight={600}>
        {title}
      </BaseTypography>
      <BaseTypography color={'#414141'}>{text}</BaseTypography>
    </FlexColumn>
  )
}

export const CancellationReversalModal = ({ modalRef, closeModal, signalCancellationReversal }) => {
  const [, isMobile] = useScreenMeasure()

  return (
    <StyledPortalModal ref={modalRef}>
      <ModalContainer>
        <BaseFlexContainer justifyContent={'space-between'} alignItems={'center'}>
          <BaseTypography
            fontWeight={700}
            color={theme.colors.blue900}
            fontSize={!isMobile && '20px'}
            lineHeight={!isMobile && '28px'}
          >
            Reverter cancelamento
          </BaseTypography>
          <CloseIcon onClick={closeModal} />
        </BaseFlexContainer>

        <BaseMediumText color={'#414141'}>
          Sinalizando a reversão, você concorda que entrou em contato com o aluno e ele desistiu do cancelamento.
        </BaseMediumText>

        <BaseFlexContainer flexDirection={isMobile && 'column'} justifyContent={'center'} alignItems={'center'} gap={'2rem'}>
          <ButtonWrapper>
            <Button onClick={closeModal} text="Voltar" secondary />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={signalCancellationReversal} text="Sinalizar reversão" />
          </ButtonWrapper>
        </BaseFlexContainer>
      </ModalContainer>
    </StyledPortalModal>
  )
}

export const ActionsContainer = ({ openModal }) => {
  return (
    <ActionsGrid>
      <GridDivider />
      <TitleAndText
        title={'Reverter cancelamento'}
        text={
          <>
            Se você entrou em contato com o aluno e ele concordou em desistir do cancelamento, sinalize a reversão.
            <br />
            <br />
            Importante: o pedido de cancelamento só será revertido depois da confirmação do aluno no Painel do aluno.
          </>
        }
      />
      <ButtonWrapper>
        <Button onClick={openModal} text="Sinalizar reversão do cancelamento" />
      </ButtonWrapper>
    </ActionsGrid>
  )
}
