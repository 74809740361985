import styled, { css } from 'styled-components'
import { ListItem } from '@material-ui/core'
import { DrawerLogo } from '~/assets/svgs/drawer-logo'

export const CustomDrawerLogo = styled(DrawerLogo)`
  display: ${({ open }) => (open ? 'inherit' : 'none')};
  transition: display 0.4s;
`
export const NestedListItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 31px;
`

export const NestedListItemText = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 23px;
  color: ${({ active }) => (active ? '#000B3C' : '#414141')};
  font-family: ${({ active }) => (active ? 'Montserrat-Bold' : 'Montserrat-SemiBold')};
  cursor: pointer;
`
export const PartnerPhoto = styled.img`
  max-width: 35px;
  max-height: 35px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;

  ${({ isCentered }) =>
    isCentered &&
    css`
      margin: 0 auto;
    `}
`

export const DrawerTitle = styled(ListItem)`
  font-size: 12px;
  line-height: 18px;
  font-family: Montserrat-Regular;
  color: #414141;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  && {
    align-items: start;
  }
`

export const TabItemTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-family: Montserrat-Bold;
  cursor: pointer;
  color: ${({ titleColor }) => {
    const possible = {
      red: '#DE0C4B',
      active: '#000B3C',
    }

    return possible.hasOwnProperty(titleColor) ? possible[titleColor] : '#414141' //eslint-disable-line
  }};

  svg {
    margin-right: 8px;
  }
`

export const TabItemHolder = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  height: 48px;
  background: ${({ active }) => (active ? 'rgba(92, 181, 254, 0.5)' : '')};

  cursor: pointer;

  &:hover {
    background: rgba(92, 181, 254, 0.5);
  }
`

export const TabDropdownHolder = styled(TabItemHolder)`
  justify-content: space-between;
`

export const NestedListItemIcon = styled.div`
  background: ${({ active }) => (active ? '#de0c4b' : '#E0E0E0')};
  width: ${({ active }) => (active ? '4px' : '1px')};
  height: 48px;
`

export const Divider = styled.div`
  height: ${({ customHeight }) => (customHeight ? customHeight : '32px')};
`

export const DrawerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 8px;
`

export const StyledTitle = styled.span`
  font-size: 16;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #414141;
  cursor: pointer;

  ${({ isCenteredText }) =>
    isCenteredText &&
    css`
      margin: 0 auto;
    `}
`

export const MainContainer = styled.main``

export const MainDiv = styled.div``

export const IconListItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
`

export const ActivatableIconBackground = styled.div`
  background: ${({ active }) => (active ? 'rgba(92, 181, 254, 0.35)' : '')};
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: relative;
  left: 5px;
`

export const AbsoluteSvgHolder = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;
  left: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f1f1f1;
  }

  cursor: pointer;
`

export const SvgWrapper = styled.div`
  width: 90px;
`
