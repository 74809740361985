import React from 'react'
import { StyledSwitch, SwitchContainer, SwitchWrapper, SwitchThumb, Labels } from './styles'

export const CustomSwitch = ({ leftLabel, rightLabel, switchState, setSwitchState }) => {
  return (
    <SwitchContainer>
      {leftLabel && <Labels isBold={!switchState}>{leftLabel}</Labels>}
      <SwitchWrapper>
        <StyledSwitch type="checkbox" switchState={switchState} onChange={(event) => setSwitchState(event.target.checked)} />
        <SwitchThumb />
      </SwitchWrapper>
      {rightLabel && <Labels isBold={switchState}>{rightLabel}</Labels>}
    </SwitchContainer>
  )
}
