/* eslint-disable react/prop-types */
import React from 'react'
import { theme } from '@provi/provi-components'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'

import { Section } from './styles'
import { useLoanComments } from './hooks'

export const LoanOptionCommentModal = (props) => {
  const { handleModal, show, loanCommentsData, setIsLoading } = props

  const { comment, expectedTed, amountTed } = useLoanComments(loanCommentsData, setIsLoading)

  return (
    <>
      {show && expectedTed && amountTed && (
        <ModalComponent isWithoutHeight width="500px" radius={5} onDismiss={() => handleModal('loanOptionComment')} footer={''}>
          <Section>
            <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900}>
              Valor esperado da TED: {expectedTed}
            </Text>
            <Text size="20px" lineHeight="30px" bold color={theme.colors.blue900}>
              Valor realizado da TED: {amountTed}
            </Text>
            {comment && (
              <Text
                size="20px"
                lineHeight="30px"
                bold
                color={theme.colors.blue900}
                mTop={'30px'}
                style={{ wordBreak: 'break-all' }}
              >
                {comment}
              </Text>
            )}
          </Section>
        </ModalComponent>
      )}
    </>
  )
}
