import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 30px;
  border: 0;
  cursor: pointer;
  padding: 15px 25px;
  background: ${theme.colors.bluePrimary};
  color: #fff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.8);
  }

  @media screen and (max-width: 800px) {
    position: inherit;

    width: 100%;
    margin-top: 100px;
  }
`
