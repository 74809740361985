import React from 'react'
import { TableComponent } from '@provi/provi-components'
import { mobileHeaders, headers } from './helpers/tableHelpers'
import { useAccountHistoryTable } from './hooks'

export const AccountHistoryTable = () => {
  const { bankHistory, rows, customPagination, handleNextButton, handleBackButton } = useAccountHistoryTable()

  return (
    <>
      {bankHistory && (
        <TableComponent
          headers={headers}
          rows={rows}
          mobileHeaders={mobileHeaders}
          customPagination={customPagination}
          handleBackButtonClick={handleBackButton}
          handleNextButtonClick={handleNextButton}
        />
      )}
    </>
  )
}
