import React from 'react'

import { EachDetailBox, DetailText } from '../styles'

export const EachDetail = ({ title, value }) => {
  return (
    <EachDetailBox>
      <DetailText isBold>{title}</DetailText>
      <DetailText>{value}</DetailText>
    </EachDetailBox>
  )
}
