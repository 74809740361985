import { useState, useMemo } from 'react'
import { DateRange, DateRangePicker, defaultStaticRanges, defaultInputRanges } from 'react-date-range'
import { translateInputDateRangePicker, translateStaticDateRangePicker } from '~/screens/dashboard/helpers'

export const useDashboardDatePicker = () => {
  const [showCalendar, setShowCalendar] = useState(false)

  const handleCalendar = () => {
    setShowCalendar(!showCalendar)
  }
  const translatedStaticRanges = useMemo(
    () =>
      defaultStaticRanges.map((eachStaticRangeObj) => {
        return { ...eachStaticRangeObj, label: translateStaticDateRangePicker(eachStaticRangeObj.label) }
      }),
    [],
  )

  const translatedInputRanges = useMemo(
    () =>
      defaultInputRanges.map((eachInputRangeObj) => {
        return { ...eachInputRangeObj, label: translateInputDateRangePicker(eachInputRangeObj.label) }
      }),
    [],
  )

  return {
    DateRange,
    DateRangePicker,
    showCalendar,
    handleCalendar,
    translatedStaticRanges,
    translatedInputRanges,
  }
}
