import styled, { css } from 'styled-components'
import { Button } from '@material-ui/core'
import { theme } from '@provi/provi-components'

const handleColors = (color, disabled) => {
  if (disabled) {
    switch (color) {
      case 'primary':
        return css`
          &.MuiButton-contained.Mui-disabled {
            background: ${theme.colors.greyLight};
          }
          .MuiButton-label {
            color: ${theme.colors.greyPrimary};
          }
        `
      default:
        return css`
          &.MuiButton-contained.Mui-disabled {
            background: ${theme.colors.white100};
          }
          .MuiButton-label {
            color: ${theme.colors.greyPrimary};
          }
        `
    }
  }

  switch (color) {
    case 'primary':
      return css`
        background-color: ${theme.colors.bluePrimary};
        .MuiButton-label {
          color: ${theme.colors.white100};
        }
        .MuiTouchRipple-ripple {
          color: ${theme.colors.bluePrimaryDark};
        }
        :hover {
          background-color: ${theme.colors.blueSecundary};
        }
        :focus {
          background-color: ${theme.colors.blueSecundary};
        }
      `
    case 'light':
      return css`
        background: ${theme.colors.bluePrimaryLight};
        .MuiTouchRipple-ripple {
          color: ${theme.colors.blueSecundaryDark};
        }
        :hover {
          background: ${theme.colors.blueSecundaryLight};
        }
        :focus {
          background: ${theme.colors.blueSecundaryLight};
        }
      `
    default:
      return css`
        background: ${theme.colors.white100};
        .MuiTouchRipple-ripple {
          color: ${theme.colors.blueSecondaryLight};
        }
        :hover {
          background: ${theme.colors.bluePrimaryLight};
        }
        :focus {
          background: ${theme.colors.bluePrimaryLight};
        }
      `
  }
}

export const ButtonContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  width: fit-content;
  height: 48px;
  border-radius: 4px;
  min-width: ${({ buttonWidth }) => buttonWidth};
  height: ${({ buttonHeight }) => buttonHeight};
  ${theme.breakpoints.down('md')} {
    min-width: ${({ mobileWidth }) => mobileWidth};
  }
  .MuiButton-root {
    ${({ colorType, disabled }) => handleColors(colorType, disabled)}
  }
  .MuiButton-label {
    color: ${theme.colors.bluePrimaryDark};
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-transform: none;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }
  :focus,
  :focus-within {
    outline: 2px auto ${theme.colors.bluePrimary};
    outline-offset: 2px;
  }
`

export const ButtonWrapper = styled(Button)`
  border-radius: 4px;
  width: 100%;
  height: 100%;
`
