import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface ISelectContainerProps {
  marginBottom?: number
  labelColor?: string
  explainerColor?: string
}

export const SelectContainer = styled.div<ISelectContainerProps>`
  ${({ marginBottom = 0, labelColor = '#000', explainerColor = '#575757' }) =>
    css`
      margin-bottom: ${marginBottom}px;

      .select-label {
        color: ${labelColor};
      }

      .select-explainer {
        color: ${explainerColor};
      }
    `}

  div div {
    z-index: 1;
  }
`

export const CalculatorContainer = styled.div`
  width: 100%;
  margin-top: 19px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg path,
  span {
    transition: all ease 0.25s;
  }

  :hover {
    span {
      color: ${theme.colors.blueSecundary};
    }

    svg path {
      stroke: ${theme.colors.blueSecundary};
    }
  }
`

export const CalculatorText = styled.span`
  width: 100%;
  margin-left: 8px;
  font-size: 14px;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-decoration-line: underline;
`
