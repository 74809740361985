import React from 'react'
import { useFinancialFlowDashboard } from './hooks'
import { PageWrapper, Container, ContainerSelector } from './styles'
import { Header, HeaderTitle, HeaderContent } from '../global'
import { Receivables } from './components'
import { ReceiptsChart } from './components/ReceiptsChart'
import Selector from 'react-select'
import { Menu, Option, MultiValue, animatedComponents, colourStyles } from '~/components/CheckboxSelector/Options'

export const FinancialFlowDashboard = () => {
  const {
    isMobile,
    drawerWidth,
    productsFilter,
    selectedProducts,
    setSelectedProducts,
    onFilter,
    filters,
  } = useFinancialFlowDashboard()

  return (
    <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      <Container>
        <Header>
          <HeaderContent>
            <HeaderTitle>Fluxo financeiro</HeaderTitle>
            <ContainerSelector>
              <Selector
                placeholder="Filtrar por cursos"
                name="products"
                options={productsFilter}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={colourStyles}
                components={{
                  Menu,
                  Option,
                  MultiValue,
                  animatedComponents,
                }}
                onChange={setSelectedProducts}
                allowSelectAll={true}
                value={selectedProducts}
                onFilter={onFilter}
                onClear={() => setSelectedProducts(null)}
              />
            </ContainerSelector>
          </HeaderContent>
        </Header>
        <ReceiptsChart filters={filters} isMobileWitdh={isMobile} drawerMenuWidth={drawerWidth} />
        <Receivables filters={filters} />
      </Container>
    </PageWrapper>
  )
}
