import React from 'react'

export const LiveIcon = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '24'}
    height={size || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14C6.36599 13.3438 5.86301 12.5644 5.51983 11.7065C5.17664 10.8485 5 9.92881 5 9C5 8.07119 5.17664 7.1515 5.51983 6.29354C5.86301 5.43558 6.36599 4.65621 7 4M17 4C17.634 4.65621 18.137 5.43558 18.4802 6.29354C18.8234 7.1515 19 8.07119 19 9C19 9.92881 18.8234 10.8485 18.4802 11.7065C18.137 12.5644 17.634 13.3438 17 14M4 16C3.04899 15.0157 2.29451 13.8466 1.77974 12.5597C1.26497 11.2728 1 9.89321 1 8.5C1 7.10679 1.26497 5.72724 1.77974 4.44031C2.29451 3.15338 3.04899 1.98431 4 1M20 1C20.951 1.98431 21.7055 3.15338 22.2203 4.44031C22.735 5.72724 23 7.10679 23 8.5C23 9.89321 22.735 11.2728 22.2203 12.5597C21.7055 13.8466 20.951 15.0157 20 16M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z"
      stroke={color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
