import { useContext, useState, useEffect, useMemo } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { getCridDetails } from '~/services/api'
import { useTable, useExpanded, useColumnOrder } from 'react-table'
import { detailsTableColumns } from './detailsTableConfig'

export const useDetails = (Crid) => {
  const { isMobile, setIsLoading, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const [cridDetails, setCridDetails] = useState({})

  const getDetails = async (cridParams) => {
    setIsLoading(true)
    try {
      const { data } = await getCridDetails(cridParams)
      if (!data.error) {
        setCridDetails(data)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDetails(Crid)
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: useMemo(() => detailsTableColumns, []) || [],
      data: useMemo(() => cridDetails?.installments, [cridDetails]) || [],
      autoResetExpanded: false,
      defaultColumn: {
        isMobileProps: isMobile,
      },
    },
    useExpanded,
    useColumnOrder,
  )

  useEffect(() => {
    if (isMobile) {
      setHiddenColumns([
        'paidValue',
        'proviComission',
        'receivedValue',
        'dueDate',
        'userPaidDate',
        'delayedDays',
        'agreementDate',
      ])
      setColumnOrder([
        'status',
        'count',
        'originalValue',
        'paidValue',
        'proviComission',
        'receivedValue',
        'dueDate',
        'userPaidDate',
        'delayedDays',
        'agreementDate',
      ])
    }
    if (!isMobile) {
      setHiddenColumns(['expanderIcon'])
      setColumnOrder([
        'count',
        'status',
        'originalValue',
        'paidValue',
        'proviComission',
        'receivedValue',
        'dueDate',
        'userPaidDate',
        'delayedDays',
        'agreementDate',
      ])
    }
  }, [isMobile])

  return {
    goToPage,
    getDetails,
    isMobile,
    drawerWidth,
    cridDetails,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  }
}
