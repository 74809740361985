import * as Yup from 'yup'
import { ValidationMessages } from '~/enums'
import { validateFullName, validatePhone } from '~/utils/validators'

export const initialValues = {
  phone: '',
  name: '',
  jobRole: '',
}

export const validationSchema = Yup.object({
  phone: Yup.string().required(ValidationMessages.requiredField).test('phone', ValidationMessages.phone, validatePhone),
  name: Yup.string().required(ValidationMessages.requiredField).test('name', ValidationMessages.fullName, validateFullName),
  jobRole: Yup.string().required(ValidationMessages.requiredField),
})
