import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import { initialValues, ValidationSchema } from './form'
import {
  ButtonWrapper,
  TextInputWrapper,
  RegularInput,
  FormTitle,
  FormWrapper,
  InputError,
  MaskedInput,
  Container,
  LinkText,
  Wrapper,
  pink,
  LabelTitle,
} from '../styles'
import { Button, Modal, showToast } from '@provi/provi-components'
import { masks } from '~/enums'
import { useServicesNFE } from './hooks'
import { TemplateContext } from '~/components/Template/context'
import { SimpleTable } from '~/components/table'

/** @typedef {object} IProps */

/** @type {React.FC<IProps>}*/
export const BillingInfo = () => {
  const [showModal, onChangeShowModal] = useState(false)
  const { setIsLoading } = useContext(TemplateContext)

  const { values, setFieldValue, handleSubmit, setFieldTouched, errors, resetForm, touched, validateForm } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (formFields) => sendNewService(formFields),
  })

  const {
    sendNewService,
    servicesRegistered,
    setServiceDefault,
    servicesCourses,
    changeServiceTrigger,
    showServicesCells,
    setShowServicesCells,
    showCoursesCells,
    setShowCoursesCells,
    setServicesRegistered,
    setServicesCourses,
  } = useServicesNFE({
    showToast,
    setIsLoading,
    resetForm,
    onChangeShowModal,
  })
  return (
    <Container>
      <Wrapper>
        <LabelTitle text="Serviços cadastrados" />
      </Wrapper>
      <Wrapper setMarginTop={true}>
        {servicesRegistered.length > 0 && (
          <SimpleTable
            setRows={setServicesRegistered}
            rows={servicesRegistered}
            showCells={showServicesCells}
            setShowCells={setShowServicesCells}
            buttonClick={setServiceDefault}
            origin="nfse"
          />
        )}
      </Wrapper>
      <Wrapper>
        <LinkText color={pink} text="Cadastrar novo serviço" onClick={() => onChangeShowModal(!showModal)} />
      </Wrapper>
      <Wrapper>
        <LabelTitle text="Meus Cursos" />
      </Wrapper>
      <Wrapper setMarginTop={true}>
        {servicesCourses.length > 0 && (
          <SimpleTable
            setRows={setServicesCourses}
            rows={servicesCourses}
            showCells={showCoursesCells}
            setShowCells={setShowCoursesCells}
            selectChange={changeServiceTrigger}
            origin="nfse"
          />
        )}
      </Wrapper>

      {showModal && (
        <Modal onDismiss={() => onChangeShowModal(!showModal)}>
          <FormWrapper onSubmit={handleSubmit}>
            <FormTitle>Preencha abaixo com os dados do seu serviço: </FormTitle>
            <TextInputWrapper>
              <p>Código de Serviço*</p>
              <span>(também conhecido como código do item na lista de serviço, geralmente segue a lei complementar 116)*</span>
              <RegularInput
                type="number"
                id="itemListServiceCode"
                value={values.itemListServiceCode}
                onChange={(e) => setFieldValue('itemListServiceCode', e.target.value)}
                placeholder="Digite aqui (ex.: 1.01)"
                onBlur={() => {
                  setFieldTouched('itemListServiceCode')
                  validateForm()
                }}
              />
              {errors.itemListServiceCode && touched.itemListServiceCode && (
                <InputError>{errors.itemListServiceCode}</InputError>
              )}
            </TextInputWrapper>
            <TextInputWrapper>
              <p>Código de tributação no município</p>
              <span>Consulte o seu contador sobre a obrigatoriedade deste campo</span>
              <RegularInput
                type="number"
                value={values.taxCode}
                id="taxCode"
                onChange={(e) => setFieldValue('taxCode', e.target.value)}
                placeholder="Digite Aqui"
                onBlur={() => {
                  setFieldTouched('taxCode')
                  validateForm()
                }}
              />
              {errors.taxCode && touched.taxCode && <InputError>{errors.taxCode}</InputError>}
            </TextInputWrapper>
            <TextInputWrapper>
              <p>CNAE*</p>
              <MaskedInput
                mask={masks.cnae}
                value={values.cnae}
                id="cnae"
                onChange={(e) => setFieldValue('cnae', e.target.value)}
                placeholder="Digite aqui (ex.: 1234-5/67)"
                onBlur={() => {
                  setFieldTouched('cnae')
                  validateForm()
                }}
              />
              {errors.cnae && touched.cnae && <InputError>{errors.cnae}</InputError>}
            </TextInputWrapper>
            <TextInputWrapper>
              <p>Alíquota do ISS</p>
              <RegularInput
                id="issAliquota"
                type="number"
                value={values.issAliquota}
                onChange={(e) => setFieldValue('issAliquota', e.target.value)}
                placeholder="Digite aqui (ex.: 1.01)"
                onBlur={() => {
                  setFieldTouched('issAliquota')
                  validateForm()
                }}
              />
              {errors.issAliquota && touched.issAliquota && <InputError>{errors.issAliquota}</InputError>}
            </TextInputWrapper>
            <ButtonWrapper>
              <Button text="Salvar alterações" icon onClick={handleSubmit} />
            </ButtonWrapper>
          </FormWrapper>
        </Modal>
      )}
    </Container>
  )
}
