import React, { useState, useContext } from 'react'
import { Container, ListIntegrations, ListIntegrationsItem } from './styles'
import { IntegrationsContext } from '../../context'

import { MemberKitPng } from '~/assets/svgs/index'

function IntegrationsHome() {
  const { setFlowState } = useContext(IntegrationsContext)
  // ** IS GOING TO BE USED SOON **
  // const [trackingTags] = useState([
  //   { title: 'Google Ads', image_svg: <GoogleSvg /> },
  //   { title: 'Facebook', image_svg: <FacebookSvg /> },
  // ])
  const [coursesHousting] = useState([
    {
      title: 'MemberKit',
      image_svg: <MemberKitPng />,
    },
    // ** IS GOING TO BE USED SOON **
    // {
    //   title: 'Eduzz',
    //   image_svg: <EduzzSvg />,
    // },
  ])

  return (
    <Container>
      {/* IS GOING TO BE USED SOON */}
      {/* {trackingTags && (
        <>
          <h1>Tags de rastreamento</h1>

          <ListIntegrations className="tags">
            {trackingTags.map((tag, index) => (
              <ListIntegrationsItem className="tags__item" key={index}>
                <h3>{tag.title}</h3>
                <div>{tag.image_svg}</div>
              </ListIntegrationsItem>
            ))}
          </ListIntegrations>
        </>
      )} */}

      {coursesHousting && (
        <>
          <h1>Hospedagem de curso</h1>

          <ListIntegrations className="courses">
            {coursesHousting.map((course, index) => (
              <ListIntegrationsItem
                className="courses__item"
                isMemberKit={course.title === 'MemberKit'}
                onClick={() => course.title === 'MemberKit' && setFlowState('Memberkit')}
                key={index}
              >
                <h3>{course.title}</h3>
                <div>{course.image_svg}</div>
              </ListIntegrationsItem>
            ))}
          </ListIntegrations>
        </>
      )}
    </Container>
  )
}

export { IntegrationsHome }
