import { useState, useEffect } from 'react'

import { formatThousand, unMask } from '~/utils'
import { getPartnerPreferences, getReceiptsV4 } from '~/services/api'

import { setUrlQueryFilters } from '~/helpers/tables/setQueryFilters'
import { getQueryFilters } from '~/helpers/tables/getQueryFilters'

import { getHeaderTableValues, formatResultTable, formatCellsWithFilterAndUrl } from './helpers'

/**
 * @name useFinancialControl
 * @param {(loading: boolean) => void} setIsLoading
 * @param {boolean} isMobile
 * @param {(string) => void} goToPage
 * @param {boolean} isMobile
 */
export const useFinancialControl = (setIsLoading, isMobile, goToPage) => {
  const INITIAL_STATE = getHeaderTableValues({ isMobile })

  // Modals
  const [filterModal, setFilterModal] = useState(false)
  const [exportsModal, setExportsModal] = useState(false)
  const [productsModal, setProductsModal] = useState(false)
  const [nextPaymentModal, setNextPaymentModal] = useState(false)
  const [helperModal, setHelperModal] = useState(false)
  const [installmentsModal, setInstallmentsModal] = useState(false)
  const [installmentsValueModal, setInstallmentsValueModal] = useState(false)
  const [loanOptionCommentModal, setLoanOptionCommentModal] = useState(false)

  //Modals data
  const [exportsModalData, setExportsModalData] = useState({})
  const [helperModalData, setHelperModalData] = useState('')
  const [installmentsModalData, setInstallmentsModalData] = useState({})
  const [installmentsValueModalData, setInstallmentsValueModalData] = useState({})
  const [loanOptionCommentData, setLoanOptionCommentData] = useState({})

  // Search
  const [search, setSearch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [activeBox, setActiveBox] = useState('cpf')

  // page
  const [pageIndicator, setPageIndicator] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState({ id: 1, label: '50', value: '50' })
  const [searchOptions, setSearchOptions] = useState({ id: 1, label: 'CPF', value: 'cpf' })
  const [page, setPage] = useState(null)
  const [totalItens, setTotalItens] = useState(null)
  const [totalPages, setTotalPages] = useState(null)

  //Table
  const [rows, setRows] = useState([])
  const [filterStatus, setFilterStatus] = useState({})
  const [warningMessage, setWarningMessage] = useState('')
  const [showCells, setShowCells] = useState(INITIAL_STATE)
  const [preferences, setPreferences] = useState(null)

  //course
  const [selectedByUserCourses, setSelectedByUserCourses] = useState([])
  const [ProductData, setProductData] = useState({})
  const [nextPaymentData, setNextPaymentData] = useState({})

  const handleModal = (option, data) => {
    switch (option) {
      case 'filters':
        setFilterModal(!filterModal)
        break
      case 'csv':
        setExportsModal(!exportsModal)
        if (data) setExportsModalData(data)
        break
      case 'product':
        setProductsModal(!productsModal)
        break
      case 'nextPayment':
        setNextPaymentModal(!nextPaymentModal)
        break
      case 'helper':
        setHelperModal(!helperModal)
        if (data) setHelperModalData(data)
        break
      case 'installments':
        setInstallmentsModal(!installmentsModal)
        if (data) setInstallmentsModalData(data)
        break
      case 'installments-value':
        setInstallmentsValueModal(!installmentsValueModal)
        if (data) setInstallmentsValueModalData(data)
        break
      case 'loanOptionComment':
        setLoanOptionCommentModal(!loanOptionCommentModal)
        if (data) setLoanOptionCommentData(data)
        break
      default:
        break
    }
  }

  const handleActiveBox = (value) => {
    setSearch('')
    setSearchKey('')
    setActiveBox(value)
  }

  const handleSearch = async (e) => {
    if (e.target.value === '') {
      setSearchKey('')
    }

    if (['cpf', 'crid'].includes(activeBox)) {
      setSearch(unMask(e.target.value))
    } else {
      setSearch(e.target.value)
    }
  }

  const handleSearchKey = (e) => {
    if (e.keyCode === 13) setSearchKey(search)
  }

  const handlePageIndicator = () => {
    let indicator
    if (page === 1) {
      indicator = `1 - ${rows.length < itemsPerPage.value ? formatThousand(rows.length) : formatThousand(itemsPerPage.value)}`
    } else if (rows.length == itemsPerPage.value) {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(itemsPerPage.value * page)}`
    } else {
      indicator = `${formatThousand(itemsPerPage.value * (page - 1) + 1)} - ${formatThousand(
        itemsPerPage.value * page - (itemsPerPage.value - rows.length),
      )}`
    }
    setPageIndicator(indicator)
  }

  const getData = async (filters) => {
    try {
      setWarningMessage('')
      setIsLoading(true)
      const response = await getReceiptsV4(search, activeBox, filters, page, itemsPerPage.value)

      try {
        setTotalItens(response.data.paging.totalItems)
        setTotalPages(response.data.paging.totalPages)
      } catch (error) {
        console.log('[catch]', error)
      }
      if (response) {
        if (!response.data.content.length > 0) {
          setWarningMessage('Nenhuma solicitação efetivada encontrada')
        }

        const rowsResponse = await formatResultTable({
          data: response.data.content,
          handleModal,
          setProductData,
          setNextPaymentData,
          goToPage,
        })

        setRows([...rowsResponse])
      }
    } catch (error) {
      console.log('[error 2]', error.message)
      setWarningMessage('Ops, ocorreu um erro. Atualize essa página e entre em contato com a Provi se o erro permanecer.')
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Effects
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryPage = urlParams.get('page')
    const queryFilters = getQueryFilters(urlParams)
    const keysFilters = Object.keys(queryFilters)

    setPage(queryPage ? Number(queryPage) : 1)

    if (queryFilters) {
      setFilterStatus(queryFilters)
    }

    const getPreferences = async () => {
      const response = await getPartnerPreferences('receipts')
      const { preferences: preferencesResponse } = response.data
      setPreferences({ ...preferencesResponse })
    }

    const getFilters = async () => {
      await formatCellsWithFilterAndUrl({ setShowCells, showCells, keysFilters, queryFilters })
    }

    getPreferences()
    getFilters()
  }, [])

  useEffect(() => {
    if (rows.length > 0) handlePageIndicator()
  }, [page, itemsPerPage, rows])

  useEffect(() => {
    setUrlQueryFilters(showCells, page, filterStatus, setSelectedByUserCourses)
  }, [searchKey, page, itemsPerPage, filterStatus])

  useEffect(() => {
    if (page) {
      getData(filterStatus)
    }
  }, [searchKey, page, itemsPerPage, filterStatus])

  /**
   * Returns
   */
  const handlers = {
    handleModal,
    handleActiveBox,
    handleSearch,
    handleSearchKey,
  }

  const states = {
    activeBox,
    search,
    pageIndicator,
    itemsPerPage,
    page,
    totalItens,
    totalPages,
    rows,
    showCells,
    ProductData,
    productsModal,
    exportsModal,
    exportsModalData,
    selectedByUserCourses,
    warningMessage,
    preferences,
    filterStatus,
    filterModal,
    nextPaymentData,
    nextPaymentModal,
    helperModal,
    helperModalData,
    installmentsModal,
    installmentsModalData,
    installmentsValueModal,
    installmentsValueModalData,
    loanOptionCommentModal,
    loanOptionCommentData,
    searchOptions,
  }

  const setStates = {
    setPage,
    setItemsPerPage,
    setPageIndicator,
    setRows,
    setShowCells,
    setProductData,
    setFilterStatus,
    setSearchOptions,
  }

  return { handlers, states, setStates }
}
