import React from 'react'

import { SpanContainer } from './styles'

interface ILengthSpanProps {
  length: number
  lengthLimit: number
}

function LengthSpan({ length, lengthLimit }: ILengthSpanProps) {
  return (
    <SpanContainer className="length-span">
      <span className="length-span__text">{length}</span>/<span className="length-span__text">{lengthLimit}</span>
    </SpanContainer>
  )
}

export { LengthSpan }
