import React from 'react'

export const PrincipiapayLogo = () => (
  <svg width="165" height="27" viewBox="0 0 165 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_672_13486)">
<path d="M5.36745 16.7336H7.57415C9.78085 16.7336 11.4923 15.1258 11.4923 12.9413C11.4923 10.7568 9.78085 8.92555 7.66155 8.92555C5.54224 8.92555 3.83077 10.7928 3.83077 13.0567V26.9784H0V13.0567C0 8.75973 3.16075 5.25586 7.65426 5.25586C12.1478 5.25586 15.3085 8.75973 15.3085 12.9485C15.3085 17.1373 12.206 20.4105 8.03297 20.4105H5.36745V16.7336Z" fill="#006EAB"/>
<path d="M24.5359 5.25586C28.709 5.25586 31.1778 7.98109 31.1778 11.8599V12.5231H27.3471V11.7733C27.3471 10.9442 27.0557 8.9616 24.5359 8.9616C22.8535 8.9616 21.6373 10.1079 21.6373 11.7733V20.4177H17.8065V11.8599C17.8065 8.09645 20.4721 5.25586 24.5359 5.25586Z" fill="#006EAB"/>
<path d="M33.8142 4.01564V1.8888C33.8142 0.828991 34.6809 -0.00732422 35.7587 -0.00732422C36.8366 -0.00732422 37.6741 0.821781 37.6741 1.8888V4.01564H33.8142ZM37.6741 20.4103H33.8434V5.62338H37.6741V20.4103Z" fill="#006EAB"/>
<path d="M47.8482 5.25586C52.0213 5.25586 54.4902 7.98109 54.4902 11.8599V20.4177H50.6594V11.7733C50.6594 10.9442 50.3681 8.9616 47.8482 8.9616C46.1659 8.9616 44.9497 10.1079 44.9497 11.7733V20.4177H41.1189V11.8599C41.1189 8.09645 43.7844 5.25586 47.8482 5.25586Z" fill="#006EAB"/>
<path d="M56.9882 13.0058C56.9882 8.8747 59.8868 5.63037 64.4386 5.63037H67.4245V9.27843H64.759C62.3484 9.27843 60.8117 10.915 60.8117 13.013C60.8117 15.111 62.3484 16.7476 64.759 16.7476H67.4245V20.4245H64.4386C59.8868 20.4245 56.9882 17.1513 56.9882 13.0202V13.0058Z" fill="#006EAB"/>
<path d="M70.2066 4.01564V1.8888C70.2066 0.828991 71.0732 -0.00732422 72.1511 -0.00732422C73.2289 -0.00732422 74.0665 0.821781 74.0665 1.8888V4.01564H70.2066ZM74.0665 20.4103H70.2357V5.62338H74.0665V20.4103Z" fill="#006EAB"/>
<path d="M82.6238 16.7334H84.8305C87.0372 16.7334 88.7487 15.1257 88.7487 12.9412C88.7487 10.7567 87.0372 8.92544 84.9179 8.92544C82.7986 8.92544 81.0871 10.7927 81.0871 13.0565V26.9783H77.2564V13.0565C77.2564 8.75241 80.4171 5.24854 84.9106 5.24854C89.4041 5.24854 92.5649 8.75241 92.5649 12.9412C92.5649 17.13 89.4624 20.4031 85.2893 20.4031H82.6238V16.7262V16.7334Z" fill="#006EAB"/>
<path d="M95.2668 4.01564V1.8888C95.2668 0.828991 96.1335 -0.00732422 97.2113 -0.00732422C98.2892 -0.00732422 99.1267 0.821781 99.1267 1.8888V4.01564H95.2668ZM99.1194 20.4103H95.2887V5.62338H99.1194V20.4103Z" fill="#006EAB"/>
<path d="M109.126 20.4105C104.953 20.4105 101.821 17.0796 101.821 12.9485C101.821 8.81741 104.982 5.25586 109.505 5.25586C114.027 5.25586 117.159 8.75973 117.159 13.0639V20.4105H113.328V13.0639C113.328 10.7928 111.617 8.93276 109.497 8.93276C107.378 8.93276 105.667 10.7424 105.667 12.9485C105.667 15.1547 107.378 16.7408 109.585 16.7408H111.792V20.4177H109.126V20.4105Z" fill="#006EAB"/>
<path d="M122.476 26.9857H120.174V12.7972C120.174 8.93283 123.349 5.78223 127.26 5.78223C131.171 5.78223 134.347 8.92562 134.347 12.7972C134.347 16.6687 131.171 19.8121 127.26 19.8121H124.923V17.5339H127.26C129.897 17.5339 132.038 15.4143 132.038 12.8044C132.038 10.1945 129.897 8.07488 127.26 8.07488C124.624 8.07488 122.483 10.1945 122.483 12.8044V26.9929L122.476 26.9857Z" fill="#5FC3DF"/>
<path d="M145.846 19.7254H143.603C139.721 19.7254 136.568 16.5964 136.568 12.7609C136.568 8.92536 139.729 5.79639 143.603 5.79639C147.477 5.79639 150.638 8.92536 150.638 12.7609V19.6388H148.337V12.7609C148.337 10.1726 146.21 8.07462 143.603 8.07462C140.996 8.07462 138.869 10.1798 138.869 12.7609C138.869 15.3419 140.996 17.4471 143.603 17.4471H145.846V19.7254Z" fill="#5FC3DF"/>
<path d="M159.123 27.0002C156.603 27.0002 154.302 25.3203 153.493 22.8835L155.678 22.1769C156.173 23.6837 157.586 24.7219 159.123 24.7219C159.137 24.7219 159.159 24.7219 159.174 24.7219C159.232 24.7219 159.298 24.7219 159.356 24.7219C161.191 24.621 162.64 23.0781 162.648 21.2036L162.699 5.63086H165L164.949 21.2108C164.942 24.2893 162.538 26.8343 159.48 26.9929C159.385 26.9929 159.298 27.0002 159.21 27.0002C159.181 27.0002 159.152 27.0002 159.123 27.0002Z" fill="#5FC3DF"/>
<path d="M158.875 20.1221C156.326 20.1221 154.891 18.7955 154.127 17.6708C153.442 16.6615 153.085 15.4502 153.085 14.1669L153.107 5.62354H155.409L155.387 14.1669C155.387 14.996 155.612 15.7603 156.035 16.3947C156.719 17.404 157.695 17.8799 159.035 17.8366C162.131 17.7429 162.633 15.4791 162.684 15.2195L164.956 15.5944C164.709 17.1012 163.179 19.9923 159.108 20.1149C159.028 20.1149 158.955 20.1149 158.875 20.1149V20.1221Z" fill="#5FC3DF"/>
</g>
<defs>
<clipPath id="clip0_672_13486">
<rect width="165" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

)



