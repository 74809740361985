import React from 'react'

export const parsePrice = (price: number | null) => {
  if (price === null) {
    return (
      <>
        R$ 0<sup>00</sup>
      </>
    )
  }

  if (isNaN(price)) {
    throw new Error(`Can't parse price: ${price}`)
  }

  const formattedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price)

  const [real, cents] = formattedPrice.split(',')

  return (
    <>
      {real}
      <sup>{cents}</sup>
    </>
  )
}
