import { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

export const useIntegrations = () => {
  const { isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  return {
    isMobile,
    drawerWidth,
  }
}
