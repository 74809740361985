import { formatStrValue, roundValue, unMask } from 'utils'

export const buildCalcConfigObject = ({
  amountInCents,
  inputedValue,
  financingCondition,
  coursesIds,
  classesIds,
  shouldCalculateByCourseAndClassId = false,
}) => {
  const amountInCentsFormatted = Number(amountInCents)
  const inputedValueFormatted = formatStrValue(unMask(inputedValue.toString()))

  const discountInCents = roundValue(amountInCentsFormatted - inputedValueFormatted) || 0

  const shouldCalculateByCourse = shouldCalculateByCourseAndClassId || !financingCondition?.value

  const objectCalcConfig = {
    amountInCents: amountInCentsFormatted,
    discountInCents,
    maxInstallmentsToApply: shouldCalculateByCourse ? 0 : financingCondition?.value,
  }

  if (shouldCalculateByCourse) {
    objectCalcConfig.coursesIds = coursesIds
    objectCalcConfig.classesIds = classesIds
  }

  return objectCalcConfig
}
