import React, { useCallback, useState, useContext, createContext } from 'react'
import { getPartnerStatus } from '~/services/api'
import '~/typings'

const context = createContext({})

/** @type {React.FC} */
export const PartnerProvider = ({ children }) => {
  const [partnerStatuses, setPartnerStatuses] = useState({})

  const getPartnerStatuses = useCallback(async () => {
    const data = await getPartnerStatus()
    return setPartnerStatuses(data)
  }, [setPartnerStatuses])

  return (
    <context.Provider
      value={{
        getPartnerStatuses,
        partnerStatuses,
      }}
    >
      {children}
    </context.Provider>
  )
}

/**
 * @typedef {object} IPartnerStore
 * @property {IPartnerStatus} partnerStatuses
 * @property {() => Promise<void>} getPartnerStatuses
 */

/** @returns {IPartnerStore} */
export const usePartnerStore = () => {
  return useContext(context)
}
