import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const A = styled.a`
  margin: 0;
  font-family: 'Montserrat-Medium', sans-serif;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ color }) => (color ? color : theme.colors.bluePrimary)};

  ${theme.breakpoints.down('md')} {
    margin-top: ${theme.variables.spacer * 1}px 0;
    font-size: 12px;
  }
`
