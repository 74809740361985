import React, { useRef } from 'react'
import { theme } from '@provi/provi-components'
import { OpenedEye, ClosedEye } from '~/assets/svgs/v2'
import { BaseTypography } from '~/globalStyles'
import { CourseDetailsText, Divider, UpperTextGrid } from './styles'
import { TextSwitch, SwiperSection } from '~/screens/courses/components'
import { format } from 'date-fns'
import { CoursesModal } from 'screens/courses/components'
import { makeCourseInvisibleModal, makeCourseVisibleModal } from 'screens/courses/constants'

export const EachCourse = ({ courseData, isMobile, updateCourseProp: updateCourse, goToPageProp: goToPage }) => {
  const { id, name, createdAt, workload, active, CourseClasses } = courseData
  const editModalRef = useRef()
  const date = format(new Date(createdAt), 'dd/MM/yyyy')

  async function updateCourseMethod() {
    const courseClassesIds = CourseClasses.map((course) => course.id)

    const body = {
      visible: !active,
      applyInCourseClasses: true,
      CourseClassesIds: courseClassesIds,
    }

    await updateCourse({ id, body })
    closeModal()
  }

  function openModal() {
    if (editModalRef.current) {
      editModalRef.current.openModalFn()
    }
  }

  function closeModal() {
    if (editModalRef.current) {
      editModalRef.current.closeModalFn()
    }
  }

  return (
    <>
      <CourseDetailsText>
        <UpperTextGrid>
          <BaseTypography
            fontSize={isMobile ? '19px' : '25px'}
            lineHeight={isMobile ? '28px' : '32px'}
            color={theme.colors.bluePrimary}
          >
            {name}
          </BaseTypography>
          <BaseTypography
            fontSize={isMobile ? '16px' : '20px'}
            lineHeight={isMobile ? '24px' : '28px'}
            color={theme.colors.bluePrimary}
          >
            Criado em: {date}
          </BaseTypography>
          <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'}>
            <b>Carga horária:</b> {workload ? `${workload}h` : '---'}
          </BaseTypography>
        </UpperTextGrid>
        <BaseTypography
          cursor="pointer"
          onClick={() => goToPage(`cursos/${id}`)}
          fontSize={isMobile ? '13px' : '16px'}
          lineHeight={isMobile ? '20px' : '24px'}
          fontWeight={600}
          color={theme.colors.bluePrimary}
        >
          Mais detalhes
        </BaseTypography>
        <TextSwitch
          LeftIcon={OpenedEye}
          leftText={'Visível'}
          RightIcon={ClosedEye}
          rightText={'Invisível'}
          switchState={active}
          toggleSwitchState={openModal}
        />
        <CoursesModal
          modalRef={editModalRef}
          closeModalFn={closeModal}
          modalObject={active ? makeCourseInvisibleModal : makeCourseVisibleModal}
          handlePrimaryBtn={updateCourseMethod}
          handleSecondaryBtn={closeModal}
        />
      </CourseDetailsText>
      <SwiperSection courseKey={id} courseClassesData={CourseClasses} isMobile={isMobile} />
      <Divider />
    </>
  )
}
