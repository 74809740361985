import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const CourseDetailsGrid = styled(BaseGrid)`
  gap: 2rem;
`

export const SpaceBetweenContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    justify-content: revert;
    flex-direction: column;
    gap: 1.5rem;
  }
`

export const InformationGrid = styled(BaseGrid)`
  gap: 1.5rem;
`

export const SelectContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    justify-content: revert;
    align-items: revert;
    gap: 0.5rem;
    flex-direction: column;
  }

  .classroomSelect__menu {
    z-index: 300;
  }

  .classroomSelect__group-heading {
    color: black;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: revert;
  }

  .classroomSelect__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .classroomSelect__option {
    white-space: normal;
  }

  .classroomSelect__option--is-focused {
    font-weight: 600;
  }

  .classroomSelect__dropdown-indicator {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
  }

  .classroomSelect__control--menu-is-open .classroomSelect__dropdown-indicator {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }
`
