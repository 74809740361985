import { formatPercentage } from 'utils'

export const formatFinancingOptions = ({ data, formik }) => {
  return data?.options.map(({ installmentsToApply, monthlyInterest, id }) => {
    const isDefault = id === data.defaultOptionId
    const formattedFee = formatPercentage(Number(monthlyInterest))
    const fee = formattedFee ? `(${formattedFee} a.m.)` : '(Sem juros)'
    const defaultString = isDefault ? '[Padrão do curso]' : ''

    const label = `Até ${installmentsToApply}x ${fee} ${defaultString}`

    const formattedOption = {
      value: installmentsToApply,
      label,
      id,
    }

    if (isDefault && formik) {
      formik.setFieldValue('financingCondition', formattedOption)
    }

    return formattedOption
  })
}
