import * as moment from 'moment'
import masks from '~/themes/masks'
import { handleGoToDetailsPage } from './handleGoToDetailsPage'
import { StyledMobileSpan } from './styles'

/**
 * @typedef CancellationActionButtonType
 * @type {object}
 * @property {string} btnText
 * @property {boolean} disableBool
 */

export const fallbackString = '---'

export const formatChargebackStatus = (string) => {
  const chargebackStatusesFormatter = {
    'Pendente de Contestação': '#FBBABC',
    'Contestação Devolvida': '#FFD482',
    'Documentos Enviados': '#ADDAFF',
    'Pendente de Análise': '#ADDAFF',
    'Em Recuperação': '#ADDAFF',
    'Encerrado Estornado': '#ECEAEA',
    'Encerrado Recuperado': '#CFEFB6',
    'Encerrado Coberto': '#ECEAEA',
    'Encerrado Expirado': '#ECEAEA',
  }
  return chargebackStatusesFormatter[string] || 'FFFFFF'
}

export const openedRequestEquivalents = [
  'approved',
  'analysis',
  'waiting_cancellation',
  'waitingAccountValidation',
  'waitingBankInfo',
]
export const formatCancellationStatus = (string) => {
  const openedRequest = { status: 'Pedido aberto', statusColor: '#2647D7', textColor: '#FFFFFF' }

  const cancellationStatusesFormatter = {
    approved: openedRequest,
    analysis: openedRequest,
    waiting_cancellation: openedRequest,
    waitingAccountValidation: openedRequest,
    waitingBankInfo: openedRequest,
    waitingRefund: { status: 'Aguardando reembolso', statusColor: '#DDBFF2', textColor: '#414141' },
    done: { status: 'Cancelamento concluído', statusColor: '#B30142', textColor: '#FFFFFF' },
    reverted: { status: 'Pedido em reversão', statusColor: '#FED96A', textColor: '#414141' },
    canceled: { status: 'Cancelamento revertido', statusColor: '#A5ECAB', textColor: '#414141' },
    refunded: { status: 'Reembolso concluído', statusColor: '#7B27C5', textColor: '#FFFFFF' },
    error: { status: 'Erro', statusColor: '#FF5776', textColor: '#FFFFFF' },

    fallback: { status: fallbackString, statusColor: '', textColor: '' },
  }
  return cancellationStatusesFormatter[string] || cancellationStatusesFormatter.fallback
}

export const formatCancellationWarranty = (valueString) => {
  const cancellationWarrantyFormatter = {
    expired_warranty_period: 'Fora do prazo',
    in_warranty_period: 'Dentro do prazo',
    fallback: fallbackString,
  }
  return cancellationWarrantyFormatter[valueString] || cancellationWarrantyFormatter.fallback
}

export const formatCancellationTexts = (valueString) => {
  const openedRequest = 'Pedido aberto'

  const cancellationTextsFormatter = {
    approved: openedRequest,
    analysis: openedRequest,
    waiting_cancellation: openedRequest,
    waitingAccountValidation: openedRequest,
    waitingBankInfo: openedRequest,
    waitingRefund: 'Aguardando reembolso',
    done: 'Cancelamento concluído',
    reverted: 'Pedido em reversão',
    canceled: 'Cancelamento revertido',
    refunded: 'Reembolso concluído',
    error: 'Erro',
    fallback: fallbackString,
  }
  return cancellationTextsFormatter[valueString] || cancellationTextsFormatter.fallback
}

export const handleMobileToggle = (rowParams, defaultColumnParams) => {
  const { toggleRowExpanded } = rowParams
  const { isMobileProps } = defaultColumnParams

  return isMobileProps
    ? toggleRowExpanded()
    : () => {
        return
      }
}

export const parseFiltersFromUrlToObj = (params) => {
  return Object.keys(params).reduce((accumulatedObject, currentElement) => {
    return {
      ...accumulatedObject,
      [currentElement]: params[currentElement].trim().split(','),
    }
  }, {})
}

export const parseFiltersFromUrlToArrOfObj = (params) => {
  return Object.keys(params).reduce((accumulatedArr, currentElement) => {
    return [...accumulatedArr, { id: currentElement, value: params[currentElement].trim().split(',') }]
  }, [])
}

export const parseFiltersFromStateArr = (filterArrOfObjs, activeUser) => {
  return filterArrOfObjs.reduce((accumulatedObject, currentObject) => {
    return {
      ...accumulatedObject,
      u: activeUser,
      [currentObject.id]: currentObject.value.map((eachObject) => eachObject.value || eachObject),
    }
  }, {})
}

export const formatStatusFilters = (availableStatusFilters) => {
  const { status } = availableStatusFilters

  const translattedArr = status.map((eachElement) => {
    if (openedRequestEquivalents.includes(eachElement)) {
      return { value: 'analysis', label: formatCancellationTexts(eachElement) }
    }

    return { value: eachElement, label: formatCancellationTexts(eachElement) }
  })

  return { status: [...new Map(translattedArr.map((item) => [item['label'], item])).values()] }
}

export const formatFinalFilterObject = (finalFilterObject) => {
  const { status } = finalFilterObject

  let formattedArr = []

  for (const element of status) {
    if (element === 'analysis') formattedArr.push(...openedRequestEquivalents)

    formattedArr.push(element)
  }

  return { ...finalFilterObject, status: [...new Set(formattedArr)] }
}

export { masks, moment, handleGoToDetailsPage, StyledMobileSpan }
