import { getActiveUser, asyncLocalStorage, parseJSON } from '~/utils'

/**
 * @description remove item
 * @name removeItem
 * @function
 * @async
 * @param {{key: string, user: string}} data
 * @returns {Promise<void>}
 */
export const removeItem = async ({ key, user }) => {
  try {
    const keys = await asyncLocalStorage.getItem(key)
    if (!keys) return

    const parsedKeys = parseJSON(keys)
    delete parsedKeys[user]
    await asyncLocalStorage.setItem(key, JSON.stringify(parsedKeys))
  } catch (error) {
    await asyncLocalStorage.setItem(key, JSON.stringify({}))
  }
}

/**
 * @description remove storage
 * @name removeStorage
 * @function
 * @async
 * @returns {Promise<void>}
 */
export const removeStorage = async () => {
  const activeUser = getActiveUser()
  localStorage.removeItem('access-level')
  localStorage.removeItem('token')

  await removeItem({ key: 'tokens', user: activeUser })
  await removeItem({ key: 'user-id', user: activeUser })
  await removeItem({ key: 'partner-id', user: activeUser })
  await removeItem({ key: 'email', user: activeUser })
  await removeItem({ key: 'partner-name', user: activeUser })
  await removeItem({ key: 'partner-slug', user: activeUser })
  await removeItem({ key: 'selectedOptions', user: activeUser })
  await removeItem({ key: 'selectedOptionsValue', user: activeUser })
  await removeItem({ key: 'selectedOptionsCampaign', user: activeUser })
  await removeItem({ key: 'selectedOptionsValueCampaign', user: activeUser })
}
