import { useEffect, useState } from 'react'
import { theme } from '@provi/provi-components'
import { isEmpty } from 'lodash'
import { getCridStatusColors, formatDate, formatDateWithoutOffset } from '~/utils'

const isaAnalysisOptions = {
  'Aprovado Provi': theme.colors.green100,
  'Aprovado Provi com ressalvas': theme.colors.blue600,
  'Reprovado Provi': theme.colors.pink900,
}

const getIsaStatusColor = (status) => {
  return isaAnalysisOptions[status] || theme.colors.blue600
}

export const useDetailedUpdates = (updatesData) => {
  const [data, setData] = useState(updatesData)

  const handleData = () => {
    const response = {}

    if (updatesData && updatesData.error) {
      response.warningMessage = 'Não foi encontrada nenhuma atualização para esse financiamento'
    }
    if (!updatesData) return {}

    const compareUpdateAts = (a, b) => {
      if (a.updatedAt > b.updatedAt) return -1
      if (a.updatedAt < b.updatedAt) return 1
      return 0
    }

    if (updatesData.isProviPay) {
      response.isProviPay = updatesData.isProviPay

      const message = (type) => {
        const options = {
          ProviPay: 'Venda realizada pelo ProviPay. Estudante ainda não escolheu sua forma de pagamento',
          Boleto: 'Venda realizada no boleto à vista.',
          CreditCard: 'Venda realizada no cartão de crédito.',
        }
        return options[type] || 'Venda realizada pelo ProviPay'
      }

      response.proviPayMessage = message(updatesData.ProductType)
    }

    if (updatesData.isIsa) {
      response.isIsa = updatesData.isIsa
      response.resumeStatus = updatesData.resumeStatus

      if (updatesData.incomeShareAnalysis) {
        const { comment, endsAt, startsAt, status } = updatesData.incomeShareAnalysis
        response.isa = {
          comment,
          hasChosenCourse: updatesData.hasChosenCourse,
          startsAt: formatDateWithoutOffset(startsAt),
          endsAt: formatDateWithoutOffset(endsAt),
          status,
          statusColor: getIsaStatusColor(status),
        }
      }
    }

    if (!isEmpty(updatesData.basicInfo)) {
      response.basicInfo = {
        status: {
          title: updatesData.basicInfo.globalStatus,
          color: getCridStatusColors(updatesData.basicInfo.globalStatus),
        },
      }

      if (updatesData.basicInfo.review.length) {
        response.basicInfo.review = updatesData.basicInfo.review
      }
    }

    if (!isEmpty(updatesData.documentsInfo)) {
      response.documentsInfo = {
        status: {
          title: updatesData.documentsInfo.globalStatus,
          color: getCridStatusColors(updatesData.documentsInfo.globalStatus),
        },
        isDelayed: updatesData.documentsInfo.isDelayed,
      }

      if (updatesData.documentsInfo.review.length) {
        response.documentsInfo.review = updatesData.documentsInfo.review
      }

      if (!isEmpty(updatesData.documentsInfo.documentsSent)) {
        response.documentsInfo.documentsSent = updatesData.documentsInfo.documentsSent
        const validDocuments = Object.values(response.documentsInfo.documentsSent).filter((docs) => docs.isValid)

        if (!validDocuments.length) {
          response.documentsInfo.documentsSentLastUpdate = 'Nenhum documento enviado'
        } else {
          const lastDocument = validDocuments.sort(compareUpdateAts)[0]
          response.documentsInfo.documentsSentLastUpdate = formatDate(lastDocument.updatedAt, true)
        }
      }
    }

    if (!isEmpty(updatesData.guarantorInfo)) {
      response.guarantorInfo = {
        status: {
          title: updatesData.guarantorInfo.globalStatus,
          color: getCridStatusColors(updatesData.guarantorInfo.globalStatus),
        },
        isDelayed: updatesData.guarantorInfo.isDelayed,
      }

      if (updatesData.guarantorInfo.review.length) {
        response.guarantorInfo.review = updatesData.guarantorInfo.review
      }

      if (!isEmpty(updatesData.guarantorInfo.documentsSent)) {
        response.guarantorInfo.documentsSent = updatesData.guarantorInfo.documentsSent

        const validDocuments = Object.values(response.guarantorInfo.documentsSent).filter((docs) => docs.isValid)

        if (!validDocuments.length) {
          response.guarantorInfo.documentsSentLastUpdate = 'Nenhum documento enviado'
        } else {
          const lastDocument = validDocuments.sort(compareUpdateAts)[0]
          response.guarantorInfo.documentsSentLastUpdate = formatDate(lastDocument.updatedAt, true)
        }
      }
      if (!isEmpty(updatesData.guarantorInfo.basicInfoStatus)) {
        response.guarantorInfo.basicInfoStatus = updatesData.guarantorInfo.basicInfoStatus.status
      }
      if (!isEmpty(updatesData.guarantorInfo.guarantorAttempts)) {
        response.guarantorInfo.guarantorAttempts = updatesData.guarantorInfo.guarantorAttempts
      }
    }

    if (!isEmpty(updatesData.signedInfo)) {
      response.signedInfo = {
        status: {
          title: updatesData.signedInfo.globalStatus,
          color: getCridStatusColors(updatesData.signedInfo.globalStatus),
        },
      }

      response.signedInfo.hasGuarantor = updatesData.signedInfo.hasGuarantor

      response.signedInfo.guarantorSigned = updatesData.signedInfo.guarantorSigned
      response.signedInfo.userSigned = updatesData.signedInfo.userSigned
    }

    setData(response)
  }

  useEffect(() => {
    handleData()
  }, [updatesData])

  return [data]
}
