import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import icon_search from '~/assets/svgs/icon-search.svg'

export const WrapperSelect = styled.input`
  font-family: 'CenturyGothic';
  background: #ffffff;
  border: 1px solid #b9b7b7;
  box-sizing: border-box;
  border-radius: 50px;
  height: 50px;
  width: 500px;
  margin-top: 15px;
  background-image: url(${icon_search});
  background-repeat: no-repeat;
  background-position: center left 25px;
  padding-left: 75px;
  font-size: 20px;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 40px;
    background-position: center left 10px;
    padding-left: 50px;
    font-size: 14px;
  }

  ${({ v2 }) =>
    v2 &&
    css`
      margin-top: 0;
      font-family: 'Montserrat-Regular';
      border: 1px solid ${theme.colors.grey500};
      border-radius: 3px;
      height: 40px;
      width: 280px;
      background-position: center left 8px;
      padding-left: 40px;
      font-size: 16px;
    `}
`
