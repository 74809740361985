import { useEffect, useState } from 'react'
import { theme } from '@provi/provi-components'

export const useCpfResult = (creditRule) => {
  const [data, setData] = useState({})

  useEffect(() => {
    const getData = () => {
      let obj = {}
      switch (creditRule) {
        case 0:
          obj = {
            title: ``,
            message: 'Não foi possível analisar esse CPF no momento',
            color: theme.colors.grey600,
          }
          break
        case 1:
          obj = {
            title: `Nota ${creditRule}`,
            message: 'Altíssima chance de ser aprovada sem avalista.',
            color: theme.colors.green100,
          }
          break
        case 2:
          obj = {
            title: `Nota ${creditRule}`,
            message: 'Alta chance de ser aprovada sem avalista',
            color: theme.colors.blue600,
          }
          break
        case 3:
          obj = {
            title: `Nota ${creditRule}`,
            message: 'Alta chance de precisar de avalista',
            color: theme.colors.yellow900,
          }
          break
        case 4:
          obj = {
            title: `Nota ${creditRule}`,
            message: 'Altíssima chance de precisar de avalista.',
            color: theme.colors.pink900,
          }
          break
        case 5:
          obj = {
            title: `Nota ${creditRule}`,
            message: 'Altíssima chance de precisar de avalista.',
            color: theme.colors.pink900,
          }
          break
        default:
          break
      }

      setData({ ...obj })
    }
    getData()
  }, [])

  return [data]
}
