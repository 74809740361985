import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperOrigin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const PopoverText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};

  background-color: #fff;
  max-width: 300px;
  box-shadow: unset;
  padding: 10px;
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold';
`
export const ContainerBall = styled.div`
  width: 30px;
  background-color: #cfcfcf;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextBall = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};
`
