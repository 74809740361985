import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span:last-child {
    display: grid;
    place-items: center;
  }
`
export const RelativeContainer = styled.div`
  position: relative;
`

export const FixedPositionWrapper = styled.div`
  background-color: #ffffff;
  position: fixed;
  border: 1px solid #d3d3d3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1rem;
  display: grid;
  gap: 1rem;

  *::before,
  *::after {
    box-sizing: initial;
  }
`

export const FilterContainer = styled.div`
  position: absolute;
  top: calc(100% + 17px);
`

export const OptionsContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
