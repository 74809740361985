import { useState, useEffect, useContext, useMemo } from 'react'
import { useToggle } from '~/hooks'
import { TemplateContext } from '~/components/Template/context'
import { getChartReceipts } from '~/services/api'
import * as moment from 'moment'

export const useReceivables = (filters) => {
  const { isMobile, goToPage } = useContext(TemplateContext)
  const [receivablesData, setReceivablesData] = useState({})
  const [chartLoadingState, toggleChartLoadingState] = useToggle()
  const [dateOptionsState, setDateOptionsState] = useState('paymentDate')
  const [filterReady, setFilterReady] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ])

  const handleCalendarFilter = () => {
    setFilterReady(!filterReady)
  }

  useEffect(() => {
    const getChartData = async () => {
      try {
        toggleChartLoadingState()
        const useFilters = {
          startDate: dateRange[0].startDate,
          endDate: dateRange[0].endDate,
          datekind: dateOptionsState,
          productsSelected: filters?.productsSelected,
        }

        const { data } = await getChartReceipts(useFilters)

        setReceivablesData(data)
      } catch (error) {
        console.error(error)
      } finally {
        toggleChartLoadingState()
      }
    }
    getChartData()
  }, [dateOptionsState, filterReady, filters])

  const labelDateButton = useMemo(() => {
    const startDateMoment = moment(dateRange[0].startDate)
    const endDateMoment = moment(dateRange[0].endDate)
    return dateRange[0] && startDateMoment.toString() !== endDateMoment.toString()
      ? `${startDateMoment.format('L')} - ${endDateMoment.format('L')}`
      : 'Filtrar por data'
  }, [dateRange])

  return {
    chartLoadingState,
    labelDateButton,
    isMobile,
    goToPage,
    receivablesData,
    dateOptionsState,
    setDateOptionsState,
    dateRange,
    setDateRange,
    handleCalendarFilter,
  }
}
