import styled from 'styled-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const ContainerGrid = styled(BaseGrid)`
  min-width: 270px;
  max-width: 300px;
  padding: 1rem;
  gap: 1.5rem;
  background-color: #ffffff;
  border: 2px solid #d4daf7;
  border-radius: 8px;
`

export const TopFlexContainer = styled(BaseFlexContainer)`
  gap: 2rem;
  justify-content: space-around;
  align-items: center;
`

export const TextGrid = styled(BaseGrid)`
  gap: 0.25rem;

  p {
    text-align: center;
  }
`
