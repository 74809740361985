import { useContext, useRef } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { putPartnerCancellationResponse } from '~/services/api'
import { toast } from 'react-toastify'

export const useCancellation = ({ data, getDetails }) => {
  const { setIsLoading } = useContext(TemplateContext)
  const { CreditRequestId } = data
  const modalRef = useRef()

  const openModal = () => {
    modalRef.current.openModalFn()
  }

  const closeModal = () => {
    modalRef.current.closeModalFn()
  }

  const signalCancellationReversal = async () => {
    try {
      setIsLoading(true)
      closeModal()
      const response = await putPartnerCancellationResponse({ CreditRequestId, schoolAnswer: 'reverted' })

      if (response.status > 199 && response.status < 300) {
        await getDetails(CreditRequestId)
        toast('Resposta armazenada com sucesso!')
      }
    } catch (error) {
      toast(
        error.response?.data?.error?.message ||
          error.response?.data?.errors?.[0]?.message ||
          'Algo de errado aconteceu enviando a sua resposta',
      )
    } finally {
      setIsLoading(false)
    }
  }

  return { modalRef, openModal, closeModal, signalCancellationReversal }
}
