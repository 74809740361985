import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'

import { Pagination, PageSize } from '../index'

import { ToolBarContainer, ComponentPlaceHolder } from './styles'

export const TableToolBar = ({
  hideColumnsComponent,
  globalFilterComponent,
  downloadTableComponent,
  paginationComponent,
  pageSizeComponent,
  page,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  pageCount,
  pageOptions,
  canNextPage,
  canPreviousPage,
  setPageSize,
  totalCount,
}) => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <ToolBarContainer>
      {hideColumnsComponent && <ComponentPlaceHolder />}
      {globalFilterComponent && <ComponentPlaceHolder />}
      {downloadTableComponent && <ComponentPlaceHolder />}
      {paginationComponent && (
        <Pagination
          page={page}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageOptions={pageOptions}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          totalCount={totalCount}
        />
      )}
      {pageSizeComponent && (
        <PageSize pageSize={pageSize} setPageSize={setPageSize} pageCount={pageCount} isMobile={isMobile} />
      )}
    </ToolBarContainer>
  )
}
