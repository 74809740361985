import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Br = styled.br``

export const Text = styled.div`
  ${({ color }) => (color ? `color: ${color};` : `color : ${theme.colors.blue900};`)}
  ${({ bold }) => (bold ? `font-family: CenturyGothicBold;` : `font-family: CenturyGothic;`)}
  ${({ size }) => (size ? `font-size: ${size};` : ``)}
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : ``)}
  ${({ align }) => (align ? `text-align: ${align};` : ``)}
  ${({ display }) => (display ? `display: ${display};` : `display: block;`)}
  
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily}, sans-serif;
    `}
  
  /* margins */
  ${({ margin }) => (margin ? `margin: ${margin};` : ``)}

  ${({ mTop }) => (mTop ? `margin-top: ${mTop};` : ``)}
  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft};` : ``)}
  ${({ mRight }) => (mRight ? `margin-right: ${mRight};` : ``)}
  ${({ mBottom }) => (mBottom ? `margin-bottom: ${mBottom};` : ``)}

  /* more props */
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : ``)} 
  ${({ textOverflow }) => (textOverflow ? `text-overflow: ${textOverflow};` : ``)} 
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : ``)}
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : ``)}
`

export const Wrapper = styled.div`
  /* sizes */
  ${({ width }) => (width ? `width: ${width};` : ``)}
  ${({ height }) => (height ? `height: ${height};` : ``)}
  
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : ``)}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : ``)}
  
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : ``)}
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : ``)}
  
  /* display */
  ${({ display }) => (display ? `display: ${display};` : ``)}
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : ``)}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : ``)}
  ${({ wrap }) => (wrap ? `flex-wrap: ${wrap};` : ``)}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : ``)}
  

  /* margins */
  ${({ margin }) => (margin ? `margin: ${margin};` : ``)}
  
  ${({ mTop }) => (mTop ? `margin-top: ${mTop};` : ``)}
  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft};` : ``)}
  ${({ mRight }) => (mRight ? `margin-right: ${mRight};` : ``)}
  ${({ mBottom }) => (mBottom ? `margin-bottom: ${mBottom};` : ``)}
  
  /* paddings */
  ${({ padding }) => (padding ? `padding: ${padding};` : ``)}
  
  ${({ pTop }) => (pTop ? `padding-top: ${pTop};` : ``)}
  ${({ pLeft }) => (pLeft ? `padding-left: ${pLeft};` : ``)}
  ${({ pRight }) => (pRight ? `padding-right: ${pRight};` : ``)}
  ${({ pBottom }) => (pBottom ? `padding-bottom: ${pBottom};` : ``)}
  
  /* text-wrappers */
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : ``)}
  ${({ color }) => (color ? `color: ${color};` : ``)}

  /* more props */
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : ``)} 
  
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : ``)} 
  ${({ textOverflow }) => (textOverflow ? `text-overflow: ${textOverflow};` : ``)} 
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : ``)}
`
