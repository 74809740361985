import React from 'react'

import { Container } from './styles'

import { PrincipiapayLogo } from '~assets/svgs/principiapay';
import { Button } from '../v3';
import { getActiveUser } from '../../utils';
import { NEW_PARTNER_URL } from '../../constants';


const PrincipiaPay = () => {

  const redirectToPrincipia = () => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    const currentUser = getActiveUser();
    const tokenToUse = tokens[currentUser];
    const redirectURL = `${NEW_PARTNER_URL}/token-access?token=${tokenToUse}`;
    window.location.href = redirectURL;
  };

  return (
    <Container>
      <PrincipiapayLogo />
      <Button text="Acessar o painel" onClick={redirectToPrincipia} />
    </Container>

  )
}

export default PrincipiaPay;