import React, { useContext, useEffect } from 'react'
import { useFormik } from 'formik'
import { Button, Input, InputMasks, showToast } from '@provi/provi-components'
import { LogoProvi } from '~/assets/svgs/logo-provi'
import { Header, Container, RedTitle, SubTitle, Content, WrapperLogout, InputWrapper, InputError, LogoutButton } from './styles'
import { initialValues, validationSchema } from './form'
import { masks } from '~/enums'
import { sendBasicInfo, getAccess } from '~/services/api'
import { removeMask } from '~/utils'
import { TemplateContext } from '~/components/Template/context'
import { useMount } from '~/hooks'

export const EditBasicInfo = () => {
  const { setIsLoading, handleLogoutModal, goToPage } = useContext(TemplateContext)
  useEffect(() => {
    const checkAccess = async () => {
      try {
        await getAccess()
      } catch (error) {
        //
      }
    }

    checkAccess()
  }, [])

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await sendBasicInfo({
          fullName: values.name,
          jobTitle: values.jobRole,
          phone: removeMask(values.phone),
        }).catch((error) => {
          throw error
        })
        goToPage('home')
      } catch (e) {
        console.log(e)
        showToast('Um erro inesperado aconteceu durante o envio')
      } finally {
        setIsLoading(false)
      }
    },
  })

  useMount(() => setIsLoading(false))

  return (
    <>
      <Header>
        <LogoProvi />
      </Header>
      <Container>
        <Content>
          <RedTitle>Olá</RedTitle>
          <SubTitle>Precisamos atualizar seu cadastro!</SubTitle>
          <InputWrapper>
            <Input
              type="text"
              label="Nome"
              value={values.name}
              onChange={(e) => setFieldValue('name', e.target.value)}
              placeholder="Digite Aqui"
            />
            {errors.name && touched.name && <InputError>{errors.name}</InputError>}
          </InputWrapper>
          <InputWrapper>
            <InputMasks
              type="tel"
              label="Celular (whatsapp)"
              mask={masks.phone}
              value={values.phone}
              onChange={(e) => setFieldValue('phone', e.target.value)}
              placeholder="Digite Aqui"
            />
            {errors.phone && touched.phone && <InputError>{errors.phone}</InputError>}
          </InputWrapper>
          <InputWrapper>
            <Input
              type="text"
              label="Cargo"
              value={values.jobRole}
              onChange={(e) => setFieldValue('jobRole', e.target.value)}
              placeholder="Digite Aqui"
            />
            {errors.jobRole && touched.jobRole && <InputError>{errors.jobRole}</InputError>}
          </InputWrapper>
          <WrapperLogout>
            <Button text="Avançar" onClick={handleSubmit} />
          </WrapperLogout>
          <LogoutButton onClick={() => handleLogoutModal(true)}>Logout</LogoutButton>
        </Content>
      </Container>
    </>
  )
}
