import React, { useContext } from 'react'

import { Button, TextLink, theme } from '@provi/provi-components'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { SimpleTable } from '~/components/table'
import { ButtonBack } from '~/components/ButtonBack'
import { BackArrow } from '~/assets/svgs/back-arrow'

import { isValidEmail } from '~/utils/validators'

import { HeaderUserControl as Header } from '~/components/HeaderUserControl'
import { Text } from '~/themes/styles'

import { useAddUser } from './hooks'
import {
  Content,
  ContainerTable,
  SectionHeader,
  WrapperText,
  WrapperInput,
  WrapperButton,
  WarningAnimation,
  Container,
  Warning,
  WrapperTextLink,
  WrapperIcon,
  Input,
  Main,
} from './styles'

export const AddUsers = () => {
  const { goToPage, setIsLoading, isMobile, activePage, setActivePage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [value, handleValue, handleSubmit, link, showCells, setRows, rows, warningMessage, data] = useAddUser(
    setIsLoading,
    isMobile,
    activePage,
    setActivePage,
  )
  return (
    <Main isMobile={isMobile} drawerWidth={drawerWidth}>
      <Header addNewMember={false} />
      <Content>
        {!isMobile && <ButtonBack onClick={() => goToPage('usuario/listar-usuarios')} />}
        <SectionHeader>
          {isMobile && (
            <WrapperIcon onClick={() => goToPage('usuario/listar-usuarios')}>
              <BackArrow />
            </WrapperIcon>
          )}
          <WrapperText>
            <Text align="center" size="20px" color={theme.colors.blue900} bold>
              Digite o email de acesso do novo usuário
            </Text>
          </WrapperText>
          <WrapperInput>
            <Input
              key="email"
              maxLength={99}
              id="newEmail"
              placeholder="Ex: pedro@gmail.com.br"
              value={value}
              onChange={(e) => handleValue(e.target.value)}
            />
          </WrapperInput>
        </SectionHeader>
        <WrapperText>
          <Text align="center" mTop="33px" size="20px" color={theme.colors.blue900} bold>
            Selecione nível de acesso do email
          </Text>
          <Text align="center" mBottom="20px" size="20px" color={theme.colors.blue600} bold>
            {value}
          </Text>
        </WrapperText>
        <ContainerTable>
          {warningMessage ? (
            <Text bold align="center" color={theme.colors.blue900} mTop="30px" size="24px">
              {warningMessage}
            </Text>
          ) : (
            <SimpleTable origin="accessLevel" setRows={setRows} rows={rows} showCells={showCells} headAlign="center" />
          )}
        </ContainerTable>
        <WrapperButton>
          <Button text="Criar acesso" disabled={!isValidEmail(value)} onClick={handleSubmit} />
        </WrapperButton>
      </Content>
      {link && (
        <WarningAnimation>
          <Warning>
            <Container>
              <Text align="center" mBottom="10px" size="20px" color={theme.colors.blue600} bold>
                Acesso criado com sucesso!
              </Text>
              <Text align="center" size="16px" mBottom="10px" color={theme.colors.white100} bold display={'inline-block'}>
                O acesso criado foi enviado para o email:
              </Text>
              <Text align="center" size="16px" mBottom="10px" color={theme.colors.pink900} bold display={'inline-block'}>
                {data.login}
              </Text>
              <WrapperTextLink>
                <WrapperIcon>
                  <BackArrow isDark />
                </WrapperIcon>
                <TextLink
                  text="Retornar a tela inicial"
                  color={theme.colors.white100}
                  onClick={() => goToPage('usuario/listar-usuarios')}
                />
              </WrapperTextLink>
            </Container>
          </Warning>
        </WarningAnimation>
      )}
    </Main>
  )
}
