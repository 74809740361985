import React from 'react'

export const FileIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7507 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.2507L19.5007 8.25V20.25C19.5007 20.3485 19.4813 20.446 19.4436 20.537C19.406 20.628 19.3507 20.7107 19.2811 20.7803C19.2114 20.85 19.1287 20.9052 19.0377 20.9429C18.9468 20.9806 18.8492 21 18.7507 21Z"
      stroke="#2647D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.25 3V8.25H19.5007" stroke="#2647D7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
