import { theme } from '@provi/provi-components'

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? 600 : 'normal',
    fontFamily: 'Montserrat, sans serif',
    color: theme.colors.blue900,
    fontSize: state.selectProps.myFontSize,
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#e0ebfd',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 600,
    color: theme.colors.blue900,
    fontFamily: 'Montserrat, sans serif',
    fontSize: state.selectProps.myFontSize,
    position: 'static',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    maxWidth: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.blue900,
    fontFamily: 'Montserrat, sans serif',
    fontWeight: 500,
    fontSize: state.selectProps.myFontSize,
    position: 'static',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    maxWidth: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat, sans serif',
    zIndex: 100,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.selectProps.myIconColor,
    padding: state.selectProps.myPadding,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    transition: 'transform 600ms ease',
    '&:hover': {
      color: state.selectProps.myIconColor,
    },
  }),
  container: (provided) => ({
    ...provided,
    placeSelf: 'center',
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
}
