/**
 * @description validate calculator values
 * @name validateCalculator
 * @param {Object} data
 * @param {Number} data.amountInCents
 * @param {Array} data.coursesIds
 * @param {Number} data.maxInstallmentsToApply
 */

export const validateCalculator = ({ amountInCents, coursesIds, maxInstallmentsToApply }) => {
  if (!amountInCents || (!coursesIds?.length && !maxInstallmentsToApply)) {
    throw new Error('Ocorreu um erro inesperado ao mostrar a calculadora, contate a Provi')
  }
}
