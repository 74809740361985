/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react'
import InputMask from 'react-input-mask'
import { InputWrapper, TextFieldWrapper } from './styles'

export const Input = ({ isDark, placeholder, icon, error, width, ...props }) => {
  return (
    <InputWrapper isDark={isDark} width={width}>
      <TextFieldWrapper placeholder={placeholder} margin="normal" error={error} {...props} />
      {icon}
    </InputWrapper>
  )
}

export const InputMasks = ({ placeholder, value, icon, error, mask, width, ...props }) => {
  return (
    <InputWrapper width={width}>
      <InputMask
        alwaysShowMask={false}
        maskChar={null}
        mask={mask}
        value={value}
        placeholder={placeholder}
        margin="normal"
        {...props}
      >
        {(props) => <TextFieldWrapper error={error} {...props} />}
      </InputMask>
      {icon}
    </InputWrapper>
  )
}
