import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

const animateFadeIn = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  margin-top: 146px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    margin-top: 115px;
  }
`

export const HoverCard = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  border-radius: 20px;
  opacity: 0.15;
  display: none;
`

export const Option = styled.div`
  border: 1px solid ${theme.colors.blue900};
  border-radius: 20px;
  padding: 18px;
  margin: 10px;
  width: 305px;

  animation-name: ${animateFadeIn};
  animation-duration: 1s;
  cursor: pointer;
  position: relative;

  &:hover ${HoverCard} {
    display: block;
  }
`

export const RedTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.pink900};
  margin-bottom: 18px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const WrapperTabs = styled.div`
  margin-top: 50px;
  justify-content: center;

  font-size: 16px;
  line-height: 24px;

  ul {
    text-align: center;
    display: flex;

    .react-tabs__tab {
      width: 50%;
      font-size: 16px !important;
      line-height: 24px !important;
      font-family: 'CenturyGothic';
      color: ${theme.colors.blue900};
    }

    .react-tabs__tab--selected {
      border: none;
      border-bottom: 5px solid ${theme.colors.pink900};
      font-family: 'CenturyGothicBold';
    }

    .react-tabs__tab:focus {
      border-bottom: 5px solid ${theme.colors.pink900};
      box-shadow: none !important;
      outline: none !important;
    }
    .react-tabs__tab:focus:after {
      position: unset;
    }
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 25px;
  }
`

export const WrapperSearchBar = styled.div`
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    padding: 0 20px;
  }
`
export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: center;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const SectionHeader = styled.div``

export const Grid = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperIcon = styled.div`
  margin-right: 5px;
  align-items: center;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
`

export const WrapperText = styled.div`
  text-align: center;
`

export const SectionClasses = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 25px 0;
`

export const DownloadMore = styled.div`
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
`
