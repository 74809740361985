import styled, { css } from 'styled-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

const hasRevertWidthFn = ({ hasRevertWidth }) =>
  hasRevertWidth &&
  css`
    div:first-child {
      width: auto;
    }
  `

export const VerticalContainers = styled(BaseGrid)`
  gap: ${({ gap }) => gap || '0.5rem'};

  ${hasRevertWidthFn}
`

export const FlexContainers = styled(BaseFlexContainer)`
  gap: ${({ gap }) => gap || '1.5rem'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
  align-items: ${({ alignItems }) => alignItems || 'normal'};

  *::before,
  *::after {
    box-sizing: revert;
  }

  svg {
    flex-shrink: 0;
  }

  ${hasRevertWidthFn}

  ${({ hasFlexGrow }) =>
    hasFlexGrow &&
    css`
      flex-grow: 1;
    `}
`

export const ExplanationContainer = styled.div`
  max-width: 500px;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  border: 2px solid #d4daf7;
  border-radius: 8px;
  align-self: end;
`

export const TwoHundredPxDiv = styled.div`
  width: 200px;
`
