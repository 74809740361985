import React from 'react'
import { EachDetail } from '../index'
import { SmallCardsContainer } from '../../styles'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'

export const SmallCardsRow = ({ receivablesData, chartLoadingState }) => {
  const { totalValues } = receivablesData

  return (
    <SmallCardsContainer>
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="90px" hasHeading hasParagraph>
        <EachDetail
          isCard
          isBoldValue
          isFromSmallCards
          title={'Total de alunos efetivados'}
          value={totalValues?.studentsEffective.toLocaleString('pt-BR')}
        />
      </LoadingSkeleton>
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="90px" hasHeading hasParagraph>
        <EachDetail
          isCard
          isBoldValue
          isFromSmallCards
          title={'Valor total'}
          value={totalValues?.sellingTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        />
      </LoadingSkeleton>
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="90px" hasHeading hasParagraph>
        <EachDetail
          isCard
          isBoldValue
          isFromSmallCards
          isBluePrimary
          title={'Valor total recebido'}
          value={totalValues?.totalValueReceived.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        />
      </LoadingSkeleton>
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="90px" hasHeading hasParagraph>
        <EachDetail
          isCard
          isBoldValue
          isFromSmallCards
          title={'Valor total a receber'}
          value={totalValues?.totalValueToReceive.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        />
      </LoadingSkeleton>
    </SmallCardsContainer>
  )
}
