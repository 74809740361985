import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const CoursesHeader = styled.div`
  display: flex;
  gap: 1.5rem;

  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
  }
`

export const CoursesDivider = styled.div`
  border-top: 2px solid ${theme.colors.bluePrimaryLight};
  grid-column: 1 / -1;

  ${theme.breakpoints.down('md')} {
    margin-top: 4rem;

    ${({ revertMarginTop }) =>
      revertMarginTop &&
      css`
        margin-top: revert;
      `}
  }
`
