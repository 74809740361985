import React from 'react'
import { Box } from '@material-ui/core'

import { MobileBox, DefaultGrid } from './styles'

export const ExpandedTableRow = ({ uniqueId, row, SubComponent }) => {
  return (
    <Box>
      {SubComponent ? (
        <SubComponent row={row} uniqueId={uniqueId} />
      ) : (
        <MobileBox>
          {row.allCells.map((eachValue) => (
            <DefaultGrid key={uniqueId()}>
              <div>
                <strong>{eachValue.column.render('Header')}</strong>
              </div>{' '}
              <div>{eachValue.render('Cell')}</div>
            </DefaultGrid>
          ))}
        </MobileBox>
      )}
    </Box>
  )
}
