/* eslint-disable react/prop-types */
import React from 'react'

import { theme } from '@provi/provi-components'
import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'

import { usePaymentModal } from './hooks'
import { Header, Section, Option, Left, Right, Circle, HeaderOption, Row, Item, TextRef } from './styles'

export const NextPayment = (props) => {
  const { handleModal, show, nextPaymentData } = props
  const [data] = usePaymentModal(nextPaymentData)

  return (
    <>
      {show && data && (
        <ModalComponent isWithoutHeight width="736px" radius={5} onDismiss={() => handleModal('nextPayment')}>
          {console.log('data', data)}
          <Header>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF" mBottom="9px">
              Informações de pagamento
            </Text>
            <Text size="24px" lineHeight="30px" bold color={theme.colors.blue900}>
              {data.name}
            </Text>
            <Text size="20px" lineHeight="30px" bold color={theme.colors.blue600}>
              CPF: {data.CPF}
            </Text>
          </Header>
          <Section>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Número de parcelas:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.installments}
              </Text>
            </Text>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Parcelas pagas:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.installmentsPaid}
              </Text>
            </Text>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Parcelas em atraso:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.installmentsDelayed}
              </Text>
            </Text>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Valor da venda:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.total}
              </Text>
            </Text>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Comissão Provi:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.proviComission}
              </Text>
            </Text>
            <Text size="16px" lineHeight="24px" bold color="#B0AFAF">
              Valor a receber:{' '}
              <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900} display="inline-block">
                {data.value}
              </Text>
            </Text>
          </Section>
          <Section mTop="15px">
            {data &&
              data.data &&
              data.data.length > 0 &&
              data.data.map((option, idx) => (
                <Option key={idx} status={option.status}>
                  <HeaderOption>
                    <Left>
                      <Text size="24px" lineHeight="30px" bold>
                        Parcela {idx + 1}
                      </Text>
                    </Left>
                    <Right>
                      <Circle status={option.status} />
                    </Right>
                  </HeaderOption>
                  <Section mTop="20px">
                    <Row>
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Valor
                        </Text>
                        <Text size="18px" lineHeight="24px" bold color={theme.colors.blue900}>
                          {option.formatPrice}
                        </Text>
                      </Item>
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Data de vencimento
                        </Text>
                        <Text size="18px" lineHeight="24px" bold color={theme.colors.blue900}>
                          {option.date || '---'}
                        </Text>
                      </Item>
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Data de pagamento
                        </Text>
                        <Text size="18px" lineHeight="24px" bold color={theme.colors.blue900}>
                          {option.paymentDate || '---'}
                        </Text>
                      </Item>
                    </Row>
                    <Row mTop="10px">
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Status
                        </Text>
                        <TextRef status={option.status}>{option.translatedStatus}</TextRef>
                      </Item>
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Comissão Provi
                        </Text>
                        <Text size="18px" lineHeight="24px" bold color={theme.colors.blue900}>
                          {option.proviComissionByInstallments || '---'}
                        </Text>
                      </Item>
                      <Item>
                        <Text size="14px" lineHeight="18px" bold color="B0AFAF">
                          Valor da Ted
                        </Text>
                        <Text size="18px" lineHeight="24px" bold color={theme.colors.blue900}>
                          {option.tedValue || '---'}
                        </Text>
                      </Item>
                    </Row>
                  </Section>
                </Option>
              ))}
          </Section>
        </ModalComponent>
      )}
    </>
  )
}
