import React from 'react'
import { useDefaultComparisonChart } from './hooks'
import { DefaultComparisonChartGrid, ChartWrapper, ChartHeader, ChartText } from './styles'
import { DashboardCollapse, ChartLegendsList } from '~/screens/dashboard/components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Line, LabelList } from 'recharts'

export const DefaultComparisonChart = ({ filters }) => {
  const { isMobile, chartData, chartLoadingState } = useDefaultComparisonChart(filters)

  return (
    <DefaultComparisonChartGrid>
      <ChartHeader>Inadimplência</ChartHeader>
      <ChartText>Este gráfico mostra um comparativo entre o número de alunos pagantes e aluno inadimplentes.</ChartText>
      <ChartLegendsList legendArr="defaultComparisonChart" />
      <ChartWrapper>
        <LoadingSkeleton isLoading={chartLoadingState} repeat={2} containerHeight="150px" hasHeading hasParagraph>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={isMobile ? chartData.slice(Math.max(chartData.length - 3, 0)) : chartData}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              barGap={0}
            >
              <CartesianGrid stroke="#DCDCDC" vertical={false} />
              <XAxis
                dataKey="month"
                scale="band"
                label={{ value: 'Mês', dy: 30, fill: '#575757' }}
                tickLine={false}
                tickMargin={5}
                interval={0}
              />
              {!isMobile && (
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={() => ''}
                  label={{
                    value: 'Alunos ativos x inadimplentes',
                    angle: -90,
                    position: 'insideBottomLeft',
                    dy: -30,
                    dx: 20,
                    fill: '#575757',
                  }}
                />
              )}
              <Tooltip />
              <Bar name="Alunos ativos" dataKey="activeStudents" maxBarSize={20} fill="#A4C7FB" isAnimationActive={false}>
                <LabelList dataKey="activeStudents" position="top" style={{ fill: '#A4C7FB', fontWeight: 600 }} />
              </Bar>
              <Bar
                name="Alunos inadimplentes"
                dataKey="defaultingStudents"
                maxBarSize={20}
                fill="#FF5776"
                isAnimationActive={false}
              >
                <LabelList dataKey="defaultingStudents" position="top" style={{ fill: '#FF5776', fontWeight: 600 }} />
              </Bar>
              <Line name="Inadimplência (%)" dataKey="defaultRate" stroke="#DE0C4B" strokeWidth={2} isAnimationActive={false} />
            </ComposedChart>
          </ResponsiveContainer>
        </LoadingSkeleton>
      </ChartWrapper>
      <DashboardCollapse
        headerText="Entenda melhor os conceitos deste gráfico"
        subComponent={<ChartLegendsList legendArr="defaultComparisonChart" isFromDropdown />}
      />
    </DefaultComparisonChartGrid>
  )
}
