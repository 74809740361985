import styled from 'styled-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const TextContainer = styled(BaseGrid)`
  gap: 0.5rem;
`

export const Container = styled(BaseGrid)`
  gap: 1rem;
`

export const ActionsContainer = styled(BaseFlexContainer)`
  gap: 2rem;
`
