import React from 'react'

export const ProviLogo = ({ onClick, logoColor, textColor, hasCursor }) => (
  <svg
    onClick={onClick}
    viewBox="0 0 84 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...(hasCursor && { style: { cursor: 'pointer' } })}
  >
    <g>
      <path
        d="M21.4542 5.77284C16.7536 0.376342 8.55608 0.165059 3.58022 5.13899C1.17467 7.54454 -0.0174459 10.7041 0.00193807 13.8579L0 13.856V26H12.1441L12.1421 25.9981C15.5304 26.0194 18.9265 24.6431 21.3863 21.8635C25.4163 17.3102 25.4473 10.3552 21.4542 5.77284ZM15.4994 17.0582C13.689 18.8687 10.7542 18.8687 8.94376 17.0582C7.1333 15.2477 7.1333 12.313 8.94376 10.5025C10.7542 8.69207 13.689 8.69207 15.4994 10.5025C17.3099 12.313 17.3099 15.2477 15.4994 17.0582Z"
        fill={logoColor || '#2647D7'}
      />
      <path
        d="M39.6217 14.9649C39.6217 18.6188 36.9738 21.4392 33.7619 21.4392C32.1181 21.4392 30.9163 20.8751 30.108 19.9427V26.0002H26.429V8.83571H30.108V9.98906C30.9163 9.05669 32.1181 8.49261 33.7619 8.49261C36.9738 8.49067 39.6217 11.311 39.6217 14.9649ZM35.9426 14.9649C35.9426 13.1254 34.7156 11.974 33.0253 11.974C31.3331 11.974 30.108 13.1273 30.108 14.9649C30.108 16.8045 31.335 17.9559 33.0253 17.9559C34.7175 17.9559 35.9426 16.8025 35.9426 14.9649Z"
        fill={textColor || '#2647D7'}
      />
      <path
        d="M49.0617 8.58953V12.7571C47.542 12.5128 45.3845 13.1254 45.3845 15.5523V21.0941H41.7055V8.83377H45.3845V11.0164C45.873 9.37264 47.5168 8.58953 49.0617 8.58953Z"
        fill={textColor || '#2647D7'}
      />
      <path
        d="M49.7963 14.9649C49.7963 11.311 52.6884 8.49261 56.2938 8.49261C59.8992 8.49261 62.7913 11.313 62.7913 14.9649C62.7913 18.6188 59.8973 21.4392 56.2938 21.4392C52.6903 21.4372 49.7963 18.6169 49.7963 14.9649ZM59.1142 14.9649C59.1142 13.2242 57.8872 12.0709 56.2938 12.0709C54.7005 12.0709 53.4735 13.2242 53.4735 14.9649C53.4735 16.7056 54.7005 17.859 56.2938 17.859C57.8891 17.857 59.1142 16.7056 59.1142 14.9649Z"
        fill={textColor || '#2647D7'}
      />
      <path
        d="M76.2787 8.83377L71.7428 21.0941H67.5753L63.0394 8.83377H67.1101L69.661 16.6067L72.2119 8.83377H76.2787Z"
        fill={textColor || '#2647D7'}
      />
      <path
        d="M77.2595 5.54818C77.2595 4.34638 78.2655 3.34229 79.4654 3.34229C80.6653 3.34229 81.6732 4.34638 81.6732 5.54818C81.6732 6.74999 80.6672 7.75602 79.4674 7.75602C78.2675 7.75602 77.2595 6.74999 77.2595 5.54818ZM77.6278 8.83377H81.3069V21.0941H77.6278V8.83377Z"
        fill={textColor || '#2647D7'}
      />
    </g>
  </svg>
)
