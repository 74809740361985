import React from 'react'

export const DeleteIcon = ({ onClick }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 5.5H1M7.75034 10L7.75 13.375M12.25 10V13.375M16.75 5.5C16.75 5.5 16.75 16.75 16.75 17.875C16.75 19 15.625 19 15.625 19H4.375C4.375 19 3.25 19 3.25 17.875C3.25 16.75 3.25 5.5 3.25 5.5M14.5 5.5V2.125C14.5 2.125 14.5 1 13.375 1C12.25 1 7.75 1 6.625 1C5.5 1 5.5 2.125 5.5 2.125V5.5"
      stroke="#2647D7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
