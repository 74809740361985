import styled from 'styled-components'
import { BaseFlexContainer } from '~/globalStyles'

export const ContainerGrid = styled(BaseFlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 270px;
  max-width: 300px;
  padding: 1rem;
  gap: 1.5rem;
  background-color: #ffffff;
  border: 2px solid #d4daf7;
  border-radius: 8px;
`

export const TextFlexColumn = styled(BaseFlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${({ gap }) => gap || '5px'};
`
