import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const EachRowContainer = styled(BaseGrid)`
  width: 100%;
  grid-template-columns: 0.6fr 0.4fr;

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
    grid-template-columns: 1fr;
  }
`

export const WhiteBox = styled.div`
  border: 2px solid #d4daf7;
  padding: 12px 22px;
  background-color: #ffffff;
  border-radius: 8px 0 0 8px;
  border-right: 0;

  ${theme.breakpoints.down('md')} {
    padding: 0.4rem;
    border: 2px solid #d4daf7;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
  }
`

export const BlueBox = styled.div`
  padding: 12px 22px;
  background-color: #d4daf7;
  border-radius: 0 8px 8px 0;

  ${theme.breakpoints.down('md')} {
    padding: 0.4rem;
    border-radius: 0 0 8px 8px;
  }
`

export const InlineParagraphs = styled.div`
  p {
    display: inline;
  }
`

export const SpaceBetweenContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: center;
`
