import styled, { keyframes, css } from 'styled-components'
import { theme } from '@provi/provi-components'
import CurrencyInput from 'react-currency-input'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const Card = styled.div`
  margin-top: 100px;
  padding: 25px;

  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(27, 36, 120, 0.1);
  border-radius: 10px;

  text-align: center;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 70px;
    /* margin: 100px 15px 0 15px; */
  }
`

export const Product = styled.div`
  margin-top: 20px;
`

export const ProductTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.grey600};
`
export const ProductText = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: ${theme.colors.blue900};
`

export const CardValueTitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.pink900};
  margin-top: 30px;
`

export const CardValue = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
`

export const Content = styled.div`
  margin: 50px 0;

  ${theme.breakpoints.down('md')} {
    padding: 0 10px;
  }
`

export const SectionOption = styled.div`
  display: ${({ display }) => display || 'block'};
  justify-content: ${({ justify }) => justify || 'unset'};
  margin: 30px 0px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 20px;
  }
`

export const OptionTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
`

export const OptionSubTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic';
`

export const WrapperInputs = styled.div`
  display: flex;
  align-items: flex-end;
`

export const WrapperIcon = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
`

export const CardInitialEntry = styled.div`
  padding: 25px 20px;
  border: 1px solid ${({ isActive }) => (isActive ? theme.colors.pink900 : theme.colors.grey600)};
  border-radius: 10px;
  transition: width 2s ease;
  width: auto;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
`

export const CIEHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CIETitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';

  color: ${({ isActive }) => (isActive ? theme.colors.blue900 : theme.colors.grey600)};
`
export const CIEContent = styled.div`
  margin-top: 30px;
`

export const WrapperSwitch = styled.div`
  display: flex;
  align-items: center;
`

export const SwitchText = styled.div`
  margin-right: 10px;
`

export const WrapperDaysToPayment = styled.div`
  margin-top: 20px;
`

export const WrapperButtonsDays = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`

export const ButtonDay = styled.div`
  cursor: pointer;
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${theme.colors.grey600};
  color: ${({ isActive }) => (isActive ? theme.colors.grey400 : theme.colors.grey600)};
  background-color: ${({ isActive }) => (isActive ? theme.colors.blue900 : 'unset')};
  margin: 0 8px;
  border-radius: 5px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`

export const WrapperGetLink = styled.div``
export const WrapperGetLinkTextLink = styled.div`
  display: flex;
  justify-content: center;
`

export const WarningAnimation = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`

export const animationWarning = keyframes`
  0% {
    bottom: -516px;
  }
  100% {
    bottom: 0;
  }
`

export const Warning = styled.div`
  padding: 0 20px;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 20px;
  border-radius: 30px 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  animation-name: ${animationWarning};
  animation-duration: 1000ms;
  animation-timing-function: ease;
  background-color: ${theme.colors.blue900};
  color: ${theme.colors.grey400};
`

export const WrapperCardLink = styled.div`
  border: 1px solid ${theme.colors.pink900};
  width: 100%;
  padding: 20px;
  border-radius: 20px;

  font-size: 14px;
  line-height: 24px;
  text-align: justify;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 24px;
  }
`

export const WarningBold = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
`

export const Br = styled.br``

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: center;

  h5 {
    font-size: 16px;
  }
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${theme.colors.pink900};
`

export const InputMask = styled(CurrencyInput)`
  margin-top: 20px;
  width: ${({ width }) => width || 'auto'};
  padding: 6px 0 7px 0;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin-bottom: 7px;

  &:before {
    content: '';
    position: absolute;
  }
`

export const AnchorElementToSmallScreens = styled.div`
  @media screen and (max-width: 365px) {
    display: none;
  }
`

export const Entries = styled.div`
  padding: 10px 15px;
  border: 1px solid ${theme.colors.grey600};
  background-color: #fff;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  margin-top: 15px;

  font-size: 16px;
  line-height: 30px;
  font-family: 'CenturyGothicBold';

  color: ${theme.colors.grey600};
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-right: 5px;
  }
`

export const CalcButton = styled.div`
  border: 1px solid red;
  position: absolute;

  right: 7px;
  bottom: 12px;
  padding: 3px 6px;
  cursor: pointer;
  border-radius: 5px;
`

export const WrapperCalc = styled.div`
  position: relative;
`

export const EntranceBold = styled.span`
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue600};
`

export const ButtonOutline = styled.div`
  cursor: pointer;
  cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};
  font-size: 16px;
  line-height: 30px;
  font-family: 'CenturyGothicBold';

  transition: color 100ms ease, border 100ms ease;

  color: ${({ isDisable }) => (isDisable ? theme.colors.grey600 : theme.colors.pink900)};
  border: 1px solid ${({ isDisable }) => (isDisable ? theme.colors.grey600 : theme.colors.pink900)};

  padding: 6px 15px;
  /* width: 140px; */
  border-radius: 20px;

  display: flex;
  align-items: center;

  svg {
    transition: stroke 200ms ease;
    margin-right: 12px;
  }
`

export const WrapperOutline = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 10px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const BackgroundBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #f2f2f2;
  height: 290px;
  z-index: -1;
`
