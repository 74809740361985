export const selectValueWithUrlFilter = (values, queryItems) => {
  if (!queryItems || !queryItems.length) return values

  return values.map((value) => {
    if (value.id === null) {
      return {
        ...value,
        active: queryItems.includes('CLEAR'),
      }
    }

    return {
      ...value,
      active: queryItems.includes(value.id && value.id.toString()),
    }
  })
}
