import React from 'react'

export const CloseIcon = ({ onClick, bluePrimarySmall }) => {
  if (bluePrimarySmall) {
    return (
      <svg
        style={{ cursor: 'pointer' }}
        onClick={onClick}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.70711 0.292891C1.31659 -0.0976337 0.68342 -0.0976333 0.292896 0.292891C-0.0976288 0.683415 -0.0976288 1.31658 0.292896 1.7071L1.70711 0.292891ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3166 -0.0976309 10.6834 -0.0976313 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976312 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM0.292896 1.7071L5.2929 6.7071L6.70711 5.29289L1.70711 0.292891L0.292896 1.7071ZM5.29289 6.7071L10.2929 11.7071L11.7071 10.2929L6.70711 5.29289L5.29289 6.7071ZM6.70711 6.7071L11.7071 1.70711L10.2929 0.292893L5.2929 5.29289L6.70711 6.7071ZM5.2929 5.29289L0.292893 10.2929L1.70711 11.7071L6.70711 6.7071L5.2929 5.29289Z"
          fill="#2647D7"
        />
      </svg>
    )
  }
  return (
    <svg
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      width={'16'}
      height={'16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7071 0.292921C1.31658 -0.0976022 0.683414 -0.0976005 0.292891 0.292924C-0.0976324 0.68345 -0.0976307 1.31661 0.292894 1.70714L1.7071 0.292921ZM14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3165 16.0976 14.6834 15.7071 14.2929L14.2929 15.7071ZM15.7071 1.70708C16.0976 1.31655 16.0976 0.683387 15.7071 0.292863C15.3166 -0.0976619 14.6834 -0.0976612 14.2929 0.292863L15.7071 1.70708ZM0.292894 14.2929C-0.0976303 14.6834 -0.0976306 15.3166 0.292894 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L0.292894 14.2929ZM0.292894 1.70714L7.29288 8.70709L8.70709 7.29288L1.7071 0.292921L0.292894 1.70714ZM7.29288 8.70709L14.2929 15.7071L15.7071 14.2929L8.70709 7.29288L7.29288 8.70709ZM8.70709 8.70709L15.7071 1.70708L14.2929 0.292863L7.29288 7.29288L8.70709 8.70709ZM7.29288 7.29288L0.292894 14.2929L1.70711 15.7071L8.70709 8.70709L7.29288 7.29288Z"
        fill={'black'}
      />
    </svg>
  )
}
