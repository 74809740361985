/* eslint-disable react/prop-types */
import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'

import { Product, ProductTitle, ProductText } from './styles'
import { capitalize, formatValue } from '~/utils'

export const ProductModal = (props) => {
  const { handleModal, show, ProductData } = props

  return (
    <>
      {show && ProductData && (
        <ModalComponent isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('product')}>
          {ProductData.courses.map((product, index) => (
            <Product key={index}>
              <ProductTitle>Produto {index + 1}:</ProductTitle>
              <ProductText>
                {capitalize(product.name)} | {product.is_full_time ? 'Full Time' : 'Part Time'} | {capitalize(product.modality)}{' '}
                |{product.price ? formatValue(product.price, true) : formatValue(product.price_in_cents)}
              </ProductText>
            </Product>
          ))}
        </ModalComponent>
      )}
    </>
  )
}
