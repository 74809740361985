import React from 'react'

export const DrawerQuestionMarkIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 19V18C16.7911 18 17.5645 17.7654 18.2223 17.3259C18.8801 16.8864 19.3928 16.2616 19.6955 15.5307C19.9983 14.7998 20.0775 13.9956 19.9231 13.2196C19.7688 12.4437 19.3878 11.731 18.8284 11.1716C18.269 10.6122 17.5563 10.2312 16.7804 10.0769C16.0044 9.92252 15.2002 10.0017 14.4693 10.3045C13.7384 10.6072 13.1136 11.1199 12.6741 11.7777C12.2346 12.4355 12 13.2089 12 14"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 24.5C16.8284 24.5 17.5 23.8284 17.5 23C17.5 22.1716 16.8284 21.5 16 21.5C15.1716 21.5 14.5 22.1716 14.5 23C14.5 23.8284 15.1716 24.5 16 24.5Z"
      fill="#000B3C"
    />
  </svg>
)
