/* eslint-disable react/prop-types */
import React from 'react'

import { Button } from '@provi/provi-components'
import { Modal } from '~/components/Modal'
import { Text } from '~/themes/styles'

export const SellerInfoModal = (props) => {
  const { handleModal } = props

  return (
    <>
      <Modal
        isWithoutHeight
        width="720px"
        radius={20}
        title="Não é você?"
        onDismiss={() => handleModal(false, 'seller')}
        footer={<Button text="Entendi" onClick={() => handleModal(false, 'seller')} />}
      >
        <Text>Solicite ao gestor para criar um acesso</Text>
      </Modal>
    </>
  )
}
