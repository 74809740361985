import styled from 'styled-components'
import { theme } from '@provi/provi-components'

import { BaseGrid } from '~/globalStyles'

export const ListCoursesGrid = styled(BaseGrid)`
  gap: 1.5rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }

  .searchBar {
    margin-left: auto;

    ${theme.breakpoints.down('md')} {
      margin-left: revert;
      margin-bottom: 0.5rem;
    }
  }

  .addBtn {
    margin: 0 auto;
  }
`

export const PaginationContainer = styled.div`
  place-self: center;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const PagesText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`

export const InputButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
