const initialMobile = [
  { item: 'CreditRequestId', label: 'CRID', show: true },
  { item: 'status', label: 'Status', show: true },
  { item: 'CPF', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', show: true },
  { item: 'installmentNumber', label: 'Número da mensalidade', show: true },
  { item: 'tuitionValue', label: 'Valor da mensalidade (R$)', show: true },
  { item: 'tuitionTransferDate', label: 'Data prevista para saldo disponível ', show: true },
]

const initial = [
  { item: 'CreditRequestId', label: 'CRID', show: true },
  { item: 'status', label: 'Status', show: true },
  { item: 'CPF', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', show: true },
  { item: 'installmentNumber', label: 'Número da mensalidade', show: true },
  { item: 'tuitionValue', label: 'Valor da mensalidade (R$)', show: true },
  {
    item: 'tuitionTransferDate',
    label: 'Data prevista para saldo disponível ',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
    isHelper: true,
  },
]

const mobileArrWithOrigin = initialMobile.map((eachObj) => ({ ...eachObj, tableOrigin: 'Mensalidades Garantidas' }))
const initialArrWithOrigin = initial.map((eachObj) => ({ ...eachObj, tableOrigin: 'Mensalidades Garantidas' }))

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return mobileArrWithOrigin
  }

  return initialArrWithOrigin
}
