import React from 'react'
import { SelectorV3 as Select, theme } from '@provi/provi-components'

import * as S from './styles'

import { Calculator as CalculatorIcon } from 'assets/svgs/calc'

interface InputValue {
  value: number
  label: string
}

type ActionModal = () => void

interface IFinancingConditionInputProps {
  financingConditions: InputValue[]
  handleSelectFinancingCondition: (value: InputValue) => void
  selectedFinancingCondition: InputValue | null
  marginBottom?: number
  label: string
  explainer?: string
  labelColor?: string
  explainerColor?: string
  calculatorModalAction?: ActionModal
  displayCalculator?: boolean
}

const renderCalculator = (action?: ActionModal) => {
  return (
    <S.CalculatorContainer onClick={action}>
      <CalculatorIcon width="24" height="24" strokeWidth={2.3} color={theme.colors.bluePrimary} />
      <S.CalculatorText>Ver condições de financiamento</S.CalculatorText>
    </S.CalculatorContainer>
  )
}

export const FinancingConditionInput: React.FC<IFinancingConditionInputProps> = ({
  marginBottom,
  financingConditions,
  selectedFinancingCondition,
  label,
  explainer,
  labelColor,
  explainerColor,
  handleSelectFinancingCondition,
  calculatorModalAction,
  displayCalculator = false,
}) => {
  return (
    <S.SelectContainer labelColor={labelColor} explainerColor={explainerColor} marginBottom={marginBottom}>
      <Select
        width="100%"
        className="groupSelect"
        classNamePrefix="groupSelect"
        options={financingConditions}
        label={label}
        placeholder="Selecione uma opção de parcelamento"
        value={selectedFinancingCondition}
        onChange={handleSelectFinancingCondition}
        explainer={explainer}
      />
      {displayCalculator ? renderCalculator(calculatorModalAction) : null}
    </S.SelectContainer>
  )
}
