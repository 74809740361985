import React from 'react'
import { useLocation } from 'react-router-dom'
import { useDrawer } from './hooks'
import { DrawerMenu } from './index'

export const DrawerMenuContext = React.createContext({
  open: false,
  setOpen: () => {},
  currentOpenTabs: [],
  handleDrawerOpen: () => {},
  handleDrawerClose: () => {},
  existingTabs: [],
  setCurrentOpenTabs: () => {},
  currentRoute: '',
  handleDrawerState: () => {},
  drawerWidth: 60,
})

export const DrawerMenuProvider = ({ children, handleLogoutModal, goToPage, accessData }) => {
  const { pathname } = useLocation()
  const drawerContext = useDrawer({ handleLogoutModal, pathname, goToPage, accessData })
  const {
    open,
    setOpen,
    currentOpenTabs,
    handleDrawerOpen,
    handleDrawerClose,
    existingTabs,
    setCurrentOpenTabs,
    currentRoute,
    handleDrawerState,
  } = drawerContext

  return (
    <DrawerMenuContext.Provider value={drawerContext}>
      <DrawerMenu
        open={open}
        setOpen={setOpen}
        currentOpenTabs={currentOpenTabs}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        existingTabs={existingTabs}
        setCurrentOpenTabs={setCurrentOpenTabs}
        currentRoute={currentRoute}
        handleDrawerState={handleDrawerState}
        goToPage={goToPage}
        accessData={accessData}
      />
      {children}
    </DrawerMenuContext.Provider>
  )
}
