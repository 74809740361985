import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ChartLabelsContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 1rem;
  }
`

export const ChartDropdownContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`
