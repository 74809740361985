import { showToast } from '@provi/provi-components'

import { getPayments } from '~/services/api'

export const useProductLink = (
  setIsLoading,
  handleModal,
  courses,
  setProductData,
  lastUpdate,
  setLastUpdateData,
  nextPaymentData,
  setNextPaymentData,
  installmentData,
  installmentValueData,
  loanOptionCommentData,
) => {
  const handleProduct = () => {
    setProductData({ courses: [...courses] })
    handleModal('product')
  }
  const handleLastUpdate = () => {
    setLastUpdateData(lastUpdate)
    handleModal('lastUpdate')
  }
  const handleNextPayment = async () => {
    try {
      setIsLoading(true)
      const { data } = await getPayments(nextPaymentData && nextPaymentData.id)

      setIsLoading(false)
      setNextPaymentData({ ...nextPaymentData, data })
      handleModal('nextPayment')
    } catch (error) {
      showToast('Ocorreu um erro ao listar as opções de pagamento')
    }
  }

  const handleOption = (target) => {
    switch (target) {
      case 'product':
        handleProduct()
        break

      case 'lastUpdate':
        handleLastUpdate()
        break

      case 'nextPayment':
        handleNextPayment()
        break

      case 'installments':
        handleModal('installments', installmentData)
        break

      case 'installments-value':
        handleModal('installments-value', installmentValueData)
        break
      case 'loanOptionComment':
        handleModal('loanOptionComment', loanOptionCommentData)
        break
    }
  }

  return [handleOption]
}
