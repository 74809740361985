import React from 'react'
import { ButtonV3, SelectorV3, Radio } from '@provi/provi-components'

import { useConnectProducts } from './hooks'
import { SelectPills, LeftSideSelects } from './components'
import { EachIntegration } from '../EachIntegration'
import { ConnectProductsContainer, TwoColumnGrid, LabelText, ColumnGrids, FlexContainer, TextContainer } from './styles'

export const ConnectProducts = () => {
  const {
    isMobile,
    productConnection,
    chosenProviProducts,
    chosenMemberkitGroupProducts,
    chosenMemberkitSubscription,
    removeItemFromGroupArr,
    isLoadingAllProducts,
    connectProductMutation,
    onChangeHandlers,
    proviProducts,
    memberkitProducts,
    memberkitSubscriptions,
    currentIntegrationName,
    proviPayVersion,
    proviCourseClasses,
    chosenProviCourseClasses,
  } = useConnectProducts()

  return (
    <ConnectProductsContainer>
      <EachIntegration name={currentIntegrationName} noBorderBottomCondition={true} />
      <TwoColumnGrid>
        <LeftSideSelects
          isMobile={isMobile}
          useV3={proviPayVersion?.data?.content?.useV3}
          isLoadingAllProducts={isLoadingAllProducts}
          proviProducts={proviProducts}
          chosenProviProducts={chosenProviProducts}
          onChangeHandlers={onChangeHandlers}
          proviCourseClasses={proviCourseClasses}
          chosenProviCourseClasses={chosenProviCourseClasses}
        />

        <ColumnGrids>
          <TextContainer>
            <LabelText isBold>Conecte com os produtos MemberKit</LabelText>
            <LabelText isSmall>Selecione a(s) turma(s) ou o produto de assinatura da MemberKit</LabelText>
          </TextContainer>
          <FlexContainer state={productConnection} isGroupContainer>
            <Radio
              text={() => null}
              checked={productConnection === 'group'}
              onChange={onChangeHandlers.handleGroupProductConnection}
            />
            <ColumnGrids>
              <SelectorV3
                isMulti
                width={'100%'}
                className="groupSelect"
                classNamePrefix="groupSelect"
                label={'Adicionar uma turma'}
                placeholder={isMobile ? 'Selecione ...' : 'Selecione uma ou mais turmas'}
                isLoading={isLoadingAllProducts}
                loadingMessage={() => 'Carregando opções'}
                controlShouldRenderValue={false}
                options={memberkitProducts}
                value={chosenMemberkitGroupProducts}
                onChange={onChangeHandlers.handleMemberkitProducts}
              />
              <SelectPills pillsArray={chosenMemberkitGroupProducts} removeItemFn={removeItemFromGroupArr} />
            </ColumnGrids>
          </FlexContainer>
          <FlexContainer state={productConnection} isSubscriptionContainer>
            <Radio
              text={() => null}
              checked={productConnection === 'subscription'}
              onChange={onChangeHandlers.handleSubscriptionProductConnection}
            />
            <ColumnGrids>
              <SelectorV3
                isClearable
                width={'100%'}
                className="groupSelect"
                classNamePrefix="groupSelect"
                label={'Adicionar uma assinatura'}
                placeholder={isMobile ? 'Selecione ...' : 'Selecione uma assinatura'}
                isLoading={isLoadingAllProducts}
                loadingMessage={() => 'Carregando opções'}
                options={memberkitSubscriptions}
                value={chosenMemberkitSubscription}
                onChange={onChangeHandlers.handleMemberkitSubscriptions}
              />
            </ColumnGrids>
          </FlexContainer>
        </ColumnGrids>
      </TwoColumnGrid>
      <ButtonV3 text="Conectar produtos" mobileWidth={'100%'} onClick={connectProductMutation} />
    </ConnectProductsContainer>
  )
}
