import React from 'react'
import { memberkitFlowArray } from './constants'
import { useFetchProviPayVersion } from '~/screens/integrations/requests'

export const IntegrationsContext = React.createContext()

export const IntegrationsProvider = (props) => {
  const { children, isMobile } = props || {}
  const [flowState, setFlowState] = React.useState('Integrações')
  const [currentIntegrationName, setCurrentIntegrationName] = React.useState('')
  const [currentIntegrationId, setCurrentIntegrationId] = React.useState('')
  const [productsToEdit, setProductsToEdit] = React.useState(null)

  const { data: proviPayVersion } = useFetchProviPayVersion()

  const setBreadcrumbs = React.useCallback(() => {
    const possibleBreadCrumbs = {
      Integrações: ['Integrações'],
      Memberkit: ['Integrações', 'Memberkit'],
      'Adicionar conta': ['Integrações', 'Memberkit', 'Adicionar conta'],
      'Gerenciar conta': ['Integrações', 'Memberkit', 'Gerenciar conta'],
      'Conectar produtos': ['Integrações', 'Memberkit', 'Gerenciar conta', 'Conectar produtos'],
    }

    const breadCrumbsToMap =
      isMobile && possibleBreadCrumbs[flowState].length > 2
        ? ['...', ...possibleBreadCrumbs[flowState].slice(-2)]
        : possibleBreadCrumbs[flowState] || []

    return breadCrumbsToMap.map((eachString) => (
      <span key={eachString} onClick={() => eachString !== '...' && setFlowState(eachString)} style={{ cursor: 'pointer' }}>
        {eachString}
      </span>
    ))
  }, [isMobile, flowState])

  const whichComponentToRender = React.useCallback(() => {
    return memberkitFlowArray.find((eachObject) => eachObject.breadCrumbTitle === flowState).component
  }, [flowState])

  const goBack = React.useCallback(() => {
    const possibleScenarios = {
      Integrações: () => setFlowState('Integrações'),
      Memberkit: () => setFlowState('Integrações'),
      'Adicionar conta': () => setFlowState('Memberkit'),
      'Gerenciar conta': () => setFlowState('Memberkit'),
      'Conectar produtos': () => setFlowState('Gerenciar conta'),
      fallback: () => setFlowState('Integrações'),
    }
    return possibleScenarios[flowState] || possibleScenarios.fallback
  }, [flowState])

  const providerValue = {
    flowState,
    setFlowState,
    currentIntegrationName,
    setCurrentIntegrationName,
    currentIntegrationId,
    setCurrentIntegrationId,
    setBreadcrumbs,
    whichComponentToRender,
    goBack,
    productsToEdit,
    setProductsToEdit,
    proviPayVersion,
  }

  return <IntegrationsContext.Provider value={providerValue}>{children}</IntegrationsContext.Provider>
}
