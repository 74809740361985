/* eslint-disable react/prop-types */
import React from 'react'

export const EyeIcon = ({ active }) => {
  if (active) {
    return (
      <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="https://www.w3.org/2000/svg">
        <path
          d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z"
          stroke="#DE0C4B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
          stroke="#DE0C4B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003M1 1L23 23M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003L17.94 17.94ZM9.9 4.24003C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19L9.9 4.24003Z"
        stroke="#B0AFAF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const DisabledEye = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M9.94545 9.94545C9.75819 10.1464 9.53238 10.3076 9.28147 10.4194C9.03056 10.5312 8.75971 10.5913 8.48506 10.5962C8.21042 10.601 7.93761 10.5505 7.68292 10.4476C7.42822 10.3447 7.19686 10.1916 7.00262 9.99738C6.80839 9.80314 6.65527 9.57178 6.55239 9.31708C6.44952 9.06239 6.399 8.78958 6.40384 8.51494C6.40869 8.24029 6.4688 7.96944 6.5806 7.71853C6.69239 7.46763 6.85358 7.24181 7.05455 7.05455M1 1L16 16M12.55 12.55C11.3845 13.4384 9.9653 13.9306 8.5 13.9545C3.72727 13.9545 1 8.5 1 8.5C1.84811 6.91947 3.02441 5.5386 4.45 4.45L12.55 12.55ZM7.06818 3.20909C7.5375 3.09924 8.018 3.04432 8.5 3.04545C13.2727 3.04545 16 8.5 16 8.5C15.5861 9.27428 15.0925 10.0032 14.5273 10.675L7.06818 3.20909Z"
      stroke="#CFCFCF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Eye = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M1 6.45455C1 6.45455 3.72727 1 8.5 1C13.2727 1 16 6.45455 16 6.45455C16 6.45455 13.2727 11.9091 8.5 11.9091C3.72727 11.9091 1 6.45455 1 6.45455Z"
      stroke="#000B3C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 8.5C9.62967 8.5 10.5455 7.58422 10.5455 6.45455C10.5455 5.32487 9.62967 4.40909 8.5 4.40909C7.37033 4.40909 6.45455 5.32487 6.45455 6.45455C6.45455 7.58422 7.37033 8.5 8.5 8.5Z"
      stroke="#000B3C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const EyeIconWithBorder = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M7 15.5C7 15.5 10.0909 10 15.5 10C20.9091 10 24 15.5 24 15.5C24 15.5 20.9091 21 15.5 21C10.0909 21 7 15.5 7 15.5Z"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 17.5625C16.7803 17.5625 17.8182 16.6391 17.8182 15.5C17.8182 14.3609 16.7803 13.4375 15.5 13.4375C14.2197 13.4375 13.1818 14.3609 13.1818 15.5C13.1818 16.6391 14.2197 17.5625 15.5 17.5625Z"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="15.5" cy="15.5" r="14.5" stroke="#DE0C4B" strokeWidth="2" />
  </svg>
)
