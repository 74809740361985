import styled from 'styled-components'
import { BaseGrid } from '~/globalStyles'
import { theme } from '@provi/provi-components'

export const CollapseContainer = styled(BaseGrid)`
  gap: 1.5rem;
`

export const TextGrid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;

  ${theme.breakpoints.down('md')} {
    grid-column-gap: 2rem;
  }
`
