import firebase from '~/services/firebase'
import { getStorage } from '~/utils'
import { LOCALSTORAGE_TOKENS } from '~/constants'
import { createNewCertificate } from '~/services/api'
import { logger } from '~/helpers/logger'

export const useCertificateData = (setIsLoading, showToast) => {
  const sendData = async (url, formData) => {
    const response = await createNewCertificate({ certificateUrl: url, password: formData.password, email: formData.email })
    if (response.status === 200) {
      setIsLoading(false)
      showToast('Certificado criado com sucesso!')
      setTimeout(function () {
        location.reload()
      }, 2000)
    } else {
      setIsLoading(false)
      showToast('Ops, ocorreu um erro ao finalizar o cadastro do seu certificado 😞')
    }
  }

  const initalizeUpload = async (formData) => {
    setIsLoading(true)
    const { file } = formData
    const fileType = file.name.split('.').pop()
    const now = new Date().getTime()
    const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
    const storagePath = `/${partnerId}/${now}.${fileType}`

    const storageRef = firebase.storage().ref(storagePath)
    const uploadTask = storageRef.put(file)

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (err) => {
        logger({ error: err, ref: 'Firebase upload error' })
        setIsLoading(false)
        showToast('Ops, ocorreu um erro ao salvar o seu certificado 😞')
      },
      async () => {
        const url = await storageRef.getDownloadURL()
        await sendData(url, formData)
      },
    )
  }

  return [initalizeUpload]
}
