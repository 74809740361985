import { useState } from 'react'
import { useMount } from '~/hooks'
import { getDataFromSchool, sendDataFromSchool, getFullDataFromSchool } from '~/services/api'

/**
 * @name useSchoolInfo
 * @param {<T>(field: string, value: T) => void} setFieldValue
 */
export const useSchoolInfo = (setFieldValue, showToast, setIsLoading, status) => {
  const [optionsTax, setOptionsTax] = useState([])
  const [specialOptionsTax, setSpecialOptionsTax] = useState([])
  const [expeditionDays, setDayToExpedition] = useState([])

  const sendSchoolInfo = async ({
    countySubscription,
    stateSubscription,
    simplesNacional,
    taxRegime,
    taxIncentive,
    culturalPromoter,
    specialTaxRegime,
    nfseEmail,
    daysToExpedition,
  }) => {
    setIsLoading(true)
    const postBody = {
      countySubscription,
      stateSubscription,
      simplesNacional,
      taxRegime: taxRegime.value,
      taxIncentive,
      culturalPromoter,
      specialTaxRegime: specialTaxRegime.value,
      nfseEmail,
      NFSeTriggerId: daysToExpedition.id,
    }

    const response = await sendDataFromSchool(postBody)
    if (response.status === 200) {
      setIsLoading(false)
      showToast('Informações da escola cadastradas com sucesso!')
      location.reload()
    } else {
      setIsLoading(false)
      showToast(response.data.error.message || 'Ops, ocorreu um erro ao cadastrar as informações da escola 😞')
    }
  }

  useMount(async () => {
    try {
      setIsLoading(true)
      const data = await getDataFromSchool()
      if (status) {
        const fullData = await getFullDataFromSchool()
        setFieldValue('nfseEmail', fullData.email)
        setFieldValue('countySubscription', fullData.countySubscription)
        setFieldValue('stateSubscription', fullData.stateSubscription)
        setFieldValue('simplesNacional', fullData.simplesNacional)

        const taxRegime = data.taxRegimeOptions.filter((x) => x.id == fullData.taxRegime)
        setFieldValue('taxRegime', { value: taxRegime[0].id, label: taxRegime[0].description })

        const daysToExpedition = fullData.dayToExpedition.filter((x) => x.selected == true)
        if (daysToExpedition.length) {
          setFieldValue('daysToExpedition', {
            id: daysToExpedition[0].id,
            value: daysToExpedition[0].id,
            label: daysToExpedition[0].label,
          })
        }

        const specialTaxRegime = data.specialTaxRegimeOptions.filter((x) => x.id == fullData.specialTaxRegime)
        setFieldValue('specialTaxRegime', { value: specialTaxRegime[0].id, label: specialTaxRegime[0].description })

        setFieldValue('taxIncentive', fullData.taxIncentive)
        setFieldValue('culturalPromoter', fullData.culturalPromoter)
      }

      setFieldValue('cnpj', data.CNPJ)
      setFieldValue('nomeFantasia', data.fantasyName)
      setFieldValue('razaoSocial', data.businessName)

      if (data.address) {
        const addressText = `${data.address.street}, ${data.address.number}, ${data.address.district} - ${data.address.city}`
        setFieldValue('address', addressText)
      }

      setOptionsTax(
        data.taxRegimeOptions.map(({ id, description }) => ({
          value: id,
          label: description,
        })),
      )

      setSpecialOptionsTax(
        data.specialTaxRegimeOptions.map(({ id, description }) => ({
          value: id,
          label: description,
        })),
      )

      setDayToExpedition(
        data.dayToExpedition.map(({ id, label }) => ({
          id,
          label,
        })),
      )
    } catch (error) {
      console.log(error)
      showToast('Um erro inesperado aconteceu')
    } finally {
      setIsLoading(false)
    }
  })

  return {
    optionsTax,
    specialOptionsTax,
    sendSchoolInfo,
    expeditionDays,
  }
}
