import React from 'react'

export const DeveloperIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none"></rect>
    <polyline
      points="64 88 16 128 64 168"
      fill="none"
      stroke="#000B3C"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <polyline
      points="192 88 240 128 192 168"
      fill="none"
      stroke="#000B3C"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></polyline>
    <line
      x1="160"
      y1="40"
      x2="96"
      y2="216"
      fill="none"
      stroke="#000B3C"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="16"
    ></line>
  </svg>
)
