import React from 'react'
import { theme } from '@provi/provi-components'
import { TextSwitch } from '~/screens/courses/components'
import { BaseTypography } from '~/globalStyles'
import { InputContainer, TextFlex, VisibilityFlexContainer } from '../../styles'

export const VisibilityInput = ({ isMobile, formik }) => {
  return (
    <InputContainer>
      <VisibilityFlexContainer>
        <TextFlex flexColumn>
          <BaseTypography fontWeight={600} fontSize={isMobile ? '13px' : '16px'} lineHeight={'20px'}>
            Publicar no site Provi
          </BaseTypography>
          <BaseTypography fontSize={isMobile ? '11px' : '13px'} color={theme.colors.greyPrimary} maxWidth={'470px'}>
            A turma ficará visível quando o marketplace da Provi estiver disponível. A turma será listada no site e no link
            geral de vendas.
          </BaseTypography>
        </TextFlex>

        <TextSwitch
          leftText="Sim"
          rightText="Não"
          switchState={formik.values.visible}
          toggleSwitchState={() => formik.setFieldValue('visible', !formik.values.visible)}
        />
      </VisibilityFlexContainer>
    </InputContainer>
  )
}
