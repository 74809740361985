import { getFinancialMovementFiltersV4 } from '~/services/api'
import { isKeyFilterChange } from '~/helpers/tables/isKeyFilterChange'
import { formatInitialFiltersWithUrl } from '~/helpers/tables/formatInitialFiltersWithUrl'
import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'

export const formatCellsWithFilterAndUrl = async ({ setShowCells, showCells, keysFilters, queryFilters }) => {
  const filters = await getFinancialMovementFiltersV4()

  if (filters && filters.data && filters.data.content) {
    const { courses, paymentMethod } = filters.data.content

    const cellsCoursesFilter =
      courses &&
      courses.map((item) => {
        return {
          label: `${item.id}-${item.name}`,
          id: item.id,
          active: true,
        }
      })

    const cellsPaymentMethodFilter =
      paymentMethod &&
      paymentMethod.map((item) => {
        return {
          label: getTranslattedProductType(item, true),
          id: item,
          active: true,
        }
      })

    const newShowCells = showCells.map((cell) => {
      if (isKeyFilterChange(keysFilters, cell.item)) {
        cell.isChanged = true
      }

      switch (cell.item) {
        case 'products':
          return formatInitialFiltersWithUrl({
            cell,
            origin: cellsCoursesFilter,
            queryFilters: queryFilters.products,
          })

        case 'paidWith':
          return formatInitialFiltersWithUrl({
            cell,
            origin: cellsPaymentMethodFilter,
            queryFilters: queryFilters.paidWith,
          })

        case 'expectedCompensationDate':
          return formatInitialFiltersWithUrl({
            cell,
            isDate: true,
            isBlankDate: true,
            queryFilters: {
              startDate: queryFilters.startExpectedCompensationDate,
              endDate: queryFilters.endExpectedCompensationDate,
            },
          })

        case 'compensationDate':
          return formatInitialFiltersWithUrl({
            cell,
            isDate: true,
            isBlankDate: true,
            queryFilters: {
              startDate: queryFilters.startCompensationDate,
              endDate: queryFilters.endCompensationDate,
            },
          })

        default:
          break
      }
      return cell
    })
    setShowCells(newShowCells)
  }
}
