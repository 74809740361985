import React from 'react'

import { formatValue } from '~/utils'
import { ProductLink } from '~/components/table/product-link'
import { WrapperText } from '../styles'
import { formatCoursesText } from '~/components/table/helpers/formatCoursesText'
import { StatusBox } from 'screens/financial-control-v2/components'
import { formatStatusBox } from 'screens/financial-control-v2/helpers/formatStatusBox'

export const formatResultTable = async ({ data, handleModal, setProductData }) => {
  const fallbackString = '---'
  return data.map((result) => {
    const {
      CPF,
      CreditRequestId,
      status,
      email,
      tuitionTransferDate,
      tuitionValue,
      tuitionNumber,
      totalOfTuition,
      name,
      phone,
      courses,
    } = result

    const formattedStatus = formatStatusBox(status)

    const ProductComponent = () => (
      <ProductLink target="product" handleModal={handleModal} courses={courses} setProductData={setProductData}>
        {courses.length > 1 ? (
          `${courses.length} produtos`
        ) : (
          <>
            <WrapperText>{formatCoursesText(courses[0])}</WrapperText>
          </>
        )}
      </ProductLink>
    )

    return {
      CreditRequestId: CreditRequestId ? CreditRequestId : fallbackString,
      status: status ? <StatusBox status={formattedStatus} isSmall /> : fallbackString,
      CPF: CPF ? CPF : fallbackString,
      name: name ? name : fallbackString,
      email: email ? email : fallbackString,
      phone: phone ? phone : fallbackString,
      products: courses && courses.length > 0 && <ProductComponent />,
      installmentNumber: tuitionNumber && totalOfTuition ? `${tuitionNumber} / ${totalOfTuition}` : fallbackString,
      tuitionValue: tuitionValue ? formatValue(tuitionValue) : fallbackString,
      tuitionTransferDate: tuitionTransferDate ? tuitionTransferDate : fallbackString,
    }
  })
}
