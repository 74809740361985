import React from 'react'
import { uniqueId } from 'lodash'

import { ExpandedTableRow, TableToolBar } from './components'

import {
  Table,
  TableBody,
  TableCell,
  StyledTableCell,
  TableContainer,
  TableHead,
  TableRow,
  StyledTableRow,
  Paper,
  useStyles,
  TableFooter,
} from './styles'

export const TableV2 = ({
  isMobile,
  SubComponent,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  rows,
  prepareRow,
  visibleColumns,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  pageCount,
  setPageSize,
  pageOptions,
  canNextPage,
  canPreviousPage,
  totalCount,
  testID,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} data-testid={testID}>
      <TableContainer className={classes.container}>
        <Table stickyHeader {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={uniqueId()} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell key={uniqueId()} {...column.getHeaderProps()}>
                    {column.canFilter ? column.render('Filter') : column.render('Header')}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {(page || rows).map((row) => {
              prepareRow(row)
              return (
                <>
                  <StyledTableRow key={uniqueId()} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <StyledTableCell key={uniqueId()} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableCell>
                      )
                    })}
                  </StyledTableRow>

                  {row.isExpanded ? (
                    <TableRow>
                      <StyledTableCell colSpan={visibleColumns.length}>
                        <ExpandedTableRow uniqueId={uniqueId} row={row} SubComponent={SubComponent} />
                      </StyledTableCell>
                    </TableRow>
                  ) : null}
                </>
              )
            })}
          </TableBody>
          {isMobile && page && (
            <TableFooter>
              <TableCell colSpan={visibleColumns.length}>
                <TableToolBar
                  paginationComponent={true}
                  page={page}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pageOptions={pageOptions}
                  canNextPage={canNextPage}
                  canPreviousPage={canPreviousPage}
                  totalCount={totalCount}
                />
              </TableCell>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  )
}
