import React from 'react'

export const ChargebackIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0001 12.9999H20.0001" stroke="#000B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0001 16.9999H20.0001" stroke="#000B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.7086 24.4854L16.8574 27.5708C16.7686 27.7189 16.6429 27.8414 16.4926 27.9265C16.3424 28.0116 16.1726 28.0563 15.9999 28.0563C15.8273 28.0563 15.6575 28.0116 15.5072 27.9265C15.357 27.8414 15.2313 27.7189 15.1424 27.5708L13.2912 24.4854C13.2024 24.3374 13.0767 24.2148 12.9264 24.1297C12.7762 24.0447 12.6064 23.9999 12.4337 23.9999H4.99994C4.73472 23.9999 4.48037 23.8946 4.29283 23.7071C4.1053 23.5195 3.99994 23.2652 3.99994 22.9999V7C3.99994 6.73478 4.1053 6.48043 4.29283 6.29289C4.48037 6.10536 4.73472 6 4.99994 6H26.9999C27.2652 6 27.5195 6.10536 27.707 6.29289C27.8946 6.48043 27.9999 6.73478 27.9999 7V23C27.9999 23.2652 27.8946 23.5196 27.707 23.7071C27.5195 23.8946 27.2652 24 26.9999 24L19.5661 23.9999C19.3935 23.9999 19.2237 24.0447 19.0734 24.1297C18.9232 24.2148 18.7975 24.3374 18.7086 24.4854V24.4854Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ChargebackNotificationIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 13H20" stroke="#01163E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 17H20" stroke="#01163E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.7087 24.4854L16.8575 27.5708C16.7686 27.7189 16.643 27.8414 16.4927 27.9265C16.3424 28.0116 16.1727 28.0563 16 28.0563C15.8273 28.0563 15.6576 28.0116 15.5073 27.9265C15.357 27.8414 15.2314 27.7189 15.1425 27.5708L13.2913 24.4854C13.2025 24.3374 13.0768 24.2148 12.9265 24.1297C12.7762 24.0447 12.6065 23.9999 12.4338 23.9999H5C4.73478 23.9999 4.48043 23.8946 4.29289 23.7071C4.10536 23.5195 4 23.2652 4 22.9999V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H27C27.2652 6 27.5196 6.10536 27.7071 6.29289C27.8946 6.48043 28 6.73478 28 7V23C28 23.2652 27.8946 23.5196 27.7071 23.7071C27.5196 23.8946 27.2652 24 27 24L19.5662 23.9999C19.3935 23.9999 19.2238 24.0447 19.0735 24.1297C18.9232 24.2148 18.7975 24.3374 18.7087 24.4854V24.4854Z"
      stroke="#01163E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="22" y="2" width="10" height="10" rx="5" fill="#DE0C4B" />
  </svg>
)
