import React, { useState, useContext } from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Button, showToast } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { useFormik } from 'formik'
import {
  ButtonWrapper,
  InputWrapper,
  RegularInput,
  FormWrapper,
  DropzoneArea,
  FormTitle,
  TextInputWrapper,
  InputError,
} from '../styles'
import { ValidationSchema, initialValues } from './form'
import { useDropzone } from 'react-dropzone'
import { ValidationMessages } from '~/enums'
import { useCertificateData } from './hooks'

/** @typedef {object} IProps */

/** @type {React.FC<IProps>}*/
export const Certificate = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [initalizeUpload] = useCertificateData(setIsLoading, showToast)
  const { touched, values, setFieldValue, handleSubmit, setFieldError, errors } = useFormik({
    validationSchema: ValidationSchema,
    initialValues,
    onSubmit: async (formData) => {
      await initalizeUpload(formData)
    },
  })

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: '.pfx,.p12',
    onDropAccepted: (files) => {
      const file = files[0]
      setFieldValue('file', file)
    },
    onDropRejected: () => {
      setFieldError('file', ValidationMessages.invalidFile)
    },
  })

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Preencha abaixo com os dados de cadastro da sua empresa:</FormTitle>
      <InputWrapper>
        <p>Faça upload do arquivo (formato .pfx ou .p12)</p>
        <DropzoneArea {...getRootProps()}>
          <input {...getInputProps()} />
          {values.file ? <p>Arquivo selecionado: {values.file?.path}</p> : <p>Arraste ou faça o upload do arquivo</p>}
        </DropzoneArea>
        {errors.file && <InputError>{errors.file}</InputError>}
      </InputWrapper>
      <TextInputWrapper shouldForceWidth={false}>
        <p>Digite abaixo a sua senha</p>
        <RegularInput
          placeholder="Digite aqui"
          value={values.password}
          type={passwordVisible ? 'text' : 'password'}
          onChange={(e) => setFieldValue('password', e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={() => setPasswordVisible((prev) => !prev)}>
                {!passwordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.password && touched.password && <InputError>{errors.password}</InputError>}
      </TextInputWrapper>
      <TextInputWrapper>
        <p>Digite abaixo um email para ser avisado do vencimento do certificado</p>
        <RegularInput placeholder="Digite aqui" value={values.email} onChange={(e) => setFieldValue('email', e.target.value)} />
        {errors.email && touched.email && <InputError>{errors.email}</InputError>}
      </TextInputWrapper>
      <ButtonWrapper>
        <Button icon text="Salvar alterações" onClick={handleSubmit} />
      </ButtonWrapper>
    </FormWrapper>
  )
}
