import { formatDate } from '~/utils'

export const mobileHeaders = [
  {
    name: 'status',
    data: 'Status',
  },
  {
    name: 'agency',
    data: 'Agência',
  },
  {
    name: 'accountNumber',
    data: 'Conta',
  },
]

export const headers = [
  {
    name: 'status',
    data: 'Status',
  },
  {
    name: 'createdBy',
    data: 'Atualizado por',
  },
  {
    name: 'name',
    data: 'Banco',
  },
  {
    name: 'type',
    data: 'Tipo',
  },
  {
    name: 'agency',
    data: 'Agência',
  },
  {
    name: 'accountNumber',
    data: 'Conta',
  },
  {
    name: 'date',
    data: 'Data de alteração',
  },
]

export const formatData = ({
  accountNumber,
  agency,
  createdBy,
  date: dateFromBE,
  name,
  status: statusType,
  type: accountType,
  default: defaultAccount,
}) => {
  let status, statusColor
  switch (statusType) {
    case 'validating':
    case 'waiting_validation':
      status = 'Em validação'
      statusColor = '#F9E27F'
      break

    case 'registered':
      status = defaultAccount ? 'Ativa' : 'Validada'
      statusColor = '#A8E771'

      break

    case 'inactive':
      status = 'Inativa'
      statusColor = '#FF7C7C'
      break

    case 'rejected':
      status = 'Rejeitada'
      statusColor = '#AEAEAE'
      break
  }

  let type = accountType === 'savings' ? 'Conta Poupança' : 'Conta Corrente'

  let date = formatDate(dateFromBE)

  return { accountNumber, agency, createdBy, date, name, status, statusColor, type }
}

export const formatRows = (rows) => {
  let formattedRows = []
  rows.forEach((eachRowObject) => {
    formattedRows.push(formatData(eachRowObject))
  })
  return formattedRows
}
