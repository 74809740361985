import React from 'react'
import { ActionsContainer } from '../../styles'
import { ArrowLeft, ArrowRight, PlusIcon } from 'assets/svgs/v2'

import { Button } from 'components/v3'

function ActionsContainerComponent({
  goToPageProp: goToPage,
  isMobileProp: isMobile,
  hasArrows = false,
  swiperRefProp: swiperRef = null,
  courseKey,
  setCourseClassCopy,
}) {
  return (
    <ActionsContainer>
      <Button
        icon={<PlusIcon color={'white'} />}
        text="Adicionar turma"
        mobileWidth={'fit-content'}
        onClick={() => {
          setCourseClassCopy(null)
          goToPage(`cursos/${courseKey}/nova-turma`)
        }}
      />
      {!isMobile && hasArrows && (
        <div style={{ display: 'flex', gap: '1.5rem' }}>
          <ArrowLeft onClick={() => swiperRef.current.swiper.slidePrev()} />
          <ArrowRight onClick={() => swiperRef.current.swiper.slideNext()} />
        </div>
      )}
    </ActionsContainer>
  )
}

export { ActionsContainerComponent }
