import { useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { getProviLogin } from '~/services/api'
import { useQuery } from '~/hooks'
import { handleLocalStorage } from '~/utils'

export const useProviLogin = ({ setIsLoading, goToPage }) => {
  const query = useQuery()

  const getData = async (token) => {
    const apiResponse = await getProviLogin(token)
    const { data } = apiResponse
    return data
  }

  const setLogin = async ({ email, partner, id, token }) => {
    const { user } = await handleLocalStorage({
      token,
      email,
      partnerName: partner.name,
      partnerSlug: partner.slug,
      partnerId: partner.id,
      userId: id,
    })
    goToPage('home', user)
  }

  useEffect(() => {
    const doLogin = async () => {
      try {
        setIsLoading(true)
        const token = query.get('token')
        const { email, id, partner } = await getData(token)
        setLogin({ id: id, email: email, partner: partner, token })

        return {}
      } catch (error) {
        setIsLoading(false)
        showToast('Algo de errado aconteceu! :( Por favor tente novamente')
        goToPage('login')
      }
    }
    doLogin()
  }, [])

  return {}
}

// import { useEffect, useContext } from 'react'
// import { showToast } from '@provi/provi-components'
// import { getProviLogin } from '~/services/api'
// import { useQuery } from '~/hooks'
// import { handleLocalStorage } from '~/utils'
// import { history } from '~/navigation/history'
// import { NavigationContext } from '~/router/context'

// export const useProviLogin = () => {
//   const { setIsLoading, setCurrentUser } = useContext(NavigationContext)
//   const query = useQuery()

//   const getData = async (token) => {
//     const apiResponse = await getProviLogin(token)
//     const { data } = apiResponse
//     return data
//   }

//   const setLogin = async ({ email, partner, id, token }) => {
//     const { user } = await handleLocalStorage({
//       token,
//       email,
//       partnerName: partner.name,
//       partnerSlug: partner.slug,
//       partnerId: partner.id,
//       userId: id,
//     })
//     history.replace(`/home?u=${user}`)
//     setCurrentUser(user)
//   }

//   useEffect(() => {
//     const doLogin = async () => {
//       try {
//         setIsLoading(true)
//         const token = query.get('token')
//         const { email, id, partner } = await getData(token)
//         setLogin({ id: id, email: email, partner: partner, token })

//         return {}
//       } catch (error) {
//         showToast('Algo de errado aconteceu! :( Por favor tente novamente')
//       }
//     }
//     doLogin()
//   }, [query, setIsLoading, setLogin])

//   return {}
// }
