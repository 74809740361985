import React from 'react'

import { getTranslattedProductType } from '~/helpers/getTranslattedProductType'
import { formatValue, formatPercentageInteger, parseThenFormatDate } from '~/utils'
import { ProductLink } from '~/components/table/product-link'
import { WrapperText, WrapperFitWidth } from '../styles'
import { formatCoursesText } from '~/components/table/helpers/formatCoursesText'

export const formatResultTable = async ({ data, handleModal, setProductData }) => {
  const fallbackString = '---'
  return data.map((result) => {
    const {
      cpf,
      crid,
      dueDate,
      email,
      expectedTransferDate,
      installmentType,
      installmentValue,
      name,
      paidAt,
      paidWith,
      phone,
      receivedValue,
      studentPaidValue,
      transferDate,
      madeEffectiveDate,
      courses,
      commission,
    } = result

    const ProductComponent = () => (
      <ProductLink target="product" handleModal={handleModal} courses={courses} setProductData={setProductData}>
        {courses.length > 1 ? (
          `${courses.length} produtos`
        ) : (
          <>
            <WrapperText>{formatCoursesText(courses[0])}</WrapperText>
          </>
        )}
      </ProductLink>
    )

    return {
      id: crid ? crid : fallbackString,
      cpf: cpf ? cpf : fallbackString,
      name: name ? name : fallbackString,
      email: email ? email : fallbackString,
      phone: phone ? phone : fallbackString,
      products: courses && courses.length > 0 && <ProductComponent />,
      installmentType:
        installmentType && installmentType.length > 30 ? (
          <WrapperText underline onClick={() => handleModal('simple-modal', installmentType)}>
            {installmentType}
          </WrapperText>
        ) : (
          installmentType
        ),
      paidWith: paidWith ? getTranslattedProductType(paidWith) : fallbackString,
      installmentValue: installmentValue ? formatValue(installmentValue) : fallbackString,
      userPaidValue: studentPaidValue ? formatValue(studentPaidValue) : fallbackString,
      proviComission: commission?.value ? formatValue(commission?.value) : fallbackString,
      proviPercentComission: commission?.percentage ? formatPercentageInteger(commission?.percentage) : fallbackString,
      receivedValue: receivedValue ? formatValue(receivedValue) : fallbackString,
      dueDate: dueDate ? parseThenFormatDate(dueDate) : fallbackString,
      userPaymentDate: paidAt ? parseThenFormatDate(paidAt) : fallbackString,
      madeEffectiveDate: madeEffectiveDate ? parseThenFormatDate(madeEffectiveDate) : fallbackString,
      expectedCompensationDate: expectedTransferDate ? parseThenFormatDate(expectedTransferDate) : fallbackString,
      compensationDate: transferDate ? <WrapperFitWidth>{parseThenFormatDate(transferDate)}</WrapperFitWidth> : fallbackString,
    }
  })
}
