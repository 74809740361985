import React from 'react'
import { isPast, format } from 'date-fns'
import {
  TimeLineContainer,
  WrappingGrid,
  FlexContainer,
  TextGrid,
  BlueText,
  BlackText,
  TimeLineFlowGrid,
  FlowContainer,
  Circle,
  Line,
} from './styles'
import { TimelineCircleCheck, EditIcon } from '~/assets/svgs/v2'
import masks from '~/themes/masks'
import { formatPaymentMethods, findLowestValue } from '../../../../helpers'

const NonArrayVariants = ({ formObject }) => {
  const { launchStartsAt, launchEndsAt, canCheckoutUntil, launchPricing } = formObject || {}

  const arrToMap = [
    {
      title: 'Abertura do carrinho',
      date: launchStartsAt,
      price:
        launchPricing?.options[0]?.uniquePriceInCents ||
        findLowestValue(launchPricing.options.map((eachObj) => eachObj.priceInCents)),
      paymentMethods:
        launchPricing.type === 'paymentMethod'
          ? launchPricing.options.map((eachObj) => formatPaymentMethods(eachObj.paymentMethod))
          : launchPricing.options[0].uniquePaymentMethods.map((eachString) => formatPaymentMethods(eachString)),
    },
    {
      title: 'Fechamento do carrinho',
      date: launchEndsAt,
    },
    {
      title: 'Data limite para efetivação',
      date: canCheckoutUntil,
    },
  ]

  return (
    <>
      {arrToMap.map((eachObject, index, mappedArr) => (
        <>
          <FlowContainer>
            {eachObject?.date && isPast(new Date(eachObject?.date)) ? <TimelineCircleCheck /> : <Circle />}
            {index === mappedArr.length - 1 ? null : <Line isPast={eachObject?.date && isPast(new Date(eachObject?.date))} />}
          </FlowContainer>
          <TextGrid isFromSteps>
            <FlexContainer>
              <BlueText isStepTitle>{eachObject.title}</BlueText>
              <EditIcon />
            </FlexContainer>

            {index === 0 && (
              <BlueText isStepTitle>
                {launchPricing?.type === 'paymentMethod'
                  ? `(a partir de ${eachObject?.price && masks.currency(eachObject?.price)})`
                  : `(${eachObject?.price && masks.currency(eachObject?.price)})`}
              </BlueText>
            )}

            <BlackText isFromStepsBig>{eachObject?.date && format(new Date(eachObject?.date), "dd/MM/yyyy' 'HH:mm")}</BlackText>

            {index === 0 && (
              <BlackText>
                Método de pagamento:
                <br />
                {eachObject?.paymentMethods?.map((eachString, idx) => (idx ? ' + ' : '') + eachString)}
              </BlackText>
            )}
          </TextGrid>
        </>
      ))}
    </>
  )
}

const ArrVariants = ({ formObject }) => {
  const { canCheckoutUntil, launchPricing } = formObject || {}
  const { options = [] } = launchPricing
  return (
    <>
      {options.map((eachObject, index, mappedArr) => (
        <>
          <FlowContainer>
            {eachObject?.startsAt && isPast(new Date(eachObject?.startsAt)) ? <TimelineCircleCheck /> : <Circle />}
            <Line isPast={eachObject?.startsAt && isPast(new Date(eachObject?.startsAt))} />
          </FlowContainer>
          <TextGrid isFromSteps>
            <FlexContainer>
              <BlueText isStepTitle>{`Início do ${index + 1}º lote`}</BlueText>
              <EditIcon />
            </FlexContainer>
            <BlueText isStepTitle>{`(${masks.currency(eachObject?.priceInCents)})`}</BlueText>
            {index === 0 && <BlackText isFromStepsBig>Abertura do carrinho</BlackText>}
            <BlackText isFromStepsBig>
              {eachObject?.startsAt && format(new Date(eachObject?.startsAt), "dd/MM/yyyy' 'HH:mm")}
            </BlackText>
            <BlackText>
              Método de pagamento:
              <br />
              {eachObject?.paymentMethods.map((eachString, idx) => (idx ? ' + ' : '') + formatPaymentMethods(eachString))}
            </BlackText>
          </TextGrid>
          <FlowContainer>
            {eachObject?.endsAt && isPast(new Date(eachObject?.endsAt)) ? <TimelineCircleCheck /> : <Circle />}
            <Line isPast={eachObject?.endsAt && isPast(new Date(eachObject?.endsAt))} />
          </FlowContainer>
          <TextGrid isFromSteps>
            <FlexContainer>
              <BlueText isStepTitle>{`Fim do ${index + 1}º lote`}</BlueText>
              <EditIcon />
            </FlexContainer>
            {index === mappedArr.length - 1 && <BlackText isFromStepsBig>Fechamento do carrinho</BlackText>}
            <BlackText isFromStepsBig>
              {eachObject?.endsAt && format(new Date(eachObject?.endsAt), "dd/MM/yyyy' 'HH:mm")}
            </BlackText>
          </TextGrid>
        </>
      ))}
      <FlowContainer>
        {canCheckoutUntil && isPast(new Date(canCheckoutUntil)) ? <TimelineCircleCheck /> : <Circle />}
      </FlowContainer>
      <TextGrid isFromSteps>
        <FlexContainer>
          <BlueText isStepTitle>Data limite para efetivação</BlueText>
          <EditIcon />
        </FlexContainer>
        <BlackText isFromStepsBig>{canCheckoutUntil && format(new Date(canCheckoutUntil), "dd/MM/yyyy' 'HH:mm")}</BlackText>
      </TextGrid>
    </>
  )
}

export const TimeLine = ({ blueTitle, optionalExplainer, formObject }) => {
  return (
    <TimeLineContainer>
      <WrappingGrid>
        <TextGrid>
          <BlueText isTimeLineTitle>{blueTitle}</BlueText>
          {optionalExplainer && <BlackText isFromExplainer>{optionalExplainer}</BlackText>}
        </TextGrid>
        <TimeLineFlowGrid>
          <>
            {formObject.launchPricing.type === 'launchSteps' ? (
              <ArrVariants formObject={formObject} />
            ) : (
              <NonArrayVariants formObject={formObject} />
            )}
          </>
        </TimeLineFlowGrid>
      </WrappingGrid>
    </TimeLineContainer>
  )
}
