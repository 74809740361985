import React from 'react'

export const ArrowRight = ({ onClick, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 8L18.75 8M12.5 1L19.5 8L12.5 15.5"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
