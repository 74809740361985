import React from 'react'

export const UploadLogo = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M17.5 17.5C19.433 17.5 21 15.933 21 14C21 12.067 19.433 10.5 17.5 10.5C15.567 10.5 14 12.067 14 14C14 15.933 15.567 17.5 17.5 17.5Z"
        fill="white"
      />
      <path
        d="M41.7058 34.0293C41.0563 33.0552 39.4431 33.0552 38.7937 34.0293L35.2937 39.2793C34.9365 39.8159 34.9023 40.5064 35.2065 41.0754C35.5107 41.6445 36.1037 42 36.7497 42H38.4997V45.5C38.4997 46.4664 39.2824 47.25 40.2497 47.25C41.217 47.25 41.9997 46.4664 41.9997 45.5V42H43.7497C44.3957 42 44.9887 41.6445 45.2929 41.0754C45.597 40.5064 45.5629 39.8159 45.2057 39.2793L41.7058 34.0293Z"
        fill="white"
      />
      <path
        d="M52.5 0H3.5C1.56712 0 0 1.56712 0 3.5V38.5C0 40.4329 1.56712 42 3.5 42H24.6034C25.4775 49.8626 32.157 56 40.25 56C48.935 56 56 48.9342 56 40.25V38.5V3.5C56 1.56712 54.4329 0 52.5 0ZM22.1382 23.1713C21.5212 22.6424 20.6206 22.6082 19.9643 23.0884L7 32.5959V7H49V26.25C49 26.25 42.0581 18.9503 39.8672 16.4071C39.5259 16.011 39.0486 15.7577 38.5256 15.75C38.0231 15.7388 37.5036 15.9679 37.1669 16.367L27.4872 27.7548L22.1382 23.1713ZM52.3608 42C51.5097 47.9345 46.4203 52.5 40.25 52.5C34.0797 52.5 28.9904 47.9345 28.1392 42C28.0572 41.4271 28 40.8455 28 40.25C28 33.4846 33.4842 28 40.25 28C47.0158 28 52.5 33.4846 52.5 40.25C52.5 40.8455 52.4428 41.4271 52.3608 42Z"
        fill="white"
      />
    </svg>
  )
}
