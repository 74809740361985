import React from 'react'

export const RecordedClasses = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6389 9.35898L2.1411 1.11004C2.02741 1.04056 1.89726 1.00263 1.76404 1.00013C1.63083 0.997637 1.49935 1.03068 1.38314 1.09585C1.26692 1.16103 1.17017 1.25598 1.10283 1.37095C1.0355 1.48592 1 1.61676 1 1.75V18.2479C1 18.3811 1.0355 18.5119 1.10283 18.6269C1.17017 18.7419 1.26692 18.8368 1.38314 18.902C1.49935 18.9672 1.63083 19.0002 1.76404 18.9977C1.89726 18.9952 2.02741 18.9573 2.1411 18.8878L15.6389 10.6389C15.7485 10.5719 15.8391 10.4779 15.902 10.3658C15.9648 10.2537 15.9978 10.1274 15.9978 9.99893C15.9978 9.87045 15.9648 9.74413 15.902 9.63207C15.8391 9.52001 15.7485 9.42598 15.6389 9.35898Z"
      stroke={color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
