import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  margin-top: 81px;
  height: calc(100vh - 81px);

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 44px;
  }

  @media (min-width: 900px) {
    ${({ drawerWidth }) =>
      drawerWidth === 240 &&
      css`
        width: 100%;
        padding-top: 44px;
      `}
  }

  @media (min-width: 1080px) {
    width: 100%;
    padding-top: 0;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 245px;
    height: calc(100% - 245px);
  }

  @media (max-width: 360px) {
    width: 100%;
    margin-top: 245px;
    height: calc(100% - 245px);
  }
`

export const ContainerTable = styled.div`
  height: 100%;
  width: 100vw;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const WrapperText = styled.div`
  display: inline-block;
  max-width: 200px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const WrapperFitWidth = styled.div`
  min-width: 300px;
`
