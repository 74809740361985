import { useContext, useRef, useState, useMemo, useCallback } from 'react'
import { queryClient } from '~/stores/react-query'
import { showToast } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { IntegrationsContext } from '~/screens/integrations/context'
import {
  useFetchMemberkitConnections,
  useFetchMemberkitConnectionsFilter,
  useUnlinkAccountMutation,
  IntegrationsQueryKeys,
} from '~/screens/integrations/requests'

export const useManageAccount = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const { currentIntegrationId, currentIntegrationName, setFlowState, setProductsToEdit } = useContext(IntegrationsContext)
  const [filters, setFilters] = useState([])
  const [page, setPage] = useState(1)
  const modalRef = useRef()
  const modalRefConfirmation = useRef()

  const extraConnectionsAndFiltersConfig = useMemo(
    () => ({
      enabled: !!currentIntegrationId,
      onError: (err) => {
        console.warn(err)
      },
    }),
    [currentIntegrationId],
  )
  const { data: memberkitConnections, isFetching: isLoadingConnections } = useFetchMemberkitConnections(
    currentIntegrationId,
    { page, filters },
    extraConnectionsAndFiltersConfig,
  )

  const { data: connectionsFilters, isFetching: isLoadingFilters } = useFetchMemberkitConnectionsFilter(
    currentIntegrationId,
    extraConnectionsAndFiltersConfig,
  )

  const extraUnlinkAccountConfig = useMemo(
    () => ({
      onMutate: () => {
        setIsLoading(true)
      },
      onError: () => {
        setIsLoading(false)
        showToast('Algo de errado aconteceu 😔 Por favor, tente novamente.')
      },
      onSuccess: () => {
        queryClient.invalidateQueries(IntegrationsQueryKeys.fetchMemberkitIntegrations)
        setIsLoading(false)
        showToast('Conta desvinculada com sucesso! 😀')
        setFlowState('Memberkit')
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }),
    [currentIntegrationId, setIsLoading, setFlowState, currentIntegrationId],
  )

  const { mutate } = useUnlinkAccountMutation(extraUnlinkAccountConfig)

  const nextPage = useCallback(() => {
    setPage((previousState) => previousState + 1)
  }, [])

  const previousPage = useCallback(() => {
    page !== 1 && setPage((previousState) => previousState - 1)
  }, [page])

  const openModal = () => {
    modalRef.current.openModalFn()
  }

  const openModalConfirmation = () => {
    modalRefConfirmation.current.openModalFn()
  }

  const closeModal = () => {
    modalRef.current.closeModalFn()
  }

  const closeModalConfirmation = () => {
    modalRefConfirmation.current.closeModalFn()
  }
  const handleConnectProducts = useCallback(() => {
    setProductsToEdit(null)
    setFlowState('Conectar produtos')
  }, [setFlowState, setProductsToEdit])

  const handleUnlinkAccount = useCallback(() => {
    mutate(currentIntegrationId)
  }, [currentIntegrationId, mutate])

  const formatBackendResponse = useCallback((beConnections) => {
    let newObj = []

    beConnections.forEach((item) => {
      const itemAlreadyExists = newObj.find((i) => i.CourseClassId === item.CourseClassId)

      if (item.type === 'membership_level' || !itemAlreadyExists) {
        newObj.push({
          ...item,
          MemberKitProductId: [item.MemberKitProductId],
          memberkitProductName: [item.memberkitProductName],
          memberkitProductIds: [item._id],
        })

        return
      }

      itemAlreadyExists.MemberKitProductId = [...itemAlreadyExists.MemberKitProductId, item.MemberKitProductId]
      itemAlreadyExists.memberkitProductName = [...itemAlreadyExists.memberkitProductName, item.memberkitProductName]
      itemAlreadyExists.memberkitProductIds = [...itemAlreadyExists.memberkitProductIds, item._id]

      newObj = [...newObj.filter((i) => i.CourseClassId !== itemAlreadyExists.CourseClassId), itemAlreadyExists]
    })

    return newObj
  }, [])

  return {
    isMobile,
    modalRef,
    modalRefConfirmation,
    openModal,
    openModalConfirmation,
    closeModal,
    closeModalConfirmation,
    memberkitConnections,
    isLoadingConnections,
    connectionsFilters,
    isLoadingFilters,
    page,
    nextPage,
    previousPage,
    filters,
    setFilters,
    currentIntegrationName,
    handleConnectProducts,
    handleUnlinkAccount,
    formatBackendResponse,
  }
}
