import React from 'react'
import { useReceivables } from './hooks'
import { FinancialFlowCard, SmallCardsRow } from './components'
import { DashboardSelect, DashboardDatePicker } from '~/screens/dashboard/components'
import { dateOptions } from '~/screens/dashboard/helpers'
import { ReceivablesGrid, Header, Divider, FlexContainer, FlexChilds } from './styles'

export const Receivables = ({ filters }) => {
  const {
    isMobile,
    goToPage,
    receivablesData,
    dateOptionsState,
    setDateOptionsState,
    dateRange,
    setDateRange,
    handleCalendarFilter,
    labelDateButton,
    chartLoadingState,
  } = useReceivables(filters)

  return (
    <ReceivablesGrid>
      <FlexContainer>
        <FlexChilds>
          <Header isSmall>Recebimentos</Header>
          <Header isLink onClick={() => goToPage('checkout/controle-recebimentos')}>
            Acessar detalhes
          </Header>
        </FlexChilds>
        <FlexChilds>
          <DashboardSelect
            placeholderText="Tipo de data"
            selectOptions={dateOptions}
            isMobile={isMobile}
            valueState={dateOptionsState}
            valueChangeFn={setDateOptionsState}
          />
          <DashboardDatePicker
            handleCalendarFilter={handleCalendarFilter}
            dateRangeState={dateRange}
            setDateRangeFn={setDateRange}
            isMobile={isMobile}
            labelDateButton={labelDateButton}
          />
        </FlexChilds>
      </FlexContainer>

      <Divider />

      <SmallCardsRow receivablesData={receivablesData} chartLoadingState={chartLoadingState} />
      <Header isBluePrimary>Valores por forma de pagamento</Header>
      <FinancialFlowCard receivablesData={receivablesData} chartLoadingState={chartLoadingState} />
    </ReceivablesGrid>
  )
}
