/**
 *
 * @param {float} value
 * @returns {float}
 */
function formatValueToFloatWith2Digits(value) {
  return parseFloat(value.toFixed(2), 2)
}

module.exports = {
  formatValueToFloatWith2Digits,
}
