import React from 'react'
import { useCollectionsChart } from './hooks'
import { CollectionsChartGrid, ChartWrapper, ChartHeader, ChartText } from './styles'
import { ChartLegendsList } from '~/screens/dashboard/components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Line, LabelList } from 'recharts'

export const CollectionsChart = ({ filters }) => {
  const { chartData, isMobile, chartLoadingState } = useCollectionsChart(filters)

  return (
    <CollectionsChartGrid>
      <ChartHeader>Contatos de cobrança</ChartHeader>
      <ChartText>
        Este gráfico mostra o número de contatos realizados pela equipe de cobrança, comparados à quantidade de alunos que
        pagaram com atraso durante o mês. Os contatos incluem mensagens de prevenção à inadimplência, de cobrança, proposta de
        acordo e negativação.
      </ChartText>
      <ChartLegendsList legendArr="collectionsChart" />
      <ChartWrapper>
        <LoadingSkeleton isLoading={chartLoadingState} repeat={2} containerHeight="150px" hasHeading hasParagraph>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={isMobile ? chartData.slice(Math.max(chartData.length - 3, 0)) : chartData}
              margin={{ top: 20, right: 40, bottom: 20, left: 20 }}
              barGap={isMobile ? 10 : 4}
            >
              <CartesianGrid stroke="#DCDCDC" vertical={false} />
              <XAxis
                dataKey="date"
                scale="band"
                label={{ value: 'Mês', dy: 30, fill: '#575757' }}
                tickLine={false}
                tickMargin={5}
                interval={0}
              />
              <YAxis
                hide={isMobile ? true : false}
                yAxisId="left"
                axisLine={false}
                tickLine={false}
                label={{
                  value: 'Contatos realizados',
                  angle: -90,
                  position: 'left',
                  dy: -50,
                  fill: '#575757',
                }}
              />
              <YAxis
                hide={isMobile ? true : false}
                yAxisId="right"
                orientation="right"
                axisLine={false}
                tickLine={false}
                label={{
                  value: 'Pagamentos recebidos com atraso',
                  angle: 90,
                  position: 'insideBottomRight',
                  fill: '#575757',
                }}
              />
              <Tooltip />
              <Bar yAxisId="left" name="E-mails" dataKey="emails" maxBarSize={10} fill="#A4C7FB" isAnimationActive={false}>
                {isMobile && <LabelList dataKey="emails" position="top" style={{ fill: '#A4C7FB' }} />}
              </Bar>
              <Bar yAxisId="left" name="SMS" dataKey="sms" maxBarSize={10} fill="#FF5776" isAnimationActive={false}>
                {isMobile && <LabelList dataKey="sms" position="top" style={{ fill: '#FF5776' }} />}
              </Bar>
              <Bar yAxisId="left" name="WhatsApp" dataKey="whatsApp" maxBarSize={10} fill="#2647D7" isAnimationActive={false}>
                {isMobile && <LabelList dataKey="whatsApp" position="top" style={{ fill: '#2647D7' }} />}
              </Bar>
              <Bar yAxisId="left" name="Ligações" dataKey="phone" maxBarSize={10} fill="#CFCFCF" isAnimationActive={false}>
                {isMobile && <LabelList dataKey="phone" position="top" style={{ fill: '#CFCFCF' }} />}
              </Bar>
              <Line
                yAxisId="right"
                name="Pagamentos recebidos"
                dataKey="delayedPaymentRate"
                stroke="#DE0C4B"
                strokeWidth={2}
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </LoadingSkeleton>
      </ChartWrapper>
    </CollectionsChartGrid>
  )
}
