import { getActiveUser } from '~/utils'

export const clearAndReload = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const user = urlParams.get('u') || getActiveUser()

  const urlWithoutQueryParams = window.location.href.split('?')[0]
  const url = `${urlWithoutQueryParams}?u=${user}`
  window.history.pushState(null, null, url)

  window.location.reload()
}
