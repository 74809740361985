import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/themes/fonts'

/** @type {import('styled-components').StyledComponent<"div", any, { active: boolean }>} */
export const Option = styled.div`
  width: 205px;
  height: 40px;
  background-color: ${(props) => (props.active ? theme.colors.blue900 : theme.colors.white100)};
  border-radius: 5px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  span {
    color: ${(props) => (props.active ? theme.colors.white100 : theme.colors.blue900)};
    font-family: ${fonts.century.bold};
    font-size: 16px;
  }
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 432px;
  min-width: 432px;
  width: 100%;
  p {
    color: ${theme.colors.blue900};
    font-family: ${fonts.century.bold};
    font-size: 16px;
  }
  section {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
