export const formatMemberkitTableType = (string) => {
  const memberkitTableTypeFormatter = {
    classroom: 'Turma',
    membership_level: 'Assinatura',
    fallback: '---',
  }
  return memberkitTableTypeFormatter[string] || memberkitTableTypeFormatter.fallback
}

export const formatReactSelectOptions = (arrFromBE) => {
  return arrFromBE.map((eachObject) => ({
    value: eachObject.id,
    label: `${eachObject.id} - ${eachObject.name}`,
    ...(eachObject?.courseClasses && { courseClasses: eachObject.courseClasses }),
  }))
}
