import { useContext, useState, useEffect } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { getProviPayVersion } from '~/screens/courses/requests'
import { toast } from 'react-toastify'

export const useCourses = () => {
  const { isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const [showNotification, setShowNotification] = useState(false)

  const verifyProviPayVersion = async () => {
    try {
      const response = await getProviPayVersion()
      if (response.status > 199 && response.status < 300) {
        setShowNotification(!response.data.content.useV3)
      }
    } catch (error) {
      toast('Algo de errado aconteceu buscando as informações do ProviPay 😔 Por favor, tente novamente.')
    }
  }

  useEffect(() => {
    verifyProviPayVersion()
  }, [])

  return {
    isMobile,
    drawerWidth,
    showNotification,
  }
}
