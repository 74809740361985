export const handleToogleActiveFilters = (setShowCells, { item, showCells, cell }) => {
  if (!(showCells && showCells.length > 0)) return false

  const newCells = showCells.map((showCell) => {
    if (showCell.selectValues && showCell.selectValues.length && showCell.item === cell.item) {
      const findItem = showCell.selectValues.find((filterItem) => filterItem.id === item.id)
      if (findItem) {
        showCell.isChanged = true
        findItem.active = !findItem.active
      }
    }
    return showCell
  })

  setShowCells(newCells)
}
