import React from 'react'

export const ArrowDown = ({ width = 10, height = 7 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.25 1.75L4.75 5.25L8.25 1.75" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const ArrowDownWithBorder = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="#DE0C4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 11L11 15L15 11" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 7V15" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
