export const getTranslattedProductType = (productType, isFilter = false) => {
  const productTypeOptions = {
    MAAS: 'Crédito Educacional Dinâmico (MaaS)',
    CourseFinancing: 'Crédito Educacional Antecipado',
    PersonalLoan: 'Empréstimo pessoal',
    ISA: 'ISA',
    ISAAAS: 'ISAaaS',
    Allowance: 'Mesada',
    ProviPay: 'ProviPay',
    CreditCard: 'Cartão de crédito',
    Boleto: 'Boleto à vista',
    TED: 'Transferência Bancária',
    tax_compensation_transfer: 'Compensação de Taxas',
    Pix: 'Pix',
    PIX: 'PIX',
    GuaranteedTuition: 'Mensalidade Garantida',
  }

  const additionalFilters = {
    null: 'Em branco',
  }

  if (isFilter) {
    return productTypeOptions[productType] || additionalFilters[productType] || `---`
  }

  return productTypeOptions[productType] || `---`
}
