import { useState } from 'react'

export const useOrigin = () => {
  // popover
  const [anchorEl, setAnchorEl] = useState()
  const [anchor, setAnchor] = useState(null)

  const handlePopover = (event, target) => {
    setAnchorEl(event.currentTarget)
    setAnchor(target)
  }

  const handleClose = () => {
    setAnchor(null)
    setAnchorEl(null)
  }

  return [handlePopover, handleClose, anchorEl, anchor]
}
