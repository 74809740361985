import React, { useState, useEffect, useContext, useCallback } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { useFormikContext } from 'formik'
import { showToast } from '@provi/provi-components'
import { getStorage } from '~/utils'
import { useQuery, useMutation } from 'react-query'
import { queryClient } from '~/stores/react-query'
import { getProfileGeneralData, postProfileGeneralData, deleteProfileGeneralDataAddress } from '~/services/api'
import cep from 'cep-promise'

export const useGeneralDataTab = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const [showModal, setShowModal] = useState(null)
  const [showFields, setShowFields] = useState(-1)
  const [cropperPhoto, setCropperPhoto] = useState(null)
  const [initialValuesState, setInitialValuesState] = useState({
    campus: [
      {
        name: '',
        address: { zipcode: '', state: '', city: '', district: '', street: '', number: '', complement: '' },
      },
    ],
    partner: {
      photoURL: '',
      daysToReleaseAccess: '',
      exemptionDays: '',
      purchaseURL: '',
    },
  })

  const partnerName = getStorage({ key: 'partner-name' })

  const { data: generalData } = useQuery(`${partnerName}-generalData`, getProfileGeneralData, {
    staleTime: 300000,
    onError: () => {
      showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  const greyTextColor = '#757575'

  const flexibilityOptions = [
    { id: 'flex_0', label: 'não flexibiliza' },
    { id: 'flex_1', label: 'flexibiliza casos extremos' },
    { id: 'flex_2', label: 'flexibiliza alguns casos' },
  ]

  const deadlineOptions = [
    { value: 'days', label: 'Dias' },
    { value: 'months', label: 'Meses' },
  ]

  const ObligatoryInput = () => <span style={{ color: 'red' }}> *</span>

  const handleFieldOpen = (index) => {
    setShowFields(index)
  }

  const handleModalOpen = (index) => {
    setShowModal(index)
  }

  const handleModalClose = () => {
    setShowModal(null)
  }

  const handleDelete = (removeFunction, index, eachAddressObject) => {
    removeFunction(index)
    setShowModal(null)
    if (eachAddressObject.id) {
      deleteMutation.mutateAsync({ campusId: eachAddressObject.id })
    }
  }

  useEffect(() => {
    if (generalData) {
      setCropperPhoto(generalData?.data?.partner?.photoURL)
    }
  }, [generalData])

  useEffect(() => {
    if (generalData) {
      let campusDataObjArr = [
        {
          name: '',
          address: { zipcode: '', state: '', city: '', district: '', street: '', number: '', complement: '' },
        },
      ]

      let partnerDataObj = {
        photoURL: cropperPhoto,
        daysToReleaseAccess: generalData.data.partner.daysToReleaseAccess,
        exemptionDays: generalData.data.partner.exemptionDays,
        purchaseURL: generalData.data.partner.purchaseURL,
      }

      if (generalData.data.campus.length) {
        campusDataObjArr = []
        generalData.data.campus.map((eachObject) => {
          campusDataObjArr.push({
            id: eachObject.id,
            name: eachObject.name,
            address: {
              zipcode: eachObject.Address.zipcode,
              state: eachObject.Address.state,
              city: eachObject.Address.city,
              district: eachObject.Address.district,
              street: eachObject.Address.street,
              number: eachObject.Address.number,
              complement: eachObject.Address.complement,
            },
          })
        })
      }

      if ('cancelationPolicyFlexibilization' in generalData.data.partner) {
        partnerDataObj = {
          ...partnerDataObj,
          cancelationPolicyFlexibilization: generalData.data.partner.cancelationPolicyFlexibilization,
        }
      }

      setInitialValuesState({
        ...initialValuesState,
        campus: campusDataObjArr,
        partner: partnerDataObj,
      })
    }
  }, [generalData, cropperPhoto])

  const autoFillCep = useCallback(async (zipcode, setFieldFn, index) => {
    try {
      setIsLoading(true)
      const cepData = await cep(zipcode)
      if (cepData) {
        setFieldFn(`campus.${index}.address.street`, cepData?.street)
        setFieldFn(`campus.${index}.address.state`, cepData?.state)
        setFieldFn(`campus.${index}.address.city`, cepData?.city)
        setFieldFn(`campus.${index}.address.district`, cepData?.neighborhood)
      }
    } catch (error) {
      showToast('Não foi possível encontrar o seu CEP 😢')
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const AutoFillAddress = () => {
    const { values, setFieldValue } = useFormikContext()
    useEffect(() => {
      values.campus.forEach((campus, index) => {
        if (campus.address.zipcode.length !== 8 && campus.address.state) {
          setFieldValue(`campus.${index}.address.street`, '')
          setFieldValue(`campus.${index}.address.state`, '')
          setFieldValue(`campus.${index}.address.city`, '')
          setFieldValue(`campus.${index}.address.district`, '')
        }
        if (campus.address.zipcode.length === 8 && !campus.address.state) {
          autoFillCep(campus.address.zipcode, setFieldValue, index)
          setShowFields(index)
        }
      })
    }, [values.campus])
    return null
  }

  const postMutation = useMutation(postProfileGeneralData, {
    onMutate: () => {
      setIsLoading(true)
    },
    onError: () => {
      showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
      setIsLoading(false)
    },
    onSuccess: (data) => {
      const { data: response } = data
      setShowFields(-1)
      setIsLoading(false)
      if (response?.updatedCampus || response?.updatedPartner) {
        queryClient.invalidateQueries(`${partnerName}-generalData`)
        queryClient.invalidateQueries('accessContext')
        showToast('Dados atualizados com sucesso 😀')
      } else {
        showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
      }
    },
  })

  const deleteMutation = useMutation(deleteProfileGeneralDataAddress, {
    onMutate: () => {
      setIsLoading(true)
    },
    onError: () => {
      queryClient.invalidateQueries(`${partnerName}-generalData`)
      showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
      setIsLoading(false)
    },
    onSuccess: (data) => {
      const { data: response } = data
      setIsLoading(false)
      ;(response?.message &&
        (queryClient.invalidateQueries(`${partnerName}-generalData`), showToast('Endereço deletado com sucesso 😀'))) ??
        showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  return {
    partnerName,
    deadlineOptions,
    flexibilityOptions,
    greyTextColor,
    ObligatoryInput,
    isMobile,
    showModal,
    setShowModal,
    handleModalOpen,
    handleModalClose,
    handleDelete,
    postMutation,
    initialValuesState,
    AutoFillAddress,
    showFields,
    handleFieldOpen,
    cropperPhoto,
    setCropperPhoto,
    generalData,
  }
}
