/* eslint-disable react/prop-types */
import React from 'react'

import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Input } from '~/components/v2/Input'
import { Button } from '~/components/v2/Button'
import { theme, showToast } from '@provi/provi-components'
import Sally from '~/assets/images/Saly-1.png'
import { KeyIcon } from '~/assets/svgs/key-icon'
import { history } from '~/navigation/history'
import { TextErrorInput } from '~/components/text-error-input'
import { resetPassword } from '~/services/api'
import { asyncLocalStorage, handleLocalStorage } from '~/utils'
import { ProviLogo } from '~/assets/svgs/v2'
import { SvgWrapper } from '~/screens/login/styles'

import { FormContainer, Title, StyledLabel, Form, ContainerLogin, Section, ImageContainer, SallyImage } from './styles'

const FormWrapper = (props) => {
  const { values, errors, handleChange, touched, handleSubmit, handleBlur } = props

  return (
    <>
      <ContainerLogin>
        <SallyImage src={Sally} />
        <ImageContainer>
          <SvgWrapper>
            <ProviLogo logoColor={'white'} textColor={'white'} />
          </SvgWrapper>
        </ImageContainer>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Title>Redefina a sua senha</Title>
            <Section>
              <StyledLabel>Digite sua nova senha</StyledLabel>
              <Input
                error={errors.newPassword && touched.newPassword}
                icon={<KeyIcon />}
                placeholder="Digite a sua senha"
                id="newPassword"
                type="password"
                onChange={handleChange('newPassword')}
                onBlur={handleBlur('newPassword')}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
              {errors.newPassword && touched.newPassword && <TextErrorInput>{errors.newPassword}</TextErrorInput>}
            </Section>

            <Section>
              <StyledLabel>Confirme sua nova senha</StyledLabel>
              <Input
                error={errors.confirmPassword && touched.confirmPassword}
                icon={<KeyIcon />}
                placeholder="Digite a sua senha"
                id="confirmPassword"
                type="password"
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
              {errors.confirmPassword && touched.confirmPassword && <TextErrorInput>{errors.confirmPassword}</TextErrorInput>}
            </Section>

            <Button
              icon
              margin="0 auto"
              backgroundDisabled={theme.colors.blue800}
              text="Avançar"
              disabled={!(values.newPassword && values.confirmPassword) || values.newPassword !== values.confirmPassword}
              type="submit"
              onClick={handleSubmit}
            />
          </Form>
        </FormContainer>
      </ContainerLogin>
    </>
  )
}

export const FormFormik = withFormik({
  validationSchema: () => {
    const schema = {
      newPassword: Yup.string()
        .min(6, 'A senha tem que ter no minimo 6 caracteres')
        .required('Preencha o campo Digite sua nova senha'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Senha está diferente')
        .required('Preencha o campo Confirme sua nova senha'),
    }
    return Yup.object().shape(schema)
  },
  handleSubmit: async (values, { props }) => {
    props.setIsLoading(true)
    const email = await asyncLocalStorage.getItem('email_ref')
    const token_ref = await asyncLocalStorage.getItem('token_ref')

    const { newPassword, confirmPassword } = values

    try {
      const response = await resetPassword(email && email.trim(), newPassword, confirmPassword, token_ref)
      const { token, partner, error } = response.data

      if (error && error.message) {
        showToast(
          error.message === 'You can not change password to the same value'
            ? 'Você não pode colocar a mesma senha que a anterior 😔'
            : 'Ops, ocorreu algum erro ao trocar sua senha 😔',
        )
        props.setIsLoading(false)

        return
      }

      await handleLocalStorage({
        token,
        email,
        partnerName: partner.name,
        partnerSlug: partner.slug,
        partnerId: partner.id,
      })

      // toast de sucesso
      showToast('Senha alterada com sucesso')

      await asyncLocalStorage.removeItem('email_ref')
      history.push('/login')
    } catch (error) {
      showToast('Ops, ocorreu algum erro ao trocar sua senha 😔')
    }
  },
})(FormWrapper)
