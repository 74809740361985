import { useContext, useState } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { useQuery } from 'react-query'
import { getProfileBankHistory } from '~/services/api'
import { showToast } from '@provi/provi-components'
import { getStorage } from '~/utils'
import { formatRows } from './helpers/tableHelpers'

export const useAccountHistoryTable = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [customPagination, setCustomPagination] = useState([])
  const partnerName = getStorage({ key: 'partner-name' })
  const limit = 5

  const { data: bankHistory } = useQuery(
    [`${partnerName}-finance`, 'bankHistory', page, limit],
    () => getProfileBankHistory(page, limit),
    {
      keepPreviousData: true,
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
        setIsLoading(false)
      },
      onSuccess: (data) => {
        setCustomPagination([data?.data?.pagination])
        setRows(formatRows(data?.data?.rows))
        setIsLoading(false)
      },
    },
  )

  const handleNextButton = () => {
    setIsLoading(true)
    setPage(page + 1)
  }

  const handleBackButton = () => {
    setIsLoading(true)
    setPage(page - 1)
  }

  return { rows, customPagination, handleNextButton, handleBackButton, bankHistory }
}
