import React from 'react'

import { EachIntegrationWrapper, TitleText, ActionsContainer } from './styles'

export const EachIntegration = ({ noBorderBottomCondition, name, secondaryComponent }) => {
  return (
    <EachIntegrationWrapper noBorder={noBorderBottomCondition}>
      <TitleText>Memberkit &lt;&gt; {name}</TitleText>
      {secondaryComponent && <ActionsContainer>{secondaryComponent}</ActionsContainer>}
    </EachIntegrationWrapper>
  )
}
