import { handleToken } from './handleToken'
import { handleStorage } from './handleStorage'

/**
 * @typedef {Object} handleLocalStorageData
 * @property {string} token
 * @property {string} email
 * @property {string} partnerName
 * @property {string} partnerSlug
 * @property {string} partnerId
 * @property {string} userId
 */

/**
 * @description handle local storage
 * @name handleLocalStorage
 * @function
 * @async
 * @param {handleLocalStorageData} data
 * @returns {Promise<{user: string}>}
 */
export const handleLocalStorage = async ({ token, email, partnerName, partnerSlug, partnerId, userId }) => {
  const user = await handleToken({ token, email })

  await handleStorage({ user, key: 'email', value: email })
  await handleStorage({ user, key: 'partner-name', value: partnerName })
  await handleStorage({ user, key: 'partner-slug', value: partnerSlug })
  await handleStorage({ user, key: 'partner-id', value: partnerId })
  await handleStorage({ user, key: 'user-id', value: userId })

  return {
    user,
  }
}
