import React from 'react'
import { Collapse, List, Tooltip } from '@material-ui/core'
import { DrawerInternalChevronDown, DrawerInternalChevronUp } from '~/assets/svgs/drawer-internal-chevron'
import {
  NestedListItem,
  NestedListItemText,
  TabItemTitle,
  TabDropdownHolder,
  NestedListItemIcon,
  Divider,
  ActivatableIconBackground,
  IconListItem,
  AbsoluteSvgHolder,
} from './styles'

export const ChevronHolder = ({ currentOpenTabs, mainTab }) => {
  if (currentOpenTabs[mainTab]) return <DrawerInternalChevronUp />
  return <DrawerInternalChevronDown />
}

/*                                                               
 Makes it possible to programatically decide whether we put a divider before our component or not.
 * Useful because it enables us to define which tabs will have dividers on top of them inside our hooks instead of doing it manually in here
 */
export const DividerWrapper = ({ divide, children }) => {
  return (
    <>
      {divide && <Divider />}
      {children}
    </>
  )
}
/* Maps through the `existingTabs` property (which is available at this component's hooks) and renders those.
 * Shows a red column next to the active route and handles the ui changes when the `currentOpenTabs` state changes
 */
export const CustomListItem = ({
  currentOpenTabs,
  setCurrentOpenTabs,
  title,
  internalTabs,
  mainTab,
  currentRoute,
  Icon,
  open,
  divide,
  titleColor,
  action,
  handleDrawerState,
}) => {
  if (!open) {
    const currentOpenIcon = internalTabs.map((tab) => tab.route).find((route) => route === currentRoute)
    return (
      <DividerWrapper divide={divide}>
        <Tooltip title={title}>
          <IconListItem>
            <ActivatableIconBackground active={currentOpenIcon} />
            <AbsoluteSvgHolder
              hasAction={!!action}
              onClick={
                action
                  ? () => action()
                  : () => {
                      handleDrawerState()
                      setCurrentOpenTabs({ ...currentOpenTabs, [mainTab]: true })
                    }
              }
            >
              <Icon />
            </AbsoluteSvgHolder>
          </IconListItem>
        </Tooltip>
      </DividerWrapper>
    )
  }

  if (internalTabs.length < 1) {
    return (
      <DividerWrapper divide={divide}>
        <TabDropdownHolder onClick={action}>
          <TabItemTitle titleColor={titleColor} active={currentOpenTabs[mainTab]}>
            <Icon /> {title}
          </TabItemTitle>
        </TabDropdownHolder>
      </DividerWrapper>
    )
  }

  return (
    <DividerWrapper divide={divide}>
      <TabDropdownHolder
        active={currentOpenTabs[mainTab]}
        button
        onClick={() => setCurrentOpenTabs({ ...currentOpenTabs, [mainTab]: !currentOpenTabs[mainTab] })}
      >
        <TabItemTitle titleColor={currentOpenTabs[mainTab] ? 'active' : ''}>
          <Icon /> {title}
        </TabItemTitle>
        <ChevronHolder currentOpenTabs={currentOpenTabs} mainTab={mainTab} />
      </TabDropdownHolder>
      <Collapse in={currentOpenTabs[mainTab]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {internalTabs.map(
            (tab) =>
              tab.show !== false && (
                <NestedListItem key={tab.title}>
                  <NestedListItemIcon active={currentRoute === tab.route} />
                  <NestedListItemText onClick={() => tab.action()} active={currentRoute === tab.route}>
                    {tab.title || tab.jsx()}
                  </NestedListItemText>
                </NestedListItem>
              ),
          )}
        </List>
      </Collapse>
    </DividerWrapper>
  )
}
