import React from 'react'

export const DrawerLogo = ({ onClick }) => (
  <svg
    cursor="pointer"
    onClick={onClick}
    width="79"
    height="24"
    viewBox="0 0 79 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.0429 13.1642C38.0429 16.752 35.5213 19.5215 32.4626 19.5215C30.8973 19.5215 29.7528 18.9676 28.9831 18.052V24.0001H25.4795V7.14571H28.9831V8.27821C29.7528 7.36269 30.8973 6.80881 32.4626 6.80881C35.5213 6.80881 38.0429 9.57632 38.0429 13.1642ZM34.5393 13.1642C34.5393 11.3579 33.3708 10.2273 31.7612 10.2273C30.1497 10.2273 28.9831 11.3598 28.9831 13.1642C28.9831 14.9705 30.1515 16.1011 31.7612 16.1011C33.3727 16.1011 34.5393 14.9705 34.5393 13.1642Z"
      fill="#000B3C"
    />
    <path
      d="M47.0326 6.90497V10.9972C45.5854 10.7574 43.5309 11.3589 43.5309 13.7419V19.1836H40.0273V7.1467H43.5309V9.2899C43.9961 7.67584 45.5614 6.90497 47.0326 6.90497Z"
      fill="#000B3C"
    />
    <path
      d="M47.7314 13.1642C47.7314 9.57632 50.4856 6.80881 53.919 6.80881C57.3524 6.80881 60.1065 9.57822 60.1065 13.1642C60.1065 16.752 57.3506 19.5215 53.919 19.5215C50.4874 19.5215 47.7314 16.752 47.7314 13.1642ZM56.6048 13.1642C56.6048 11.455 55.4363 10.3224 53.919 10.3224C52.4016 10.3224 51.2332 11.455 51.2332 13.1642C51.2332 14.8734 52.4016 16.0059 53.919 16.0059C55.4382 16.0059 56.6048 14.8734 56.6048 13.1642Z"
      fill="#000B3C"
    />
    <path
      d="M72.9524 7.14603L68.633 19.1849H64.6642L60.3447 7.14603H64.2212L66.6504 14.7786L69.0797 7.14603H72.9524Z"
      fill="#000B3C"
    />
    <path
      d="M73.8843 3.91952C73.8843 2.73943 74.8423 1.75348 75.9849 1.75348C77.1276 1.75348 78.0856 2.74133 78.0856 3.91952C78.0856 5.09961 77.1276 6.08747 75.9849 6.08747C74.8423 6.08747 73.8843 5.09771 73.8843 3.91952ZM74.235 7.14574H77.7386V19.1846H74.235V7.14574Z"
      fill="#000B3C"
    />
    <path
      d="M20.4289 4.13619C15.9525 -1.16281 8.1461 -1.37028 3.40759 3.51379C1.11679 5.87778 -0.0166134 8.98028 0.00184593 12.0771L0 12.0752V23.9998H11.5648L11.5629 23.9979C14.7896 24.0189 18.0237 22.6675 20.3662 19.938C24.202 15.467 24.2316 8.63768 20.4289 4.13619ZM14.7582 15.2176C13.0341 16.9954 10.2394 16.9954 8.51529 15.2176C6.79119 13.4399 6.79119 10.5582 8.51529 8.78043C10.2394 7.00268 13.0341 7.00268 14.7582 8.78043C16.4823 10.5582 16.4823 13.4399 14.7582 15.2176Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="23.2622" y1="11.9997" x2="0" y2="11.9997" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2369F1" />
        <stop offset="0.999" stopColor="#06C1EF" />
      </linearGradient>
    </defs>
  </svg>
)
