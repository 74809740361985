import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'

import { logger } from '~/helpers/logger'
import { createCampaing } from '~/services/api'
import { ERRORS } from '~/constants'
import slugify from 'slugify'

import { FinantialIcon } from '~/assets/svgs/provipay'
import { BoletoIcon } from '~/assets/svgs/provipay-boleto'
import { CreditCardIcon } from '~/assets/svgs/provipay-credit-card'

import { formatInput, handlePercent, handleValue, formatStrValue, unMask, getStorage, removeItem, getActiveUser } from '~/utils'

import { setCampaignPaymentMethods } from '../helpers/setCampaignPaymentMethods'

const payment_methods_initial_state = [
  {
    name: 'Cartão de crédito',
    value: 'CreditCard',
    selected: false,
    icon: CreditCardIcon,
    available: true,
  },
  {
    name: 'Boleto',
    value: 'Boleto',
    selected: false,
    icon: BoletoIcon,
    available: true,
  },
  {
    name: 'Crédito educacional',
    value: 'CourseFinancing',
    selected: false,
    icon: FinantialIcon,
    available: true,
  },
]

export const useCheckout = (
  setIsLoading,
  setCurrent,
  isIE,
  isEdge,
  engineName,
  setSelectedOptionsCampaign,
  setValueCampaign,
  activePage,
  setActivePage,
) => {
  const [products, setProducts] = useState([])
  const [productsValue, setProductsValue] = useState(0)
  const [link, setLink] = useState(false)
  const [linkUrl, setLinkUrl] = useState()
  const [slug, setSlug] = useState('')
  const [slugPreview, setSlugPreview] = useState('')
  const [sellerId, setSellerId] = useState()
  const [campaignName, setCampaignName] = useState()
  const [campaignNameError, setCampaignNameError] = useState(false)
  const [obj, setObj] = useState({ value: null, percent: null })

  const [errorPercent, setErrorPercent] = useState(false)
  const [errorMaxValue, setErrorMaxValue] = useState(false)
  const [showSellerInfoModal, setShowSellerInfoModal] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(payment_methods_initial_state)
  const [defaultPaymentMethods, setDefaultPaymentMethods] = useState([])

  const [isShowCalcModal, setIsShowCalcModal] = useState(false)
  const [calcConfig, setCalcConfig] = useState({})

  useEffect(() => {
    setIsLoading(true)
    setCurrent(95, 2)

    if (activePage !== 'campaigns') setActivePage('campaigns')

    window.scrollTo(0, 0)

    const getData = async () => {
      const products = getStorage({ key: 'selectedOptionsCampaign' })
      const responseValue = getStorage({ key: 'selectedOptionsValueCampaign' })
      const responseEmail = getStorage({ key: 'email' })

      const parsedProducts = products

      await setCampaignPaymentMethods({ paymentMethods, setDefaultPaymentMethods, setPaymentMethods, parsedProducts })

      setProductsValue(responseValue)
      setProducts(parsedProducts)
      if (responseEmail) {
        setSellerId(responseEmail)
      }

      setIsLoading(false)
    }

    getData()
  }, [])

  const handleCalcModal = () => {
    setIsShowCalcModal(!isShowCalcModal)
  }

  const handleValueChange = (_event) => {
    const unMaskedValue = unMask(_event.target.value)
    const _value = unMaskedValue

    if (!_value) {
      setObj({ value: '', percent: '' })
    } else {
      const valueInInput = formatInput(_value)

      const errorMax = Number(valueInInput) > Number(productsValue)
      setErrorMaxValue(errorMax)

      return handleValue({ setObj, valueInInput, productsValue })
    }
  }

  const handleCalcClick = async () => {
    try {
      setIsLoading(true)
      const formattedValue = formatStrValue(unMask(obj.value))
      const amountOfCourses = products.reduce((accumulator, current) => current.price_in_cents + accumulator, 0)
      const courseIds = products.map((course) => course.id)
      const discountInCents = obj.value ? Number(amountOfCourses) - formattedValue : 0

      setCalcConfig({
        amountInCents: amountOfCourses,
        coursesIds: courseIds,
        discountInCents,
      })

      handleCalcModal()
    } catch (error) {
      showToast('Ocorreu um erro ao mostrar a calculadora, tente novamente ou contate a Provi')
    } finally {
      setIsLoading(false)
    }
  }

  const handlePercentChange = (_event) => {
    let floatValue = _event.target.value
    floatValue = floatValue && floatValue.replace('%', '')

    if (!floatValue) {
      setObj({ value: '', percent: '' })
    } else {
      setErrorPercent(floatValue >= 100)

      return handlePercent({ setObj, percent: floatValue, productsValue })
    }
  }

  const handleSubmitCheckout = async () => {
    let selectedCourses = []
    let selectedCoursesIds = []
    try {
      selectedCourses = getStorage({ key: 'selectedOptionsCampaign' })
      selectedCoursesIds = selectedCourses.map((selectedOption) => ({ proviId: selectedOption.id }))
    } catch (error) {
      showToast(ERRORS.default)
    }

    try {
      const activePaymentMethods = paymentMethods.filter((payment) => payment.selected).map((payment) => payment.value)

      if (defaultPaymentMethods && defaultPaymentMethods.length && !activePaymentMethods.length) {
        showToast('Você tem que selecionar pelo menos um método de pagamento')
        return
      }

      const objPost = {
        checkout_price_in_cents: Number(formatStrValue(unMask(obj.value)) || productsValue),
        courses: selectedCoursesIds,
        consultant_id: sellerId,
        name: campaignName,
        slug: slug.toLowerCase(),
        notification_url: sellerId,
        paymentMethods: activePaymentMethods,
      }

      if (errorPercent || errorMaxValue) {
        showToast('Ops, ocorreu algum erro 😔')
      } else {
        setIsLoading(true)

        const response = await createCampaing(objPost)

        const { redirect_url, error } = response.data

        if (error) {
          const message =
            response.status !== 500 && error.message
              ? error.message
              : 'Ocorreu um erro ao criar a campanha, tente novamente ou contate a Provi'
          showToast(message)
        } else {
          setLinkUrl(redirect_url)
          setLink(true)

          await removeItem({ key: 'selectedOptionsCampaign', user: getActiveUser() })
          await removeItem({ key: 'selectedOptionsValueCampaign', user: getActiveUser() })

          setSelectedOptionsCampaign([])
          setValueCampaign(0)
        }
        setIsLoading(false)
      }
    } catch (error) {
      showToast('Ocorreu um erro ao criar a campanha, tente novamente ou contate a Provi')

      logger({
        error,
        level: 'error',
        screen: 'checkout',
      })
    }
  }

  const handleFocus = (event) => event.target.select()

  const handleSellerId = (e) => {
    if (!e) return

    setSellerId(e.target.value)
  }

  const handleCampaignName = (e) => {
    if (!e) return

    const regexCampaignName = /[áàâãéèêíïóôõöúçñ`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]+/gi
    if (regexCampaignName.test(e.target.value)) {
      setCampaignNameError(true)
      return false
    }
    setCampaignNameError(false)

    const formatSlug = (str, isFormatted) => {
      if (!str) return

      const formattedSlug = slugify(str, '-')

      if (isFormatted) {
        return `.../campanha/${formattedSlug.toLowerCase()}`
      }

      return formattedSlug
    }

    const formattedSlug = formatSlug(e.target.value, true)

    setSlugPreview(formattedSlug)
    setSlug(formatSlug(e.target.value))

    setCampaignName(e.target.value)
  }

  const showToastCopy = () => {
    showToast('Link copiado')
  }

  const handleModal = (option, location) => {
    if (location === 'seller') {
      setShowSellerInfoModal(option)
    }
  }

  return [
    handleValueChange,
    handlePercentChange,
    link,
    showToastCopy,
    products,
    productsValue,
    obj,
    handleFocus,
    handleSubmitCheckout,
    sellerId,
    handleSellerId,
    linkUrl,
    errorPercent,
    errorMaxValue,
    campaignName,
    handleCampaignName,
    slugPreview,
    handleModal,
    showSellerInfoModal,
    campaignNameError,
    handleCalcModal,
    handleCalcClick,
    isShowCalcModal,
    calcConfig,
    paymentMethods,
    setPaymentMethods,
    defaultPaymentMethods,
  ]
}
