import React from 'react'

export const RemoteClasses = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 18H7M17.5 15H2.5C1.67157 15 1 14.3036 1 13.4444V2.55556C1 1.69645 1.67157 1 2.5 1H17.5C18.3284 1 19 1.69645 19 2.55556V13.4444C19 14.3036 18.3284 15 17.5 15ZM13 8L8 5V11L13 8Z"
      stroke={color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
