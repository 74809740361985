import React from 'react'
import { components } from 'react-select'
import { Tooltip } from '@material-ui/core'

export const CustomSelectOption = (props) => {
  return (
    <Tooltip title={props?.label}>
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  )
}
