import React from 'react'

export const ChainLinkIcon = ({ color }) => (
  <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00049 6.99951H21.0005"
      stroke={color || 'black'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9995 13H6.99951C5.40821 13 3.88209 12.3679 2.75687 11.2426C1.63165 10.1174 0.999512 8.5913 0.999512 7C0.999512 5.4087 1.63165 3.88258 2.75687 2.75736C3.88209 1.63214 5.40821 1 6.99951 1H11.9995"
      stroke={color || 'black'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0005 13H23.0005C24.5918 13 26.1179 12.3679 27.2431 11.2426C28.3683 10.1174 29.0005 8.5913 29.0005 7C29.0005 5.4087 28.3683 3.88258 27.2431 2.75736C26.1179 1.63214 24.5918 1 23.0005 1H18.0005"
      stroke={color || 'black'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
