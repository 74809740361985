import React from 'react'

export const AddIcon = ({ ...props }) => {
  return (
    <svg {...props} xmlns="https://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke="#B0AFAF"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M8 13H18" stroke="#B0AFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 8V18" stroke="#B0AFAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
