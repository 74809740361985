import React from 'react'
import {
  IntegrationsHome,
  AddAccount,
  ConnectProducts,
  ManageAccount,
  Memberkit,
  Link,
} from '~/screens/integrations/components'

const sharedIntegrationHomeObject = { breadCrumbTitle: 'Integrações', component: <IntegrationsHome />, key: 1 }

export const memberkitFlowArray = [
  { ...sharedIntegrationHomeObject },
  { breadCrumbTitle: 'Memberkit', component: <Memberkit />, key: 2 },
  { breadCrumbTitle: 'Adicionar conta', component: <AddAccount />, key: 3 },
  { breadCrumbTitle: 'Gerenciar conta', component: <ManageAccount />, key: 4 },
  { breadCrumbTitle: 'Conectar produtos', component: <ConnectProducts />, key: 5 },
]

export const productModalObj = {
  header: 'Desconectar produtos',
  firstParagraph:
    'O vínculo entre o produto Provi e a MemberKit será desfeito. Ao desconectar o produto, todo processo de ativação e bloqueio dele deverá ser feito manualmente.',
  secondParagraph: (
    <p>
      Nosso{' '}
      <Link
        href="https://parceiros.provi.com.br/como-desconectar-produtos-da-integra%C3%A7%C3%A3o-provi-com-a-memberkit"
        text="FAQ"
      />{' '}
      está disponível para eventuais dúvidas.
    </p>
  ),
  primaryBtnText: 'Desconectar produto',
}

export const accountModalObj = {
  header: 'Desvincular conta',
  firstParagraph:
    'A liberação e o bloqueio de acesso ao conteúdo na MemberKit serão interrompidos. Ao desvincular a conta, esse processo deverá ser feito manualmente pelos administradores.',
  secondParagraph: (
    <p>
      Nosso <Link href="https://parceiros.provi.com.br/como-desvincular-minha-conta-memberkit-provi" text="FAQ" /> está
      disponível para eventuais dúvidas.
    </p>
  ),
  primaryBtnText: 'Desvincular conta',
  secondaryBtnText: 'Cancelar',
}

export const accountModalObjConfirmation = {
  header: 'Desvincular conta',
  firstParagraph: 'As turmas e os produtos vinculados à MemberKit serão desfeitos. Tem certeza que deseja desvincular a conta?',
  primaryBtnText: 'Desvincular conta',
  secondaryBtnText: 'Manter Conta',
}
