import { useState, useEffect } from 'react'
import verifyPainelUrl from './../../helpers/verifyPainelUrl'

export const useLogin = (setIsLoading) => {
  useEffect(() => {
    async function verify() {
      await verifyPainelUrl()
    }

    verify()
    setIsLoading(false)
  }, [])
}

export const useModalLogin = () => {
  const [isShowModal, setIsShowModal] = useState(false)

  const handleModal = (option) => {
    setIsShowModal(option)
  }

  return [isShowModal, handleModal]
}
