/* eslint-disable react/prop-types */
import React from 'react'

import { Button, theme } from '@provi/provi-components'
import { Modal } from '~/components/Modal'
import { Text } from '~/themes/styles'
import { Span } from './style'

export const InactivateUserModal = (props) => {
  const { handleModal, email, submitDeleteUser } = props

  return (
    <>
      <Modal
        isWithoutHeight
        width="720px"
        radius={20}
        onDismiss={() => handleModal(false, 'inativate')}
        footer={<Button text="Confirmar" onClick={submitDeleteUser} />}
      >
        <Text bold align="left" color={theme.colors.blue900} size="20px">
          Tem certeza que deseja inativar o usuário <Span>"{email}"</Span> ?
        </Text>
      </Modal>
    </>
  )
}
