import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { TemplateContext } from '~/components/Template/context'
import { postSimulation } from '~/services/api'
import { simulationsCopyText as createCopyFinancingConditions } from 'utils/createCopyFinancingConditions'

export const useFinancingConditions = ({ classroomDetails }) => {
  const { isMobile } = useContext(TemplateContext)
  const [simulations, setSimulations] = useState(null)
  const [isLoadingSimulations, setIsLoadingSimulations] = useState(false)

  const financingModalRef = useRef()

  const openFinancingModal = () => {
    financingModalRef.current.openModalFn()
  }

  const closeFinancingModal = () => {
    financingModalRef.current.closeModalFn()
  }

  const getFinancingData = useCallback(async () => {
    try {
      setIsLoadingSimulations(true)

      await getSimulations()
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoadingSimulations(false)
    }
  }, [getSimulations])

  const getSimulations = useCallback(async () => {
    try {
      const simulationsResponse = await postSimulation({
        amountInCents: classroomDetails.content.priceInCents,
        coursesIds: [classroomDetails.content.Course.id],
        classesIds: [classroomDetails.content.id],
      })

      if (simulationsResponse.status > 199 && simulationsResponse.status < 300) {
        setSimulations(simulationsResponse.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }, [classroomDetails])

  useEffect(() => {
    if (classroomDetails) {
      getFinancingData()
    }
  }, [classroomDetails, getFinancingData])

  const simulationsCopyText = useMemo(() => {
    return createCopyFinancingConditions(simulations)
  }, [simulations])

  return {
    isMobile,
    financingModalRef,
    openFinancingModal,
    closeFinancingModal,
    simulations,
    isLoadingSimulations,
    simulationsCopyText,
  }
}
