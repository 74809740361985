import React, { useContext } from 'react'
import { Label, Button, TextLink, theme } from '@provi/provi-components'
import { Popover } from '~/components/Popover'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { ButtonBack } from '~/components/ButtonBack'
import { Header } from '~/components/Header'
import { SubHeader } from '~/components/SubHeader'
import { Input } from '~/components/Input'
import { Selector } from '~/components/Select'

import { IconHelp } from '~/assets/svgs/icon-help'

import { useCourseClass } from './hooks'
import {
  Section,
  Container,
  ErrorMessage,
  WrapperInput,
  ContainerModal,
  Title,
  WrapperGetLink,
  WrapperGetLinkTextLink,
  Main,
} from './styles'

export const Form = () => {
  const {
    isLowMobile,
    setIsLoading,
    handleLogoutModal,
    isMobile,
    goToPage,
    activePage,
    setActivePage,
    editOption,
    setEditOption,
  } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    validate,
    nameError,
    startsAtError,
    endsAtError,
    handleSubmitCourseClasses,
    handleValue,
    values,
    isButtonDisable,
    courseIdValue,
    courseOptions,
    handleGoBack,
  ] = useCourseClass(setIsLoading, activePage, setActivePage, goToPage, editOption, setEditOption)

  return (
    <Main isMobile={isMobile} drawerWidth={drawerWidth}>
      <Header isCampaign={false} isNewSale={false} />

      {isMobile && <SubHeader isMargin="0 0 30px 0" isShowButtonHelpModal={false} functionBackButton={handleGoBack} />}
      <Container>
        {!isMobile && <ButtonBack onClick={handleGoBack} />}
        <ContainerModal>
          <Title>{!editOption ? 'Criar nova' : 'Alterar'} turma</Title>
          <Section>
            <Label text="Nome da turma*" />
            <WrapperInput>
              <Input
                key="name"
                inputProps={{ autoFocus: true, maxLength: 200 }}
                id="courseClassName"
                name="courseClassName"
                placeholder="Ex: Turma Web Development Noturna..."
                onChange={(event) => handleValue('name', event.target.value)}
                value={values.name}
                onBlur={(event) => validate(event, 'name')}
                tabIndex="0"
              />

              <Popover
                insideForm
                text={`
                  Insira o nome da turma e se achar necessário a data de início e fim. Ex: Turma Web Full Stack 13/08 a 11/09.
                  Você poderá detalhar mais as datas de vigência da turma nos campos abaixo.
                `}
              >
                <IconHelp />
              </Popover>
            </WrapperInput>
            {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
          </Section>
          <Section>
            <Label text="Curso*" />
            <WrapperInput>
              <Selector
                isDisabled={!!editOption}
                value={courseIdValue}
                onChange={(event) => handleValue('courseId', event, 'select')}
                id="courseId"
                options={courseOptions}
                placeholder={isLowMobile ? 'Opção' : 'Selecione uma opção'}
              />

              <Popover insideForm text="Selecione o curso ao qual a turma pertencerá.">
                <IconHelp />
              </Popover>
            </WrapperInput>
          </Section>
          <Section>
            <Label text="Início da turma*" />
            <WrapperInput>
              <Input
                type="date"
                key="startsAt"
                id="courseClassStartsAt"
                name="courseClassStartsAt"
                onChange={(event) => handleValue('startsAt', event.target.value, 'date')}
                value={values.startsAt}
                onBlur={(event) => validate(event, 'startsAt')}
                tabIndex="0"
              />

              <Popover insideForm text="Selecione a data em que sua turma será iniciada.">
                <IconHelp />
              </Popover>
            </WrapperInput>
            {startsAtError && <ErrorMessage>{startsAtError}</ErrorMessage>}
          </Section>
          <Section>
            <Label text="Encerramento da turma*" />
            <WrapperInput>
              <Input
                type="date"
                key="endsAt"
                id="courseClassEndsAt"
                name="courseClassEndsAt"
                onChange={(event) => handleValue('endsAt', event.target.value, 'date')}
                value={values.endsAt}
                onBlur={(event) => validate(event, 'endsAt')}
                tabIndex="0"
              />

              <Popover insideForm text="Selecione a data em que sua turma será encerrada.">
                <IconHelp />
              </Popover>
            </WrapperInput>
            {endsAtError && <ErrorMessage>{endsAtError}</ErrorMessage>}
          </Section>

          <Section align="center">
            <WrapperGetLink>
              <Button
                disabled={isButtonDisable}
                text={`${!editOption ? 'Criar' : 'Alterar'} turma`}
                marginHorizontal={0}
                marginVertical={0}
                onClick={handleSubmitCourseClasses}
              />
              <WrapperGetLinkTextLink>
                <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
              </WrapperGetLinkTextLink>
            </WrapperGetLink>
          </Section>
        </ContainerModal>
      </Container>
    </Main>
  )
}
