import { useContext, useState, useCallback, useMemo, useEffect } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { useToggle } from '~/hooks'
import { format } from 'date-fns'
import { postAddTimeLineForm } from '~/screens/courses/requests'
import { showToast } from '@provi/provi-components'

export const useAddTimeLine = ({ classroomId, courseId }) => {
  const numberFromBE = 500000

  const { isMobile, goToPage, setIsLoading } = useContext(TemplateContext)
  const [showTimeLine, setShowTimeLine] = useToggle()
  const [formValues, setFormValues] = useState({
    launchStartsAt: '',
    launchEndsAt: '',
    canCheckoutUntil: '',
    launchPricing: '',
    CourseClassId: 23,
  })
  const [formErrors, setFormErrors] = useState({
    launchStartsAt: { hasError: false, errMessage: 'Você deve informar uma data de início.' },
    launchEndsAt: { hasError: false, errMessage: 'Você deve informar uma data de fim.' },
    canCheckoutUntil: { hasError: false, errMessage: 'Você deve informar um prazo limite para efetivação.' },
    launchPricing: { hasError: false, errMessage: 'Você deve selecionar um modelo de precificação' },
  })

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    },
    [setFormValues],
  )

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target

      value
        ? setFormErrors((prevState) => ({
            ...prevState,
            [name]: { ...prevState[name], hasError: false },
          }))
        : setFormErrors((prevState) => ({
            ...prevState,
            [name]: { ...prevState[name], hasError: true },
          }))
    },
    [setFormErrors],
  )

  const minimumEndAtDate = useMemo(
    () => (formValues.launchStartsAt ? formValues.launchStartsAt : format(new Date(), "yyyy-MM-dd'T'00:00")),
    [formValues],
  )

  const minimumCanCheckoutUntilDate = useMemo(
    () => (formValues.launchEndsAt ? formValues.launchEndsAt : format(new Date(), "yyyy-MM-dd'T'00:00")),
    [formValues],
  )

  const formIsFilled = useMemo(() => {
    return Object.values(formValues).every(Boolean)
  }, [formValues])

  const validateForm = useCallback(() => {
    const formPropertiesArr = Object.keys(formValues)
    const formFieldValuesArr = Object.values(formValues)

    formIsFilled
      ? setShowTimeLine()
      : formPropertiesArr.forEach((eachProp, index) => {
          !formFieldValuesArr[index] &&
            setFormErrors((prev) => ({ ...prev, [eachProp]: { ...prev[eachProp], hasError: true } }))
        })
  }, [formValues, setFormErrors, formIsFilled, setShowTimeLine])

  const launchPricingObjFormatter = (string) => {
    const possibleObjs = {
      uniquePrice: [
        {
          uniquePaymentMethods: [],
          uniquePriceInCents: numberFromBE,
        },
      ],
      paymentMethod: [],
      launchSteps: [
        {
          startsAt: formValues.launchStartsAt,
          endsAt: formValues.launchEndsAt,
          priceInCents: numberFromBE,
          paymentMethods: [],
        },
      ],
    }
    return possibleObjs[string] || {}
  }

  const handleRadioChange = (string) => {
    setFormErrors((prev) => ({ ...prev, launchPricing: { ...prev.launchPricing, hasError: false } }))
    setFormValues((prev) => ({ ...prev, launchPricing: { type: string, options: launchPricingObjFormatter(string) } }))
  }

  const discountPercentage = (originalPrice, discountedPrice) => (100 * (originalPrice - discountedPrice)) / originalPrice

  const priceWithDiscount = (originalPrice, discountedPrice) => originalPrice - (originalPrice * discountedPrice) / 100

  const postAddTimeLine = async (formObject) => {
    try {
      setIsLoading(true)

      const response = await postAddTimeLineForm(formObject)

      if (response.status > 199 && response.status < 300) {
        showToast('Estratégia de venda cadastrada com sucesso!')
      }
    } catch (error) {
      showToast('Algo de errado aconteceu salvando sua estratégia de venda 😔 Por favor, tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }

  const smallFlexGaps = isMobile ? '0.5rem' : '1rem'
  const flexDirs = isMobile && 'column'
  const btnFontSize = isMobile ? '13px' : '16px'
  const btnLineHeight = isMobile ? '20px' : '24px'

  return {
    isMobile,
    goToPage,
    formValues,
    setFormValues,
    handleInputChange,
    formErrors,
    setFormErrors,
    validateForm,
    handleBlur,
    handleRadioChange,
    discountPercentage,
    priceWithDiscount,
    showTimeLine,
    setShowTimeLine,
    postAddTimeLine,
    minimumEndAtDate,
    minimumCanCheckoutUntilDate,
    smallFlexGaps,
    flexDirs,
    btnFontSize,
    btnLineHeight,
  }
}
