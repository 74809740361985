/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'

import { Button } from '@provi/provi-components'
import { Modal as ModalComponent } from '~/components/Modal'

import { DisabledEye, Eye } from '~/assets/svgs/eye'
import { Lock } from '~/assets/svgs/Lock'

import { useModal } from './hooks'
import { WrapperItens, Item, WrapperIcon, Text } from './styles'
import { filterTypes } from './filterTypes'
import { TemplateContext } from '~/components/Template/context'

export const FilterModal = (props) => {
  const { showCells, handleModal, setShowCells, show, refer, preferences } = props
  const { setIsLoading } = useContext(TemplateContext)

  const [handleOption, showOptions, isChangeOption, handlePreferences] = useModal(
    showCells,
    setShowCells,
    refer,
    preferences,
    handleModal,
    setIsLoading,
  )

  return (
    <>
      {show && (
        <ModalComponent
          width="420px"
          radius={5}
          onDismiss={() => handleModal('filters')}
          footer={<Button text="Salvar alterações" disabled={!isChangeOption} onClick={handlePreferences} />}
        >
          <WrapperItens>
            {filterTypes[refer].map((item, i) => {
              return (
                <Item
                  key={i}
                  disabled={!showOptions[i].show}
                  blocked={item.blocked}
                  onClick={() => handleOption(item.blocked, item.name)}
                >
                  <WrapperIcon>{item.blocked ? <Lock /> : !showOptions[i].show ? <DisabledEye /> : <Eye />}</WrapperIcon>
                  <Text>{item.value}</Text>
                </Item>
              )
            })}
          </WrapperItens>
        </ModalComponent>
      )}
    </>
  )
}
