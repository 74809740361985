import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperProduct = styled.div`
  text-decoration: underline ${({ underlineColor }) => underlineColor || theme.colors.blue900};
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ isDelayed }) => (isDelayed ? theme.colors.pink900 : theme.colors.blue900)};

  svg {
    margin-left: 5px;
  }
`
