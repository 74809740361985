import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'

import { putPartnerPreferences } from '~/services/api'
import { getInitialoptions } from './filterTypes'

export const useModal = (showCells, setShowCells, refer, preferences, handleModal, setIsLoading) => {
  const [showOptions, setShow] = useState(getInitialoptions(refer, preferences))
  const [isChangeOption, setIsChangeOption] = useState(false)

  const handleOption = (blocked, option) => {
    if (!isChangeOption && !blocked) {
      setIsChangeOption(true)
    }

    setShow((prev) =>
      prev.map((options) => {
        if (options.item === option && !options.isBlocked) {
          options.show = !options.show
        }
        return options
      }),
    )
  }

  const handlePreferences = async () => {
    setIsLoading(true)
    try {
      const opts = {}
      showOptions.forEach((so) => {
        opts[so.item] = so.show
      })

      await putPartnerPreferences({ module: refer, tableColumns: opts })
      setIsLoading(false)
      handleModal('filters')
    } catch (error) {
      showToast('Houve um erro ao alterar as preferências')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (preferences) {
      const updateShowCells = showCells.map((sc) => {
        const option = showOptions.find((ns) => ns.item === sc.item)
        if (option) sc.show = option.show

        return sc
      })

      setShowCells([...updateShowCells])
    }
  }, [showOptions])

  return [handleOption, showOptions, isChangeOption, handlePreferences]
}
