import React from 'react'

export const LineFilterSvg = ({ isActive }) => (
  <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33398 12H9.33398"
      stroke={isActive ? '#DE0C4B' : '#01163E'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33398 7.5H14.334"
      stroke={isActive ? '#DE0C4B' : '#01163E'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33398 16.5H4.33398"
      stroke={isActive ? '#DE0C4B' : '#01163E'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
