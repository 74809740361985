import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const PopoverText = styled.p`
  ${({ insideForm }) =>
    insideForm
      ? css`
          font-size: 12px;
          line-height: 18px;
          color: ${theme.colors.blue900};

          background-color: #fff;
          max-width: 300px;
          box-shadow: unset;
          padding: 10px;
        `
      : css`
          font-family: CenturyGothicBold;
          font-size: 16px;
          color: ${theme.colors.blue800};
          font-weight: bold;
          margin: 15px;
        `}
`

export const PopoverWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 20px;
`
