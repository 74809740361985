import React from 'react'
import { Selector } from '~/components/v2/Select'
import { InputField, ButtonV2 } from '@provi/provi-components'
import { accountOptions, withdrawOptions, bankOptions } from '~/constants/banks'
import { isEqual } from 'lodash'
import {
  Container,
  Label,
  Configuration,
  BankingInformation,
  Columns,
  Rows,
  SaveInformation,
  SelectWrapper,
  StyledSpan,
} from './styles'
import { useFinanceTab } from './hooks'

export const FinanceTab = () => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isMobile,
    financeData,
  } = useFinanceTab()

  return (
    <>
      {financeData && (
        <form onSubmit={handleSubmit}>
          <Container>
            <Configuration>
              <Label isRequired>Configuração de saque Iugu</Label>
              <SelectWrapper
                onBlur={() => setFieldTouched('withdraw')}
                isError={touched.withdraw && !values.withdraw}
                isValid={touched.withdraw && values.withdraw}
              >
                <Selector
                  width={isMobile ? '100%' : 384}
                  id="withdraw"
                  placeholder="Selecione uma opção de saque"
                  options={withdrawOptions}
                  isSearchable={false}
                  value={
                    withdrawOptions && values.withdraw
                      ? withdrawOptions.find((option) => isEqual(option.value, values.withdraw))
                      : ''
                  }
                  onChange={(option) => setFieldValue('withdraw', option.value)}
                  isDisabled={
                    financeData.data.isDefault ||
                    (financeData.data.iuguAccount && !Object.keys(financeData.data.iuguAccount).length)
                  }
                />
                {touched.withdraw && !values.withdraw && <StyledSpan>{errors.withdraw}</StyledSpan>}
              </SelectWrapper>
            </Configuration>
            <BankingInformation>
              <Columns>
                <Label isRequired>Dados bancários</Label>
                <Label isParagraph>Mantenha atualizada a conta para qual serão feitos os repasses da Provi.</Label>
                <Label isParagraph>
                  <b>
                    <u>Atenção:</u> a conta precisa ter o mesmo CNPJ cadastrado junto à Provi.
                  </b>
                </Label>
              </Columns>
              <Columns>
                <Label isRequired>Instituição</Label>
                <SelectWrapper
                  onBlur={() => setFieldTouched('bankNumber')}
                  isError={touched.bankNumber && !values.bankNumber}
                  isValid={touched.bankNumber && values.bankNumber}
                >
                  <Selector
                    width={isMobile ? '100%' : 384}
                    id="bankNumber"
                    placeholder="Selecione um banco"
                    options={bankOptions}
                    value={bankOptions ? bankOptions.find((option) => option.value === values.bankNumber) : ''}
                    onChange={(option) => setFieldValue('bankNumber', option.value)}
                  />
                  {touched.bankNumber && !values.bankNumber && <StyledSpan>{errors.bankCode}</StyledSpan>}
                </SelectWrapper>
                <Label isRequired>Tipo de conta</Label>
                <SelectWrapper
                  onBlur={() => setFieldTouched('accountType')}
                  isError={touched.accountType && !values.accountType}
                  isValid={touched.accountType && values.accountType}
                >
                  <Selector
                    width={isMobile ? '100%' : 384}
                    id="accountType"
                    placeholder="Selecione o tipo de conta"
                    isSearchable={false}
                    options={accountOptions}
                    value={accountOptions ? accountOptions.find((option) => option.value === values.accountType) : ''}
                    onChange={(option) => setFieldValue('accountType', option.value)}
                  />
                  {touched.accountType && !values.accountType && <StyledSpan>{errors.accountType}</StyledSpan>}
                </SelectWrapper>
                <Rows>
                  <InputField
                    width="237px"
                    isRequired
                    label="Agência"
                    placeholder="Digite o número da agência"
                    id="agency"
                    type="text"
                    mask="9999"
                    value={values.agency}
                    hasError={errors.agency && touched.agency}
                    isValid={!errors.agency && touched.agency}
                    errorMessage={errors.agency}
                    onChange={handleChange('agency')}
                    onBlur={handleBlur('agency')}
                  />
                  <InputField
                    width={isMobile ? '78px' : '131px'}
                    label="Dígito"
                    id="agencyDigit"
                    type="text"
                    mask="**"
                    value={values.agencyDigit}
                    onChange={handleChange('agencyDigit')}
                    onBlur={handleBlur('agencyDigit')}
                  />
                </Rows>
                <Rows>
                  <InputField
                    width="237px"
                    isRequired
                    label="Conta"
                    placeholder="Digite o número da conta"
                    id="account"
                    type="text"
                    mask={`9`.repeat(19)}
                    value={values.account}
                    hasError={errors.account && touched.account}
                    isValid={!errors.account && touched.account}
                    errorMessage={errors.account}
                    onChange={handleChange('account')}
                    onBlur={handleBlur('account')}
                  />
                  <InputField
                    width={isMobile ? '78px' : '131px'}
                    isRequired
                    label="Dígito"
                    id="accountDigit"
                    type="text"
                    mask="**"
                    value={values.accountDigit}
                    hasError={errors.accountDigit && touched.accountDigit}
                    isValid={!errors.accountDigit && touched.accountDigit}
                    errorMessage={errors.accountDigit}
                    onChange={handleChange('accountDigit')}
                    onBlur={handleBlur('accountDigit')}
                  />
                </Rows>
              </Columns>
            </BankingInformation>
            <SaveInformation>
              <ButtonV2 marginHorizontal={0} marginVertical={0} persistFloatingMode={false} text="Salvar" type="submit" />
            </SaveInformation>
          </Container>
        </form>
      )}
    </>
  )
}
