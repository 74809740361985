import { useState, useEffect, useContext } from 'react'
import { useToggle } from '~/hooks'

import { TemplateContext } from '~/components/Template/context'
import { translateDefaultRateType } from '~/screens/dashboard/helpers'
import { getChartDefaultRate } from '~/services/api'

export const useDefaultRateChart = (filters) => {
  const { isMobile } = useContext(TemplateContext)
  const [chartData, setChartData] = useState([])
  const [chartLoadingState, toggleChartLoadingState] = useToggle()

  useEffect(() => {
    const getChartData = async () => {
      try {
        toggleChartLoadingState()
        const { data } = await getChartDefaultRate(filters)
        if (!data.error) {
          setChartData(
            data.map((eachObject) => {
              return { ...eachObject, type: translateDefaultRateType(eachObject.type) }
            }),
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        toggleChartLoadingState()
      }
    }
    getChartData()
  }, [filters])

  return { isMobile, chartData, chartLoadingState }
}
