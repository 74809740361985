import React from 'react'

import { ActionsBtnFlex, BlueActionTitle } from './styles'

export const ActionsBtn = ({ svg, actionText, handleClick }) => (
  <ActionsBtnFlex onClick={handleClick}>
    {svg}
    <BlueActionTitle>{actionText}</BlueActionTitle>
  </ActionsBtnFlex>
)
