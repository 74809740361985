import { bankOptions, bankAgencyAndAccountDigits } from '~/constants/banks'

function getRegexByBank(agencyNumber) {
  if (agencyNumber) {
    const { label: agencyLabel } = bankOptions.find((bank) => bank.value === agencyNumber)
    const [, bankName] = agencyLabel.trim().split('-')

    const bankObj = bankAgencyAndAccountDigits.find((bank) => bank.bank === bankName.trimStart())

    const [numberOfAgencyDigits, agencyAfterDigit] = bankObj.agencyDigits.split('-')
    const [numberOfAccountDigits, accountAfterDigit] = bankObj.accountDigits.split('-')

    const pattern = '[0-9]'
    const agencyLengthDigits = numberOfAgencyDigits.length
    const accountLengthDigits = agencyNumber === '260' ? '8,10' : numberOfAccountDigits.length

    const regexAgency = new RegExp(`${pattern}{${agencyLengthDigits}}`, 'g')
    const regexAccount = new RegExp(`${pattern}{${accountLengthDigits}}`, 'g')

    return { regexAgency, regexAccount, agencyLengthDigits, accountLengthDigits, agencyAfterDigit, accountAfterDigit }
  }
}

export { getRegexByBank }
