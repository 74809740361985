import React from 'react'
import { fetchAPItoken, generateAPItoken } from './requests'
import { toast } from 'react-toastify'

export const useDeveloper = () => {
  const [apiToken, setAPItoken] = React.useState(null)
  const [isLoadingToken, setIsLoadingToken] = React.useState(true)

  const getAPItokenData = async () => {
    try {
      setIsLoadingToken(true)
      const response = await fetchAPItoken()
      if (response.status > 199 && response.status < 300) {
        setAPItoken(response.data.content['Api-Token'])
      }
    } catch (error) {
      toast('Algo de errado aconteceu buscando as informações do seu API Token 😔 Por favor, tente novamente.')
    } finally {
      setIsLoadingToken(false)
    }
  }

  const postAPItokenData = async (replaceCurrentBoolean) => {
    try {
      setIsLoadingToken(true)
      const response = await generateAPItoken(replaceCurrentBoolean)
      if (response.status > 199 && response.status < 300) {
        setAPItoken(response.data.content['Api-Token'].encryptedApiToken)
      }
    } catch (error) {
      toast('Algo de errado aconteceu gerando o seu API Token 😔 Por favor, tente novamente.')
    } finally {
      setIsLoadingToken(false)
    }
  }

  React.useEffect(() => {
    getAPItokenData()
  }, [])

  return {
    apiToken,
    isLoadingToken,
    postAPItokenData,
  }
}
