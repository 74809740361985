import React, { useContext, useMemo, useRef } from 'react'
import { ptBR } from 'date-fns/locale'
import { DateRangePicker } from 'react-date-range'
import { Big } from 'big.js'
import Selector from 'react-select'
import { Menu, Option, MultiValue, animatedComponents, colourStyles } from '~/components/CheckboxSelector/Options'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { TemplateContext } from '~/components/Template/context'
import { parseCurrency } from '~/utils'
import { QuestionMark } from '~/assets/svgs/QuestionMark'
import { Popover } from '~/components/Popover'
import { useSalesFunnel } from './hooks'
import { staticRanges, inputRanges } from '../dateRanges'
// styles
import {
  Wrapper,
  View,
  Card,
  CardTitle,
  CardHeader,
  CardSection,
  SeePaymentsRow,
  SeePaymentsButton,
  CardSeparator,
  StyledSwitchWrapper,
  SwitchLabel,
  StyledSwitch,
  RequestStatusTitle,
  RequestProgress,
  RequestProperties,
  PopoverWrapper,
  Header,
  HeaderTitle,
  CalendarWrapper,
  ContainerSelector,
} from '../styles'

export function SalesFunnel() {
  const { drawerWidth } = useContext(DrawerMenuContext)
  const { isMobile } = useContext(TemplateContext)
  const datePickerRef = useRef()

  const {
    productsFilter,
    setSelectedProducts,
    startDate,
    endDate,
    navigateToPage,
    checked,
    setChecked,
    requestStatusData,
    visible,
    setRangeDates,
    checkboxSelected,
    setCheckboxSelected,
  } = useSalesFunnel()

  const rightPosition = useMemo(() => {
    return window.innerWidth - datePickerRef.current?.getBoundingClientRect().right
  }, [])

  return (
    <Wrapper>
      <Header isMobile={isMobile} drawerWidth={drawerWidth}>
        <HeaderTitle>Funil de vendas</HeaderTitle>
        <ContainerSelector>
          <Selector
            placeholder="Filtrar por cursos"
            name="products"
            options={productsFilter}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Menu,
              Option,
              MultiValue,
              animatedComponents,
            }}
            onChange={(selected) => setCheckboxSelected(selected)}
            allowSelectAll={true}
            value={checkboxSelected}
            styles={colourStyles}
            onFilter={setSelectedProducts}
            onClear={() => setCheckboxSelected(null)}
          />
        </ContainerSelector>
      </Header>
      <View>
        <Card>
          <CardHeader>
            <CardTitle>Solicitações em Andamento</CardTitle>
            <SeePaymentsRow>
              <SeePaymentsButton onClick={() => navigateToPage('checkout/listar-checkouts')}>
                Visualizar lista de vendas
              </SeePaymentsButton>
              <StyledSwitchWrapper>
                <SwitchLabel checked={!checked}>Solicitações</SwitchLabel>
                <StyledSwitch checked={checked} onChange={setChecked} />
                <SwitchLabel checked={checked}>Valores</SwitchLabel>
              </StyledSwitchWrapper>
            </SeePaymentsRow>
          </CardHeader>
          <CardSeparator />
          <CardSection>
            <RequestStatusTitle>
              Em preenchimento
              <strong>
                {checked
                  ? parseCurrency(requestStatusData?.inProgress?.value || 0)
                  : requestStatusData?.inProgress?.requests || 0}
              </strong>
              <p>({Big(requestStatusData?.inProgress?.percentage || 0).toFixed(2)}%)</p>
              <span>(O valor total só é calculado com base nos alunos que escolheram um produto)</span>
            </RequestStatusTitle>
            <RequestProgress status="warn" percent={requestStatusData?.inProgress?.percentage || 0} />
            <RequestProperties>
              <PopoverWrapper>
                <p>Info básicas | incompleto: {requestStatusData?.inProgress?.basicInfo}</p>
                <Popover text="Aluno não completou informações pessoais">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Documentos incompletos: {requestStatusData?.inProgress?.documents}</p>
                <Popover text="Aluno não incluiu 1 ou mais documentos">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Precisa indicar avalista: {requestStatusData?.inProgress?.indication || 0}</p>
                <Popover text="Aluno ainda não indicou, ou ainda não teve um avalista aprovado">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Avalista incompleto: {requestStatusData?.inProgress?.guarantor || 0}</p>
                <Popover text="O Avalista ainda não completou/iniciou o envio dos dados">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
            </RequestProperties>
          </CardSection>
          <CardSection>
            <RequestStatusTitle>
              Pendentes de correção
              <strong>
                {checked
                  ? parseCurrency(requestStatusData?.pendingCorrection?.value || 0)
                  : requestStatusData?.pendingCorrection?.requests || 0}
              </strong>
              <p>({Big(requestStatusData?.pendingCorrection?.percentage || 0).toFixed(2)}%)</p>
            </RequestStatusTitle>
            <RequestProgress status="danger" percent={requestStatusData?.pendingCorrection?.percentage || 0} />
            <RequestProperties>
              <PopoverWrapper>
                <p>Revisão | Infos básicas: {requestStatusData?.pendingCorrection?.basicInfo || 0}</p>
                <Popover text="Provi analisou o cadastro e o aluno precisa corrigir/confirmar alguma(s) informações básicas.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Revisão | Documentos: {requestStatusData?.pendingCorrection?.documents || 0}</p>
                <Popover text="Provi analisou o cadastro e o aluno precisa corrigir um ou mais documentos.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Revisão | Avalista: {requestStatusData?.pendingCorrection?.guarantor || 0}</p>
                <Popover text="Provi analisou e o avalista precisa corrigir/confirmar informações e/ou documentos.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
            </RequestProperties>
          </CardSection>
          <CardSection>
            <RequestStatusTitle>
              Análise
              <strong>
                {checked
                  ? parseCurrency(requestStatusData?.inAnalisis?.value || 0.0)
                  : requestStatusData?.inAnalisis?.requests || 0}
              </strong>
              <p>({Big(requestStatusData?.inAnalisis?.percentage || 0).toFixed(2)}%)</p>
            </RequestStatusTitle>
            <RequestProgress status="success" percent={requestStatusData?.inAnalisis?.percentage || 0} />
            <RequestProperties>
              <PopoverWrapper>
                <p>Em análise: {requestStatusData?.inAnalisis?.inAnalisis || 0}</p>
                <Popover text="Provi está analisando a solicitação de crédito do aluno (e avalista, se houver). Análise realizada em média em 24 horas.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Aguardando assinatura: {requestStatusData?.inAnalisis?.waitingSignature || 0}</p>
                <Popover text="Contrato enviado, mas o aluno (e/ou avalista) ainda não assinaram.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
              <PopoverWrapper>
                <p>Aguardando pagamento: {requestStatusData?.inAnalisis?.waitingPayment || 0}</p>
                <Popover text="Contrato assinado, aguardando o aluno efetuar o pagamento da entrada.">
                  <QuestionMark />
                </Popover>
              </PopoverWrapper>
            </RequestProperties>
          </CardSection>
        </Card>
      </View>
      {visible && (
        <CalendarWrapper
          style={{
            position: 'fixed',
            top: '100px',
            right: rightPosition,
          }}
        >
          <DateRangePicker
            language="pt-br"
            staticRanges={staticRanges}
            inputRanges={inputRanges}
            locale={ptBR}
            ranges={[
              {
                startDate,
                endDate,
                key: 'selection',
              },
            ]}
            onChange={setRangeDates}
          />
        </CalendarWrapper>
      )}
    </Wrapper>
  )
}
