import React from 'react'

export const FinantialIcon = () => {
  return (
    <svg width="55" height="49" viewBox="0 0 55 49" fill="none" xmlns="https://www.w3.org/2000/svg">
      <rect x="10.1309" width="38.8469" height="48.7845" fill="url(#paint0_linear)" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="16" y="0" width="39" height="49">
        <path
          d="M16.1514 19.4235C16.1514 8.69617 24.8476 0 35.5748 0V0C46.3021 0 54.9983 8.69618 54.9983 19.4235V29.3611C54.9983 40.0883 46.3021 48.7845 35.5748 48.7845V48.7845C24.8476 48.7845 16.1514 40.0883 16.1514 29.3611V19.4235Z"
          fill="url(#paint1_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_f)">
          <rect
            x="7.11914"
            y="-6.10352e-05"
            width="38.8469"
            height="48.7845"
            rx="19.4235"
            fill="url(#paint2_linear)"
            fillOpacity="0.3"
          />
        </g>
      </g>
      <rect x="5.47168" width="38.8469" height="48.7845" fill="url(#paint3_linear)" />
      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="0" width="40" height="49">
        <rect x="11.4951" width="38.8469" height="48.7845" rx="19.4235" fill="url(#paint4_linear)" />
      </mask>
      <g mask="url(#mask1)">
        <g filter="url(#filter1_f)">
          <rect
            x="2.45996"
            y="-6.10352e-05"
            width="38.8469"
            height="48.7845"
            rx="19.4235"
            fill="url(#paint5_linear)"
            fillOpacity="0.3"
          />
        </g>
      </g>
      <rect x="0.351562" width="38.8469" height="48.7845" fill="url(#paint6_linear)" />
      <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="0" width="40" height="49">
        <rect x="6.375" width="38.8469" height="48.7845" rx="19.4235" fill="url(#paint7_linear)" />
      </mask>
      <g mask="url(#mask2)">
        <g filter="url(#filter2_f)">
          <rect
            x="-2.66016"
            y="-6.10352e-05"
            width="38.8469"
            height="48.7845"
            rx="19.4235"
            fill="url(#paint8_linear)"
            fillOpacity="0.3"
          />
        </g>
      </g>
      <rect x="0.351562" width="38.8469" height="48.7845" rx="19.4235" fill="url(#paint9_linear)" />
      <rect x="8.48047" y="9.03409" width="22.5854" height="2.71025" rx="1.35513" fill="white" />
      <rect x="8.48047" y="14.7558" width="22.5854" height="2.40911" rx="1.20456" fill="white" fillOpacity="0.5" />
      <rect x="8.48047" y="20.1763" width="22.5854" height="2.40911" rx="1.20456" fill="white" fillOpacity="0.2" />
      <rect x="8.48047" y="35.5344" width="22.5854" height="8.13075" rx="4.06538" fill="white" fillOpacity="0.2" />
      <defs>
        <filter
          id="filter0_f"
          x="-7.88086"
          y="-15.0001"
          width="68.8469"
          height="78.7845"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter1_f"
          x="-12.54"
          y="-15.0001"
          width="68.8469"
          height="78.7845"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter2_f"
          x="-17.6602"
          y="-15.0001"
          width="68.8469"
          height="78.7845"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient id="paint0_linear" x1="29.5543" y1="0" x2="29.5543" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="35.5748"
          y1="-6.39917e-09"
          x2="35.5748"
          y2="48.7845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="26.5426"
          y1="-6.10352e-05"
          x2="26.5426"
          y2="48.7845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A44A6" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="24.8951" y1="0" x2="24.8951" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="30.9186" y1="0" x2="30.9186" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="21.8834"
          y1="-6.10352e-05"
          x2="21.8834"
          y2="48.7845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A44A6" />
        </linearGradient>
        <linearGradient id="paint6_linear" x1="19.775" y1="0" x2="19.775" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient id="paint7_linear" x1="25.7985" y1="0" x2="25.7985" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="16.7633"
          y1="-6.10352e-05"
          x2="16.7633"
          y2="48.7845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A44A6" />
        </linearGradient>
        <linearGradient id="paint9_linear" x1="19.775" y1="0" x2="19.775" y2="48.7845" gradientUnits="userSpaceOnUse">
          <stop stopColor="#216EF1" />
          <stop offset="1" stopColor="#07BFEF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
