import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const MobileFallback = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  place-items: center;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor || theme.colors.bluePrimaryLight};

  p {
    font-size: 13.2px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
`

export const PageWrapper = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    padding: initial;
  }
`

export const Container = styled.div`
  display: grid;
  padding: 0 30px 30px;
  min-height: 100vh;
  gap: 2rem;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    padding: 0 0 30px 0;
  }
`

export const LayoutGrid = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 1280px;
  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`

export const ContainerSelector = styled.div`
  max-width: 450px;
`
