import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { InputWrapper, TextFieldWrapper } from './styles'

export const Input = ({ isDark, placeholder, icon, error, ...props }) => {
  return (
    <InputWrapper isDark={isDark}>
      <TextFieldWrapper
        variant="outlined"
        placeholder={placeholder}
        margin="normal"
        error={error}
        {...props}
        InputProps={{
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        }}
      />
    </InputWrapper>
  )
}

Input.defaultProps = {
  isDark: false,
  placeholder: '',
  value: undefined,
  icon: null,
  error: false,
  mask: '',
}
