import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useQuery } from 'react-query'
import { showToast } from '@provi/provi-components'
import { history } from '~/navigation/history'
import { getAccess } from '~/services/api'
import { logger } from '~/helpers/logger'
import { resetGA } from '~/utils/googleAnalytics'

export const useModal = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [showLogoutModal, SetShowLogoutModal] = useState(false)

  const handleModal = (option) => {
    setIsShowModal(option)
  }

  const handleLogoutModal = (option) => {
    SetShowLogoutModal(option)
  }

  return [isShowModal, handleModal, showLogoutModal, handleLogoutModal]
}

export const useInfos = (handleLogoutModal) => {
  const [modal, setModal] = useState(null)

  const logout = () => {
    Sentry.configureScope((scope) => scope.setUser(null))
    resetGA()
    history.push('/')

    handleLogoutModal(false)
  }

  return [modal, setModal, logout]
}

// export const useLoading = () => {
//   const [isLoading, setIsLoading] = useState(true)

//   return [isLoading, setIsLoading]
// }

export const useHeader = () => {
  const [current, setNewCurrent] = useState(0)
  const [refActive, setRefActive] = useState(1)
  const [options, setOptions] = useState([
    {
      ref: 1,
      title: 'PARTE 1',
      text: 'SELEÇÃO DE PRODUTOS',
      active: true,
      complete: false,
    },
    {
      ref: 2,
      title: 'PARTE 2',
      text: 'DETALHES DA VENDA',
      active: false,
      complete: false,
    },
  ])

  useEffect(() => {
    const optionsNew = options.map((item) => {
      item.active = false
      item.complete = false

      if (item.ref < refActive) {
        item.complete = true
      }
      if (item.ref === refActive) {
        item.active = true
      }
      return item
    })
    setOptions(optionsNew)
  }, [current, refActive])

  const setCurrent = (curr, ref) => {
    setNewCurrent(curr)
    setRefActive(ref)
  }

  return [setCurrent, options, current]
}

export const useScreenControl = (setIsLoading) => {
  const [activePage, setActivePage] = useState('')

  const goToPage = async (path, user) => {
    setIsLoading(true)

    try {
      let userActive
      if (user) {
        const href = new URL(window.location.href)
        href.searchParams.set(`u`, user)
        window.history.pushState(null, null, href)
        userActive = user
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        userActive = urlParams.get('u')

        if (!userActive) {
          showToast('Usuário não identificado')
          history.push('/login')
        }
      }

      if (path) {
        setIsLoading(false)

        history.push(`/${path}?u=${userActive}`)
      }
    } catch (error) {
      logger({ error })
    }
  }

  return [goToPage, activePage, setActivePage]
}

export const useAuth = () => {
  const [accessData, setAccessData] = useState()

  const urlParams = new URLSearchParams(window.location.search)
  let userActive = urlParams.get('u')

  useQuery(['accessContext', userActive], getAccess, {
    enabled: !!userActive,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAccessData(data?.data?.data)
    },
  })

  return [accessData]
}
