import styled from 'styled-components'
import { CheckBox, SelectorV3, theme } from '@provi/provi-components'

export const Checkbox = styled(CheckBox)``

export const ItemMenu = styled.div`
  padding-top: 8px;
  label {
    word-wrap: break-word;
    display: flex;
  }
  span::after {
    box-sizing: content-box !important;
  }
`

export const Selector = styled(SelectorV3).attrs((props) => ({
  inputProps: { 'aria-label': { name: props.name } },
  hideSelected: true,
  width: '450px',
  styles: {
    multiValue: (provided) => ({
      ...provided,
      maxWidth: 450,
    }),
    option: () => ({
      padding: 5,
      height: 40,
      fontSize: 14,
      paddingLeft: 13,
      color: theme.colors.blue800,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey500,
      },
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.grey500}`,
      borderRadius: 5,
      height: 42,
      fontFamily: 'Montserrat, sans serif',
      cursor: 'pointer',
      minWidth: 250,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      lineHeight: 34,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      fontFamily: 'Montserrat, sans serif',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.pink900,
      marginRight: 8,
    }),
    placeholder: () => ({
      fontFamily: 'Montserrat, sans serif',
      color: theme.colors.grey600,
      fontSize: 16,
    }),
    indicatorSeparator: () => ({}),
    menuList: (menuList) => ({
      ...menuList,
      padding: 0,
      zIndex: 100,
    }),
    menu: (provided) => ({
      ...provided,
      borderCollapse: 'separate',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'Montserrat, sans serif',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 16px',
      fontSize: 20,
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }),
  },
}))``
