import React from 'react'

export const ArrowMore = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2 7.77441L7.875 13.6494L14.174 7.3504"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
