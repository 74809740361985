import React, { useContext } from 'react'
import { TableComponent, Modal, ButtonV2 } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import {
  Container,
  ModalContainer,
  ModalButtons,
  Content,
  Header,
  HeaderName,
  HeaderButton,
  TransactionSection,
  TransactionHeader,
  Row,
  TransactionButton,
} from './styles'
import { TransactionCard } from '~/components/v2/TransactionCard'
import { usePartnersTransactions } from './hooks'
import masks from '~/themes/masks'

export const PartnerTransactions = () => {
  const { isMobile, setIsLoading, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const {
    showModal,
    setShowModal,
    iuguBalance,
    handleClickWithdraw,
    withdrawBalance,
    handleWithdraw,
    rows,
    headers,
    mobileHeaders,
    customPagination,
    handleBackButton,
    handleNextButton,
  } = usePartnersTransactions({ setIsLoading })

  return (
    <Container isMobile={isMobile} drawerWidth={drawerWidth}>
      {showModal && (
        <ModalContainer>
          <Modal
            title={`Confirma o agendamento do saque no valor de ${masks.currency(withdrawBalance)}?`}
            onDismiss={() => setShowModal(false)}
            containerStyle={{ borderRadius: 5, paddingTop: 5, paddingRight: 5, paddingBottom: 35 }}
          >
            Lembre-se, esse valor pode variar de acordo com mudanças em algumas vendas até o dia do saque.
            <ModalButtons>
              <ButtonV2 text="Cancelar" outline={true} icon={' '} onClick={() => setShowModal(false)} />
              <ButtonV2 text="Confirmar saque" icon={' '} onClick={() => handleWithdraw()} />
            </ModalButtons>
          </Modal>
        </ModalContainer>
      )}
      <Content>
        <Header>
          <HeaderName>Saques</HeaderName>
          {!isMobile && (
            <div>
              <HeaderButton onClick={() => goToPage('perfil/dados-financeiros')}>Configurações de saque</HeaderButton>
            </div>
          )}
        </Header>

        <TransactionSection>
          <TransactionHeader>Saldo Iugu</TransactionHeader>
          <Row>
            <TransactionCard
              available={false}
              popoverType="availableWithdraw"
              title="Saldo disponível"
              amount={masks.currency(iuguBalance?.availableBalance)}
            >
              {isMobile && (
                <TransactionButton
                  onClick={() => {
                    handleClickWithdraw('iugu')
                  }}
                  disabled={!iuguBalance || iuguBalance?.isDefault || iuguBalance?.availableBalance < 500}
                >
                  Efetuar saque
                </TransactionButton>
              )}
            </TransactionCard>
            <TransactionCard
              available={false}
              popoverType="pendingWithdraw"
              title="Saldo pendente"
              amount={masks.currency(iuguBalance?.pendingBalance)}
            />
          </Row>
          {!isMobile && (
            <TransactionButton
              onClick={() => {
                handleClickWithdraw('iugu')
              }}
              disabled={!iuguBalance || iuguBalance?.isDefault || iuguBalance?.availableBalance < 500}
            >
              Efetuar saque
            </TransactionButton>
          )}
        </TransactionSection>

        {!!rows.length && (
          <TransactionSection>
            <TransactionHeader style={{ marginBottom: 24 }}>Histórico de saques</TransactionHeader>
            <TableComponent
              headers={headers}
              rows={rows}
              mobileHeaders={mobileHeaders}
              customPagination={customPagination}
              handleBackButtonClick={handleBackButton}
              handleNextButtonClick={handleNextButton}
            />
          </TransactionSection>
        )}
      </Content>
    </Container>
  )
}
