import { useFormik } from 'formik'
import { useState, useContext, useEffect } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { mapToOptions } from './helpers/mapToOptions'
import { postCourse } from '~/screens/courses/requests'
import { getV4MacroAreas, getV4MicroAreas, getFinancingConditions } from 'services/api'
import { toast } from 'react-toastify'
import { usePartnerCommercialTrack } from '~/hooks'
import { getCourseSchema } from '~/screens/courses/helpers'

import masks from '~/themes/masks'
import { isV3ProvipayService } from 'services/ms-checkout/v4'

export const useAddCourse = ({ setIsLoading }) => {
  const [financingConditions, setFinancingConditions] = useState([])

  const { goToPage, accessData } = useContext(TemplateContext)
  const { lowerCasedCommercialTrackString } = usePartnerCommercialTrack()
  const regexOnlyNumbers = /^[0-9]*$/
  const formik = useFormik({
    initialValues: {
      courseName: '',
      courseArea: null,
      courseSpecialty: '',
      courseValue: '',
      courseValueFormatted: '',
      courseWorkload: '',
      courseDisclaimer: true,
      courseDescription: '',
      hasSelectionProcess: false,
      financingCondition: null,
    },
    onSubmit: async ({
      courseArea,
      courseDescription,
      courseName,
      courseDisclaimer,
      courseSpecialty,
      courseValue,
      courseWorkload,
      hasSelectionProcess,
      financingCondition,
    }) => {
      const courseBody = {
        name: courseName.trim(),
        price: parseFloat(courseValue) / 100,
        priceInCents: Number(courseValue),
        MacroAreaId: courseArea.id,
        MicroAreaId: courseSpecialty.id,
        workload: parseInt(courseWorkload) || 0,
        visible: courseDisclaimer,
        hasSelectionProcess,
        ...(financingCondition && { maxInstallmentsToApply: financingCondition.value }),
        ...(courseDescription && { description: courseDescription?.trim() }),
      }

      createCourse(courseBody)
    },
    validationSchema: getCourseSchema(lowerCasedCommercialTrackString),
    validate: values => {
      let errors = {}
      const { hasProductValueLimit = true } = accessData || {}

      if (values.courseName !== '' && values.courseName.match(regexOnlyNumbers)) {
        errors.courseName = 'O nome não pode conter apenas números'
      }

      if (!accessData) return errors

      // TODO: Remove this condition and add it to ms_product endpoint
      if (values.courseValue > 4500000 && hasProductValueLimit) {
        errors.courseValue = 'O preço do curso não pode exceder R$ 45.000,00'
      }

      return errors
    },
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
  })

  const [courseAreaOptions, setCourseAreaOptions] = useState([])
  const [courseSpecialtyOptions, setCourseSpecialtyOptions] = useState([])

  function handleResetForm() {
    formik.resetForm()
    goToPage('cursos')
  }

  const [useV3, setUseV3] = useState(false)

  useEffect(() => {
    setCourseArea()

    const checkVersion = async () => {
      const isV3 = await isV3ProvipayService({ setIsLoading })
      setUseV3(isV3)
    }

    checkVersion()
  }, [setCourseArea])

  useEffect(() => {
    const fetchFinancingConditions = async () => {
      const { options } = await getFinancingConditions({ formik })

      setFinancingConditions(options)
      formik.setFieldValue('financingCondition', options.at(-1))
    }

    fetchFinancingConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createCourse = async formObj => {
    try {
      setIsLoading(true)
      const response = await postCourse(formObj)

      if (response.status > 199 && response.status < 300) {
        toast('Curso cadastrado com sucesso!')
        setTimeout(() => {
          goToPage('cursos')
        }, 1500)
      }
    } catch (error) {
      toast(
        error.response?.data?.error?.message ||
          error.response?.data?.errors?.[0]?.message ||
          'Algo de errado aconteceu criando o seu curso 😔 Por favor, tente novamente.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const hasErrors = !!Object.keys(formik.errors).length

  const setCourseArea = async () => {
    const response = await getV4MacroAreas({ setIsLoading })

    if (!response || !response.metadata || !response.content) return

    const { metadata, content: macroAreas } = response

    const macroAreaOptions = mapToOptions(macroAreas)

    setCourseAreaOptions(macroAreaOptions)

    if (metadata.defaultMacroAreaId) {
      const defaultArea = macroAreaOptions.find(area => area.id === metadata.defaultMacroAreaId)

      handleCourseArea(defaultArea)
    }
  }

  const handleCourseArea = async macroArea => {
    if (macroArea.id !== formik.values.courseArea?.id) {
      formik.setFieldValue('courseArea', macroArea)

      const response = await getV4MicroAreas({ macroAreaId: macroArea.id, setIsLoading })

      if (!response || !response.metadata || !response.content) return

      const { content: microAreas } = response

      const microAreaOptions = mapToOptions(microAreas)

      setCourseSpecialtyOptions(microAreaOptions)

      formik.setFieldValue('courseSpecialty', null)
    }
  }

  const handleSelectFinancingCondition = async financingCondition => {
    if (!financingCondition) return

    formik.setFieldValue('financingCondition', financingCondition)
  }

  const handleMicroArea = microArea => {
    formik.setFieldValue('courseSpecialty', microArea)
  }

  const handleMaskInputChange = async e => {
    const { value } = e.target

    formik.setFieldValue('courseValueFormatted', masks.currency(value))

    formik.setFieldValue('courseValue', masks.unmask(value))

    await formik.validateField('courseValue')
  }

  return {
    formik,
    hasErrors,
    courseAreaOptions,
    courseSpecialtyOptions,
    setCourseArea,
    handleCourseArea,
    handleMicroArea,
    handleMaskInputChange,
    handleResetForm,
    financingConditions,
    handleSelectFinancingCondition,
    useV3,
  }
}
