import masks from 'themes/masks'

export const validateCourseClassPrice = ({ courseData, values, accessData }) => {
  if (!courseData || !accessData) return { valid: true }

  const { priceInCents } = values
  const { price } = courseData
  const { hasProductValueLimit } = accessData

  const formattedPrice = parseInt(masks.unmask(price))
  const formattedProductLimit = 45_000 * 100
  const productMin = 30 * 100

  const shouldExceptMin = priceInCents < productMin
  const shouldBiggerThanCourse = priceInCents > formattedPrice
  const shouldBiggerThanAccess = priceInCents > formattedProductLimit && hasProductValueLimit

  if (shouldBiggerThanCourse) {
    return { error: 'O preço da turma não pode ser maior do que o preço do curso', valid: false }
  }

  if (shouldBiggerThanAccess) {
    return { error: 'O preço da turma não pode exceder R$ 45.000,00', valid: false }
  }

  if (shouldExceptMin) {
    return { error: 'O preço da turma não pode ser menor do que R$ 30,00', valid: false }
  }

  return { valid: true }
}
