import styled from 'styled-components'
import { theme, PortalModal } from '@provi/provi-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const CancellationsCardGrid = styled(BaseGrid)`
  padding: 1.75rem;
  max-width: 900px;
  background-color: #ffffff;
  gap: 2rem;
  border: 1px solid ${theme.colors.pink900};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`
export const SpaceBetweenContainer = styled(BaseFlexContainer)`
  gap: 1rem;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`

export const InformationGrid = styled(BaseGrid)`
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

export const FlexColumn = styled(BaseFlexContainer)`
  flex-direction: column;
`
export const ButtonsFlex = styled(BaseFlexContainer)`
  margin-top: 1rem;
  gap: 1rem;
`

export const ActionsGrid = styled(BaseGrid)`
  gap: 2rem;
`

export const ButtonWrapper = styled.div`
  div {
    width: revert;
    text-align: left;

    .MuiButton-text {
      padding: 1rem 1.5rem;
    }

    button {
      width: revert;
      ${theme.breakpoints.down('md')} {
        height: revert;
      }
    }
  }
`

export const StyledPortalModal = styled(PortalModal)`
  border-radius: 16px;
  ${theme.breakpoints.down('md')} {
    max-width: calc(100% - 40px);
    border-radius: 8px;
  }
`

export const ModalContainer = styled.div`
  padding: 0.5rem;
  display: grid;
  gap: 2rem;
  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`
