import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const PageWrapper = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    padding: initial;
  }
`

export const Container = styled.div`
  /* padding: 0px 30px 30px; */
  /* margin: 0 auto; */
  width: 100%;
  /* max-width: 1280px; */

  background-color: #f6f8fb;

  ${theme.breakpoints.down('md')} {
    padding: 0 0 30px 0;
  }
`

export const HeaderContainer = styled.div`
  padding: 2rem 0;
  width: 100%;
  max-width: 920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cfcfcf;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    width: 100%;
    max-width: 100%;
  }
`
export const TextFlexContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`

export const LayoutGrid = styled.div`
  display: grid;
  gap: 2rem;
  /* max-width: 1280px; */
  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`

export const Component1 = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 500px;
  background-color: lightblue;
`

export const ContainerSelector = styled.div`
  max-width: 450px;
`
