import React from 'react'
import Select from 'react-select'
import { theme } from '@provi/provi-components'

import { customSelectStyles } from './styles'

export const DashboardSelect = ({
  placeholderText,
  selectOptions,
  valueState,
  valueChangeFn,
  isMobile,
  isRed,
  disableCondition,
}) => {
  return (
    <Select
      placeholder={placeholderText}
      value={selectOptions ? selectOptions.find((option) => option.value === valueState) : ''}
      options={selectOptions}
      onChange={(option) => valueChangeFn(option.value)}
      isSearchable={false}
      styles={customSelectStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      myFontSize={isMobile ? '12px' : '16px'}
      isDisabled={disableCondition}
      myIconColor={isRed ? theme.colors.pink900 : theme.colors.bluePrimary}
      myPadding={isMobile ? '4px' : '6px'}
    />
  )
}
