import React from 'react'

export const CircleX = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19.5C15.2467 19.5 19.5 15.2467 19.5 10C19.5 4.75329 15.2467 0.5 10 0.5C4.75329 0.5 0.5 4.75329 0.5 10C0.5 15.2467 4.75329 19.5 10 19.5Z"
      stroke={color || 'black'}
      strokeMiterlimit="10"
    />
    <path d="M13.1667 6.83334L6.83334 13.1667" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1667 13.1667L6.83334 6.83334" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
