import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Button = styled.div`
  width: 234px;
  height: 46px;
  border-radius: 100px;
  background-color: ${({ disabled }) => (!disabled ? theme.colors.pink900 : theme.colors.grey600)};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  line-height: 30px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.grey400};

  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};

  margin-bottom: 10px;

  &:hover {
    opacity: ${({ disabled }) => (!disabled ? '0.9' : 'unset')};
  }

  svg {
    margin-right: 10px;
  }
`

export const WrapperFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
