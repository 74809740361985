import React from 'react'

import { EachDetail } from '..'

import { AutoRowGrid } from '../styles'

export const CreditCardDetails = ({ cridDetails, formatValue, fallbackString }) => {
  return (
    <AutoRowGrid>
      <EachDetail
        title={'Produto'}
        value={cridDetails?.products?.map((eachProduct, index) => (index ? ', ' : '') + eachProduct) || fallbackString}
      />
      <EachDetail title={'Valor da venda'} value={formatValue(cridDetails?.value) || fallbackString} />
    </AutoRowGrid>
  )
}
