import React, { useContext } from 'react'

import { TemplateContext } from '~/components/Template/context'
import { FormFormik } from './form'
import { useReset } from './hooks'

export const ResetPassword = () => {
  const { setIsLoading } = useContext(TemplateContext)
  useReset(setIsLoading)

  return <FormFormik setIsLoading={setIsLoading} />
}
