import { isEmpty } from 'lodash'
import { formatDatePickerStr } from './formatDatePickerStr'
import { transpileFiltersToStart } from '~/helpers/tables/translateFilters'

export const setUrlQueryFilters = (showCells, page, filterStatus, setSelectedByUserCourses) => {
  const href = new URL(window.location.href)

  if (page) {
    href.searchParams.set('page', page)
    window.history.pushState(null, null, href)
  }

  if (!isEmpty(filterStatus)) {
    showCells.forEach((cells) => {
      if (cells.selectValues && filterStatus[cells.item]) {
        if (cells.selectValues.length !== filterStatus[cells.item].length || cells.isChanged) {
          if (filterStatus[cells.item] && filterStatus[cells.item].length) {
            const formatted =
              (filterStatus[cells.item].length &&
                filterStatus[cells.item].map((filter) => (filter === null ? 'CLEAR' : filter))) ||
              filterStatus[cells.item]

            href.searchParams.set(cells.item, formatted.toString())

            if (filterStatus.products && setSelectedByUserCourses) {
              setSelectedByUserCourses(filterStatus.products)
            }
            window.history.pushState(null, null, href)
          } else {
            href.searchParams.delete(cells.item, '')
          }
        }
      }

      if (cells.isDate && cells.isChanged) {
        href.searchParams.set(`${cells.item}_start`, formatDatePickerStr(cells.startDate, cells))
        window.history.pushState(null, null, href)

        href.searchParams.set(`${cells.item}_end`, formatDatePickerStr(cells.endDate, cells))
        window.history.pushState(null, null, href)
      } else if (
        cells.isDate &&
        !cells.isChanged &&
        !filterStatus[transpileFiltersToStart(cells.item)] &&
        filterStatus[transpileFiltersToStart(cells.item)] !== null
      ) {
        href.searchParams.delete(`${cells.item}_start`, '')
        href.searchParams.delete(`${cells.item}_end`, '')
        window.history.pushState(null, null, href)
      }
    })
  }
}
