/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from '@provi/provi-components'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text, Bold, Br } from './styles'

export const Modal = ({ handleModal, location, optionChange, submitHandleEye }) => {
  const texts = {
    active: {
      title: 'Tem certeza que deseja tornar a campanha inativa?',
      text: (
        <Text>
          <Bold>Campanhas ativas</Bold> permitem que usuários que acessem o link e iniciem uma nova solicitação com as condições
          que ela oferece.
          <Br />
          <Br />
          <Bold>Campanhas inativas</Bold> tem o link desativado, porém os usuários que já acessaram antes dela ser desativada,
          podem completar sua solicitação.
        </Text>
      ),
    },
    inactive: {
      title: 'Tem certeza que deseja tornar a campanha ativa?',
      text: (
        <Text>
          <Bold>Campanhas ativas</Bold> permitem que usuários que acessem o link e iniciem uma nova solicitação com as condições
          que ela oferece.
          <Br />
          <Br />
          <Bold>Campanhas inativas</Bold> tem o link desativado, porém os usuários que já acessaram antes dela ser desativada,
          podem completar sua solicitação.
        </Text>
      ),
    },
  }

  return (
    <ModalComponent
      isWithoutHeight
      title={texts[location].title}
      onDismiss={() => handleModal(false)}
      footer={<Button text="Confirmo" onClick={() => submitHandleEye(location, optionChange)} />}
    >
      {texts[location].text}
    </ModalComponent>
  )
}
