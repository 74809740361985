import React from 'react'
import { ButtonV3, InputFieldV3 } from '@provi/provi-components'
import { Formik, Field, Form } from 'formik'
import { Collapse } from '@material-ui/core'
import * as Yup from 'yup'

import { ArrowDown } from '~/assets/svgs/v2'
import { useAddAccount } from './hooks'
import {
  AddAccountContainer,
  BlueHeader,
  StyledInput,
  BoldLabel,
  BlackParagraph,
  DropdownContainer,
  FlexContainer,
} from './styles'

export const AddAccount = () => {
  const { keyError, dropdownState, toggleDropdownState, mutate } = useAddAccount()
  return (
    <Formik
      initialValues={{
        name: '',
        secretKey: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Digite um nome').max(42, 'Máximo de 42 caracteres atingido'),
        secretKey: Yup.string().required('Digite uma chave'),
      })}
      onSubmit={async (values) => {
        mutate(values)
      }}
    >
      <Form>
        <AddAccountContainer>
          <BlueHeader>Adicionar conta MemberKit</BlueHeader>
          <StyledInput>
            <BoldLabel>Nome da conta</BoldLabel>
            <BlackParagraph>
              Crie um nome para a conta vinculada entre a Provi e a MemberKit (Máximo de 42 caracteres)
            </BlackParagraph>
            <Field name={'name'}>
              {({ field, meta }) => (
                <InputFieldV3
                  id={'name'}
                  name={'name'}
                  type={'text'}
                  width="100%"
                  placeholder={'Ex: Minha conta MemberKit'}
                  {...field}
                  hasError={meta.error}
                  errorMessage={meta.error}
                />
              )}
            </Field>
          </StyledInput>
          <StyledInput>
            <BoldLabel>Chave secreta</BoldLabel>
            <BlackParagraph>Insira a chave secreta da MemberKit para que os dados da conta sejam integrados</BlackParagraph>
            <Field name={'secretKey'}>
              {({ field, meta }) => (
                <InputFieldV3
                  id={'secretKey'}
                  name={'secretKey'}
                  type={'text'}
                  width="100%"
                  {...field}
                  hasError={meta.error || keyError}
                  errorMessage={meta.error || keyError}
                />
              )}
            </Field>
          </StyledInput>
          <DropdownContainer onClick={toggleDropdownState}>
            <FlexContainer state={dropdownState}>
              <BlueHeader isFromDropdown>Onde encontrar minha chave secreta?</BlueHeader>
              <ArrowDown />
            </FlexContainer>
            <Collapse in={dropdownState}>
              <BlackParagraph>
                Acesse a sua conta da MemberKit e, no menu lateral, clique em <strong>Configurações.</strong> A chave secreta
                fica na seção <strong>Código.</strong>
              </BlackParagraph>
            </Collapse>
          </DropdownContainer>
          <ButtonV3 text="Adicionar conta" mobileWidth={'100%'} icon buttonProps={{ type: 'submit' }} />
        </AddAccountContainer>
      </Form>
    </Formik>
  )
}
