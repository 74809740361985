import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseGrid, BaseFlexContainer } from '~/globalStyles'

export const ClassroomDetailsGrid = styled(BaseGrid)`
  gap: 1.5rem;
`

export const SpaceBetweenContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    justify-content: revert;
    flex-direction: column;
    gap: 1.5rem;
  }

  ${({ isReverse }) =>
    isReverse &&
    css`
      ${theme.breakpoints.down('md')} {
        flex-direction: column-reverse;
      }
    `}
`
export const FlexColumns = styled(BaseFlexContainer)`
  flex-direction: column;
  gap: 1.5rem;
`
