import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { CourseDetails, NestedSwitch, AddClassroom } from '~/screens/courses/components/DetailsComponents/components'

export const DetailsComponents = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <CourseDetails />
      </Route>
      <Route path={`${path}/nova-turma`}>
        <AddClassroom />
      </Route>
      <Route path={`${path}/:classroomId`}>
        <NestedSwitch />
      </Route>
    </Switch>
  )
}
