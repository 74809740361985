/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'

import { WrapperProduct } from './styles'
import { useProductLink } from './hooks'

export const ProductLink = (props) => {
  const { setIsLoading } = useContext(TemplateContext)

  const {
    children,
    handleModal,
    nextPaymentData,
    setNextPaymentData,
    courses,
    setProductData,
    target,
    lastUpdate,
    setLastUpdateData,
    isDelayed = false,
    installmentData,
    installmentValueData,
    underlineColor,
    loanOptionCommentData,
  } = props

  const [handleOption] = useProductLink(
    setIsLoading,
    handleModal,
    courses,
    setProductData,
    lastUpdate,
    setLastUpdateData,
    nextPaymentData,
    setNextPaymentData,
    installmentData,
    installmentValueData,
    loanOptionCommentData,
  )

  return (
    <WrapperProduct isDelayed={isDelayed} underlineColor={underlineColor} onClick={() => handleOption(target)}>
      {children}
    </WrapperProduct>
  )
}
