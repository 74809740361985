/* eslint-disable react/prop-types */
import React from 'react'

export const PlusIcon = ({ color = '#B0AFAF' }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PlusIconWithBorder = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M12.4999 7.62096V17.379M7.62087 12.5H17.3789M20.6317 4.36827C25.1228 8.8593 25.1228 16.1407 20.6317 20.6317C16.1407 25.1228 8.8593 25.1228 4.36827 20.6317C-0.122757 16.1407 -0.122757 8.8593 4.36827 4.36827C8.8593 -0.122758 16.1407 -0.122757 20.6317 4.36827Z"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
