import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'

import { Container } from './styles'

export const SimpleModal = (props) => {
  const { handleModal, show, data, title } = props

  return (
    <>
      {show && data && (
        <ModalComponent isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('simple-modal')}>
          <Container>
            {title && (
              <Text size="16px" lineHeight="30px" bold>
                {title}
              </Text>
            )}
            <Text size="16px" lineHeight="30px">
              {data}
            </Text>
          </Container>
        </ModalComponent>
      )}
    </>
  )
}
