import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;

  ${theme.breakpoints.down('md')} {
    min-height: 200px;
  }
`

export const LeftColumn = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    padding: 15px 20px;
    min-height: 200px;
  }
`

export const StyledSpan = styled.span`
  font-family: 'Montserrat-ExtraBold', sans-serif;
  line-height: 24px;
  font-size: 18px;
  color: ${theme.colors.pink900};
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const VanishingWrapper = styled.div`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`
