import React from 'react'

export const MixedModalityIcon = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '24'}
    height={size || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.60788 19.4398C1.43654 19.6563 1.47321 19.9708 1.68978 20.1421C1.90634 20.3135 2.22079 20.2768 2.39212 20.0602L1.60788 19.4398ZM14.6079 20.0598C14.7792 20.2764 15.0937 20.313 15.3103 20.1417C15.5268 19.9703 15.5634 19.6559 15.3921 19.4393L14.6079 20.0598ZM12.625 11.875C12.625 14.2912 10.6662 16.25 8.25 16.25V17.25C11.2185 17.25 13.625 14.8435 13.625 11.875H12.625ZM8.25 16.25C5.83375 16.25 3.875 14.2912 3.875 11.875H2.875C2.875 14.8435 5.28147 17.25 8.25 17.25V16.25ZM3.875 11.875C3.875 9.45875 5.83375 7.5 8.25 7.5V6.5C5.28147 6.5 2.875 8.90647 2.875 11.875H3.875ZM8.25 7.5C10.6662 7.5 12.625 9.45875 12.625 11.875H13.625C13.625 8.90647 11.2185 6.5 8.25 6.5V7.5ZM2.39212 20.0602C3.07389 19.1985 3.98331 18.4898 5.04728 17.9988L4.62822 17.0908C3.42961 17.644 2.39241 18.4481 1.60788 19.4398L2.39212 20.0602ZM5.04728 17.9988C6.11133 17.5077 7.29573 17.25 8.49989 17.25L8.49985 16.25C7.15445 16.25 5.82674 16.5376 4.62822 17.0908L5.04728 17.9988ZM8.49989 17.25C9.70405 17.25 10.8885 17.5075 11.9526 17.9985L12.3716 17.0905C11.173 16.5375 9.84526 16.25 8.49985 16.25L8.49989 17.25ZM11.9526 17.9985C13.0166 18.4895 13.9261 19.1981 14.6079 20.0598L15.3921 19.4393C14.6075 18.4477 13.5702 17.6437 12.3716 17.0905L11.9526 17.9985Z"
      fill="black"
    />
    <path
      d="M8 5.29412V3.28105C8 2.57354 8.55964 2 9.25 2H21.75C22.4404 2 23 2.57354 23 3.28105V12.2484C23 12.9559 22.4404 13.5294 21.75 13.5294H14.5M19 7.76471L14.8333 5.29412V10.2353L19 7.76471Z"
      stroke={color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
