import React, { useContext } from 'react'
import { isArray } from 'lodash'

import { theme, TextLink } from '@provi/provi-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { TemplateContext } from '~/components/Template/context'
import { Title } from '~/components/title'
import { SubTitle } from '~/components/sub-title'
import { Search } from '~/components/search'
import { Header } from '~/components/Header'

import { Text } from '~/themes/styles'

import { ArrowMore } from '~/assets/svgs/arrow-more'
import { BlueCheck } from '~/assets/svgs/blue-check'

import formatDate from '~/helpers/formatDate'

import { useCourseClass } from './hooks'

import {
  Container,
  Content,
  SectionHeader,
  WrapperText,
  WrapperSearchBar,
  SectionClasses,
  Option,
  Grid,
  RedTitle,
  WrapperIcon,
  HoverCard,
  WrapperTextLink,
  DownloadMore,
  WrapperTabs,
} from './styles'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

export const List = () => {
  const { setIsLoading, handleLogoutModal, activePage, setActivePage, goToPage, setEditOption, isMobile } = useContext(
    TemplateContext,
  )
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    handleSearchValue,
    search,
    incrementNumberOfCourseClasses,
    showSeeMore,
    optionsShow,
    visibleOpt,
    setVisibleOpt,
    handleEditCourseClass,
  ] = useCourseClass(setIsLoading, activePage, setActivePage, goToPage, setEditOption)

  return (
    <>
      <Header isCampaign={false} isNewSale={false} />
      <Container>
        <Content isMobile={isMobile} drawerWidth={drawerWidth}>
          <SectionHeader>
            <WrapperText>
              <Title text="Gerenciamento de turmas" />
              <SubTitle text="Escolha abaixo a turma ou crie uma nova:" />
            </WrapperText>
            <WrapperSearchBar>
              <Search type="text" placeholder="Pesquise aqui" onChange={handleSearchValue} value={search} />
            </WrapperSearchBar>

            <WrapperTextLink>
              <TextLink onClick={() => goToPage('turmas/formulario-turma')} text="Criar nova turma" />
            </WrapperTextLink>
          </SectionHeader>

          <WrapperTabs id="wrapper-tabs">
            <Tabs selectedIndex={visibleOpt.tabIndex} onSelect={(tabIndex) => setVisibleOpt({ tabIndex })}>
              <TabList>
                <Tab>Turmas ativas</Tab>
                <Tab>Turmas inativas</Tab>
              </TabList>

              <TabPanel>
                <>
                  <SectionClasses>
                    {optionsShow &&
                      optionsShow.active &&
                      isArray(optionsShow.active) &&
                      optionsShow.active.map((option, idx) => (
                        <div key={idx}>
                          <Option onClick={() => handleEditCourseClass(option)}>
                            <HoverCard />
                            <Grid>
                              <RedTitle>{option.name}</RedTitle>
                            </Grid>
                            <Grid key={1}>
                              <WrapperIcon>
                                <BlueCheck />
                              </WrapperIcon>
                              <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                De {formatDate(option.startsAt)} até {formatDate(option.endsAt)}
                              </Text>
                            </Grid>
                          </Option>
                        </div>
                      ))}

                    {optionsShow && optionsShow.active && !optionsShow.active.length && (
                      <>
                        <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                          Não foram encontradas turmas ativas {search && `para: ${search}`}
                        </Text>
                      </>
                    )}
                  </SectionClasses>
                  {showSeeMore && showSeeMore.active && (
                    <DownloadMore onClick={() => incrementNumberOfCourseClasses('active')}>
                      <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                        VER MAIS
                      </Text>
                      <ArrowMore />
                    </DownloadMore>
                  )}
                </>
              </TabPanel>
              <TabPanel>
                <>
                  <SectionClasses>
                    {optionsShow &&
                      optionsShow.inactive &&
                      isArray(optionsShow.inactive) &&
                      optionsShow.inactive.map((option, idx) => (
                        <div key={idx}>
                          <Option>
                            <HoverCard />
                            <Grid>
                              <RedTitle>{option.name}</RedTitle>
                            </Grid>
                            <Grid key={1}>
                              <WrapperIcon>
                                <BlueCheck />
                              </WrapperIcon>
                              <Text bold size="12px" lineHeight="18px" color={theme.colors.blue900}>
                                De {formatDate(option.startsAt)} até {formatDate(option.endsAt)}
                              </Text>
                            </Grid>
                          </Option>
                        </div>
                      ))}

                    {optionsShow && optionsShow.inactive && !optionsShow.inactive.length && (
                      <>
                        <Text size="16px" lineHeight="24px" bold color={theme.colors.blue900}>
                          Não foram encontradas turmas inativas {search && `para: ${search}`}
                        </Text>
                      </>
                    )}
                  </SectionClasses>
                  {showSeeMore && showSeeMore.inactive && (
                    <DownloadMore onClick={() => incrementNumberOfCourseClasses('inactive')}>
                      <Text margin="0" size="16px" lineHeight="20px" color={theme.colors.blue900} bold>
                        VER MAIS
                      </Text>
                      <ArrowMore />
                    </DownloadMore>
                  )}
                </>
              </TabPanel>
            </Tabs>
          </WrapperTabs>
          <WrapperTextLink>
            <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
          </WrapperTextLink>
        </Content>
      </Container>
    </>
  )
}
