import * as Yup from 'yup'
import { ValidationMessages } from '~/enums'

export const initialValues = {
  cnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  nfseEmail: '',
  countySubscription: '',
  stateSubscription: '',
  simplesNacional: true,
  taxRegime: null,
  specialTaxRegime: null,
  taxIncentive: true,
  culturalPromoter: true,
  address: '',
  daysToExpedition: null,
}

export const validations = Yup.object({
  countySubscription: Yup.string().required(ValidationMessages.requiredField),
  taxRegime: Yup.mixed().required(ValidationMessages.requiredField),
  specialTaxRegime: Yup.mixed().required(ValidationMessages.requiredField),
  daysToExpedition: Yup.mixed().required(ValidationMessages.requiredField),
  nfseEmail: Yup.string().email(ValidationMessages.invalidEmail).required(ValidationMessages.requiredField),
})
