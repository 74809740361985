/* eslint-disable react/prop-types */
import React from 'react'

export const Pencil = ({ color = '#B0AFAF' }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M1 5C1 2.79086 2.79086 1 5 1H25C27.2091 1 29 2.79086 29 5V25C29 27.2091 27.2091 29 25 29H5C2.79086 29 1 27.2091 1 25V5Z"
      fill="white"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M20.1666 7.49993C20.3855 7.28106 20.6453 7.10744 20.9313 6.98899C21.2173 6.87054 21.5238 6.80957 21.8333 6.80957C22.1428 6.80957 22.4493 6.87054 22.7353 6.98899C23.0213 7.10744 23.2811 7.28106 23.5 7.49993C23.7188 7.7188 23.8924 7.97863 24.0109 8.2646C24.1294 8.55057 24.1903 8.85706 24.1903 9.16659C24.1903 9.47612 24.1294 9.78262 24.0109 10.0686C23.8924 10.3546 23.7188 10.6144 23.5 10.8333L12.25 22.0833L7.66663 23.3333L8.91663 18.7499L20.1666 7.49993Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
