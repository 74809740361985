import React from 'react'
import { Formik, Form } from 'formik'
import { Collapse } from '@material-ui/core'
import { InputFieldV3, ButtonV3 } from '@provi/provi-components'
import { CloseIcon } from '~/assets/svgs/v2'
import { StyledPortalModal, ModalContainer, FlexContainer, BlueHeader, BtnFlexContainer, InputsGrid, InputFlex } from './styles'
import { useAddCampusModal } from './hooks'
import { addCampusSchema } from './schema'

export const AddCampusModal = ({ modalRef, closeModalFn, handlePrimaryBtn, handleSecondaryBtn }) => {
  const { initialValues, handleCepChange, showFields, setShowFields, isLoadingInput } = useAddCampusModal()

  return (
    <StyledPortalModal ref={modalRef} backdropColor={'rgba(212, 218, 247, 0.8)'}>
      <Formik
        initialValues={initialValues}
        validationSchema={addCampusSchema}
        validateOnMount={true}
        onSubmit={(values) => handlePrimaryBtn(values)}
      >
        {(props) => (
          <Form>
            <ModalContainer>
              <FlexContainer>
                <BlueHeader>Cadastrar nova unidade</BlueHeader>
                <CloseIcon
                  onClick={() => {
                    setShowFields(false)
                    closeModalFn()
                  }}
                />
              </FlexContainer>

              <InputsGrid>
                <InputFieldV3
                  width="100%"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="text"
                  name="name"
                  value={props.values.name}
                  hasError={props.errors.name && props.touched.name}
                  label="Nome da unidade"
                  placeholder="Digite um nome"
                  errorMessage={props.errors.name}
                  isValid={!props.errors.name && props.touched.name}
                />
                <InputFieldV3
                  width="100%"
                  onBlur={props.handleBlur}
                  onChange={(event) => handleCepChange(event, props)}
                  mask="99999-999"
                  type="text"
                  name="zipcode"
                  placeholder="Digite o CEP"
                  value={props.values.zipcode}
                  hasError={props.errors.zipcode && props.touched.zipcode}
                  label="CEP"
                  errorMessage={props.errors.zipcode}
                  isValid={!props.errors.zipcode && props.touched.zipcode}
                />
                <Collapse in={showFields} unmountOnExit>
                  <InputsGrid>
                    <InputFieldV3
                      width="100%"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      name="street"
                      placeholder="Digite a rua"
                      value={props.values.street}
                      hasError={props.errors.street && props.touched.street}
                      label="Rua"
                      errorMessage={props.errors.street}
                      isValid={!props.errors.street && props.touched.street}
                      isLoading={isLoadingInput}
                    />
                    <InputFlex>
                      <InputFieldV3
                        width="100%"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        type="text"
                        name="state"
                        mask="aa"
                        value={props.values.state}
                        hasError={props.errors.state && props.touched.state}
                        label="Estado"
                        placeholder="Digite o estado"
                        errorMessage={props.errors.state}
                        isValid={!props.errors.state && props.touched.state}
                        isLoading={isLoadingInput}
                      />
                      <InputFieldV3
                        width="100%"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        type="text"
                        name="city"
                        value={props.values.city}
                        hasError={props.errors.city && props.touched.city}
                        label="Cidade"
                        placeholder="Digite a cidade"
                        errorMessage={props.errors.city}
                        isValid={!props.errors.city && props.touched.city}
                        isLoading={isLoadingInput}
                      />
                    </InputFlex>
                    <InputFieldV3
                      width="100%"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      name="district"
                      value={props.values.district}
                      hasError={props.errors.district && props.touched.district}
                      label="Bairro"
                      placeholder="Digite o bairro"
                      errorMessage={props.errors.district}
                      isValid={!props.errors.district && props.touched.district}
                      isLoading={isLoadingInput}
                    />
                    <InputFlex>
                      <InputFieldV3
                        width="100%"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        type="text"
                        name="number"
                        value={props.values.number}
                        placeholder="Digite o número"
                        hasError={props.errors.number && props.touched.number}
                        label="Número"
                        errorMessage={props.errors.number}
                        isValid={!props.errors.number && props.touched.number}
                      />
                      <InputFieldV3
                        width="100%"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        type="text"
                        name="complement"
                        placeholder="Digite o complemento"
                        value={props.values.complement}
                        hasError={props.errors.complement && props.touched.complement}
                        label="Complemento"
                        errorMessage={props.errors.complement}
                        isValid={!props.errors.complement && props.touched.complement}
                      />
                    </InputFlex>
                  </InputsGrid>
                </Collapse>
              </InputsGrid>
              <BtnFlexContainer>
                <ButtonV3
                  text={'Cancelar'}
                  color={'white'}
                  mobileWidth={'100%'}
                  onClick={() => {
                    setShowFields(false)
                    handleSecondaryBtn()
                  }}
                />
                <ButtonV3
                  text={'Cadastrar'}
                  mobileWidth={'100%'}
                  buttonProps={{
                    type: 'submit',
                  }}
                  disabled={!(props.isValid && props.dirty)}
                  isLoading={props.isSubmitting}
                />
              </BtnFlexContainer>
            </ModalContainer>
          </Form>
        )}
      </Formik>
    </StyledPortalModal>
  )
}
