import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RowWrapper = styled.div`
  padding: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
    padding: 16px 0px 16px 0px;
    display: flex;
    flex-direction: column;

    margin-bottom: ${({ isLast }) => (isLast ? '40px' : '')};
  }

  [placeholder] {
    font-size: 14px;
    font-weight: initial;
  }
`

export const Label = styled.p`
  margin: 0;
  max-width: 300px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    text-align: left;
    font-size: 14px;
    width: 100%;
  }
  ${({ isRequired }) =>
    isRequired &&
    css`
      ::after {
        content: ' *';
        color: ${theme.colors.pink900};
      }
    `}
  ${({ isParagraph }) =>
    isParagraph &&
    css`
      font-size: 14px;
      line-height: 18px;
      color: #757575;
      font-weight: initial;
      margin-bottom: 25px !important;
      ${theme.breakpoints.down('md')} {
        font-size: 12px;
        line-height: 14px;
      }
    `}
`

export const Divider = styled.div`
  background: #cfcfcf;
  height: 1px;
  width: 100%;

  display: ${({ isVanish }) => (isVanish ? 'none' : '')};
`

export const SaveInformation = styled.div`
  button {
    height: 40px;
  }

  ${theme.breakpoints.up('md')} {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      height: 48px;
      margin-top: 8px;
    }

    div {
      width: 174px;
    }

    .MuiButtonBase-root {
      width: 174px;
    }
    .MuiButton-label {
      font-size: 16px;
      line-height: 24px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
    }
  }

  align-items: center;
`
