/* eslint-disable react/prop-types */
import React from 'react'
import { theme } from '@provi/provi-components'

export const Calculator = ({ color = '#DE0C4B', isDisable = false, width = '24', height = '29', strokeWidth = 2 }) => {
  const iconColor = isDisable ? theme.colors.grey600 : color

  return (
    <svg width={width} height={height} viewBox="0 0 24 29" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M5.47368 18.5263H6.94737M5.47368 22H6.94737M11.2632 18.5263H12.7368M11.2632 22H12.7368M17.0526 18.5263H18.5263M17.0526 22H18.5263M3 27.6316H21C22.1046 27.6316 23 26.7361 23 25.6316V3C23 1.89543 22.1046 1 21 1H3C1.89543 1 1 1.89543 1 3V25.6316C1 26.7361 1.89543 27.6316 3 27.6316ZM6.47368 14.8947H17.5263C18.6309 14.8947 19.5263 13.9993 19.5263 12.8947V6.47368C19.5263 5.36911 18.6309 4.47368 17.5263 4.47368H6.47368C5.36911 4.47368 4.47368 5.36911 4.47368 6.47368V12.8947C4.47368 13.9993 5.36911 14.8947 6.47368 14.8947Z"
        stroke={iconColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
