import { useContext, useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { useTable, usePagination, useExpanded, useColumnOrder, useFilters } from 'react-table'
import { getCancellations } from './requests'
import { toast } from 'react-toastify'
import { cancellationsColumns } from './cancellationTableConfig'
import { TableFilter } from '~/components/v2/TableV2/components/TableFilter'
import { getActiveUser } from '~/utils'
import {
  parseFiltersFromUrlToArrOfObj,
  parseFiltersFromStateArr,
  formatStatusFilters,
  formatFinalFilterObject,
} from '~/screens/requests/helpers'

export const useCancellations = () => {
  const { isMobile, setIsLoading, goToPage: goToPageFromContext } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [cancellationsData, setCancellationsData] = useState([])
  const [pageCountState, setPageCountState] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [filterOptions, setFilterOptions] = useState([])
  const history = useHistory()
  const location = useLocation()

  const getCancellationsData = async (tableStatePage, tableStateSize, tableFilters) => {
    try {
      setIsLoading(true)
      const params = {
        page: tableStatePage + 1,
        itemsPerPage: tableStateSize,
        ...tableFilters,
      }

      const response = await getCancellations(params)

      if (response.status > 199 && response.status < 300) {
        if (response.data.content.length) {
          setCancellationsData(response.data.content)
          setTotalCount(response.data.paging.totalItems)
          setPageCountState(response.data.paging.totalPages)
          setFilterOptions(formatStatusFilters(response.data.metadata.availableFilters))
        }
      }
    } catch (error) {
      toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
    } finally {
      setIsLoading(false)
      setIsFirstLoad(false)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    canNextPage,
    canPreviousPage,
    prepareRow,
    visibleColumns,
    setColumnOrder,
    setHiddenColumns,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns: useMemo(() => cancellationsColumns, []) || [],
      data: useMemo(() => cancellationsData, [cancellationsData]) || [],
      manualPagination: true,
      manualFilters: true,
      pageCount: pageCountState,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetExpanded: false,
      defaultColumn: {
        gotoPage: (path) => goToPageFromContext(path),
        isMobileProps: isMobile,
        Filter: TableFilter,
        disableFilters: true,
        tableOrigin: 'Cancelamentos',
      },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            filterOptions: filterOptions,
          }),
          [state, filterOptions],
        )
      },
    },
    useFilters,
    useExpanded,
    usePagination,
    useColumnOrder,
  )

  useEffect(() => {
    const activeUser = getActiveUser()
    if (filters.length > 0) {
      const filterObject = parseFiltersFromStateArr(filters, activeUser)
      const finalFilterObject = formatFinalFilterObject(filterObject)
      getCancellationsData(pageIndex, pageSize, finalFilterObject)
      const finalQueryString = new URLSearchParams(finalFilterObject)
      history.replace({ pathname: location.pathname, search: finalQueryString.toString() })
    } else {
      const initialUrlFilters = new URLSearchParams(location.search)
      const params = Object.fromEntries(initialUrlFilters)
      const filtersFromUrl = parseFiltersFromUrlToArrOfObj(params)
      const finalFiltersFromUrl = parseFiltersFromStateArr(filtersFromUrl, activeUser)
      getCancellationsData(pageIndex, pageSize, finalFiltersFromUrl)
    }
  }, [pageIndex, pageSize, filters])

  useEffect(() => {
    if (isMobile) {
      setHiddenColumns(['warrantyPeriod', 'cpf', 'comment', 'requestDate', 'canBeContested'])
      setColumnOrder([
        'status',
        'CreditRequestId',
        'responseDeadline',
        'warrantyPeriod',
        'cpf',
        'comment',
        'requestDate',
        'canBeContested',
      ])
    }
    if (!isMobile) {
      setHiddenColumns([])
      setColumnOrder([
        'CreditRequestId',
        'canBeContested',
        'responseDeadline',
        'status',
        'warrantyPeriod',
        'cpf',
        'comment',
        'requestDate',
      ])
    }
  }, [isMobile])

  return {
    isMobile,
    drawerWidth,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageIndex,
    pageSize,
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
    prepareRow,
    visibleColumns,
    totalCount,
    isFirstLoad,
  }
}
