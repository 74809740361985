import styled from 'styled-components'
import { theme } from '@provi/provi-components'

const fonts = {
  montserrat: {
    regular: 'Montserrat-Regular',
    bold: 'Montserrat-Bold',
    semiBold: 'Montserrat-SemiBold',
    medium: 'Montserrat-Medium',
  },
}

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* flex: 1; */
  min-height: 100vh;
  background-color: #f6f8fb;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const ModalContainer = styled.div`
  div > div {
    max-width: 480px;
    font-family: ${fonts.montserrat.regular};
    color: ${theme.colors.blue900};
    font-size: 16px;
    margin: 0;
    ${theme.breakpoints.down('sm')} {
      div > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          margin: 0;
          max-width: 300px;
          margin-top: 16px;
        }
      }
    }
  }
  h3 {
    font-family: ${fonts.montserrat.bold};
    font-size: 20px;
  }
`

export const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 37px !important;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  button {
    max-width: 174px;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    padding: 0;
  }
`

export const Content = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1280px;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 0px solid #000;
  margin-top: 24px;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`

export const Header = styled.div`
  display: flex;
  flex: 1;
  max-height: 84px;
  border-bottom: 1px solid ${theme.colors.grey500};
  background-color: #f6f8fb;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  padding: 30px 0;
  div {
    position: absolute;
    right: 64px;
    ${theme.breakpoints.up('lg')} {
      position: relative;
      right: unset;
    }
  }
  ${theme.breakpoints.down('sm')} {
    padding: 0;
    padding-left: 72px;
    min-height: 72px;
  }
`

export const HeaderName = styled.h2`
  margin: 0;
  font-family: ${fonts.montserrat.semiBold};
  font-size: 24px;
  display: flex;
  align-items: space-around;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`

export const HeaderButton = styled.button`
  min-width: 195px;
  min-height: 32px;
  font-family: ${fonts.montserrat.medium};
  font-size: 14px;
  color: ${theme.colors.pink900};
  background-color: ${theme.colors.white100};
  border-radius: 5px;
  border: 1px solid ${theme.colors.grey500};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  padding: 6px 10px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  :hover {
    cursor: pointer;
    color: ${theme.colors.white100};
    background-color: ${theme.colors.pink900};
    border: 1px solid ${theme.colors.pink900};
  }
  &:disabled {
    color: ${theme.colors.white100};
    background-color: ${theme.colors.grey500};
    :hover {
      cursor: initial;
      background-color: ${theme.colors.grey500};
      border: initial;
    }
  }
`

/** transactions section */
export const TransactionSection = styled.div`
  * {
    overflow-x: hidden !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid ${theme.colors.grey500};
  margin-top: 32px;
  margin-bottom: 8px;
  padding-bottom: 24px;
  ${theme.breakpoints.down('md')} {
    margin-top: 24px;
    margin-bottom: 0;
    padding-bottom: 24px;
  }
`
export const TransactionHeader = styled.label`
  margin: 0;
  font-family: ${fonts.montserrat.semiBold};
  font-size: 16px;
  color: ${theme.colors.blue900};
`

export const TransactionButton = styled.button`
  max-width: 140px;
  min-height: 32px;
  font-family: ${fonts.montserrat.medium};
  font-size: 14px;
  color: ${theme.colors.white100};
  background-color: ${theme.colors.pink900};
  border-radius: 5px;
  margin-top: 16px;
  border: 0px;
  transition: background-color 0.2s ease;
  :hover {
    cursor: pointer;
    background-color: #ad093a;
  }
  &:disabled {
    color: ${theme.colors.white100};
    background-color: ${theme.colors.grey500};
    :hover {
      cursor: initial;
      background-color: ${theme.colors.grey500};
    }
  }
  ${theme.breakpoints.down('md')} {
    max-width: 109px;
    font-size: 12px;
    margin-top: 0;
  }
`

// import styled from 'styled-components'
// import { theme } from '@provi/provi-components'

// export const Container = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
// `

// export const ModalContainer = styled.div`
//   div > div {
//     max-width: 480px;
//     font-family: 'Montserrat', sans-serif;
//     color: ${theme.colors.blue900};
//     font-size: 16px;
//     margin: 0;

//     ${theme.breakpoints.down('sm')} {
//       div > div {
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         div {
//           margin: 0;
//           max-width: 300px;
//           margin-top: 16px;
//         }
//       }
//     }
//   }

//   h3 {
//     font-family: 'Montserrat', sans-serif;
//     font-weight: 700;
//     font-size: 20px;
//   }
// `

// export const ModalButtons = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 37px !important;

//   ${theme.breakpoints.down('sm')} {
//     flex-direction: column;
//   }

//   button {
//     max-width: 174px;
//     font-family: 'Montserrat', sans-serif;
//     font-size: 14px;
//     padding: 0;
//   }
// `

// export const Content = styled.div`
//   margin: 0 auto;
//   padding: 0 16px;
//   max-width: 1280px;
// `

// export const Row = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: row;
//   border: 0px solid #000;
//   margin-top: 24px;

//   ${theme.breakpoints.down('md')} {
//     flex-direction: column;
//   }
// `

// export const Header = styled.div`
//   display: flex;
//   flex: 1;
//   max-height: 84px;
//   border-bottom: 1px solid ${theme.colors.grey500};
//   background-color: #f6f8fb;
//   margin-bottom: 5px;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 100%;
//   padding: 30px 0;

//   div {
//     position: absolute;
//     right: 64px;

//     ${theme.breakpoints.up('lg')} {
//       position: relative;
//       right: unset;
//     }
//   }

//   ${theme.breakpoints.down('sm')} {
//     padding: 0;
//     padding-left: 72px;
//     min-height: 72px;
//   }
// `

// export const HeaderName = styled.h2`
//   margin: 0;
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 600;
//   font-size: 24px;
//   display: flex;
//   align-items: space-around;

//   ${theme.breakpoints.down('sm')} {
//     font-size: 16px;
//   }
// `

// export const HeaderButton = styled.button`
//   min-width: 195px;
//   min-height: 32px;
//   font-family: 'Montserrat', sans-serif;
//   font-size: 14px;
//   color: ${theme.colors.pink900};
//   background-color: ${theme.colors.white100};
//   border-radius: 5px;
//   border: 1px solid ${theme.colors.grey500};
//   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
//   padding: 6px 10px;
//   text-decoration: none;
//   transition: background-color 0.2s ease;

//   :hover {
//     cursor: pointer;
//     color: ${theme.colors.white100};
//     background-color: ${theme.colors.pink900};
//     border: 1px solid ${theme.colors.pink900};
//   }

//   &:disabled {
//     color: ${theme.colors.white100};
//     background-color: ${theme.colors.grey500};
//     :hover {
//       cursor: initial;
//       background-color: ${theme.colors.grey500};
//       border: initial;
//     }
//   }
// `

// export const TransactionSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   border-bottom: 1px solid ${theme.colors.grey500};
//   margin-top: 32px;
//   margin-bottom: 8px;
//   padding-bottom: 24px;

//   ${theme.breakpoints.down('md')} {
//     margin-top: 24px;
//     margin-bottom: 0;
//     padding-bottom: 24px;
//   }
// `
// export const TransactionHeader = styled.label`
//   margin: 0;
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 700;
//   font-size: 16px;
//   color: ${theme.colors.blue900};
// `

// export const TransactionButton = styled.button`
//   max-width: 140px;
//   min-height: 32px;
//   font-family: 'Montserrat', sans-serif;
//   font-size: 14px;
//   color: ${theme.colors.white100};
//   background-color: ${theme.colors.pink900};
//   border-radius: 5px;
//   margin-top: 16px;
//   border: 0px;
//   transition: background-color 0.2s ease;

//   :hover {
//     cursor: pointer;
//     background-color: #ad093a;
//   }

//   &:disabled {
//     color: ${theme.colors.white100};
//     background-color: ${theme.colors.grey500};
//     :hover {
//       cursor: initial;
//       background-color: ${theme.colors.grey500};
//     }
//   }

//   ${theme.breakpoints.down('md')} {
//     max-width: 109px;
//     font-size: 12px;
//     margin-top: 0;
//   }
// `
