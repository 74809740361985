import React, { useContext } from 'react'
import { theme, TextLink } from '@provi/provi-components'

import { pagination_options } from '~/constants/pagination_options'
import { handlePlaceholder, clearAndReload } from '~/utils'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { Header } from '~/components/table/header-table'
import { SimpleTable as Table } from '~/components/table'

import { ProductModal } from '~/components/modals/product-modal'
import { CheckCsvModal } from '~/components/modals/check-csv-modal'
import { FilterModal } from '~/components/modals/filter-modal'
import { HelperModal } from '~/components/modals/helper-modal'
import { SimpleModal } from '~/components/modals/simple-modal'

import { Text } from '~/themes/styles'

import { search_options } from '../../constants/search_options'
import { Container, Content, ContainerTable } from './styles'
import { useFinantialMovement } from './hooks'

export const FinantialMovement = () => {
  const { setIsLoading, isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const { handlers, states, setStates } = useFinantialMovement(setIsLoading, isMobile)

  const { handleModal, handleActiveBox, handleSearch, handleSearchKey } = handlers
  const {
    search,
    activeBox,
    pageIndicator,
    page,
    itemsPerPage,
    totalItens,
    totalPages,
    warningMessage,
    rows,
    showCells,
    productsModal,
    ProductData,
    exportsModal,
    exportsModalData,
    filterModal,
    preferences,
    helperModalData,
    helperModal,
    simpleModal,
    simpleModalData,
    searchOptions,
    setSearchOptions,
  } = states
  const { setPageIndicator, setPage, setItemsPerPage, setShowCells, setFilterStatus } = setStates

  return (
    <Container>
      {(preferences || filterModal) && (
        <FilterModal
          refer="financial-movement"
          show={filterModal}
          handleModal={handleModal}
          showCells={showCells}
          setShowCells={setShowCells}
          preferences={preferences}
        />
      )}
      <SimpleModal show={simpleModal} data={simpleModalData} handleModal={handleModal} title="Parcelas pagas pelo aluno:" />
      <CheckCsvModal show={exportsModal} csvData={exportsModalData} handleModal={handleModal} />
      <ProductModal show={productsModal} ProductData={ProductData} handleModal={handleModal} />
      {helperModal && <HelperModal show={helperModal} helperModalData={helperModalData} handleModal={handleModal} />}
      <Header
        handleModal={handleModal}
        activeBox={activeBox}
        handleActiveBox={handleActiveBox}
        handleSearch={handleSearch}
        handleSearchKey={handleSearchKey}
        search={search}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        search_options={search_options}
        handlePlaceholder={handlePlaceholder}
        pageIndicator={pageIndicator}
        setPageIndicator={setPageIndicator}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItens={totalItens}
        totalPages={totalPages}
        pagination_options={pagination_options}
        location="finantial-movement"
      />
      <Content drawerWidth={drawerWidth}>
        <ContainerTable isMobile={isMobile} drawerWidth={drawerWidth}>
          {warningMessage ? (
            <>
              <Text bold align="center" color={theme.colors.blue900} mTop="30px" size="24px">
                {warningMessage}
              </Text>
              <Text align="center" color={theme.colors.blue900} mTop="8px" size="14px" bold>
                <TextLink text="Limpar filtros e atualizar página" onClick={clearAndReload} />
              </Text>
            </>
          ) : (
            <Table
              setPage={setPage}
              handleModal={handleModal}
              search={search}
              rows={rows}
              showCells={showCells}
              setShowCells={setShowCells}
              setFilterStatus={setFilterStatus}
            />
          )}
        </ContainerTable>
      </Content>
    </Container>
  )
}
