import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const StyledButton = styled.button`
color: #414141;
background-color: ${theme.colors.white100};
border-radius: 5px;
border: 1px solid #414141;
box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
padding: 6px 10px;
text-decoration: none;
transition: background-color 0.2s ease;

:hover {
  cursor: pointer;
  color: ${theme.colors.white100};
  background-color: ${theme.colors.pink900};
  border: 1px solid ${theme.colors.pink900};
}

&:disabled {
color: #B0AFAF;
  background-color: #ECEAEA;
  border: 1px solid transparent;

  :hover {
    cursor: initial;
  }
`
