import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/themes/fonts'
import Selector from 'react-select'

export const InputSelector = styled(Selector).attrs((props) => ({
  inputProps: { 'aria-label': { name: props.name } },
  isSearchable: false,
  hideSelected: true,
  styles: {
    option: () => ({
      padding: 5,
      height: 35,
      fontSize: 14,
      paddingLeft: 13,
      color: theme.colors.blue800,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey500,
      },
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.blue800}`,
      borderRadius: 50,
      height: 48,
      width: '100%',
      fontFamily: fonts.century.regular,
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      lineHeight: 34,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      width: 432,
      fontFamily: fonts.century.regular,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.pink900,
      marginRight: 8,
    }),
    placeholder: () => ({
      fontFamily: fonts.century.regular,
      color: theme.colors.grey600,
      fontSize: 16,
    }),
    indicatorSeparator: () => ({}),
    menuList: (menuList) => ({
      ...menuList,
      padding: 0,
      zIndex: 100,
    }),
    menu: (provided) => ({
      ...provided,
      borderCollapse: 'separate',
      boxSizing: 'border-box',
      width: '100%',
      borderRadius: 5,
      fontFamily: fonts.century.regular,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 16px',
      fontSize: 20,
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }),
  },
}))``
