/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { TemplateContext } from './context'
import { useModal, useInfos, useHeader, useScreenControl, useAuth } from './hooks'
import { useScreenMeasure } from '~/hooks'

export const TemplateProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [value, setValue] = useState(0)

  const [editOption, setEditOption] = useState(null)

  const [activeSection, setActiveSection] = useState(0)

  const [selectedOptionsCampaign, setSelectedOptionsCampaign] = useState([])
  const [valueCampaign, setValueCampaign] = useState(0)

  const [isLowMobile, isMobile, isBreakpointMenu, isLowDesktop] = useScreenMeasure()
  const [isShowModal, handleModal, showLogoutModal, handleLogoutModal] = useModal()
  const [modal, setModal, logout] = useInfos(handleLogoutModal)
  const [goToPage, activePage, setActivePage] = useScreenControl(setIsLoading)
  const [setCurrent, options, current] = useHeader()
  const [accessData] = useAuth()

  const { children } = props

  const providerValue = {
    isLowMobile,
    isMobile,
    isBreakpointMenu,
    isLowDesktop,
    //
    isShowModal,
    modal,
    setModal,
    logout,
    handleModal,
    showLogoutModal,
    handleLogoutModal,
    isLoading,
    setIsLoading,
    setCurrent,
    current,
    options,
    //
    selectedOptions,
    setSelectedOptions,
    value,
    setValue,
    //
    editOption,
    setEditOption,
    //
    activeSection,
    setActiveSection,
    //
    selectedOptionsCampaign,
    setSelectedOptionsCampaign,
    valueCampaign,
    setValueCampaign,
    //
    goToPage,
    activePage,
    setActivePage,
    accessData,
  }

  return <TemplateContext.Provider value={providerValue}>{children}</TemplateContext.Provider>
}
