import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import moment from 'moment'

import { logger } from '~/helpers/logger'
import { createCampaingV4 } from '~/services/api'
import { ERRORS } from '~/constants'
import slugify from 'slugify'

import { FinantialIcon } from '~/assets/svgs/provipay'
import { BoletoIcon } from '~/assets/svgs/provipay-boleto'
import { CreditCardIcon } from '~/assets/svgs/provipay-credit-card'
import { PixIcon } from '~/assets/svgs/provipay-pix'

import { formatInput, handlePercent, handleValue, formatStrValue, unMask, getStorage, removeItem, getActiveUser } from 'utils'
import { buildCalcConfigObject } from 'utils/buildCalcConfigObject'

import { setCampaignPaymentMethods } from '../helpers/setCampaignPaymentMethods'

import { getFinancingConditions } from 'services/api'

const payment_methods_initial_state = [
  {
    name: 'Cartão de crédito',
    value: 'CreditCard',
    selected: false,
    icon: CreditCardIcon,
    available: true,
  },
  {
    name: 'Boleto',
    value: 'Boleto',
    selected: false,
    icon: BoletoIcon,
    available: true,
  },
  {
    name: 'Crédito educacional',
    value: 'CourseFinancing',
    selected: false,
    icon: FinantialIcon,
    available: true,
  },
  {
    name: 'Pix',
    value: 'Pix',
    selected: false,
    icon: PixIcon,
    available: true,
  },
]

export const useCheckout = (
  setIsLoading,
  setCurrent,
  _isIE,
  _isEdge,
  _engineName,
  setSelectedOptionsCampaign,
  setValueCampaign,
  activePage,
  setActivePage,
) => {
  const [products, setProducts] = useState([])
  const [productsValue, setProductsValue] = useState(0)
  const [link, setLink] = useState(false)
  const [linkUrl, setLinkUrl] = useState()
  const [slug, setSlug] = useState('')
  const [slugPreview, setSlugPreview] = useState('')
  const [sellerId, setSellerId] = useState()
  const [campaignName, setCampaignName] = useState()
  const [campaignNameError, setCampaignNameError] = useState(false)
  const [obj, setObj] = useState({ value: null, percent: null })
  const [errorPercent, setErrorPercent] = useState(false)
  const [errorMaxValue, setErrorMaxValue] = useState(false)
  const [showSellerInfoModal, setShowSellerInfoModal] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(payment_methods_initial_state)
  const [defaultPaymentMethods, setDefaultPaymentMethods] = useState([])
  const [isShowCalcModal, setIsShowCalcModal] = useState(false)
  const [calcConfig, setCalcConfig] = useState({})
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('')
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('')
  const [dates, setDates] = useState({})
  const [selectedFinancingCondition, setSelectedFinancingCondition] = useState({})
  const [financingConditions, setFinancingConditions] = useState([])

  const getSelectedCourses = async () => getStorage({ key: 'selectedOptions' })

  useEffect(() => {
    const fetchFinancingConditions = async () => {
      const selectedCoursesFinancing = await getSelectedCourses()
      const selectedCoursesFinancingIds = selectedCoursesFinancing?.map((selectedCourse) => selectedCourse.courseClassId)
      const { options: financingConditionsOptions, defaultOptionId } = await getFinancingConditions({
        CourseClassIds: selectedCoursesFinancingIds,
      })

      setFinancingConditions(financingConditionsOptions)
      setSelectedFinancingCondition(financingConditionsOptions.filter((condition) => condition.id === defaultOptionId))
    }

    fetchFinancingConditions()
  }, [])

  const getInitialData = async () => {
    const products = getStorage({ key: 'selectedOptionsCampaign' })
    const responseValue = getStorage({ key: 'selectedOptionsValueCampaign' })
    const responseEmail = getStorage({ key: 'email' })

    const parsedProducts = products

    await setCampaignPaymentMethods({ paymentMethods, setDefaultPaymentMethods, setPaymentMethods, parsedProducts })

    setProductsValue(responseValue)
    setProducts(parsedProducts)
    if (responseEmail) {
      setSellerId(responseEmail)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    setCurrent(95, 2)

    if (activePage !== 'campaigns') setActivePage('campaigns')

    window.scrollTo(0, 0)
    getInitialData()
  }, [])

  const handleCalcModal = () => {
    setIsShowCalcModal(!isShowCalcModal)
  }

  const handleValueChange = (_event) => {
    const unMaskedValue = unMask(_event.target.value)
    const _value = unMaskedValue

    if (!_value) {
      setObj({ value: '', percent: '' })
    } else {
      const valueInInput = formatInput(_value)

      const errorMax = Number(valueInInput) > Number(productsValue)
      setErrorMaxValue(errorMax)

      return handleValue({ setObj, valueInInput, productsValue })
    }
  }

  const handleCalcClick = async () => {
    try {
      setIsLoading(true)
      const amountOfCourses = products.reduce((accumulator, current) => current.price_in_cents + accumulator, 0)

      const classesIds = []
      const coursesIds = []

      products.forEach(({ id: courseId, courseClassId }) => {
        classesIds.push(courseClassId)
        coursesIds.push(courseId)
      })

      const objectCalcConfig = buildCalcConfigObject({
        amountInCents: amountOfCourses,
        coursesIds,
        classesIds,
        financingCondition: selectedFinancingCondition,
        inputedValue: obj.value,
      })

      setCalcConfig(objectCalcConfig)

      handleCalcModal()
    } catch (error) {
      showToast('Ocorreu um erro ao mostrar a calculadora, tente novamente ou contate a Provi')
    } finally {
      setIsLoading(false)
    }
  }

  const handlePercentChange = (_event) => {
    let floatValue = _event.target.value
    floatValue = floatValue && floatValue.replace('%', '')

    if (!floatValue) {
      setObj({ value: '', percent: '' })
    } else {
      setErrorPercent(floatValue >= 100)

      return handlePercent({ setObj, percent: floatValue, productsValue })
    }
  }

  const handleSelectFinancingCondition = (financingCondition) => {
    if (!financingCondition) return

    return setSelectedFinancingCondition(financingCondition)
  }

  const handleSubmitCheckout = async () => {
    let selectedCourses = []
    let selectedCoursesIds = []
    try {
      selectedCourses = getStorage({ key: 'selectedOptionsCampaign' })
      selectedCoursesIds = selectedCourses.map((selectedOption) => ({
        id: selectedOption.id,
        courseClassId: selectedOption.courseClassId,
      }))
    } catch (error) {
      showToast(ERRORS.default)
    }

    try {
      let dateStartTime
      let dateEndTime
      const currentDateTime = moment(new Date(), 'DD/MM/YYYY HH:mm')

      if (dates.startDate || dates.startHour || dates.endDate || dates.endHour) {
        dateStartTime = moment(dates.startDate + ' ' + dates.startHour, 'DD/MM/YYYY HH:mm')
        dateStartTime =
          dates.startDate || dates.startHour
            ? moment(dates.startDate + ' ' + dates.startHour, 'DD/MM/YYYY HH:mm')
            : currentDateTime
        dateEndTime = moment(dates.endDate + ' ' + dates.endHour, 'DD/MM/YYYY HH:mm')

        if (!checkDateIsValid(dateStartTime)) {
          setStartDateErrorMessage('Data de inicio inválida, por favor, tente novamente.')
          return
        }
        if (!checkDateIsValid(dateEndTime)) {
          setEndDateErrorMessage('Data de fim inválida, por favor, tente novamente.')
          return
        }
      }

      const activePaymentMethods = paymentMethods.filter((payment) => payment.selected).map((payment) => payment.value)

      if (defaultPaymentMethods && defaultPaymentMethods.length && !activePaymentMethods.length) {
        showToast('Você tem que selecionar pelo menos um método de pagamento')
        return
      }

      const objPost = {
        checkout_price_in_cents: Number(formatStrValue(unMask(obj.value)) || productsValue),
        courses: selectedCoursesIds,
        consultant_id: sellerId,
        name: campaignName,
        slug: slug.toLowerCase(),
        notification_url: sellerId,
        payment_methods: activePaymentMethods && activePaymentMethods.length ? activePaymentMethods : null,
        maxInstallmentsToApply: selectedFinancingCondition?.value,
      }

      if (dateStartTime || dateEndTime) {
        objPost.startsAt = (dateStartTime && dateStartTime.format()) || null
        objPost.endsAt = (dateEndTime && dateEndTime.format()) || null
      }

      if (errorPercent || errorMaxValue) {
        showToast('Ops, ocorreu algum erro 😔')
        return
      }

      setIsLoading(true)

      const response = await createCampaingV4(objPost)

      const { content, error } = response.data

      if (error) {
        const message =
          response.status !== 500 && error.message
            ? error.message
            : 'Ocorreu um erro ao criar a campanha, tente novamente ou contate a Provi'
        showToast(message)
      } else {
        setLinkUrl(content.redirect_url)
        setLink(true)

        await removeItem({ key: 'selectedOptionsCampaign', user: getActiveUser() })
        await removeItem({ key: 'selectedOptionsValueCampaign', user: getActiveUser() })

        setSelectedOptionsCampaign([])
        setValueCampaign(0)
      }
    } catch (error) {
      showToast('Ocorreu um erro ao criar a campanha, tente novamente ou contate a Provi')

      logger({
        error,
        level: 'error',
        screen: 'checkout',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFocus = (event) => event.target.select()

  const handleSellerId = (e) => {
    if (!e) return

    setSellerId(e.target.value)
  }

  const handleCampaignName = (e) => {
    if (!e) return

    const regexCampaignName = /[áàâãéèêíïóôõöúçñ`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]+/gi
    if (regexCampaignName.test(e.target.value)) {
      setCampaignNameError(true)
      return false
    }
    setCampaignNameError(false)

    const formatSlug = (str, isFormatted) => {
      if (!str) return

      const formattedSlug = slugify(str, '-')

      if (isFormatted) {
        return `.../campanha/${formattedSlug.toLowerCase()}`
      }

      return formattedSlug
    }

    const formattedSlug = formatSlug(e.target.value, true)

    setSlugPreview(formattedSlug)
    setSlug(formatSlug(e.target.value))

    setCampaignName(e.target.value)
  }

  const showToastCopy = () => {
    showToast('Link copiado')
  }

  const handleModal = (option, location) => {
    if (location === 'seller') {
      setShowSellerInfoModal(option)
    }
  }

  const checkDateIsBefore = (date, compareDate) => {
    return moment(date, 'DD/MM/YYYY HH:mm').isBefore(moment(compareDate, 'DD/MM/YYYY HH:mm'))
  }

  const checkDateIsValid = (date, type) => {
    const formatTypes = {
      date: 'DD/MM/YYYY',
      hour: 'HH:mm',
    }
    return moment(date, formatTypes[type]).isValid()
  }

  const resetErrors = () => {
    setStartDateErrorMessage('')
    setEndDateErrorMessage('')
  }

  const handleDates = (label, value) => {
    setDates((prev) => ({ ...prev, [label]: value }))
  }

  useEffect(() => {
    resetErrors()

    if (
      (dates.startDate && dates.startDate.length === 10 && !checkDateIsValid(dates.startDate, 'date')) ||
      (dates.startHour && dates.startHour.length === 5 && !checkDateIsValid(dates.startHour, 'hour'))
    ) {
      setStartDateErrorMessage('Por favor, utilize uma data de inicío válida')
    }

    if (
      (dates.endDate && dates.endDate.length === 10 && !checkDateIsValid(dates.endDate, 'date')) ||
      (dates.endHour && dates.endHour.length === 5 && !checkDateIsValid(dates.endHour, 'hour'))
    ) {
      setEndDateErrorMessage('Por favor, utilize uma data de fim válida')
    }

    if (
      dates.startDate &&
      dates.startHour &&
      dates.startDate.length === 10 &&
      dates.startHour.length === 5 &&
      checkDateIsBefore(`${dates.startDate} ${dates.startHour}`, new Date())
    ) {
      setStartDateErrorMessage('Data de início não pode ser anterior a data atual')
    }

    if (
      dates.endDate &&
      dates.endHour &&
      dates.endDate.length === 10 &&
      dates.endHour.length === 5 &&
      checkDateIsBefore(`${dates.endDate} ${dates.endHour}`, new Date())
    ) {
      setEndDateErrorMessage('Data de fim não pode ser anterior a data atual')
    }

    if (
      (dates.startDate &&
        dates.endDate &&
        dates.endDate.length === 10 &&
        !dates.endHour &&
        checkDateIsBefore(dates.endDate, dates.startDate)) ||
      (dates.startDate &&
        dates.endDate &&
        dates.endDate.length === 10 &&
        dates.endHour &&
        dates.endHour.length === 5 &&
        checkDateIsBefore(`${dates.endDate} ${dates.endHour}`, `${dates.startDate} ${dates.startHour}`))
    ) {
      setEndDateErrorMessage('Data de fim não pode ser anterior a data de início')
    }
  }, [dates])

  return [
    handleValueChange,
    handlePercentChange,
    link,
    showToastCopy,
    products,
    productsValue,
    obj,
    handleFocus,
    handleSubmitCheckout,
    sellerId,
    handleSellerId,
    linkUrl,
    errorPercent,
    errorMaxValue,
    campaignName,
    handleCampaignName,
    slugPreview,
    handleModal,
    showSellerInfoModal,
    campaignNameError,
    handleCalcModal,
    handleCalcClick,
    isShowCalcModal,
    calcConfig,
    paymentMethods,
    setPaymentMethods,
    defaultPaymentMethods,
    handleDates,
    dates,
    endDateErrorMessage,
    startDateErrorMessage,
    financingConditions,
    selectedFinancingCondition,
    handleSelectFinancingCondition,
  ]
}
