import React from 'react'
import { ChartLabels } from '../ChartLabels'
import { dashboardLegendsObj } from '~/screens/dashboard/constants'
import { ChartLabelsContainer, ChartDropdownContainer } from './styles'

export const ChartLegendsList = ({ legendArr, isFromDropdown }) => {
  return (
    <>
      {isFromDropdown ? (
        <ChartDropdownContainer>
          {dashboardLegendsObj[legendArr].map((eachObject) => (
            <ChartLabels
              isBold
              key={eachObject.key}
              labelText={eachObject.labelText}
              fillColor={eachObject.fillColor}
              optionalText={eachObject.optionalText}
              isLine={eachObject.isLine}
            />
          ))}
        </ChartDropdownContainer>
      ) : (
        <ChartLabelsContainer>
          {dashboardLegendsObj[legendArr].map((eachObject) => (
            <ChartLabels
              key={eachObject.key}
              labelText={eachObject.labelText}
              fillColor={eachObject.fillColor}
              isLine={eachObject.isLine}
            />
          ))}
        </ChartLabelsContainer>
      )}
    </>
  )
}
