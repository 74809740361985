import styled from 'styled-components'

export const MobileGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-rows: repeat(4, 1fr);
`

export const SubGrid = styled.div`
  display: grid;
  grid-template-columns: 0.35fr 0.65fr;
  gap: 10px;
`

export const CenteredBtn = styled.div`
  place-self: center;
`
