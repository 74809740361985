import React from 'react'
import { deleteCourseService, getCourseService } from 'services/ms-checkout/v4'
import { getStorage } from 'utils'
import { TemplateContext } from '~/components/Template/context'
import { editCourseVisibility } from '~/screens/courses/requests'
import { toast } from 'react-toastify'

export const useCourseDetails = ({ id }) => {
  const [courseDetails, setCourseDetails] = React.useState(null)
  const partnerId = getStorage({ key: 'partner-id' })
  const { isMobile, goToPage, setIsLoading } = React.useContext(TemplateContext)
  const editVisibilityModalRef = React.useRef()
  const deleteModalRef = React.useRef()
  const shareLinkModalRef = React.useRef()

  const openModals = (string) => {
    switch (string) {
      case 'editVisibility':
        editVisibilityModalRef.current.openModalFn()
        break
      case 'delete':
        deleteModalRef.current.openModalFn()
        break
      case 'share':
        shareLinkModalRef.current.openModalFn()
        break

      default:
        break
    }
  }

  const closeModals = (string) => {
    switch (string) {
      case 'editVisibility':
        editVisibilityModalRef.current.closeModalFn()
        break
      case 'delete':
        deleteModalRef.current.closeModalFn()
        break
      case 'share':
        shareLinkModalRef.current.closeModalFn()
        break

      default:
        break
    }
  }

  async function deleteCourseMethod() {
    closeModals('delete')
    await deleteCourseService({ id, partnerId, setIsLoading, goToPage })
  }

  const toggleVisibility = async (courseDetailsContent) => {
    try {
      closeModals('editVisibility')
      setIsLoading(true)

      const courseClassesIds = courseDetailsContent.CourseClasses.map((course) => course.id)

      const response = await editCourseVisibility(id, {
        visible: !courseDetailsContent.active,
        applyInCourseClasses: true,
        CourseClassesIds: courseClassesIds,
      })
      if (response.status > 199 && response.status < 300) {
        await getCourseService({ id, partnerId, setIsLoading }).then((res) => setCourseDetails(res))
        toast('Alteração feita com sucesso! 🥳')
      }
    } catch (error) {
      if (error.response.status > 399 && error.response.status < 500) {
        toast(error.response.data.error.message || error.response.message)
      } else {
        toast('Algo de errado aconteceu modificando suas informações 😔 Por favor, tente novamente.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const extraBreadCrumbs = React.useMemo(() => {
    if (!courseDetails) return []
    if (isMobile) return []
    return [<span key={courseDetails.content.name}>{courseDetails.content.name}</span>]
  }, [courseDetails, isMobile])

  React.useEffect(() => {
    getCourseService({ id, partnerId, setIsLoading }).then((res) => setCourseDetails(res))
  }, [])

  return {
    isMobile,
    goToPage,
    courseDetails,
    editVisibilityModalRef,
    deleteModalRef,
    shareLinkModalRef,
    openModals,
    closeModals,
    deleteCourseMethod,
    toggleVisibility,
    extraBreadCrumbs,
  }
}
