import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  border: 2px solid ${theme.colors.blue600};

  width: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${({ selected }) =>
    !selected &&
    css`
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    `}

  ${({ available }) =>
    !available &&
    css`
      cursor: not-allowed;
    `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: 15px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    margin: 15px 0;
  }
`

export const IconSection = styled.div``
