/* eslint-disable react/prop-types */
import React from 'react'

import { ContainerText } from './styles'

export const Title = (props) => {
  const { text } = props

  return <ContainerText>{text}</ContainerText>
}
