import React, { useContext } from 'react'

import { theme, TextLink } from '@provi/provi-components'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { FilterModal } from '~/components/modals/filter-modal'
import { CalcModalV2 } from '~/components/modals/calc-modal-v2'
import { ProductModal } from '~/components/modals/product-modal'
import { LastUpdateModal } from '~/components/modals/last-update-modal'
import { CopyLinks } from '~/components/modals/copy-links-modal'
import { CheckCsvModal } from '~/components/modals/check-csv-modal'
import { DetailedUpdatesModal } from '~/components/modals/detailed-updates-modal'

import { SimpleTable } from '~/components/table'
import { Header } from '~/components/table/header-table'
import { Text } from '~/themes/styles'
import { clearAndReload } from '~/utils'

import { search_options } from '../../../constants/search_options'
import { pagination_options } from '~/constants/pagination_options'
import { useCheckoutList } from './hooks'
import { Content, ContainerTable } from './styles'
import { FlexCalculator } from '~/components/v3'

export const CheckoutList = () => {
  const { setIsLoading, isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    filterModal,
    handleModal,
    lastUpdateModal,
    lastUpdateData,
    productsModal,
    showCells,
    setShowCells,
    calcModal,
    ProductData,
    setProductData,
    handleSearch,
    search,
    setRows,
    rows,
    pageIndicator,
    itemsPerPage,
    page,
    setPage,
    handleSearchKey,
    activeBox,
    handleActiveBox,
    handlePlaceholder,
    totalItens,
    totalPages,
    setItemsPerPage,
    setPageIndicator,
    warningMessage,
    preferences,
    copylinkModal,
    copyLinksData,
    csvModal,
    csvModalData,
    getDataCheckouts,
    setFilterStatus,
    UpdatesModal,
    updatesModalData,
    selectedByUserCourses,
    calcConfig,
    handleCalcModal,
    isDownloadable,
    filterStatus,
    searchOptions,
    setSearchOptions,
    flexCalcModal,
    handleFlexCalcModal,
  ] = useCheckoutList(setIsLoading, isMobile)

  return (
    <div>
      {(preferences || filterModal) && (
        <FilterModal
          refer="sales"
          show={filterModal}
          handleModal={handleModal}
          showCells={showCells}
          setShowCells={setShowCells}
          preferences={preferences}
        />
      )}

      {flexCalcModal && <FlexCalculator show={flexCalcModal} handleModal={handleFlexCalcModal} calcConfig={calcConfig} />}

      {calcModal && <CalcModalV2 show={calcModal} handleModal={handleCalcModal} calcConfig={calcConfig} />}

      <ProductModal show={productsModal} ProductData={ProductData} handleModal={handleModal} />
      <LastUpdateModal show={lastUpdateModal} lastUpdateData={lastUpdateData} handleModal={handleModal} />
      <CopyLinks show={copylinkModal} copyLinksData={copyLinksData} handleModal={handleModal} />
      <CheckCsvModal
        show={csvModal}
        csvData={csvModalData}
        handleModal={handleModal}
        exportUserCourses={true}
        selectedByUserCourses={selectedByUserCourses}
        filterStatus={filterStatus}
      />
      <DetailedUpdatesModal show={UpdatesModal} updatesData={updatesModalData} handleModal={handleModal} />

      <Header
        handleModal={handleModal}
        //
        activeBox={activeBox}
        handleActiveBox={handleActiveBox}
        //
        handleSearch={handleSearch}
        handleSearchKey={handleSearchKey}
        search={search}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        search_options={search_options}
        //
        handlePlaceholder={handlePlaceholder}
        //
        pageIndicator={pageIndicator}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        //
        totalItens={totalItens}
        totalPages={totalPages}
        pagination_options={pagination_options}
        setPageIndicator={setPageIndicator}
        location="checkouts"
        //
        isDownloadable={isDownloadable}
      />

      <Content drawerWidth={drawerWidth}>
        <ContainerTable isMobile={isMobile} drawerWidth={drawerWidth}>
          {warningMessage ? (
            <>
              <Text bold align="center" color={theme.colors.blue900} mTop="30px" size="24px">
                {warningMessage}
              </Text>
              <Text align="center" color={theme.colors.blue900} mTop="8px" size="14px" bold>
                <TextLink text="Limpar filtros e atualizar pagina" onClick={clearAndReload} />
              </Text>
            </>
          ) : (
            <SimpleTable
              setPage={setPage}
              setFilterStatus={setFilterStatus}
              getData={getDataCheckouts}
              setRows={setRows}
              rows={rows}
              showCells={showCells}
              setShowCells={setShowCells}
              handleModal={handleModal}
              setProductData={setProductData}
              search={search}
            />
          )}
        </ContainerTable>
      </Content>
    </div>
  )
}
