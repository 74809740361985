import { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { isFunction } from 'lodash'
import { showToast } from '@provi/provi-components'

import { getCampaing, putCampaing, getAccess } from '~/services/api'
import { formatValue, formatDate, getTranslatedPaymentMethods } from '~/utils'
import { buildCalcConfigObject } from 'utils/buildCalcConfigObject'

const INITIAL_VALUE = 6
const OPTIONS = {
  shouldSort: true,
  threshold: 0.25, // 0.4
  location: 4,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
}

export const useCampaings = (setIsLoading, activePage, setActivePage) => {
  // searchBox
  const [search, setSearch] = useState('')
  const [countOptions, setCountOptions] = useState(INITIAL_VALUE)

  // aqui vou guardar todas as opções
  const [options, setOptions] = useState([])

  // guardar todas as opções validas visiveis e invisiveis
  const [activeOptions, setActiveOptions] = useState([])
  const [inactiveOptions, setInactiveOptions] = useState([])

  const [activeOptionsShow, setActiveOptionsShow] = useState([])
  const [inactiveOptionsShow, setInactiveOptionsShow] = useState([])

  const [seeMoreActive, setSeeMoreActive] = useState(false)
  const [seeMoreInactive, setSeeMoreInactive] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const [showProductModal, setShowProductModal] = useState(false)
  const [productModalOptions, setProductModalOptions] = useState([])

  const [location, setLocation] = useState('')
  const [modalOption, setModalOption] = useState()
  const [userAccess, setUserAccess] = useState('')

  const [visibleOpt, setVisibleOpt] = useState({ tabIndex: 0 })
  const [isShowCalcModal, setIsShowCalcModal] = useState(false)
  const [calcConfig, setCalcConfig] = useState()

  const handleModal = (loc, bool = false, l, modalOpt) => {
    if (loc === 'product') {
      setShowProductModal(bool)
      setProductModalOptions(modalOpt)
    } else {
      setShowModal(bool)
      setLocation(l)
      setModalOption(modalOpt)
    }
  }

  const handleSearchValue = e => {
    setSearch(e.target.value)
  }

  const incrementNumberOfCourses = () => {
    setSeeMoreActive(!(countOptions + INITIAL_VALUE > activeOptions.length))
    setSeeMoreInactive(!(countOptions + INITIAL_VALUE > inactiveOptions.length))

    setCountOptions(countOptions + INITIAL_VALUE)
  }

  const getSlice = () => {
    let result = []

    if (search !== '') {
      let campaigns = []
      const fuse = new Fuse(options, OPTIONS)
      result = fuse.search(search)
      campaigns = result.map(res => res.item)
      //
      const active = []
      const inactive = []

      campaigns.forEach(campaign => {
        if (campaign.active) {
          active.push(campaign)
        } else {
          inactive.push(campaign)
        }
        return true
      })

      setActiveOptionsShow(active.slice(0, countOptions))
      setInactiveOptionsShow(inactive.slice(0, countOptions))

      setSeeMoreActive(!(countOptions >= active.length))
      setSeeMoreInactive(!(countOptions >= inactive.length))
    } else {
      const activeShow = activeOptions.slice(0, countOptions)
      const inactiveShow = inactiveOptions.slice(0, countOptions)

      setActiveOptionsShow(activeShow)
      setInactiveOptionsShow(inactiveShow)

      setSeeMoreActive(!(countOptions >= activeOptions.length))
      setSeeMoreInactive(!(countOptions >= inactiveOptions.length))
    }
  }

  const handleCopy = () => {
    showToast('Link copiado!')
  }

  const submitHandleEye = async (l, option) => {
    console.log('res submitHandleEye', l, option)
    setIsLoading(true)

    if (l === 'active') {
      // inativar campanha
      // ativo para inativo
      // tirar de ativo e colocar em inativo
      await putCampaing(option.id, false)

      const newSearchCampaigns = activeOptions.filter(opt => opt !== option)

      setActiveOptions([...newSearchCampaigns])
      setActiveOptionsShow([...newSearchCampaigns])

      setInactiveOptions([option, ...inactiveOptions])
      setInactiveOptionsShow([option, ...inactiveOptionsShow])
    } else {
      // ativar campanha
      // inativo para ativo
      // tirar de inativo e colocar em ativo
      await putCampaing(option.id, true)

      const newSearchCampaigns = inactiveOptions.filter(opt => opt !== option)

      setInactiveOptions([...newSearchCampaigns])
      setInactiveOptionsShow([...newSearchCampaigns])

      setActiveOptions([option, ...activeOptions])
      setActiveOptionsShow([option, ...activeOptions])

      setVisibleOpt({ tabIndex: 0 })
    }

    handleModal('campaigns', false)
    setIsLoading(false)
  }

  const handleEye = (condition, option) => {
    // handleModal('campaigns', bool, option)
    handleModal('campaigns', true, condition, option)
  }

  const handleCalcModal = () => {
    setIsShowCalcModal(!isShowCalcModal)
  }

  const handleCalcClick = async (event, option) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()

    try {
      setIsLoading(true)
      const { courses, checkout_price_in_cents, maxInstallmentsToApply } = option
      const amountOfCourses = courses.reduce((accumulator, current) => current.price_in_cents + accumulator, 0)

      const classesIds = []
      const coursesIds = []

      courses.forEach(({ proviId: courseId, courseClassIds }) => {
        classesIds.push(...(courseClassIds || []))
        coursesIds.push(courseId)
      })

      const calcConfigObject = buildCalcConfigObject({
        amountInCents: amountOfCourses,
        inputedValue: checkout_price_in_cents,
        financingCondition: { value: maxInstallmentsToApply },
        coursesIds,
        classesIds,
        shouldCalculateByCourseAndClassId: true,
      })

      setCalcConfig(calcConfigObject)

      handleCalcModal()
    } catch (error) {
      showToast('Ocorreu um erro ao mostrar a calculadora, tente novamente ou entre em contato com a Provi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    if (activePage !== 'campaigns') setActivePage('campaigns')

    const checkAccess = async () => {
      try {
        try {
          const access = await getAccess()
          setUserAccess(access.data.data.abbr)
        } catch (error) {
          console.log('erro no check access')
        }
      } catch (error) {
        //
      }
    }
    checkAccess()

    const getDate = async () => {
      try {
        const response = await getCampaing()
        const { data } = response

        setOptions(data)

        const active = []
        const inactive = []

        data.forEach(d => {
          if (!d.name) {
            d.name = d.slug.split('-').join(' ')
          }
          if (d.checkout_price_in_cents) {
            d.formattedValue = formatValue(d.checkout_price_in_cents)
          }
          if (d.createdAt) d.formattedCreateDate = formatDate(d.createdAt)
          if (d.payment_methods && d.payment_methods.length)
            d.formattedPaymentMethods = d.payment_methods
              .map(method => {
                return getTranslatedPaymentMethods(method)
              })
              .join(', ')

          if (d.active) {
            active.push(d)
          } else {
            inactive.push(d)
          }
        })

        if (active.length > INITIAL_VALUE) setSeeMoreActive(true)
        if (inactive.length > INITIAL_VALUE) setSeeMoreInactive(true)

        setActiveOptions(active)
        setInactiveOptions(inactive)
      } catch (error) {
        showToast('Ocorreu um erro inesperado, atualize a pagina ou contate a Provi')
      }
      setIsLoading(false)
    }

    getDate()
  }, [])

  useEffect(() => {
    getSlice()
  }, [activeOptions, inactiveOptions, countOptions, search])

  return [
    seeMoreActive,
    seeMoreInactive,
    handleSearchValue,
    incrementNumberOfCourses,
    activeOptions,
    inactiveOptions,
    search,
    activeOptionsShow,
    inactiveOptionsShow,
    showModal,
    handleModal,
    handleCopy,
    showProductModal,
    location,
    submitHandleEye,
    handleEye,
    modalOption,
    userAccess,
    productModalOptions,
    visibleOpt,
    setVisibleOpt,
    handleCalcClick,
    isShowCalcModal,
    handleCalcModal,
    calcConfig,
  ]
}
