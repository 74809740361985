import styled, { css, keyframes } from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { theme } from '@provi/provi-components'

export const InputWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}` : '500px')};

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ isDark }) =>
    isDark &&
    css`
      & input {
        color: #fff;
        ::placeholder {
          color: #fff;
        }
      }
    `}
`

const shake = keyframes`
    10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const TextFieldWrapper = styled(TextField)`
  width: 100%;

  ${({ error }) =>
    error &&
    css`
      animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    `}
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
