import React from 'react'
import { theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { ExplanationContainer } from '../../styles'

export const ExplanationBox = ({ isMobile, explanation }) => {
  return (
    <ExplanationContainer>
      <BaseTypography
        fontSize={isMobile ? '11px' : '13px'}
        lineHeight={isMobile ? '16px' : '20px'}
        color={theme.colors.bluePrimary}
      >
        {explanation}
      </BaseTypography>
    </ExplanationContainer>
  )
}
