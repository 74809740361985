import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ChartWrapper = styled.div`
  min-width: 300px;
  min-height: 350px;
  * {
    font-family: 'Montserrat', sans-serif;
  }
`

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #f6f8fb;
  padding-left: 0;
  transition: padding 0.5s;
`

export const Content = styled.div`
  margin: 32px auto;
  padding: 0 16px;
  max-width: 1150px;
  width: 1150px;
  border-radius: 20px;
  background-color: #ffffff;
`

export const Header = styled.div`
  max-width: 100%;
  width: 474px;
  height: 100px;

  display: block;
  margin: 10px 55px 10px 0px;

  h2 {
  }
`

export const ChartSection = styled.section`
  width: 100%;
  height: 500px;
  margin: 35px auto 50px;

  min-width: 300px;
  min-height: 350px;
  * {
    font-family: 'Montserrat', sans-serif;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .recharts-legend-item {
    margin: 0 0 20px 13px;

    span {
      font-weight: normal;
      color: #000 !important;
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 0px solid #000;
  margin-top: 24px;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`
