import React from 'react'
import { BlueBox } from '../../styles'
import { ArrowClock } from 'assets/svgs/v2'

function BlueBoxWithNoData({ isFiltered }) {
  return (
    <BlueBox>
      <p className="paragraph__with-icon">
        <ArrowClock />
        Pendente.
      </p>
      <p>{isFiltered ? 'Você deve criar pelo menos 1 turma.' : 'Nenhuma turma encontrada para o filtro aplicado.'}</p>
    </BlueBox>
  )
}

export { BlueBoxWithNoData }
