import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const ReceivablesGrid = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 1150px;
  margin: 32px auto;
  padding: 0 16px;
  ${theme.breakpoints.down('md')} {
    padding: 0 1rem;
    gap: 1rem;
  }
`

export const FinancialFlowCardGrid = styled.div`
  display: grid;
  padding: 2rem 2rem 2rem 1rem;
  max-width: 1280px;
  background-color: #ffffff;
  gap: 1.5rem;
  border-radius: 20px;
  border: 1px solid #d4daf7;
  box-shadow: 0 4px 14px 0 rgba(180, 194, 215, 0.4);
  transition: 0.3s;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(180, 194, 215, 0.4);
  }

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`

export const EachDetailBox = styled.div`
  display: grid;
  gap: 10px;

  ${({ isCard }) =>
    isCard &&
    css`
      padding: 14px;
      background-color: #ffffff;
      border-radius: 20px;
      border: 1px solid #d4daf7;
      box-shadow: 0 4px 14px 0 rgba(180, 194, 215, 0.4);
      transition: 0.3s;

      :hover {
        box-shadow: 0 6px 20px 0 rgba(180, 194, 215, 0.4);
      }
    `}
`

export const DetailText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  color: #000000;
  font-size: 14px;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }

  ${({ isBluePrimary }) =>
    isBluePrimary &&
    css`
      color: ${theme.colors.bluePrimary};
    `}

  ${({ isRed }) =>
    isRed &&
    css`
      color: ${theme.colors.pink900};
    `}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `}
    
    ${({ isBoldValue }) =>
    isBoldValue &&
    css`
      font-weight: 500;
    `}

  ${({ isFromSmallCards }) =>
    isFromSmallCards &&
    css`
      font-size: 22px;
      ${theme.breakpoints.down('md')} {
        font-size: 16px;
      }
    `}

  ${({ isFromBigCard }) =>
    isFromBigCard &&
    css`
      font-size: 18px;
      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `}

    ${({ isFromHelper }) =>
    isFromHelper &&
    css`
      font-size: 13px;
      ${theme.breakpoints.down('md')} {
        font-size: 11px;
        color: #575757;
      }
    `}
`
export const Header = styled.p`
  margin: 0;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 20px;
      font-weight: 600;

      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `}

  ${({ isBluePrimary }) =>
    isBluePrimary &&
    css`
      color: ${theme.colors.bluePrimary};
      font-size: 20px;
      font-weight: 600;

      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `}

    ${({ isLink }) =>
    isLink &&
    css`
      color: ${theme.colors.bluePrimary};
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;

      ${theme.breakpoints.down('md')} {
        font-size: 12px;
      }
    `}

    ${({ isGridHeader }) =>
    isGridHeader &&
    css`
      color: ${theme.colors.bluePrimary};
      font-size: 16px;
      font-weight: 500;

      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `}
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 1rem;
  }
`

export const FlexChilds = styled.div`
  display: flex;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
    gap: 0.8rem;
  }
`

export const SmallCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #cfcfcf;
  grid-column: 1 / -1;

  ${({ isTransparent }) =>
    isTransparent &&
    css`
      border-top: 1px solid transparent;
    `}
`

export const DropdownContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 4rem;

  ${theme.breakpoints.down('md')} {
    grid-auto-flow: row;
    gap: 1.5rem;
  }
`

export const DropdownFlexChilds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const GridRowFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  ${theme.breakpoints.down('md')} {
    gap: 1rem;
  }
`
