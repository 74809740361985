import styled from 'styled-components'

export const MobileGrid = styled.div`
  display: grid;
  gap: 10px;
`

export const CenteredBtn = styled.div`
  place-self: center;
`
