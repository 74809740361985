import React from 'react'

export const ArrowClock = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.57871 5.04797V10" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.8673 12.476L9.57871 10" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3733 7.08203H19.5V2.95534" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.9983 16.4196C14.7287 17.6893 13.111 18.554 11.3499 18.9043C9.58879 19.2546 7.76336 19.0748 6.10444 18.3876C4.44552 17.7005 3.02762 16.5369 2.03004 15.0439C1.03246 13.5509 0.5 11.7956 0.5 10C0.5 8.2044 1.03246 6.44912 2.03004 4.95614C3.02762 3.46315 4.44552 2.29951 6.10444 1.61236C7.76336 0.925214 9.58879 0.745425 11.3499 1.09573C13.111 1.44603 14.7287 2.3107 15.9983 3.58038L19.4999 7.08199"
      stroke={color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
