/* eslint-disable react/prop-types */
import React from 'react'

import { Wrapper, CheckBox, Active } from './styles'
import { InputMasks } from '~/components/Input'

import { useRelease } from './hooks'

export const ReleaseCourse = (props) => {
  const { value, id } = props

  const [handleInput, validate, date, error, checked, handleCheck] = useRelease(value, id)

  return (
    <Wrapper>
      <CheckBox onClick={() => handleCheck(!checked, true)}>{checked && <Active />}</CheckBox>

      <InputMasks
        className="input-courses"
        width="100px"
        placeholder="  /  /    "
        //
        onBlur={validate}
        onChange={handleInput}
        mask="99/99/9999"
        error={error}
        value={checked ? date : ''}
        onClick={!checked ? () => handleCheck(true) : null}
      />
    </Wrapper>
  )
}
