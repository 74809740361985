import React from 'react'

import { EachDetail } from '..'

import { AutoRowGrid } from '../styles'

export const CEDetails = ({ cridDetails, formatValue, fallbackString }) => {
  return (
    <AutoRowGrid>
      <EachDetail
        title={'Produto'}
        value={cridDetails?.products?.map((eachProduct, index) => (index ? ', ' : '') + eachProduct) || fallbackString}
      />
      <EachDetail title={'Valor da venda'} value={formatValue(cridDetails?.value) || fallbackString} />
      <EachDetail title={'Valores pagos aluno'} value={formatValue(cridDetails?.paidValues, true) || fallbackString} />
      <EachDetail title={'Valor pago Provi'} value={formatValue(cridDetails?.paidValuesProvi, true) || fallbackString} />
      <EachDetail title={'Data prevista TED'} value={cridDetails?.expectedTedDate || fallbackString} />
      <EachDetail title={'Data TED'} value={cridDetails?.TedDate || fallbackString} />
    </AutoRowGrid>
  )
}
