import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Main = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const Container = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 10px;
`

export const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
  margin-bottom: 50px;
`

export const Section = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '30px')};
  width: 500px;

  ${({ align }) =>
    align &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${theme.colors.pink900};
`

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperGetLink = styled.div``
export const WrapperGetLinkTextLink = styled.div`
  display: flex;
  justify-content: center;
`
