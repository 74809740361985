/* eslint-disable react/prop-types */
import React from 'react'

import { Button } from '@provi/provi-components'
import { Modal as ModalComponent } from '../Modal'

export const LogoutModal = ({ handleModal, logout }) => {
  return (
    <ModalComponent
      width="500px"
      isWithoutHeight
      title="Deseja mesmo sair?"
      onDismiss={() => handleModal(false)}
      footer={<Button text="Fazer logout" onClick={logout} />}
    />
  )
}
