import React from 'react'
import { theme } from '@provi/provi-components'
import { Text } from '~/themes/styles'
import { Section, Underline } from '../styles'
import { InlineBold } from './InlineBold'

export const InstallmentValueHelper = () => {
  return (
    <Section>
      <Text mBottom="5px">
        <InlineBold>Legenda da coluna</InlineBold>
      </Text>
      <Text mBottom="5px">
        <Underline color={theme.colors.pink900} underlineColor={theme.colors.pink900}>
          vermelho
        </Underline>
        {': '}
        parcela não paga e em atraso.
      </Text>
      <Text mBottom="5px">
        <Underline color={'#FC7900'} underlineColor={'#FC7900'}>
          laranja
        </Underline>
        {': '}
        parcela não paga e em atraso e aluno com mais do que um pagamento no mês atual.
      </Text>
      <Text mBottom="5px">
        Os valores <Underline>sublinhados</Underline> indicam que o aluno possui mais do que um pagamento no mês atual.
      </Text>
    </Section>
  )
}
