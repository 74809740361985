import React from 'react'
import { formatBoleto } from '~/screens/details/helpers'
import { EachDetail } from '..'

import { AutoRowGrid } from '../styles'

export const BoletoDetails = ({ cridDetails, formatValue, fallbackString }) => {
  return (
    <AutoRowGrid>
      <EachDetail title={'Status'} value={formatBoleto(cridDetails?.bankslipStatus) || fallbackString} />
      <EachDetail title={'Valor do boleto'} value={formatValue(cridDetails?.bankslipValue) || fallbackString} />
      <EachDetail title={'Data de vencimento'} value={cridDetails?.bankslipDueDate || fallbackString} />
      <EachDetail title={'Data do pagamento'} value={cridDetails?.bankslipPaidAt || fallbackString} />
    </AutoRowGrid>
  )
}
