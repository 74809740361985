import React from 'react'
import { theme, ButtonV3 } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { ContainerGrid, TopFlexContainer, TextGrid } from './styles'

export const InformationContainer = ({ leftProps, rightProps, buttonProps }) => {
  return (
    <ContainerGrid>
      <TopFlexContainer>
        <TextGrid>
          <BaseTypography fontSize={'20px'} lineHeight={'28px'} {...(leftProps.isBlue && { color: theme.colors.bluePrimary })}>
            {(leftProps && leftProps.number) ?? '---'}
          </BaseTypography>
          <BaseTypography fontSize={'13px'} lineHeight={'20px'} {...(leftProps.isBlue && { color: theme.colors.bluePrimary })}>
            {(leftProps && leftProps.text) ?? '---'}
          </BaseTypography>
        </TextGrid>
        <TextGrid>
          <BaseTypography fontSize={'20px'} lineHeight={'28px'} {...(rightProps.isBlue && { color: theme.colors.bluePrimary })}>
            {(rightProps && rightProps.number) ?? '---'}
          </BaseTypography>
          <BaseTypography fontSize={'13px'} lineHeight={'20px'} {...(rightProps.isBlue && { color: theme.colors.bluePrimary })}>
            {(rightProps && rightProps.text) ?? '---'}
          </BaseTypography>
        </TextGrid>
      </TopFlexContainer>
      <ButtonV3
        mobileWidth={'100%'}
        width={'100%'}
        {...(buttonProps && { text: buttonProps.text, onClick: buttonProps.onClick })}
      />
    </ContainerGrid>
  )
}
