import React from 'react'
import { theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { EachRowContainer, WhiteBox, BlueBox, SpaceBetweenContainer, InlineParagraphs } from './styles'
import { formatValue } from '~/utils'

export const EachModalRow = ({ isMobile, eachObj, upfrontAmount }) => {
  const fontSize = isMobile ? '11px' : '16px'
  const lineHeight = isMobile ? '16px' : '24px'

  return (
    <EachRowContainer>
      <WhiteBox>
        <InlineParagraphs>
          <BaseTypography fontSize={fontSize} lineHeight={lineHeight} fontWeight={400}>
            Entrada +{' '}
          </BaseTypography>

          <BaseTypography fontSize={fontSize} lineHeight={lineHeight} fontWeight={600} color={theme.colors.bluePrimary}>
            {`${eachObj.installmentsToApply} parcelas de ${formatValue(eachObj.pricePerInstallment, true)} `}
          </BaseTypography>

          <BaseTypography fontSize={fontSize} lineHeight={lineHeight} fontWeight={400}>
            {eachObj.monthlyInterest ? `(${eachObj.monthlyInterest}% a.m.)` : '(sem juros)'}
          </BaseTypography>
        </InlineParagraphs>
      </WhiteBox>
      <BlueBox>
        <SpaceBetweenContainer>
          <BaseTypography fontSize={fontSize} lineHeight={lineHeight} fontWeight={400}>
            Total a pagar
          </BaseTypography>

          <BaseTypography fontSize={fontSize} lineHeight={lineHeight}>
            {formatValue(eachObj.installmentsToApply * eachObj.pricePerInstallment + upfrontAmount, true)}
          </BaseTypography>
        </SpaceBetweenContainer>
      </BlueBox>
    </EachRowContainer>
  )
}
