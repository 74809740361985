import React from 'react'

export const ReceiptIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 13H22.5" stroke="#000B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 17H22.5" stroke="#000B3C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4 26V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H27C27.2652 6 27.5196 6.10536 27.7071 6.29289C27.8946 6.48043 28 6.73478 28 7V26L24 24L20 26L16 24L12 26L8 24L4 26Z"
      stroke="#000B3C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
