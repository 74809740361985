import * as Yup from 'yup'
import { ValidationMessages } from '~/enums'

export const initialValues = {
  file: null,
  password: '',
  email: '',
}

export const ValidationSchema = Yup.object({
  file: Yup.mixed().required(ValidationMessages.requiredField),
  password: Yup.string().required(ValidationMessages.requiredField),
  email: Yup.string().email(ValidationMessages.invalidEmail).required(ValidationMessages.requiredField),
})
