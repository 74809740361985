import React, { useContext, useRef } from 'react'
import { QuestionMark } from '~/assets/svgs/QuestionMark'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { Popover } from '~/components/Popover'
import { TemplateContext } from '~/components/Template/context'
// import { CheckboxSelector } from '~/components/CheckboxSelector'
// import { Menu, Option, MultiValue, animatedComponents } from '~/components/CheckboxSelector/Options'
import { parseCurrency } from '~/utils'
import { CalendarPicker } from '../components/CalendarPicker'
import { ModalDashboard } from '../components/ModalDashboard'
import {
  Wrapper,
  Header,
  HeaderTitle,
  View,
  Card,
  CardTitle,
  CardHeader,
  CardSeparator,
  CardSection,
  SectionTitle,
  SectionData,
  SectionDataValue,
  InformativeText,
  PopoverWrapper,
  CardSeparatorContainer,
  HeaderContent,
} from '../global'
import { useFinancialHooks } from './hooks'
import { DatePickerButton, Selector } from '../styles'
import { SelectorWrapper } from './styles'
import { CalendarIcon } from '~/assets/svgs/calendar-icon'
import { options } from '../variables'

function GeneralFlux() {
  const { drawerWidth } = useContext(DrawerMenuContext)
  const { isMobile } = useContext(TemplateContext)
  const datePickerRef = useRef()
  const {
    startDate,
    endDate,
    visible,
    navigateToPage,
    setModal,
    modal,
    financingFluxData,
    setRangeDates,
    makeDatePickerVisible,
    labelDateButton,
    setVisible,
    setDateKind,
    dateKind,
  } = useFinancialHooks()

  const rightPosition = window?.innerWidth - datePickerRef.current?.getBoundingClientRect()?.right

  return (
    <Wrapper>
      <Header isMobile={isMobile} drawerWidth={drawerWidth}>
        <HeaderContent>
          <HeaderTitle>Geral</HeaderTitle>
          {/* <CheckboxSelector
            placeholder="Filtrar por cursos"
            name="products"
            options={productsFilter}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Menu,
              Option,
              MultiValue,
              animatedComponents,
            }}
            onChange={(selected) => setCheckboxSelected(selected)}
            allowSelectAll={true}
            value={checkboxSelected}
            onFilter={setSelectedProducts}
            onClear={() => setCheckboxSelected(null)}
          /> */}
          <SelectorWrapper>
            <DatePickerButton height={'48px'} ref={datePickerRef} onClick={() => makeDatePickerVisible(datePickerRef)}>
              <CalendarIcon />
              <p>{labelDateButton}</p>
            </DatePickerButton>
            <Selector
              onMenuOpen={() => setVisible(false)}
              name="dateKind"
              width={'220px'}
              height={'48px'}
              options={options}
              onChange={setDateKind}
              defaultValue={dateKind}
            />
          </SelectorWrapper>
        </HeaderContent>
      </Header>
      <View>
        <Card>
          <CardHeader>
            <CardTitle>Fluxo Financeiro</CardTitle>
            <a onClick={() => navigateToPage('checkout/controle-recebimentos')}>Visualizar lista de recebimentos</a>
          </CardHeader>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection isBlue>
            <SectionTitle isMain>Valores Totais</SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos totais efetivados</p>
                <span>{financingFluxData?.totalValues?.totalStudents || 0}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>**Valor total financiado</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.totalValues?.totalValue || 0)}</span>
                  <Popover text="Valor total das vendas realizadas">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="success">
                <p>Valor total recebido</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.totalValues?.receivedValue || 0)}</span>
                  <Popover text="Valor transferido da Provi para a instituição">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="info">
                <p>Valor total a receber*</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.totalValues?.receiveTotalValue || 0)}</span>
                  <Popover text="Valor sujeito a alteração mediante cancelamento">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection>
            <SectionTitle>
              Crédito Educacional Antecipado <span>({financingFluxData?.educationalCreditPercentage}% do valor total)</span>
            </SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos efetivados</p>
                <span>{financingFluxData?.educationalCredit?.totalStudents}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>**Valor financiado</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.educationalCredit?.totalValue || 0)}</span>
                  <Popover text="Valor total das vendas realizadas com Crédito Educacional Antecipado">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="success">
                <p>Valor recebido</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.educationalCredit?.receivedValue || 0)}</span>
                  <Popover text="Valor transferido da Provi para a Instituição">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="info">
                <p>Valor a receber*</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.educationalCredit?.receiveTotalValue || 0)}</span>
                  <Popover text="Valor sujeito a alteração mediante cancelamento">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection>
            <SectionTitle>
              Crédito Educacional Dinâmico (MaaS) <span>({financingFluxData?.maasPercentage}% do valor total)</span>
            </SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos efetivados</p>
                <span>{financingFluxData?.maas?.totalStudents}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>**Valor financiado</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.maas?.totalValue || 0)}</span>
                  <Popover text="Valor total das vendas realizadas com Crédito Educacional Dinâmico (MaaS)">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="success">
                <p>Valor recebido</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.maas?.receivedValue || 0)}</span>
                  <Popover text="Valor transferido da Provi para a Instituição">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue variant="info">
                <p>Valor em aberto</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.maas?.receiveTotalValue || 0)}</span>
                  <Popover text="Valor sujeito a alteração mediante cancelamento">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection isBlue>
            <SectionTitle isMain>Valores totais - ProviPay</SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos totais aprovados</p>
                <span>{financingFluxData?.provipay?.totalCountValue}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>Valor total vendido</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.provipay?.totalValue || 0)}</span>
                  <Popover text="Valor total das vendas realizadas no ProviPay">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue />
              <SectionDataValue />
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection>
            <SectionTitle>
              Cartão de crédito <span>({financingFluxData?.creditCardPercentage}% do valor total)</span>
            </SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos aprovados</p>
                <span>{financingFluxData?.provipay?.totalCountCreditCard}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>Valor da venda</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.provipay?.totalValueCreditCard || 0)}</span>
                  <Popover text="Valor total das vendas por cartão de crédito no ProviPay">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue />
              <SectionDataValue />
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection>
            <SectionTitle>
              Boleto à vista <span>({financingFluxData?.boletoPercentage}% do valor total)</span>
            </SectionTitle>
            <SectionData>
              <SectionDataValue>
                <p>Alunos aprovados</p>
                <span>{financingFluxData?.provipay?.totalCountBoleto}</span>
              </SectionDataValue>
              <SectionDataValue>
                <p>Valor da venda</p>
                <PopoverWrapper>
                  <span>{parseCurrency(financingFluxData?.provipay?.totalValueBoleto || 0)}</span>
                  <Popover text="Valor total das vendas por boleto no ProviPay">
                    <QuestionMark />
                  </Popover>
                </PopoverWrapper>
              </SectionDataValue>
              <SectionDataValue></SectionDataValue>
              <SectionDataValue></SectionDataValue>
            </SectionData>
          </CardSection>
          <CardSeparatorContainer>
            <CardSeparator />
          </CardSeparatorContainer>
          <CardSection>
            <InformativeText>
              ** Os valores dos financiamentos consideram o preço cheio das vendas realizadas e não contemplam refinanciamentos
              e cancelamentos
            </InformativeText>
          </CardSection>
        </Card>
      </View>
      {visible && (
        <CalendarPicker rightPosition={rightPosition} startDate={startDate} endDate={endDate} setRangeDates={setRangeDates} />
      )}
      {modal && <ModalDashboard setModal={setModal} />}
    </Wrapper>
  )
}

export { GeneralFlux }
