import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  min-height: auto;
  display: grid;
  grid-template-rows: auto 1fr auto;

  & * ::placeholder {
    text-overflow: ellipsis;
  }
`

export const Label = styled.p`
  margin: 0;

  max-width: 300px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }

  ${({ isRequired }) =>
    isRequired &&
    css`
      ::after {
        content: ' *';
        color: ${theme.colors.pink900};
      }
    `}

  ${({ isParagraph }) =>
    isParagraph &&
    css`
      font-size: 14px;
      line-height: 18px;
      color: #757575;
      font-weight: initial;
      margin-bottom: 25px !important;

      ${theme.breakpoints.down('md')} {
        font-size: 12px;
        line-height: 14px;
      }
    `}
`

export const Configuration = styled.div`
  padding: 71px 0 24px 0;
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    padding: 54px 0 24px 0;
    display: flex;
    flex-direction: column;

    p ~ div {
      margin-top: 14px;
    }
  }
`

export const BankingInformation = styled.div`
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  padding: 24px 0 24px 0;
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    border-bottom: initial;
    padding: 24px 0 0 0;
  }
`
export const Columns = styled.div`
  display: flex;
  flex-direction: column;

  p ~ div {
    padding: 2px !important;
  }

  & > * + * {
    margin: 5px 0;
  }
`

export const Rows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  [placeholder] {
    font-size: 14px;
    font-weight: initial;
  }

  ${theme.breakpoints.down('md')} {
    [label='Dígito'] {
      width: 100px !important;
      margin-left: 15px;
    }
  }
`
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isError }) =>
    isError &&
    css`
      > * {
        &:first-child {
          border: 1px solid ${theme.colors.pink900};
          border-radius: 3px;
        }
      }
    `}
  ${({ isValid }) =>
    isValid &&
    css`
      > * {
        &:first-child {
          border: 1px solid ${theme.colors.green100};
          border-radius: 3px;
        }
      }
    `}
`

export const StyledSpan = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #de0c4b;
  font-size: 12px;
  margin-top: 2px;
`

export const SaveInformation = styled.div`
  button {
    height: 40px;
  }

  ${theme.breakpoints.up('md')} {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      height: 48px;
    }

    div {
      width: 174px;
    }

    .MuiButtonBase-root {
      width: 174px;
    }

    .MuiButton-label {
      font-size: 16px;
      line-height: 24px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
    }
  }
`
