import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const TextSwitchContainer = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
`

export const FlexContainers = styled.div`
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  background-color: ${({ state }) => (state ? theme.colors.bluePrimary : 'white')};
  transform: ${({ state }) => state && `scaleY(1.05)`};

  ${({ isLeft }) =>
    isLeft &&
    css`
      border: ${({ state }) => `2px solid ${state ? theme.colors.bluePrimary : theme.colors.bluePrimaryLight}`};
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-right: 0;
    `}

  ${({ isRight }) =>
    isRight &&
    css`
      border: ${({ state }) => `2px solid ${state ? theme.colors.bluePrimary : theme.colors.bluePrimaryLight}`};
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      border-left: 0;
    `}
`

export const StyledText = styled.p`
  margin: 0;
  font-size: 12.8px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: ${({ state }) => (state ? 'white' : theme.colors.bluePrimary)};

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
    line-height: 16px;
  }
`
