/**
 *
 * @param {string} dateString - date string without parsed
 * @returns {string} string date parsed on format dd/MM/yyyy
 */
export function formatDate(dateString) {
  if (!dateString) return '---'
  const date = new Date(dateString),
    dayOfTheDate = date.getDate(),
    monthOfTheDate = date.getMonth() + 1,
    yearOfTheDate = date.getFullYear()

  const dayFormatted = dayOfTheDate < 10 ? `0${dayOfTheDate}` : dayOfTheDate,
    monthFormatted = monthOfTheDate < 10 ? `0${monthOfTheDate}` : monthOfTheDate

  return `${dayFormatted}/${monthFormatted}/${yearOfTheDate}`
}
