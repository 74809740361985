import React from 'react'

// Modals

export const shareLinkModal = {
  customHeader: (headerText) => headerText,
  jsx: (linkUrl) => (
    <>
      Olá! Chegou a hora de conhecer a Provi, nossa parceira de pagamentos.
      <br />
      <br />
      Acesse o link e finalize sua compra:
      <br />
      <br />
      <p>
        <b>Link geral de vendas: </b>
        <a href={linkUrl?.link} target="_blank" rel="noopener noreferrer">
          {linkUrl?.link}
        </a>
      </p>
      <br />
      <p>
        <b>Link exclusivo de vendedor: </b>
        <a href={linkUrl?.referralLink} target="_blank" rel="noopener noreferrer">
          {linkUrl?.referralLink}
        </a>
      </p>
    </>
  ),
  primaryBtnText: 'Copiar link',
  secondaryBtnText: 'Copiar texto e link',
  onlyStrings: (linkUrl) =>
    `Olá! Chegou a hora de conhecer a Provi, nossa parceira de pagamentos. Acesse o link e finalize sua compra:\nLink geral de vendas: ${linkUrl.link}\nLink geral de vendedor: ${linkUrl.referralLink}`,
}

export const makeCourseInvisibleModal = {
  header: 'Deseja tornar seu curso invisível?',
  paragraphText: (
    <>
      O curso e todas as turmas associadas <b>não aparecerão no site da Provi</b>, não serão listados no link geral de vendas e
      só poderão ser comercializadas por meio de uma venda específica.
    </>
  ),
  primaryBtnText: 'Tornar invisível',
  secondaryBtnText: 'Cancelar',
}

export const makeCourseVisibleModal = {
  header: 'Deseja tornar seu curso visível?',
  paragraphText: (
    <>
      O curso e todas as turmas associadas <b>serão listados no site da Provi</b>, aparecerão no seu link geral de vendas e
      poderão ser comercializados pelo preço cadastrado no produto.
    </>
  ),
  primaryBtnText: 'Tornar visível',
  secondaryBtnText: 'Cancelar',
}

export const deleteCourseModal = {
  header: 'Excluir curso',
  paragraphText: (
    <>
      Fique atento: essa ação é <b>definitiva</b> e remove todos os dados do curso e suas turmas associadas do sistema.
    </>
  ),
  primaryBtnText: 'Excluir ',
  secondaryBtnText: 'Cancelar',
}

export const deleteClassroomModal = {
  header: 'Excluir turma',
  paragraphText: (
    <>
      Fique atento: essa ação é <b>definitiva</b> e remove todos os dados da turma do sistema.
    </>
  ),
  primaryBtnText: 'Excluir ',
  secondaryBtnText: 'Cancelar',
}

export const makeClassroomInvisibleModal = {
  header: 'Deseja tornar a turma invisível?',
  paragraphText: (
    <>
      Sua turma <b>não será publicada no site da Provi</b>, não será listada no link geral de vendas e só poderá ser
      comercializada por meio de uma venda específica.
    </>
  ),
  primaryBtnText: 'Tornar invisível',
  secondaryBtnText: 'Cancelar',
}

export const makeClassroomVisibleModal = {
  header: 'Deseja tornar a turma visível?',
  paragraphText: (
    <>
      A turma <b>será listada no site da Provi</b>, aparecerá no seu link geral de vendas e poderá ser comercializada pelo preço
      cadastrado no produto.
    </>
  ),
  primaryBtnText: 'Tornar visível',
  secondaryBtnText: 'Cancelar',
}

export const memberkitReminderModal = {
  header: 'Integrar produto MemberKit',
  jsx: (courseClassName) => (
    <>
      Olá! Identificamos que você tem integrações ativas com produtos MemberKit.
      <br />
      <br />
      Caso deseje fazer o mesmo com a nova turma criada, você precisa integrar a turma <b>{courseClassName}</b> com um produto
      MemberKit no menu Integrações.
      <br />
      <br />
      Gostaria de integrar a nova turma a um produto MemberKit?
    </>
  ),
  primaryBtnText: 'Sim, integrar turma',
  secondaryBtnText: 'Não, obrigado',
}
