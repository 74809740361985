import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { buildTabs } from './constants'

export const useDrawer = ({ handleLogoutModal, pathname, goToPage, accessData }) => {
  const [open, setOpen] = useState(false)
  /* If you plan on adding a new expandable tab you must add it constants and then add it to the following state.
   * The drawer component should then be able to handle it automatically.
   */
  const [currentOpenTabs, setCurrentOpenTabs] = useState({
    sales: false,
    financial: false,
    products: false,
  })

  /* Useful functions in case you want to programatically open/close the component.
   */
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerState = () => {
    setOpen(!open)
  }

  const drawerWidth = open ? 240 : 60

  const existingTabs = buildTabs({ goToPage, handleLogoutModal, accessData })
  const currentRoute = pathname

  /* Prevents the menu from rendering when the user is not authenticated
   */

  return {
    open,
    setOpen,
    currentOpenTabs,
    setCurrentOpenTabs,
    handleDrawerClose,
    handleDrawerOpen,
    existingTabs,
    currentRoute,
    handleDrawerState,
    drawerWidth,
  }
}

export const useDrawerStyles = () => {
  const drawerWidth = 240
  const widthTransition = 'width 0.5s, display 0.5s'

  const classes = makeStyles(() => ({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
      transition: 'display 2s',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: widthTransition,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: widthTransition,
    },
    drawerClose: {
      overflowX: 'hidden',
      width: '60px',
      transition: widthTransition,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      transition: 'display 1s',
    },
    content: {
      flexGrow: 1,
    },
    openedWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 12px',
      transition: 'display 1s',
    },
    closedWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px',
      transition: 'display 1s',
    },
  }))()

  return { classes }
}
