import React from 'react'
import { useCancellation } from './hooks'
import { Tag, theme } from '@provi/provi-components'
import { TitleAndText, ActionsContainer, CancellationReversalModal } from './components'
import { TableFallback } from '~/components/v2/TableV2/components'
import { CancellationsCardGrid, SpaceBetweenContainer, InformationGrid } from './styles'
import { BaseTypography } from '~/globalStyles'
import { formatCancellationStatus, formatCancellationWarranty } from '~/screens/requests/helpers'
import { parseISOThenFormatDate } from '~/utils'

export const Cancellation = ({ data, getDetails }) => {
  const { modalRef, openModal, closeModal, signalCancellationReversal } = useCancellation({ data, getDetails })
  const { id, status, warrantyPeriod, comment, requestDate, responseDeadline, canBeContested } = data
  const { status: translatedStatus, statusColor, textColor } = formatCancellationStatus(status)

  return (
    <>
      <CancellationsCardGrid>
        <SpaceBetweenContainer>
          <BaseTypography fontSize={'24px'} color={theme.colors.pink900} fontWeight={700}>
            Cancelamento #{id}
          </BaseTypography>
          <Tag text={translatedStatus} color={statusColor} textColor={textColor} />
        </SpaceBetweenContainer>

        <InformationGrid>
          <TitleAndText title={'Prazo de garantia'} text={formatCancellationWarranty(warrantyPeriod)} />
          <TitleAndText title={'Data da solicitação'} text={parseISOThenFormatDate(requestDate)} />
          <TitleAndText title={'Prazo para resposta'} text={parseISOThenFormatDate(responseDeadline)} />
          <TitleAndText title={'Motivo'} text={comment} />
        </InformationGrid>

        {canBeContested && <ActionsContainer openModal={openModal} />}
        {status === 'reverted' && (
          <TableFallback
            text={'Reversão de cancelamento sinalizada. Agora é só aguardar a confirmação do aluno no Painel do aluno.'}
          />
        )}
      </CancellationsCardGrid>
      <CancellationReversalModal
        modalRef={modalRef}
        closeModal={closeModal}
        signalCancellationReversal={signalCancellationReversal}
      />
    </>
  )
}
