const { REACT_APP_API_ENV } = process.env
import axios from 'axios'
import moment from 'moment'
import { isEmpty, isArray } from 'lodash'
import * as Sentry from '@sentry/browser'
import { showToast } from '@provi/provi-components'
import { randomBytes } from 'crypto'
import { history } from '~/navigation/history'
import { ROOT_URL, WP_URL } from '~/constants'
import { asyncLocalStorage, formatDateToServer, getStorage, parseJSON, checkPreviousToken } from '~/utils'
import { logger } from '~/helpers/logger'
import { formatDatesObj } from '~/helpers/formatDatesObj'
import { setGaUserInfoAndPartner, resetGA, trackEvent } from '../utils/googleAnalytics'
import { LOCALSTORAGE_TOKENS } from '../constants'
import { toast } from 'react-toastify'
import { buildQueryParams } from '~utils/buildQueryParams'

import '~/typings'
import { formatFinancingOptions } from 'utils/formatFinancingOptions'

export const api = axios.create({
  baseURL: ROOT_URL,
  headers: { 'accept-language': 'pt-BR' },
})

export const apiWP = axios.create({
  baseURL: WP_URL,
})

const transactionId = randomBytes(10).toString('hex')

const getToken = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  let userActive = urlParams.get('u')
  const tokens = await asyncLocalStorage.getItem('tokens')

  if (!tokens) {
    const data = await checkPreviousToken()

    return data && data.token
  }

  const parsedTokens = parseJSON(tokens)
  const newToken = parsedTokens[userActive]

  if (!newToken && tokens) {
    const keys = Object.keys(parsedTokens)
    const lastKey = keys.pop()

    const href = new URL(window.location.href)
    href.searchParams.set(`u`, lastKey)
    window.history.pushState(null, null, href)

    return parsedTokens[lastKey]
  }

  return newToken
}

api.interceptors.response.use(
  response => response,
  error => checkIfNotAuthorized(error),
)

api.interceptors.request.use(
  async config => {
    const ignoreRoutes = [
      '/sessions/login',
      '/sessions/change-password',
      '/sessions/provi-login',
      '/password/recovery',
      '/password/redefine',
    ]
    if (!ignoreRoutes.includes(config.url)) {
      const token = await getToken()
      if (token) {
        Sentry.configureScope(scope => {
          scope.setTag('x-request-id', transactionId)
        })
        config.headers['x-request-id'] = transactionId
        config.headers.authorization = token
      } else {
        const data = await checkPreviousToken()
        const newToken = data && data.token
        if (!newToken) {
          showToast('Ocorreu um erro ao validar o seu usuário, por favor, faça o login novamente')

          setTimeout(() => {
            history.push('/')
          }, 1000)
        }
      }
    }
    return config
  },
  error => Promise.reject(error),
)

// Requests

export const checkIfNotAuthorized = async error => {
  if (error.response) {
    if (error.response.status === 401) {
      if (error.response.data.error.message === 'Invalid authorization token') {
        resetGA()
      }
      history.push('/')
    }
  }
  return Promise.reject(error)
}

export const authToken = async (email, password) => {
  try {
    return await api.post('/sessions/login', {
      email,
      password,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getProviLogin = async token => {
  try {
    return await api.post('/sessions/provi-login', {
      token,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
  }
}

export const resetPassword = async (email, password, confirmPassword, token) => {
  try {
    return await api.post('/sessions/change-password', {
      email,
      password,
      confirmPassword,
      token,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getCourses = async () => {
  try {
    return await api.get('/courses?quantity=5000')
  } catch (error) {
    checkIfNotAuthorized(error)
    logger({ error, screen: '/checkout/selecionar-produtos (fn getCourses)' })
    return error.response
  }
}

export const getCourseClasses = async ({ page, active, search }) => {
  try {
    let queryParams = '?page=' + page
    if (search) queryParams = queryParams + '&name=' + search
    queryParams = queryParams + '&active=' + active

    return await api.get(`/course-classes${queryParams}`)
  } catch (error) {
    checkIfNotAuthorized(error)
    logger({ error, screen: '/turmas/listar-turmas (fn getCourseClasses)' })
    return error.response
  }
}

export const getCourseClassInfo = async () => {
  try {
    const {
      data: { content },
    } = await api.get('/v4/course-classes/info')
    return content
  } catch (error) {
    checkIfNotAuthorized(error)
    logger({ error, screen: '/v4/course-classes/info (fn getCourseClassInfo)' })
    return error.response
  }
}
export const getCampus = async () => {
  try {
    const {
      data: { rows },
    } = await api.get('/v4/campus')
    return {
      campus: rows,
    }
  } catch (error) {
    checkIfNotAuthorized(error)
    logger({ error, screen: '/v4/course-classes/info (fn getCourseClassInfo)' })
    return error.response
  }
}

export const getPartnerDetails = async () => {
  try {
    return await api.get('/partners/details')
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    checkIfNotAuthorized(error)

    return error.response
  }
}

export const putCourseClasses = async (id, obj) => {
  try {
    const response = await api.put(`/course-classes/edit/${id}`, obj)
    response &&
      trackEvent({
        category: 'Conversion',
        action: 'Update Course Class',
      })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })

    const errorMessage = error.response?.data?.error?.message || error.response?.data?.errors[0]?.message

    throw new Error(errorMessage)
  }
}

export const postCourseClasses = async obj => {
  try {
    const response = await api.post('/course-classes', obj)
    response &&
      trackEvent({
        category: 'Conversion',
        action: 'Create Course Class',
      })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })

    const errorMessage = error.response?.data?.error?.message || error.response?.data?.errors[0]?.message

    throw new Error(errorMessage)
  }
}
export const postCourseClassesV4 = async obj => {
  try {
    const response = await api.post('/v4/course-classes', obj)
    response &&
      trackEvent({
        category: 'Conversion',
        action: 'Create Course Class',
      })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })

    const errorMessage = error.response?.data?.error?.message || error.response?.data?.errors[0]?.message

    throw new Error(JSON.stringify(errorMessage))
  }
}

export const listCourseClassesV4 = async ({ CourseId, itensPerPage = 500 }) => {
  try {
    const PartnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })

    const response = await api.get('/v4/course-classes', {
      params: {
        PartnerId,
        CourseId,
        itensPerPage,
      },
    })
    response &&
      trackEvent({
        category: 'List',
        action: 'List Course Class',
      })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })

    const errorMessage = error.response?.data?.error?.message || error.response?.data?.errors[0]?.message

    throw new Error(errorMessage)
  }
}

export const postCourses = async obj => {
  try {
    const response = await api.post('/courses', obj)
    trackEvent({
      category: 'Conversion',
      action: 'Create Course',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const postCheckouts = async obj => {
  try {
    const response = await api.post('/checkouts', obj)
    trackEvent({
      category: 'Conversion',
      action: 'Create Exclusive Sale',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const postCheckoutsV4 = async obj => {
  try {
    const response = await api.post('/v4/sales/exclusive-sale', obj)
    trackEvent({
      category: 'Conversion',
      action: 'Create Exclusive Sale',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

// List checkouts

export const getListCheckouts = async (page = 1, quantity = 50, search, key, filters) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }

  if (page) {
    let url
    if (search && search.length > 2) {
      url = `/v2/checkouts?page=${page}&quantity=${quantity}&search=${search}`
    } else {
      url = `/v2/checkouts?page=${page}&quantity=${quantity}`
    }

    if (key && search) {
      url = `/v2/checkouts/${key}?page=${page}&quantity=${quantity}&search=${search}`
    }

    try {
      return await api.post(
        url,
        filters
          ? {
              status: filters.status,
              cridOrigin: filters.origin,
              courseIds: filters.products,
              startDate: filters.startDate,
              endDate: filters.endDate,
              startEffectiveDate: filters.startEffectiveDate,
              endEffectiveDate: filters.endEffectiveDate,
              productTypes: filters.productType,
            }
          : {},
      )
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: `ERROR INFO ${error}`,
        level: Sentry.Severity.Warning,
      })
      return error.response
    }
  }
}

const formattedClearArray = array => {
  if (!array || !isArray(array)) return
  return array.map(arr => (arr === 'CLEAR' ? null : arr))
}

export const getReceiptsV3 = async (page = 1, quantity = 50, search, key, filters) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }
  try {
    let url
    if (page) {
      url = `/v3/receipts?page=${page}&quantity=${quantity}`
    }

    if (key === 'CreditRequestId') search = Number(search)

    const formattedKey =
      key && search
        ? {
            [key]: search,
          }
        : {}

    if (url) {
      return await api.post(url, {
        ...formattedKey,
        ...(filters
          ? {
              statuses: filters.status,
              cridOrigin: filters.origin,
              courseIds: filters.products,
              campaigns: filters.campaignName,
              startEffectiveDate: filters.startEffectiveDate,
              endEffectiveDate: filters.endEffectiveDate,
              startPaymentDate: filters.startNextPaymentDate,
              endPaymentDate: filters.endNextPaymentDate,
              startReleaseDate: filters.startReleasedDate,
              endReleaseDate: filters.endReleasedDate,
              startTransferDate: filters.startTedDate,
              endTransferDate: filters.endTedDate,
              startAgreementDate: filters.startAgreementDate,
              endAgreementDate: filters.endAgreementDate,
              productTypes: filters.productType,
              delayedDays: filters.delayed_days,
              delayedInstallments: filters.installments_delayed,
              startExpectedTransferDate: filters.startExpectedTransferDate,
              endExpectedTransferDate: filters.endExpectedTransferDate,
              chargebacks: filters.chargebacks,
              cancellations: filters.cancellations,
            }
          : {}),
      })
    }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error
  }
}

export const getCheckoutsV3 = async (page = 1, quantity = 50, search, key, filters, origin) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }

  if (page) {
    let url
    if (search && search.length > 2) {
      url = `/v3/checkouts?page=${page}&quantity=${quantity}&search=${search}`
    } else {
      url = `/v3/checkouts?page=${page}&quantity=${quantity}`
    }

    const filtersObj = !isEmpty(filters)
      ? {
          status: filters.status,
          cridOrigin: filters.origin,
          courseIds: formattedClearArray(filters.products),
          productTypes: formattedClearArray(filters.productType),
          creators: formattedClearArray(filters.seller),
          campaigns: formattedClearArray(filters.campaignName),
          startDate: filters.startDate,
          endDate: filters.endDate,
          startEffectiveDate: filters.startEffectiveDate,
          endEffectiveDate: filters.endEffectiveDate,
          startExpectedPaymentDate: filters.startExpectedPaymentDate,
          endExpectedPaymentDate: filters.endExpectedPaymentDate,
          startTedDate: filters.startTedDate,
          endTedDate: filters.endTedDate,
          startReleaseDate: filters.startReleasedDate,
          endReleaseDate: filters.endReleasedDate,
          startNextPaymentDate: filters.startNextPaymentDate,
          endNextPaymentDate: filters.endNextPaymentDate,
        }
      : {}

    if (origin === 'receipts' && filtersObj.status && filtersObj.status.length) {
      filtersObj.status = [...filtersObj.status]
    } else if (origin === 'receipts') {
      filtersObj.status = [
        'made_effective',
        'abandonment_after_settled',
        'abandonment_after_upfront',
        'isa_abandonment_after_signed',
      ]
    }

    if (key === 'CreditRequestId') search = Number(search)

    const formattedKey =
      key && search
        ? {
            [key]: search,
          }
        : {}

    try {
      return await api.post(url, {
        ...filtersObj,
        ...formattedKey,
      })
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: `ERROR INFO ${error}`,
        level: Sentry.Severity.Warning,
      })
      return error.response
    }
  }
}

/**
 * @description get finantial movement
 * @function
 * @async
 * @name getFinantialMovement
 * @param {Object} data
 * @param {String} data.search
 * @param {Number} data.page
 * @param {Number} data.quantity
 * @returns {Promise<Object>}
 */
export const getFinantialMovement = async ({ key, search, page, quantity = 25, filters }) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }
  try {
    let url = ''

    if (search && page !== 1) page = 1

    if (page) {
      url = `/v1/maas/get-data?page=${page}&quantity=${quantity}`
    }

    if (key === 'CreditRequestId') search = Number(search)

    const formattedKey =
      key && search
        ? {
            [key]: search,
          }
        : {}

    if (url) {
      return await api.post(url, {
        ...formattedKey,
        ...(filters
          ? {
              courseIds: formattedClearArray(filters.products),
              paymentMethod: formattedClearArray(filters.paidWith),
              startExpectedTransferDate: filters.startExpectedCompensationDate,
              endExpectedTransferDate: filters.endExpectedCompensationDate,
              startTransferDate: filters.startCompensationDate,
              endTransferDate: filters.endCompensationDate,
            }
          : {}),
      })
    }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

// List checkouts

export const postSimulation = async obj => {
  try {
    return await api.post(`/simulations`, obj)
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.message ||
      'Não foi possível calcular o parcelamento, por favor entre em contato com a equipe da Provi'

    logger({ errorMessage, screen: '/checkout/listar-checkouts (CalcModal) (fn postSimulation)' })
    throw new Error(errorMessage)
  }
}

export const updateStatus = async id => {
  try {
    return await api.delete(`/checkouts/${id}`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getStates = async () => {
  try {
    return await api.get(`/states`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getCity = async id => {
  try {
    return await api.get(`/cities?stateId=${id}`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const updateVisible = async (id, condition) => {
  try {
    return await api.put(`/courses/${id}`, {
      visible: condition === 'visible',
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getAccess = async () => {
  try {
    const response = await api.get('/sessions/access-level')
    await asyncLocalStorage.setItem('access-level', response.data.data.accessLevel)

    const url = window.location.href
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const indexToken = urlParams.get('u')

    const tokens = await asyncLocalStorage.getItem('tokens')
    const parseJsonTokens = parseJSON(tokens)
    const token = parseJsonTokens[indexToken]
    const accessLevel = await asyncLocalStorage.getItem(LOCALSTORAGE_TOKENS.accessLevel)
    const userId = getStorage({ key: LOCALSTORAGE_TOKENS.userId })
    const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
    const userEmail = getStorage({ key: LOCALSTORAGE_TOKENS.email })

    const URL_ENV_CHECKOUT = {
      staging: 'checkout-staging.provi.com.br',
      production: 'checkout.provi.com.br',
    }

    const URL_ENV_PAINEL = {
      staging: `https://painel-staging.provi.com.br/?token=${token}`,
      production: `https://painel-2.provi.com.br/?token=${token}`,
    }

    const isUrlCheckout = url.includes(URL_ENV_CHECKOUT[REACT_APP_API_ENV])
    if (isUrlCheckout && indexToken) {
      window.location.replace(URL_ENV_PAINEL[REACT_APP_API_ENV])
    }

    const internalUser = userEmail && !!userEmail.match(/.*(@provi.com.br)/gi)

    setGaUserInfoAndPartner(userId, partnerId, accessLevel, internalUser)
    return response
  } catch (error) {
    logger({ error: error })
    if (error.response?.data?.error?.message === 'Invalid authorization token') {
      showToast('Houve um erro de autenticação, por favor faça o login novamente')
      resetGA()
    }
    return error.response
  }
}

/**
 * @description HTTP GET method that returns homepage introduction information
 * @function
 * @async
 * @name getHomepageIntro
 * @returns {Promise<Object>}
 */
export const getHomepageIntro = async () => {
  try {
    return await api.get(`/v2/partners/introduction`)
  } catch (error) {
    logger({ error, screen: '(fn getHomepageIntro)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns communication card information
 * @function
 * @async
 * @name getCommunicationCard
 * @returns {Promise<Object>}
 */
export const getCommunicationCard = async () => {
  try {
    return await apiWP.get(`/wp-json/acf/v3/options/options/partners_cards`)
  } catch (error) {
    logger({ error, screen: '(fn getCommunicationCard)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns the profile page GeneralData information
 * @function
 * @async
 * @name getProfileGeneralData
 * @returns {Promise<Object>}
 */
export const getProfileGeneralData = async () => {
  try {
    return await api.get(`/v2/partners/general-data`)
  } catch (error) {
    logger({ error, screen: '(fn getProfileGeneralData)' })
    return error.response
  }
}

/**
 * @description HTTP POST method that stores/updates the profile page GeneralData information
 * @function
 * @async
 * @name postProfileGeneralData
 * @returns
 */
export const postProfileGeneralData = async data => {
  try {
    return await api.post(`/v2/partners/general-data`, data)
  } catch (error) {
    logger({ error, screen: '(fn postProfileGeneralData)' })
    return error.response
  }
}

/**
 * @description HTTP DELETE method that deletes the profile page general data address field
 * @function
 * @async
 * @name deleteProfileGeneralDataAddress
 * @returns
 */
export const deleteProfileGeneralDataAddress = async payload => {
  try {
    return await api.delete(`/v2/partners/general-data`, { data: payload })
  } catch (error) {
    logger({ error, screen: '(fn deleteProfileGeneralDataAddress)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns the profile page Finance information
 * @function
 * @async
 * @name getProfileFinance
 * @returns {Promise<Object>}
 */
export const getProfileFinance = async () => {
  try {
    return await api.get(`/bankaccount/show-iugu-and-bankaccount`)
  } catch (error) {
    logger({ error, screen: '(fn getProfileFinance)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns the profile page bank account history
 * @function
 * @async
 * @name getProfileBankHistory
 * @returns {Promise<Object>}
 */
export const getProfileBankHistory = async (page, limit) => {
  try {
    return await api.get(`/bankaccounts?page=${page}&limit=${limit}`)
  } catch (error) {
    logger({ error, screen: '(fn getProfileBankHistory)' })
    return error.response
  }
}

/**
 * @description HTTP POST method that stores/updates the profile page Finance information
 * @function
 * @async
 * @name postProfileFinance
 * @returns
 */
export const postProfileFinance = async data => {
  try {
    return await api.post(`/bankaccount/store-iugu-and-bankaccount`, data)
  } catch (error) {
    logger({ error, screen: '(fn postProfileFinance)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns the profile page contacts information
 * @function
 * @async
 * @name getProfileContacts
 * @returns {Promise<Object>}
 */
export const getProfileContacts = async () => {
  try {
    return await api.get(`/v2/partners/contact`)
  } catch (error) {
    logger({ error, screen: '(fn getProfileContacts)' })
    return error.response
  }
}

/**
 * @description HTTP post method that stores/updates the profile page contacts information
 * @function
 * @async
 * @name postProfileContacts
 */
export const postProfileContacts = async data => {
  try {
    return await api.post(`/v2/partners/contact`, data)
  } catch (error) {
    logger({ error, screen: '(fn postProfileContacts)' })
    return error.response
  }
}

export const putReceipts = async (id, date) => {
  try {
    let formattedDateToServer = null
    if (date) {
      formattedDateToServer = formatDateToServer(date)
    }
    return await api.put(`/receipts/${id}`, {
      date: formattedDateToServer,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

// Campaings
export const getCampaing = async () => {
  try {
    return await api.get(`/campaigns?quantity=500`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const putCampaing = async (id, bool) => {
  try {
    return await api.put(`/campaigns/${id}`, {
      active: bool,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const createCampaing = async data => {
  try {
    const response = await api.post(`/campaigns`, data)
    trackEvent({
      category: 'Conversion',
      action: 'Create Campaign',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const createCampaingV4 = async data => {
  try {
    const response = await api.post(`/v4/campaigns`, data)
    trackEvent({
      category: 'Conversion',
      action: 'Create Campaign',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getCampaingDetails = async () => {
  try {
    const response = await api.get(`campaigns/get-details`)
    trackEvent({
      category: 'Conversion',
      action: 'Get Campaign Details',
    })
    return response
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const postCreditRule = async data => {
  try {
    return await api.post(`/credit-rule`, data)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    return error.response
  }
}

export const getPartnerEmployees = async () => {
  try {
    return await api.get('/partners/users')
  } catch (error) {
    logger({ error, screen: '/usuarios (fn getPartnerEmployees)' })
    return error.response
  }
}

export const addNewUser = async (email, accessLevel) => {
  try {
    return await api.post('/sessions/sign-up', {
      email,
      accessLevel,
    })
  } catch (error) {
    logger({ error, screen: '/usuario/criar-usuario (fn addNewUser)', email })
    return error.response
  }
}

export const generatePassword = async id => {
  try {
    return await api.post('/sessions/generate-password-event', {
      id,
    })
  } catch (error) {
    logger({ error, screen: '/usuario/editar-usuario (fn generatePassword)', id })
    return error.response
  }
}

export const updateUser = async data => {
  try {
    return await api.put('/partners/users', data)
  } catch (error) {
    logger({ error, screen: '/usuario/editar-usuario (fn updateAcessLevel)', ...data })
    return error.response
  }
}

export const getListUpdates = async data => {
  try {
    return await api.post('/partners/users/updates', data)
  } catch (error) {
    logger({ error, screen: '/usuario/listar-usuarios (fn getListUpdates)', ...data })
    return error.response
  }
}

export const getPartnerPreferences = async (origin = 'sales') => {
  try {
    return await api.post(`/plataform/preferences`, {
      module: origin,
    })
  } catch (error) {
    logger({ error, screen: '(fn getPartnerPreferences)' })
    return error.response
  }
}

export const putPartnerPreferences = async data => {
  try {
    return await api.put(`/plataform/preferences`, data)
  } catch (error) {
    logger({ error, screen: '(fn getPartnerPreferences)' })
    return error.response
  }
}

export const getMacroAreas = async () => {
  try {
    return await api.get(`/macroareas`)
  } catch (error) {
    logger({ error, screen: '(fn getMacroAreas)' })
    return error.response
  }
}

export const getMicroAreas = async id => {
  try {
    return await api.get(`/microareas?MacroAreaId=${id}`)
  } catch (error) {
    logger({ error, screen: '(fn getMicroAreas)' })
    return error.response
  }
}
export const postUpdates = async ids => {
  try {
    return await api.post(`/v1/credit-request-updates`, {
      CreditRequestsIds: ids,
    })
  } catch (error) {
    logger({ error, screen: '(fn postUpdates)' })
    return error.response
  }
}

export const postDetailedUpdates = async CreditRequestId => {
  try {
    return await api.post(`/v1/detailed-credit-request-updates`, { CreditRequestId })
  } catch (error) {
    logger({ error, screen: '(fn postDetailedUpdates)' })
    return error.response
  }
}

export const postCsv = async (location, courseIds = [], isReceiptsV2 = false, filterStatus = {}) => {
  try {
    return await api.post(`/partners/csv`, {
      location,
      courseIds,
      isReceiptsV2,
      filterStatus,
    })
  } catch (error) {
    logger({ error, screen: '(fn postCsv)' })
    return error.response
  }
}

export const getPayments = async id => {
  try {
    return await api.get(`/payments?creditRequestId=${id}`)
  } catch (error) {
    logger({ error, screen: '(fn getPayments)' })
    return error.response
  }
}

export const getFiltersCheckout = async () => {
  try {
    return await api.get(`/partners/checkout/filters`)
  } catch (error) {
    logger({ error, screen: '(fn getFilters)' })
    return error.response
  }
}

export const getFiltersV2Receipts = async () => {
  try {
    return await api.get(`/v2/partners/receipts/filters`)
  } catch (error) {
    logger({ error, screen: '(fn getFilters)' })
    return error.response
  }
}

/**
 * @description HTTP GET method that returns all available filters from ms_checkout
 * @function
 * @async
 * @name getFiltersFinancialMovement
 * @returns {Promise<Object>}
 */
export const getFiltersFinancialMovement = async () => {
  try {
    return await api.get(`/partners/financial-control/filters`)
  } catch (error) {
    logger({ error, screen: '(fn getFilters)' })
    return error.response
  }
}

export const getGuarantorLinks = async id => {
  try {
    return await api.get(`/v2/links?CreditRequestId=${id}`)
  } catch (error) {
    logger({ error, screen: '(fn getGuarantorLinks)' })
    return error.response
  }
}

export const putSoftDelete = async id => {
  try {
    return await api.put(`/courses/delete/${id}`)
  } catch (error) {
    logger({ error, screen: '(fn putSoftDelete)' })
    return error.response
  }
}
/**
 * Brief description of the object here.
 * @typedef {Object} IFluxDataParams
 * @property {Date} startDate - Brief description of the key here.
 * @property {Date} endDate - Brief description of the optional key here, if applicable.
 * @property {string} dateKind - Brief description of the key here. Use this for keys inside other keys.
 * @property {string} productsSelected - Brief description of the key here. Use this for keys inside other keys.
 */

/**
 * Brief description of the function here.
 * @name getFinanceFluxData
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {IFluxDataParams} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */

export const getFinanceFluxData = async ({ startDate, endDate, productsSelected, datekind }) => {
  try {
    const filters = []

    if (productsSelected.length > 0) {
      filters.push({
        filterType: 'product',
        CourseIds: productsSelected,
      })
    }
    if (startDate && endDate) {
      filters.push({
        filterType: 'daterange',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        datekind,
      })
    }

    return await api.post(
      '/v1/monitoring/financial-flow',
      filters.length > 0
        ? {
            filters,
          }
        : {},
    )
  } catch (error) {
    logger({ error, screen: '(fn getFinanceFluxData)' })
    return error.response
  }
}

/**
 * @name getSalesFunnelData
 * @summary Gets the sold products to populate the chart
 * @param {IFluxDataParams} parameterNameHere - All Filters for the monitoring dashboard
 * @return {Promise<Object>} Sold products data for the chart
 */

export const getSalesFunnelData = async ({ startDate, endDate, productsSelected, datekind }) => {
  try {
    const filters = []

    if (productsSelected.length > 0) {
      filters.push({
        filterType: 'product',
        CourseIds: productsSelected,
      })
    }
    if (startDate && endDate) {
      filters.push({
        filterType: 'daterange',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        datekind,
      })
    }

    return await api.post(
      'v1/monitoring/sales-funnel',
      filters.length > 0
        ? {
            filters,
          }
        : {},
    )
  } catch (error) {
    logger({ error, screen: '(fn getFinanceFluxData)' })
    return error.response
  }
}

/**
 * @typedef ISoldProductsResponse
 * @property {Number} CourseId
 * @property {String} count
 * @property {String} name
 * @property {String} sum
 */

/**
 * @name getSoldProducts
 * @summary Gets the sold products to populate the chart
 * @param {IFluxDataParams} parameterNameHere - All Filters for the monitoring dashboard
 * @return {Promise<Array<ISoldProductsResponse>>} Sold products data for the chart
 */
export const getSoldProducts = async ({ startDate, endDate, productsSelected, datekind }) => {
  try {
    const filters = []

    if (productsSelected.length > 0) {
      filters.push({
        filterType: 'product',
        CourseIds: productsSelected,
      })
    }
    if (startDate && endDate) {
      filters.push({
        filterType: 'daterange',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        datekind,
      })
    }
    const { data } = await api.post('v1/monitoring/sold-products', filters.length > 0 ? { filters } : {})
    return data
  } catch (error) {
    console.log('ERROR Sold products', error)
    logger({ error, screen: '(fn getSoldProducts)' })
  }
}

/**
 * @name getPartnerIsEnabledOnProviPay
 * @returns {Promise<{canEmitNFSe: boolean, error: boolean, isPartnerEnabledOnProviPay: boolean}>}
 */
export const getPartnerIsEnabledOnProviPay = async () => {
  try {
    const { data } = await api.get('v1/proviPay/is-partner-enabled')
    return data
  } catch (error) {
    logger({ error, screen: '(fn getPartnerIsEnabledOnProviPay)' })
  }
}

/**
 * @name getDataFromSchool
 * @returns {Promise<ISchoolInfo>}
 */
export const getDataFromSchool = async () => {
  try {
    const { data } = await api.get('v1/nfse/create-company-info')
    return data
  } catch (error) {
    return error.response
  }
}

/**
 * @name getFullDataFromSchool
 * @returns {Promise<ISchoolInfo>}
 */
export const getFullDataFromSchool = async () => {
  try {
    const { data } = await api.get('v1/nfse/get-company')
    return data
  } catch (error) {
    return error.response
  }
}

/**
 * @name getDataFromSchool
 * @returns {Promise<ISchoolInfo>}
 */
export const sendDataFromSchool = async body => {
  try {
    const data = await api.post('v1/nfse/create-company', body)
    return data
  } catch (error) {
    return error.response
  }
}

/**
 * @name getPartnerStatus
 * @returns {Promise<IPartnerStatus>}
 */
export const getPartnerStatus = async () => {
  try {
    const { data } = await api.get('v1/nfse/partner-status')
    return data
  } catch (error) {
    logger({ error, screen: '(fn getPartnerStatus)' })
  }
}

/**
 * @name sendBasicInfo
 * @param {{ jobTitle: string, phone: string, fullName: string }} body
 * @returns {Promise<void>}
 */
export const sendBasicInfo = async body => {
  try {
    await api.post('v1/partnerEmployee/update-basic-info', {
      ...body,
    })
  } catch (error) {
    logger({ error, screen: '(fn sendBasicInfo)' })
  }
}

/**
 * @description get request to get crid details
 * @name getCridDetails
 * @function
 * @async
 * @param {Number} CreditRequestId
 * @returns {Promise<Object>}
 */

export const getCridDetails = async CreditRequestId => {
  try {
    return await api.post('v1/credit-request-details', {
      CreditRequestId,
    })
  } catch (error) {
    showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    return error.response
  }
}

/**
 * @description get payment flows of installment id
 * @name getInstallmentPayments
 * @function
 * @async
 * @param {Number} CreditRequestId
 * @returns {Promise<Object>}
 */

export const getInstallmentPayments = async ({ InstallmentId, CreditRequestId }) => {
  try {
    return await api.post('receipts/get-payment-flows', {
      InstallmentId,
      CreditRequestId,
    })
  } catch (error) {
    logger({ error, screen: '(fn getInstallmentPayments)' })
  }
}

/**
 * @description get all services registered
 * @name getRegisteredServices
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getRegisteredServices = async () => {
  try {
    return await api.get('v1/nfse/nfse-services')
  } catch (error) {
    logger({ error })
  }
}

/**
 * @description create new service nfse
 * @name sendNewNFSEService
 * @function
 * @param {Object} serviceObject
 * @async
 * @return {Promise<Object>}
 */
export const sendNewNFSEService = async data => {
  try {
    return await api.post('v1/nfse/create-nfse-service', data)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    throw error
  }
}

/**
 * @description create new service default
 * @name sendServiceDefault
 * @function
 * @param {Object} serviceObject
 * @async
 * @return {Promise<Object>}
 */
export const sendServiceDefault = async body => {
  try {
    return await api.post('v1/nfse/create-partner-service', body)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    throw error
  }
}

export const getPartnerServices = async () => {
  try {
    return await api.get('/v1/nfse/partner-services')
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    throw error
  }
}
export const setCourseTriggerDefault = async postBody => {
  try {
    return await api.post('/v1/nfse/create-partner-service', postBody)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning,
    })
    throw error
  }
}

/**
 * @name createNewCertificate
 * @function
 * @async
 * @param {String} certificateUrl
 * @param {String} password
 * @param {String} email
 * @returns {Promise<Object>}
 */
export const createNewCertificate = async ({ certificateUrl, password, email }) => {
  try {
    return await api.post('/v1/nfse/create-certificate', {
      certificateUrl,
      password,
      email,
    })
  } catch (error) {
    return error.response
  }
}

/**
 * @name readIuguBalance
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const readIuguBalance = async () => {
  try {
    const response = await api.get(`/partners/balance`)
    return response.data
  } catch (error) {
    logger({ error, screen: '(fn readIuguBalance)' })
    showToast('Ocorreu um erro ao buscar as informações da Iugu', false)
  }
}

/**
 * @name readTransactionsHistory
 * @function
 * @param {Number=} size
 * @param {Number=} page
 * @async
 * @returns {Promise<Object>}
 */
export const readTransactionsHistory = async ({ size = 5, page = 1 }) => {
  try {
    const response = await api.get(`/withdraw?size=${size}&page=${page}`)
    return response.data
  } catch (error) {
    logger({ error, screen: '(fn readTransactionsHistory)' })
    showToast('Ocorreu um erro ao buscar as informações do histórico de saques')
  }
}

/**
 * @name withdrawIuguBalance
 * @function
 * @param {Number} amount
 * @async
 * @returns {Promise<Object>}
 */
export const withdrawIuguBalance = async ({ amount }) => {
  try {
    return await api.post(`/financial/withdraw`, {
      amount,
    })
  } catch (error) {
    logger({ error, screen: '(fn withdrawIuguBalance)' })
    showToast('Ocorreu um erro ao efetuar o saque')
    return error?.response
  }
}

/**
 * @name getChargebacks
 * @function
 * @param {Number=} page
 * @param {Number=} limit
 * @async
 * @returns {Promise<Object>}
 */
export const getChargebacks = async (page, pageSize, filters) => {
  const pageFix = page + 1
  try {
    const { data } = await api.get(`/chargeback?page=${pageFix}&limit=${pageSize}`, {
      params: filters,
    })
    return data
  } catch (error) {
    logger({ error, screen: '(fn getChargebacks)' })
    showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
  }
}

/**
 * @name getCancellations
 * @function
 * @param {Number=} page
 * @param {Number=} quantity
 * @async
 * @returns {Promise<Object>}
 */
export const getCancellations = async (page, pageSize) => {
  const pageFix = page + 1
  try {
    return await api.post(`/cancellation?page=${pageFix}&quantity=${pageSize}`)
  } catch (error) {
    logger({ error, screen: '(fn getCancellations)' })
    showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
  }
}

/**
 * @description get a single cancellation information
 * @name getSingleCancellation
 * @function
 * @param {Object} crid
 * @async
 * @return {Promise<Object>}
 */
export const getSingleCancellation = async crid => {
  try {
    return await api.post(`/cancellation?page=1&quantity=10`, {
      CreditRequestId: crid,
    })
  } catch (error) {
    showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    logger({ error, screen: screen || '/details (fn getSingleCancellation)' })
    return error.response
  }
}

/**
 * @description send chargeback information
 * @name sendChargebackInformation
 * @function
 * @param {Number} ProviPayChargebackId
 * @param {Object} urls
 * @param {string} ProviPayChargebackId
 * @async
 * @return {Promise<Object>}
 */
export const sendChargebackInformation = async ({ ProviPayChargebackId, urls, description }) => {
  try {
    return await api.post('/chargeback', {
      ProviPayChargebackId,
      urls,
      description,
    })
  } catch (error) {
    logger({ error, screen: screen || '/details (fn sendChargebackInformation)' })
    return error.response
  }
}

/**
 * @description send chargeback documents
 * @name readChargebackDocuments
 * @function
 * @param {Number} ProviPayChargebackId
 * @async
 * @return {Promise<Object>}
 */
export const readChargebackDocuments = async ProviPayChargebackId => {
  try {
    const response = await api.get(`/chargeback/${ProviPayChargebackId}/documents?limit=15`)
    return response.data
  } catch (error) {
    logger({ error, screen: screen || '/details (fn readChargebackDocuments)' })
    return error.response
  }
}

/**
 * @description send school answer
 * @name sendSchoolAnswer
 * @function
 * @param {Number} CreditRequestId
 * @param {Boolean} schoolAnswer
 * @async
 * @return {Promise<Object>}
 */
export const sendSchoolAnswer = async (CreditRequestId, schoolAnswer) => {
  const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
  try {
    return await api.put(`/cancellation?PartnerId=${partnerId}`, {
      CreditRequestId,
      schoolAnswer,
    })
  } catch (error) {
    logger({ error, screen: screen || '/details (fn sendSchoolAnswer)' })
    return error.response
  }
}

/**
 * @name getChartReceipts
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChartReceipts = async ({ startDate, endDate, datekind, productsSelected }) => {
  try {
    const filters = []

    if (startDate && endDate) {
      filters.push({
        filterType: 'daterange',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        datekind,
      })
    }
    if (productsSelected?.length > 0) {
      filters.push({
        filterType: 'product',
        CourseIds: productsSelected,
      })
    }

    return await api.post(`/dashboard/receipts`, filters.length > 0 ? { filters } : {})
  } catch (error) {
    logger({ error, screen: '(fn getChartReceipts)' })
    showToast('Ocorreu um erro ao buscar suas informações de recebimentos')
  }
}

/**
 * @name getChartDefaultComparison
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChartDefaultComparison = async ({ productsSelected }) => {
  try {
    let params = {}

    if (productsSelected.length > 0) {
      params = {
        course_ids: productsSelected,
      }
    }
    return await api.get('/v1/monitoring/non-payments', {
      params,
    })
  } catch (error) {
    logger({ error, screen: '(fn getChartDefaultComparison)' })
    showToast('Ocorreu um erro ao buscar suas informações de inadimplência')
  }
}

/**
 * @name getChartCollections
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChartCollections = async ({ productsSelected }) => {
  try {
    let params = {}

    if (productsSelected.length > 0) {
      params = {
        course_id: productsSelected,
      }
    }
    return await api.get('/collection/action', {
      params,
    })
  } catch (error) {
    logger({ error, screen: '(fn getChartCollections)' })
    showToast('Ocorreu um erro ao buscar suas informações de ações de cobrança')
  }
}

/**
 * @name getChartDefaultRate
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChartDefaultRate = async ({ productsSelected }) => {
  try {
    let params = {}

    if (productsSelected.length > 0) {
      params = {
        filterType: 'product',
        CourseIds: productsSelected,
      }
    }

    return await api.get('/non-payment/defaultrate', {
      params,
    })
  } catch (error) {
    logger({ error, screen: '(fn getChartDefaultRate)' })
    showToast('Ocorreu um erro ao buscar suas informações de grau de inadimplência')
  }
}

/**
 * @name getChartDefaultStatus
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChartDefaultStatus = async ({ productsSelected }) => {
  try {
    let params = {}

    if (productsSelected.length > 0) {
      params = {
        filterType: 'product',
        CourseIds: productsSelected,
      }
    }
    return await api.get('/non-payment/defaultstatus', {
      params,
    })
  } catch (error) {
    logger({ error, screen: '(fn getChartDefaultStatus)' })
    showToast('Ocorreu um erro ao buscar suas informações da situação dos inadimplentes')
  }
}

/**
 * @name getChargebackFilters
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getChargebackFilters = async () => {
  try {
    return await api.get('/chargeback/filter')
  } catch (error) {
    logger({ error, screen: '(fn getChargebackFilters)' })
    showToast('Ocorreu um erro ao buscar os filtros da tabela de chargebacks')
  }
}

/**
 * @name getReceiptsV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getReceiptsV4 = async (search, key, filters, page = 1, limit = 50) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }
  if (key === 'CreditRequestId') search = Number(search)

  const params = {
    page: page,
    limit: limit,
    ...(key && search ? { [key]: search } : {}),
    ...(filters
      ? {
          status: filters.status,
          cridOrigin: filters.origin,
          courseId: filters.products,
          campaigns: filters.campaignName,
          startEffectiveDate: filters.startEffectiveDate,
          endEffectiveDate: filters.endEffectiveDate,
          startPaymentDate: filters.startNextPaymentDate,
          endPaymentDate: filters.endNextPaymentDate,
          startReleaseDate: filters.startReleasedDate,
          endReleaseDate: filters.endReleasedDate,
          startTransferDate: filters.startTedDate,
          endTransferDate: filters.endTedDate,
          startAgreementDate: filters.startAgreementDate,
          endAgreementDate: filters.endAgreementDate,
          productType: filters.productType,
          delayedDay: filters.delayed_days,
          delayedInstallment: filters.installments_delayed,
          startExpectedTransferDate: filters.startExpectedTransferDate,
          endExpectedTransferDate: filters.endExpectedTransferDate,
        }
      : {}),
  }

  try {
    return await api.get('/v4/receipts', params ? { params } : {})
  } catch (error) {
    logger({ error, screen: '(fn getReceiptsV4)' })
    showToast('Ocorreu um erro ao buscar suas informações')
  }
}

/**
 * @name getReceiptsFiltersV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getReceiptsFiltersV4 = async () => {
  try {
    return await api.get('/v4/receipts/available-filters')
  } catch (error) {
    logger({ error, screen: '(fn getReceiptsFiltersV4)' })
    showToast('Ocorreu um erro ao buscar seus filtros')
  }
}

/**
 * @name getFinancialMovementV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getFinancialMovementV4 = async ({ key, search, page, quantity = 25, filters }) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }
  if (key === 'CreditRequestId') search = Number(search)

  const params = {
    page: page,
    limit: quantity,
    ...(key && search ? { [key]: search } : {}),
    ...(filters
      ? {
          ...(filters.products ? { courseIds: filters.products } : {}),
          ...(filters.paidWith ? { paymentMethod: formattedClearArray(filters.paidWith) } : {}),
          ...(filters.startExpectedCompensationDate
            ? { startExpectedTransferDate: filters.startExpectedCompensationDate }
            : {}),
          ...(filters.endExpectedCompensationDate ? { endExpectedTransferDate: filters.endExpectedCompensationDate } : {}),
          ...(filters.startCompensationDate ? { startTransferDate: filters.startCompensationDate } : {}),
          ...(filters.endCompensationDate ? { endTransferDate: filters.endCompensationDate } : {}),
        }
      : {}),
  }
  const query = new URLSearchParams({
    ...params,
  }).toString()

  try {
    return await api.get(`/v4/financial/maas?${query}`)
  } catch (error) {
    logger({ error, screen: '(fn getFinancialMovementV4)' })
    showToast('Ocorreu um erro ao buscar suas informações')
  }
}

/**
 * @name getFinancialMovementFiltersV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getFinancialMovementFiltersV4 = async () => {
  try {
    return await api.get('/v4/financial/maas/available-filters')
  } catch (error) {
    logger({ error, screen: '(fn getFinancialMovementFiltersV4)' })
    showToast('Ocorreu um erro ao buscar seus filtros')
  }
}

/**
 * @name getCheckoutsV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getCheckoutsV4 = async ({ page = 1, quantity = 50, search, key, filters }) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }

  if (key === 'CreditRequestId') search = Number(search)

  const params = {
    page: page,
    limit: quantity,
    ...(key && search ? { [key]: search } : {}),
    ...(filters
      ? {
          status: filters.status,
          cridOrigin: filters.origin,
          courseId: formattedClearArray(filters.products),
          productType: formattedClearArray(filters.productType),
          creator: formattedClearArray(filters.seller),
          campaign: formattedClearArray(filters.campaignName),
          startDate: filters.startDate,
          endDate: filters.endDate,
          startEffectiveDate: filters.startEffectiveDate,
          endEffectiveDate: filters.endEffectiveDate,
          startExpectedPaymentDate: filters.startExpectedPaymentDate,
          endExpectedPaymentDate: filters.endExpectedPaymentDate,
          startTedDate: filters.startTedDate,
          endTedDate: filters.endTedDate,
          startReleaseDate: filters.startReleasedDate,
          endReleaseDate: filters.endReleasedDate,
          startNextPaymentDate: filters.startNextPaymentDate,
          endNextPaymentDate: filters.endNextPaymentDate,
        }
      : {}),
  }

  try {
    return await api.get('/v4/sales', params ? { params } : {})
  } catch (error) {
    logger({ error, screen: '(fn getCheckoutsV4)' })
    showToast('Ocorreu um erro ao buscar suas informações')
  }
}

/**
 * @name getCheckoutFiltersV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getCheckoutFiltersV4 = async () => {
  try {
    return await api.get('/v4/sales/available-filters')
  } catch (error) {
    logger({ error, screen: '(fn getCheckoutFiltersV4)' })
    showToast('Ocorreu um erro ao buscar seus filtros')
  }
}

export const getV4MacroAreas = async ({ setIsLoading }) => {
  try {
    setIsLoading(true)

    const { data } = await api.get('/v4/areas/macro')

    return data
  } catch (error) {
    logger({ error, screen: '(fn getV4MacroAreas)' })
    toast('Ocorreu um erro ao buscar as informações de área. ')
  } finally {
    setIsLoading(false)
  }
}

export const getV4MicroAreas = async ({ macroAreaId, setIsLoading }) => {
  try {
    setIsLoading(true)

    const { data } = await api.get(`/v4/areas/micro-area-group/${macroAreaId}`)

    return data
  } catch (error) {
    logger({ error, screen: '(fn getV4MacroAreas)' })
    toast('Ocorreu um erro ao buscar as informações de área. Por favor, tente novamente mais tarde.')
  } finally {
    setIsLoading(false)
  }
}

export const getTypeFormData = async formId => {
  return api.get(`/v4/typeform/responses/${formId}`)
}

export const putPartnerCancellationResponse = async putBody => {
  return api.put('/v4/cancellations', putBody)
}

export const getFinancingConditions = async ({ CourseIds = [], CourseClassIds = [], formik }) => {
  try {
    const query = buildQueryParams({ CourseIds, CourseClassIds })

    const { data } = await api.get(`/v4/financing-conditions?${query}`)

    const options = formatFinancingOptions({ data, formik })

    return { ...data, options }
  } catch (error) {
    logger({ error, screen: 'getFinancingConditions' })
    toast('Ocorreu um erro ao buscar opções de financiamento.')
  }
}

export const getAdminFinancingConditions = async crid => {
  try {
    return api.get(`v4/admin/flex-conditions/${crid}`)
  } catch (error) {
    logger({ error, screen: 'getAdminFinancingConditions' })
    return error
  }
}

/**
 * @name getGuaranteedTuitionV4
 * @function
 * @async
 * @returns {Promise<Object>}
 */
export const getGuaranteedTuitionV4 = async ({ key, search, page, quantity = 25, filters }) => {
  if (filters && filters.hasFormatDates) {
    filters = formatDatesObj(filters)
  }
  if (key === 'CreditRequestId') search = Number(search)

  const params = {
    page: page,
    limit: quantity,
    ...(key && search ? { [key]: search } : {}),
    ...(filters
      ? {
          ...(filters.products ? { courseIds: filters.products } : {}),
          ...(filters.startTuitionTransferDate ? { startTuitionTransferDate: filters.startTuitionTransferDate } : {}),
          ...(filters.endTuitionTransferDate ? { endTuitionTransferDate: filters.endTuitionTransferDate } : {}),
        }
      : {}),
  }
  const query = new URLSearchParams({
    ...params,
  }).toString()

  try {
    return await api.get(`/v4/financial/guaranteed-payment?${query}`)
  } catch (error) {
    logger({ error, screen: '(fn getGuaranteedTuitionV4)' })
    showToast('Ocorreu um erro ao buscar suas informações')
  }
}
