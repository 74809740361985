const initialServicesHeaders = [
  { item: 'id', label: 'ID serviço', show: true, blocked: false },
  { item: 'CityId', label: '', show: false, blocked: true },
  { item: 'active', label: '', show: false, blocked: true },
  { item: 'itemListServiceCode', label: 'Código do item na lista de serviço', show: true, blocked: false },
  { item: 'integrationId', label: '', show: false, blocked: true },
  { item: 'taxCode', label: 'Código de tributação no município', show: true, blocked: false },
  { item: 'cnae', label: 'CNAE', show: true, blocked: false },
  { item: 'issAliquota', label: 'Alíquota do ISS', show: true, blocked: false },
  { item: 'createdAt', label: '', show: false, blocked: true },
  { item: 'updatedAt', label: '', show: false, blocked: false },
  {
    item: 'isDefault',
    label: 'Serviço Padrão',
    show: true,
    blocked: false,
    button: { active: 'SERVIÇO PADRÃO', notActive: 'DEFINIR PADRÃO' },
  },
]

const initialCoursesHeader = [
  { item: 'id', label: 'ID curso', show: true, blocked: false },
  { item: 'name', label: 'Nome do curso', show: true, blocked: false },
  { item: 'price', label: 'Preço', show: true, blocked: true },
  { item: 'NFSeServiceId', label: '', show: false, blocked: false },
  { item: 'trigger', label: 'Data de expedição', show: true, blocked: false, select: true },
]

export const defaultTrigger = (triggers) => triggers.filter((x) => x.isDefault == true)
export const triggerFormat = (triggers) => {
  return triggers.map(({ id, label, isDefault }) => ({
    value: id,
    label,
    isDefault,
  }))
}

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ table: String }} params
 * @return {typeof initialHeaders}
 */
export const getHeaderTableValues = ({ table }) => {
  switch (table) {
    case 'services':
      return initialServicesHeaders
    case 'courses':
      return initialCoursesHeader
    default:
      return []
  }
}
