/* eslint-disable react/prop-types */
import React from 'react'

export const TrashV2 = ({ size = 16, stroke = '#B0AFAF', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M17 3.90906L1 3.90906" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.81836 8.27271V14.0909" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.1816 8.27271V14.0909" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.546 3.90906V17.7272C15.546 17.9201 15.4694 18.1051 15.333 18.2415C15.1966 18.3779 15.0116 18.4545 14.8187 18.4545H3.18235C2.98947 18.4545 2.80448 18.3779 2.66809 18.2415C2.5317 18.1051 2.45508 17.9201 2.45508 17.7272V3.90906"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.638 3.90909V2.45455C12.638 2.06878 12.4847 1.69881 12.2119 1.42603C11.9392 1.15325 11.5692 1 11.1834 1H6.81978C6.43401 1 6.06404 1.15325 5.79126 1.42603C5.51848 1.69881 5.36523 2.06878 5.36523 2.45455V3.90909"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
