import { selectValueWithUrlFilter } from '~/helpers/tables/selectValueWithUrlFilter'
import { checkIsBlankDateChecked } from '~/helpers/tables/checkIsBlankDateChecked'

export const formatInitialFiltersWithUrl = ({ cell, queryFilters, origin, isDate = false, isBlankDate = false }) => {
  if (isDate) {
    const obj = {}
    if (isBlankDate) {
      obj.isBlankDate = checkIsBlankDateChecked({
        start: queryFilters.startDate,
        end: queryFilters.endDate,
      })
    }
    return {
      ...cell,
      ...obj,
      startDate:
        queryFilters.startDate && queryFilters.startDate !== 'CLEAR'
          ? new Date(`${queryFilters.startDate} 00:00:00`)
          : cell.startDate,
      endDate:
        queryFilters.endDate && queryFilters.endDate !== 'CLEAR'
          ? new Date(`${queryFilters.endDate} 00:00:00`)
          : cell.startDate,
    }
  }

  return {
    ...cell,
    selectValues: selectValueWithUrlFilter(origin, queryFilters),
  }
}
