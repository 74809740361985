import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

const animateFadeIn = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ color }) => color || theme.colors.pink900};
  padding: 15px 25px;
  /* min-height: 96px; */
  border-radius: 5px;
  margin: 50px 0;

  animation-name: ${animateFadeIn};
  animation-duration: 500ms;
`
