import React from 'react'

import { dashboardLegendsObj } from '../../../../../constants'

import { Container } from './styles'

function DescriptionLegendsReceipts() {
  const { receiptsChart } = dashboardLegendsObj

  return (
    <Container>
      <ul className="colors__legends">
        {receiptsChart.map((legend) => (
          <li key={legend.id}>
            <h4>
              <span style={{ backgroundColor: `${legend.color}` }} className="colors__legends--box"></span>
              <span className="colors__legends--title">{legend.label}</span>
            </h4>
            <p className="colors__legends--description">{legend.description}</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export { DescriptionLegendsReceipts }
