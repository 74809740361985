import React from 'react'
import { EachDetail, TableDropdown } from '../index'
import { formatValue } from '~/utils'
import { checkIsaValueReducer } from '~/screens/dashboard/helpers'
import { DashboardCollapse } from '~/screens/dashboard/components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { FinancialFlowCardGrid, Divider, Header, GridRowFlex } from '../../styles'

export const FinancialFlowCard = ({ receivablesData, chartLoadingState }) => {
  const { MaaS, bankSlip, courseFinancing, creditCard, ISAandISAaaS } = receivablesData || {}

  return (
    <FinancialFlowCardGrid>
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="110px" hasHeading hasParagraph>
        <Header isGridHeader>Crédito Educacional Antecipado</Header>
        <GridRowFlex>
          <EachDetail
            isFromBigCard
            title={'Alunos efetivados'}
            value={courseFinancing?.studentsEffective.toLocaleString('pt-BR')}
            isBoldValue
          />
          <EachDetail
            isFromBigCard
            title={'Valor das vendas'}
            value={courseFinancing?.sellingTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            isBoldValue
          />
          <EachDetail
            isFromBigCard
            isBluePrimary
            title={'Valor recebido'}
            value={courseFinancing?.totalValueReceived.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            isBoldValue
          />
          <EachDetail
            isFromBigCard
            title={'Valor a receber'}
            value={courseFinancing?.totalValueToReceive.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            isBoldValue
          />
        </GridRowFlex>
      </LoadingSkeleton>
      <Divider />
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="110px" hasHeading hasParagraph>
        <Header isGridHeader>Crédito Educacional Dinâmico (MaaS)</Header>
        <GridRowFlex>
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Alunos efetivados'}
            value={MaaS?.studentsEffective.toLocaleString('pt-BR')}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Valor das vendas'}
            value={MaaS?.sellingTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            isBluePrimary
            title={'Valor recebido'}
            value={MaaS?.totalValueReceived.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Valor a receber'}
            value={MaaS?.totalValueToReceive.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            isRed
            title={'Valor pendente'}
            value={MaaS?.totalValuePending.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
        </GridRowFlex>
      </LoadingSkeleton>
      <Divider />
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="110px" hasHeading hasParagraph>
        <Header isGridHeader>Cartão de crédito</Header>
        <GridRowFlex>
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Alunos efetivados'}
            value={creditCard?.studentsEffective.toLocaleString('pt-BR')}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Valor das vendas'}
            value={creditCard?.sellingTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
        </GridRowFlex>
      </LoadingSkeleton>
      <Divider />
      <LoadingSkeleton isLoading={chartLoadingState} containerHeight="110px" hasHeading hasParagraph>
        <Header isGridHeader>Boleto à vista</Header>
        <GridRowFlex>
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Alunos efetivados'}
            value={bankSlip?.studentsEffective.toLocaleString('pt-BR')}
          />
          <EachDetail
            isBoldValue
            isFromBigCard
            title={'Valor das vendas'}
            value={bankSlip?.sellingTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          />
        </GridRowFlex>
      </LoadingSkeleton>
      {ISAandISAaaS && checkIsaValueReducer(ISAandISAaaS) && (
        <>
          <Divider />
          <LoadingSkeleton isLoading={chartLoadingState} containerHeight="110px" hasHeading hasParagraph>
            <Header isGridHeader>ISA e ISAaaS</Header>
            <GridRowFlex>
              <EachDetail
                isBoldValue
                isFromBigCard
                title={'Alunos efetivados'}
                value={ISAandISAaaS?.studentsEffective.toLocaleString('pt-BR')}
              />
              <EachDetail
                isBoldValue
                isFromBigCard
                title={'Valor das vendas'}
                value={formatValue(ISAandISAaaS?.sellingTotal)}
              />
            </GridRowFlex>
          </LoadingSkeleton>
        </>
      )}

      <Divider isTransparent />
      <DashboardCollapse headerText="Entenda melhor os conceitos desta tabela" subComponent={<TableDropdown />} />
    </FinancialFlowCardGrid>
  )
}
