/* eslint-disable react/prop-types */
import React from 'react'

export const CheckoutCarIcon = ({ color = '#B0AFAF' }) => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M2 2H6L8.68 15.39C8.77144 15.8504 9.02191 16.264 9.38755 16.5583C9.75318 16.8526 10.2107 17.009 10.68 17H20.4C20.8693 17.009 21.3268 16.8526 21.6925 16.5583C22.0581 16.264 22.3086 15.8504 22.4 15.39L24 7H7M11 22C11 22.5523 10.5523 23 10 23C9.44772 23 9 22.5523 9 22C9 21.4477 9.44772 21 10 21C10.5523 21 11 21.4477 11 22ZM22 22C22 22.5523 21.5523 23 21 23C20.4477 23 20 22.5523 20 22C20 21.4477 20.4477 21 21 21C21.5523 21 22 21.4477 22 22Z"
      stroke={`${color}`}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
