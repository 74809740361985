export const ValidationMessages = {
  requiredField: 'Campo obrigatório',
  invalidServiceCode: 'Código de Serviço inválido',
  invalidCNPJ: 'CNPJ inválido',
  invalidMunicipalPoints: 'Inscrição municipal inválida',
  invalidEmail: 'Email inválido',
  serviceCode: 'Código de serviço inválido',
  invalidCNAE: 'CNAE inválido',
  serviceDescription: 'Descrição de serviço inválida',
  invalidFile: 'Formato inválido, são permitidos apenas .p12 ou .pfx',
  fullName: 'O nome deve ser composto e ter entre 7 e 90 letras',
  phone: 'Número de telefone inválido',
  issAliquotaInvalid: 'Número de aliquota inválido',
}
