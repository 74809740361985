export const dashboardLegendsObj = {
  receiptsChart: [
    {
      id: 1,
      color: '#9E9E9E',
      title: 'plannedValue',
      description:
        'É a soma dos valores que serão repassados pela venda de cursos, incluindo todos os meios de pagamento. O valor do repasse é líquido: as taxas da Provi já estão descontadas.',
      label: 'Valor previsto',
    },
    {
      id: 2,
      color: '#A4C7FB',
      title: 'receivedValueInDay',
      description:
        'É a soma dos repasses realizados referente à parcelas em atraso e pagas neste mês, incluindo juros e multas. O valor do repasse é líquido: as taxas da Provi já estão descontadas.',
      label: 'Valor recebido (em dia)',
      stackId: 'a',
    },
    {
      id: 3,
      color: '#2647D7',
      title: 'receivedValueOverdue',
      description:
        'É a soma dos repasses realizados referente à parcelas em atraso e pagas neste mês, incluindo juros e multas. O valor do repasse é líquido: as taxas da Provi já estão descontadas.',
      label: 'Valor recebido (em atraso)',
      stackId: 'a',
    },
    {
      id: 4,
      color: '#FF5776',
      title: 'pendingValue',
      description:
        'É a diferença entre os valores Previsto e Recebido, até a data do vencimento. Inclui a soma de repasses que não foram realizados por falta de pagamento durante o período. Este valor não inclui juros e multas que irão incidir sobre as parcelas não pagas.',
      label: 'Valor pendente',
    },
  ],
  defaultComparisonChart: [
    {
      key: 1,
      labelText: 'Alunos ativos',
      fillColor: '#A4C7FB',
      optionalText:
        'Alunos efetivados e que não finalizaram o pagamento do financiamento. Pode diminuir em caso de cancelamento.',
      isLine: false,
    },
    {
      key: 2,
      labelText: 'Alunos inadimplentes',
      fillColor: '#FF5776',
      optionalText: 'Alunos que não realizaram o pagamento de um boleto até a data de vencimento.',
      isLine: false,
    },
    {
      key: 3,
      labelText: 'Inadimplência (%)',
      fillColor: '#DE0C4B',
      optionalText: 'Resultado da divisão do número de alunos inadimplentes pelo número de alunos ativos.',
      isLine: true,
    },
  ],
  collectionsChart: [
    {
      key: 1,
      labelText: 'E-mails',
      fillColor: '#A4C7FB',
      optionalText: '',
      isLine: false,
    },
    {
      key: 2,
      labelText: 'SMS',
      fillColor: '#FF5776',
      optionalText: '',
      isLine: false,
    },
    {
      key: 3,
      labelText: 'WhatsApp',
      fillColor: '#2647D7',
      optionalText: '',
      isLine: false,
    },
    {
      key: 4,
      labelText: 'Ligações',
      fillColor: '#CFCFCF',
      optionalText: '',
      isLine: false,
    },
    {
      key: 5,
      labelText: 'Pagamentos recebidos com atraso',
      fillColor: '#DE0C4B',
      optionalText: '',
      isLine: true,
    },
  ],
  defaultRateChart: [
    {
      key: 1,
      labelText: 'Total de inadimplentes',
      fillColor: false,
      optionalText: 'Soma dos alunos em débito, negativados e protestados.',
      isLine: false,
    },
    {
      key: 2,
      labelText: 'Em débito',
      fillColor: false,
      optionalText: 'Alunos inadimplentes há menos de 45 dias, que foram contatados pela equipe de cobrança da Provi.',
      isLine: false,
    },
    {
      key: 3,
      labelText: 'Negativados',
      fillColor: false,
      optionalText:
        'Alunos inadimplentes há mais de 45 dias, cuja dívida foi inscrita no sistema de proteção ao crédito (Serasa).',
      isLine: false,
    },
    {
      key: 4,
      labelText: 'Protestados',
      fillColor: false,
      optionalText: 'Alunos inadimplentes há mais de 90 dias, cuja dívida foi oficializada junto ao cartório.',
      isLine: false,
    },
  ],
}
