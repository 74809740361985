import React from 'react'
import Select from 'react-select'

import { customSelectStyles } from './styles'

const RowOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

export const PageSize = ({ pageSize, setPageSize, isMobile }) => {
  return (
    <Select
      value={RowOptions ? RowOptions.find((option) => option.value === pageSize) : ''}
      options={RowOptions}
      onChange={(option) => setPageSize(option.value)}
      isSearchable={false}
      styles={customSelectStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      myFontSize={isMobile ? '12px' : '14px'}
    />
  )
}
