import React from 'react'
import { Checkmark } from 'assets/svgs/v2'
import { IntegrationCheckContainer, IntegrationCheckFlex, IntegrationCheckText } from './styles'

export const IntegrationCheck = ({ text }) => {
  return (
    <IntegrationCheckContainer>
      <IntegrationCheckFlex>
        <IntegrationCheckText>{text}</IntegrationCheckText>
        <Checkmark />
      </IntegrationCheckFlex>
    </IntegrationCheckContainer>
  )
}
