import { useContext, useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import * as Yup from 'yup'
import { useQuery, useMutation } from 'react-query'
import { queryClient } from '~/stores/react-query'
import { useFormik } from 'formik'
import { TemplateContext } from '~/components/Template/context'
import { unMask, getStorage } from '~/utils'
import { VALIDATIONERRORS } from '~/constants'
import { getProfileContacts, postProfileContacts } from '~/services/api'

export const useContactsTab = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const [isIsaEnabledState, setIsIsaEnabledState] = useState(undefined)

  const partnerName = getStorage({ key: 'partner-name' })

  const { data: contactData } = useQuery(`${partnerName}-contacts`, getProfileContacts, {
    staleTime: 300000,
    onError: () => {
      showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  const populateInputs = ({
    contactSupportEmail,
    contactSupportWhatsapp,
    contactCancellationEmail,
    contactStudentAccessEmail,
    contactFinancialEmail,
    contactPartnershipResponsibleEmail,
    contactISAStudentEmail,
  }) => {
    const adjustPhoneFE = (phoneFromBE) => {
      return '(' + phoneFromBE.substring(0, 2) + ')' + ' ' + phoneFromBE.substring(2, 7) + '-' + phoneFromBE.substring(7, 11)
    }

    setFieldValue('contactSupportEmail', contactSupportEmail)
    setFieldValue('contactSupportWhatsapp', contactSupportWhatsapp && adjustPhoneFE(contactSupportWhatsapp))
    setFieldValue('contactCancellationEmail', contactCancellationEmail)
    setFieldValue('contactStudentAccessEmail', contactStudentAccessEmail)
    setFieldValue('contactFinancialEmail', contactFinancialEmail)
    setFieldValue('contactPartnershipResponsibleEmail', contactPartnershipResponsibleEmail)
    setFieldValue('contactISAStudentEmail', contactISAStudentEmail)
  }

  const postMutation = useMutation(postProfileContacts, {
    onMutate: () => {
      setIsLoading(true)
    },
    onError: () => {
      showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
      setIsLoading(false)
    },
    onSuccess: (data) => {
      const { data: response } = data
      setIsLoading(false)
      ;(response?.message && (queryClient.invalidateQueries(`${partnerName}-contacts`), showToast(response?.message))) ??
        showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isValid, dirty, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      contactSupportEmail: '',
      contactSupportWhatsapp: '',
      contactCancellationEmail: '',
      contactStudentAccessEmail: '',
      contactFinancialEmail: '',
      contactPartnershipResponsibleEmail: '',
      contactISAStudentEmail: '',
    },
    validationSchema: Yup.object({
      contactSupportEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
      contactSupportWhatsapp: Yup.string()
        .nullable()
        .matches(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/g, VALIDATIONERRORS.phone),
      contactCancellationEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
      contactStudentAccessEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
      contactFinancialEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
      contactPartnershipResponsibleEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
      contactISAStudentEmail: Yup.string().nullable().email(VALIDATIONERRORS.email),
    }),
    onSubmit: () => {
      postMutation.mutateAsync({
        ...values,
        contactSupportWhatsapp: values.contactSupportWhatsapp && unMask(values.contactSupportWhatsapp),
      })
    },
  })

  useEffect(() => {
    if (contactData) {
      setIsIsaEnabledState(contactData?.data.isIsaEnabled)
      populateInputs(contactData?.data)
    }
  }, [contactData])

  return {
    isMobile,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    isIsaEnabledState,
    contactData,
  }
}
