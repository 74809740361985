import React from 'react'

export const ConnectIcon = ({ onClick, bluePrimary }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5098 11.1538L17.8951 7.76858C19.3209 6.3428 19.3209 4.03114 17.8951 2.60536V2.60536C16.4693 1.17957 14.1576 1.17957 12.7319 2.60536L9.34661 5.99061"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.49065 9.8464L2.1054 13.2317C0.679612 14.6574 0.679612 16.9691 2.1054 18.3949V18.3949C3.53118 19.8207 5.84284 19.8207 7.26863 18.3949L10.6539 15.0096"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="3.0791"
      y1="7.45453"
      x2="1.32869"
      y2="7.45453"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="6.83008"
      y1="3.57886"
      x2="6.83008"
      y2="1.82844"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="16.9194"
      y1="13.5447"
      x2="18.6698"
      y2="13.5447"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13.1689"
      y1="17.4203"
      x2="13.1689"
      y2="19.1708"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
