import React, { useContext } from 'react'
import { useFormik } from 'formik'
import { Button, showToast } from '@provi/provi-components'
import {
  FormWrapper,
  InputWrapper,
  MaskedInput,
  RegularInput,
  TextInputWrapper,
  FormTitle,
  ButtonWrapper,
  InputError,
} from '../styles'
import { InputSelector } from '~/components/InputSelector/styles'
import { OptionInput } from '~/components/Option'
import { initialValues, validations } from './form'
import { masks } from '~/enums'
import { useSchoolInfo } from './hooks'
import { TemplateContext } from '~/components/Template/context'

/** @type {React.FC} */
export const SchoolInfo = ({ status }) => {
  const { setIsLoading } = useContext(TemplateContext)
  const { values, setFieldValue, handleSubmit, errors, validateForm, touched, setFieldTouched } = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (formBody) => {
      await sendSchoolInfo(formBody)
    },
  })
  const { optionsTax, specialOptionsTax, sendSchoolInfo, expeditionDays } = useSchoolInfo(
    setFieldValue,
    showToast,
    setIsLoading,
    status,
  )

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>Preencha abaixo com os dados de cadastro da sua empresa:</FormTitle>
      <TextInputWrapper>
        <p>CNPJ</p>
        <MaskedInput
          inputProps={{
            disabled: true,
          }}
          disabled
          mask={masks.cnpj}
          value={values.cnpj}
          onChange={(e) => setFieldValue('cnpj', e.target.value)}
          placeholder="Digite Aqui"
        />
        {errors.cnpj && touched.cnpj && <InputError>{errors.cnpj}</InputError>}
      </TextInputWrapper>

      {values.address != '' && (
        <TextInputWrapper>
          <p>Endereço</p>
          <RegularInput
            inputProps={{
              disabled: true,
            }}
            disabled
            value={values.address}
            placeholder="Endereço"
          />
        </TextInputWrapper>
      )}

      <TextInputWrapper>
        <p>Razão Social</p>
        <RegularInput
          disabled
          value={values.razaoSocial}
          onChange={(e) => setFieldValue('razaoSocial', e.target.value)}
          placeholder="Digite Aqui"
        />
        {errors.razaoSocial && touched.razaoSocial && <InputError>{errors.razaoSocial}</InputError>}
      </TextInputWrapper>
      <TextInputWrapper>
        <p>Nome Fantasia</p>
        <RegularInput
          disabled
          value={values.nomeFantasia}
          onChange={(e) => setFieldValue('nomeFantasia', e.target.value)}
          placeholder="Digite Aqui"
        />
        {errors.nomeFantasia && touched.nomeFantasia && <InputError>{errors.nomeFantasia}</InputError>}
      </TextInputWrapper>
      <TextInputWrapper>
        <p>Email para recebimento das NFS</p>
        <RegularInput
          label="Email"
          value={values.nfseEmail}
          type="email"
          placeholder="Digite Aqui"
          id="emailNFS"
          onChange={(e) => setFieldValue('nfseEmail', e.target.value)}
          onBlur={() => {
            setFieldTouched('nfseEmail')
            validateForm()
          }}
        />
        {errors.nfseEmail && touched.nfseEmail && <InputError>{errors.nfseEmail}</InputError>}
      </TextInputWrapper>
      <TextInputWrapper>
        <p>Inscrição Municipal</p>
        <span>Inserir pontuação, caso necessário para o município</span>
        <RegularInput
          type="number"
          id="countySubscription"
          value={values.countySubscription}
          onChange={(e) => setFieldValue('countySubscription', e.target.value)}
          defaultValue={values.countySubscription}
          placeholder="Digite Aqui"
        />
        {errors.countySubscription && touched.countySubscription && <InputError>{errors.countySubscription}</InputError>}
      </TextInputWrapper>
      <TextInputWrapper>
        <p>Inscrição Estadual</p>
        <RegularInput
          type="number"
          minLength={8}
          id="stateSubscription"
          value={values.stateSubscription}
          maxLength={14}
          onChange={(e) => setFieldValue('stateSubscription', e.target.value)}
          defaultValue={values.stateSubscription}
          placeholder="Digite Aqui"
        />
        {errors.stateSubscription && touched.stateSubscription && <InputError>{errors.stateSubscription}</InputError>}
      </TextInputWrapper>
      <InputWrapper>
        <OptionInput
          value={values.simplesNacional}
          id="simplesNacional"
          onChange={(v) => setFieldValue('simplesNacional', v)}
          label="Optante do simples nacional?*"
        />
        {errors.simplesNacional && touched.simplesNacional && <InputError>{errors.simplesNacional}</InputError>}
      </InputWrapper>
      <InputWrapper>
        <p>Regime tributário</p>
        <InputSelector
          defaultValue={values.taxRegime}
          placeholder="Selecione um regime"
          options={optionsTax}
          value={values.taxRegime}
          id="taxRegime"
          onChange={(e) => setFieldValue('taxRegime', e)}
        />
        {errors.taxRegime && touched.taxRegime && <InputError>{errors.taxRegime}</InputError>}
      </InputWrapper>
      <InputWrapper>
        <p>Qtd. de dias para emissão após o pagamento </p>
        <InputSelector
          defaultValue={values.daysToExpedition}
          options={expeditionDays}
          value={values.daysToExpedition}
          id="daysToExpedition"
          placeholder="Selecione a quantidade de dias"
          onChange={(e) => setFieldValue('daysToExpedition', e)}
        />
        {errors.daysToExpedition && touched.daysToExpedition && <InputError>{errors.daysToExpedition}</InputError>}
      </InputWrapper>
      <InputWrapper>
        <p>Regime tributário especial</p>
        <InputSelector
          defaultValue={values.specialTaxRegime}
          options={specialOptionsTax}
          value={values.specialTaxRegime}
          id="specialTaxRegime"
          placeholder="Selecione um regime"
          onChange={(e) => setFieldValue('specialTaxRegime', e)}
        />
        {errors.specialTaxRegime && touched.specialTaxRegime && <InputError>{errors.specialTaxRegime}</InputError>}
      </InputWrapper>
      <InputWrapper>
        <OptionInput
          value={values.taxIncentive}
          id="taxIncentive"
          onChange={(v) => setFieldValue('taxIncentive', v)}
          label="Possui algum incentivo fiscal?"
        />
        {errors.taxIncentive && touched.taxIncentive && <InputError>{errors.taxIncentive}</InputError>}
      </InputWrapper>
      <InputWrapper>
        <OptionInput
          value={values.culturalPromoter}
          id="culturalPromoter"
          onChange={(v) => setFieldValue('culturalPromoter', v)}
          label="É incentivador cultural?"
        />
        {errors.culturalPromoter && touched.culturalPromoter && <InputError>{errors.culturalPromoter}</InputError>}
      </InputWrapper>
      <ButtonWrapper>
        <Button text="Salvar alterações" icon onClick={handleSubmit} />
      </ButtonWrapper>
    </FormWrapper>
  )
}
