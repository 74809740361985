export const isValidCpf = (value) => {
  if (!value) return false
  let sum = 0
  let rest
  const cpf = value.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return false
  if (cpf === '00000000000') return false
  if (cpf === '11111111111') return false
  if (cpf === '22222222222') return false
  if (cpf === '33333333333') return false
  if (cpf === '44444444444') return false
  if (cpf === '55555555555') return false
  if (cpf === '66666666666') return false
  if (cpf === '77777777777') return false
  if (cpf === '88888888888') return false
  if (cpf === '99999999999') return false
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false
  return true
}

export const isValidEmail = (email) => {
  if (!email) return false

  const value = email.trim()
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}

export const serviceCodeValidator = new RegExp(/^(?:\d{1,2}\.\d{1,2}|\d+)$/)

export const aliquotaValidator = new RegExp(/^(?:\d{1,2}\.\d{1,4}|\d+)$/)

/** @type {(value: string) => boolean} */
export const validatePhone = (value) => new RegExp(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/g).test(value)

function getFirstName(fullName) {
  return fullName ? fullName.split(' ')[0] : ''
}
function getLastName(fullName) {
  if (!fullName) return ''
  return fullName
    .trim()
    .split(' ')
    .filter((_name, index) => index > 0)
}
export function hasNumbers(string) {
  return string.split('').filter((item) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(parseInt(item))).length
}
function isEmptyString(string) {
  return string.trim() === ''
}
function isEmpty(string) {
  return string.trim().length === 0
}
function isEmptyArray(array) {
  return array.length === 0
}

/** @type {(fullName: string) => boolean} */
export const validateFullName = (fullName) => {
  let result = true
  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ\-' ]+$/
  if (!REGEX.test(fullName)) {
    result = false
    return result
  }
  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)
  if (isEmpty(firstName) || isEmptyString(firstName) || isEmptyArray(lastName)) {
    result = false
    return result
  }
  lastName.forEach((name) => (isEmpty(name) || isEmptyString(name) || hasNumbers(name) ? (result = false) : null))
  return result
}
