import * as Yup from 'yup'
import { ValidationMessages } from '~/enums'
import { serviceCodeValidator, aliquotaValidator } from '~/utils/validators'

export const initialValues = {
  itemListServiceCode: '',
  taxCode: '',
  cnae: '',
  issAliquota: '',
}

export const ValidationSchema = Yup.object({
  itemListServiceCode: Yup.string()
    .required(ValidationMessages.requiredField)
    .matches(serviceCodeValidator, ValidationMessages.invalidServiceCode),
  taxCode: Yup.string().required(ValidationMessages.requiredField),
  cnae: Yup.string().required(ValidationMessages.requiredField),
  issAliquota: Yup.string()
    .required(ValidationMessages.requiredField)
    .matches(aliquotaValidator, ValidationMessages.issAliquotaInvalid),
})
