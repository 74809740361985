import React from 'react'
import { theme, Breadcrumbs } from '@provi/provi-components'

import { ArrowLeft } from '~/assets/svgs/v2'

import { IntegrationsProvider, IntegrationsContext } from './context'
import { useIntegrations } from './hooks'
import { PageWrapper, Container, HeaderContainer, ComponentContainer, Divider } from './styles'

export const Integrations = () => {
  const { isMobile, drawerWidth } = useIntegrations()

  return (
    <IntegrationsProvider isMobile={isMobile}>
      <IntegrationsContext.Consumer>
        {({ flowState, setBreadcrumbs, whichComponentToRender, goBack }) => (
          <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
            <Container>
              <HeaderContainer>
                {!isMobile && <Breadcrumbs color={'black'} currentColor={theme.colors.bluePrimary} levels={setBreadcrumbs()} />}
                <Divider />
                {isMobile && <Breadcrumbs color={'black'} currentColor={theme.colors.bluePrimary} levels={setBreadcrumbs()} />}
                {!isMobile && flowState !== 'Integrações' && <ArrowLeft onClick={goBack()} />}
              </HeaderContainer>
              <ComponentContainer>{whichComponentToRender()}</ComponentContainer>
            </Container>
          </PageWrapper>
        )}
      </IntegrationsContext.Consumer>
    </IntegrationsProvider>
  )
}
