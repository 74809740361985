import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const DetailsCardGrid = styled.div`
  display: grid;
  padding: 2rem;
  max-width: 900px;
  background-color: #ffffff;
  gap: 1.5rem;
  border-radius: 5px;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #cfcfcf;
  grid-column: 1 / -1;
`

export const Header = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`
