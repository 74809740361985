import { useEffect, useState } from 'react'
import { formatValue, formatDate } from '~/utils'

export const usePaymentModal = (nextPaymentData) => {
  const [data, setData] = useState(null)

  const handleTranslateStatus = (status) => {
    switch (status) {
      case 'paid':
        return 'paga'
      case 'delayed':
        return 'atrasada'
      default:
        return 'aberta'
    }
  }

  useEffect(() => {
    const formatData = () => {
      const { data: responseData, id, name, CPF, proviComission, value, totalInCents } = nextPaymentData

      let installmentsDelayed = null
      let installmentsPaid = null
      let installments = null
      if (responseData && responseData.length) {
        installments = responseData.length
        installmentsDelayed = responseData.filter((d) => d.status === 'delayed').length
        installmentsPaid = responseData.filter((d) => d.status === 'paid').length

        responseData.map((d) => {
          d.translatedStatus = handleTranslateStatus(d.status)
          d.formatPrice = formatValue(d.price, true)
          d.paymentDate = formatDate(d.paymentDate)
          d.proviComissionByInstallments = formatValue(d.proviComissionByInstallments, true)
          d.tedValue = formatValue(d.tedValue, true)

          return d
        })
      }
      setData({
        id,
        name,
        CPF,
        proviComission: formatValue(proviComission, true),
        value,
        data: responseData,
        total: formatValue(totalInCents),

        installmentsDelayed,
        installmentsPaid,
        installments,
      })
    }
    formatData()
  }, [])

  return [data]
}
