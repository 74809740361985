import React from 'react'

import { ChartLabelContainer, ColorBox, FlexContainer, LabelText } from './styles'

export const ChartLabels = ({ labelText, fillColor, optionalText, isBold, isLine }) => {
  return (
    <ChartLabelContainer>
      <FlexContainer>
        {fillColor && <ColorBox fillColor={fillColor} isLine={isLine} />}
        <LabelText isBold={isBold}>{labelText}</LabelText>
      </FlexContainer>
      {optionalText && <LabelText>{optionalText}</LabelText>}
    </ChartLabelContainer>
  )
}
