import { useContext, useCallback, useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { getFiltersCheckout } from '~/services/api'
import { useMount } from '~/hooks'

export const useNonPaymentsDashboard = () => {
  const [productsFilter, setProductsFilter] = useState([])
  const [selectedProducts, setSelectedProducts] = useState(null)
  const [filters, setFilters] = useState(null)
  const { isMobile, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const [showTypeform, setShowTypeform] = useState(false)

  useMount(async () => {
    getProducts()
    onFilter()
  })

  const getProducts = useCallback(async () => {
    try {
      const filtered = await getFiltersCheckout()
      const courses = filtered?.data?.result?.courses || []
      const productsFiltered = courses
        .filter((item) => item.id)
        .map((item) => {
          return {
            label: `${item.name}-${item.id}`,
            value: item.id,
            active: true,
          }
        })

      setProductsFilter(productsFiltered)
    } catch (error) {
      showToast('Um erro inesperado aconteceu')
    }
  }, [setProductsFilter])

  const onFilter = (products) => {
    const newProducts = products || []
    const filtered = {
      productsSelected: newProducts ? newProducts.map((e) => e.value) : [],
    }
    setFilters(filtered)
  }

  useEffect(() => {
    const checkTypeform = localStorage.getItem('submittedNonPaymentsTypeform')
    checkTypeform === 'true' ? setShowTypeform(false) : setShowTypeform(true)
  }, [])

  return {
    isMobile,
    drawerWidth,
    goToPage,
    productsFilter,
    setProductsFilter,
    selectedProducts,
    setSelectedProducts,
    onFilter,
    filters,
    showTypeform,
  }
}
