import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Question } from '~/assets/svgs/question'

const fonts = {
  montserrat: {
    regular: 'Montserrat-Regular',
    bold: 'Montserrat-Bold',
    semiBold: 'Montserrat-SemiBold',
    medium: 'Montserrat-Medium',
  },
}

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ available }) => (available ? 'column' : 'row')};
  max-width: 296px;
  width: calc(100vw - 32px);
  min-height: 72px;
  background-color: ${theme.colors.white100};
  border-radius: 5px;
  border-left: 8px solid ${({ available }) => (available ? theme.colors.green100 : theme.colors.yellow900)};
  box-shadow: 0px 4px 15px rgba(9, 45, 99, 0.08);

  &:last-child {
    margin-left: 16px;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 328px;

    &:last-child {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`

export const TransactionTitle = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #414141;
  margin-top: 12px;
  margin-left: 20px;
`

export const TransactionPrice = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: ${theme.colors.blue900};
  margin-left: 20px;
  margin-bottom: 12px;

  ${theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    margin-right: 6px;
  }
`

export const QuestionIcon = styled(Question)``

export const InvisibleButton = styled.button`
  background-color: transparent;
  border: 0px;
  :hover {
    cursor: pointer;
  }
`

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  &:last-child {
    flex: 0.2;
    justify-content: center;
    align-items: center;
  }
`
