import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { Container, LeftColumn, RightColumn, ButtonWrapper } from '../SalesCard/styles'
import { Text } from '~/themes/styles'
import { Button } from '~/components/v2/Button'

export const SalesCard = ({ text, color, value, onClick }) => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <Container>
      <LeftColumn>
        <Text size={isMobile ? '12px' : '16px'} lineHeight="24px" fontFamily="Montserrat-SemiBold">
          {text}
        </Text>
        <Text color={color} size={isMobile ? '24px' : '28px'} lineHeight="36px" fontFamily="Montserrat-Bold">
          {value}
        </Text>
      </LeftColumn>
      <RightColumn>
        <ButtonWrapper>
          <Button icon variant="contained" secondary type="button" text="Ver mais" onClick={onClick} />
        </ButtonWrapper>
      </RightColumn>
    </Container>
  )
}
