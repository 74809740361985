import { isValid } from 'date-fns'
import { mixed, string, date, ref, object, number } from 'yup'

const currentDate = new Date()
const dateWithoutTime = new Date()
dateWithoutTime.setHours(0, 0, 0, 0)

const classroomValidationSchema = object().shape({
  classroomName: string()
    .required('Campo obrigatório.')
    .max(68, 'Máximo de 68 caracteres.')
    .min(8, 'Mínimo de 8 caracteres.')
    .nullable()
    .test('testNumbers', 'O nome não pode conter apenas números.', (value: any) => !/^\d+$/.test(value)),
  beginDate: mixed().when('classTypeId', {
    is: 1,
    then: date()
      .required('A data é requerida.')
      .min(dateWithoutTime, 'Data não pode ser no passado.')
      .nullable()
      .test('testDateFormatBeginDate', 'Data inválida', (value: any) => isValid(value)),
  }),
  endDate: mixed().when('classTypeId', {
    is: 1,
    then: date()
      .required('A data é requerida.')
      .min(ref('beginDate'), 'Data final deve ser maior ou igual à data inicial.')
      .nullable()
      .test('testDateFormatEndDate', 'Data inválida.', (value: any) => isValid(value)),
  }),
  expiresAt: date()
    .min(currentDate, 'Data não pode ser no passado.')
    .nullable()
    .notRequired()
    .test('testDateForExpiresAt', 'Data inválida.', (value: any) => {
      if (!value) return true
      return isValid(value)
    }),
  deadlineSolicitationNumber: mixed().when(['classTypeId', 'lifeTimeSpan'], {
    is: (classTypeId, lifeTimeSpan) => classTypeId === 2 && !lifeTimeSpan,
    then: number().required('O prazo é obrigatório.').min(1, 'O prazo não pode ser 0.').nullable(),
  }),
  deadlineSolicitation: number().min(7, 'Valor não pode ser menor do que 7 dias').nullable(),
  classTypeId: number()
    .oneOf([1, 2], 'Você precisa escolher um modelo de liberação de conteúdo')
    .required('Você precisa escolher um modelo de liberação de conteúdo'),
  modalityId: number()
    .oneOf([1, 2, 3], 'Você precisa escolher uma modalidade de ensino')
    .required('Você precisa escolher uma modalidade de ensino'),
  campusId: mixed().when('modalityId', {
    is: (modalityId) => modalityId === 2 || modalityId === 3,
    then: number().required('Escolha uma unidade.').nullable(),
  }),
  classPeriodId: mixed().when('modalityId', {
    is: (modalityId) => modalityId === 2 || modalityId === 3,
    then: number().required('Escolha um período.').nullable(),
  }),
  classTimeId: mixed().when('classPeriodId', {
    is: 1,
    then: number().required('Escolha um período.').nullable(),
  }),
  attendTypeId: mixed().when('modalityId', {
    is: (modalityId) => modalityId === 1 || modalityId === 2,
    then: number().required('Escolha um formato.').nullable(),
  }),
  priceInCents: number()
    .typeError('O preço da turma deve ser no mínimo R$30,00, e não pode ser maior que o preço do curso.')
    .min(3000, 'O preço da turma deve ser no mínimo R$30,00, e não pode ser maior que o preço do curso.'),
})

const classroomFlexValidationSchema = classroomValidationSchema.shape({
  priceInCents: number().min(18000, 'O preço da turma deve ser no mínimo R$180,00, e não pode ser maior que o preço do curso.'),
})

export { classroomValidationSchema, classroomFlexValidationSchema }
