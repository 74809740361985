import React from 'react'

import { EachDetail } from '..'

import { AutoRowGrid, Divider } from '../styles'

export const ISADetails = ({ cridDetails, formatValue, fallbackString }) => {
  return (
    <>
      <AutoRowGrid>
        <EachDetail title={'Empresa'} value={cridDetails?.company_name || fallbackString} />
        <EachDetail title={'Linkedin'} value={cridDetails?.linkedin_url || fallbackString} />
      </AutoRowGrid>
      <Divider />
      <AutoRowGrid>
        <EachDetail
          title={'Produto'}
          value={cridDetails?.products?.map((eachProduct, index) => (index ? ', ' : '') + eachProduct) || fallbackString}
        />
        <EachDetail title={'Valor da venda'} value={formatValue(cridDetails?.value) || fallbackString} />
        <EachDetail title={'Valores pagos aluno'} value={formatValue(cridDetails?.paidValues, true) || fallbackString} />
      </AutoRowGrid>
    </>
  )
}
