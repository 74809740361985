import React from 'react'
import { Tags } from '~/components/v2/TableV2/components'
import {
  formatValue,
  fallbackString,
  installmentCount,
  formatDetailsStatus,
  handleNegativeDelayedDays,
  handleMobileToggle,
} from './helpers'

import { StyledMobileSpan } from './styles'

export const detailsTableColumns = [
  {
    Header: 'Parcelas',
    accessor: 'count',
    Cell: ({ value, rows, row, defaultColumn }) => (
      <StyledMobileSpan onClick={() => handleMobileToggle(row, defaultColumn)}>
        {installmentCount(value, rows) || fallbackString}
      </StyledMobileSpan>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, row, defaultColumn }) => {
      const { status, statusColor } = formatDetailsStatus(value)
      return (
        <span onClick={() => handleMobileToggle(row, defaultColumn)}>
          <Tags value={status} color={statusColor} />
        </span>
      )
    },
  },
  {
    Header: 'Valor original',
    accessor: 'originalValue',
    Cell: ({ value, row, defaultColumn }) => (
      <StyledMobileSpan onClick={() => handleMobileToggle(row, defaultColumn)}>
        {(value && formatValue(value, true)) || fallbackString}
      </StyledMobileSpan>
    ),
  },
  {
    Header: 'Valor pago',
    accessor: 'paidValue',
    Cell: ({ value }) => (value && formatValue(value, true)) || fallbackString,
  },
  {
    Header: 'Comissão',
    accessor: 'proviComission',
    Cell: ({ value }) => (value && formatValue(value, true)) || fallbackString,
  },
  {
    Header: 'Valor recebido',
    accessor: 'receivedValue',
    Cell: ({ value }) => (value && formatValue(value, true)) || fallbackString,
  },
  { Header: 'Vencimento', accessor: 'dueDate', Cell: ({ value }) => value || fallbackString },
  { Header: 'Pagamento aluno', accessor: 'userPaidDate', Cell: ({ value }) => value || fallbackString },
  {
    Header: 'Atraso em dias',
    accessor: 'delayedDays',
    Cell: ({ value }) => handleNegativeDelayedDays(value) || fallbackString,
  },
  { Header: 'Data do acordo', accessor: 'agreementDate', Cell: ({ value }) => value || fallbackString },
]
