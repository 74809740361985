import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

import { usePaginationStyles, Container, ToolWrapper, PaginationText } from './styles'

export const Pagination = ({ page, nextPage, previousPage, pageIndex, pageSize, canNextPage, canPreviousPage, totalCount }) => {
  const classes = usePaginationStyles()

  const handlePageIndicator = (pageIndexParams, pageParams, pageSizeParams, totalCountParams) => {
    let indicator
    if (pageIndexParams === 0) {
      indicator = `1 - ${pageParams.length < pageSizeParams ? pageParams.length : pageSizeParams}`
    } else if (pageParams.length === pageSizeParams) {
      indicator = `${pageSizeParams * pageIndexParams + 1} - ${pageSizeParams * (pageIndexParams + 1)}`
    } else {
      indicator = `${pageSizeParams * pageIndexParams + 1} - ${
        pageSizeParams * (pageIndexParams + 1) - (pageSizeParams - pageParams.length)
      }`
    }
    return indicator + ` de ${totalCountParams}` || ''
  }

  return (
    <Container className={classes.root}>
      <ToolWrapper>
        {totalCount && <PaginationText>{handlePageIndicator(pageIndex, page, pageSize, totalCount)}</PaginationText>}
      </ToolWrapper>
      <ToolWrapper>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton onClick={() => nextPage()} disabled={!canNextPage} aria-label="next page">
          <KeyboardArrowRight />
        </IconButton>
      </ToolWrapper>
    </Container>
  )
}
