import React from 'react'

export const ClosedEye = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.6858 9.68124L18.7704 13.2918" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.3935 11.688L13.0436 15.375" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.59991 11.6862L6.94968 15.3738" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.31181 9.67828L1.21719 13.3063" stroke={color || 'black'} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.22729 7.6314C2.76378 9.53326 5.58055 11.9381 10.0007 11.9381C14.4209 11.9381 17.2377 9.53328 18.7742 7.63143"
      stroke={color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
