export const translateFilters = (filter) => {
  const filters = {
    startDate: 'createDate',
    endDate: 'createDate',
    startEffectiveDate: 'madeEffectiveDate',
    endEffectiveDate: 'madeEffectiveDate',
    startExpectedPaymentDate: 'datePrevToted',
    endExpectedPaymentDate: 'datePrevToted',
    startTedDate: 'tedDate',
    endTedDate: 'tedDate',
    startReleasedDate: 'released',
    endReleasedDate: 'released',
    startNextPaymentDate: 'nextPayment',
    endNextPaymentDate: 'nextPayment',
    startAgreementDate: 'agreementDate',
    endAgreementDate: 'agreementDate',
    startExpectedCompensationDate: 'expectedCompensationDate',
    endExpectedCompensationDate: 'expectedCompensationDate',
    startCompensationDate: 'compensationDate',
    endCompensationDate: 'compensationDate',
  }

  return filters[filter] || filter
}

export const transpileFiltersToStart = (filter) => {
  const filters = {
    createDate: 'startDate',
    madeEffectiveDate: 'startEffectiveDate',
    datePrevToted: 'startExpectedPaymentDate',
    tedDate: 'startTedDate',
    released: 'startReleasedDate',
    nextPayment: 'startNextPaymentDate',
    agreementDate: 'startAgreementDate',
    expectedCompensationDate: 'startExpectedCompensationDate',
    compensationDate: 'startCompensationDate',
  }

  return filters[filter] || filter
}
