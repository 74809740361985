import React from 'react'
import { TemplateContext } from '~/components/Template/context'
import { CoursesContext } from '~/screens/courses/context'
import { getClassroom, editClassroomVisibility, deleteClassroom } from '~/screens/courses/requests'
import { toast } from 'react-toastify'
import { format, parseISO } from 'date-fns'
import masks from '~/themes/masks'

export const useClassroomDetails = ({ classroomId, courseId }) => {
  const { isMobile, goToPage, setIsLoading } = React.useContext(TemplateContext)
  const { setCourseClassCopy, showModalAfterPost, setShowModalAfterPost } = React.useContext(CoursesContext)
  const [classroomDetails, setClassroomDetails] = React.useState(null)
  const editVisibilityModalRef = React.useRef()
  const deleteModalRef = React.useRef()
  const shareLinkModalRef = React.useRef()
  const memberkitReminderModalRef = React.useRef()

  const getData = async (classroomIdParam) => {
    try {
      setIsLoading(true)
      const response = await getClassroom(classroomIdParam)

      if (response.status > 199 && response.status < 300) {
        setClassroomDetails(response.data)
      }
    } catch (error) {
      if (error.response.status > 399 && error.response.status < 500) {
        toast(error.response.data.error.message || error.response.message)
      } else {
        toast('Algo de errado aconteceu buscando suas informações 😔 Por favor, tente novamente.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const openModals = (string) => {
    switch (string) {
      case 'editVisibility':
        editVisibilityModalRef.current.openModalFn()
        break
      case 'delete':
        deleteModalRef.current.openModalFn()
        break
      case 'share':
        shareLinkModalRef.current.openModalFn()
        break
      case 'memberkitReminder':
        memberkitReminderModalRef.current.openModalFn()
        break

      default:
        break
    }
  }

  const closeModals = (string) => {
    switch (string) {
      case 'editVisibility':
        editVisibilityModalRef.current.closeModalFn()
        break
      case 'delete':
        deleteModalRef.current.closeModalFn()
        break
      case 'share':
        shareLinkModalRef.current.closeModalFn()
        break
      case 'memberkitReminder':
        memberkitReminderModalRef.current.closeModalFn()
        break

      default:
        break
    }
  }

  const toggleVisibility = async (boolean) => {
    try {
      closeModals('editVisibility')
      setIsLoading(true)
      const response = await editClassroomVisibility(classroomId, { visible: !boolean })
      if (response.status > 199 && response.status < 300) {
        await getData(classroomId)
        toast('Alteração feita com sucesso! 🥳')
      }
    } catch (error) {
      if (error.response.status > 399 && error.response.status < 500) {
        toast(error.response.data.error.message || error.response.message)
      } else {
        toast('Algo de errado aconteceu modificando suas informações 😔 Por favor, tente novamente.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSingleClassroom = async () => {
    try {
      closeModals('delete')
      setIsLoading(true)
      const response = await deleteClassroom(classroomId)
      if (response.status > 199 && response.status < 300) {
        toast('Turma deletada com sucesso! 🥳')
        setTimeout(() => {
          goToPage(`cursos/${courseId}`)
        }, 1500)
      }
    } catch (error) {
      if (error.response.status > 399 && error.response.status < 500) {
        toast(error.response.data.error.message || error.response.message)
      } else {
        toast('Algo de errado aconteceu deletando suas informações 😔 Por favor, tente novamente.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const extraBreadCrumbs = React.useMemo(() => {
    if (!classroomDetails) return []
    if (isMobile) return []
    return [
      <span
        key={classroomDetails.content.Course.name}
        onClick={() => goToPage(`cursos/${classroomDetails.content.Course.id}`)}
        style={{ cursor: 'pointer' }}
      >
        {classroomDetails.content.Course.name}
      </span>,
      <span key={classroomDetails.content.name}>{classroomDetails.content.name}</span>,
    ]
  }, [classroomDetails, isMobile])

  const makeContextCopy = () => {
    const { content } = classroomDetails || {}

    const copyObj = {
      ...(content?.name && { classroomName: content?.name + ' - 2' }),
      ...(content?.startsAt && { beginDate: content?.startsAt }),
      ...(content?.endsAt && { endDate: content?.endsAt }),
      ...(content?.durationTime && { deadlineSolicitationNumber: content?.durationTime }),
      ...(content?.CourseClassDuration?.id && { deadlineSolicitationSelect: content?.CourseClassDuration?.id }),
      ...(content?.gracePeriodDays && { deadlineSolicitation: content?.gracePeriodDays }),
      ...(content?.CourseClassDuration?.id === 5 && { lifeTimeSpan: true }),
      ...(content?.partnerSKU && { internCode: content?.partnerSKU }),
      ...(content?.Campus?.id && { campusId: content?.Campus?.id }),
      ...(content?.priceInCents && { priceInCents: content?.priceInCents }),
      ...(content?.priceInCents && { priceInCentsFormatted: masks.currency(content?.priceInCents) }),
      ...(content?.expiresAt && { expiresAt: format(parseISO(content?.expiresAt), "yyyy-MM-dd'T'HH:mm", new Date()) }),
      ...(content?.CourseClassType?.id && { classTypeId: content?.CourseClassType?.id }),
      ...(content?.CourseClassPeriod?.id && { classPeriodId: content?.CourseClassPeriod?.id }),
      ...(content?.CourseClassTime?.id && { classTimeId: content?.CourseClassTime?.id }),
      ...(content?.CourseClassAttendType?.id && { attendTypeId: content?.CourseClassAttendType?.id }),
      ...(content?.CourseClassModality?.id && { modalityId: content?.CourseClassModality?.id }),
    }

    setCourseClassCopy(copyObj)

    goToPage(`cursos/${courseId}/nova-turma`)
  }

  React.useEffect(() => {
    getData(classroomId)
  }, [])

  React.useEffect(() => {
    if (classroomDetails && showModalAfterPost) {
      openModals('memberkitReminder')
      setShowModalAfterPost(false)
    }
  }, [classroomDetails, showModalAfterPost])

  return {
    isMobile,
    goToPage,
    classroomDetails,
    extraBreadCrumbs,
    openModals,
    closeModals,
    editVisibilityModalRef,
    toggleVisibility,
    deleteModalRef,
    deleteSingleClassroom,
    shareLinkModalRef,
    makeContextCopy,
    memberkitReminderModalRef,
  }
}
