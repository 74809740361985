import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { getActiveUser, handleStorage } from '~/utils'
import { getCourses, listCourseClassesV4 } from '~/services/api'

export const useProduct = (setIsLoading, setSelectedOptionsCampaign, setValueCampaign, goToPage) => {
  const [list, setList] = useState([{ isValid: false }])
  const [courseOptions, setCourseOptions] = useState([])
  const [isValidSubmit, setIsValidSubmit] = useState(false)
  const getInitialData = async () => {
    try {
      setIsLoading(true)

      const courseData = await getCourses()

      const courses = courseData.data

      if (courses && courses.length) {
        const formattedCourseOptions = courses.map((course) => {
          return { ...course, label: course.name, key: course.id, disabled: false }
        })

        setCourseOptions(formattedCourseOptions)
      } else {
        showToast('Nenhum curso encontrado')
      }
    } catch (error) {
      showToast('Ops, ocorreu algum erro ao listar os cursos 😔')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    setIsValidSubmit(!list.some((option) => option.isValid === false))
  }, [list])

  const handleOption = async (item, index, type) => {
    setIsLoading(true)
    try {
      let courseClassesValues = []
      if (type === 'course') {
        const { id } = item

        const {
          data: { content },
        } = await listCourseClassesV4({ CourseId: id })

        courseClassesValues = content.map((course) => {
          return { ...course, label: course.name, key: course.id }
        })
      }

      setList((prev) =>
        prev.map((option, idx) => {
          if (index === idx) {
            return {
              ...option,
              [type]: item,
              isValid: !!(type === 'courseClass' && item),
              courseClassOptions: (type === 'course' && courseClassesValues) || option.courseClassOptions || [],
            }
          }

          return option
        }),
      )
    } catch (error) {
      showToast('Ocorreu um erro ao selecionar um produto, tente novamente')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    const items = list.map((value) => {
      return {
        ...value.course,
        courseClassId: value.courseClass.id,
        courseClass: value.courseClass,
      }
    })

    const sumValue = list.map((value) => value.courseClass.priceInCents).reduce((acc, cur) => acc + cur)

    setSelectedOptionsCampaign(items)
    setValueCampaign(sumValue)

    const activeUser = getActiveUser()
    await handleStorage({ key: 'selectedOptionsCampaign', user: activeUser, value: items })
    await handleStorage({ key: 'selectedOptionsValueCampaign', user: activeUser, value: sumValue })

    goToPage('campanha/criar-campanha')
  }

  const handleRemoveItem = (index) => {
    setList(list.filter((option, idx) => idx !== index))
  }

  return {
    handleOption,
    handleSubmit,
    courseOptions,
    list,
    setList,
    isValidSubmit,
    handleRemoveItem,
  }
}
