import React, { useContext } from 'react'
import { theme, TextLink } from '@provi/provi-components'

import { handlePlaceholder, clearAndReload } from '~/utils'
import { pagination_options } from '~/constants/pagination_options'
import { search_options } from '~/constants/search_options'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { Header } from '~/components/table/header-table'
import { SimpleTable as Table } from '~/components/table'

import { ProductModal } from '~/components/modals/product-modal'
import { CheckCsvModal } from '~/components/modals/check-csv-modal'
import { FilterModal } from '~/components/modals/filter-modal'
import { NextPayment } from '~/components/modals/next-payment-modal'
import { HelperModal } from '~/components/modals/helper-modal'
import { InstallmentsModal } from '~/components/modals/installment-modal'
import { InstallmentsValueModal } from '~/components/modals/installment-value-modal'
import { LoanOptionCommentModal as ComponentLoanOptionCommentModal } from '~/components/modals/loan-option-comment-modal'

import { Text } from '~/themes/styles'

import { Container, Content, ContainerTable } from './styles'
import { useFinancialControl } from './hooks'

/** @type {React.FC} */
export const FinantialControlV2 = () => {
  const { setIsLoading, isMobile, goToPage } = useContext(TemplateContext)
  const { handlers, states, setStates } = useFinancialControl(setIsLoading, isMobile, goToPage)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const { handleModal, handleActiveBox, handleSearch, handleSearchKey } = handlers
  const {
    activeBox,
    search,
    pageIndicator,
    itemsPerPage,
    page,
    totalItens,
    totalPages,
    rows,
    showCells,
    ProductData,
    productsModal,
    exportsModal,
    exportsModalData,
    selectedByUserCourses,
    warningMessage,
    preferences,
    filterModal,
    filterStatus,
    nextPaymentData,
    nextPaymentModal,
    helperModal,
    helperModalData,
    installmentsModal,
    installmentsModalData,
    installmentsValueModal,
    installmentsValueModalData,
    loanOptionCommentData,
    loanOptionCommentModal,
    searchOptions,
  } = states
  const {
    setShowCells,
    setPage,
    setItemsPerPage,
    setPageIndicator,
    setRows,
    setProductData,
    setFilterStatus,
    setSearchOptions,
  } = setStates

  return (
    <Container>
      {(preferences || filterModal) && (
        <FilterModal
          refer="receipts"
          show={filterModal}
          handleModal={handleModal}
          showCells={showCells}
          setShowCells={setShowCells}
          preferences={preferences}
        />
      )}
      <CheckCsvModal
        show={exportsModal}
        csvData={exportsModalData}
        filterStatus={filterStatus}
        handleModal={handleModal}
        handleV2={true}
        selectedByUserCourses={selectedByUserCourses}
      />
      {nextPaymentModal && <NextPayment show={nextPaymentModal} nextPaymentData={nextPaymentData} handleModal={handleModal} />}
      {helperModal && <HelperModal show={helperModal} helperModalData={helperModalData} handleModal={handleModal} />}
      <ProductModal show={productsModal} ProductData={ProductData} handleModal={handleModal} />
      <InstallmentsModal show={installmentsModal} installmentsModalData={installmentsModalData} handleModal={handleModal} />
      {installmentsValueModal && (
        <InstallmentsValueModal
          show={installmentsValueModal}
          installmentsValueModalData={installmentsValueModalData}
          handleModal={handleModal}
        />
      )}
      {loanOptionCommentModal && (
        <ComponentLoanOptionCommentModal
          show={loanOptionCommentModal}
          loanCommentsData={loanOptionCommentData}
          handleModal={handleModal}
          setIsLoading={setIsLoading}
        />
      )}

      <Header
        handleModal={handleModal}
        activeBox={activeBox}
        handleActiveBox={handleActiveBox}
        handleSearch={handleSearch}
        handleSearchKey={handleSearchKey}
        search={search}
        handlePlaceholder={handlePlaceholder}
        pageIndicator={pageIndicator}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItens={totalItens}
        totalPages={totalPages}
        pagination_options={pagination_options}
        search_options={search_options}
        setSearchOptions={setSearchOptions}
        searchOptions={searchOptions}
        setPageIndicator={setPageIndicator}
        location="receipts"
      />
      <Content drawerWidth={drawerWidth}>
        <ContainerTable isMobile={isMobile} drawerWidth={drawerWidth}>
          {warningMessage ? (
            <>
              <Text bold align="center" color={theme.colors.blue900} mTop="30px" size="24px">
                {warningMessage}
              </Text>
              <Text align="center" color={theme.colors.blue900} mTop="8px" size="14px" bold>
                <TextLink text="Limpar filtros e atualizar página" onClick={clearAndReload} />
              </Text>
            </>
          ) : (
            <Table
              setPage={setPage}
              handleModal={handleModal}
              search={search}
              setRows={setRows}
              rows={rows}
              showCells={showCells}
              setShowCells={setShowCells}
              setProductData={setProductData}
              setFilterStatus={setFilterStatus}
            />
          )}
        </ContainerTable>
      </Content>
    </Container>
  )
}
