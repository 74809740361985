import React from 'react'

export const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M15 9L9 15" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 15L9 9" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
