import { getActiveUser, parseJSON } from '~/utils'

/**
 * @description get values from local storage dynamically
 * @function
 * @name getStorage
 * @param {Object} data
 * @param {Number} data.user user id reference
 * @param {String} data.key key in local storage reference
 * @param {Boolean} data.last take the last value of key
 * @returns {(null|String)}
 */
export const getStorage = ({ user, key, last = false }) => {
  let activeUser = null

  if (!user) {
    activeUser = getActiveUser()
  }

  if (!activeUser && !last) {
    return null
  }

  const keys = localStorage.getItem(key)
  const parsedKeys = parseJSON(keys)

  if (!parsedKeys) {
    return null
  }

  if (last) {
    const activeKeys = Object.keys(parsedKeys)
    const lastKey = activeKeys[activeKeys.length - 1]
    return parsedKeys[lastKey]
  }

  if (keys) {
    const value = parsedKeys[activeUser]
    return value
  }

  return null
}
