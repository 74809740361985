import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FlexWrapContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

export const PillContainer = styled.div`
  width: fit-content;
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 0.4rem 1rem;
  border: 1px solid ${theme.colors.bluePrimaryLight};

  &:hover {
    border: 1px solid ${theme.colors.bluePrimary};
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const BlueText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`
