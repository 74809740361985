import ReactGA from 'react-ga'
import { trackingId, LOCALSTORAGE_TOKENS, googleAnalyticsDimensions } from '../constants'
import ttiPolyfill from 'tti-polyfill'
import { findApproximateElementText, removeStorage } from '../utils'

export const setGaUserInfoAndPartner = (userId, partnerId, accessLevel, internalUser) => {
  ReactGA.set({
    [googleAnalyticsDimensions.userId]: userId,
    [googleAnalyticsDimensions.partnerId]: partnerId,
    [googleAnalyticsDimensions.accessLevel]: accessLevel,
    [googleAnalyticsDimensions.internalUser]: internalUser,
  })
}

/**
 * @description Initialize the GA, set debug to true in order to keep track
 * of what information is being send to analytics
 * @return {void}
 */
export const initGA = () => {
  ReactGA.initialize(trackingId, { debug: false })

  const userId = localStorage.getItem(LOCALSTORAGE_TOKENS.userId)
  const partnerId = localStorage.getItem(LOCALSTORAGE_TOKENS.partnerId)
  const accessLevel = localStorage.getItem(LOCALSTORAGE_TOKENS.accessLevel)

  setGaUserInfoAndPartner(userId, partnerId, accessLevel)

  document.addEventListener(
    'click',
    (event) => {
      const clickedText = findApproximateElementText(event.target)
      trackEvent({ category: 'Click', action: clickedText })
    },
    true,
  )

  firstInteraction()

  try {
    const observer = new PerformanceObserver(Performance)
    observer.observe({ entryTypes: ['navigation'] })
  } catch (error) {
    console.error(error)
  }
}

/**
 * @description Track page views, and set dimensions for the user and for the session
 * @return {void}
 */
export const trackVirtualPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search)
}

/**
 * @description Dispatch events for the google analytics, can be used
 * to trigger specific conversion goals
 * @typedef {Object} data
 * @property {string} data.category
 * @property {string} data.action
 * @property {string} data.label
 * @return {void}
 */
export const trackEvent = ({ category, action, label }) => {
  ReactGA.event({
    category,
    action,
    label,
  })
}

/** @type {() => void} - Track app performance */

export const Performance = (list) => {
  list.getEntries().forEach((entry) => {
    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Server Latency',
      value: entry.responseStart - entry.requestStart,
    })

    ReactGA.timing({
      category: 'Load Performace',
      variable: 'App load time',
      value: entry.responseEnd - entry.requestStart,
    })

    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Total app load time',
      value: entry.responseEnd - entry.responseStart,
    })
  })
}

/**
 * @description Track the time it takes for for the site to be interactive
 * @return {void}
 */
const firstInteraction = () => {
  ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
    ReactGA.timing({
      category: 'Load Performace',
      variable: 'Download time',
      value: tti,
    })
  })
}

/**
 * @description Use to track different exceptions
 * @typedef {Object} data
 * @property {string} data.error
 * @property {boolean} data.fatal
 * @property {void}
 */
export const trackExceptions = ({ error, fatal }) => {
  ReactGA.exception({
    description: error,
    fatal,
  })
}

export const resetGA = async () => {
  await removeStorage()

  ReactGA.set({
    [googleAnalyticsDimensions.userId]: null,
    [googleAnalyticsDimensions.partnerId]: null,
    [googleAnalyticsDimensions.accessLevel]: null,
    [googleAnalyticsDimensions.internalUser]: null,
  })
}
