export const fonts = {
  century: {
    regular: 'CenturyGothic',
    bold: 'CenturyGothicBold',
    italic: 'CenturyGothicItalic',
    italicBold: 'CenturyGothicBoldItalic',
    light: 'CenturyGothicLight',
  },
  montserrat: {
    regular: 'MontserratRegular',
    medium: 'MontserratMedium',
    bold: 'MontserratBold',
  },
}
