import React from 'react'
import { theme, ButtonV3 } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { ContainerGrid, TextFlexColumn } from './styles'
import { capitalizeFirstLetter } from '~/utils'

const IntegrationTextComponent = ({ integrationStr }) => {
  return (
    <>
      {integrationStr ? (
        <TextFlexColumn>
          <BaseTypography fontWeight={700} fontSize={'13px'} lineHeight={'20px'}>
            Plataforma
          </BaseTypography>
          <BaseTypography>{capitalizeFirstLetter(integrationStr)}</BaseTypography>
        </TextFlexColumn>
      ) : (
        <BaseTypography>Esta turma não possui integração.</BaseTypography>
      )}
    </>
  )
}

export const IntegrationsBox = ({ integrationStr, goToPage }) => {
  return (
    <ContainerGrid>
      <BaseTypography textTransform={'uppercase'} color={theme.colors.bluePrimary} fontWeight={700}>
        Integrações
      </BaseTypography>
      <TextFlexColumn gap={'1rem'}>
        <BaseTypography color={theme.colors.bluePrimary} fontWeight={600}>
          Hospedagem de curso
        </BaseTypography>
        <IntegrationTextComponent integrationStr={integrationStr} />
      </TextFlexColumn>
      <ButtonV3
        mobileWidth={'100%'}
        width={'100%'}
        text={integrationStr ? 'Gerenciar integração' : 'Integrar turma'}
        onClick={() => goToPage('integracoes')}
      />
    </ContainerGrid>
  )
}
