import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.div`
  border-bottom: 1px solid ${theme.colors.grey600};
  margin-bottom: 19px;
`

export const Title = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 20px;
  line-height: 30px;
  color: ${({ first }) => (first ? theme.colors.blue900 : theme.colors.grey600)};
`

export const Employee = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.pink900};
  margin-bottom: 19px;
`

export const Comment = styled.div`
  /* margin-top: 30px; */
  font-size: 16px;
  line-height: 24px;
  color: #b0afaf;
  margin-bottom: 23px;
`

export const WrapperComment = styled.div`
  display: flex;

  svg {
    margin-right: 10px;
  }
`
