/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { Gear } from '~/assets/svgs/Gear'
import { Home } from '~/assets/svgs/Home'
import { ArrowLeft } from '~/assets/svgs/arrow-left'
import { ArrowRight } from '~/assets/svgs/arrow-right'
import { Selector } from '~/components/Select'
import { ArrowDownWithBorder } from '~/assets/svgs/arrow-down'

import {
  ContainerHeader,
  ListButton,
  Search,
  WrapperButtons,
  WrapperHeader,
  WrapperSearch,
  BoxButton,
  ControlBox,
  ControlBoxText,
  WrapperArrow,
  Top,
  WrapperBoxButtons,
} from './styles'
import { useHeader } from './hooks'

export const Header = (props) => {
  const {
    handleModal,
    activeBox,
    handleActiveBox,
    handleSearch,
    handleSearchKey,
    search,
    searchOptions,
    handlePlaceholder,
    pageIndicator,
    // handleItensPerPage,
    itemsPerPage,
    // nextPage,
    // prevPage,
    page,
    totalItens,
    totalPages,
    pagination_options,
    search_options,
    setPage,
    setItemsPerPage,
    setSearchOptions,
    setPageIndicator,
    location,
    isDownloadable = true,
  } = props
  const { goToPage, isLowDesktop, isMobile } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [handleItensPerPage, handleSearchOptions, nextPage, prevPage] = useHeader({
    setPage,
    page,
    setItemsPerPage,
    totalPages,
    setSearchOptions,
    handleActiveBox,
  })

  return (
    <ContainerHeader isMobile={isMobile} drawerWidth={drawerWidth}>
      <WrapperHeader>
        <WrapperButtons>
          <ListButton single onClick={() => goToPage('home')}>
            <Home />
          </ListButton>
          <ListButton single={isLowDesktop} onClick={() => handleModal('filters')}>
            <Gear />
            {!isLowDesktop && <>Configure sua planilha</>}
          </ListButton>
          {isMobile && (
            <Selector
              width="90px"
              value={itemsPerPage}
              onChange={handleItensPerPage}
              id="options"
              options={pagination_options}
              placeholder=""
            />
          )}
        </WrapperButtons>

        <Top>
          <Selector
            width="120px"
            value={searchOptions}
            onChange={handleSearchOptions}
            id="searchOption"
            options={search_options}
            placeholder=""
          />
          <WrapperSearch>
            <Search
              disabled={!activeBox}
              type="text"
              placeholder={`${handlePlaceholder(activeBox)}`}
              onChange={handleSearch}
              onKeyUp={handleSearchKey}
              value={search}
            />
          </WrapperSearch>
        </Top>

        <ControlBox drawerWidth={drawerWidth}>
          <ControlBoxText>
            {pageIndicator && (
              <>
                {pageIndicator} {totalItens ? `de ${totalItens}` : ''}
              </>
            )}
          </ControlBoxText>
          <WrapperArrow active={page !== 1} onClick={prevPage}>
            <ArrowLeft active={page !== 1} />
          </WrapperArrow>
          <WrapperArrow active={page < totalPages} onClick={nextPage}>
            <ArrowRight active={page < totalPages} />
          </WrapperArrow>
          {!isMobile && (
            <Selector
              width="90px"
              value={itemsPerPage}
              onChange={handleItensPerPage}
              id="options"
              options={pagination_options}
              placeholder=""
            />
          )}
          {isDownloadable && (
            <ListButton
              mLeft="5px"
              width={isMobile || isLowDesktop ? 'unset' : '180px'}
              onClick={() => handleModal('csv', location)}
            >
              <ArrowDownWithBorder />
              {!isMobile && !isLowDesktop && <>Baixar planilha</>}
            </ListButton>
          )}
        </ControlBox>
      </WrapperHeader>
    </ContainerHeader>
  )
}
