const { REACT_APP_API_ENV } = process.env
import { handleStorage, asyncLocalStorage } from '~/utils'
import { getAccess } from '~/services/api'
import { handleToken } from '~/utils/handleLocalStorage/handleToken'
import { URL_ENV_PAINEL, URL_ENV_CHECKOUT } from '../helpers/matchUrl'

export default async () => {
  try {
    const url = window.location.href

    const isUrlPainel = url.includes(URL_ENV_PAINEL[REACT_APP_API_ENV])
    const isUrlCheckout = url.includes(URL_ENV_CHECKOUT[REACT_APP_API_ENV])

    if (isUrlCheckout) {
      window.location.replace(`${URL_ENV_PAINEL[REACT_APP_API_ENV]}`)
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('token')
    if (isUrlPainel && token) {
      localStorage.clear()
      const objectTokens = {
        1: token,
      }
      // set token
      await asyncLocalStorage.setItem('tokens', JSON.stringify(objectTokens))

      const response = await getAccess()
      const { email, partnerName, partnerSlug, partnerId, userId } = response.data.data

      const user = await handleToken({ token, email })

      await handleStorage({ user, key: 'email', value: email })
      await handleStorage({ user, key: 'partner-name', value: partnerName })
      await handleStorage({ user, key: 'partner-slug', value: partnerSlug })
      await handleStorage({ user, key: 'partner-id', value: partnerId })
      await handleStorage({ user, key: 'user-id', value: userId })

      window.location.replace(`${URL_ENV_PAINEL[REACT_APP_API_ENV]}/home?u=1`)
    }
  } catch (error) {
    return error.response
  }
}
