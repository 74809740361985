import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { AddTimeLine, ClassroomDetails } from '~/screens/courses/components/DetailsComponents/components'

export const NestedSwitch = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <ClassroomDetails />
      </Route>
      <Route path={`${path}/nova-estrategia`}>
        <AddTimeLine />
      </Route>
    </Switch>
  )
}
