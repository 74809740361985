import React from 'react'
import masks from '~/themes/masks'

export const useStepsSub = ({
  formValues,
  setFormValues,
  discountPercentage,
  priceWithDiscount,
  index,
  mappedArr,
  minimumEndAtDate,
  minimumCanCheckoutUntilDate,
}) => {
  const numberFromBE = 500000
  const [maskedValue, setMaskedValue] = React.useState(masks.currency(numberFromBE) || masks.currency(0))
  const [percentage, setPercentage] = React.useState('')

  const isFirstElement = React.useMemo(() => index === 0, [index])
  const isLastElement = React.useMemo(() => index === mappedArr.length - 1, [index, mappedArr])

  const handleDateInputChange = React.useCallback(
    (e) => {
      const { name, value } = e.target
      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: prev.launchPricing.options.map((eachMapObj, mapIndex) => {
            if (mapIndex === index) {
              return { ...eachMapObj, [name]: value }
            }
            if (mapIndex === index + 1) {
              return { ...eachMapObj, startsAt: value }
            }
            return eachMapObj
          }),
        },
      }))
    },
    [formValues, setFormValues, index],
  )

  React.useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      launchPricing: {
        ...prev.launchPricing,
        options: prev.launchPricing.options.map((eachMapObj, mapIndex, array) => {
          if (array.length === 1) {
            return { ...eachMapObj, startsAt: minimumEndAtDate, endsAt: minimumCanCheckoutUntilDate }
          }
          if (mapIndex === 0) {
            return { ...eachMapObj, startsAt: minimumEndAtDate }
          }
          if (mapIndex === array.length - 1) {
            return { ...eachMapObj, endsAt: minimumCanCheckoutUntilDate }
          }
          return eachMapObj
        }),
      },
    }))
  }, [minimumEndAtDate, minimumCanCheckoutUntilDate])

  const handleCheckBoxChange = React.useCallback(
    (string) => {
      formValues?.launchPricing?.options[index]?.paymentMethods.some((eachString) => eachString === string)
        ? setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: prev.launchPricing.options.map((eachMapObj, mapIndex) =>
                mapIndex === index
                  ? { ...eachMapObj, paymentMethods: eachMapObj.paymentMethods.filter((eachString) => eachString !== string) }
                  : eachMapObj,
              ),
            },
          }))
        : setFormValues((prev) => ({
            ...prev,
            launchPricing: {
              ...prev.launchPricing,
              options: prev.launchPricing.options.map((eachMapObj, mapIndex) =>
                mapIndex === index
                  ? {
                      ...eachMapObj,
                      paymentMethods: [...eachMapObj.paymentMethods, string],
                    }
                  : eachMapObj,
              ),
            },
          }))
    },
    [formValues, setFormValues, index],
  )

  const handleMaskedInputChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setMaskedValue(masks.currency(value))

      const calculatedDiscountPercentage = discountPercentage(numberFromBE, parseInt(masks.unmask(value), 10))

      calculatedDiscountPercentage <= 0 || calculatedDiscountPercentage >= 100
        ? setPercentage('')
        : setPercentage(calculatedDiscountPercentage)

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: prev.launchPricing.options.map((eachMapObj, mapIndex) =>
            mapIndex === index
              ? {
                  ...eachMapObj,
                  priceInCents: parseInt(masks.unmask(value), 10),
                }
              : eachMapObj,
          ),
        },
      }))
    },
    [formValues, setFormValues, maskedValue, setMaskedValue, percentage, setPercentage, index, discountPercentage],
  )

  const handlePercentageChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setPercentage(value)

      const calculatedPriceWithDiscount = priceWithDiscount(numberFromBE, value)

      setMaskedValue(masks.currency(calculatedPriceWithDiscount))

      setFormValues((prev) => ({
        ...prev,
        launchPricing: {
          ...prev.launchPricing,
          options: prev.launchPricing.options.map((eachMapObj, mapIndex) =>
            mapIndex === index
              ? {
                  ...eachMapObj,
                  priceInCents: calculatedPriceWithDiscount,
                }
              : eachMapObj,
          ),
        },
      }))
    },
    [formValues, setFormValues, maskedValue, setMaskedValue, percentage, setPercentage, index, priceWithDiscount],
  )

  return {
    maskedValue,
    percentage,
    handleDateInputChange,
    handleCheckBoxChange,
    handleMaskedInputChange,
    handlePercentageChange,
    isFirstElement,
    isLastElement,
  }
}
