import React from 'react'
import { InputContainer, SpaceBetweenFlex, InputError } from '../../styles'
import { InputFieldV3 as Input } from '@provi/provi-components'
import { FormikProps } from 'formik'
import { LengthSpan } from '../LengthSpan'
import { IInitialValues } from '../../hooks'

function ClassroomNameInput(formik: FormikProps<IInitialValues>) {
  const ErrorText = () => {
    return formik.errors.classroomName && formik.touched.classroomName ? (
      <InputError>{formik.errors.classroomName}</InputError>
    ) : (
      <div />
    )
  }

  return (
    <InputContainer>
      <Input
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        width="100%"
        type="text"
        label="Nome"
        name="classroomName"
        value={formik.values.classroomName}
        maxLength={68}
        placeholder="Digite um nome para a sua turma"
        hasError={formik.errors.classroomName && formik.touched.classroomName}
        isValid={!formik.errors.classroomName && formik.touched.classroomName}
      />
      <SpaceBetweenFlex>
        <ErrorText />
        <LengthSpan length={formik.values.classroomName.length} lengthLimit={68} />
      </SpaceBetweenFlex>
    </InputContainer>
  )
}

export { ClassroomNameInput }
