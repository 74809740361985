import React, { useMemo } from 'react'
import { Template } from '~/components/Template'
import { Home } from '~/assets/svgs/Home'
import {
  CardsWrapper,
  Container,
  Wrapper,
  Header,
  SubHeader,
  ListButton,
  ViewWrapper,
  ArrowWrapper,
  BackContainer,
  CardWrapper,
  FormsWrapper,
} from './styles'
import { useNFS, tabsEnum } from './hooks'
import { BackArrow } from '~/assets/svgs/back-arrow'
import { SchoolInfo } from './forms/schoolInfo'
import { Certificate } from './forms/certificate'
import { BillingInfo } from './forms/billingInfo'

/** @typedef {object} IProps */

/** @type {React.FC<IProps>}*/
export const NFS = () => {
  const { navigateToPage, tabs, selectedTab, setSelectedTab, partnerStatuses } = useNFS()

  const renderForm = useMemo(() => {
    switch (selectedTab) {
      case tabsEnum.schoolData:
        return <SchoolInfo status={partnerStatuses.companyRegistered} />

      case tabsEnum.cerificate:
        return <Certificate />

      case tabsEnum.billingInfo:
        return <BillingInfo />

      default:
        return null
    }
  }, [selectedTab])

  const getCardText = (e) => {
    if (e.special) {
      return 'Consulta'
    }

    return e.status ? 'Cadastro Completo' : 'Cadastro Incompleto'
  }

  return (
    <Template>
      <Wrapper>
        <Header>
          <Container>
            <ListButton single onClick={() => navigateToPage('home')}>
              <Home />
            </ListButton>
          </Container>
        </Header>
        <SubHeader>
          <BackContainer>
            <ArrowWrapper onClick={() => navigateToPage('home')}>
              <BackArrow />
            </ArrowWrapper>
          </BackContainer>
          <h1>Cadastro para emissão de NF</h1>
          <p>
            Cadastre abaixo as informações referente ao seu certificado digital, seus dados empresarias e serviço padrão para
            emissão automática das suas NFs.
          </p>
        </SubHeader>
        <ViewWrapper>
          <CardsWrapper>
            {tabs.map((e, i) => {
              if (!partnerStatuses.requireCertificate) {
                return (
                  i > 0 && (
                    <CardWrapper key={e.id} isComplete={e.status} isClickable={true} onClick={() => setSelectedTab(e.title)}>
                      <div>
                        <h1>{getCardText(e)}</h1>
                      </div>
                      <section>
                        <p>{e.title}</p>
                        {<a>Clique para editar</a>}
                      </section>
                    </CardWrapper>
                  )
                )
              } else {
                const clicable = partnerStatuses.certificateRegistered
                return (
                  <CardWrapper
                    key={e.id}
                    isComplete={e.status}
                    isClickable={i > 0 ? clicable : true}
                    onClick={() => (i == 0 || clicable) && setSelectedTab(e.title)}
                  >
                    <div>
                      <h1>{getCardText(e)}</h1>
                    </div>
                    <section>
                      <p>{e.title}</p>
                      {(i == 0 || clicable) && <a>Clique para editar</a>}
                    </section>
                  </CardWrapper>
                )
              }
            })}
          </CardsWrapper>
        </ViewWrapper>
        <ViewWrapper>
          <FormsWrapper>{renderForm}</FormsWrapper>
        </ViewWrapper>
      </Wrapper>
    </Template>
  )
}
