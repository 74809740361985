import { theme } from '@provi/provi-components'

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? 600 : 'normal',
    fontFamily: 'Montserrat, sans serif',
    color: theme.colors.blue900,
    fontSize: state.selectProps.myFontSize,
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#e0ebfd',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 600,
    color: theme.colors.blue900,
    fontFamily: 'Montserrat, sans serif',
    fontSize: state.selectProps.myFontSize,
    position: 'static',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    maxWidth: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.blue900,
    fontSize: state.selectProps.myFontSize,
    position: 'static',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    maxWidth: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    fontFamily: 'Montserrat, sans serif',
    zIndex: 100,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? theme.colors.blue900 : theme.colors.pink900,
    padding: 6,
    '&:hover': {
      color: theme.colors.pink900,
    },
  }),
  container: (provided) => ({
    ...provided,
    placeSelf: 'center',
  }),
}
