import React from 'react'

export const Copy = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M17.15 12.7H18.1C18.6039 12.7 19.0872 12.5104 19.4435 12.1728C19.7998 11.8352 20 11.3774 20 10.9V2.8C20 2.32261 19.7998 1.86477 19.4435 1.52721C19.0872 1.18964 18.6039 1 18.1 1H9.55C9.04609 1 8.56282 1.18964 8.2065 1.52721C7.85018 1.86477 7.65 2.32261 7.65 2.8V3.7M11.45 7.3H2.9C1.85066 7.3 1 8.10589 1 9.1V17.2C1 18.1941 1.85066 19 2.9 19H11.45C12.4993 19 13.35 18.1941 13.35 17.2V9.1C13.35 8.10589 12.4993 7.3 11.45 7.3Z"
      stroke="#DE0C4B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DuplicateIcon = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 29C22.9558 29 29 22.9558 29 15.5C29 8.04416 22.9558 2 15.5 2C8.04416 2 2 8.04416 2 15.5C2 22.9558 8.04416 29 15.5 29ZM15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31ZM20.738 18.6175C22.1159 17.528 23 15.8416 23 13.9487C23 10.6633 20.3366 8 17.0513 8C15.7643 8 14.5727 8.4087 13.5994 9.1034C17.685 9.43439 20.8974 12.855 20.8974 17.0259C20.8974 17.571 20.8426 18.1033 20.738 18.6175ZM10.4863 9.46593C7.30166 10.5025 5 13.4954 5 17.0259C5 21.4158 8.55876 24.9746 12.9487 24.9746C15.6757 24.9746 18.082 23.6013 19.5137 21.5087C22.6983 20.4721 25 17.4792 25 13.9487C25 9.55876 21.4412 6 17.0513 6C14.3243 6 11.918 7.37325 10.4863 9.46593Z"
      fill="#DE0C4B"
    />
  </svg>
)
