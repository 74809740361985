import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { TemplateContext } from '~/components/Template/context'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { showToast, theme } from '@provi/provi-components'
import Sally from '~/assets/images/Saly-1.png'
import { ProviLogo } from '~/assets/svgs/v2'
import { UserIcon } from '~/assets/svgs/user-icon'

import { Container, ImageContainer, SallyImage, FormContainer, Form, Title, Section, StyledLabel } from './styles'
import { SvgWrapper } from '~/screens/login/styles'
import { TextErrorInput } from '~/components/text-error-input'
import { Input } from '~/components/v2/Input'
import { Button } from '~/components/v2/Button'
import { api } from '~/services/api'

function ForgotPassword() {
  const { setIsLoading } = useContext(TemplateContext)
  const history = useHistory()
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email('Digite um email válido').required('Preencha o campo Email'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      setIsLoading(true)

      try {
        const { status, data } = await api.post('/password/recovery', { email })
        if (status === 200) {
          showToast(data.message)
          history.push('/')
        }
      } catch ({ response }) {
        showToast(response.data.error.message)
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Container>
      <SallyImage src={Sally} />
      <ImageContainer>
        <SvgWrapper>
          <ProviLogo logoColor={'white'} textColor={'white'} />
        </SvgWrapper>
      </ImageContainer>
      <FormContainer>
        <Form onSubmit={formik.handleSubmit}>
          <Title>Redefina a sua senha</Title>
          <p>Você receberá um e-mail com instruções para redefinir a senha</p>
          <Section>
            <StyledLabel>Email</StyledLabel>
            <Input
              error={formik.errors.email && formik.touched.email}
              autoFocus
              id="email"
              placeholder="Digite o seu email"
              icon={<UserIcon />}
              value={formik.values.email}
              type="text"
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur('email')}
            />
            {formik.errors.email && formik.touched.email && <TextErrorInput>{formik.errors.email}</TextErrorInput>}

            <Button
              icon
              id="button"
              margin="35px auto 0"
              backgroundDisabled={theme.colors.blue800}
              text="Avançar"
              disabled={!formik.values.email || formik.errors.email}
              type="submit"
            />
          </Section>
        </Form>
      </FormContainer>
    </Container>
  )
}

export { ForgotPassword }
