import styled from 'styled-components'

export const ContainerSubHeader = styled.div`
  position: fixed;
  top: 80px;
  background: ${({ isDark }) => (isDark ? 'none' : '#fff')};
  width: 100%;
  height: 60px;
  z-index: 15;
  background-color: ${(backgroundColor) => backgroundColor || 'none'};
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: ${({ isMargin }) => isMargin || 'unset'};
`
