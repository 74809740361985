import React from 'react'

export const SearchIcon = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '18'}
    height={size || '18'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7026 12.7032L16.4995 16.5001M14.625 8.0625C14.625 11.6869 11.6869 14.625 8.0625 14.625C4.43813 14.625 1.5 11.6869 1.5 8.0625C1.5 4.43813 4.43813 1.5 8.0625 1.5C11.6869 1.5 14.625 4.43813 14.625 8.0625Z"
      stroke={color || 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
