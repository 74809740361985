import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Product = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const ProductTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.grey600};
`

export const ProductText = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: ${theme.colors.blue900};
`
