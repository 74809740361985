import React from 'react'
import Box from '@material-ui/core/Box'

import { MobileGrid, SubGrid, CenteredBtn } from './styles'

const findAndPrintValue = (row, valueHeader) => {
  return row.allCells.find(({ column: { Header } }) => Header === valueHeader)
}

export const ChargebacksMobileRows = ({ row }) => {
  return (
    <Box>
      <MobileGrid>
        <div>
          <strong>Status:</strong>
          <br />
          {findAndPrintValue(row, 'Status').value}
        </div>
        <SubGrid>
          <div>
            <strong>Data do CB:</strong> <br />
            {findAndPrintValue(row, 'Data do CB').render('Cell')}
          </div>
          <div>
            <strong>Valor:</strong> <br />
            {findAndPrintValue(row, 'Valor').render('Cell')}
          </div>
        </SubGrid>
        <SubGrid>
          <div>
            <strong>CPF:</strong> <br />
            {findAndPrintValue(row, 'CPF').render('Cell')}
          </div>
          <div>
            <strong>Email:</strong> <br />
            {findAndPrintValue(row, 'E-mail').render('Cell')}
          </div>
        </SubGrid>
        <CenteredBtn>{findAndPrintValue(row, 'Ações').render('Cell')}</CenteredBtn>
      </MobileGrid>
    </Box>
  )
}
