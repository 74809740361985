import React from 'react'
import { TemplateContext } from '~/components/Template/context'
import { theme, Breadcrumbs, ButtonV3 } from '@provi/provi-components'
import { CoursesHeader, CoursesDivider } from '../../styles'
import { BaseTypography, BaseFlexContainer } from '~/globalStyles'
import { ShareIcon } from '~/assets/svgs/v2'
import { useLocation } from 'react-router-dom'

export const ActionsContainer = ({ isMobile, goToPage, openLinkModal }) => {
  return (
    <BaseFlexContainer gap={'2rem'} justifyContent={'center'} alignItems={'center'}>
      <BaseFlexContainer gap={'0.5rem'} onClick={openLinkModal}>
        <BaseTypography
          color={theme.colors.bluePrimary}
          fontWeight={600}
          fontSize={isMobile && '13px'}
          lineHeight={isMobile && '20px'}
          cursor={'pointer'}
        >
          Link de vendas
        </BaseTypography>
        <ShareIcon color={theme.colors.bluePrimary} />
      </BaseFlexContainer>
      {!isMobile && <ButtonV3 text="Novo curso" onClick={() => goToPage('cursos/novo-curso')} />}
    </BaseFlexContainer>
  )
}

export const CoursesBreadCrumbs = ({ breadCrumbsArr = [], showNotification = false, openLinkModal = undefined }) => {
  const { pathname } = useLocation()
  const { isMobile, goToPage } = React.useContext(TemplateContext)

  const sharedBreadCrumbs = [
    <span key={'Vendas'}>Vendas</span>,
    <span key={'Cursos'} onClick={() => goToPage('cursos')} style={{ cursor: 'pointer' }}>
      Cursos
    </span>,
  ]

  return (
    <CoursesHeader>
      {!isMobile && (
        <div>
          <Breadcrumbs
            color={'black'}
            currentColor={theme.colors.bluePrimary}
            levels={[...sharedBreadCrumbs, ...breadCrumbsArr]}
          />
          {pathname === '/cursos' && <ActionsContainer isMobile={isMobile} goToPage={goToPage} openLinkModal={openLinkModal} />}
        </div>
      )}
      <CoursesDivider revertMarginTop={pathname === '/cursos' && showNotification} />
      {isMobile && (
        <div>
          <Breadcrumbs
            color={'black'}
            currentColor={theme.colors.bluePrimary}
            levels={[...sharedBreadCrumbs, ...breadCrumbsArr]}
          />
          {pathname === '/cursos' && <ActionsContainer isMobile={isMobile} goToPage={goToPage} openLinkModal={openLinkModal} />}
        </div>
      )}
    </CoursesHeader>
  )
}
