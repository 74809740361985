/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Label, Button, TextLink, theme } from '@provi/provi-components'
import { Popover } from '@material-ui/core'

import { TemplateContext } from '~/components/Template/context'
import { ButtonBack } from '~/components/ButtonBack'
import { SubHeader } from '~/components/SubHeader'
import { Selector } from '~/components/Select'
import { Input } from '~/components/Input'

import { IconHelp } from '~/assets/svgs/icon-help'

import { useCreateCourses } from './hooks'
import {
  Section,
  Container,
  WrapperLabel,
  ErrorMessage,
  WrapperInput,
  WrapperIcon,
  PopoverText,
  ContainerModal,
  Title,
  ContainerBoxButtons,
  BoxButton,
  OptionsVisibleOnSite,
  WarningText,
  WarningTextBold,
  WrapperGetLink,
  WrapperGetLinkTextLink,
  Message,
  Bold,
  Br,
  Main,
} from './styles'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

export const CreateCourse = ({ handleModal }) => {
  const {
    isLowMobile,
    setIsLoading,
    handleLogoutModal,
    selectedOptions,
    setSelectedOptions,
    setValue,
    value,
    isMobile,
    goToPage,
    activePage,
    setActivePage,
  } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    validate,
    nameError,
    valueError,
    handleSubmitCouses,
    handleClick,
    anchorEl,
    handleClose,
    anchor,
    handleCheck,
    visible,
    optionActive,
    modality_options,
    period_options,
    //
    state_options,
    city_options,
    //
    handleValue,
    values,

    isButtonDisable,
    //
    periodValue,
    modalityValue,

    macro_options,
    micro_options,
    macroAreaValue,
    microAreaValue,
  ] = useCreateCourses(
    setIsLoading,
    handleModal,
    selectedOptions,
    setSelectedOptions,
    setValue,
    value,
    goToPage,
    activePage,
    setActivePage,
  )

  return (
    <Main isMobile={isMobile} drawerWidth={drawerWidth}>
      {isMobile && (
        <SubHeader
          isMargin="0 0 30px 0"
          isShowButtonHelpModal={false}
          functionBackButton={() => goToPage('checkout/selecionar-produtos')}
        />
      )}
      <Container>
        {!isMobile && <ButtonBack onClick={() => goToPage('checkout/selecionar-produtos')} />}
        <ContainerModal>
          <Title>Criar novo produto</Title>
          <Section>
            <Label text="Nome do produto*" />
            <WrapperInput>
              <Input
                key="name"
                inputProps={{ autoFocus: true, maxLength: 99 }}
                id="productName"
                name="productName"
                placeholder="Ex: Web Development, UX | UI Design, Growth Marketing..."
                onChange={(event) => handleValue('name', event.target.value)}
                value={values.name}
                onBlur={(event) => validate(event, 'name')}
                tabIndex="0"
              />

              <WrapperIcon onClick={(event) => handleClick(event, 'name')}>
                <IconHelp />
              </WrapperIcon>

              <Popover
                id="name"
                open={anchor === 'name'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PopoverText>
                  Insira o nome do curso e se achar necessário a data de início e fim. Ex: Web Full Stack 13/08 a 11/09 ou
                  Marketing Digital Noturno.
                  <br />
                  <br />
                  Você poderá detalhar mais esse produto nos campos abaixo.
                </PopoverText>
              </Popover>
            </WrapperInput>
            {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
          </Section>
          <Section>
            <Label text="Valor do produto*" />
            <WrapperInput>
              <Input
                type="tel"
                placeholder="Ex: 1.200,00"
                onChange={(event) => handleValue('productValue', event.target.value)}
                value={values.productValue}
                onBlur={(event) => validate(event, 'productValue')}
                width="100%"
              />

              <WrapperIcon onClick={(event) => handleClick(event, 'productValue')}>
                <IconHelp />
              </WrapperIcon>

              <Popover
                id="productValue"
                open={anchor === 'productValue'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PopoverText>Digite apenas o valor sem pontuação.</PopoverText>
              </Popover>
            </WrapperInput>
            {valueError && <ErrorMessage>{valueError}</ErrorMessage>}
          </Section>
          <Section>
            <WrapperLabel>
              <Label text="Periodo*" />
            </WrapperLabel>
            <WrapperInput>
              <Selector
                value={periodValue}
                onChange={(event) => handleValue('period', event)}
                id="period"
                options={period_options}
                placeholder={isLowMobile ? 'Opção' : 'Selecione uma opção'}
              />
              <WrapperIcon onClick={(event) => handleClick(event, 'period')}>
                <IconHelp />
              </WrapperIcon>

              <Popover
                id="period"
                open={anchor === 'period'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PopoverText>
                  Considere como &ldquo;Full Time&ldquo; os cursos em que o estudante utilizará grande parte do horário
                  comercial para estudar.
                </PopoverText>
              </Popover>
            </WrapperInput>
          </Section>
          <Section>
            <WrapperLabel>
              <Label text="Modalidade*" />
            </WrapperLabel>
            <WrapperInput>
              <Selector
                value={modalityValue}
                onChange={(event) => handleValue('modality', event)}
                id="modality"
                options={modality_options}
                placeholder={isLowMobile ? 'Opção' : 'Selecione uma opção'}
              />
              <WrapperIcon onClick={(event) => handleClick(event, 'modality')}>
                <IconHelp />
              </WrapperIcon>

              <Popover
                id="modality"
                open={anchor === 'modality'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PopoverText>
                  Os cursos &ldquo;Presenciais&ldquo; exigem a presença física do aluno todos os dias, enquanto o
                  &ldquo;Semi-presencial&ldquo; possui horários de estudo mais flexíveis e os encontros presenciais são mais
                  espaçados.
                </PopoverText>
              </Popover>
            </WrapperInput>
          </Section>

          <Section>
            <WrapperInput>
              <Label text="Deve ser visível para outros e listado no site da Provi?*" />
              <WrapperIcon onClick={(event) => handleClick(event, 'options')}>
                <IconHelp />
              </WrapperIcon>

              <Popover
                id="options"
                open={anchor === 'options'}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <PopoverText>
                  <Message>
                    <Bold>Produtos visíveis</Bold> são listados no site da Provi e podem ser
                    <Bold> abertamente comercializados pelo preço cadastrado</Bold> no produto.
                    <Br />
                    <Br />
                    <Bold>Produtos invisíveis</Bold> não são listados no site da Provi e são comercializados{' '}
                    <Bold>apenas se o vendedor criar uma venda com esse produto selecionado.</Bold>
                  </Message>
                </PopoverText>
              </Popover>
            </WrapperInput>
            <ContainerBoxButtons>
              <BoxButton active={optionActive.visibleCoursesOption === 'y'} onClick={() => handleCheck('options', 'y')}>
                SIM
              </BoxButton>
              <BoxButton active={optionActive.visibleCoursesOption === 'n'} onClick={() => handleCheck('options', 'n')}>
                NÃO
              </BoxButton>
            </ContainerBoxButtons>
          </Section>

          {visible && (
            <OptionsVisibleOnSite>
              <Section>
                <WarningText>
                  <WarningTextBold>Lembre-se!</WarningTextBold> Você precisa preencher o máximo de informações opcionais
                  possíveis para que o produto seja melhor <WarningTextBold>rankeado</WarningTextBold> no site da Provi!
                </WarningText>
              </Section>

              {values.modality && values.modality.value === 'remoto' ? (
                ''
              ) : (
                <>
                  <Section>
                    <WrapperLabel>
                      <Label text="Estado*" />
                    </WrapperLabel>
                    <WrapperInput>
                      <Selector
                        // onChange={item => setPeriod(item)}
                        onChange={(event) => handleValue('state', event)}
                        id="state"
                        options={state_options}
                        placeholder="Selecione um estado"
                        value={values.state ? values.state : null}
                      />
                      <WrapperIcon onClick={(event) => handleClick(event, 'state')}>
                        <IconHelp />
                      </WrapperIcon>

                      <Popover
                        id="state"
                        open={anchor === 'state'}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <PopoverText>Selecione o estado em que o curso ocorrerá.</PopoverText>
                      </Popover>
                    </WrapperInput>
                  </Section>
                  <Section>
                    <WrapperLabel>
                      <Label text="Cidade*" />
                    </WrapperLabel>
                    <WrapperInput>
                      <Selector
                        onChange={(event) => handleValue('city', event)}
                        id="city"
                        value={values.city ? values.city : null}
                        options={city_options}
                        placeholder="Selecione uma cidade"
                      />
                      <WrapperIcon onClick={(event) => handleClick(event, 'city')}>
                        <IconHelp />
                      </WrapperIcon>

                      <Popover
                        id="city"
                        open={anchor === 'city'}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <PopoverText>Selecione a cidade em que o curso ocorrerá.</PopoverText>
                      </Popover>
                    </WrapperInput>
                  </Section>
                </>
              )}

              <Section>
                <WrapperLabel>
                  <Label text="Categoria de ensino*" />
                </WrapperLabel>
                <WrapperInput>
                  <Selector
                    onChange={(event) => handleValue('macroareas', event)}
                    id="macroareas"
                    options={macro_options}
                    value={macroAreaValue}
                    placeholder="Selecione uma categoria"
                  />
                </WrapperInput>
              </Section>

              <Section>
                <WrapperLabel>
                  <Label text="Subcategoria de ensino*" />
                </WrapperLabel>
                <WrapperInput>
                  <Selector
                    onChange={(event) => handleValue('microareas', event)}
                    id="microareas"
                    value={microAreaValue}
                    options={micro_options}
                    placeholder="Selecione uma subcategoria"
                  />
                </WrapperInput>
              </Section>

              <Section>
                <Label text="Tem processo seletivo? (opcional)" />
                <ContainerBoxButtons>
                  <BoxButton active={optionActive.proccessOption === 'y'} onClick={() => handleCheck('process', 'y')}>
                    SIM
                  </BoxButton>
                  <BoxButton active={optionActive.proccessOption === 'n'} onClick={() => handleCheck('process', 'n')}>
                    NÃO
                  </BoxButton>
                </ContainerBoxButtons>
              </Section>
              <Section>
                <Label text="Carga horária (opcional)" />
                <WrapperInput>
                  <Input
                    type="number"
                    key="workload"
                    id="workload"
                    name="workload"
                    placeholder="Ex: 36"
                    inputProps={{
                      maxLength: 99,
                      min: '0',
                    }}
                    onChange={(event) => handleValue('workload', event.target.value)}
                    value={values.workload}
                    tabIndex="0"
                  />
                </WrapperInput>
              </Section>
              <Section>
                <Label text="Duração em dias (opcional)" />
                <WrapperInput>
                  <Input
                    type="number"
                    key="durationDays"
                    inputProps={{
                      autoFocus: false,
                      maxLength: 99,
                      min: '0',
                    }}
                    id="durationDays"
                    name="durationDays"
                    placeholder="Ex: 60"
                    onChange={(event) => handleValue('durationDays', event.target.value)}
                    value={values.durationDays}
                    tabIndex="0"
                  />
                </WrapperInput>
              </Section>
            </OptionsVisibleOnSite>
          )}

          {optionActive.visibleCoursesOption && (
            <Section align="center">
              <WrapperGetLink>
                <Button
                  disabled={isButtonDisable}
                  text="Criar produto"
                  marginHorizontal={0}
                  marginVertical={0}
                  onClick={handleSubmitCouses}
                />
                <WrapperGetLinkTextLink>
                  <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
                </WrapperGetLinkTextLink>
              </WrapperGetLink>
            </Section>
          )}
        </ContainerModal>
      </Container>
    </Main>
  )
}
