import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'
import { useProfile } from './hooks'

import { SelectSectionButton } from '@provi/provi-components'
import { GeneralDataTab, FinanceTab, ContactsTab, AccountHistoryTable } from '~/screens/profile/components'

import {
  PageWrapper,
  Container,
  Header,
  Content,
  HeaderText,
  CtaText,
  StyledCard,
  SectionButtonsWrapper,
  ComponentSection,
  TableHeaderText,
  TableWrapper,
  Components,
} from './styles'

export const Profile = () => {
  const { setIsLoading, isMobile, goToPage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)
  const { name: pathName } = useParams()
  const {
    activeTab,
    handleActiveTab,
    accessLevel,
    generalNotifications,
    contactsNotifications,
    financeNotifications,
  } = useProfile(setIsLoading, goToPage, pathName)

  return (
    <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      {accessLevel && (
        <Container>
          <Header>
            <HeaderText>Perfil do Parceiro</HeaderText>
          </Header>
          <Content>
            <StyledCard>
              <CtaText>Mantenha seus dados empresariais atualizados ;)</CtaText>
              <SectionButtonsWrapper>
                <SelectSectionButton
                  buttonWidth={isMobile ? 77 : 184}
                  buttonHeight={isMobile ? 40 : 56}
                  onClick={() => handleActiveTab('dados-gerais')}
                  text="Dados gerais"
                  isActive={activeTab === 'dados-gerais'}
                  notifications={generalNotifications || null}
                />
                {accessLevel === 'superadmin' && (
                  <SelectSectionButton
                    buttonWidth={isMobile ? 77 : 184}
                    buttonHeight={isMobile ? 40 : 56}
                    onClick={() => handleActiveTab('dados-financeiros')}
                    text="Dados financeiros"
                    isActive={activeTab === 'dados-financeiros'}
                    notifications={financeNotifications || null}
                  />
                )}
                <SelectSectionButton
                  buttonWidth={isMobile ? 77 : 184}
                  buttonHeight={isMobile ? 40 : 56}
                  onClick={() => handleActiveTab('contatos')}
                  text="Contatos"
                  isActive={activeTab === 'contatos'}
                  notifications={contactsNotifications || null}
                />
                {/* <SelectSectionButton
                  buttonWidth={isMobile ? 77 : 184}
                  buttonHeight={isMobile ? 40 : 56}
                  onClick={() => handleActiveTab('condicoes-comerciais')}
                  text={isMobile ? 'Condições' : 'Condições comerciais'}
                  isActive={activeTab === 'condicoes-comerciais'}
                  notifications={1 || null}
                /> */}
              </SectionButtonsWrapper>
              <ComponentSection>
                {activeTab === 'dados-gerais' && <GeneralDataTab />}
                {activeTab === 'dados-financeiros' && <FinanceTab />}
                {activeTab === 'contatos' && <ContactsTab />}
                {activeTab === 'condicoes-comerciais' && <Components />}
              </ComponentSection>
            </StyledCard>
          </Content>
          {activeTab === 'dados-financeiros' && (
            <TableWrapper>
              <TableHeaderText>Histórico de contas</TableHeaderText>
              <AccountHistoryTable />
            </TableWrapper>
          )}
        </Container>
      )}
    </PageWrapper>
  )
}
