import React from 'react'

export const OpenedEye = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.37444C3.75 4.37444 1.25 10.0001 1.25 10.0001C1.25 10.0001 3.75 15.6244 10 15.6244C16.25 15.6244 18.75 10.0001 18.75 10.0001C18.75 10.0001 16.25 4.37444 10 4.37444Z"
      stroke={color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.1251C11.7259 13.1251 13.125 11.726 13.125 10.0001C13.125 8.27422 11.7259 6.87511 10 6.87511C8.27411 6.87511 6.875 8.27422 6.875 10.0001C6.875 11.726 8.27411 13.1251 10 13.1251Z"
      stroke={color || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
