/**
 * @description get translatted creator
 * @function
 * @name getTranslattedCreator
 * @param {string} creator
 * @returns {string}
 */
export const getTranslattedCreator = (creator) => {
  const creatorOptions = {
    SYSTEM: 'Campanha',
    null: 'Em branco',
  }

  return creatorOptions[creator] || creator
}
