import React, { useContext } from 'react'

// import { Button } from '@provi/provi-components'
import { Home } from '~/assets/svgs/Home'
import { Bag } from '~/assets/svgs/bag'
import { CheckoutCarIcon } from '~/assets/svgs/checkoutCar'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { Wrapper, ListButton, Container, BoxButton } from './styles'
import { useHeader } from './hooks'

export const Header = ({ isCampaign = true, isNewSale = true }) => {
  const { isMobile, goToPage, activePage, setActivePage } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [handlePage] = useHeader(goToPage, activePage, setActivePage)

  return (
    <Wrapper isMobile={isMobile} drawerWidth={drawerWidth}>
      <Container>
        <ListButton single onClick={() => handlePage('home')}>
          <Home />
        </ListButton>
        {isCampaign && (
          <BoxButton active={activePage === 'campaigns'} onClick={() => handlePage('campanha/listar-campanhas', 'campaigns')}>
            <Bag color="#fff" />
            {!isMobile && 'Campanhas'}
          </BoxButton>
        )}
        {isNewSale && (
          <BoxButton active={activePage === 'sell'} onClick={() => handlePage('checkout/selecionar-produtos', 'sell')}>
            <CheckoutCarIcon color="#fff" />
            {!isMobile && 'Nova venda'}
          </BoxButton>
        )}
      </Container>
    </Wrapper>
  )
}
