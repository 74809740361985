import React from 'react'

import { StyledButton } from './styles'

export const ActionButton = ({ onClick, text, disabled }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      {text}
    </StyledButton>
  )
}
