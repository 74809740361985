import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

export const SallyImage = styled.img`
  position: absolute;
  max-width: 700px;
  max-height: 700px;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);

  ${theme.breakpoints.down('lg')} {
    max-width: 500px;
    max-height: 500px;
  }
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  width: 25vw;
  background-color: ${theme.colors.blue900};
  padding-top: 60px;
  padding-left: 30px;

  ${theme.breakpoints.down('md')} {
    padding-top: 40px;
    padding-left: 15px;
    width: 30vw;
  }

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 10px 20px;
  }
`

export const FormContainer = styled.div`
  display: flex;
  width: 75vw;
  align-content: center;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    width: 70vw;
  }

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 90px 10px 0;
  }
`

export const Form = styled.form`
  padding: 5px;
  width: 100%;
  max-width: 400px;

  ${theme.breakpoints.up('md')} {
    padding-left: 30px;
    width: 100%;
  }

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 500px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormControl-marginNormal {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 8px;
  }
`

export const Title = styled.p`
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 28px;
  line-height: 30px;
  color: ${theme.colors.pink900};
  margin-bottom: 20px;
`

export const StyledLabel = styled.label`
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: block;
`
export const Section = styled.div`
  margin-bottom: 35px;
`
