import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperButtonBack = styled.div`
  display: block;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 30px;
  ${theme.breakpoints.down('md')} {
    position: unset;
  }
`
