import { useContext, useCallback, useState } from 'react'
import { showToast } from '@provi/provi-components'

import { TemplateContext } from '~/components/Template/context'
import { useMount } from '~/hooks'
import { history } from '~/navigation/history'
import { usePartnerStore } from '~/stores'

export const tabsEnum = {
  schoolData: 'Dados da Escola',
  cerificate: 'Certificado digital',
  billingInfo: 'Dados Tributários',
  nfse: 'Listar NFSe',
}

export const useNFS = () => {
  const [selectedTab, setSelectedTab] = useState('')
  const { getPartnerStatuses, partnerStatuses } = usePartnerStore()
  const { goToPage, setActivePage, setIsLoading } = useContext(TemplateContext)

  useMount(async () => {
    setIsLoading(true)
    try {
      await getPartnerStatuses()
    } catch (error) {
      console.log(error.response)
      if (error.response.status === 403) {
        showToast('Parceiro não habilitado para cadastrar notas fiscais')
        return history.push('/home')
      }
      return showToast('Um erro inesperado aconteceu')
    } finally {
      setIsLoading(false)
    }
  })

  /** @type {(path: string, page: string) => void} */
  const navigateToPage = useCallback(
    (path, page) => {
      if (page) setActivePage(page)
      goToPage(path)
    },
    [goToPage, setActivePage],
  )

  const tabs = [
    {
      id: 1,
      title: 'Certificado digital',
      status: partnerStatuses.certificateRegistered,
    },
    {
      id: 0,
      title: 'Dados da Escola',
      status: partnerStatuses.companyRegistered,
    },
    {
      id: 2,
      title: 'Dados Tributários',
      status: partnerStatuses.defaultServiceRegistered,
    },
    {
      id: 3,
      title: 'Listar NFSe',
      status: true,
      special: true,
    },
  ]

  return {
    navigateToPage,
    tabs,
    selectedTab,
    setSelectedTab,
    partnerStatuses,
  }
}
