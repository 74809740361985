import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const SomeOtherComponent = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: lightgreen;
`

export const ManageAccountContainer = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 1200px;

  ${theme.breakpoints.down('md')} {
    gap: 1.5rem;
  }
`
