import React from 'react'
import { useParams } from 'react-router-dom'
import { TextSwitch, SwiperSection, CoursesBreadCrumbs } from '~/screens/courses/components'
import { InformationContainer } from '~/screens/courses/components/DetailsComponents/components'
import {
  TitleAndActions,
  CourseExpandDetails,
} from '~/screens/courses/components/DetailsComponents/components/CourseDetails/components'
import { OpenedEye, ClosedEye } from '~/assets/svgs/v2'
import { useCourseDetails } from './hooks'
import { CourseDetailsGrid, SpaceBetweenContainer, InformationGrid } from './styles'
import { CoursesModal } from 'screens/courses/components'
import { makeCourseInvisibleModal, makeCourseVisibleModal, deleteCourseModal, shareLinkModal } from 'screens/courses/constants'
import { copyString } from '~/helpers/copyAndPaste.js'

export const CourseDetails = () => {
  const { courseId } = useParams()
  const {
    isMobile,
    goToPage,
    courseDetails,
    editVisibilityModalRef,
    deleteModalRef,
    shareLinkModalRef,
    openModals,
    closeModals,
    deleteCourseMethod,
    toggleVisibility,
    extraBreadCrumbs,
  } = useCourseDetails({
    id: courseId,
  })

  return (
    <>
      {courseDetails && (
        <>
          <CoursesBreadCrumbs breadCrumbsArr={extraBreadCrumbs} />
          <CourseDetailsGrid>
            <SpaceBetweenContainer>
              <TitleAndActions
                isMobile={isMobile}
                courseTitle={courseDetails?.content.name}
                createdAt={courseDetails?.content.createdAt}
                actions={{
                  edit: () => alert('editar'),
                  copy: () => alert('copy'),
                  share: () => openModals('share'),
                  delete: () => openModals('delete'),
                }}
              />

              <TextSwitch
                switchState={courseDetails?.content.active}
                LeftIcon={OpenedEye}
                leftText={'Visível'}
                RightIcon={ClosedEye}
                rightText={'Invisível'}
                toggleSwitchState={() => openModals('editVisibility')}
              />
            </SpaceBetweenContainer>
            <SpaceBetweenContainer>
              <CourseExpandDetails isMobile={isMobile} courseDetails={courseDetails?.content} />
              <InformationGrid>
                <InformationContainer
                  leftProps={{ number: courseDetails?.metadata.sales?.waiting_signature, text: 'Aguardando assinatura' }}
                  rightProps={{ number: courseDetails?.metadata.sales?.waiting_payment, text: 'Aguardando pagamento' }}
                  buttonProps={{ text: 'Gerenciar vendas', onClick: () => goToPage('checkout/listar-checkouts') }}
                />
                <InformationContainer
                  leftProps={{
                    number: courseDetails?.metadata.receipts?.made_effective,
                    text: 'Alunos efetivados',
                    isBlue: true,
                  }}
                  rightProps={{ number: courseDetails?.metadata.receipts?.canceled, text: 'Alunos cancelados' }}
                  buttonProps={{ text: 'Controlar recebimentos', onClick: () => goToPage('checkout/controle-recebimentos') }}
                />
              </InformationGrid>
            </SpaceBetweenContainer>
            <SwiperSection courseKey={courseId} courseClassesData={courseDetails?.content.CourseClasses} isMobile={isMobile} />

            <CoursesModal
              modalRef={editVisibilityModalRef}
              closeModalFn={() => closeModals('editVisibility')}
              modalObject={courseDetails?.content?.active ? makeCourseInvisibleModal : makeCourseVisibleModal}
              handlePrimaryBtn={() => toggleVisibility(courseDetails?.content)}
              handleSecondaryBtn={() => closeModals('editVisibility')}
            />
            <CoursesModal
              modalRef={deleteModalRef}
              closeModalFn={() => closeModals('delete')}
              modalObject={deleteCourseModal}
              handlePrimaryBtn={deleteCourseMethod}
              handleSecondaryBtn={() => closeModals('delete')}
            />
            <CoursesModal
              modalRef={shareLinkModalRef}
              closeModalFn={() => closeModals('share')}
              modalObject={shareLinkModal}
              paragraphVariables={courseDetails?.metadata?.redirect_url}
              headerText={'Compartilhe seu link de venda do curso'}
              handlePrimaryBtn={() =>
                copyString(courseDetails?.metadata?.redirect_url?.link, 'link', () => closeModals('share'))
              }
              handleSecondaryBtn={() =>
                copyString(shareLinkModal.onlyStrings(courseDetails?.metadata?.redirect_url), 'linkAndText', () =>
                  closeModals('share'),
                )
              }
            />
          </CourseDetailsGrid>
        </>
      )}
    </>
  )
}
