import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const BasePageWrapper = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;
  min-height: 100vh;

  ${theme.breakpoints.down('md')} {
    padding: initial;
  }

  .MuiButton-label {
    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }
`

export const BaseContainer = styled.div`
  display: grid;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`

export const BaseGrid = styled.div`
  display: grid;
`

export const BaseFlexContainer = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  gap: ${({ gap }) => gap || 'normal'};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
  align-items: ${({ alignItems }) => alignItems || 'normal'};
  cursor: ${({ cursor }) => cursor || 'auto'};
`

export const GridDivider = styled.div`
  border-top: 1px solid #cfcfcf;
  grid-column: 1 / -1;

  ${({ isFromBreadcrumbs }) =>
    isFromBreadcrumbs &&
    css`
      ${theme.breakpoints.down('md')} {
        margin-top: 4rem;
      }
    `}
`

export const BaseTypography = styled.p`
  font-family: 'Montserrat', sans-serif;
  margin: ${({ margin }) => margin || 0};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '24px'};
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  color: ${({ color }) => color || 'black'};
  cursor: ${({ cursor }) => cursor || 'auto'};
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
`

export const BaseBigText = styled(BaseTypography)`
  font-size: ${({ fontSize }) => fontSize || '25px'};
  line-height: ${({ lineHeight }) => lineHeight || '32px'};

  ${theme.breakpoints.down('md')} {
    font-size: ${({ fontSize }) => fontSize || '19.1px'};
    line-height: ${({ lineHeight }) => lineHeight || '28px'};
  }
`

export const BaseMediumText = styled(BaseTypography)`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '24px'};

  ${theme.breakpoints.down('md')} {
    font-size: ${({ fontSize }) => fontSize || '12.8px'};
    line-height: ${({ lineHeight }) => lineHeight || '20px'};
  }
`

export const BaseSmallText = styled(BaseTypography)`
  font-size: ${({ fontSize }) => fontSize || '12.8px'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};

  ${theme.breakpoints.down('md')} {
    font-size: ${({ fontSize }) => fontSize || '11px'};
    line-height: ${({ lineHeight }) => lineHeight || '16px'};
  }
`
