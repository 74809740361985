import React from 'react'
import { isBoolean } from 'lodash'
import { formatDate, getTranslatedCridStatus } from '~/utils'

import { Modal as ModalComponent } from '~/components/Modal'
import { Text } from '~/themes/styles'

import { Section, Title, SubSection, SingleOption, DateText, IsaFooterSection, IsaFooterDatesSection } from './styles'
import { useDetailedUpdates } from './hooks'
import masks from '~/themes/masks'

export const DetailedUpdatesModal = (props) => {
  const { handleModal, show, updatesData } = props
  const [data] = useDetailedUpdates(updatesData)
  const getFallbackIsaText = () => {
    if (data.resumeStatus === 'denied')
      return 'Para ser aprovada com a Provi, o aluno deve ser maior de idade, estar com o CPF regular, e adimplente em outros financiamentos. Nesse caso, não foi cumprido um dos critérios dessa avaliação e por isso está negado'

    if (!data.isa.hasChosenCourse) return 'Estudante ainda não escolheu o curso'

    return 'O curso selecionado pelo aluno não tem turmas associadas. Crie uma turma ou entre em contato com a gente!'
  }

  return (
    <>
      {show && data && (
        <ModalComponent isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('updates')}>
          {data.warningMessage && (
            <>
              <Title>{data.warningMessage}</Title>
            </>
          )}
          {data.isProviPay && (
            <>
              <Title>{data.proviPayMessage}</Title>
            </>
          )}

          {data.isIsa && data.isa && (
            <>
              {data.isa.status && (
                <>
                  <Text size="20px" bold>
                    Análise de Crédito e Comportamental:
                  </Text>
                  <Text size="24px" bold color={data.isa.statusColor} mBottom="20px">
                    {data.isa.status}
                  </Text>
                  <Text size="16px" mBottom="50px">
                    Caso você ainda não tenha informado a aprovação ou reprovação do aluno, por favor entre em contato conosco.
                  </Text>
                </>
              )}

              {data.isa.comment && (
                <>
                  <Text size="18px" bold mBottom="5px">
                    Comentário Provi
                  </Text>
                  <Text size="16px" mBottom="50px">
                    {data.isa.comment}
                  </Text>
                </>
              )}

              {data.isa.startsAt && data.isa.endsAt ? (
                <IsaFooterSection>
                  <IsaFooterDatesSection>
                    <Text size="18px" bold>
                      Início do curso:
                    </Text>
                    <Text size="16px">{data.isa.startsAt}</Text>
                  </IsaFooterDatesSection>
                  <IsaFooterDatesSection>
                    <Text size="18px" bold>
                      Final do curso:
                    </Text>
                    <Text size="16px">{data.isa.endsAt}</Text>
                  </IsaFooterDatesSection>
                </IsaFooterSection>
              ) : (
                <Text>{getFallbackIsaText()}</Text>
              )}
            </>
          )}

          {data.basicInfo && !data.isProviPay && (
            <Section>
              <Title>
                Informações básicas{' '}
                <Text color={data.basicInfo.status.color} display="inline-block" bold>
                  {getTranslatedCridStatus(data.basicInfo.status.title)}
                </Text>{' '}
              </Title>

              <SubSection>
                {data.basicInfo.review &&
                  data.basicInfo.review.length &&
                  data.basicInfo.review.map((review, index) => (
                    <>
                      {index === 0 && (
                        <>
                          {Object.keys(review.messages).length &&
                            Object.keys(review.messages).map((message) => (
                              <>
                                {!!review.messages[message].length && (
                                  <SingleOption key={Date.now()}>
                                    {getTranslatedCridStatus(message)}:{' '}
                                    {review.messages[message].map((text) => (
                                      <Text mLeft={'15px'} key={Date.now()}>
                                        - {text}
                                      </Text>
                                    ))}
                                  </SingleOption>
                                )}
                              </>
                            ))}

                          <DateText>Data do último envio -{formatDate(review.updatedAt, true)}</DateText>
                        </>
                      )}
                    </>
                  ))}
              </SubSection>
            </Section>
          )}

          {data.documentsInfo && !data.isProviPay && (
            <Section>
              <Title>
                Documentos{' '}
                <Text color={data.documentsInfo.status.color} display="inline-block" bold>
                  {getTranslatedCridStatus(data.documentsInfo.status.title)}
                </Text>{' '}
              </Title>
              <SubSection>
                {data.documentsInfo.documentsSent &&
                  data.documentsInfo.status.title !== 'review' &&
                  Object.keys(data.documentsInfo.documentsSent).length &&
                  Object.keys(data.documentsInfo.documentsSent).map((document) => (
                    <>
                      <SingleOption>
                        {getTranslatedCridStatus(document, data.documentsInfo.isDelayed)}
                        <Text mLeft={'15px'} display="inline-block">
                          {data.documentsInfo.documentsSent[document].isValid ? 'Enviado' : 'Não enviado'}{' '}
                          {data.documentsInfo.documentsSent[document].updatedAt && (
                            <DateText> - {formatDate(data.documentsInfo.documentsSent[document].updatedAt, true)} </DateText>
                          )}
                        </Text>
                      </SingleOption>
                    </>
                  ))}
              </SubSection>
              {data.documentsInfo.review &&
                data.documentsInfo.review.length &&
                data.documentsInfo.review.map((review, index) => (
                  <>
                    {index === 0 && (
                      <>
                        <DateText>Data do último envio de documento - {data.documentsInfo.documentsSentLastUpdate}</DateText>
                        <DateText>Data da última revisão - {formatDate(review.updatedAt, true)}</DateText>

                        {Object.keys(review.messages).length &&
                          Object.keys(review.messages).map((message) => (
                            <>
                              {!!review.messages[message].length && (
                                <SingleOption key={Date.now()}>
                                  {getTranslatedCridStatus(message)}:{' '}
                                  {review.messages[message].map((text) => (
                                    <Text mLeft={'15px'} key={Date.now()}>
                                      {text}
                                    </Text>
                                  ))}
                                </SingleOption>
                              )}
                            </>
                          ))}
                      </>
                    )}
                  </>
                ))}
            </Section>
          )}

          {data.guarantorInfo && !data.isProviPay && (
            <Section>
              <Title>
                Avalista{' '}
                <Text color={data.guarantorInfo.status.color} display="inline-block" bold>
                  {getTranslatedCridStatus(data.guarantorInfo.status.title)}
                </Text>{' '}
              </Title>

              <SubSection>
                {data.guarantorInfo.basicInfoStatus && data.guarantorInfo.status.title !== 'review' && (
                  <SingleOption>
                    Status das informações básicas:
                    <Text mLeft={'15px'} display="inline-block">
                      {getTranslatedCridStatus(data.guarantorInfo.basicInfoStatus)}
                    </Text>
                  </SingleOption>
                )}

                {data.guarantorInfo.documentsSent &&
                  data.guarantorInfo.status.title !== 'review' &&
                  Object.keys(data.guarantorInfo.documentsSent).length &&
                  Object.keys(data.guarantorInfo.documentsSent).map((document) => (
                    <>
                      <SingleOption>
                        {getTranslatedCridStatus(document, data.guarantorInfo.isDelayed)}
                        <Text mLeft={'15px'} display="inline-block">
                          {data.guarantorInfo.documentsSent[document].isValid ? 'Enviado' : 'Não enviado'}{' '}
                        </Text>{' '}
                        {data.guarantorInfo.documentsSent[document].updatedAt && (
                          <DateText> - {formatDate(data.guarantorInfo.documentsSent[document].updatedAt, true)} </DateText>
                        )}
                      </SingleOption>
                    </>
                  ))}

                {data.guarantorInfo.review &&
                  data.guarantorInfo.review.length &&
                  data.guarantorInfo.review.map((review, index) => (
                    <>
                      {index === 0 && (
                        <>
                          <DateText>Data do último envio de documento - {data.guarantorInfo.documentsSentLastUpdate}</DateText>
                          <DateText>Data da última revisão - {formatDate(review.updatedAt, true)}</DateText>

                          {Object.keys(review.messages).length &&
                            Object.keys(review.messages).map((message) => (
                              <>
                                {!!review.messages[message].length && (
                                  <SingleOption key={Date.now()}>
                                    {getTranslatedCridStatus(message)}:{' '}
                                    {review.messages[message].map((text) => (
                                      <Text mLeft={'15px'} key={Date.now()}>
                                        {text}
                                      </Text>
                                    ))}
                                  </SingleOption>
                                )}
                              </>
                            ))}
                        </>
                      )}
                    </>
                  ))}

                {data.guarantorInfo.guarantorAttempts &&
                  data.guarantorInfo.guarantorAttempts.length &&
                  data.guarantorInfo.guarantorAttempts.map((guarantorAttempt) => (
                    <SingleOption key={guarantorAttempt.CPF}>
                      CPF: {masks.cpf(guarantorAttempt.CPF)}{' '}
                      <Text display="inline-block">- {guarantorAttempt.active ? 'Aprovado' : 'Não aprovado'} </Text>
                    </SingleOption>
                  ))}
              </SubSection>
            </Section>
          )}

          {data.signedInfo && !data.isProviPay && (
            <Section>
              <Title>
                Assinatura{' '}
                <Text color={data.signedInfo.status.color} display="inline-block" bold>
                  {getTranslatedCridStatus(data.signedInfo.status.title)}
                </Text>{' '}
              </Title>

              <SubSection>
                {isBoolean(data.signedInfo.userSigned) && (
                  <SingleOption>
                    Assinatura comprador
                    <Text mLeft={'15px'} display="inline-block">
                      {data.signedInfo.userSigned ? 'Assinado' : 'Não assinado'}
                    </Text>
                  </SingleOption>
                )}

                <SingleOption>
                  Tem avalista?
                  <Text mLeft={'15px'} display="inline-block">
                    {data.signedInfo.hasGuarantor ? 'Sim' : 'Não'}
                  </Text>
                </SingleOption>

                {data.signedInfo.hasGuarantor && (
                  <SingleOption>
                    Assinatura Avalista
                    <Text mLeft={'15px'} display="inline-block">
                      {data.signedInfo.guarantorSigned ? 'Assinado' : 'Não assinado'}
                    </Text>
                  </SingleOption>
                )}
              </SubSection>
            </Section>
          )}
        </ModalComponent>
      )}
    </>
  )
}
