import React from 'react'

export const CalendarIcon = ({ onClick, size, color }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width={size || '20'}
    height={size || '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 1V4M5 1V4M1 8H19M1.81818 3H18.1818C18.6337 3 19 3.32561 19 3.72727V18.2727C19 18.6744 18.6337 19 18.1818 19H1.81818C1.36631 19 1 18.6744 1 18.2727V3.72727C1 3.32561 1.36631 3 1.81818 3Z"
      stroke={color || 'black'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
