import React from 'react'

export const WrenchIcon = ({ onClick, bluePrimary }) => (
  <svg
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3421 1.47587L11.4082 5.40982L11.9385 8.06149L14.5902 8.59182L18.5241 4.65787L18.5245 4.65771C19.0114 5.80712 19.1279 7.07975 18.8577 8.29844C18.5874 9.51714 17.944 10.6213 17.0169 11.4571C16.0897 12.293 14.9251 12.819 13.685 12.962C12.4449 13.1049 11.1911 12.8577 10.0981 12.2547L10.0982 12.2545L4.84011 18.3419C4.41807 18.7635 3.84589 19.0002 3.24938 19C2.65286 18.9998 2.08082 18.7628 1.65901 18.341C1.23721 17.9192 1.00017 17.3471 1 16.7506C0.99983 16.1541 1.23655 15.5819 1.65811 15.1599L7.74546 9.90184L7.74534 9.90191C7.14233 8.80892 6.89509 7.55512 7.03803 6.31503C7.18098 5.07495 7.70699 3.91028 8.54286 2.98315C9.37873 2.05602 10.4829 1.41256 11.7016 1.14233C12.9203 0.872105 14.1929 0.988561 15.3423 1.47549L15.3421 1.47587Z"
      stroke={bluePrimary ? '#2647D7' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
