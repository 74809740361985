import styled from 'styled-components'

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* overflow: hidden; */
  /* overflow-y: scroll; */
  transition: padding 0.5s;
`
