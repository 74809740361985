import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { getInstallmentPayments } from '~/services/api'

/**
 * @typedef {Object} paramsObject
 * @property {Number} InstallmentId
 * @property {Number} CreditRequestId
 * @property {Function} setIsLoading
 */

/**
 * @description hooks of installment-value-modal
 * @param {paramsObject} data
 * @returns {Object}
 */
export const useInstallmentValue = ({ InstallmentId, CreditRequestId, setIsLoading }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true)
        const { data } = await getInstallmentPayments({ InstallmentId, CreditRequestId })
        const { payments } = data

        setData(payments)
      } catch (error) {
        showToast('Ocorreu um erro ao trazer os pagamentos dessa parcela')
      } finally {
        setIsLoading(false)
      }
    }

    getData()
  }, [])

  return { data }
}
