import { showToast } from '@provi/provi-components'

export const usePaymentMethods = (paymentMethods, setPaymentMethods) => {
  const handlePaymentMethods = (name, selected, available) => {
    if (!available) {
      showToast('Opção indisponível')
      return
    }
    const newPaymentMethods = paymentMethods.map((method) => ({
      ...method,
      selected: method.name === name ? !selected : method.selected,
    }))

    setPaymentMethods(newPaymentMethods)
  }

  return [handlePaymentMethods]
}
