import React from 'react'
import { FallbackContainer, StyledText } from './styles'

export const TableFallback = ({ text, bgColor, marginTop, maxWidth }) => {
  return (
    <FallbackContainer bgColor={bgColor} marginTop={marginTop} maxWidth={maxWidth}>
      <StyledText>{text}</StyledText>
    </FallbackContainer>
  )
}
