/* eslint-disable react/prop-types */
import React from 'react'

import { Modal as ModalComponent } from '~/components/Modal'
import { formatCoursesText } from '~/components/table/helpers/formatCoursesText'

import { Product, ProductTitle, ProductText } from './styles'

export const ProductModal = (props) => {
  const { handleModal, show, ProductData } = props

  return (
    <>
      {show && ProductData && (
        <ModalComponent isWithoutHeight width="630px" radius={5} onDismiss={() => handleModal('product')}>
          {ProductData.courses.map((product, index) => (
            <Product key={index}>
              <ProductTitle>Produto {index + 1}:</ProductTitle>
              <ProductText>
                {typeof product === 'object' && product !== null ? formatCoursesText(product) : product}
              </ProductText>
            </Product>
          ))}
        </ModalComponent>
      )}
    </>
  )
}
