import React from 'react'

import { SpanContainer } from './styles'

function LenghtSpan({ length, lengthLimit }) {
  return (
    <SpanContainer className="length-span">
      <span className="length-span__text">{length}</span>/<span className="length-span__text">{lengthLimit}</span>
    </SpanContainer>
  )
}

export { LenghtSpan }
