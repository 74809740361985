import React from 'react'

export const InputIcon = () => (
  <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M15 1H18C20.76 1 23 3.24 23 6C23 8.76 20.76 11 18 11H15M9 11H6C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1H9M8 6H16"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
