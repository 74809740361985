import React, { forwardRef } from 'react'
import { ButtonContainer, ButtonWrapper } from './styles'

/**
 * @typedef {object} ButtonProps
 * @property {string} text
 * @property {'primary'|'light' | 'white'} [color='primary']
 * @property {string} [width='184px']
 * @property {string} [mobileWidth='148px']
 * @property {string} [height='48px']
 * @property {boolean} [disabled=false]
 * @property {boolean} [icon=false]
 * @property {object} [buttonProps={}}]
 */
export const Button = forwardRef((props, ref) => {
  const {
    onClick = () => {},
    text = '',
    color = 'primary',
    width = '184px',
    mobileWidth = '148px',
    height = '48px',
    disabled = false,
    icon = false,
    buttonProps = {},
    ...rest
  } = props

  return (
    <ButtonContainer
      colorType={color}
      buttonWidth={width}
      mobileWidth={mobileWidth}
      buttonHeight={height}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      {...rest}
    >
      <ButtonWrapper
        variant="contained"
        endIcon={icon}
        disableElevation
        disableFocusRipple
        disabled={disabled}
        ref={ref}
        {...buttonProps}
      >
        {text}
      </ButtonWrapper>
    </ButtonContainer>
  )
})
