const initialMobile = [
  { item: 'id', label: 'ID', show: true },
  { item: 'cpf', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', show: true },
  { item: 'installmentType', label: 'Tipo da parcela', show: true },
  { item: 'paidWith', label: 'Método de pagamento', show: true },
  { item: 'installmentValue', label: 'Valor da parcela (R$)', show: true },
  { item: 'userPaidValue', label: 'Valor pago pelo aluno', show: true },
  { item: 'proviComission', label: 'Comissão Provi (R$)', show: true },
  { item: 'proviPercentComission', label: 'Comissão Provi (%)', show: true },
  { item: 'receivedValue', label: 'Valor recebido (R$)', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true },
  { item: 'dueDate', label: 'Vencimento da parcela', show: true },
  { item: 'userPaymentDate', label: 'Data de pagamento', show: true },
  { item: 'expectedCompensationDate', label: 'Data prevista para saldo disponível ', show: true },
  { item: 'compensationDate', label: 'Data do saque', show: true },
]

const initial = [
  { item: 'id', label: 'ID', show: true },
  { item: 'cpf', label: 'CPF', show: true },
  { item: 'name', label: 'Nome', show: true },
  { item: 'email', label: 'E-mail', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'products', label: 'Produto', isHelper: true, show: true, isFilter: true },
  { item: 'installmentType', label: 'Tipo da parcela', show: true },
  { item: 'paidWith', label: 'Método de pagamento', show: true, isFilter: true },
  { item: 'installmentValue', label: 'Valor da parcela (R$)', show: true },
  { item: 'userPaidValue', label: 'Valor pago pelo aluno', isHelper: true, show: true },
  { item: 'proviComission', label: 'Comissão Provi (R$)', isHelper: true, show: true },
  { item: 'proviPercentComission', label: 'Comissão Provi (%)', isHelper: true, show: true },
  { item: 'receivedValue', label: 'Valor recebido (R$)', isHelper: true, show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', isHelper: true, show: true },
  { item: 'dueDate', label: 'Vencimento da parcela', show: true },
  { item: 'userPaymentDate', label: 'Data de pagamento', show: true }
]

const mobileArrWithOrigin = initialMobile.map((eachObj) => ({ ...eachObj, tableOrigin: 'Movimentações financeiras' }))
const initialArrWithOrigin = initial.map((eachObj) => ({ ...eachObj, tableOrigin: 'Movimentações financeiras' }))

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return mobileArrWithOrigin
  }

  return initialArrWithOrigin
}
