import React, { useState, useCallback, useMemo } from 'react'
import { Popover as MUIPopover } from '@material-ui/core'
import { PopoverText, PopoverWrapper } from './styles'

export const Popover = ({ children, text, insideForm }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const anchorOrigin = useMemo(() => {
    return !insideForm
      ? {
          vertical: 'bottom',
          horizontal: 'center',
        }
      : {
          vertical: 'top',
          horizontal: 'right',
        }
  }, [insideForm])

  const transformOrigin = useMemo(() => {
    return !insideForm
      ? {
          vertical: 'top',
          horizontal: 'center',
        }
      : {
          vertical: 'bottom',
          horizontal: 'left',
        }
  }, [insideForm])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <PopoverWrapper onClick={handleClick}>{children}</PopoverWrapper>
      <MUIPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <PopoverText insideForm={insideForm}>{text}</PopoverText>
      </MUIPopover>
    </>
  )
}
