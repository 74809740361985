import styled from 'styled-components'
import { VictoryChart, VictoryBar, VictoryTheme, VictoryAxis } from 'victory'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress'
import Switch from 'react-switch'
import { theme } from '@provi/provi-components'
import Select from 'react-select'
import { fonts } from '~/themes/fonts'

const tickLabelsDefaultStyle = {
  fontSize: 12,
  fill: theme.colors.blue900,
}

export const ModalContent = styled.div`
  margin-top: -20px;
`

export const ModalText = styled.h1`
  font-family: ${fonts.century.light};
  font-size: 18px;
  strong {
    font-family: ${fonts.century.bold};
  }
`

export const ChartContainer = styled(VictoryChart).attrs({
  theme: VictoryTheme.material,
  height: 600,
  width: 1280,
  alignment: 'start',
  animate: {
    duration: 0,
    onLoad: { duration: 0 },
  },
  responsive: true,
})``

export const Axis = styled(VictoryAxis).attrs({
  style: {
    tickLabels: {
      ...tickLabelsDefaultStyle,
      fontFamily: 'CenturyGothic',
      fontSize: 11,
    },
  },
})``

export const DependentAxis = styled(VictoryAxis).attrs({
  style: {
    tickLabels: {
      ...tickLabelsDefaultStyle,
      fontFamily: 'CenturyGothicBold',
    },
  },
})``

export const BarChart = styled(VictoryBar).attrs({
  barRatio: 1,
  cornerRadius: 5,
  alignment: 'middle',
  width: 100,
  style: {
    data: {
      fill: 'url(#chartGradient)',
      width: 80,
    },
    labels: {
      ...tickLabelsDefaultStyle,
      fontSize: 14,
    },
  },
})``

export const SeePaymentsRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  justify-content: flex-end;
`

export const SeePaymentsButton = styled.a`
  cursor: pointer;
  font-family: CenturyGothicBold;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${theme.colors.blue900};
  margin-right: 50px;
`

export const PopoverText = styled.p`
  font-family: CenturyGothicBold;
  font-size: 18px;
  color: ${theme.colors.white100};
  font-weight: bold;
`

export const SwitchLabel = styled.p`
  font-family: CenturyGothicBold;
  font-size: 12px;
  line-height: 24px;
  color: ${(props) => (props.checked ? theme.colors.blue900 : theme.colors.grey600)};
`

SwitchLabel.defaultProps = {
  checked: false,
}

SwitchLabel.propTypes = {
  checked: PropTypes.bool,
}

export const StyledSwitchWrapper = styled.div`
  max-width: 200px;
  width: 100%;
  height: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledSwitch = styled(Switch).attrs({
  checkedIcon: false,
  uncheckedIcon: false,
  width: 61,
  height: 27,
  onColor: theme.colors.green100,
  offColor: theme.colors.blue600,
})``

const inputsWidth = 600

const colorsDictionary = {
  success: theme.colors.green100,
  info: theme.colors.blue600,
  default: theme.colors.blue900,
}

export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  span {
    font-family: CenturyGothicBold;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${theme.colors.red100};
  }
`

export const Card = styled.div`
  width: 100%;
  margin-top: 50px;
  box-shadow: 0px 0px 15px rgba(0, 26, 91, 0.1);
  border-radius: 10px;
  background-color: ${theme.colors.white100};
  padding-bottom: 40px;
`

export const CardTitle = styled.h1`
  font-family: CenturyGothicBold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${theme.colors.blue600};
`

export const CardSeparatorContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const CardSeparator = styled.div`
  border: 1px solid ${theme.colors.grey500};
  width: 98%;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  padding: 12px 25px;
  a {
    cursor: pointer;
    font-family: CenturyGothicBold;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${theme.colors.blue900};
  }
`

export const SectionTitle = styled.h1`
  display: flex;
  align-items: center;
  font-family: CenturyGothicBold;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isMain }) => (isMain ? '24px;' : '20px;')};
  color: ${theme.colors.blue900};
  span {
    margin-left: 10px;
    font-family: CenturyGothicBold;
    font-style: normal;
    font-size: 18px;
    color: ${theme.colors.blue600};
  }
`

export const ChartSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  p {
    width: 100%;
    text-align: center;
    font-family: CenturyGothic;
    font-style: normal;
    font-size: 16px;
    color: ${theme.colors.blue900};
  }
`

export const SectionData = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const SectionDataValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 200px;
  p {
    font-family: CenturyGothicBold;
    font-style: normal;
    font-size: 16px;
    color: ${theme.colors.grey600};
  }
  span {
    font-family: CenturyGothicBold;
    font-style: normal;
    font-size: 20px;
    color: ${(props) => colorsDictionary[props.variant]};
  }
`

SectionDataValue.propTypes = {
  variant: PropTypes.oneOf(['default', 'info', 'success']),
}

SectionDataValue.defaultProps = {
  variant: 'default',
}

export const InformativeSectionFunnel = styled.div`
  padding-top: 30px;
  width: 100%;
`

export const CardSection = styled.div`
  padding: 12px 25px;
  width: 100%;
  ${({ isBlue }) => isBlue && 'background: rgba(92, 181, 254, 0.15);'}
`

export const SalesFunnelContainerWithIntersection = styled.div`
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: -37px;
  flex-direction: row;
  align-items: center;
`

export const IntersectionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  margin-top: -60px;
  width: 20px;
`

export const SplittedArrowIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  margin-bottom: -10px;
  width: 20px;
`

export const ArrowsDownContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: -90px;
  width: 50%;
`

export const SalesFunnelItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
`

export const SalesFunnelDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${theme.colors.red100};
    font-size: 20px;
    font-family: CenturyGothicBold;
  }
  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 470px;
  }
  h2 {
    color: ${theme.colors.blue900};
    font-size: 16px;
    font-family: CenturyGothicBold;
    margin-bottom: -20px;
  }
  p {
    color: ${theme.colors.blue600};
    font-size: 24px;
    font-family: CenturyGothicBold;
  }
`

export const SalesFunnelContainerCentered = styled.div`
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -37px;
  h1 {
    color: ${theme.colors.blue900};
    font-size: 18px;
    font-family: CenturyGothicBold;
    margin-bottom: -20px;
  }
  p {
    color: ${theme.colors.blue600};
    font-size: 30px;
    font-family: CenturyGothicBold;
  }
`

export const SalesFunnelPill = styled.div`
  width: 97px;
  height: 35px;
  background: #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  span {
    font-size: 14px;
    color: ${theme.colors.white100};
    font-family: CenturyGothicBold;
    margin-left: 5px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
`

export const TitleBar = styled.div`
  font-family: CenturyGothicBold;
  font-style: normal;
  font-size: 32px;
  color: ${theme.colors.blu900};
  margin-left: 27px;
`

export const SelectorWrapper = styled.div`
  margin-right: 20px;
  max-width: ${inputsWidth}px;
`

export const DatePickerButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  min-width: 196px;
  max-width: 270px;
  height: 42px;
  display: flex;
  background-color: ${theme.colors.white100};
  flex-direction: row;
  align-items: center;
  padding: 0px 11px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  p {
    font-family: CenturyGothicBold, sans serif;
    color: ${theme.colors.blue800};
    font-size: 16px;
    margin-left: 25px;
  }
`

export const Selector = styled(Select).attrs((props) => ({
  inputProps: { 'aria-label': { name: props.name } },
  hideSelected: true,
  styles: {
    multiValue: (provided) => ({
      ...provided,
      maxWidth: 150,
    }),
    option: () => ({
      padding: 5,
      height: 35,
      fontSize: 14,
      paddingLeft: 13,
      color: theme.colors.blue800,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey500,
      },
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${theme.colors.blue800}`,
      borderRadius: 5,
      height: 42,
      fontFamily: 'CenturyGothic, sans serif',
      cursor: 'pointer',
      minWidth: 250,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      lineHeight: 34,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.blue800,
      fontSize: 16,
      fontFamily: 'CenturyGothic, sans serif',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.pink900,
      marginRight: 8,
    }),
    placeholder: () => ({
      fontFamily: 'CenturyGothic, sans serif',
      color: theme.colors.grey600,
      fontSize: 16,
    }),
    indicatorSeparator: () => ({}),
    menuList: (menuList) => ({
      ...menuList,
      padding: 0,
      zIndex: 100,
    }),
    menu: (provided) => ({
      ...provided,
      borderCollapse: 'separate',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'CenturyGothic, sans serif',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 16px',
      fontSize: 20,
      flexWrap: 'nowrap',
      flexDirection: 'row',
    }),
  },
}))``

export const RequestStatusTitle = styled.h1`
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: CenturyGothicBold;
  font-size: 24px;
  color: ${theme.colors.blue900};
  strong {
    margin-left: 10px;
    color: ${theme.colors.blue600};
  }
  span {
    margin-left: 10px;
    font-family: CenturyGothic;
    font-size: 12px;
    color: ${theme.colors.blue900};
  }
  p {
    margin-left: 10px;
    font-family: CenturyGothic;
    font-size: 18px;
    color: ${theme.colors.blue900};
  }
`

export const Wrapper = styled.div`
  background-color: #f3f3f3;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
`

const drawerWidthFunction = (drawerWidth) => {
  return `${drawerWidth} + 30px`
}

export const Header = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 10px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cfcfcf;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? drawerWidthFunction(drawerWidth) : 0)};
`

export const HeaderTitle = styled.h1`
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
`
export const About = styled.p`
  font-weight: 500;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
`

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ListButton = styled.div`
  background: ${theme.colors.grey400};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  margin-right: 10px;
  font-family: 'CenturyGothicBold';
  &:hover {
    opacity: 0.9;
  }
`

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    justify-content: center;
  }
`

export const BoxButton = styled.div`
  height: inherit;
  background-color: ${({ active }) => (active ? theme.colors.pink900 : theme.colors.blue800)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  border-radius: 20px;
  padding: 0 15px;
  font-family: ${({ active }) => (active ? 'CenturyGothicBold' : 'CenturyGothic')};
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey400};
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
  ${theme.breakpoints.down('md')} {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    padding: unset;

    svg {
      margin-right: unset;
    }
  }
`

export const CalendarWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`

export const View = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1150px;
`

export const InformativeText = styled.span`
  font-family: CenturyGothic;
  font-size: 16px;
  color: ${theme.colors.blue800};
`

export const statusColors = {
  success: theme.colors.green100,
  warn: theme.colors.yellow900,
  danger: '#FC7900',
}

export const RequestProgress = styled(Line).attrs((props) => ({
  strokeColor: statusColors[props.status],
  trailColor: '#EDEDED',
  strokeWidth: '1.2',
  trailWidth: '1.2',
}))``

RequestProgress.propTypes = {
  status: PropTypes.oneOf(['success', 'warn', 'danger']).isRequired,
}

export const RequestProperties = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  p {
    font-family: CenturyGothicBold;
    font-style: normal;
    font-size: 14px;
    color: #8b8888;
    margin-right: 20px;
  }
`

export const ArrowWrapper = styled.div`
  cursor: pointer;
`

export const PopoverWrapper = styled.div`
  display: flex;
  align-items: center;
`
