import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IProgressProps {
  bgColor?: string
}

interface ISubMessageProps {
  isFollowedByInput: boolean
}

interface IFormProps {
  hasErrors: boolean
}

interface IButtonSubmit {
  hasErrors: boolean
}

interface IFormatClassButton {
  isSelected: boolean
}

interface IFlex {
  flexColumn: boolean
}

export const SubMessage = styled.span<ISubMessageProps>`
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #575757;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
  }

  .submessage__font {
    font-size: 1.15em;
  }

  ${(props) => props.isFollowedByInput && `margin: .5em 0 1em 0;`}
`

export const ProgressBar = styled.progress<IProgressProps>`
  width: 100%;

  margin: 8px 0px;
`

export const ButtonSubmit = styled.button<IButtonSubmit>`
  padding: 10px 40px;
  background: ${(props) => (props.hasErrors ? `#575757` : `#2647d7`)};
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: center;
  color: ${(props) => (props.hasErrors ? `#575757` : `#fff`)};
  border: 0;
  border-radius: 4px;
  transition: 0.2s filter;

  & svg {
    border: 2px solid ${(props) => (props.hasErrors ? `#575757` : `#2647d7`)};
  }

  &:hover {
    filter: brightness(0.9);
  }
`

export const Form = styled.form<IFormProps>`
  button {
    cursor: pointer;
  }

  .footer__button--actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .footer__button--cancel {
      border: 0;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: center;
      color: #2647d7;
      background: transparent;
      transition: 0.2s text-decoration;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .has__checkbox {
    label {
      span:last-of-type {
        &::after {
          left: 7.8px;
          top: 5px;
          transform: rotate(45deg) scale(1.5);
        }
      }
    }
  }

  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    max-width: 100%;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .input__number--withSpan {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0075em;
    text-align: left;

    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }

    display: flex;
    align-items: center;

    > div {
      width: 20em;
    }

    span {
      margin-left: 15px;
    }
  }

  input[type='text'],
  input[type='number'] {
    > label {
      margin-bottom: 10px;
    }

    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.0075em;
      text-align: left;
      color: #767676;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  input[type='text'] {
    width: 85vw;
  }

  input[type='number'] {
    width: 20vw;
    margin-right: 25px;
  }

  .input__date {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    @media screen and (min-width: 542px) {
      & > div {
        width: 15em !important;
        height: 10em !important;
        margin: 0 20px 0 0 !important;
      }
    }

    & > div {
      margin-top: 15px;
      width: 100%;
      height: 8em;

      &.date__input--container {
        &:nth-of-type(2) {
          margin: 10px 0;
        }
      }
    }
  }

  .input__deadline--access {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      display: flex;
      margin-bottom: 20px;

      & > div {
        width: 27em;
        margin-right: 15px;

        label {
          max-width: 100%;
        }

        & div.select-menu {
          background-color: white;
        }

        & div.is__disabled {
          & > div:first-of-type {
            background-color: white;
            border: 2px solid #cfcfcf;
            cursor: not-allowed;
          }
        }
      }

      & div:first-of-type {
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 800px) {
      & > div {
        margin-right: 15px;

        label {
          max-width: 100%;
        }
      }
    }

    @media screen and (max-width: 574px) {
      & {
        flex-wrap: wrap;
      }

      & > div {
        width: 27em;
      }
    }
  }

  .class__location {
    flex-direction: column;
    align-items: start;
    width: 40em;

    > div {
      width: 40em;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 663px) {
      & {
        width: 100%;
      }

      > div {
        width: 100%;
      }
    }

    .class__location--add {
      cursor: pointer;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: center;
      color: ${theme.colors.bluePrimary};
      text-decoration: none;

      margin-left: auto;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .classroom__period--div {
    gap: 1.5rem;

    ${theme.breakpoints.down('md')} {
      gap: 0.5rem;
    }

    &.format__class {
      gap: 0.5rem;
    }

    button {
      margin: 15px 0 0 0;
    }
  }
`

export const Container = styled.section`
  width: 100%;
  max-width: 1200px;
`

export const InputGroupRadio = styled.div`
  padding: 15px 0;

  > div {
    display: flex;
    align-items: center;
  }

  input[type='radio'] {
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
  }

  input[type='radio']:checked + label {
    color: #2647d7;
  }

  .input__format {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;

    > label {
      margin-bottom: 15px;
    }

    & > div.format__class {
      gap: 0.35rem;
    }

    > div {
      flex-direction: row;
      margin-bottom: 25px;
      flex-wrap: wrap;

      & button {
        margin-right: 24px;

        @media screen and (max-width: 800px) {
          margin: 10px 0;
        }
      }
    }
  }
`

export const FormatClass = styled.button<IFormatClassButton>`
  font-family: 'Montserrat', sans-serif;

  height: 36px;
  width: 142px;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid ${(props) => (props.isSelected ? theme.colors.bluePrimary : theme.colors.bluePrimaryLight)};
  color: ${(props) => (!props.isSelected ? theme.colors.bluePrimary : '#fff')};
  background-color: ${(props) => (props.isSelected ? theme.colors.bluePrimary : 'transparent')};

  ${theme.breakpoints.down('md')} {
    width: 92px;
  }
`

export const InputError = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #b30142;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
  }
`

export const InputContainer = styled.div`
  display: grid;
  gap: 0.25rem;

  margin-bottom: 50px;
`

export const TextFlex = styled.div<IFlex>`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  ${({ flexColumn }) =>
    flexColumn &&
    css`
      flex-direction: column;
      align-items: revert;
    `}
`

export const InputFlex = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  div:first-of-type {
    width: 260px;

    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`
export const SpaceBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const VisibilityFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: revert;
  }
`
