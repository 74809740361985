export const dateOptions = [
  { value: 'paymentDate', label: 'Data de pagamento' },
  { value: 'createdAt', label: 'Data de criação' },
  { value: 'made_effective', label: 'Data de efetivação' },
]

export const translateStaticDateRangePicker = (string) => {
  const staticDateRangePickerFormatter = {
    Today: 'Hoje',
    Yesterday: 'Ontem',
    'This Week': 'Esta semana',
    'Last Week': 'Semana passada',
    'This Month': 'Este mês',
    'Last Month': 'Mês passado',
    fallback: '---',
  }

  return staticDateRangePickerFormatter[string] || staticDateRangePickerFormatter.fallback
}

export const translateInputDateRangePicker = (string) => {
  const inputDateRangePickerFormatter = {
    'days up to today': 'Dias até hoje',
    'days starting today': 'Dias começando hoje',
    fallback: '---',
  }

  return inputDateRangePickerFormatter[string] || inputDateRangePickerFormatter.fallback
}

export const translateDefaultRateType = (string) => {
  const defaultRateTypeFormatter = {
    nonPayment: 'Total de inadimplentes',
    inDebt: 'Em débito (menos de 45 dias)',
    negativated: 'Negativados (mais de 45 dias)',
    protested: 'Protestados (mais de 90 dias)',
    fallback: '',
  }

  return defaultRateTypeFormatter[string] || defaultRateTypeFormatter.fallback
}

export const checkIsaValueReducer = (isaDataObject) => {
  return Object.values(isaDataObject).reduce((accumulator, currentValue) => accumulator + currentValue) ? true : false
}
