import styled, { keyframes } from 'styled-components'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@provi/provi-components'

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const CustomSlider = withStyles({
  root: {
    color: `${theme.colors.blue900}`,
    height: 3,
    padding: '13px 0',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid #cfcfcf',
    marginTop: -9,
    marginLeft: -11,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &:active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    color: '#fff',
  },
})(Slider)

export const InputFile = styled.input`
  display: none;
`

export const WrapperImage = styled.div`
  display: grid;
  place-items: center;
  width: 90px;
  height: 90px;
  background: #c4c4c4;
  border-radius: 5%;
  cursor: pointer;
`

export const StyledImage = styled.img`
  max-width: 90px;
  max-height: 90px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CropperBackDrop = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background: rgba(0, 11, 60, 0.5);
  -webkit-animation-name: ${opacity};
  -webkit-animation-duration: 0.25s;
  animation: ${opacity} 0.25s ease-out;
`

export const CropperModal = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-auto-flow: row;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  :hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }
`

export const CropperHeader = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
`

export const CropperContainer = styled.div`
  max-width: 600px;
  min-height: 400px;
  position: relative;
  margin-bottom: 10px;

  .reactEasyCrop_Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reactEasyCrop_Image {
    will-change: transform;
  }

  .reactEasyCrop_Contain {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .reactEasyCrop_Cover_Horizontal {
    width: 100%;
    height: auto;
  }
  .reactEasyCrop_Cover_Vertical {
    width: auto;
    height: 100%;
  }

  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background: linear-gradient(to right, black 6px, transparent 6px) 0 0,
      linear-gradient(to right, black 6px, transparent 6px) 0 100%, linear-gradient(to left, black 6px, transparent 6px) 100% 0,
      linear-gradient(to left, black 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, black 6px, transparent 6px) 0 0,
      linear-gradient(to bottom, black 6px, transparent 6px) 100% 0, linear-gradient(to top, black 6px, transparent 6px) 0 100%,
      linear-gradient(to top, black 6px, transparent 6px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 25px 25px;
  }
`
export const CropperError = styled.p`
  color: ${theme.colors.pink900};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`

export const SliderWrapper = styled.div`
  margin: 0 auto 50px;
  width: 100%;
  max-width: 370px;
  display: grid;
  place-items: center;
  gap: 10px;
  grid-template-columns: 0.5fr 4fr 0.5fr;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 330px;
  display: grid;
  grid-auto-flow: column;
  gap: 25px;

  div {
    margin: initial;
    width: 150px;
    height: 40px;
  }

  .MuiButtonBase-root {
    padding: 5px 8px;
    width: 150px;
    height: 40px;
  }

  .MuiButton-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`
