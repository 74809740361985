/* eslint-disable react/prop-types */
import React from 'react'
import Select from 'react-select'
import { theme } from '@provi/provi-components'
import { View } from './styles'

const customStyles = () => ({
  option: () => ({
    padding: 5,
    height: 40,
    fontSize: 16,
    paddingLeft: 13,
    color: theme.colors.blue900,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey500,
    },
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  control: (provided) => ({
    ...provided,
    border: `1px solid ${theme.colors.grey600}`,
    borderRadius: 3,
    height: 42,
    fontFamily: 'Montserrat, sans serif',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.blue900,
    fontSize: 16,
    lineHeight: 24,
  }),
  input: (provided) => ({
    ...provided,
    color: theme.colors.blue900,
    fontSize: 16,
    fontFamily: 'Montserrat, sans serif',
    textOverflow: 'ellipsis',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.colors.pink900,
    marginRight: 8,
    '&:hover': {
      color: theme.colors.pink900,
    },
  }),
  placeholder: () => ({
    fontFamily: 'Montserrat, sans serif',
    color: theme.colors.grey600,
    fontSize: 14,
  }),
  indicatorSeparator: () => ({}),
  menuList: (menuList) => ({
    ...menuList,
    padding: 0,
    zIndex: 100,
  }),
  menu: (provided) => ({
    ...provided,
    borderCollapse: 'separate',
    border: '1px solid #323B69',
    boxSizing: 'border-box',
    borderRadius: 3,
    overflow: 'auto',
    fontFamily: 'Montserrat, sans serif',
    zIndex: 100,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 5px',
    fontSize: 20,
    flexWrap: 'nowrap',
    flexDirection: 'row',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
})

export const Selector = ({ options, value, onValueChange, name, width, ...props }) => (
  <View width={width}>
    <Select
      inputProps={{ 'aria-label': { name } }}
      noOptionsMessage={() => 'Sem opçōes'}
      styles={customStyles()}
      options={options}
      value={value}
      onChange={onValueChange}
      width={width}
      {...props}
    />
  </View>
)
