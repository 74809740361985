import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
`

export const SvgWrapper = styled.div`
  cursor: pointer;

  svg {
    transform: ${({ state }) => (state ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.6s ease;
  }
`

export const BlueHeader = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 24px;
  color: ${theme.colors.bluePrimary};
  font-size: 13px;
  font-weight: 600;

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
  }
`
