/* eslint-disable react/prop-types */

import React from 'react'
import { theme } from '@provi/provi-components'

import { Text } from '~/themes/styles'

import { Wrapper } from './styles'
import { useCpfResult } from './hooks'

export const CpfResult = ({ creditRule }) => {
  const [data] = useCpfResult(creditRule)

  return (
    <>
      {data && (
        <Wrapper color={data.color}>
          <Text size="16px" lineHeight="24px" bold align="center" color={data.color}>
            {data.title}
          </Text>
          <Text size="16px" lineHeight="24px" align="center" color={theme.colors.blue900}>
            {data.message}
          </Text>
        </Wrapper>
      )}
    </>
  )
}
