import styled from 'styled-components'

import { theme } from '@provi/provi-components'

export const Span = styled.span`
  color: ${theme.colors.blue600};
`

export const WrapperUpdate = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid ${theme.colors.grey600};
`
