export const bankOptions = [
  { value: '001', label: '1 - Banco do Brasil' },
  { value: '003', label: '3 - Banco da Amazonia' },
  { value: '004', label: '4 - Banco do Nordeste' },
  { value: '021', label: '21 - Banestes' },
  { value: '033', label: '33 - Santander' },
  { value: '041', label: '41 - Banrisul' },
  { value: '070', label: '70 - BRB' },
  { value: '077', label: '77 - Inter' },
  { value: '084', label: '84 - Uniprime Norte do Paraná' },
  { value: '085', label: '85 - Cooperativa Central de Crédito' },
  { value: '104', label: '104 - Caixa Econômica' },
  { value: '136', label: '136 - Unicred' },
  { value: '197', label: '197 - Stone' },
  { value: '208', label: '208 - Banco BTG Pactual' },
  { value: '212', label: '212 - Banco Original' },
  { value: '218', label: '218 - Banco BS2' },
  { value: '237', label: '237 - Bradesco' },
  { value: '260', label: '260 - Nu Pagamentos' },
  { value: '274', label: '274 - Money Plus' },
  { value: '290', label: '290 - Pagseguro' },
  { value: '336', label: '336 - C6 Bank' },
  { value: '341', label: '341 - Itaú' },
  { value: '389', label: '389 - Mercantil do Brasil' },
  { value: '403', label: '403 - Cora' },
  { value: '422', label: '422 - Safra' },
  { value: '633', label: '633 - Banco Rendimento' },
  { value: '655', label: '655 - Votorantim' },
  { value: '735', label: '735 - Neon' },
  { value: '745', label: '745 - Citibank' },
  { value: '746', label: '746 - Banco Modal' },
  { value: '748', label: '748 - Sicredi' },
  { value: '756', label: '756 - Sicoob' },
  { value: '133', label: '133 - Cresol' },
  { value: '323', label: '323 - Mercado Pago' },
]

export const accountOptions = [
  { value: 'checking', label: 'Conta Corrente' },
  { value: 'savings', label: 'Conta Poupança' },
]

export const bankAgencyAndAccountDigits = [
  { bank: 'Banco do Brasil', agencyDigits: '9999-D', accountDigits: '99999999-D' },
  { bank: 'Santander', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Caixa Econômica', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Bradesco', agencyDigits: '9999-D', accountDigits: '9999999-D' },
  { bank: 'Itaú', agencyDigits: '9999', accountDigits: '99999-D' },
  { bank: 'Banrisul', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Sicredi', agencyDigits: '9999', accountDigits: '999999D' },
  { bank: 'Sicoob', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Inter', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'BRB', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Neon', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Votorantim', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Nu Pagamentos', agencyDigits: '9999', accountDigits: '9999999999-D' },
  { bank: 'Pagseguro', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Banco Original', agencyDigits: '9999', accountDigits: '9999999-D' },
  { bank: 'Safra', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Banco Modal', agencyDigits: '9999', accountDigits: '999999999-D' },
  { bank: 'Banestes', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Unicred', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Mercantil do Brasil', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Banco BS2', agencyDigits: '9999', accountDigits: '999999-D' },
  { bank: 'Stone', agencyDigits: '9999', accountDigits: '9999999-D' },
  { bank: 'Banco Rendimento', agencyDigits: '9999-D', accountDigits: '9999999999' },
  { bank: 'Banco do Nordeste', agencyDigits: '999', accountDigits: '999999-D' },
  { bank: 'Citibank', agencyDigits: '9999', accountDigits: '99999999' },
  { bank: 'Uniprime Norte do Paraná', agencyDigits: '9999', accountDigits: '999999-D' },
  { bank: 'Banco da Amazonia', agencyDigits: '9999', accountDigits: '999999-D' },
  { bank: 'Cora', agencyDigits: '9999', accountDigits: '9999999-D' },
  { bank: 'C6 Bank', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Cooperativa Central de Crédito', agencyDigits: '9999', accountDigits: '9999999-D' },
  { bank: 'Banco BTG Pactual', agencyDigits: '9999', accountDigits: '9999999-D' },
  { bank: 'Money Plus', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Cresol', agencyDigits: '9999', accountDigits: '99999999-D' },
  { bank: 'Mercado Pago', agencyDigits: '9999', accountDigits: '9999999999-D' },
]

export const withdrawOptions = [
  {
    value: {
      auto: true,
      month: true,
    },
    label: 'Automático, todo dia 20',
  },
  {
    value: {
      auto: true,
      month: false,
    },
    label: 'Automático, diário',
  },
  {
    value: {
      auto: false,
      month: false,
    },
    label: 'Desativado',
  },
]
