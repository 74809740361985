import { useEffect, useState, useCallback, useMemo } from 'react'
import { useQueries } from 'react-query'
import { showToast } from '@provi/provi-components'
import { getStorage } from '~/utils'

import { getAccess, getProfileGeneralData, getProfileFinance, getProfileContacts } from '~/services/api'

export const useProfile = (setIsLoading, goToPage, pathName) => {
  const [accessLevel, setAccessLevel] = useState(null)
  const [generalNotifications, setGeneralNotifications] = useState(0)
  const [contactsNotifications, setContactsNotifications] = useState(0)
  const [financeNotifications, setFinanceNotifications] = useState(0)
  const partnerName = getStorage({ key: 'partner-name' })
  const minsStaleTime = 300000

  useEffect(() => {
    const getUserAccessLevel = async () => {
      setIsLoading(true)
      const {
        data: {
          data: { accessLevel: responseLevel },
        },
      } = await getAccess()
      setAccessLevel(responseLevel)
      setIsLoading(false)
    }
    getUserAccessLevel()
  }, [])

  useQueries([
    {
      queryKey: `${partnerName}-generalData`,
      queryFn: getProfileGeneralData,
      staleTime: minsStaleTime,
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
      },
      onSuccess: (data) => {
        if (data) {
          const {
            data: { campus: generalDataCampusArr, partner: generalDataPartnerObj },
          } = data

          let generalDataNotifications

          let partnerObjNumber = Object.values(generalDataPartnerObj).reduce((accumulator, eachDataValue) => {
            return accumulator + (!eachDataValue ? 1 : 0)
          }, 0)
          let campusArrNumber = generalDataCampusArr && generalDataCampusArr.length === 0 ? 1 : 0

          generalDataNotifications = partnerObjNumber + campusArrNumber

          setGeneralNotifications(generalDataNotifications)
        }
      },
    },
    {
      queryKey: `${partnerName}-contacts`,
      queryFn: getProfileContacts,
      staleTime: minsStaleTime,
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
      },
      onSuccess: (data) => {
        if (data) {
          const { data: contactsDataObj } = data

          let contactsNotificationsNumber

          let fullContactsObj =
            contactsDataObj &&
            Object.values(contactsDataObj).reduce((accumulator, eachContactValue) => {
              return accumulator + (!eachContactValue ? 1 : 0)
            }, 0)

          contactsNotificationsNumber =
            contactsDataObj && contactsDataObj.isIsaEnabled === false ? fullContactsObj - 2 : fullContactsObj

          setContactsNotifications(contactsNotificationsNumber)
        }
      },
    },
    {
      queryKey: [`${partnerName}-finance`, 'account'],
      queryFn: getProfileFinance,
      staleTime: minsStaleTime,
      enabled: accessLevel === 'superadmin',
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
      },
      onSuccess: (data) => {
        if (data) {
          const {
            data: {
              bankAccount: { account: accountDataObj },
            },
          } = data

          let financeNotificationsNumber

          financeNotificationsNumber = accountDataObj && Object.keys(accountDataObj).length ? 0 : 1

          setFinanceNotifications(financeNotificationsNumber)
        }
      },
    },
  ])

  const activeTab = useMemo(() => {
    setIsLoading(false)
    return pathName || null
  }, [pathName])

  const handleActiveTab = useCallback((tabPath) => {
    goToPage(`perfil/${tabPath}`)
  }, [])

  return {
    activeTab,
    handleActiveTab,
    accessLevel,
    generalNotifications,
    contactsNotifications,
    financeNotifications,
  }
}
