import React from 'react'
import { Text } from '~/themes/styles'
import { Section, Underline } from './styles'
import { InstallmentValueHelper, InlineBold } from './components'

const StatusHelper = () => {
  return (
    <Section>
      <Text bold mBottom="10px" size="18px">
        Definição dos status:
      </Text>
      <Text mBottom="5px">
        - <Underline>Pendente</Underline>: fatura do mês vigente está em aberto, mas ainda não venceu (pagamento em dia).
      </Text>
      <Text mBottom="5px">
        - <Underline>Inadimplente</Underline>: fatura(s) não foi(foram) paga(s) e a data de vencimento já passou (atraso no
        pagamento).
      </Text>
      <Text mBottom="5px">
        - <Underline>Pago</Underline>: fatura(s) paga(s). O pagamento está em dia até o momento vigente.
      </Text>
    </Section>
  )
}

export const useHelperModal = (helperModalData) => {
  const helperModalOptions = {
    status: <StatusHelper />,
    total_amount_receivable: (
      <Text>
        Valor a ser recebido pelo parceiro de acordo com a venda. Atente-se: esse valor pode ser alterado mediante cancelamento
        do CRID.
      </Text>
    ),
    total_amount_received: (
      <Text>Valor recebido de acordo com a venda. Esse valor foi transferido da Provi para o parceiro.</Text>
    ),
    tedDate: <Text>Data em que o valor foi repassado da Provi para o parceiro.</Text>,
    installment_value: <InstallmentValueHelper />,
    compensationDate: (
      <Text>
        Data em que o parceiro realizou o saque do valor recebido. Nesse dia, a transferência dos valores para a conta bancária
        foi solicitada pelo parceiro. O campo é atualizado em até 72 horas após o saque ter sido efetuado.
      </Text>
    ),
    userPaidValue: <Text>Valor que o aluno pagou da parcela.</Text>,
    products: <Text>Curso do aluno vinculado ao ID.</Text>,
    proviComission: <Text>Valor recebido pela Provi da parcela paga pelo Aluno.</Text>,
    proviPercentComission: <Text>Porcentagem recebida pela Provi em relação ao valor da parcela paga pelo aluno.</Text>,
    receivedValue: (
      <Text>
        Valor recebido pelo parceiro de acordo com a parcela paga pelo aluno. Esse valor inclui os encargos de multas e juros,
        se existirem.
      </Text>
    ),
    madeEffectiveDate: <Text>Data de efetivação do aluno.</Text>,
    expectedCompensationDate: (
      <Text>
        Data prevista para que o saldo esteja disponível para o parceiro. O saldo fica disponível até 1 dia útil após o
        pagamento do aluno.
      </Text>
    ),
    campaignName: <Text>Nome da campanha vinculada ao ID, se existir.</Text>,
    installments: <Text>Quantidade de parcelas contratadas pelo aluno.</Text>,
    installments_paid: <Text>Total de parcelas pagas pelo aluno até o momento.</Text>,
    installments_delayed: <Text>Total de parcelas que estão atrasadas pelo aluno.</Text>,
    delayed_days: <Text>Número de dias que o aluno está em atraso com a Provi.</Text>,
    released: (
      <Text>
        Data que o parceiro indicou que liberou o curso para o aluno. Atente-se: essa informação pode demorar alguns minutos
        para ser atualizada.
      </Text>
    ),
    nextPayment: <Text>Data do vencimento da proxima parcela a ser paga pelo aluno.</Text>,
    agreementDate: <Text>Data de vencimento do acordo, caso exista.</Text>,
    value: <Text>Valor do curso adquirido pelo aluno.</Text>,
    commission_provi: <Text>Valor recebido pela Provi do total da venda.</Text>,
    commission_partner: <Text>Porcentagem recebida pela Provi em relação ao valor total da venda.</Text>,
    expectedTransferDate: <Text>Data de previsão para que o repasse do valor a receber ocorra.</Text>,
  }
  const data = helperModalOptions[helperModalData]

  return { data }
}
