import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

/**
 * @description modal hooks
 * @name useLoanComments
 * @param {Object} loanOptionComments
 * @returns {comment: String}
 */
export const useLoanComments = (loanCommentsData, setIsLoading) => {
  const [comment, setComment] = useState('')
  const [expectedTed, setExpectedTed] = useState('')
  const [amountTed, setAmountTed] = useState('')

  const replaceText = (text) => {
    const replacedText = text
      .toLowerCase()
      .replace('compensacao#:', 'Compensação dos alunos:')
      .replace('crid', 'ID')
      .replace('crids', 'IDs')
      .replace('invoiceid', 'id do boleto')

    return replacedText
  }

  const formatText = (loanOptionComments) => {
    const checkString = loanOptionComments.includes('COMPENSACAO#:')

    if (checkString) {
      const formattedComment = replaceText(loanOptionComments)
      setComment(formattedComment)
    } else if (expectedTed !== amountTed) {
      setComment('Entre em contato com seu Gerente de Contas para mais detalhes')
    }
  }

  useEffect(() => {
    if (!isEmpty(loanCommentsData)) {
      const { amountOfTEDPerformed, expectedTEDValue, loanOptionComments } = loanCommentsData

      setExpectedTed(expectedTEDValue)
      setAmountTed(amountOfTEDPerformed)

      formatText(loanOptionComments)

      setIsLoading(false)
    }
  }, [])

  return { comment, expectedTed, amountTed }
}
