/* eslint-disable no-restricted-globals */
import { theme } from '@provi/provi-components'
import { Big } from 'big.js'
import moment from 'moment'
import { isObject } from 'lodash'
import masks from '~/themes/masks'
import { removeMask } from './removeMask'
import { getActiveUser } from './getActiveUser'
import { handleLocalStorage } from './handleLocalStorage'
import { handleStorage } from './handleLocalStorage/handleStorage'
import { getStorage } from './handleLocalStorage/getStorage'
import { removeStorage, removeItem } from './handleLocalStorage/removeStorage'
import { clearAndReload } from './clearAndReload'
import { parse, format, parseISO } from 'date-fns'

export { getActiveUser, handleLocalStorage, getStorage, handleStorage, removeStorage, removeItem, clearAndReload, removeMask }

/** @type {(length: number) => number} */
export const getPaddingDomain = (length) => {
  switch (length) {
    case 2:
      return 600

    case 3:
      return 300

    default:
      return 100
  }
}

/**
 * @name customizeUrl
 * @param {String} type
 * @param {String} parameter
 * @return {void}
 **/

export function customizeUrl(type, parameter, parameterValue = '') {
  const href = new URL(window.location.href)

  if (type === 'delete') href.searchParams.delete(parameter)
  if (type === 'add' && parameterValue) href.searchParams.set(parameter, parameterValue)

  window.history.pushState(null, null, href)
}

/**
 * @name addDotForDecimal
 * @param {Number} integer
 * @return {String}
 **/

function addDotForDecimal(integer) {
  const stringfied = String(integer)
  if (stringfied.length === 1) {
    return integer
  }
  const idx = stringfied.length - 2
  return stringfied.slice(0, idx) + '.' + stringfied.slice(idx + Math.abs(0))
}

/**
 * @name parseCurrency
 * @description This function parses any currency value for the
 * @param {Number} monetaryValue
 * @param {Boolean} shouldAddDotForDecimal
 * @return {String}
 **/

export function parseCurrency(monetaryValue, shouldAddDotForDecimal = true, legacy = false) {
  const value = monetaryValue.toString().replace('.')

  if (!shouldAddDotForDecimal) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  if (shouldAddDotForDecimal && !legacy) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(addDotForDecimal(value))
  }
  let stringValue = new String(value)
  let integers = []
  let decimals = ''

  const sign = /-.+/.test(stringValue) ? '-' : ''

  stringValue = stringValue.replace(/-/g, '').replace(/,/g, '.').replace('', '')

  const splitedValues = stringValue.split('.')

  decimals = splitedValues.length > 1 ? splitedValues.pop() : '00'
  const integerValues = splitedValues.join('')

  integers = placeDots(integerValues)

  decimals = decimals.length < 2 ? `${decimals}0` : decimals.substring(0, 2)

  return `R$ ${'' + sign + integers},${decimals}`
}

export function placeDots(string) {
  let iteration = -2
  const arrayWithDots = []

  for (let i = string.length - 1; i > -1; i -= 1) {
    if (iteration % 3 === 0 && i !== 0) {
      arrayWithDots.push(`.${string[i]}`)
    } else {
      arrayWithDots.push(string[i])
    }

    iteration += 1
  }

  return arrayWithDots.reverse().join('')
}

export const CurrencyMask = (str) => {
  if (!str) return

  return masks.currency(str)
}

export const unMask = (str) => {
  if (!str) return

  return masks.unmask(str)
}

export const roundValue = (str) => {
  if (!str) return

  return Math.round(str)
}

export const asyncLocalStorage = {
  setItem(key, value) {
    return Promise.resolve().then(() => {
      localStorage.setItem(key, value)
    })
  },
  getItem(key) {
    return Promise.resolve().then(() => {
      return localStorage.getItem(key)
    })
  },
  removeItem(key) {
    return Promise.resolve().then(() => {
      return localStorage.removeItem(key)
    })
  },
}

export const formatValue = (valueInCents, NoCents = false) => {
  if (!valueInCents && valueInCents !== 0) return
  let valueInReals
  if (!NoCents) {
    valueInReals = Number(valueInCents) / 100
  } else {
    valueInReals = Number(valueInCents)
  }
  return valueInReals.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const checkPreviousToken = async () => {
  const previousToken = await asyncLocalStorage.getItem('token')

  if (previousToken) {
    const previousEmail = await asyncLocalStorage.getItem('email')
    const previousPartnerSlug = await asyncLocalStorage.getItem('partner-slug')
    const previousPartnerName = await asyncLocalStorage.getItem('partner-name')
    const previousUserId = await asyncLocalStorage.getItem('user-id')
    const previousPartnerId = await asyncLocalStorage.getItem('partner-id')

    await asyncLocalStorage.removeItem('email')
    await asyncLocalStorage.removeItem('partner-slug')
    await asyncLocalStorage.removeItem('partner-name')
    await asyncLocalStorage.removeItem('user-id')
    await asyncLocalStorage.removeItem('partner-id')

    await handleLocalStorage({
      token: previousToken,
      email: previousEmail,
      partnerName: previousPartnerName,
      partnerSlug: previousPartnerSlug,
      partnerId: previousPartnerId,
      userId: previousUserId,
    })

    await asyncLocalStorage.removeItem('token')
    return { token: previousToken }
  }
}

const isNumber = (element) => {
  if (element === undefined || element === null) return false
  return isNaN(Number(element)) === false
}

const formatValueStr = (number) => {
  const str = String(number).replace('.', ',')

  return str
}

export const formatStrValue = (number) => {
  if (typeof number !== 'string') return number
  const str = Number(number.replace(',', '.'))

  return str
}

const calculateValue = ({ percent, productsValue }) => {
  // (<productsValue> * <percent> / 100) - <productsValue> * -1 = <productsValueWithDiscount>

  percent = percent.replace(',', '.')

  const value = Big(Number(productsValue))
    .times(percent || 0)
    .div(100)
    .sub(Number(productsValue))
    .times(-1)

  return formatValueStr(roundValue(value.toFixed(2)))
}

const calculatePercent = ({ valueInInput, productsValue }) => {
  if (valueInInput === undefined || valueInInput === null || Number(valueInInput) === 0) return ''
  if (productsValue === undefined || productsValue === null) return '0%'

  /**
   * Ref: (<inputvalue> / (<productsValue> / 100) - 100) * - 1
   */
  const percent = Big(valueInInput).div(Big(productsValue).div(100)).sub(100).times(-1)

  if (percent.toFixed() < 0) {
    return `0%`
  }
  if (percent.toFixed(2) === '100.00') {
    return `99,99%`
  }

  const finalValue = Math.round(percent.toFixed(2))
  if (finalValue === 100) return '99%'

  return `${finalValue < 10 ? `0${finalValue}` : finalValue}%`
}

const calculateDays = (days, now) => {
  if (days === undefined || days === null || !days) return '00/00/0000'

  const datewithdays = moment(now).add('days', days).format('DD/MM/YYYY')

  return datewithdays
}

export const formatDate = (d, withHour) => {
  // YYYY-MM-DD to DD/MM/YYYY
  // YYYY-MM-DDTHH:MM:SS.000 to DD/MM/YYYY HH:MM:SS

  if (!d) return false

  d = moment(d).utcOffset(-3).format()

  const dateHour = d.split('T')
  const date = dateHour[0].split('-')

  const years = date[0]
  const month = date[1]
  const days = date[2]

  if (withHour && dateHour.length > 1) {
    return `${days}/${month}/${years} (${dateHour[1].split('-')[0]})`
  }
  return `${days}/${month}/${years}`
}

/**
 * Temporarily until merging with the top function
 */
export const formatDateWithoutOffset = (d, withHour) => {
  if (!d) return false

  d = moment(d).utcOffset(0).format()

  const dateHour = d.split('T')
  const date = dateHour[0].split('-')

  const years = date[0]
  const month = date[1]
  const days = date[2]

  if (withHour && dateHour.length > 1) {
    return `${days}/${month}/${years} (${dateHour[1].split('-')[0]})`
  }
  return `${days}/${month}/${years}`
}

export const formatDateToServer = (d) => {
  // DD/MM/YYYY to YYYY-MM-DD
  if (!d) return false

  const date = d.split('/')

  const days = date[0]
  const month = date[1]
  const years = date[2]

  return `${years}-${month}-${days}`
}

export const calculateDate = (date) => {
  if (date === undefined || date === null) return '0'

  const now = moment()
  const end = moment(date, 'DD/MM/YYYY')

  const duration = moment.duration(end.diff(now))
  const daysBasedOnDate = roundValue(duration.asDays() + 1)

  return daysBasedOnDate
}

export const handleValue = ({ setObj, valueInInput, productsValue }) => {
  const percentValue = calculatePercent({ valueInInput, productsValue })

  const maskedValueInInput = CurrencyMask(valueInInput)

  return setObj({ percent: percentValue, value: maskedValueInInput })
}

export const handlePercent = ({ setObj, percent, productsValue }) => {
  let value = calculateValue({ percent, productsValue })

  value = CurrencyMask(value)
  return setObj({ percent, value })
}

export const handleDay = ({ setDate, days, now, setdateError }) => {
  setdateError(false)

  if (days < 0) {
    setdateError(true)
  }

  return setDate({ days, date: calculateDays(days, now) })
}

export const handleDate = ({ setDate, date, setdateError }) => {
  setdateError(false)

  if (calculateDate(date) < 0 || isNaN(calculateDate(date))) {
    setdateError(true)
    return setDate({ days: 0, date })
  }

  return setDate({ days: calculateDate(date), date })
}

export const cleanPercent = (percent) => {
  return isNumber(percent) ? percent.replace(/%/g, '') : 0
}

export const formatInput = (element) => {
  if (element === undefined || element === null) return 0
  return element
}

export const removeDotsCpf = (str) => {
  if (!str) return
  return str.replace(/\D+/g, '')
}

export const capitalize = (str) => {
  if (!str) return false

  const strLower = str.toLowerCase()
  if (typeof strLower !== 'string') return ''

  return strLower
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const formatThousand = (num) => {
  // return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.')
  return num
}

export const getTranslatedDelayedInstallments = (delayedInstallment) => {
  return delayedInstallment === null ? 'Em branco' : delayedInstallment
}
export const getTranslatedCampaigns = (campaignName) => {
  return campaignName || 'Em branco'
}

export const getTranslatedStatus = (byStatus) => {
  const status = {
    not_logged: 'LOGIN | SEM ACESSO',
    link_inactive: 'LINK INATIVADO',
    abandonment: 'DESISTÊNCIA',
    expired: 'EXPIRADO',
    abandonment_before_signed: 'DESISTÊNCIA',
    abandonment_after_signed: 'DESISTÊNCIA APÓS ASSINATURA',
    isa_abandonment_after_signed: 'DESISTÊNCIA APÓS ASSINATURA',
    abandonment_after_upfront: 'DESISTÊNCIA APÓS ENTRADA',
    abandonment_after_settled: 'CANCELADO',
    basic_info_incomplete: 'INFOS BÁSICAS | INCOMPLETO',
    document_info_incomplete: 'DOCUMENTOS | INCOMPLETO',
    guarantor_needed: 'PRECISA INDICAR AVALISTA',
    guarantor_incomplete: 'AVALISTA | INCOMPLETO',
    basic_info_review: 'REVISÃO | INFOS BÁSICAS ',
    document_info_review: 'REVISÃO | DOCUMENTOS',
    guarantor_review: 'REVISÃO | AVALISTA DOCUMENTOS',
    analysis: 'EM ANÁLISE',
    approved_by_provi: 'APROVADO PROVI',
    approved_with_caveats_by_provi: 'APROVADO COM RESSALVAS PROVI',
    not_approved_by_provi: 'REPROVADO PROVI',
    approved: 'APROVADO',
    waiting_signature: 'AGUARDANDO ASSINATURA',
    waiting_payment: 'AGUARDANDO PAGAMENTO ',
    made_effective: 'EFETIVADO',
    paid: 'PAGO',
    late: 'INADIMPLENTE',
    pending: 'PENDENTE',
    canceled: 'CANCELADO',
    denied: 'NEGADO',
    late_declaration: 'DECLARAÇÃO DE RENDA ATRASADA',
    awaiting_declaration: 'AGUARDANDO DECLARAÇÃO',
    declaration_review: 'DECLARAÇÃO EM REVISÃO',
    declaration_analysis: 'DECLARAÇÃO EM ANÁLISE',
    studying: 'CURSANDO',
    no_income: 'SEM RENDA',
    income_below_min: 'RENDA ABAIXO DO MÍNIMO',
    negativated: 'NEGATIVADO',
    isa_default: 'DEFAULT',
    fully_paid: 'QUITADO',
    protest: 'PROTESTADO',
  }

  return status[byStatus]
}

export const getTranslatedCridStatus = (status, isDelayed) => {
  const cridStatus = {
    basicInfo: 'Informações básicas',
    selfie: isDelayed ? 'Selfie' : 'Foto segurando documento',
    personalDocuments: 'Documentos pessoais',
    proofOfAddress: 'Comprovante de endereço',
    proofOfEnrollment: 'Comprovante de matricula',
    schoolRecords: 'Histórico escolar',
    review: 'Precisa corrigir',
    incomplete: 'Incompleto',
    approved: 'Aprovado',
    approved_by_provi: 'APR. PROVI - ANÁLISE ESCOLA',
    not_approved_by_provi: 'Não APR. PROVI - ANÁLISE ESCOLA',
    analysis: 'Em análise',
    'full-signed': 'Assinado',
    comment: 'Comentário enviado ao aluno',
    needed: 'Precisa indicar',
    unneeded: '- Não precisa indicar',
    isa_default: 'DEFAULT',
  }
  return cridStatus[status] || status
}

export const getCridStatusColors = (status) => {
  const colors = {
    review: '#FC7900',
    incomplete: theme.colors.yellow900,
    approved: theme.colors.green100,
    analysis: theme.colors.blue600,
    'full-signed': theme.colors.green100,
    needed: theme.colors.yellow900,
  }

  return colors[status] || theme.colors.blue900
}

export const findApproximateElementText = (element) => {
  const text = element.textContent || element.id
  const length = text.length

  if (length === 0 && element.parentElement) {
    return findApproximateElementText(element.parentElement)
  } else if (length >= 70) {
    return null
  }
  return text
}

export const getTranslatedPaymentMethods = (original) => {
  const paymentMethodsStatus = {
    CreditCard: 'Cartão de Crédito',
    Boleto: 'Boleto à vista',
    CourseFinancing: 'Crédito educacional',
  }

  return paymentMethodsStatus[original] || original
}

export const handlePlaceholder = (activeBox) => {
  let message

  switch (activeBox) {
    case 'cpf':
      message = 'Digite o CPF'
      break
    case 'name':
      message = 'Digite o nome'
      break
    case 'crid':
    case 'CreditRequestId':
      message = 'Digite o id'
      break
    case 'email':
      message = 'Digite o email'
      break
    default:
      message = 'Pesquise aqui'
  }
  return message
}

export const log = console.log
export const parseJSON = (json) => {
  let response

  try {
    response = JSON.parse(json)
  } catch (e) {}

  return response
}

const fixStorage = async (key) => {
  const storage = await asyncLocalStorage.getItem(key)
  const parsedStorage = parseJSON(storage)

  if (isObject(parsedStorage)) {
    let keys = Object.keys(parsedStorage)
    let value = parsedStorage[keys[0]]
    await asyncLocalStorage.setItem(key, value)
  }
}

export const fixLocalStorage = async () => {
  await fixStorage('user-id')
  await fixStorage('partner-id')
  await fixStorage('partner-name')
  await fixStorage('partner-slug')
  await fixStorage('email')

  localStorage.removeItem('tokens')

  localStorage.removeItem('selectedOptions')
  localStorage.removeItem('selectedOptionsValue')
  localStorage.removeItem('selectedOptionsCampaign')
  localStorage.removeItem('selectedOptionsValueCampaign')
}

export const getAbsoluteEntranceByPercent = (percentEntrance, courseValue) => {
  return percentEntrance ? Big(courseValue).times(Big(percentEntrance).div(100)) : 0
}

export const getBiggerOptionThenAbsoluteAndPercentValue = (percentValue, absoluteValue) => {
  return percentValue > absoluteValue ? percentValue : absoluteValue
}

export const formatPercentage = (commission = 0) => {
  if (!isNumber(commission)) return null

  const formattedCommission = Big(Number(commission)).times(100).toString()

  return Number(formattedCommission) ? `${formattedCommission}%` : null
}

export const formatCnae = (str) => {
  if (!str) return
  return str.replace(/\D+/g, '')
}

export const formatPercentageInteger = (integerToFormat) => (Number.isInteger(integerToFormat) ? `${integerToFormat}%` : null)

export const isEmptyObj = (objToCheck) => typeof objToCheck === 'object' && Object.keys(objToCheck).length === 0

export const parseThenFormatDate = (dateString) => format(parse(dateString, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')

export const parseISOThenFormatDate = (dateString) => format(parseISO(dateString), 'dd/MM/yyyy')

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
