import React from 'react'
import { useDefaultStatusChart } from './hooks'
import { CustomSwitch } from '~/components/v2/Switch'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { DefaultStatusChartGrid, TopGrid, TextContainer, SwitchContainer, ChartWrapper, ChartHeader, ChartText } from './styles'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'

export const DefaultStatusChart = ({ filters }) => {
  const { isMobile, chartData, switchState, setSwitchState, chartLoadingState, whichChartToRender } = useDefaultStatusChart(
    filters,
  )

  return (
    <DefaultStatusChartGrid>
      <TopGrid>
        <TextContainer>
          <ChartHeader>Situação dos inadimplentes</ChartHeader>
          <ChartText>
            Este gráfico mostra a situação dos inadimplentes, dividida pelo número de parcelas em atraso ou pelo número de dias
            em atraso.
          </ChartText>
        </TextContainer>
        <SwitchContainer hasPadding>
          <CustomSwitch
            leftLabel={'Parcelas em atraso'}
            rightLabel={'Dias em atraso'}
            switchState={switchState}
            setSwitchState={setSwitchState}
          />
        </SwitchContainer>
      </TopGrid>
      <ChartWrapper>
        <LoadingSkeleton isLoading={chartLoadingState} repeat={2} containerHeight="150px" hasHeading hasParagraph>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={whichChartToRender(switchState, chartData)} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid stroke="#DCDCDC" vertical={false} />
              <XAxis
                dataKey={switchState ? 'dayOfDelayedInstallments' : 'delayedInstallments'}
                scale="band"
                style={{ fontWeight: 600 }}
                tickLine={false}
                tickMargin={10}
                interval={0}
                height={60}
                label={{ value: switchState ? 'Dias em atraso' : 'Número de parcelas', dy: 30, fill: '#575757' }}
              />
              {!isMobile && (
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value: 'Número de alunos',
                    angle: -90,
                    position: 'Left',
                    dx: -30,
                    fill: '#575757',
                  }}
                />
              )}
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Bar
                name="Número de alunos"
                maxBarSize={80}
                dataKey="numberOfStudentsWithInstallmentDelayed"
                fill="#A4C7FB"
                isAnimationActive={false}
              >
                <LabelList
                  dataKey="numberOfStudentsWithInstallmentDelayed"
                  position="top"
                  style={{ fill: 'rgba(65, 65, 65, 0.6)', fontWeight: 600 }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </LoadingSkeleton>
      </ChartWrapper>
    </DefaultStatusChartGrid>
  )
}
