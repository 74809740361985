import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const ChartLabelContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`

export const ColorBox = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${({ fillColor }) => fillColor || 'white'};

  ${({ isLine }) =>
    isLine &&
    css`
      height: initial;
      border: 1px solid ${theme.colors.pink900};
      border-radius: initial;
    `}
`

export const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
`

export const LabelText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 20px;
  color: #575757;
  font-size: 13px;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 600;
    `}

  ${theme.breakpoints.down('md')} {
    font-size: 11px;
  }
`
