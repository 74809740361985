import moment from 'moment'

export const formatDatePickerStr = (dateStr, cells) => {
  if (cells && cells.blankDatePermitted && cells.isBlankDate) {
    return 'CLEAR'
  }

  if (!dateStr) return ''

  const formattedDate = moment(dateStr).format('YYYY-MM-DD')
  return formattedDate
}
