import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { useProviLogin } from './hooks'

export const ProviLogin = () => {
  const { setIsLoading, goToPage } = useContext(TemplateContext)
  useProviLogin({ setIsLoading, goToPage })
  return <div />
}

// import React from 'react'
// import { useProviLogin } from './hooks'

// export const ProviLogin = () => {
//   useProviLogin()
//   return <div />
// }
