import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const View = styled.div`
  width: ${({ width }) => width || '500px'} !important;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
