import styled, { css } from 'styled-components'
import { Button, makeStyles } from '@material-ui/core'
import { theme } from '@provi/provi-components'

export const useStyles = makeStyles({
  icon: {
    fontSize: theme.variables.spacer * 2,
    marginLeft: 10,
  },
})

export const ContainerButton = styled.div`
  margin: ${({ margin }) => margin};
  text-align: center;
`

export const ButtonWrapper = styled(Button)`
  float: none;
  border-radius: 6px !important;
  background: ${({ disabled, backgroundDisabled }) =>
    disabled ? (backgroundDisabled ? theme.colors.blue800 : theme.colors.grey600) : theme.colors.pink900} !important;
  width: ${theme.variables.spacer * 32}px;
  height: ${theme.variables.spacer * 6}px;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }

  .MuiButton-label {
    color: white;
    text-transform: none;
    font-size: 16px;
    font-family: 'Montserrat-Bold', sans-serif;
  }

  .MuiButton-contained &:hover {
    background-color: #a50024 !important;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid ${theme.colors.pink900}!important;
      background: ${theme.colors.white100}!important;

      .MuiButton-label {
        color: ${theme.colors.pink900};
      }
    `}
`

export const ButtonTitle = styled.p`
  font-size: 16px;
  margin: 0;
  font-weight: bold;
  color: ${(props) => (props.visuals ? props.visuals.buttonTextColor : theme.colors.white100)};
`
