import React from 'react'
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom'
import { CoursesContext, CoursesProvider } from './context'
import { useCourses } from './hooks'
import { ListCourses, DetailsComponents, AddCourse } from '~/screens/courses/components'
import { BasePageWrapper, BaseContainer } from '~/globalStyles'
import { TableFallback } from '~/components/v2/TableV2/components'

export const Courses = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { isMobile, drawerWidth, showNotification } = useCourses()

  return (
    <CoursesProvider isMobile={isMobile}>
      <CoursesContext.Consumer>
        {() => (
          <>
            <BasePageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
              {pathname === '/cursos' && showNotification && (
                <TableFallback
                  text={
                    <>
                      Os dados de turma só ficarão disponíveis para a venda após a implementação do novo ProviPay, entrar em
                      contato com a{' '}
                      <a href="https://api.whatsapp.com/send?phone=+5511911261503" target="_blank" rel="noopener noreferrer">
                        Provi
                      </a>
                    </>
                  }
                  marginTop={'5rem'}
                  maxWidth={'1200px'}
                />
              )}

              <BaseContainer>
                <Switch>
                  <Route exact path={path}>
                    <ListCourses showNotification={showNotification} />
                  </Route>
                  <Route path={`${path}/novo-curso`}>
                    <AddCourse />
                  </Route>
                  <Route path={`${path}/:courseId`}>
                    <DetailsComponents />
                  </Route>
                </Switch>
              </BaseContainer>
            </BasePageWrapper>
          </>
        )}
      </CoursesContext.Consumer>
    </CoursesProvider>
  )
}
