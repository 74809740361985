import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FlexWrapContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

export const PillContainer = styled.div`
  width: fit-content;
  background: #d4daf7;
  border-radius: 24px;
  padding: 2px 10px;
  border: 1px solid ${theme.colors.bluePrimaryLight};

  &:hover {
    border: 1px solid ${theme.colors.bluePrimary};
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const BlueText = styled.p`
  margin: 3px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #00198a;

  ${theme.breakpoints.down('md')} {
    font-size: 13px;
    line-height: 20px;
  }
`
