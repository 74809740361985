import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const InputGroup = styled.div`
  ${({ isTextArea }) =>
    isTextArea &&
    `
    .input__with--icon {
    display: inline-flex;
    align-items: center;
    padding: 8px !important;
    border-radius: 3px;
    background: #ffffff;

    border: 2px solid #D4DAF7;
    box-sizing: border-box;
    border-radius: 4px;

    &.error {
      border: 2px solid #B30142;
    }
  }

    display: flex;
    flex-direction: column;
    
    textarea {
    width: 100%;
    height: 10em;
    resize: none;
    background: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
    border: 0;


  &::placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
    color: #767676;
  }
}

label {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #000000;

  & span {
    font-weight: 400;
  }
}

  `}

  ${({ hasMany, isMobile, hasErrors }) =>
    hasMany
      ? `
    display: flex;
    justify-content: space-between;
    align-items: ${hasErrors && !isMobile ? 'center' : 'end'};
    height: ${hasErrors && !isMobile && '125px'};
    flex-direction: ${isMobile ? 'column' : 'row'};

    & > div:first-of-type {
      margin-bottom: ${isMobile ? '1rem' : '0'};
    }

    & > div {
      width: ${isMobile ? '100%' : `calc(50% - 20px)`};

      label {
        max-width: 100%;
      }
    }

    @media and screen (max-width: 768px) {
      & > div {
        width: 100%;
        margin: 15px 0;
      }
    }
  `
      : ''}

  .groupSelect__menu {
    z-index: 300;
  }

  .groupSelect__group-heading {
    color: black;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: revert;
  }

  .groupSelect__placeholder {
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .groupSelect__option {
    white-space: normal;
    z-index: 5000;
  }

  .groupSelect__option--is-focused {
    font-weight: 600;
  }

  .groupSelect__dropdown-indicator {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
  }

  .groupSelect__control--menu-is-open .groupSelect__dropdown-indicator {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }

  label {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #000000;

    & span {
      font-weight: 400;
    }
  }

  .link__create--skills {
    float: right;
    margin-top: 25px;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
  }

  .input__with {
    &-error {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .input__error-value,
  .input__error-workload {
    align-items: center;
  }

  .input__with-error-workload.input__with--subMessage {
    height: 125px;
    justify-content: start;
  }

  .input__with-error-workload {
    height: 125px;
    justify-content: end;
  }

  .input__with--subMessage {
    label {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
      color: #000000;
    }

    span {
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.005em;
      text-align: left;
      color: #575757;
    }
  }

  &.input__with--disclaimer {
    display: flex;
    justify-content: space-between;
    align-items: ${({ isMobile }) => (isMobile ? 'start' : 'end')};

    flex-wrap: wrap;

    ${({ isMobile }) => isMobile && `flex-direction: column;`}

    & > div:first-of-type {
      display: flex;
      flex-direction: column;
      width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
      margin-bottom: ${({ isMobile }) => (isMobile ? '1rem' : '0')};

      label {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #000000;
      }

      span {
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #575757;
      }
    }

    & > div:last-of-type {
      width: ${({ isMobile }) => isMobile && '100%'};

      & div {
        width: ${({ isMobile }) => isMobile && '100%'};
      }
    }
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  flex-wrap: wrap;

  div {
    ${({ isMobile }) => isMobile && `margin-bottom: 15px;`}

    &:first-of-type {
      button {
        &:hover {
          background-color: transparent !important;
          span {
            text-decoration: underline;
          }
        }
        span {
          color: ${theme.colors.bluePrimary} !important;
        }
        background-color: transparent;
      }
    }
  }
`

export const TextInputError = styled.span`
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.0075em;
  text-align: left;
  color: #b30142 !important;
`

export const SelectContainer = styled.div`
  .select-explainer {
    color: #575757;
  }
`
