/* eslint-disable react/prop-types */
import React from 'react'

export const Trash = ({ color = '#B0AFAF' }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M1 5C1 2.79086 2.79086 1 5 1H25C27.2091 1 29 2.79086 29 5V25C29 27.2091 27.2091 29 25 29H5C2.79086 29 1 27.2091 1 25V5Z"
      fill="white"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M7 9.99984H8.66667M8.66667 9.99984H22M8.66667 9.99984L8.66667 21.6665C8.66667 22.1085 8.84226 22.5325 9.15482 22.845C9.46738 23.1576 9.89131 23.3332 10.3333 23.3332H18.6667C19.1087 23.3332 19.5326 23.1576 19.8452 22.845C20.1577 22.5325 20.3333 22.1085 20.3333 21.6665V9.99984L8.66667 9.99984ZM11.1667 9.99984V8.33317C11.1667 7.89114 11.3423 7.46722 11.6548 7.15466C11.9674 6.8421 12.3913 6.6665 12.8333 6.6665H16.1667C16.6087 6.6665 17.0326 6.8421 17.3452 7.15466C17.6577 7.46722 17.8333 7.89114 17.8333 8.33317V9.99984M12.8333 14.1665V19.1665M16.1667 14.1665V19.1665"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
