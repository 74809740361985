import React, { useContext } from 'react'

import { Button, TextLink, theme } from '@provi/provi-components'

import { TemplateContext } from '~/components/Template/context'
import { DrawerMenuContext } from '~/components/DrawerMenu/context'

import { SimpleTable } from '~/components/table'
import { ButtonBack } from '~/components/ButtonBack'
import { BackArrow } from '~/assets/svgs/back-arrow'

import { HeaderUserControl as Header } from '~/components/HeaderUserControl'
import { Text } from '~/themes/styles'

import { useEditUsers } from './hooks'
import {
  Content,
  ContainerTable,
  SectionHeader,
  WrapperText,
  ContainerBoxButtons,
  BoxButton,
  WrapperButton,
  WarningAnimation,
  Container,
  Warning,
  AnchorElementToSmallScreens,
  WrapperTextLink,
  WrapperAccessLevel,
  WrapperIcon,
  WrapperBoxButton,
} from './styles'

export const EditUsers = () => {
  const { goToPage, setIsLoading, isMobile, activePage, setActivePage, accessData } = useContext(TemplateContext)
  const { drawerWidth } = useContext(DrawerMenuContext)

  const [
    email,
    visible,
    handleCheck,
    handleUpdate,
    optionActive,
    link,
    showCells,
    setRows,
    rows,
    warningMessage,
  ] = useEditUsers(setIsLoading, isMobile, activePage, setActivePage, accessData)

  return (
    <>
      <Header addNewMember={false} />
      {!isMobile && <ButtonBack onClick={() => goToPage('usuario/listar-usuarios')} />}
      <Content isMobile={isMobile} drawerWidth={drawerWidth}>
        <SectionHeader>
          <WrapperText>
            {isMobile && (
              <WrapperIcon onClick={() => goToPage('usuario/listar-usuarios')}>
                <BackArrow />
              </WrapperIcon>
            )}
            <Text align="center" size="20px" color={theme.colors.blue900} bold>
              O que deseja alterar no acesso do usuário:
            </Text>
            <Text align="center" mBottom="20px" size="20px" color={theme.colors.blue600} bold>
              {email}
            </Text>
          </WrapperText>
          <ContainerBoxButtons>
            <WrapperBoxButton>
              <BoxButton active={optionActive.visibleAccessLevel === 'accessLevel'} onClick={() => handleCheck('accessLevel')}>
                Nível de acesso
              </BoxButton>
            </WrapperBoxButton>
            <WrapperBoxButton>
              <BoxButton active={optionActive.visibleAccessLevel === 'password'} onClick={() => handleCheck('password')}>
                Senha
              </BoxButton>
            </WrapperBoxButton>
          </ContainerBoxButtons>
        </SectionHeader>
        {visible === 'accessLevel' && (
          <WrapperAccessLevel>
            <ContainerTable>
              {warningMessage ? (
                <Text bold align="center" color={theme.colors.blue900} mTop="30px" size="24px">
                  {warningMessage}
                </Text>
              ) : (
                <SimpleTable origin="accessLevel" setRows={setRows} rows={rows} showCells={showCells} headAlign="center" />
              )}
            </ContainerTable>
            <WrapperButton>
              <Button
                text="Salvar alteração"
                onClick={() => {
                  handleUpdate('accessLevel')
                }}
              />
            </WrapperButton>
          </WrapperAccessLevel>
        )}
        {visible === 'password' && (
          <WrapperAccessLevel>
            <WrapperText>
              <Text align="center" size="20px" color={theme.colors.blue900} bold>
                Confirma a criação de uma nova senha?
              </Text>
            </WrapperText>
            <WrapperButton>
              <Button
                text="Gerar nova senha"
                onClick={() => {
                  handleUpdate('password')
                }}
              />
            </WrapperButton>
          </WrapperAccessLevel>
        )}
      </Content>
      {link && visible === 'accessLevel' && (
        <WarningAnimation>
          <Warning>
            <Container>
              <Text align="center" mBottom="10px" size="20px" color={theme.colors.blue600} bold>
                Nível de acesso alterado com sucesso
              </Text>
              <WrapperTextLink>
                <WrapperIcon>
                  <BackArrow isDark />
                </WrapperIcon>
                <TextLink
                  text="Retornar a tela inicial"
                  color={theme.colors.white100}
                  onClick={() => goToPage('usuario/listar-usuarios')}
                />
              </WrapperTextLink>
            </Container>
          </Warning>
        </WarningAnimation>
      )}
      {link && visible === 'password' && (
        <WarningAnimation>
          <Warning>
            <Container>
              <Text align="center" mBottom="10px" size="20px" color={theme.colors.blue600} bold>
                Solicitação de alteração de senha realizada com sucesso!
              </Text>
              <AnchorElementToSmallScreens>
                <Text align="center" size="16px" mBottom="10px" color={theme.colors.white100} bold display={'inline-block'}>
                  Verifique o endereço de e-mail{' '}
                  <Text bold size="16px" display={'inline-block'} color={theme.colors.pink900}>
                    {email}
                  </Text>{' '}
                  para ver instruções de como redefinir a senha.
                </Text>
                <Text align="center" color={theme.colors.greyLight} size="16px">
                  O email pode demorar até 10 minutos para aparecer na sua caixa de entrada. Qualquer problema, entre em contato
                  com a Provi.
                </Text>
              </AnchorElementToSmallScreens>
              <WrapperTextLink>
                <WrapperIcon>
                  <BackArrow isDark />
                </WrapperIcon>
                <TextLink
                  text="Retornar a tela inicial"
                  size="16px"
                  color={theme.colors.white100}
                  onClick={() => goToPage('usuario/listar-usuarios')}
                />
              </WrapperTextLink>
            </Container>
          </Warning>
        </WarningAnimation>
      )}
    </>
  )
}
