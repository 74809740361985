import React from 'react'
import { useChargebacks } from './hooks'
import { ChargebacksMobileRows } from './components'
import { TableV2 } from '~/components/v2/TableV2'
import { TableToolBar, TableFallback } from '~/components/v2/TableV2/components'
import { PageWrapper, Container, HeaderContainer, Header, TableWrapper } from './styles'

export const Chargebacks = () => {
  const {
    isMobile,
    drawerWidth,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageIndex,
    pageSize,
    pageCount,
    setPageSize,
    canNextPage,
    canPreviousPage,
    totalCount,
    prepareRow,
    visibleColumns,
    isFirstLoad,
  } = useChargebacks()

  if (isFirstLoad) return null

  return (
    <>
      <PageWrapper isMobile={isMobile} drawerWidth={drawerWidth}>
        <Container>
          <HeaderContainer>
            <Header>Chargebacks</Header>
            {!isMobile && !!page.length && (
              <TableToolBar
                paginationComponent={true}
                pageSizeComponent={true}
                page={page}
                previousPage={previousPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                totalCount={totalCount}
              />
            )}
          </HeaderContainer>

          <TableWrapper>
            {page.length ? (
              <TableV2
                testID="chargebacks-table"
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                page={page}
                prepareRow={prepareRow}
                isMobile={isMobile}
                visibleColumns={visibleColumns}
                SubComponent={ChargebacksMobileRows}
                previousPage={previousPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                totalCount={totalCount}
              />
            ) : (
              <TableFallback text={'Você ainda não possui chargebacks'} />
            )}
          </TableWrapper>
        </Container>
      </PageWrapper>
    </>
  )
}
