import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { LampSvg } from '~/assets/svgs/lamp'
import { BlueRocket } from '~/assets/svgs/bluerocket'
import { Text } from '~/themes/styles'
import { Container, LeftColumn, RightColumn, StyledSpan, VanishingWrapper } from './styles'

export const HeroCard = ({ numberOfStudents }) => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <>
      <Container>
        <LeftColumn>
          <LampSvg />
          <Text
            size={isMobile ? '20px' : '24px'}
            lineHeight={isMobile ? '24px' : '30px'}
            fontFamily="Montserrat-SemiBold"
            margin="20px 0"
          >
            Você sabia?
          </Text>
          <Text size={isMobile ? '16px' : '18px'} lineHeight={isMobile ? '20px' : '24px'} fontFamily="Montserrat-Regular">
            A nossa parceria já possibilitou que <StyledSpan>{numberOfStudents} estudantes</StyledSpan> transformassem suas
            carreiras!
          </Text>
        </LeftColumn>
        <VanishingWrapper>
          <RightColumn>
            <BlueRocket />
          </RightColumn>
        </VanishingWrapper>
      </Container>
    </>
  )
}
