import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { handleColorStatus } from './status-box/styles'
import { fonts } from '~/themes/fonts'
import { makeStyles } from '@material-ui/core'

export const ContainerTableMobile = styled.div``

export const TableMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const DefaultButton = styled.button`
  width: 118px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.pink900};
  color: ${theme.colors.pink900};
  color: ${(props) => (props.isDefault ? theme.colors.white100 : theme.colors.pink900)};
  font-family: ${fonts.century.bold};
  font-size: 12px;
  background-color: ${(props) => (props.isDefault ? theme.colors.pink900 : theme.colors.white100)};
  &:hover {
    cursor: pointer;
    color: ${theme.colors.white100};
    background-color: ${theme.colors.pink900};
  }
`
export const Head = styled.div`
  width: 100%;
  height: 50px;
  background-color: #ebebeb;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  z-index: 2;
`
export const ListsBody = styled.div`
  width: 100%;
  margin-top: 50px;

  ${theme.breakpoints.down('md')} {
    margin-top: 50px;
  }
`
export const Body = styled.div`
  width: 100%;
  margin-top: 50px;

  ${theme.breakpoints.down('md')} {
    margin-top: 0px;
  }
`

export const Row = styled.div`
  /* border: 3px solid skyblue; */
  width: 100%;
  height: inherit;
`

export const ContentRow = styled.div`
  cursor: ${({ head }) => (head ? 'default' : 'pointer')};
  display: flex;
  justify-content: space-around;
  height: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: ${({ disabledInformation }) => (disabledInformation ? '1px solid #cfcfcf' : 'unset')};
`

export const Cell = styled.div`
  font-family: ${({ normal }) => (normal ? 'CenturyGothic' : 'CenturyGothicBold')};
  color: ${({ head }) => (head ? theme.colors.grey600 : theme.colors.blue900)};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ head }) => (head ? '0 0 5px 10px' : 'unset')};

  ${theme.breakpoints.down('md')} {
  }

  ${({ align }) =>
    align &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`

export const Indicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ status, initialStatus }) => handleColorStatus(status, initialStatus)};
  border: ${({ status, initialStatus }) => handleColorStatus(status, initialStatus)};
`

export const SectionInformation = styled.div`
  padding: 5px 20px;
  margin-bottom: 20px;
`

export const Information = styled.div`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  transition: display 2000ms;
  border-bottom: 1px solid #cfcfcf;
`

export const Value = styled.div`
  color: ${theme.colors.blue900};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
`

export const WrapperLabel = styled.div`
  display: inline-block;
  margin-left: 10px;
`
export const WrapperHeadButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: ${({ head }) => (head ? 'default' : 'pointer')};
  display: flex;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.grey400};
  border-bottom: ${({ disabledInformation }) => (disabledInformation ? '1px solid #cfcfcf' : 'unset')};
`
export const HeadSecond = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${theme.colors.grey400};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  z-index: 2;
  margin-bottom: 20px;
`

export const Item = styled.div`
  padding: 10px;
  font-size: 12px;
  line-height: 24px;
  font-family: 'CenturyGothic';
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const HoverSelect = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`

export const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.blue900};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
`

export const Active = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.pink900};
  background-color: ${theme.colors.pink900};
`

export const SquareCheckBox = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 1px solid ${theme.colors.blue900};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 5px;
`

export const SquareActive = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 1px;
  border: 1px solid ${theme.colors.pink900};
  background-color: ${theme.colors.pink900};
`

export const Disabled = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`

export const TextTableCell = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const ButtonFilter = styled.div`
  height: 30px;
  width: 100px;
  background-color: #de0c4b;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 20px;
  font-family: CenturyGothicBold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
`

export const TextTableCellFilter = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
`

export const TextMobileName = styled.p`
  margin: 0;
  font-size: 12px;
`

export const DateWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

//

export const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'revert',
    },
    '&:hover $notchedOutline': {
      borderColor: 'revert',
    },
    '&$focused $notchedOutline': {
      borderColor: 'revert',
    },
  },
  focused: {},
  notchedOutline: {},
}))

export const DropItens = styled.div`
  position: absolute;
  top: 100%;
  min-width: 300px;
  width: fit-content;
  background-color: #fff;
`

export const DropBody = styled.div`
  max-height: 450px;
  padding: 1rem;

  overflow-y: auto;
`

export const DropOption = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-family: 'CenturyGothic';
  margin-bottom: 1rem;
  cursor: pointer;
`

export const DropFooter = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
`

export const DropFooterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const WrapperCheck = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ pointer }) => (pointer ? `pointer` : 'default')};
`

export const VerticalLine = styled.div`
  width: 1px;
  min-height: 100%;
  border-left: 1px solid #afafb0;
  align-self: stretch;
`

export const FilterButton = styled.div`
  border-radius: 20px;
  width: 80px;
  height: 32px;
  padding: 10px;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const TextLink = styled.div`
  cursor: pointer;
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')};
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  &:hover {
    text-decoration: underline;
  }
`

export const WrapperQuestionMark = styled.div`
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
