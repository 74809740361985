import React from 'react'
import { theme, CheckBox, InputFieldV3 } from '@provi/provi-components'
import { Button } from '~/components/v3'
import { BlackHeaders, ExplanationBox } from '../index'
import { VerticalContainers, FlexContainers } from '../../styles'
import { ChainLinkIcon, PlusIcon } from '~/assets/svgs/v2'
import { BaseTypography } from '~/globalStyles'
import { useStepsSub } from './hooks'

const numberFromBE = 500000

const StepComponent = ({ isMobile, eachObj, index, mappedArr, stateProps }) => {
  const {
    formValues,
    setFormValues,
    discountPercentage,
    priceWithDiscount,
    minimumEndAtDate,
    minimumCanCheckoutUntilDate,
  } = stateProps

  const {
    maskedValue,
    percentage,
    handleDateInputChange,
    handleCheckBoxChange,
    handleMaskedInputChange,
    handlePercentageChange,
    isFirstElement,
    isLastElement,
  } = useStepsSub({
    formValues,
    setFormValues,
    discountPercentage,
    priceWithDiscount,
    index,
    mappedArr,
    eachObj,
    minimumEndAtDate,
    minimumCanCheckoutUntilDate,
  })

  const flexDirs = isMobile && 'column'

  return (
    <>
      <VerticalContainers gap={isMobile ? '0.5rem' : '1rem'}>
        <BaseTypography
          fontSize={isMobile ? '13px' : '16px'}
          lineHeight={isMobile ? '20px' : '24px'}
          color={theme.colors.bluePrimary}
          fontWeight={700}
        >
          {`${index + 1}º Lote`}
        </BaseTypography>
        <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'} hasRevertWidth>
          <InputFieldV3
            id={`startsAt`}
            name={`startsAt`}
            label="Início do lote"
            type="datetime-local"
            value={eachObj.startsAt}
            onChange={handleDateInputChange}
            disabled={true}
          />

          <ExplanationBox
            isMobile={isMobile}
            explanation={
              isFirstElement
                ? 'O primeiro lote tem que começar ao mesmo tempo do início das vendas.'
                : 'Os lotes subsequentes precisam começar ao mesmo tempo do fim dos lotes antecedentes.'
            }
          />
        </FlexContainers>
      </VerticalContainers>
      <VerticalContainers gap={isMobile ? '0.5rem' : '1rem'}>
        <FlexContainers flexDirection={flexDirs} gap={isMobile && '1rem'} hasRevertWidth>
          <InputFieldV3
            id={'endsAt'}
            name={'endsAt'}
            label="Fim do lote"
            type="datetime-local"
            min={isFirstElement ? formValues.launchStartsAt : mappedArr[index - 1].endsAt}
            max={formValues.launchEndsAt}
            value={eachObj.endsAt}
            onChange={handleDateInputChange}
            disabled={isLastElement}
          />
          <ExplanationBox
            isMobile={isMobile}
            explanation={
              isLastElement ? (
                <>
                  O último lote precisa terminar ao mesmo tempo que o fim das vendas.
                  <br />
                  <b>Adicione mais um lote para poder modificar esta data.</b>
                </>
              ) : (
                'Após o fim do período, o aluno não poderá solicitar a compra nestas condições.'
              )
            }
          />
        </FlexContainers>
      </VerticalContainers>
      <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
        <BlackHeaders
          isMobile={isMobile}
          title={'Métodos de pagamento disponíveis '}
          explanation={'O aluno poderá escolher como pagar dentro do modelo de precificação determinado por você.'}
        />
        <FlexContainers flexDirection={flexDirs}>
          <CheckBox
            name="creditCard"
            text={'Cartão de crédito'}
            checked={eachObj.paymentMethods.some((eachString) => eachString === 'CreditCard')}
            onChange={() => handleCheckBoxChange('CreditCard')}
          />
          <CheckBox
            name="slip"
            text={'Boleto à vista'}
            checked={eachObj.paymentMethods.some((eachString) => eachString === 'Boleto')}
            onChange={() => handleCheckBoxChange('Boleto')}
          />
          <CheckBox
            name="financing"
            text={'Financiamento'}
            checked={eachObj.paymentMethods.some((eachString) => eachString === 'CourseFinancing')}
            onChange={() => handleCheckBoxChange('CourseFinancing')}
          />
        </FlexContainers>
      </VerticalContainers>
      <VerticalContainers gap={'1rem'}>
        <BlackHeaders isMobile={isMobile} title={'Valor do lote'} explanation={'Digite o valor final ou o desconto em %.'} />
        <VerticalContainers>
          <FlexContainers gap={isMobile ? '0.5rem' : '1.5rem'} justifyContent={'space-between'} alignItems={'center'}>
            <InputFieldV3
              type="text"
              name="priceInCents"
              placeholder="R$ 5.000,00"
              width={'100%'}
              value={maskedValue}
              onChange={handleMaskedInputChange}
            />
            {!isMobile && <ChainLinkIcon />}
            <InputFieldV3
              type="text"
              name="discountPercentage"
              placeholder="Ex: 20%"
              width={'100%'}
              value={percentage}
              onChange={handlePercentageChange}
              mask={'99'}
            />
          </FlexContainers>
        </VerticalContainers>
        {isLastElement && (
          <Button
            icon={<PlusIcon color={'white'} />}
            text="Adicionar lote"
            mobileWidth={'100%'}
            onClick={() =>
              setFormValues((prev) => ({
                ...prev,
                launchPricing: {
                  ...prev.launchPricing,
                  options: [
                    ...prev.launchPricing.options,
                    {
                      startsAt: eachObj.endsAt,
                      endsAt: formValues.launchEndsAt,
                      priceInCents: numberFromBE,
                      paymentMethods: [],
                    },
                  ],
                },
              }))
            }
          />
        )}
      </VerticalContainers>
    </>
  )
}

export const StepsSub = ({ isMobile, stateProps }) => {
  const {
    formValues,
    setFormValues,
    discountPercentage,
    priceWithDiscount,
    minimumEndAtDate,
    minimumCanCheckoutUntilDate,
  } = stateProps

  return (
    <VerticalContainers gap={isMobile ? '1rem' : '1.5rem'}>
      {formValues?.launchPricing?.options?.map((eachObj, index, mappedArr) => (
        <StepComponent
          key={index}
          isMobile={isMobile}
          index={index}
          eachObj={eachObj}
          mappedArr={mappedArr}
          stateProps={{
            formValues,
            setFormValues,
            discountPercentage,
            priceWithDiscount,
            minimumEndAtDate,
            minimumCanCheckoutUntilDate,
          }}
        />
      ))}
    </VerticalContainers>
  )
}
