import React from 'react'

export const AccessIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M16 23.0001C19.866 23.0001 23 19.8661 23 16.0001C23 12.1341 19.866 9.00006 16 9.00006C12.134 9.00006 9 12.1341 9 16.0001C9 19.8661 12.134 23.0001 16 23.0001Z"
      stroke="#01163E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.97363 22.3661L6.82402 24.6867C6.81328 24.8354 6.83546 24.9846 6.88898 25.1238C6.9425 25.263 7.02604 25.3886 7.13366 25.4918C8.57195 26.8561 10.3124 27.8609 12.213 28.4244C12.3562 28.466 12.5068 28.4755 12.6541 28.4523C12.8014 28.4291 12.9417 28.3736 13.0651 28.29L15 27.0002H17L18.9349 28.29C19.0583 28.3737 19.1986 28.4291 19.3459 28.4523C19.4932 28.4756 19.6438 28.4661 19.787 28.4244C21.6876 27.861 23.4281 26.8561 24.8663 25.4918C24.974 25.3887 25.0575 25.263 25.111 25.1239C25.1646 24.9847 25.1867 24.8354 25.176 24.6867L25.0264 22.3662L26.0264 20.6341L28.1109 19.6034C28.2451 19.5383 28.3632 19.4445 28.457 19.3286C28.5508 19.2126 28.6178 19.0775 28.6534 18.9327C29.1157 17.005 29.1157 14.9952 28.6533 13.0675C28.6178 12.9227 28.5507 12.7875 28.457 12.6716C28.3632 12.5557 28.2451 12.4618 28.1109 12.3968L26.0265 11.3661L25.0265 9.634L25.1761 7.31339C25.1868 7.16468 25.1646 7.01543 25.1111 6.87626C25.0576 6.7371 24.9741 6.61145 24.8664 6.50825C23.4282 5.14401 21.6877 4.13916 19.7871 3.57571C19.6439 3.5341 19.4933 3.52456 19.346 3.54779C19.1987 3.57102 19.0584 3.62643 18.935 3.71009L17.0001 4.99991H15.0001L13.0652 3.71003C12.9418 3.62638 12.8015 3.57096 12.6542 3.54773C12.5069 3.5245 12.3563 3.53402 12.2131 3.57562C10.3125 4.13909 8.57206 5.14396 7.13378 6.50823C7.02616 6.61141 6.94261 6.73706 6.88908 6.87622C6.83556 7.01539 6.81338 7.16464 6.82411 7.31335L6.97371 9.63389L5.97371 11.3659L3.88919 12.3967C3.75503 12.4617 3.63687 12.5556 3.54311 12.6715C3.44935 12.7874 3.3823 12.9226 3.34674 13.0674C2.88441 14.9951 2.88442 17.0048 3.34677 18.9326C3.38232 19.0774 3.44936 19.2125 3.54312 19.3285C3.63687 19.4444 3.75503 19.5382 3.88919 19.6033L5.97363 20.634L6.97363 22.3661Z"
      stroke="#01163E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)
