import React from 'react'
import { theme } from '@provi/provi-components'
import { Sidetab, PopupButton } from '@typeform/embed-react'
import { getStorage } from '~/utils'
const { REACT_APP_API_ENV } = process.env

const partnerName = getStorage({ key: 'partner-name' })
const partnerId = getStorage({ key: 'partner-id' })

export const DesktopTypeForm = ({ extraTypeformConfigs, btnText }) => (
  <Sidetab
    buttonText={btnText || 'Feedback'}
    buttonColor={theme.colors.bluePrimary}
    enableSandbox={REACT_APP_API_ENV === 'staging'}
    hidden={
      REACT_APP_API_ENV === 'staging'
        ? { partnername: 'Web Development School', partnerid: '392' }
        : { partnername: partnerName, partnerid: partnerId }
    }
    {...extraTypeformConfigs}
  />
)

export const MobileTypeForm = ({ extraTypeformConfigs, btnText }) => (
  <PopupButton
    enableSandbox={REACT_APP_API_ENV === 'staging'}
    hidden={
      REACT_APP_API_ENV === 'staging'
        ? { partnername: 'Web Development School', partnerid: '392' }
        : { partnername: partnerName, partnerid: partnerId }
    }
    style={{
      border: 'none',
      padding: '0 0.5rem',
      backgroundColor: theme.colors.bluePrimary,
      color: 'white',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '20px',
      width: '100%',
      height: '48px',
      borderRadius: '4px',
    }}
    {...extraTypeformConfigs}
  >
    {btnText || 'Feedback'}
  </PopupButton>
)
