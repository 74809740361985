import { useState, useEffect, useContext, useCallback } from 'react'
import { useToggle } from '~/hooks'

import { TemplateContext } from '~/components/Template/context'
import { getChartDefaultStatus } from '~/services/api'

export const useDefaultStatusChart = (filters) => {
  const { isMobile } = useContext(TemplateContext)
  const [switchState, setSwitchState] = useState(false)
  const [chartLoadingState, toggleChartLoadingState] = useToggle()
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const getChartData = async () => {
      try {
        toggleChartLoadingState()
        const { data } = await getChartDefaultStatus(filters)
        if (!data.error) {
          setChartData(data)
        }
      } catch (error) {
        console.error(error)
      } finally {
        toggleChartLoadingState()
      }
    }
    getChartData()
  }, [filters])

  const whichChartToRender = useCallback((switchStateParams, chartDataParams) => {
    const { nonPaymentOverdueDayFormatted, installmentsInDebtFormatted } = chartDataParams || {}
    return switchStateParams ? nonPaymentOverdueDayFormatted : installmentsInDebtFormatted
  }, [])

  return { isMobile, chartData, switchState, setSwitchState, chartLoadingState, whichChartToRender }
}
