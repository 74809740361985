import { api } from '~/services/api'
import { useQuery, useMutation } from 'react-query'
import { getProviPayVersion } from '~/screens/courses/requests'

// Query Keys
export const IntegrationsQueryKeys = {
  invalidateAllKey: ['memberkit'],
  fetchMemberkitIntegrations: ['memberkit', 'fetchMemberkitIntegrations'],
  fetchMemberkitProducts: ['memberkit', 'fetchMemberkitProducts'],
  fetchMemberkitConnections: ['memberkit', 'fetchMemberkitConnections', 'fetchMemberkitConnectionsFilters'],
  fetchMemberkitConnectionsFilters: ['memberkit', 'fetchMemberkitConnections', 'fetchMemberkitConnectionsFilters'],
}

// API calls
export const fetchMemberkitIntegrations = async () => {
  const { data } = await api.get('/v4/partner-external-integrations/memberkit')
  return data
}

export const fetchMemberkitProducts = async (integrationId) => {
  const { data } = await api.get('/v4/memberkit/list-products', integrationId ? { params: { integrationId } } : {})
  return data
}

export const fetchMemberkitConnections = async (integrationId, params) => {
  const { data } = await api.get(`/v4/memberkit/connections/${integrationId}`, params ? { params } : {})
  return data
}

export const fetchMemberkitConnectionsFilters = async (integrationId) => {
  const { data } = await api.get(`/v4/memberkit/connections/filters/${integrationId}`)
  return data
}

const connectAccountMutation = async (variables) => {
  const { data } = await api.post('/v4/memberkit/add-account', variables)
  return data
}

const connectProductMutation = async (variables) => {
  const { data } = await api.post('/v4/memberkit/connections', variables)
  return data
}
const unlinkAccountMutation = async (integrationId) => {
  const { data } = await api.delete(`/v4/partner-external-integrations/${integrationId}`)
  return data
}

const unlinkProductMutation = async (productId) => {
  const { data } = await api.delete(`/v4/memberkit/unbind-product/${productId}`)
  return data
}

// React-Query Config
const defaultIntegrationsConfig = {
  staleTime: 600000,
  retry: false,
  refetchOnWindowFocus: false,
}

// React-Query Hooks
export const useFetchMemberkitIntegrations = (extraConfigObj) => {
  return useQuery(IntegrationsQueryKeys.fetchMemberkitIntegrations, fetchMemberkitIntegrations, {
    ...defaultIntegrationsConfig,
    ...extraConfigObj,
  })
}

export const useFetchMemberkitProducts = (integrationId, extraConfigObj) => {
  return useQuery(
    [...IntegrationsQueryKeys.fetchMemberkitProducts, integrationId],
    () => fetchMemberkitProducts(integrationId),
    {
      ...defaultIntegrationsConfig,
      ...extraConfigObj,
    },
  )
}

export const useFetchMemberkitConnections = (integrationId, params, extraConfigObj) => {
  const { page, filters } = params || {}
  const modifiedParams = { page, CourseId: filters }
  return useQuery(
    [...IntegrationsQueryKeys.fetchMemberkitConnections, integrationId, page, filters],
    () => fetchMemberkitConnections(integrationId, modifiedParams),
    {
      ...defaultIntegrationsConfig,
      ...extraConfigObj,
    },
  )
}

export const useFetchMemberkitConnectionsFilter = (integrationId, extraConfigObj) => {
  return useQuery(
    IntegrationsQueryKeys.fetchMemberkitConnectionsFilters,
    () => fetchMemberkitConnectionsFilters(integrationId),
    {
      ...defaultIntegrationsConfig,
      ...extraConfigObj,
    },
  )
}

export const useFetchProviPayVersion = () => {
  return useQuery('provipay-version', getProviPayVersion, defaultIntegrationsConfig)
}

export const useConnectAccountMutation = (extraConfigObj) => {
  return useMutation(connectAccountMutation, extraConfigObj)
}

export const useConnectProductMutation = (extraConfigObj) => {
  return useMutation(connectProductMutation, extraConfigObj)
}

export const useUnlinkAccountMutation = (extraConfigObj) => {
  return useMutation(unlinkAccountMutation, extraConfigObj)
}

export const useUnlinkProductMutation = (extraConfigObj) => {
  return useMutation(unlinkProductMutation, extraConfigObj)
}
