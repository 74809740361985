import { useEffect, useState, useCallback } from 'react'
import Fuse from 'fuse.js'
import { isFunction, isArray } from 'lodash'
import { showToast } from '@provi/provi-components'

import {
  asyncLocalStorage,
  formatValue,
  getStorage,
  getActiveUser,
  handleStorage,
  getAbsoluteEntranceByPercent,
  getBiggerOptionThenAbsoluteAndPercentValue,
} from '~/utils'
import { getCourses, updateVisible, getAccess } from '~/services/api'

const INITIAL_VALUE = 6

const OPTIONS = {
  shouldSort: true,
  threshold: 0.25, // 0.4
  location: 4,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
}

export const useProduct = (
  setIsLoading,
  setCurrent,
  selectedOptionsCampaign,
  valueCampaign,
  setSelectedOptionsCampaign,
  setValueCampaign,
  goToPage,
  activePage,
  setActivePage,
) => {
  const [search, setSearch] = useState('') // string pesquisada no search bar

  const [options, setOptions] = useState([]) // todas as options que vem do back

  const [visibleOptions, setVisibleOptions] = useState([]) // todas as options que vem do back VISIVEIS
  const [invisibleOptions, setInvisibleOptions] = useState([]) // todas as options que vem do back INVISIVEIS

  const [showSeeMoreVisible, setShowSeeMoreVisible] = useState(false) // botao ver mais visible
  const [showSeeMoreInvisible, setShowSeeMoreInvisible] = useState(false) // botao ver mais invisible

  const [countOptions, setCountOptions] = useState(INITIAL_VALUE) // numero de itens exibidos

  // modal
  const [isShowModal, SetIsShowModal] = useState(false)
  const [location, SetLocation] = useState(false)
  const [optionChange, SetOptionChange] = useState()
  const [visibleOpt, setVisibleOpt] = useState({ tabIndex: 0 })
  const [userAccess, setUserAccess] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [archorElRef, setArchorElRef] = useState(null)
  const [loginUrl, setLoginUrl] = useState('')
  const [calcConfig, setCalcConfig] = useState({})
  const [isShowCalcModal, setIsShowCalcModal] = useState(false)

  const sliceOptions = useCallback(
    (items) => {
      if (!isArray(items)) return []

      return items.slice(0, countOptions)
    },
    [visibleOptions, invisibleOptions],
  )

  const handleClick = (event, ref) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setArchorElRef(ref)
  }

  const handleClose = (event) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()

    setAnchorEl(null)
  }

  const checkAccess = async () => {
    try {
      const { data } = await getAccess()
      const { data: requestData } = data
      const { abbr, enabledProviPay } = requestData

      setUserAccess(abbr)

      return { enabledProviPay }
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    setCurrent(50, 1)
    try {
      localStorage.removeItem('duplicate-course')
    } catch (error) {
      //
    }

    if (activePage !== 'campaigns') setActivePage('campaigns')

    const getData = async () => {
      try {
        const { enabledProviPay } = await checkAccess()

        setIsLoading(true)

        const partnerSlug = getStorage({ key: 'partner-slug' })
        const selectedOptionsCampaignStorage = getStorage({ key: 'selectedOptionsCampaign' }) || selectedOptionsCampaign || []
        const selectedOptionsValueCampaignStorage = getStorage({ key: 'selectedOptionsValueCampaign' }) || valueCampaign || 0

        setSelectedOptionsCampaign((isArray(selectedOptionsCampaignStorage) && selectedOptionsCampaignStorage) || [])
        setValueCampaign(selectedOptionsValueCampaignStorage || 0)

        let url = ''
        if (enabledProviPay) {
          url = `http://pay.principia.net/courses/${partnerSlug}`
        } else {
          url = `https://login.principia.net/${partnerSlug}`
        }

        setLoginUrl(url)

        const response = await getCourses()
        const courses = response.data

        const visible = []
        const invisible = []

        if (courses && courses.length > 0) {
          courses.forEach((course) => {
            course.formattedValue = formatValue(course.price_in_cents)
            if (course.visible) {
              visible.push(course)
            } else {
              invisible.push(course)
            }
          })

          if (visible.length > INITIAL_VALUE) setShowSeeMoreVisible(true)
          if (invisible.length > INITIAL_VALUE) setShowSeeMoreInvisible(true)
        } else {
          showToast('Nenhum curso encontrado')
        }

        setOptions(courses)
      } catch (error) {
        showToast('Ops, ocorreu algum erro ao listar os cursos 😔')
      } finally {
        setIsLoading(false)
      }
    }

    getData()
  }, [])

  const handleModal = (bool, l, option) => {
    SetIsShowModal(bool)
    SetLocation(l)
    SetOptionChange(option)
  }

  const handleCalcModal = () => {
    setIsShowCalcModal(!isShowCalcModal)
  }

  const getSlice = () => {
    let result = []
    let courses = []

    const cleanOptions = options?.filter((option) => !selectedOptionsCampaign.find((opt) => opt.name === option.name))
    const fuse = new Fuse(cleanOptions, OPTIONS)

    result = fuse.search(search)
    courses = search ? result.map((res) => res.item) : cleanOptions

    const visible = []
    const invisible = []

    courses?.forEach((course) => {
      if (course.visible) {
        visible.push(course)
      } else {
        invisible.push(course)
      }
    })

    setVisibleOptions(visible)
    setInvisibleOptions(invisible)

    setShowSeeMoreVisible(countOptions < visible.length)
    setShowSeeMoreInvisible(countOptions < invisible.length)
  }

  useEffect(() => {
    getSlice()
  }, [countOptions, search, selectedOptionsCampaign, options])

  const handleSearchValue = (e) => {
    setSearch(e.target.value)
  }

  const handleOption = async (item) => {
    const newValue = valueCampaign + item.price_in_cents

    setSelectedOptionsCampaign([...selectedOptionsCampaign, item])
    setValueCampaign(newValue)

    const activeUser = getActiveUser()
    await handleStorage({ key: 'selectedOptionsCampaign', user: activeUser, value: [...selectedOptionsCampaign, item] })
    await handleStorage({ key: 'selectedOptionsValueCampaign', user: activeUser, value: newValue })
  }

  const revertOption = async (revertItem) => {
    const newOptions = selectedOptionsCampaign.filter((opt) => opt !== revertItem)
    setSelectedOptionsCampaign(newOptions)

    const newValue = valueCampaign - revertItem.price_in_cents
    setValueCampaign(newValue)

    const activeUser = getActiveUser()
    await handleStorage({ key: 'selectedOptionsCampaign', user: activeUser, value: newOptions })
    await handleStorage({ key: 'selectedOptionsValueCampaign', user: activeUser, value: newValue })
  }

  const incrementNumberOfCourses = () => {
    setCountOptions(countOptions + INITIAL_VALUE)
  }

  const handleSubmit = async () => {
    const activeUser = getActiveUser()
    await handleStorage({ key: 'selectedOptionsCampaign', user: activeUser, value: selectedOptionsCampaign })
    await handleStorage({ key: 'selectedOptionsValueCampaign', user: activeUser, value: valueCampaign })

    goToPage('campanha/criar-campanha')
  }

  const handleEye = (event, condition, option) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()
    handleModal(true, condition, option)
    handleClose()
  }

  const submitHandleEye = async (condition, option) => {
    setIsLoading(true)
    if (condition === 'visible') {
      try {
        await updateVisible(option.id, 'invisible')
        const newSearchOptions = visibleOptions.filter((opt) => opt !== option)
        setVisibleOptions([...newSearchOptions])
        setInvisibleOptions([option, ...invisibleOptions])
        showToast('Produto alterado para invisível')
      } catch (error) {
        showToast('Ocorreu um erro ao alterar o item')
      }
    } else if (condition === 'invisible') {
      try {
        await updateVisible(option.id, 'visible')
        const newSearchOptions = invisibleOptions.filter((opt) => opt !== option)
        setInvisibleOptions([...newSearchOptions])
        setVisibleOptions([option, ...visibleOptions])
        showToast('Produto alterado para visível')
      } catch (error) {
        showToast('Ocorreu um erro ao alterar o item')
      }
      setVisibleOpt({ tabIndex: 0 })
    }
    handleModal(false)
    setIsLoading(false)
  }

  const handleDuplicateCourse = async (event, course, activeCondition) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()

    setIsLoading(true)
    await asyncLocalStorage.setItem('duplicate-course', JSON.stringify({ ...course, visible: activeCondition }))

    goToPage('campanha/criar-produto')
  }

  const handleClearOptions = async () => {
    try {
      setIsLoading(true)

      const activeUser = getActiveUser()
      await handleStorage({ key: 'selectedOptionsCampaign', user: activeUser, value: [] })
      await handleStorage({ key: 'selectedOptionsValueCampaign', user: activeUser, value: 0 })

      setSelectedOptionsCampaign([])
      setValueCampaign(0)
    } catch (error) {
      showToast('Ocorreu um erro ao limpar os cursos selecionados')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCalcClick = async (event, product) => {
    if (event && isFunction(event.stopPropagation)) event.stopPropagation()

    try {
      setIsLoading(true)
      const { id, price_in_cents, absoluteUpfrontValue, percentualUpfrontValue } = product
      const formattedAbsoluteUpfrontValue = absoluteUpfrontValue && absoluteUpfrontValue * 100

      const entranceWithPercent = getAbsoluteEntranceByPercent(percentualUpfrontValue, price_in_cents)
      const biggestDefaultCourseOptions = getBiggerOptionThenAbsoluteAndPercentValue(
        entranceWithPercent,
        formattedAbsoluteUpfrontValue,
      )

      setCalcConfig({
        amountInCents: price_in_cents,
        coursesIds: [id],
        discountInCents: 0,
        ...(biggestDefaultCourseOptions ? { upfrontInCents: Number(biggestDefaultCourseOptions) } : {}),
      })

      handleCalcModal()
    } catch (error) {
      showToast('Não foi possivel calcular o parcelamento')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleSearchValue,
    search,
    handleOption,
    revertOption,
    incrementNumberOfCourses,
    showSeeMoreVisible,
    showSeeMoreInvisible,
    handleSubmit,
    handleEye,
    isShowModal,
    handleModal,
    location,
    submitHandleEye,
    optionChange,
    formatValue,
    visibleOpt,
    setVisibleOpt,
    userAccess,
    handleClick,
    handleClose,
    anchorEl,
    archorElRef,
    handleDuplicateCourse,
    handleCalcClick,
    isShowCalcModal,
    handleCalcModal,
    calcConfig,
    loginUrl,
    handleClearOptions,
    visibleOptions,
    invisibleOptions,
    sliceOptions,
  }
}
