import React from 'react'
import { Drawer } from '@material-ui/core'
import { CloseIcon, CopyIcon, WarningIcon } from '~/assets/svgs/v2'
import { theme } from '@provi/provi-components'
import { useFlexCalculator } from './hooks'
import { copyString } from 'helpers/copyAndPaste'
import masks from '~/themes/masks'

import * as S from './styles'

const SelectedOptionLines = ({ title, value }) => {
  return (
    <S.HeaderAttributes color={title === 'Desconto' && '#DE0C4B'}>
      <h3>{title}</h3>
      <span>{value}</span>
    </S.HeaderAttributes>
  )
}

const UpFrontLines = ({ hasRadio, eachUpFrontOption, index, currentSelectedOption, setCurrentSelectedOption }) => {
  return (
    <S.Amounts>
      {hasRadio && (
        <S.Radio
          name="radio-options-entrance"
          type="radio"
          value={eachUpFrontOption.upfrontAmountInPercent}
          checked={currentSelectedOption === index}
          onChange={() => setCurrentSelectedOption(index)}
        />
      )}
      {`${masks.currency(eachUpFrontOption.upfrontAmountInCents)} (${(eachUpFrontOption.upfrontAmountInPercent * 100).toFixed(
        2,
      )}% do valor total)`}
    </S.Amounts>
  )
}

const InstallmentLines = ({ eachInstallmentObj, buildInstallments }) => {
  return <S.Amounts>{buildInstallments(eachInstallmentObj)} </S.Amounts>
}

export const FlexCalculator = ({ show, handleModal, calcConfig }) => {
  const {
    calcData,
    buildHeaderAttributesCalculator,
    currentSelectedOption,
    setCurrentSelectedOption,
    selectedOptionInstallments,
    buildInstallments,
    copyText,
  } = useFlexCalculator({ calcConfig, handleModal })

  return (
    <>
      {calcData && (
        <Drawer anchor={'right'} open={show} onClose={handleModal} transitionDuration={600}>
          <S.DrawerWrapper>
            <CloseIcon onClick={handleModal} />
            <S.Container>
              <S.SubContainer>
                <S.Title>Resumo do Financiamento</S.Title>
                <S.Content>
                  {buildHeaderAttributesCalculator(calcData).map((eachLine) => (
                    <SelectedOptionLines key={eachLine.attribute} title={eachLine.attribute} value={eachLine.value} />
                  ))}
                </S.Content>
              </S.SubContainer>
              {!calcData?.selectedOption && (
                <S.Warning>
                  <WarningIcon />
                  Nenhuma condição de parcelamento foi selecionada para esse CRID até agora.
                </S.Warning>
              )}
              <S.SubContainer>
                <S.Title>Outras simulações</S.Title>

                <S.Content>
                  <S.SubtitleSimulations>
                    Entrada
                    <button onClick={() => copyString(copyText, 'linkAndText')}>
                      <CopyIcon color={theme.colors.bluePrimary} />
                    </button>
                  </S.SubtitleSimulations>
                  {calcData.otherOptions.map((eachUpFrontOption, index, arr) => (
                    <UpFrontLines
                      key={index}
                      hasRadio={calcData.otherOptions.length > 1}
                      eachUpFrontOption={eachUpFrontOption}
                      index={index}
                      currentSelectedOption={currentSelectedOption}
                      setCurrentSelectedOption={setCurrentSelectedOption}
                      arr={arr}
                    />
                  ))}

                  <S.SubtitleWithBorder>Parcelas</S.SubtitleWithBorder>
                  <S.Installments>
                    {selectedOptionInstallments?.installments?.map((eachInstallmentObj, index) => (
                      <InstallmentLines
                        key={index}
                        eachInstallmentObj={eachInstallmentObj}
                        buildInstallments={buildInstallments}
                      />
                    ))}
                  </S.Installments>
                </S.Content>
              </S.SubContainer>
            </S.Container>
          </S.DrawerWrapper>
        </Drawer>
      )}
    </>
  )
}
