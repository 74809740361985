import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const FallbackContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  place-items: center;
  border-radius: 5px;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  background-color: ${({ bgColor }) => bgColor || theme.colors.bluePrimaryLight};

  ${theme.breakpoints.down('md')} {
    margin-top: ${({ marginTop }) => marginTop || 'revert'};
  }
`
export const StyledText = styled.p`
  margin: 0;
  font-size: 13.2px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`
