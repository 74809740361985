import { useEffect, useContext, useCallback } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { showToast } from '@provi/provi-components'
import { getStorage } from '~/utils'
import { useQuery, useMutation } from 'react-query'
import { queryClient } from '~/stores/react-query'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getProfileFinance, postProfileFinance } from '~/services/api'

import { getRegexByBank } from './utils/getRegexByBank'

export const useFinanceTab = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const partnerName = getStorage({ key: 'partner-name' })

  const { data: financeData } = useQuery([`${partnerName}-finance`, 'account'], getProfileFinance, {
    staleTime: 300000,
    onError: () => {
      showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
    },
  })

  const populateInputs = useCallback(
    ({ bankAccount, iuguAccount, isDefault }) => {
      const { account: { agency, accountNumber, bankNumber, accountType, accountNumberDigit, agencyDigit } = {} } =
        bankAccount || {}
      const { metadata: { config: { withdraw } = {} } = {} } = iuguAccount || {}

      setFieldValue('withdraw', !isDefault && withdraw ? withdraw : { auto: false, month: false })
      setFieldValue('bankNumber', bankNumber)
      setFieldValue('accountType', accountType)
      setFieldValue('agency', agency)
      setFieldValue('agencyDigit', agencyDigit)
      setFieldValue('account', accountNumber)
      setFieldValue('accountDigit', accountNumberDigit)
    },
    [setFieldValue],
  )

  const makePostObject = (valuesFromForm) => {
    return {
      bankAccount: {
        agency: valuesFromForm.agency,
        agencyDigit: valuesFromForm.agencyDigit,
        account: valuesFromForm.account,
        accountDigit: valuesFromForm.accountDigit,
        bankNumber: valuesFromForm.bankNumber,
        accountType: valuesFromForm.accountType,
      },
      iuguAccount: {
        accountId: financeData.data.iuguAccount.id,
        withdraw: valuesFromForm.withdraw,
      },
    }
  }

  const postMutation = useMutation(postProfileFinance, {
    onMutate: () => {
      setIsLoading(true)
    },
    onError: () => {
      setIsLoading(false)
      showToast('Algo de errado aconteceu atualizando suas informações! 😔 Por favor, tente novamente.')
    },
    onSettled: (data) => {
      setIsLoading(false)
      const { data: response } = data
      if (response?.error) {
        showToast(response.error.message)
      }
      if (response?.bankAccount || response?.iuguAccount) {
        showToast('Dados atualizados com sucesso 😀')
        queryClient.invalidateQueries(`${partnerName}-finance`)
      }
    },
  })

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      withdraw: '',
      bankNumber: '',
      accountType: '',
      agency: '',
      agencyDigit: '',
      account: '',
      accountDigit: '',
    },
    validate: (value) => {
      let errors = {}

      if (value.bankNumber) {
        const {
          regexAgency,
          regexAccount,
          agencyLengthDigits,
          accountLengthDigits,
          agencyAfterDigit,
          accountAfterDigit,
        } = getRegexByBank(value.bankNumber)

        if (!regexAgency.test(value.agency)) errors.agency = `O campo deve ter ${agencyLengthDigits} dígitos`

        if (!regexAccount.test(value.account))
          value.bankNumber === '260'
            ? (errors.account = `O campo deve ter entre 8 e 10 dígitos`)
            : (errors.account = `O campo deve ter ${accountLengthDigits} dígitos`)

        if (agencyAfterDigit && value.agencyDigit === '') errors.agencyDigit = 'Esse campo precisa ser preenchido'

        if (accountAfterDigit && value.accountDigit === '') errors.agencyDigit = 'Esse campo precisa ser preenchido'
      }

      return errors
    },
    validationSchema: Yup.object({
      withdraw: Yup.object().required('Selecione uma configuração de saque'),
      bankNumber: Yup.string().required('Selecione uma instituição'),
      accountType: Yup.string().required('Selecione um tipo de conta'),
      agency: Yup.string().required('Preencha o campo Agência'),
      agencyDigit: Yup.string().nullable(),
      account: Yup.string().required('Preencha o campo Conta'),
      accountDigit: Yup.string().required('Preencha o campo Dígito'),
    }),
    onSubmit: () => {
      postMutation.mutateAsync(makePostObject(values))
    },
  })

  useEffect(() => {
    if (financeData) {
      populateInputs(financeData?.data)
    }
  }, [financeData, populateInputs])

  return {
    isMobile,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
    financeData,
  }
}
