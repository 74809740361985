import React from 'react'
import { useDashboardDatePicker } from './hooks'
import { BlueCalendar } from '~/assets/svgs/blue-calendar'
import { ptBR } from 'date-fns/locale'
import { ButtonV3 } from '@provi/provi-components'

import { CalendarBtnContainer, CalendarText, MobileCalendarWrapper, CalendarFixer } from './styles'

const renderCalendar = (
  DateRangeParams,
  DateRangePickerParams,
  translatedStaticRangesParams,
  translatedInputRangesParams,
  isMobileParams,
  dateRangeStateParams,
  setDateRangeFnParams,
) => {
  return isMobileParams ? (
    <>
      <MobileCalendarWrapper>
        <DateRangeParams
          onChange={(item) => setDateRangeFnParams([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={dateRangeStateParams}
          locale={ptBR}
          weekdayDisplayFormat="EEEEEE"
        />
      </MobileCalendarWrapper>
    </>
  ) : (
    <>
      <DateRangePickerParams
        onChange={(item) => setDateRangeFnParams([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        ranges={dateRangeStateParams}
        locale={ptBR}
        weekdayDisplayFormat="EEEEEE"
        staticRanges={translatedStaticRangesParams}
        inputRanges={translatedInputRangesParams}
      />
    </>
  )
}

export const DashboardDatePicker = ({ handleCalendarFilter, dateRangeState, setDateRangeFn, isMobile, labelDateButton }) => {
  const {
    DateRange,
    DateRangePicker,
    translatedStaticRanges,
    translatedInputRanges,
    showCalendar,
    handleCalendar,
  } = useDashboardDatePicker()

  return (
    <>
      <CalendarBtnContainer onClick={handleCalendar}>
        <BlueCalendar />
        <CalendarText>{labelDateButton}</CalendarText>
      </CalendarBtnContainer>

      {showCalendar && (
        <CalendarFixer>
          {renderCalendar(
            DateRange,
            DateRangePicker,
            translatedStaticRanges,
            translatedInputRanges,
            isMobile,
            dateRangeState,
            setDateRangeFn,
          )}
          <ButtonV3
            text="Filtrar"
            onClick={() => {
              handleCalendarFilter()
              handleCalendar()
            }}
            height={'32px'}
          />
        </CalendarFixer>
      )}
    </>
  )
}
