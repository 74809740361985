import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'

import { postCourseClassesV4, putCourseClasses, getCourses } from '~/services/api'

import formatDate from '~/helpers/formatDate'

export const useCourseClass = (setIsLoading, activePage, setActivePage, goToPage, editOption) => {
  const [nameError, setNameError] = useState('')
  const [startsAtError, setStartsAtError] = useState('')
  const [endsAtError, setEndsAtError] = useState('')
  const [values, setValues] = useState({ name: '', startsAt: '', endsAt: '', courseId: 0 })
  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [courseOptions, setCourseOptions] = useState([])
  const [courseIdValue, setCourseIdValue] = useState({})

  useEffect(() => {
    setIsLoading(true)

    if (activePage !== 'course-class') {
      setActivePage('course-class')
    }

    const getFormData = async () => {
      const { data } = await getCourses()
      const courses = data.map((course) => {
        return {
          label: course.name,
          value: course.id,
          active: true,
        }
      })
      setCourseOptions(courses)

      if (editOption) {
        setValues({
          name: editOption.name,
          startsAt: editOption.startsAt,
          endsAt: editOption.endsAt,
          courseId: editOption.CourseId,
        })
        setCourseIdValue(courses.find((course) => course.value === editOption.CourseId))
      }

      setIsLoading(false)
    }

    getFormData()
  }, [editOption])

  useEffect(() => {
    setIsButtonDisable(
      !(values.name && values.courseId && values.startsAt && values.endsAt && !(nameError || startsAtError || endsAtError)),
    )
  }, [values, nameError, startsAtError, endsAtError])

  const startsAtDateIsGreaterThanToday = () => {
    return new Date() <= new Date(`${values.startsAt} 23:59:59`)
  }

  const endsAtDateIsGreaterThanStartsAtDate = () => {
    return new Date(`${values.endsAt}`) > new Date(`${values.startsAt}`)
  }

  const validate = (e, field) => {
    let valueInput = e.target.value
    switch (field) {
      case 'name':
        if (!valueInput) {
          setNameError('Nome é obrigatório')
        } else if (valueInput.length < 8) {
          setNameError('Mínimo de 8 digitos para o nome da turma')
        } else {
          setNameError('')
        }
        break
      case 'startsAt':
        if (!valueInput) {
          setStartsAtError('Data de início é obrigatória')
        } else if (!startsAtDateIsGreaterThanToday()) {
          setStartsAtError('Data de início deve ser ao menos igual a data atual')
        } else {
          setStartsAtError('')
        }
        break
      case 'endsAt':
        if (!valueInput) {
          setEndsAtError('Data de encerramento é obrigatória')
        } else if (!endsAtDateIsGreaterThanStartsAtDate()) {
          setEndsAtError('Data de encerramento deve ser maior que a data de início')
        } else {
          setEndsAtError('')
        }
        break
      default:
        break
    }
  }

  const createCourseClass = async () => {
    const { name, startsAt, endsAt, courseId: CourseId } = values
    await postCourseClassesV4({
      name,
      CourseId,
      startsAt: formatDate(startsAt),
      endsAt: formatDate(endsAt),
    })
    showToast('Turma cadastrada com sucesso')
  }

  const updateCourseClass = async () => {
    const { name, startsAt, endsAt } = values
    await putCourseClasses(editOption.id, {
      name,
      startsAt: formatDate(startsAt),
      endsAt: formatDate(endsAt),
    })
    showToast('Turma alterada com sucesso')
  }

  const handleSubmitCourseClasses = async () => {
    try {
      setIsLoading(true)

      const courseClassActions = {
        create: createCourseClass,
        update: updateCourseClass,
      }

      await courseClassActions[!editOption ? 'create' : 'update']()
      goToPage('turmas/listar-turmas')
    } catch (error) {
      showToast(error.message || 'Houve um erro ao cadastrar a turma')
    } finally {
      setIsLoading(false)
    }
  }

  const handleValue = async (opt, v, type = null) => {
    let formatedValue

    switch (type) {
      case 'date':
        if (v.length > 10) {
          const splitedValue = v.split('-')
          formatedValue = `${splitedValue[0].substr(0, 4)}-${splitedValue[1]}-${splitedValue[2]}`
        }
        break
      case 'select':
        formatedValue = v.value
        if (opt === 'courseId') {
          setCourseIdValue(v)
        }
        break
      default:
        break
    }

    setValues({ ...values, ...{ [opt]: formatedValue || v } })
  }

  const handleGoBack = () => {
    goToPage('turmas/listar-turmas')
  }

  return [
    validate,
    nameError,
    startsAtError,
    endsAtError,
    handleSubmitCourseClasses,
    handleValue,
    values,
    isButtonDisable,
    courseIdValue,
    courseOptions,
    handleGoBack,
  ]
}
