import React from 'react'
import { TextLinkWrapped } from './styles'

export const TextLink = ({ text, onClick, color, id }) => {
  return (
    <TextLinkWrapped color={color} onClick={onClick} id={id}>
      {text}
    </TextLinkWrapped>
  )
}
