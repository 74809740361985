import React from 'react'
import { InputFieldV3, theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { InputContainer, TextFlex } from '../../styles'

export const PartnerSkuInput = ({ isMobile, formik }) => {
  return (
    <InputContainer>
      <TextFlex>
        <BaseTypography fontWeight={600} fontSize={isMobile ? '13px' : '16px'}>
          Código interno
        </BaseTypography>
        <BaseTypography fontSize={isMobile ? '11px' : '13px'} lineHeight={'20px'}>
          (opcional)
        </BaseTypography>
      </TextFlex>
      <BaseTypography fontSize={isMobile ? '11px' : '13px'} color={theme.colors.greyPrimary}>
        Você pode incluir um código personalizado para unificar os registros do seu negócio ou auxiliar na correlação entre
        diferentes plataformas. Essa é uma forma simples de descrever e diferenciar os cursos do seu catálogo. O código não é
        público.
      </BaseTypography>

      <InputFieldV3
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        width="100%"
        type="text"
        name="internCode"
        value={formik.values.internCode}
        hasError={formik.errors.internCode && formik.touched.internCode}
        errorMessage={formik.errors.internCode}
      />
    </InputContainer>
  )
}
