import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const PageWrapper = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
  background-color: #f6f8fb;
  min-height: 100vh;

  ${theme.breakpoints.down('md')} {
    padding: initial;
  }

  .MuiButton-label {
    ${theme.breakpoints.down('md')} {
      font-size: 13px;
      line-height: 20px;
    }
  }
`

export const Container = styled.div`
  display: grid;
  gap: 2rem;
  padding: 2rem;
  background-color: #f6f8fb;
  max-width: 1200px;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`

export const HeaderContainer = styled.div`
  display: grid;
  gap: 2rem;
  background-color: #f6f8fb;
`

export const Divider = styled.div`
  border-top: 1px solid #cfcfcf;
  grid-column: 1 / -1;

  ${theme.breakpoints.down('md')} {
    margin-top: 4rem;
  }
`

export const ComponentContainer = styled.div``
