import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ChargebackModalWrapper = styled.div`
  * {
    font-family: 'Montserrat', sans-serif;
  }

  align-self: flex-end;

  > button {
    background: none;
    border: 0;
    height: auto;
  }
`

export const ChargebackModalContent = styled.div`
  width: 100%;
  height: fit-content;
  padding: calc(27px - 16px) calc(29px - 16px) calc(33px - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 21px;
  background: #fff;
  font-family: inherit;
`

export const ChargebackModalTitle = styled.div`
  align-self: flex-start;
  margin-left: 5px;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${theme.colors.blue900};
`

export const ChargebackCloseModal = styled.button`
  background: none;
  border: 0;

  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${theme.colors.pink900};
  cursor: pointer;

  :hover {
    font-weight: 700;
  }
`
