import React from 'react'
import { theme } from '@provi/provi-components'

import { TextSwitchContainer, FlexContainers, StyledText } from './styles'

export const TextSwitch = ({ leftText, LeftIcon, rightText, RightIcon, switchState, toggleSwitchState }) => {
  return (
    <TextSwitchContainer>
      <FlexContainers onClick={toggleSwitchState} isLeft state={switchState}>
        {LeftIcon && <LeftIcon color={switchState ? 'white' : theme.colors.bluePrimary} />}
        <StyledText state={switchState}>{leftText}</StyledText>
      </FlexContainers>
      <FlexContainers onClick={toggleSwitchState} isRight state={!switchState}>
        {RightIcon && <RightIcon color={switchState ? theme.colors.bluePrimary : 'white'} />}
        <StyledText state={!switchState}>{rightText}</StyledText>
      </FlexContainers>
    </TextSwitchContainer>
  )
}
