import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { fonts } from '~/themes/fonts'

export const LogoutButton = styled.a`
  color: ${theme.colors.pink900};
  font-size: 16;
  font-family: ${fonts.century.bold};
  text-decoration: underline;
  cursor: pointer;
`

export const InputWrapper = styled.div`
  padding: 10px 0px;
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    width: 350px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
  ${theme.breakpoints.down('md')} {
    max-width: 100%;
    width: 100%;
  }
`

export const Header = styled.div`
  height: 64px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.grey400};
`

export const Container = styled.div`
  background-color: #e5e5e5;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${theme.breakpoints.down('md')} {
    height: 100%;
  }
`

export const Content = styled.div`
  margin-top: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    margin-top: 95px;
  }
`

export const RedTitle = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${theme.colors.pink900};
  font-family: 'CenturyGothicBold';

  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const SubTitle = styled.div`
  font-size: 30px;
  line-height: 36px;
  margin-top: 10px;
  color: ${theme.colors.blue900};
`

export const WrapperLogout = styled.div`
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
    color: ${theme.colors.pink900};
  }
`

export const BoxOption = styled.div`
  width: 225px;
  height: 170px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  padding: 25px;

  font-size: 20px;
  line-height: 25px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.blue900};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  cursor: pointer;
  margin: 10px;

  position: relative;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 160px;
  }
`

export const WrapperIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;
`

export const WrapperText = styled.div``

export const InputError = styled.h1`
  font-family: ${fonts.century.regular};
  color: ${theme.colors.pink800};
  font-size: 14px;
  margin-top: 5px;
`
