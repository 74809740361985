import React from 'react'
import { Container, Box } from './styles'
import { Text } from '~/themes/styles'

/**
 * @typedef {object} IProps
 * @property {{ label: string, color: string }} status
 * @property {boolean} isSmall
 */

/** @type {React.FC<IProps>} */
export const StatusBox = ({ status, isSmall }) => {
  return (
    <>
      {status && status.label && status.color && (
        <Container>
          <Box color={status.color} isSmall={isSmall}>
            <Text size="12px" color={status.fontColor} bold>
              {status.label}
            </Text>
          </Box>
        </Container>
      )}
    </>
  )
}
