/* eslint-disable react/prop-types */
import React, { useContext } from 'react'

import { LoadingProvi } from '@provi/provi-components'
import { View } from './styles'
import { TemplateContext } from './context'
import { LogoutModal } from '../LogoutModal'
import { Header } from '../Header'
import { useScreenMeasure } from '~/hooks'
import { DrawerMenuProvider } from '~/components/DrawerMenu/context'

export const Template = (props) => {
  const { isHeader, children } = props
  const { isLoading, showLogoutModal, handleLogoutModal, logout, activeScreen, goToPage, accessData } = useContext(
    TemplateContext,
  ) // activeScreen,

  //eslint-disable-next-line
  const [_, isMobile] = useScreenMeasure()

  return (
    <>
      <View>
        <DrawerMenuProvider
          goToPage={goToPage}
          isMobile={isMobile}
          handleLogoutModal={handleLogoutModal}
          accessData={accessData}
        >
          {showLogoutModal && <LogoutModal handleModal={handleLogoutModal} logout={logout} />}
          {isHeader && <Header activeScreen={activeScreen} />}
          {/* {isHeader && <Header current={current} total={100} options={options} />} */}
          {isLoading && <LoadingProvi />}
          {children}
        </DrawerMenuProvider>
      </View>
    </>
  )
}

export const LoginTemplate = (props) => {
  const { isHeader, children } = props
  const { isLoading, showLogoutModal, handleLogoutModal, logout, activeScreen } = useContext(TemplateContext) // activeScreen,

  return (
    <>
      {showLogoutModal && <LogoutModal handleModal={handleLogoutModal} logout={logout} />}
      {isHeader && <Header activeScreen={activeScreen} />}
      {isLoading && <LoadingProvi />}
      <View>{children}</View>
    </>
  )
}
