import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TemplateContext } from '~/components/Template/context'
import { api } from '~/services/api'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { showToast, theme } from '@provi/provi-components'
import Sally from '~/assets/images/Saly-1.png'
import { ProviLogo } from '~/assets/svgs/v2'
import { KeyIcon } from '~/assets/svgs/key-icon'

import { Container, ImageContainer, SallyImage, FormContainer, Form, Title, Section, StyledLabel } from './styles'
import { SvgWrapper } from '~/screens/login/styles'
import { TextErrorInput } from '~/components/text-error-input'
import { Input } from '~/components/v2/Input'
import { Button } from '~/components/v2/Button'

function RedefinePassword() {
  const { token } = useParams()
  const { setIsLoading } = useContext(TemplateContext)
  const history = useHistory()
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'A senha tem que ter no minimo 6 caracteres')
      .required('Preencha o campo Digite sua nova senha'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Senha está diferente')
      .required('Preencha o campo Confirme sua nova senha'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async ({ password }) => {
      setIsLoading(true)

      try {
        const { status, data } = await api.post('/password/redefine', { password, token })

        if (status === 200) {
          showToast(data.message)
          history.push('/')
        }
      } catch ({ response }) {
        showToast(response.data.error.message)
      } finally {
        setIsLoading(false)
      }
    },
  })

  return (
    <Container>
      <SallyImage src={Sally} />
      <ImageContainer>
        <SvgWrapper>
          <ProviLogo logoColor={'white'} textColor={'white'} />
        </SvgWrapper>
      </ImageContainer>
      <FormContainer>
        <Form onSubmit={formik.handleSubmit}>
          <Title>Redefina a sua senha</Title>

          <Section>
            <Section>
              <StyledLabel>Digite sua nova senha</StyledLabel>
              <Input
                error={formik.errors.password && formik.touched.password}
                autoFocus
                id="password"
                placeholder="Digite a sua senha"
                icon={<KeyIcon />}
                value={formik.values.password}
                type="password"
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
              />
              {formik.errors.password && formik.touched.password && <TextErrorInput>{formik.errors.password}</TextErrorInput>}
            </Section>

            <Section>
              <StyledLabel>Confirme sua nova senha</StyledLabel>
              <Input
                error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                icon={<KeyIcon />}
                placeholder="Digite a sua senha"
                id="confirmPassword"
                type="password"
                onChange={formik.handleChange('confirmPassword')}
                onBlur={formik.handleBlur('confirmPassword')}
                InputProps={{
                  style: { color: 'white' },
                }}
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <TextErrorInput>{formik.errors.confirmPassword}</TextErrorInput>
              )}
            </Section>

            <Button
              id="button"
              margin="35px auto 0"
              backgroundDisabled={theme.colors.blue800}
              text="Redefinir"
              disabled={
                !formik.values.password ||
                formik.errors.password ||
                !formik.values.confirmPassword ||
                formik.errors.confirmPassword
              }
              type="submit"
            />
          </Section>
        </Form>
      </FormContainer>
    </Container>
  )
}

export { RedefinePassword }
