import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Main = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const Container = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: relative;
`

export const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
  margin-bottom: 50px;
  margin-top: 90px;
`

export const Section = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? `0` : `30px`)};
  width: 500px;

  ${({ align }) =>
    align &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerModal = styled.div`
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const WrapperLabel = styled.div`
  margin-bottom: 10px;
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${theme.colors.pink900};
`

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperIcon = styled.button`
  margin-left: 10px;
  cursor: pointer;
  position: relative;

  border: unset;
  background: unset;
`

export const PopoverText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};

  background-color: #fff;
  max-width: 300px;
  box-shadow: unset;
  padding: 10px;
`

export const ContainerBoxButtons = styled.div`
  display: flex;
  margin-top: 15px;
`

export const BoxButton = styled.div`
  height: 50px;
  width: 50%;
  margin: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.blue900};
  background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.grey400)};
  color: ${({ active }) => (active ? theme.colors.grey400 : theme.colors.blue900)};

  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  cursor: pointer;
  user-select: none;
`

export const OptionsVisibleOnSite = styled.div``

export const WarningText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.pink900};
  text-align: center;
`

export const WarningTextBold = styled.span`
  font-family: 'CenturyGothicBold';
`

export const WrapperGetLink = styled.div``
export const WrapperGetLinkTextLink = styled.div`
  display: flex;
  justify-content: center;
`

export const Message = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothic';
  color: ${theme.colors.blue900};
  text-align: center;
`

export const Bold = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
`

export const Br = styled.br``
