import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'
import { TrashV2 } from '~/assets/svgs/trash-v2'
import { PencilV2 } from '~/assets/svgs/pencil-v2'

export const TrashIcon = styled(TrashV2)`
  cursor: pointer;
`

export const PencilIcon = styled(PencilV2)`
  cursor: pointer;
`

export const IconWrapper = styled.div`
  width: 50px;
  margin-left: auto;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  place-content: end;
`

export const Divider = styled.div`
  background: #cfcfcf;
  height: 1px;
  margin: ${({ margin }) => margin || '18px 0'};
`

export const DividerTransparent = styled.div`
  height: ${({ height }) => height || '24px'};
  width: ${({ width }) => width || '24px'};
`

export const WrapperRow = styled.div`
  position: relative;
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || ''};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width }) => width || ''};
`

export const WrapperColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  width: ${({ width }) => width || '100%'};
  padding-left: ${({ paddingLeft }) => paddingLeft || ''};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || ''};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
  opacity: ${({ opacity }) => opacity || ''};
  align-items: ${({ alignItems }) => alignItems || ''};

  [placeholder] {
    font-size: 14px;
    font-weight: initial;
  }

  label {
    max-width: 100%;
  }
`

export const WrapperInput = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  flex-direction: column;

  align-items: left;
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '18px')};
  margin-bottom: 18px;
  overflow: ${({ overflow }) => overflow || ''};

  [placeholder] {
    font-size: 14px;
    font-weight: initial;
  }

  p {
    font-size: 12px;
  }
`

export const WrapperCheckbox = styled.div`
  display: flex;
  width: 42px;
  height: 45px;
  padding-right: 12px;
  justify-content: right;
  align-items: center;
  position: relative;

  div:first-child {
    padding-right: 18px;
  }
`

export const TitleField = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: ${theme.colors.blue900};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: ${({ isMobile }) => (isMobile ? '18px' : '3px')};
`

export const FlexibilityOptionText = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: ${theme.colors.blue900};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: ${({ isMobile }) => (isMobile ? '8px' : '3px')};
`

export const TextLink = styled.div`
  justify-self: start;
  font-size: ${({ isMobile }) => (isMobile ? 'small' : '15px')};
  line-height: 30px;
  color: ${theme.colors.red100};
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`

export const InputHeader = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.blue900};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledSpan = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #de0c4b;
  font-size: 12px;
  margin-top: 2px;
  padding-left: ${({ paddingLeft }) => paddingLeft || ''};
`

export const ModalBackDrop = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99999;
  background: rgba(0, 11, 60, 0.5);
  -webkit-animation-name: ${modalOpacity};
  -webkit-animation-duration: 0.25s;
  animation-name: ${modalOpacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

export const Modal = styled.div`
  width: 90%;
  max-width: 600px;
  padding: 20px;
  z-index: 1000;
  display: grid;
  grid-auto-flow: row;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  :hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }
`
export const ModalHeader = styled.div`
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  max-width: 330px;
  display: grid;
  grid-auto-flow: column;
  gap: 25px;

  ${theme.breakpoints.down('md')} {
    grid-auto-flow: row;
  }

  div {
    margin: initial;
    width: 150px;
    height: 40px;
  }

  .MuiButtonBase-root {
    padding: 5px 8px;
    width: 150px;
    height: 40px;
  }

  .MuiButton-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`

const modalOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
