import React from 'react'
import { useDefaultRateChart } from './hooks'
import { DefaultRateChartGrid, ChartWrapper, ChartHeader, ChartText } from './styles'
import { DashboardCollapse, ChartLegendsList } from '~/screens/dashboard/components'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'

export const DefaultRateChart = ({ filters }) => {
  const { isMobile, chartData, chartLoadingState } = useDefaultRateChart(filters)

  return (
    <DefaultRateChartGrid>
      <ChartHeader>Alunos negativados e protestados</ChartHeader>
      <ChartText>
        O gráfico mostra o número total de alunos inadimplentes, além de dividi-los entre os que estão em débito e os que foram
        negativados ou protestados em cartório.
      </ChartText>
      <ChartWrapper>
        <LoadingSkeleton isLoading={chartLoadingState} repeat={2} containerHeight="150px" hasHeading hasParagraph>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid stroke="#DCDCDC" vertical={false} />
              <XAxis
                dataKey="type"
                scale="band"
                style={{ fontWeight: 600 }}
                tick={{ fontSize: 10 }}
                tickLine={false}
                tickMargin={isMobile ? 30 : 10}
                interval={0}
                {...(isMobile && { angle: 35, height: 80, dx: 15 })}
              />
              {!isMobile && (
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value: 'Número de alunos',
                    angle: -90,
                    position: 'Left',
                    dx: -30,
                    fill: '#575757',
                  }}
                />
              )}
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Bar name="Número de alunos" maxBarSize={80} dataKey="numberOfStudents" fill="#A4C7FB" isAnimationActive={false}>
                <LabelList
                  dataKey="numberOfStudents"
                  position="top"
                  style={{ fill: 'rgba(65, 65, 65, 0.6)', fontWeight: 600 }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </LoadingSkeleton>
      </ChartWrapper>
      <DashboardCollapse
        headerText="Entenda melhor os conceitos deste gráfico"
        subComponent={<ChartLegendsList legendArr="defaultRateChart" isFromDropdown />}
      />
    </DefaultRateChartGrid>
  )
}
