import React from 'react'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { ButtonWrapper, ContainerButton, useStyles } from './styles'

export const Button = ({
  id,
  disabled,
  onClick,
  text,
  type = 'button',
  backgroundDisabled,
  icon,
  variant,
  responsiveWidth,
  secondary,
  margin,
}) => {
  const classes = useStyles()
  return (
    <ContainerButton id={id} margin={margin} responsiveWidth={responsiveWidth}>
      <ButtonWrapper
        secondary={secondary}
        backgroundDisabled={backgroundDisabled}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {text}
        {icon && <ArrowForward className={classes.icon} />}
      </ButtonWrapper>
    </ContainerButton>
  )
}
