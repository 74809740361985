import React from 'react'
import { Link } from 'react-router-dom'
import { Tags, ActionButton } from '~/components/v2/TableV2/components'
import {
  formatChargebackStatus,
  masks,
  moment,
  handleMobileToggle,
  StyledMobileSpan,
  fallbackString,
} from '~/screens/requests/helpers'
import { handleGoToDetailsPage } from '../helpers/handleGoToDetailsPage'
import { getActiveUser } from '~/utils'

export const chargebacksColumns = [
  {
    Header: 'ID',
    accessor: 'CreditRequestId',
    Cell: ({ value }) => (
      <Link
        to={`/detalhes/${value}?u=${getActiveUser()}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontWeight: 'bold', cursor: 'pointer', color: '#414141' }}
      >
        {value || fallbackString}
      </Link>
    ),
  },
  {
    Header: 'Ações',
    id: 'actions',
    Cell: ({ defaultColumn, row }) => (
      <ActionButton
        row={row}
        text="Contestar"
        onClick={() => handleGoToDetailsPage(defaultColumn, row.original, moment().isSameOrBefore(row.values.expireAt))}
        disabled={!moment().isSameOrBefore(row.values.expireAt)}
      />
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value, row, defaultColumn }) => (
      <span onClick={() => handleMobileToggle(row, defaultColumn)}>
        <Tags value={value} color={formatChargebackStatus(value)} />
      </span>
    ),
    disableFilters: false,
  },
  {
    Header: 'Prazo para contestação',
    accessor: 'expireAt',
    Cell: ({ value, row, defaultColumn }) => (
      <StyledMobileSpan onClick={() => handleMobileToggle(row, defaultColumn)}>
        {moment(value).format('DD/MM/YYYY') || fallbackString}
      </StyledMobileSpan>
    ),
  },
  {
    Header: 'Data do CB',
    accessor: 'requestedAt',
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY') || fallbackString,
  },
  { Header: 'Valor', accessor: 'total', Cell: ({ value }) => masks.currency(value) || fallbackString },
  { Header: 'CPF', accessor: 'CPF', Cell: ({ value }) => masks.cpf(value) || fallbackString },
  { Header: 'E-mail', accessor: 'email', Cell: ({ value }) => value || fallbackString },
]
