import React from 'react'
import { theme } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'

export const ErrorMessage = ({ isMobile, errMessage, showCondition }) => {
  return (
    <>
      {showCondition && (
        <BaseTypography
          fontSize={isMobile ? '11px' : '16px'}
          lineHeight={isMobile ? '16px' : '24px'}
          color={theme.colors.red300}
        >
          {errMessage}
        </BaseTypography>
      )}
    </>
  )
}
