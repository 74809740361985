/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { TemplateContext } from '~/components/Template/context'
import { Route, Redirect } from 'react-router-dom'
import { Template, LoginTemplate } from '~/components/Template'
function PrivateRoute(props) {
  const { component: Component, isFirstScreen, isHeader, isTemplate, permissions, activeOnly, templateIntro, ...rest } = props
  const { accessData } = useContext(TemplateContext)

  const RedirectRoute = <Redirect to="/home" />

  const TemplateIntroRoute = (prop) => (
    <LoginTemplate isFirstScreen={isFirstScreen} isHeader={isHeader}>
      <Component {...prop} />
    </LoginTemplate>
  )

  const checkRenderTemplate = (prop) => {
    if (isTemplate && templateIntro) return <TemplateIntroRoute {...prop} />
    if (isTemplate)
      return (
        <Template isFirstScreen={isFirstScreen} isHeader={isHeader}>
          <Component {...prop} />
        </Template>
      )

    return <Component {...prop} />
  }

  const CorrectRoute = <Route {...rest} render={(prop) => <>{checkRenderTemplate(prop)}</>} />

  if (templateIntro) return <Component />

  if (
    accessData?.accessLevel &&
    !(permissions || []).includes(accessData?.accessLevel) &&
    accessData?.accessLevel !== undefined
  ) {
    return RedirectRoute
  }

  if (activeOnly && !accessData?.active) return RedirectRoute

  return CorrectRoute
}
export default PrivateRoute

PrivateRoute.defaultProps = {
  isFirstScreen: false,
  isTemplate: true,
}
