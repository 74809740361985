import React, { useState, useCallback, useEffect } from 'react'
import { CheckBox, ButtonV3 } from '@provi/provi-components'
import { uniqueId } from 'lodash'

import { useToggle } from '~/hooks'
import { ArrowDown, FunnelIcon } from '~/assets/svgs/v2'
import {
  SelectSimulator,
  RelativeContainer,
  OptionsContainer,
  OptionsWrapper,
  ActionsContainer,
  FlexContainerWithSVG,
} from './styles'
import { trackEvent } from '~/utils/googleAnalytics.js'

export const IntegrationFilter = ({ filterOptions, filtersState, setFilterFn }) => {
  const [filterVisibility, toggleFilterVisibility] = useToggle()
  const [checkedState, setCheckedState] = useState([])

  useEffect(() => {
    if (filtersState.length > 0) {
      setCheckedState(filterOptions.map((eachObject) => filtersState.includes(eachObject.courseId)))
    } else {
      setCheckedState(new Array(filterOptions && filterOptions.length).fill(false))
    }
  }, [filtersState, filterOptions])

  const handleChange = useCallback(
    (positionIndex) => {
      const updatedCheckedState = checkedState.map((eachBoolean, index) =>
        index === positionIndex ? !eachBoolean : eachBoolean,
      )
      setCheckedState(updatedCheckedState)
    },
    [checkedState],
  )

  const setFilterArray = useCallback(() => {
    return filterOptions
      .filter((_, optionIndex) => checkedState[optionIndex] === true)
      .map((eachFilteredObject) => eachFilteredObject.courseId)
  }, [filterOptions, checkedState])

  const handleClear = useCallback(() => {
    setFilterFn([])
    setCheckedState(new Array(filterOptions && filterOptions.length).fill(false))
    toggleFilterVisibility()
  }, [filterOptions, setFilterFn, toggleFilterVisibility])

  const handleSetFilters = useCallback(() => {
    checkedState.some((boolean) => boolean === true) && setFilterFn(setFilterArray)
    toggleFilterVisibility()
    trackEvent({
      category: 'Filters',
      action: 'Memberkit',
      label: 'Produto Provi',
    })
  }, [checkedState, setFilterFn, setFilterArray, toggleFilterVisibility])

  return (
    <RelativeContainer>
      <SelectSimulator onClick={toggleFilterVisibility} isActive={filterVisibility}>
        {filtersState.length ? (
          <FlexContainerWithSVG>
            <FunnelIcon />
            <p>Filtro aplicado</p>
          </FlexContainerWithSVG>
        ) : (
          <p>Filtrar</p>
        )}
        <ArrowDown />
      </SelectSimulator>

      {filterVisibility && (
        <OptionsWrapper>
          <OptionsContainer>
            {filterOptions &&
              filterOptions.map((eachObj, index) => {
                return (
                  <CheckBox
                    key={uniqueId()}
                    htmlFor={eachObj.courseName}
                    name={eachObj.courseName}
                    value={eachObj.courseName}
                    text={`${eachObj.courseId} - ${eachObj.courseName}`}
                    checked={checkedState[index]}
                    onChange={() => handleChange(index)}
                  />
                )
              })}
          </OptionsContainer>
          <ActionsContainer>
            <ButtonV3 color={'white'} width={'150px'} onClick={() => handleClear()} text="Limpar filtros" />
            <ButtonV3 width={'150px'} onClick={() => handleSetFilters()} text="Filtrar" />
          </ActionsContainer>
        </OptionsWrapper>
      )}
    </RelativeContainer>
  )
}
