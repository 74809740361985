import styled from 'styled-components'

const inputsWidth = 600

export const SelectorWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  max-width: ${inputsWidth}px;
`
