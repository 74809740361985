import React, { useContext } from 'react'

import { isIE, isEdge, engineName } from 'react-device-detect'
import { Button, TextLink, theme } from '@provi/provi-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import InputAdornment from '@material-ui/core/InputAdornment'

// import { NumberFormat } from '~/components/CurrencyInput'
import { Input, InputMasks } from '~/components/Input'
import { ButtonBack } from '~/components/ButtonBack'
import { SubHeader } from '~/components/SubHeader'
import { TemplateContext } from '~/components/Template/context'
import { useCheckout } from './hooks'
import { formatValue, capitalize, formatStrValue } from '~/utils'
// import { Text } from '~/themes/styles'

import { InputIcon } from '~/assets/svgs/inputIcon'
import { Calculator } from '~/assets/svgs/calc'
import { Lock } from '~/assets/svgs/Lock'
import { LoopArrow } from '~/assets/svgs/loop-arrows'

import { CalcModalV2 } from '~/components/modals/calc-modal-v2'

import { CheckCpfModal } from '../check-cpf-modal'
import { SellerInfoModal } from '../seller-modal'

import {
  Container,
  Card,
  //
  Product,
  ProductTitle,
  ProductText,
  CardValueTitle,
  CardValue,
  //
  Content,
  SectionOption,
  OptionTitle,
  OptionSubTitle,
  WrapperInputs,
  WrapperIcon,
  //
  CIETitle,
  WrapperDaysToPayment,
  WrapperButtonsDays,
  ButtonDay,
  WrapperGetLink,
  WarningAnimation,
  Warning,
  WrapperCardLink,
  WarningBold,
  Br,
  WrapperButton,
  WrapperTextLink,
  WrapperGetLinkTextLink,
  ErrorMessage,
  AnchorElementToSmallScreens,
  //
  WrapperCalc,
  EntranceBold,
  ButtonOutline,
  WrapperOutline,
  BackgroundBlock,
} from '../styles'

export const CheckoutPlan = () => {
  const {
    setIsLoading,
    setCurrent,
    handleLogoutModal,
    isMobile,
    setSelectedOptions,
    setValue,
    goToPage,
    activePage,
    setActivePage,
  } = useContext(TemplateContext)
  const [
    checked,
    handleButtonDay,
    buttonActive,
    handleValueChange,
    handlePercentChange,
    handleDaysChange,
    handleDateChange,
    link,
    showToastCopy,
    products,
    productsValue,
    obj,
    date,
    handleFocus,
    handleSubmitCheckout,
    cpf,
    handleCpf,
    sellerId,
    handleSellerId,
    cpfError,
    dateError,
    dateErrorMessage,
    linkUrl,
    errorPercent,
    errorMaxValue,
    handleEntranceChange,
    entranceValue,
    clickCalcModal,
    modal,
    handleModal,
    entranceError,
    defaultUpFront,
    defaultPercentualUpfront,
    defaultRunningDays,
    showCpfModal,
    requests,
    handleCheckCpfModal,
    creditRule,
    showSellerInfoModal,
    handleEntranceBlur,
    hasCheckCpf,
    preApprovedDisable,
    seeInstallmentDisable,
    calcConfig,
    handleCalcModal,
  ] = useCheckout(setIsLoading, setCurrent, isIE, isEdge, engineName, setSelectedOptions, setValue, activePage, setActivePage)

  return (
    <>
      {modal && <CalcModalV2 show={modal} handleModal={handleCalcModal} calcConfig={calcConfig} />}

      {showCpfModal && (
        <CheckCpfModal
          cpf={cpf}
          handleModal={handleModal}
          requests={requests}
          handleCheckCpfModal={handleCheckCpfModal}
          value={String(formatStrValue(obj.value) || productsValue / 100)}
          creditRule={creditRule}
        />
      )}
      {showSellerInfoModal && <SellerInfoModal handleModal={handleModal} />}

      {!isMobile ? (
        <ButtonBack onClick={() => goToPage('checkout/selecionar-produtos')} />
      ) : (
        <SubHeader
          isMargin="0 0 30px 0"
          isShowButtonHelpModal={false}
          functionBackButton={() => goToPage('checkout/selecionar-produtos')}
        />
      )}
      <Container>
        {!isMobile && <BackgroundBlock />}
        <Content>
          <Card>
            {products &&
              products.map((product, index) => (
                <Product key={index}>
                  <ProductTitle>Produto {index + 1}:</ProductTitle>
                  <ProductText>
                    {capitalize(product.name)} | {product.is_full_time ? 'Full Time' : 'Part Time'} |{' '}
                    {capitalize(product.modality)} |{formatValue(product.price_in_cents)}
                  </ProductText>
                </Product>
              ))}
            <CardValueTitle>Valor total:</CardValueTitle>
            <CardValue>{formatValue(productsValue)}</CardValue>
          </Card>
          <SectionOption>
            <OptionTitle>Digite o valor final da venda ou o valor do desconto em %*</OptionTitle>
            <WrapperInputs>
              <Input
                type="tel"
                placeholder="Ex: 1.200,00"
                onChange={handleValueChange}
                value={obj.value}
                onBlur={() => handleEntranceBlur()}
                width={isMobile ? '100%' : '300px'}
                onClick={handleFocus}
              />
              <WrapperIcon>
                <InputIcon />
              </WrapperIcon>
              <InputMasks
                type="tel"
                mask="99%"
                placeholder="Ex: 22%"
                onChange={handlePercentChange}
                value={obj.percent}
                onClick={handleFocus}
                onBlur={() => handleEntranceBlur(true)}
                width={isMobile ? '100%' : '300px'}
              />
            </WrapperInputs>
            {errorPercent && <ErrorMessage>A porcentagem de desconto é no máximo 100%</ErrorMessage>}
            {errorMaxValue && (
              <ErrorMessage>O valor com desconto tem que ser menor que o valor total dos produtos</ErrorMessage>
            )}
          </SectionOption>
          <SectionOption>
            {(defaultUpFront || defaultPercentualUpfront) && (
              <>
                <OptionTitle>Valor da entrada{(defaultUpFront || defaultPercentualUpfront) && '*'}</OptionTitle>
                <OptionSubTitle>
                  Valor da entrada deve ser no mínimo{' '}
                  {defaultUpFront && (
                    <>
                      <EntranceBold>{formatValue(defaultUpFront, true)}</EntranceBold>
                    </>
                  )}
                  {defaultPercentualUpfront && (
                    <>
                      <EntranceBold>{defaultPercentualUpfront}%</EntranceBold> do valor final da venda
                    </>
                  )}
                  .
                </OptionSubTitle>
                <WrapperCalc>
                  <Input
                    type="tel"
                    placeholder="Ex: 1.200,00"
                    onChange={handleEntranceChange}
                    value={entranceValue}
                    width="100%"
                    onClick={handleFocus}
                  />
                </WrapperCalc>

                {entranceError && <ErrorMessage>{entranceError}</ErrorMessage>}
              </>
            )}

            {(buttonActive || buttonActive === 0) && (
              <WrapperDaysToPayment>
                <CIETitle isActive={checked}>Dias para pagamento da parcela de entrada*</CIETitle>
                <WrapperButtonsDays>
                  <ButtonDay
                    onClick={() => handleButtonDay(1)}
                    isActive={buttonActive === 1}
                    disabled={defaultRunningDays < 1 && defaultRunningDays !== 0}
                  >
                    1
                  </ButtonDay>
                  <ButtonDay onClick={() => handleButtonDay(2)} isActive={buttonActive === 2} disabled={defaultRunningDays < 2}>
                    2
                  </ButtonDay>
                  <ButtonDay onClick={() => handleButtonDay(3)} isActive={buttonActive === 3} disabled={defaultRunningDays < 3}>
                    3
                  </ButtonDay>
                  <ButtonDay onClick={() => handleButtonDay(4)} isActive={buttonActive === 4} disabled={defaultRunningDays < 4}>
                    4
                  </ButtonDay>
                  <ButtonDay onClick={() => handleButtonDay(5)} isActive={buttonActive >= 5} disabled={defaultRunningDays < 5}>
                    5
                  </ButtonDay>
                </WrapperButtonsDays>
              </WrapperDaysToPayment>
            )}
          </SectionOption>

          <SectionOption>
            <OptionTitle>Digite o CPF do estudante/comprador*</OptionTitle>
            <OptionSubTitle>Se o estudante for menor de idade, faça a venda no CPF do responsável financeiro.</OptionSubTitle>
            <WrapperInputs>
              <InputMasks
                mask="999.999.999-99"
                width="100%"
                placeholder="Ex: 123.456.789.01"
                value={cpf}
                onChange={handleCpf}
                onBlur={(e) => handleCpf(e, true)}
              />
            </WrapperInputs>
            {cpfError && <ErrorMessage>CPF inválido</ErrorMessage>}
          </SectionOption>
          <SectionOption display="flex" justify="center">
            <WrapperOutline>
              <ButtonOutline isDisable={seeInstallmentDisable} onClick={clickCalcModal}>
                <Calculator isDisable={seeInstallmentDisable} />
                Ver parcelas
              </ButtonOutline>
            </WrapperOutline>
            {hasCheckCpf && (
              <WrapperOutline>
                <ButtonOutline isDisable={preApprovedDisable} onClick={() => handleModal(true, 'checkCpf')}>
                  <LoopArrow isDisable={preApprovedDisable} color={theme.colors.pink900} />
                  Testar pré-aprovação
                </ButtonOutline>
              </WrapperOutline>
            )}
          </SectionOption>
          {/* <SectionOption>{creditRule && <CpfResult creditRule={creditRule} />}</SectionOption> */}
          <SectionOption>
            <OptionTitle>Digite o n° de dias até a expiração ou escolha uma data</OptionTitle>
            <OptionSubTitle>Caso o aluno não acesse o link até a data de expiração, o link ficará inválido.</OptionSubTitle>
            <WrapperInputs>
              <Input
                width="300px"
                placeholder="Ex: 5"
                onChange={handleDaysChange}
                value={date.days}
                onClick={handleFocus}
                inputProps={{ maxLength: 4 }}
              />
              <WrapperIcon>
                <InputIcon />
              </WrapperIcon>
              <InputMasks
                mask="99/99/9999"
                width={isMobile ? '100%' : '300px'}
                placeholder="Ex: 12/03/2020"
                onChange={handleDateChange}
                value={date.date}
                onClick={handleFocus}
              />
            </WrapperInputs>
            {dateError && <ErrorMessage>{dateErrorMessage}</ErrorMessage>}
          </SectionOption>
          <SectionOption>
            <OptionTitle>Vendedor</OptionTitle>
            <WrapperInputs>
              <Input
                disabled
                id="sellerid"
                width="100%"
                placeholder="Email do vendedor"
                InputProps={{
                  // endAdornment: (
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color={theme.colors.grey600} />
                    </InputAdornment>
                  ),
                  'aria-label': 'seller',
                  cy: 'seller',
                }}
                value={sellerId}
                onChange={handleSellerId}
                onClick={() => handleModal(true, 'seller')}
              />
            </WrapperInputs>
          </SectionOption>
        </Content>

        <WrapperGetLink>
          <Button
            marginHorizontal
            disabled={
              !cpf || (cpf && cpf.length < 14) || dateError || cpfError || errorPercent || errorMaxValue || entranceError
            }
            text="Gerar link"
            onClick={handleSubmitCheckout}
          />
          <WrapperGetLinkTextLink>
            <TextLink text="Logout" color={theme.colors.pink900} onClick={() => handleLogoutModal(true)} />
          </WrapperGetLinkTextLink>
        </WrapperGetLink>
      </Container>

      {link && (
        <WarningAnimation>
          <Warning>
            <Container>
              <WrapperCardLink>
                <WarningBold> Olá! Chegou a hora de conhecer a Provi.</WarningBold>
                <AnchorElementToSmallScreens>
                  <Br />A Provi é uma fintech de crédito educacional com quem temos parceria para que você possa fazer o curso
                  com parcelas que cabem no seu bolso!
                  <Br />
                </AnchorElementToSmallScreens>
                <Br /> Clique no link abaixo para finalizar a sua compra via parcelamento no boleto.
                <Br /> <Br />
                <WarningBold> {linkUrl}</WarningBold>
              </WrapperCardLink>
              <WrapperButton>
                <CopyToClipboard text={linkUrl} onCopy={showToastCopy}>
                  <Button text="Copiar link" />
                </CopyToClipboard>
              </WrapperButton>
              <WrapperTextLink>
                <TextLink
                  text="Começar nova venda"
                  color={theme.colors.pink900}
                  onClick={() => goToPage('checkout/selecionar-produtos')}
                />
              </WrapperTextLink>
            </Container>
          </Warning>
        </WarningAnimation>
      )}
    </>
  )
}
