import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.section`
  max-width: 1280px;

  display: flex;
  flex-direction: column;
  margin: 10px auto;

  h1 {
    margin-bottom: 0;
    color: ${theme.colors.bluePrimary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
  }
`

export const ListIntegrations = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0;

  svg {
    width: 100%;
    height: 2.5rem;
    margin: 20px auto;
  }

  img {
    width: 230px;
    padding: 25px;
  }
`

export const ListIntegrationsItem = styled.li`
  width: 300px;

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.0075em;
    color: #000000;
    margin-bottom: 0.75rem;
  }

  &:not(:first-of-type) {
    padding-left: 1.5rem;
  }

  @media screen and (max-width: 632px) {
    &:not(:first-of-type) {
      padding-left: 0;
    }
  }

  &.tags__item {
    div {
      filter: grayscale(0.7) brightness(0.7);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  div {
    background-color: #fff;
    border: 2px solid ${theme.colors.bluePrimaryLight};
    border-radius: 5px;
    text-align: center;
    cursor: ${(props) => props.isMemberKit && 'pointer'};
    transition: 0.3s filter;

    &:hover {
      filter: ${(props) => props.isMemberKit && 'brightness(0.8)'};
    }
  }
`
