import React from 'react'
import moment from 'moment'
import { FileIcon } from '~/assets/svgs/file-icon'

import { TableWrapper, TableHeadCell, TableRow, TableRowCell, DocumentNameWrapper, DocumentNameLink } from './styles'

export const ChargebackTable = ({ data }) => {
  return (
    <TableWrapper>
      {!!data.length && (
        <>
          <TableHeadCell>Data de envio</TableHeadCell>
          <TableHeadCell>Nome do documento</TableHeadCell>
          <TableHeadCell>Enviado por</TableHeadCell>

          {data.map((item, index) => (
            <TableRow key={`${item.submittedAt}-${index}`}>
              <TableRowCell>{moment(item.submittedAt).format('DD/MM/YYYY')}</TableRowCell>
              <TableRowCell>
                {item.submittedDocuments.map(({ id, url, name }) => (
                  <ChargebackDocumentName key={id} documentName={name} url={url} />
                ))}
              </TableRowCell>
              <TableRowCell>{item.submittedDocuments[0].createdBy}</TableRowCell>
            </TableRow>
          ))}
        </>
      )}
    </TableWrapper>
  )
}

export const ChargebackDocumentName = ({ documentName, url }) => {
  return (
    <DocumentNameWrapper href={url} target="_blank" rel="noopener noreferrer" title={documentName}>
      <DocumentNameLink>{documentName}</DocumentNameLink>
      <FileIcon />
    </DocumentNameWrapper>
  )
}
