import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Main = styled.div`
  width: 100%;
  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  transition: padding 0.5s;
`

export const Content = styled.div`
  margin-top: 81px;
  position: relative;
  height: calc(100vh - 81px);
  width: 100%;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: calc(100% - 81px);
  }
`

export const ContainerTable = styled.div`
  width: calc(100vw-200px);
  margin-left: 100px;
  margin-right: 100px;
  ${theme.breakpoints.down('md')} {
    margin-left: 0px;
    margin-right: 0px;
  }
`
export const WrapperOrigin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
`

export const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.blue900};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
`

export const Active = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;

  border: 1px solid ${({ isClicked }) => (isClicked ? `${theme.colors.pink900}` : `${theme.colors.grey600}`)};
  background-color: ${({ isClicked }) => (isClicked ? `${theme.colors.pink900}` : `${theme.colors.grey600}`)};
`

export const SectionHeader = styled.div`
  padding-top: 21px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f2f2f2;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const WrapperText = styled.div`
  margin-bottom: 20px;
  text-align: center;
  flex-direction: row;
`
export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BoxButton = styled.div`
  height: 50px;
  width: 100%;
  margin: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.grey400)};
  color: ${({ active }) => (active ? theme.colors.grey400 : theme.colors.blue900)};

  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  font-size: 16px;
  line-height: 24px;
  font-family: 'CenturyGothicBold';
  cursor: pointer;
  user-select: none;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 50px;
    margin: 0px;
    border-radius: 5px;
    background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.grey400)};
  }
`

export const WrapperButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`
export const WarningAnimation = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`

export const animationWarning = keyframes`
  0% {
    bottom: -516px;
  }
  100% {
    bottom: 0;
  }
`

export const Warning = styled.div`
  padding: 0 20px;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 20px;
  border-radius: 30px 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  animation-name: ${animationWarning};
  animation-duration: 1000ms;
  animation-timing-function: ease;
  background-color: ${theme.colors.blue900};
  color: ${theme.colors.grey400};
`

export const Bold = styled.span`
  font-family: CenturyGothicBold;
`
export const WrapperTextLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'CenturyGothicBold';
  }
`
export const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    margin: unset;
    max-width: unset;
  }
`

export const Br = styled.br``

export const AnchorElementToSmallScreens = styled.div`
  @media screen and (max-width: 365px) {
    display: none;
  }
`
export const WrapperIcon = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    width: 28px;
    margin-left: 10px;
  }
`
export const Input = styled.input`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'unset')};
  width: ${({ width }) => (width ? `${width}` : '420px')};
  font-family: 'CenturyGothic';
  background: #f2f2f2;
  border: 0px;
  border-bottom: 1px solid ${theme.colors.blue900};
  box-sizing: border-box;
  height: 42px;
  /* margin-top: 15px; */
  background-repeat: no-repeat;
  background-position: center left 15px;
  padding-left: 100px;
  font-size: 16px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 40px;
    /* max-width: 220px; */
    background-position: center left 10px;
    padding-left: 50px;
    font-size: 14px;
    max-width: 300px;
  }
`
