import React, { useContext } from 'react'
import { IntegrationsContext } from '~/screens/integrations/context'
import { ButtonV3 } from '@provi/provi-components'
import { Container, Title, DescriptionMemberkit, ListSteps, ListStepsItem, CircleStepItem } from './styles'

function WelcomeMemberkit() {
  const { setFlowState } = useContext(IntegrationsContext)

  return (
    <>
      <Container>
        <Title>Passo a passo</Title>
        <DescriptionMemberkit>
          Antes de começar, é preciso que você tenha uma conta na MemberKit e possua produtos cadastrados na MemberKit e na
          Provi.
        </DescriptionMemberkit>

        <ListSteps>
          <ListStepsItem>
            <CircleStepItem className="circle__progress-1"></CircleStepItem>
            <div>
              <h4>Adicionar conta</h4>
              <p>Insira a sua chave secreta da MemberKit </p>
            </div>
          </ListStepsItem>
          <ListStepsItem>
            <CircleStepItem className="circle__progress-2"></CircleStepItem>
            <div>
              <h4>Conectar os seus produtos</h4>
              <p>Selecione os produtos que devem ser conectados</p>
            </div>
          </ListStepsItem>
          <ListStepsItem>
            <CircleStepItem className="circle__progress-3"></CircleStepItem>
            <div>
              <h4>Integração feita!</h4>
              <p>A liberação e o bloqueio do acesso serão automáticos</p>
            </div>
          </ListStepsItem>
        </ListSteps>
      </Container>
      <ButtonV3 text="Adicionar conta" mobileWidth={'100%'} onClick={() => setFlowState('Adicionar conta')} />
    </>
  )
}

export { WelcomeMemberkit }
