import React from 'react'
import { theme, ProgressBar } from '@provi/provi-components'
import { BaseTypography } from '~/globalStyles'
import { VerticalContainers } from '../../styles'

export const MainHeader = ({ isMobile }) => {
  return (
    <VerticalContainers>
      <BaseTypography
        fontSize={isMobile ? '13px' : '16px'}
        lineHeight={isMobile ? '20px' : '24px'}
        color={theme.colors.bluePrimary}
        fontWeight={700}
      >
        ESTRATÉGIA DE VENDA
      </BaseTypography>
      <ProgressBar progress="100" color="#2647D7" backgroundColor="#D4DAF7" />
      <BaseTypography
        fontSize={isMobile ? '11px' : '13px'}
        lineHeight={isMobile ? '16px' : '20px'}
        color={theme.colors.greyPrimary}
      >
        Defina variáveis de data e preço para que seu lançamento fique visível para vendas.
      </BaseTypography>
    </VerticalContainers>
  )
}
