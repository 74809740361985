import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { BaseFlexContainer } from '~/globalStyles'

export const SelectContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    justify-content: revert;
    align-items: revert;
    gap: 0.5rem;
    flex-direction: column;
  }

  .classroomSelect__menu {
    z-index: 300;
  }

  .classroomSelect__group-heading {
    color: black;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: revert;
  }

  .classroomSelect__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .classroomSelect__option {
    white-space: normal;
  }

  .classroomSelect__option--is-focused {
    font-weight: 600;
  }

  .classroomSelect__dropdown-indicator {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
  }

  .classroomSelect__control--menu-is-open .classroomSelect__dropdown-indicator {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }
`

export const ActionsContainer = styled(BaseFlexContainer)`
  justify-content: space-between;
  align-items: center;
`

export const BlueBox = styled.div`
  border: 2px solid ${theme.colors.bluePrimaryLight};
  font-family: 'Montserrat', sans-serif;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 380px;
  border-top: 0;
  background-color: ${theme.colors.bluePrimaryLight};

  p.paragraph__with-icon {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`
