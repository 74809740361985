/* eslint-disable react/prop-types */
import React from 'react'

export const BackArrow = ({ size, isDark }) => (
  <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M19 12H5" stroke={`${isDark ? '#fff' : '#101D36'}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12 5L5 12L12 19"
      stroke={`${isDark ? '#fff' : '#101D36'}`}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
