import { useState, useCallback, useContext, useMemo, useEffect } from 'react'
import { queryClient } from '~/stores/react-query'
import { TemplateContext } from '~/components/Template/context'
import { IntegrationsContext } from '~/screens/integrations/context'
import { useFetchMemberkitProducts, useConnectProductMutation, IntegrationsQueryKeys } from '~/screens/integrations/requests'
import { showToast } from '@provi/provi-components'
import { formatReactSelectOptions } from '~/screens/integrations/helpers'

export const useConnectProducts = () => {
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const {
    currentIntegrationName,
    currentIntegrationId,
    setFlowState,
    productsToEdit,
    proviPayVersion,
    setProductsToEdit,
  } = useContext(IntegrationsContext)
  const [productConnection, setProductConnection] = useState('group')
  const [proviProducts, setProviProducts] = useState([])
  const [chosenProviProducts, setChosenProviProducts] = useState()
  const [proviCourseClasses, setProviCourseClasses] = useState([])
  const [chosenProviCourseClasses, setChosenProviCourseClasses] = useState()
  const [memberkitProducts, setMemberkitProducts] = useState([])
  const [chosenMemberkitGroupProducts, setChosenMemberkitGroupProducts] = useState()
  const [memberkitSubscriptions, setMemberkitSubscriptions] = useState([])
  const [chosenMemberkitSubscription, setChosenMemberkitSubscripionProducts] = useState()

  const extraProductsConfig = useMemo(
    () => ({
      enabled: !!currentIntegrationId,
      onError: () => {
        showToast('Algo de errado aconteceu buscando suas informações! 😔 Por favor, tente novamente.')
      },
      onSuccess: (data) => {
        formatProviProducts(data)
        formatMemberkitProducts(data)
        formatMemberkitSubscriptions(data)
      },
    }),
    [currentIntegrationId, formatProviProducts, formatMemberkitProducts, formatMemberkitSubscriptions],
  )
  const { data: allProductsData, isLoading: isLoadingAllProducts } = useFetchMemberkitProducts(
    currentIntegrationId,
    extraProductsConfig,
  )

  const extraConnectProductMutationConfig = useMemo(
    () => ({
      onMutate: () => {
        setIsLoading(true)
      },
      onError: () => {
        setIsLoading(false)
        showToast('Algo de errado aconteceu salvando suas informações! 😔 Por favor, tente novamente.')
      },
      onSuccess: () => {
        queryClient.invalidateQueries(IntegrationsQueryKeys.fetchMemberkitConnections)
        setIsLoading(false)
        showToast('Produto conectado com sucesso! 😀')
        setTimeout(() => {
          setFlowState('Gerenciar conta')
        }, 1000)
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }),
    [setIsLoading, setFlowState],
  )

  const { mutate } = useConnectProductMutation(extraConnectProductMutationConfig)

  const connectProductMutation = useCallback(() => {
    try {
      if (productConnection === 'group' && (!chosenProviProducts || !chosenMemberkitGroupProducts?.length)) {
        showToast('Preencha as opções necessárias!')
        return
      }

      if (productConnection === 'subscription' && (!chosenProviProducts || !chosenMemberkitSubscription)) {
        showToast('Preencha as opções necessárias!')
        return
      }

      if (proviPayVersion?.data?.content?.useV3 && !chosenProviCourseClasses) {
        showToast('Preencha as opções necessárias!')
        return
      }

      const mutationVariables = {
        CourseId: chosenProviProducts.value || chosenProviProducts[0].value,
        IntegrationId: currentIntegrationId,
        ...(productConnection === 'group' &&
          chosenMemberkitGroupProducts && {
            ClassroomIds: chosenMemberkitGroupProducts.map((eachObject) => eachObject.value),
          }),
        ...(productConnection === 'subscription' &&
          chosenMemberkitSubscription && { MembershipLevelId: chosenMemberkitSubscription.value }),
        ...(proviPayVersion?.data?.content?.useV3 &&
          chosenProviCourseClasses && {
            CourseClassId: chosenProviCourseClasses.value || chosenProviCourseClasses[0].value,
          }),
      }

      mutate(mutationVariables)
    } catch (err) {
      console.warn(err)
    }
  }, [
    mutate,
    productConnection,
    chosenProviProducts,
    currentIntegrationId,
    chosenMemberkitGroupProducts,
    chosenMemberkitSubscription,
    chosenProviCourseClasses,
    proviPayVersion,
  ])

  const onChangeHandlers = useMemo(
    () => ({
      handleProviProducts: (option) => setChosenProviProducts(option),
      handleMemberkitProducts: (option) => setChosenMemberkitGroupProducts(option),
      handleMemberkitSubscriptions: (option) => setChosenMemberkitSubscripionProducts(option),
      handleProviCourseClasses: (option) => setChosenProviCourseClasses(option),
      handleGroupProductConnection: () => setProductConnection('group'),
      handleSubscriptionProductConnection: () => setProductConnection('subscription'),
    }),
    [
      setChosenProviProducts,
      setChosenMemberkitGroupProducts,
      setChosenMemberkitSubscripionProducts,
      setProductConnection,
      setChosenProviCourseClasses,
    ],
  )

  const removeItemFromGroupArr = useCallback((clickedObject) => {
    setChosenMemberkitGroupProducts((previousArray) =>
      previousArray.filter((eachObject) => eachObject.value !== clickedObject.value),
    )
  }, [])

  const formatProviProducts = useCallback((dataFromOnSuccess) => {
    const {
      products: { visibles, invisibles },
    } = dataFromOnSuccess || {}

    const mappedVisibleProducts = formatReactSelectOptions(visibles)
    const mappedInvisibleVisibleProducts = formatReactSelectOptions(invisibles)

    setProviProducts([
      { label: 'Cursos visíveis', options: mappedVisibleProducts },
      { label: 'Cursos invisíveis', options: mappedInvisibleVisibleProducts },
    ])
  }, [])

  const formatMemberkitProducts = useCallback((dataFromOnSuccess) => {
    const { classrooms } = dataFromOnSuccess || {}

    const mappedClassrooms = classrooms.map((eachObject) => ({
      value: eachObject.id,
      label: eachObject.name,
    }))

    setMemberkitProducts(mappedClassrooms)
  }, [])

  const formatMemberkitSubscriptions = useCallback((dataFromOnSuccess) => {
    const { memberships } = dataFromOnSuccess || {}

    const mappedMemberships = memberships.map((eachObject) => ({
      value: eachObject.id,
      label: eachObject.name,
    }))

    setMemberkitSubscriptions(mappedMemberships)
  }, [])

  const formatProviCourseClasses = useCallback(() => {
    if (!chosenProviProducts) return

    const mappedVisibles = formatReactSelectOptions(chosenProviProducts.courseClasses.visibles)
    const mappedInvisibles = formatReactSelectOptions(chosenProviProducts.courseClasses.invisibles)

    setProviCourseClasses([
      { label: 'Turmas visíveis', options: mappedVisibles },
      { label: 'Turmas invisíveis', options: mappedInvisibles },
    ])
  }, [chosenProviProducts])

  useEffect(() => {
    if (allProductsData) {
      formatProviProducts(allProductsData)
      formatMemberkitProducts(allProductsData)
      formatMemberkitSubscriptions(allProductsData)
    }
  }, [])

  useEffect(() => {
    formatProviCourseClasses()
  }, [chosenProviProducts])

  useEffect(() => {
    if (productsToEdit?.courseId && proviProducts.length) {
      const concatArrs = proviProducts[0].options.concat(proviProducts[1].options)
      setChosenProviProducts(concatArrs.find((eachOption) => productsToEdit?.courseId === eachOption.value))
    }
    if (productsToEdit?.type === 'classroom' && memberkitProducts) {
      setChosenMemberkitGroupProducts(
        memberkitProducts.filter((eachOption) => productsToEdit?.IdsArr.includes(eachOption.value)),
      )
    }
    if (productsToEdit?.type === 'membership_level' && memberkitSubscriptions) {
      setProductConnection('subscription')
      setChosenMemberkitSubscripionProducts(
        memberkitSubscriptions.find((eachOption) => productsToEdit?.IdsArr.includes(eachOption.value)),
      )
    }
  }, [proviProducts, memberkitProducts, memberkitSubscriptions, productsToEdit])

  useEffect(() => {
    setChosenProviCourseClasses(null)
    if (productsToEdit?.CourseClassId && chosenProviProducts && proviPayVersion?.data?.content?.useV3) {
      const concatArrs = chosenProviProducts?.courseClasses?.visibles.concat(chosenProviProducts?.courseClasses?.invisibles)
      const courseClassToEdit = concatArrs.find((eachOption) => productsToEdit?.CourseClassId === eachOption?.id)
      courseClassToEdit &&
        setChosenProviCourseClasses({
          value: courseClassToEdit?.id,
          label: `${courseClassToEdit?.id} - ${courseClassToEdit?.name}`,
        })
      setProductsToEdit(null)
    }
  }, [chosenProviProducts])

  return {
    isMobile,
    productConnection,
    chosenProviProducts,
    chosenMemberkitGroupProducts,
    chosenMemberkitSubscription,
    removeItemFromGroupArr,
    isLoadingAllProducts,
    connectProductMutation,
    onChangeHandlers,
    proviProducts,
    memberkitProducts,
    memberkitSubscriptions,
    currentIntegrationName,
    proviPayVersion,
    proviCourseClasses,
    chosenProviCourseClasses,
  }
}
