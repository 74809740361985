import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const ConnectProductsContainer = styled.div`
  display: grid;
  gap: 2rem;
  max-width: 1200px;

  ${theme.breakpoints.down('md')} {
    gap: 1.5rem;
  }
`

export const TextContainer = styled.div`
  p:first-child {
    margin-bottom: 0.5rem;
  }
`

export const LabelText = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  ${({ isBold }) =>
    isBold &&
    css`
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      ${theme.breakpoints.down('md')} {
        font-size: 13px;
        line-height: 20px;
      }
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;

      ${theme.breakpoints.down('md')} {
        font-size: 11px;
        line-height: 16px;
      }
    `}
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .groupSelect__menu {
    z-index: 300;
  }

  .groupSelect__group-heading {
    color: black;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: revert;
  }

  .groupSelect__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .groupSelect__option {
    white-space: normal;
  }

  .groupSelect__option--is-focused {
    font-weight: 600;
  }

  .groupSelect__dropdown-indicator {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out;
  }

  .groupSelect__control--menu-is-open .groupSelect__dropdown-indicator {
    transform: rotate(180deg);
    transition: transform 0.4s ease-in-out;
  }
`

export const ColumnGrids = styled.div`
  width: 100%;
  display: grid;
  gap: 1.5rem;
`
export const FlexContainer = styled.div`
  padding: 1rem;
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }

  ${({ isGroupContainer }) =>
    isGroupContainer &&
    css`
      border: ${({ state }) =>
        state === 'group' ? `2px solid ${theme.colors.bluePrimary}` : `1px solid ${theme.colors.bluePrimaryLight}`};
      background-color: ${({ state }) => (state === 'group' ? `${theme.colors.bluePrimaryLight}` : 'none')};
    `}

  ${({ isSubscriptionContainer }) =>
    isSubscriptionContainer &&
    css`
      border: ${({ state }) =>
        state === 'subscription' ? `2px solid ${theme.colors.bluePrimary}` : `1px solid ${theme.colors.bluePrimaryLight}`};
      background-color: ${({ state }) => (state === 'subscription' ? `${theme.colors.bluePrimaryLight}` : 'none')};
    `}
`
