import React from 'react'
import { parsePrice } from 'utils/parsePrice'
import { BaseTypography } from '~/globalStyles'
import { TextGrids } from '../../../AddClassroom/components/FinancingConditions/styles'

export const MiddleSection = ({ isMobile, values }) => {
  const { finalTotal = 0, installmentAmount = 0, upfrontAmount = 0 } = values || {}
  return (
    <TextGrids>
      {!!finalTotal && (
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'}>
          Valor total da venda: <b>{parsePrice(finalTotal)}</b>
        </BaseTypography>
      )}
      {!!installmentAmount && (
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'}>
          Valor parcelado: <b>{parsePrice(installmentAmount)}</b>
        </BaseTypography>
      )}
      {!!upfrontAmount && (
        <BaseTypography fontSize={isMobile ? '13px' : '16px'} lineHeight={isMobile ? '20px' : '24px'}>
          Valor da entrada: <b>{parsePrice(upfrontAmount)}</b>
        </BaseTypography>
      )}
    </TextGrids>
  )
}
