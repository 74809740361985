import React from 'react'
import { useGeneralDataTab } from './hooks'

import { Formik, Form, Field, FieldArray } from 'formik'
import * as Yup from 'yup'

import { ImageCropper } from './components/ImageCropper'

import { Text } from '~/themes/styles'
import { ButtonV2, Checkbox, InputField, theme } from '@provi/provi-components'
import {
  WrapperColumn,
  WrapperRow,
  TitleField,
  Divider,
  DividerTransparent,
  WrapperInput,
  WrapperCheckbox,
  FlexibilityOptionText,
  TextLink,
  WrapperButton,
  StyledSpan,
  TrashIcon,
  PencilIcon,
  IconWrapper,
  ModalBackDrop,
  Modal,
  ModalHeader,
  ButtonWrapper,
} from './styles'

export const GeneralDataTab = () => {
  const {
    partnerName,
    flexibilityOptions,
    greyTextColor,
    ObligatoryInput,
    isMobile,
    showModal,
    showFields,
    handleModalOpen,
    handleModalClose,
    handleDelete,
    postMutation,
    initialValuesState,
    AutoFillAddress,
    handleFieldOpen,
    cropperPhoto,
    setCropperPhoto,
    generalData,
  } = useGeneralDataTab()

  return (
    <>
      {generalData && (
        <Formik
          initialValues={initialValuesState}
          enableReinitialize={true}
          validationSchema={Yup.object({
            campus: Yup.array().of(
              Yup.object({
                id: Yup.number().nullable(),
                name: Yup.string().required('Digite o nome do Campus').typeError('Digite o nome do Campus'),
                address: Yup.object({
                  zipcode: Yup.string().required('Digite um CEP').typeError('Digite um CEP'),
                  state: Yup.string().required('Digite um Estado').typeError('Digite um Estado'),
                  city: Yup.string().required('Digite uma cidade').typeError('Digite uma cidade'),
                  district: Yup.string().required('Digite um bairro').typeError('Digite um bairro'),
                  street: Yup.string().required('Digite uma rua').typeError('Digite uma rua'),
                  number: Yup.string().nullable(),
                  complement: Yup.string().nullable(),
                }),
              }),
            ),
            partner: Yup.object({
              photoURL: Yup.string().nullable(),
              purchaseURL: Yup.string().nullable(),
              daysToReleaseAccess: Yup.number()
                .min(1, 'Digite um prazo de até 10 dias')
                .max(10, 'Digite um prazo de até 10 dias')
                .required('Digite um prazo de até 10 dias')
                .typeError('Digite um prazo de até 10 dias'),
              exemptionDays: Yup.number()
                .min(7, 'Digite um prazo igual ou superior a 7 dias, em função do Código de Defesa do Consumidor')
                .required('Digite um prazo')
                .typeError('Digite um prazo'),
              cancelationPolicyFlexibilization: initialValuesState.partner.cancelationPolicyFlexibilization
                ? Yup.string().required('Escolha uma opção de flexibilidade')
                : Yup.string().nullable(),
            }),
          })}
          onSubmit={(values) => {
            postMutation.mutateAsync(values)
          }}
          render={({ values, touched, errors, handleChange }) => (
            <Form>
              <AutoFillAddress />
              <WrapperRow marginTop={'24px'}>
                <ImageCropper cropperPhoto={cropperPhoto} setCropperPhoto={setCropperPhoto} />
                <WrapperColumn
                  isMobile={isMobile}
                  width={'calc(100% - 90px)'}
                  paddingLeft={isMobile ? '12px' : '24px'}
                  justifyContent={'center'}
                >
                  <TitleField aria-label="partner-photo">Foto do parceiro</TitleField>
                  <Text fontFamily={'Montserrat'} color={'#757575'}>
                    Clique na imagem ao lado para fazer um upload do logo da {partnerName || 'empresa'}
                  </Text>
                  <Text fontFamily={'Montserrat'} color={'#757575'}>
                    Essa é a imagem que aparecerá no ProviPay
                  </Text>
                </WrapperColumn>
              </WrapperRow>

              {isMobile ? <DividerTransparent /> : <Divider margin={'27px 0'} />}

              <FieldArray name="campus">
                {({ remove, push }) => {
                  return (
                    <>
                      {values.campus.map((eachAddressObject, index) => (
                        <Form key={index}>
                          {showModal === index && (
                            <ModalBackDrop>
                              <Modal>
                                <ModalHeader>Deseja cancelar este endereço do seu cadastro?</ModalHeader>
                                <ButtonWrapper>
                                  <ButtonV2
                                    onClick={handleModalClose}
                                    icon={() => null}
                                    variant="contained"
                                    outline
                                    text="Cancelar"
                                  />
                                  <ButtonV2
                                    onClick={() => handleDelete(remove, index, eachAddressObject)}
                                    icon={() => null}
                                    variant="contained"
                                    text="Confirmar"
                                  />
                                </ButtonWrapper>
                              </Modal>
                            </ModalBackDrop>
                          )}
                          {!isMobile && (
                            <IconWrapper>
                              {index > 0 && <TrashIcon onClick={() => handleModalOpen(index)} stroke={theme.colors.pink900} />}
                              <PencilIcon onClick={() => handleFieldOpen(index)} stroke={theme.colors.pink900} />
                            </IconWrapper>
                          )}

                          <WrapperRow isMobile={isMobile}>
                            <WrapperColumn isMobile={isMobile}>
                              <TitleField>Endereço {index > 0 && index + 1}</TitleField>
                              <Text fontFamily={'Montserrat'} mBottom={isMobile ? '24px' : '30px'} color={greyTextColor}>
                                Se tiver mais de uma unidade, {!isMobile && <br />}cadastre mais de um endereço.
                              </Text>
                            </WrapperColumn>

                            <WrapperColumn isMobile={isMobile}>
                              <WrapperColumn isMobile={isMobile} flexDirection={isMobile ? 'column' : 'row'}>
                                <WrapperInput isMobile={isMobile}>
                                  <Field name={`campus.${index}.name`}>
                                    {({ field, meta }) => (
                                      <InputField
                                        {...field}
                                        isRequired
                                        label="Nome da unidade"
                                        placeholder="Nome da unidade"
                                        hasError={meta.error && meta.touched}
                                        errorMessage={meta.error}
                                      />
                                    )}
                                  </Field>
                                </WrapperInput>

                                <WrapperInput isMobile={isMobile}>
                                  <Field name={`campus.${index}.address.zipcode`}>
                                    {({ field, meta }) => (
                                      <InputField
                                        {...field}
                                        isRequired
                                        label="CEP"
                                        aria-label="zipcode"
                                        mask="99999999"
                                        placeholder="Digite o CEP"
                                        hasError={meta.error && meta.touched}
                                        errorMessage={meta.error}
                                      />
                                    )}
                                  </Field>
                                </WrapperInput>
                              </WrapperColumn>

                              {showFields === index && (
                                <>
                                  <WrapperColumn>
                                    <WrapperInput isMobile={isMobile}>
                                      <Field name={`campus.${index}.address.street`}>
                                        {({ field, meta }) => (
                                          <InputField
                                            {...field}
                                            isRequired
                                            label="Rua"
                                            aria-label="street"
                                            placeholder="Ex: Cardoso de Melo"
                                            width={'100%'}
                                            hasError={meta.error && meta.touched}
                                            errorMessage={meta.error}
                                          />
                                        )}
                                      </Field>
                                    </WrapperInput>
                                  </WrapperColumn>

                                  <WrapperColumn flexDirection={'row'}>
                                    <WrapperInput isMobile={isMobile}>
                                      <Field name={`campus.${index}.address.state`}>
                                        {({ field, meta }) => (
                                          <InputField
                                            {...field}
                                            isRequired
                                            label="Estado"
                                            aria-label="state"
                                            placeholder="Selecione o estado"
                                            hasError={meta.error && meta.touched}
                                            errorMessage={meta.error}
                                          />
                                        )}
                                      </Field>
                                    </WrapperInput>

                                    {isMobile && <DividerTransparent width={'24px'} />}

                                    <WrapperInput isMobile={isMobile}>
                                      <Field name={`campus.${index}.address.city`}>
                                        {({ field, meta }) => (
                                          <InputField
                                            {...field}
                                            isRequired
                                            label="Cidade"
                                            aria-label="city"
                                            placeholder="Ex: São Paulo"
                                            hasError={meta.error && meta.touched}
                                            errorMessage={meta.error}
                                          />
                                        )}
                                      </Field>
                                    </WrapperInput>
                                  </WrapperColumn>

                                  <WrapperColumn flexDirection={isMobile ? 'column' : 'row'}>
                                    <WrapperInput isMobile={isMobile} width={isMobile ? '100%' : '50%'}>
                                      <Field name={`campus.${index}.address.district`}>
                                        {({ field, meta }) => (
                                          <InputField
                                            {...field}
                                            isRequired
                                            label="Bairro"
                                            aria-label="district"
                                            placeholder="Ex: Vila olímpia"
                                            hasError={meta.error && meta.touched}
                                            errorMessage={meta.error}
                                          />
                                        )}
                                      </Field>
                                    </WrapperInput>

                                    <WrapperRow width={isMobile ? '100%' : '50%'}>
                                      <WrapperInput isMobile={isMobile}>
                                        <Field name={`campus.${index}.address.number`}>
                                          {({ field }) => (
                                            <InputField {...field} label="Número" aria-label="number" placeholder="111" />
                                          )}
                                        </Field>
                                      </WrapperInput>

                                      {isMobile && <DividerTransparent width={'24px'} />}

                                      <WrapperInput isMobile={isMobile} overflow={'hidden'}>
                                        <Field name={`campus.${index}.address.complement`}>
                                          {({ field }) => (
                                            <InputField
                                              {...field}
                                              label="Complemento"
                                              aria-label="complement"
                                              placeholder="111"
                                            />
                                          )}
                                        </Field>
                                      </WrapperInput>
                                    </WrapperRow>
                                  </WrapperColumn>
                                </>
                              )}
                            </WrapperColumn>
                          </WrapperRow>

                          {isMobile && (
                            <>
                              <IconWrapper>
                                {index > 0 && (
                                  <TrashIcon onClick={() => handleModalOpen(index)} stroke={theme.colors.pink900} />
                                )}
                                <PencilIcon stroke={theme.colors.pink900} />
                              </IconWrapper>
                            </>
                          )}

                          {index === values.campus.length - 1 && (
                            <TextLink
                              isMobile={isMobile}
                              onClick={() =>
                                push({
                                  name: '',
                                  address: {
                                    zipcode: '',
                                    state: '',
                                    city: '',
                                    district: '',
                                    street: '',
                                    number: '',
                                    complement: '',
                                  },
                                })
                              }
                            >
                              Adicionar outra unidade
                            </TextLink>
                          )}

                          <Divider />
                        </Form>
                      ))}
                    </>
                  )
                }}
              </FieldArray>

              <WrapperRow isMobile={isMobile} width={'100%'}>
                <WrapperColumn isMobile={isMobile} width={isMobile ? '100%' : '50%'}>
                  <TitleField>
                    Prazo para liberação de acesso ao conteúdo do curso
                    <ObligatoryInput />
                  </TitleField>
                </WrapperColumn>

                <WrapperColumn
                  isMobile={isMobile}
                  justifyContent={'right'}
                  marginTop={isMobile ? '12px' : ''}
                  paddingBottom={'9px'}
                  paddingLeft={isMobile ? '0' : '45px'}
                  width={isMobile ? '100%' : '50%'}
                  flexDirection={'row'}
                >
                  <Field name={'partner.daysToReleaseAccess'}>
                    {({ field, meta }) => (
                      <InputField
                        {...field}
                        placeholder="Ex: 2 dias"
                        mask="99"
                        isValid={!meta.error && meta.touched}
                        hasError={meta.error && meta.touched}
                        errorMessage={meta.error}
                      />
                    )}
                  </Field>
                </WrapperColumn>
              </WrapperRow>

              <Divider />

              <WrapperRow isMobile={isMobile}>
                <WrapperColumn isMobile={isMobile} width={isMobile ? '100%' : '50%'}>
                  <TitleField>
                    Prazo de garantia <ObligatoryInput />
                  </TitleField>
                  <Text fontFamily={'Montserrat'} mBottom={!isMobile ? '24px' : ''} color={greyTextColor}>
                    Após esse prazo, o aluno que optar por {!isMobile && <br />}cancelar o curso estará sujeito a multas.
                  </Text>
                </WrapperColumn>

                <WrapperColumn
                  isMobile={isMobile}
                  justifyContent={'right'}
                  marginTop={isMobile ? '12px' : ''}
                  paddingBottom={'9px'}
                  paddingLeft={isMobile ? '0' : '45px'}
                  width={isMobile ? '100%' : '50%'}
                  flexDirection={'row'}
                >
                  <Field name={'partner.exemptionDays'}>
                    {({ field, meta }) => (
                      <InputField
                        {...field}
                        placeholder="Ex: 2 dias"
                        mask="99"
                        isValid={!meta.error && meta.touched}
                        hasError={meta.error && meta.touched}
                        errorMessage={meta.error}
                      />
                    )}
                  </Field>
                </WrapperColumn>
              </WrapperRow>

              {initialValuesState.partner.cancelationPolicyFlexibilization && (
                <>
                  <Divider />
                  <WrapperRow isMobile={isMobile}>
                    <WrapperColumn isMobile={isMobile} width={isMobile ? '100%' : '50%'}>
                      <TitleField isMobile={isMobile}>
                        Flexibilidade de cancelamento <ObligatoryInput />
                      </TitleField>
                    </WrapperColumn>

                    <WrapperColumn isMobile={isMobile} width={isMobile ? '100%' : '50%'} paddingLeft={isMobile ? '0' : '24px'}>
                      {flexibilityOptions.map((option) => (
                        <WrapperColumn
                          key={option.id}
                          status={values.partner.cancelationPolicyFlexibilization === option.id}
                          opacity={values.partner.cancelationPolicyFlexibilization === option.id ? 1 : 0.25}
                          isMobile={isMobile}
                          paddingLeft={isMobile ? '0' : '24px'}
                          alignItems={'center'}
                          flexDirection={'row'}
                        >
                          <WrapperCheckbox>
                            <Checkbox
                              aria-label={option.id}
                              id={option.id}
                              status={values.partner.cancelationPolicyFlexibilization === option.id}
                              setFunction={handleChange('partner.cancelationPolicyFlexibilization')}
                            />
                          </WrapperCheckbox>

                          <FlexibilityOptionText isMobile={isMobile}>{option.label}</FlexibilityOptionText>
                        </WrapperColumn>
                      ))}
                      {touched.terminationFlexibility && !values.terminationFlexibility && (
                        <StyledSpan paddingLeft={isMobile ? '0' : '24px'}>{errors.terminationFlexibility}</StyledSpan>
                      )}
                    </WrapperColumn>
                  </WrapperRow>{' '}
                </>
              )}
              {/* <Divider />
              <WrapperRow isMobile={isMobile} width={'100%'}>
                <WrapperColumn isMobile={isMobile} width={isMobile ? '100%' : '50%'}>
                  <TitleField>URL da página própria de confirmação de compra</TitleField>
                </WrapperColumn>

                <WrapperColumn
                  isMobile={isMobile}
                  justifyContent={'right'}
                  marginTop={isMobile ? '12px' : ''}
                  paddingBottom={'9px'}
                  paddingLeft={isMobile ? '0' : '45px'}
                  width={isMobile ? '100%' : '50%'}
                  flexDirection={'row'}
                >
                  <Field name={'partner.purchaseURL'}>
                    {({ field, meta }) => (
                      <InputField
                        {...field}
                        placeholder="Ex: URL da sua página de confirmação"
                        isValid={!meta.error && meta.touched}
                        hasError={meta.error && meta.touched}
                        errorMessage={meta.error}
                      />
                    )}
                  </Field>
                </WrapperColumn>
              </WrapperRow> */}

              {!isMobile && <Divider />}

              <WrapperButton aria-label="button">
                <ButtonV2 marginHorizontal={0} marginVertical={12} type="submit" text="Salvar" persistFloatingMode={false} />
              </WrapperButton>

              {isMobile && <DividerTransparent height={'48px'} />}
            </Form>
          )}
        />
      )}{' '}
    </>
  )
}
