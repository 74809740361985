import { useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { showToast } from '@provi/provi-components'
import { queryClient } from '~/stores/react-query'

import { TemplateContext } from '~/components/Template/context'
import firebase from '~/services/firebase'
import { getStorage } from '~/utils'
import { LOCALSTORAGE_TOKENS } from '~/constants'
import { sendChargebackInformation } from '~/services/api'
import { logger } from '~/helpers/logger'

export const useChargebackData = ({ proviPayChargebackId, getDetails, CreditRequestId }) => {
  const { setIsLoading } = useContext(TemplateContext)
  const [text, setText] = useState('')
  const [files, setFiles] = useState([])

  const onFileChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i]
      newFile['id'] = uuid()

      const existingFile = files.find((file) => file.name === newFile.name)

      if (!existingFile) {
        setFiles((prevState) => [...prevState, newFile])
      }
    }
  }

  const removeFile = (fileName) => {
    setFiles((prevState) => prevState.filter((file) => file.name !== fileName))
  }

  const sendData = async (urls = []) => {
    setIsLoading(true)
    const response = await sendChargebackInformation({ ProviPayChargebackId: proviPayChargebackId, urls, description: text })
    if (response.status === 200) {
      queryClient.invalidateQueries('accessContext')
      await getDetails(CreditRequestId)
      showToast('Arquivos enviados com sucesso')
      setText('')
      setFiles([])
    } else {
      showToast(response.data?.error?.message || response.data?.errors[0]?.message || 'Erro ao enviar os arquivos')
    }
    setIsLoading(false)
  }

  const onUploadSubmission = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const urls = []
    const promises = files.map(async (file) => {
      const fileType = file.name.split('.').pop()
      const now = new Date().getTime()
      const partnerId = getStorage({ key: LOCALSTORAGE_TOKENS.partnerId })
      const storagePath = `/${partnerId}/${now}.${fileType}`
      const storageRef = firebase.storage().ref(storagePath)
      const uploadTask = storageRef.put(file)

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        () => {},
        (err) => {
          logger({ error: err, ref: 'Firebase upload error' })
          setIsLoading(false)
          showToast('Ops, ocorreu um erro ao salvar o seu certificado 😞')
        },
        () => {
          const url = storageRef.getDownloadURL()
          urls.push({ url: url, typeExtension: fileType.toUpperCase(), name: file.name })
        },
      )
      return uploadTask
    })
    Promise.all(promises).then(async () => {
      const urlWithExtension = await Promise.all(
        urls.map(async (url) => {
          return { url: await url.url, typeExtension: await url.typeExtension, name: url.name }
        }),
      )
      setIsLoading(false)
      sendData(urlWithExtension)
    })
  }

  const showFileNames = (fileNames) => {
    const shownFileNames = []
    for (const fileName of fileNames) {
      shownFileNames.push(fileName.name)
    }
    return shownFileNames
  }

  return { onFileChange, files, setFiles, text, setText, onUploadSubmission, showFileNames, removeFile }
}
