import React from 'react'
import { Card } from '~/components/v2/Card'
import { LoadingSkeleton } from '~/components/v2/LoadingSkeleton'
import { theme } from '@provi/provi-components'
import { HeroCard, CommunicationCard, SalesCard } from '~/screens/home/components/'
import {
  Container,
  HeroLabel,
  ContainerContent,
  TopSection,
  BottomSection,
  LeftLabel,
  RightLabel,
  BottomColumns,
  VanishingWrapper,
  DeckWrapper,
} from './styles'

import { useHome } from './hooks'

import { matchUrlCheckout } from '../../helpers/matchUrl'
import { NewPartnerCard } from './components'

export const Home = () => {
  const {
    drawerWidth,
    isFetchingIntro,
    partnerIntroduction,
    isMobile,
    setIsLoading,
    goToPage,
    canUseMonitoringDashboard,
    formatValue,
    CommunicationCardData,
    isFetchingCommunicationCard,
    accessData,
  } = useHome()

  if (matchUrlCheckout()) {
    setIsLoading(true)
    return <div />
  }

  return (
    <>
      <Container isMobile={isMobile} drawerWidth={drawerWidth}>
        <ContainerContent>
          <TopSection>
            <HeroLabel>
              <span>Olá! </span>
              <>{isMobile && <br></br>}</>
              Que bom te ver aqui de novo ;)
            </HeroLabel>
            <VanishingWrapper>
              <Card>
                <LoadingSkeleton isLoading={isFetchingIntro} repeat={2} containerHeight="150px" hasHeading hasParagraph>
                  <NewPartnerCard />
                </LoadingSkeleton>
              </Card>
            </VanishingWrapper>
            <VanishingWrapper>
              <Card>
                <LoadingSkeleton isLoading={isFetchingIntro} repeat={2} containerHeight="150px" hasHeading hasParagraph>
                  <HeroCard
                    numberOfStudents={partnerIntroduction?.data.totalStudents ? partnerIntroduction?.data.totalStudents : 0}
                  />
                </LoadingSkeleton>
              </Card>
            </VanishingWrapper>
          </TopSection>
          <BottomSection>
            <BottomColumns>
              <LeftLabel>Comunicados</LeftLabel>
              <Card>
                <LoadingSkeleton
                  isLoading={isFetchingCommunicationCard}
                  repeat={isMobile ? 1 : 2}
                  containerHeight={isMobile ? '100px' : '150px'}
                  hasHeading
                  hasParagraph
                >
                  <CommunicationCard content={CommunicationCardData} accessData={accessData} />
                </LoadingSkeleton>
              </Card>
            </BottomColumns>
            <BottomColumns>
              <RightLabel>Últimos 7 dias</RightLabel>
              <DeckWrapper>
                <Card>
                  <LoadingSkeleton
                    isLoading={isFetchingIntro}
                    containerHeight={isMobile ? '100px' : '120px'}
                    hasHeading
                    hasParagraph
                  >
                    <SalesCard
                      text="Número total de vendas"
                      value={partnerIntroduction?.data.sales ? partnerIntroduction?.data.sales : 0}
                      color={theme.colors.blue600}
                      onClick={() => goToPage('checkout/listar-checkouts')}
                    />
                  </LoadingSkeleton>
                </Card>
                <Card>
                  <LoadingSkeleton
                    isLoading={isFetchingIntro}
                    containerHeight={isMobile ? '100px' : '120px'}
                    hasHeading
                    hasParagraph
                  >
                    <SalesCard
                      text="Valor total vendido"
                      value={partnerIntroduction?.data.revenue ? formatValue(partnerIntroduction?.data.revenue) : 'R$ 0,00'}
                      color={theme.colors.green100}
                      onClick={() =>
                        canUseMonitoringDashboard ? goToPage('dashboard/geral') : goToPage('checkout/listar-checkouts')
                      }
                    />
                  </LoadingSkeleton>
                </Card>
              </DeckWrapper>
            </BottomColumns>
          </BottomSection>
        </ContainerContent>
      </Container>
    </>
  )
}
