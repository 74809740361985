import React from 'react'
import Cropper from 'react-easy-crop'

import { useImageCropper } from './hooks'

import { ButtonV2 } from '@provi/provi-components'
import {
  CropperContainer,
  InputFile,
  WrapperImage,
  CropperModal,
  CropperBackDrop,
  CropperHeader,
  SliderWrapper,
  ButtonWrapper,
  CustomSlider,
  StyledImage,
  CropperError,
} from './styles'
import { UploadLogo } from '~/assets/svgs/upload-logo'
import { AddIcon } from '~/assets//svgs/add-icon'
import { SubtractIcon } from '~/assets//svgs/subtract-icon'

export const ImageCropper = ({ cropperPhoto, setCropperPhoto, ...props }) => {
  const {
    imageSrc,
    crop,
    zoom,
    setCrop,
    onCropComplete,
    setZoom,
    getCroppedImageToState,
    onClose,
    onFileChange,
    inputFileRef,
    imageError,
    checkImageDimensions,
  } = useImageCropper(setCropperPhoto)

  return (
    <>
      <InputFile
        type="file"
        accept="image/*"
        ref={inputFileRef}
        onChange={onFileChange}
        onClick={(e) => (e.target.value = null)}
        {...props}
      />
      <WrapperImage onClick={() => inputFileRef.current.click()}>
        {cropperPhoto ? <StyledImage src={cropperPhoto} alt="source from firebase" /> : <UploadLogo />}
      </WrapperImage>
      {imageSrc && (
        <>
          <CropperBackDrop>
            <CropperModal>
              <CropperHeader>Foto do parceiro</CropperHeader>
              <CropperContainer>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  disableAutomaticStylesInjection={true}
                  onMediaLoaded={(image) => {
                    checkImageDimensions(image)
                  }}
                />
              </CropperContainer>

              {imageError && <CropperError>{imageError}</CropperError>}

              <SliderWrapper>
                <SubtractIcon onClick={() => setZoom((zoomBtn) => Math.max(1, zoomBtn - 0.1))} />
                <CustomSlider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoomSlider) => setZoom(zoomSlider)}
                />
                <AddIcon onClick={() => setZoom((zoomBtn) => Math.min(3, zoomBtn + 0.1))} />
              </SliderWrapper>
              <ButtonWrapper>
                <ButtonV2 onClick={onClose} icon={() => null} variant="contained" outline text="Cancelar" />
                <ButtonV2
                  disabled={imageError}
                  onClick={getCroppedImageToState}
                  icon={() => null}
                  variant="contained"
                  text="Salvar"
                />
              </ButtonWrapper>
            </CropperModal>
          </CropperBackDrop>
        </>
      )}
    </>
  )
}
