import * as Yup from 'yup'

export const courseValidationSchema = Yup.object().shape({
  courseName: Yup.string().trim().min(8, 'O curso deve ter no mínimo 8 caracteres.').required('O nome do curso é obrigatório.'),
  courseDescription: Yup.string()
    .trim()
    .min(25, 'A descrição do curso deve ter no mínimo 25 caracteres.')
    .required('A descrição do curso é obrigatória'),
  courseSpecialty: Yup.string().required('A especialidade é obrigatória'),
  courseValue: Yup.number()
    .min(3000, 'O preço do curso deve ser de mínimo R$ 30,00')
    .required('O preço do curso é obrigatório'),
  courseWorkload: Yup.number().min(0).required('A carga horária do curso é obrigatória'),
  courseDisclaimer: Yup.boolean(),
})

export const courseFlexValidationSchema = courseValidationSchema.shape({
  courseValue: Yup.number()
    .min(16000, 'O preço do curso deve ser de mínimo R$ 160,00')
    .required('O preço do curso é obrigatório'),
})
