import styled, { css } from 'styled-components'

import { theme } from '@provi/provi-components'
import icon_search from '~/assets/svgs/icon-search.svg'

export const ContainerHeader = styled.div`
  height: 81px;
  width: 100%;
  background-color: ${theme.colors.blue900};
  position: fixed;
  z-index: 998;
  ${theme.breakpoints.down('md')} {
    height: auto;
  }

  @media (max-width: 900px) {
    height: auto;
    padding-top: 10px;
  }

  @media (min-width: 900px) {
    ${({ drawerWidth }) =>
      drawerWidth === 240 &&
      css`
        height: auto;
        padding-top: 10px;
      `}
  }

  @media (min-width: 1080px) {
    height: 81px;
    padding-top: 0;
  }

  padding-left: ${({ drawerWidth, isMobile }) => (drawerWidth && !isMobile ? `${drawerWidth}px` : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: padding 0.5s;
`

export const ListButton = styled.div`
  background: ${theme.colors.grey400};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 42px;
  font-family: 'CenturyGothicBold';
  ${({ single }) =>
    !single
      ? css`
          padding: 0 11px;
          width: ${({ width }) => width || '90%'};
          svg {
            margin-right: 0px;
          }

          @media screen and (min-width: 1000px) {
            svg {
              margin-right: 11px;
            }
          }
        `
      : css`
          width: 42px;
          min-width: 42px;
          margin-right: 10px;
        `}
  &:hover {
    opacity: 0.9;
  }

  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft};` : ``)}
`

export const WrapperButtons = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  /* width: 300px; */

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    margin: 15px 0 0;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.colors.blue800};
  }
`

export const WrapperBoxButtons = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  /* width: 300px; */

  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-bottom: 15px;
    justify-content: space-between;
    max-width: 300px;
  }
`

export const Search = styled.input`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'unset')};
  font-family: 'CenturyGothic';
  background: #ffffff;
  border: 1px solid #b9b7b7;
  box-sizing: border-box;
  border-radius: 50px;
  height: 42px;
  width: 200px;
  /* margin-top: 15px; */
  background-image: url(${icon_search});
  background-repeat: no-repeat;
  background-position: center left 15px;
  padding-left: 45px;
  font-size: 16px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 40px;
    /* max-width: 220px; */
    background-position: center left 10px;
    padding-left: 50px;
    font-size: 14px;
    max-width: 300px;
  }
`

export const BoxButton = styled.div`
  height: inherit;
  width: 75px;
  background-color: ${({ active }) => (active ? theme.colors.pink900 : theme.colors.blue800)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 20px;

  /* font */
  font-family: ${({ active }) => (active ? 'CenturyGothicBold' : 'CenturyGothic')};
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey400};
  cursor: pointer;

  ${theme.breakpoints.down('md')} {
    height: 40px;
    width: 20%;
    margin-right: unset;
    font-size: 12px;
    line-height: 24px;
  }

  @media screen and (max-width: 365px) {
    height: 30px;
  }
`

export const WrapperHeader = styled.div`
  max-width: 1600px;
  //margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 2%;
  flex-wrap: wrap;
  /* position: absolute; */
  /* bottom: 0; */
`
export const Top = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    justify-content: center;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid ${theme.colors.blue800};
  }
`
export const ControlBox = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 900px) {
    ${({ drawerWidth }) =>
      drawerWidth === 240 &&
      css`
        width: 100%;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid ${theme.colors.blue800};
      `}
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid ${theme.colors.blue800};
  }

  @media (min-width: 1080px) {
    width: auto;
    margin-top: 0;
    padding: 0;
    border-top: none;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
    justify-content: center;
    /* margin: 0px; */
    /* margin-bottom: 15px; */
    border-top: none;
  }
`
export const ControlBoxText = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  margin: 0 10px;
  color: #fff;
`

export const WrapperArrow = styled.div`
  margin-right: 25px;

  svg {
    cursor: ${({ active }) => (active ? 'pointer' : 'default')};
    width: 20px;
    height: 20px;
  }
`

export const WrapperSearch = styled.div`
  margin-left: 5px;
  ${theme.breakpoints.down('md')} {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 15px; */
  }
`
