import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100%;
  padding: 30px 15px;
  min-height: 120px;
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('sm')} {
    padding: 15px 7px;
    min-height: 100px;
  }
`
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightColumn = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('sm')} {
    margin-top: auto;
  }
`
export const ButtonWrapper = styled.div`
  .MuiButtonBase-root {
    padding: 5px 8px;
    width: initial;
    height: initial;
  }

  .MuiButton-label {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Montserrat-SemiBold', sans-serif;
  }
`
