import React from 'react'
import Box from '@material-ui/core/Box'
import { uniqueId } from 'lodash'
import { findAndPrintValue, mobileValuesToPrint } from '../../helpers'

import { TwoColumnGrid } from './styles'

const EachDetailMobile = ({ row, arrValue }) => {
  return (
    <div>
      <strong>{arrValue}</strong>
      <br />
      {findAndPrintValue(row, arrValue).render('Cell')}
    </div>
  )
}

export const DetailsMobileRows = ({ row }) => {
  return (
    <Box>
      <TwoColumnGrid>
        {mobileValuesToPrint.map((eachValue) => {
          return <EachDetailMobile key={uniqueId()} row={row} arrValue={eachValue} />
        })}
      </TwoColumnGrid>
    </Box>
  )
}
