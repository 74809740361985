import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CourseDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${theme.breakpoints.down('md')} {
    gap: 1.5rem;
  }
`

export const UpperTextGrid = styled.div`
  display: grid;
  gap: 0.5rem;
`

export const Divider = styled.div`
  border-top: 2px solid ${theme.colors.bluePrimaryLight};
  grid-column: 1 / -1;
`
