const initialMobile = [
  { item: 'name', label: 'Nome', show: false, blocked: true },
  { item: 'cpf', label: 'CPF', show: false, blocked: true },
  { item: 'id', label: 'ID', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', show: true },
  { item: 'value', label: 'Valor da venda', show: true },
  { item: 'nextPayment', label: 'Data vencimento 1° boleto', show: true },
  { item: 'seller', label: 'Vendedor', show: true },
  { item: 'products', label: 'Produtos', show: true },
  { item: 'status', label: 'Status', show: true, blocked: true },
  { item: 'origin', label: 'Origem', show: true, blocked: true },
  { item: 'productType', label: 'Tipo de produto', show: true },
  { item: 'createDate', label: 'Data de criação', show: true },
  { item: 'expirationDate', label: 'Data de expiração', show: true },
  { item: 'lastUpdate', label: 'Ultima atualização', show: true, blocked: true },
  { item: 'installments', label: 'Parcelas', show: true },
  { item: 'madeEffectiveDate', label: 'Data de efetivação', show: true, blocked: true },
  {
    item: 'commercialTrack',
    label: 'Trilha comercial',
    show: true,
  },
]

const initial = [
  {
    item: 'origin',
    label: 'Origem',
    show: true,
    isFilter: true,
    showSelect: false,
  },
  { item: 'productType', label: 'Tipo de produto', show: true, isFilter: true, showSelect: false },
  { item: 'id', label: 'ID', show: true, blocked: true },
  {
    item: 'status',
    label: 'STATUS',
    show: true,
    blocked: true,
    showSelect: false,
    isFilter: true,
  },
  {
    item: 'commercialTrack',
    label: 'Trilha comercial',
    show: true,
    showSelect: false,
  },
  { item: 'lastUpdate', label: 'Última atualização', show: true, blocked: true },
  { item: 'cpf', label: 'CPF', show: true, blocked: true },
  { item: 'name', label: 'Nome', show: true, blocked: true },
  { item: 'email', label: 'Email', show: true },
  { item: 'phone', label: 'Telefone', show: true },
  { item: 'campaignName', label: 'Nome da campanha', show: true, isFilter: true, showSelect: false },
  { item: 'products', label: 'Produtos', show: true, isFilter: true, showSelect: false },
  { item: 'installments', label: 'Parcelas', show: true },
  { item: 'seller', label: 'Vendedor', show: true, isFilter: true, showSelect: false },
  { item: 'value', label: 'Valor da venda', show: true },
  { item: 'nextPayment', label: 'Data vencimento 1° boleto', show: true },
  {
    item: 'createDate',
    label: 'Data de criação',
    show: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
  },
  { item: 'expirationDate', label: 'Data de expiração', show: true },
  {
    item: 'madeEffectiveDate',
    label: 'Data de efetivação',
    show: true,
    blocked: true,
    isFilter: true,
    isDate: true,
    startDate: new Date(),
    endDate: new Date(),
    isChanged: false,
    blankDatePermitted: true,
    isBlankDate: false,
  },
]

const mobileArrWithOrigin = initialMobile.map((eachObj) => ({ ...eachObj, tableOrigin: 'Gerenciar vendas' }))
const initialArrWithOrigin = initial.map((eachObj) => ({ ...eachObj, tableOrigin: 'Gerenciar vendas' }))

/**
 * @description get initial header in table
 * @name getHeaderTableValues
 * @param {{ isMobile: boolean }} params
 * @return {typeof initial}
 */

export const getHeaderTableValues = ({ isMobile }) => {
  if (isMobile) {
    return mobileArrWithOrigin
  }

  return initialArrWithOrigin
}
